<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Staff Schedule' | translate | allCaps}}</div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Staff' | translate}}:<span class="required-entry">*</span></label> 
                <Dropdown  
                    v-on:item-selected="selectedStaff = $event" 
                    v-bind:selected="selectedStaff.displayname" 
                    v-bind:optioncontent="allStaff"
                    placeholder="Select Staff">
                </Dropdown>
            </div>
            <div class="staff-schedule-container" v-for="(schedule,index) in staffSchedule" :key="index">
                
                <div class="schedule-location">{{schedule.name}}</div> 
                <!-- <div class="schedule-location">Start:{{selectedStaff.startdate}}</div> 
                <div class="schedule-location">End:{{selectedStaff.expiredate}}</div>  -->
                <div class="schedule-row" v-for="(day,index) in schedule.data" :key="index">
                    <div v-if="!day.closed">
                        <div class="schedule-day">{{day.name}}</div>
                        <div class="schedule-day" ><span v-if="day.startTime">{{day.startTime.name}}</span></div>
                        <div class="schedule-day" ><span v-if="day.endTime">{{day.endTime.name}}</span></div>
                    </div>
                </div>   
            </div>

            <div class="button-main" @click="closePanel()">{{'Close' | translate}}</div>
        </div>
        
    </div>
</template>

<script>
import Dropdown from '../utility/Dropdown'
import store from '../../store/store'

export default {
    name: 'StaffSchedule',
    data() {
        return {
            selectedStaff: {},
            allStaff: [],
            store,
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            staffSchedule: []
        }
    },
    components: {
        Dropdown,
    },
    methods: {
        getStaff() {
            this.$store.dispatch('getAllStaff').then(response => {
                window.console.log('GOT STAFF', response)
                this.allStaff = response
                this.allStaff.map(item => {item.name = item.displayname})
            })
        },
        closePanel() {
            this.$router.back()
        }

    },
    mounted() {
        this.getStaff()
    },
    watch: {
        selectedStaff() {
            const selectedStaff = this.allStaff.filter(staff => {
                if (staff.id == this.selectedStaff.id) {
                    return staff
                }
            })
            window.console.log('SELECTED STAFF', selectedStaff, this.selectedStaff)
            this.staffSchedule = selectedStaff[0].schedule
            window.console.log('STAFF SCHEDULE', this.staffSchedule)
        },
    },
}
</script>

<style scoped>
.schedule-day{
    display: inline-block;
    width: 100px;
}
.schedule-location{
    font-weight: 600;
    padding: 5px;
}
</style>
    