<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Expenses' | translate | allCaps}}</div>
        <div class="reports-schedule-row">
            <div class="report-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="selectedStartDate = $event" 
                    v-bind:mydate="startdate"
                    :placeholder = "'Select Start Date'">
                </DatePicker>
            </div>
            <div class="report-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="selectedEndDate = $event" 
                    v-bind:mydate="enddate"
                    :placeholder = "'Select End Date'">
                </DatePicker>
            </div>
            <div class="report-dropdown">
                <Dropdown  
                    v-on:item-selected="selectedLocation = $event" 
                    v-bind:selected="selectedLocation.name" 
                    v-bind:optioncontent="allLocations"
                    v-bind:placeholder="currentLocation.name">
                </Dropdown>
            </div>
        </div>
        <div v-for="(expense,index) in expenses" :key="index" class="expense-item-row">
            <div class="expense-item med-width">{{expense.date}}</div>
            <div class="expense-item med-width">{{expense.type}}</div>
            <div class="expense-item large-width">{{expense.description}}</div>
            <div class="expense-item med-width align-right">{{expense.amount | toUSD}}</div>
            <div class="expense-item med-width">{{expense.paytype}}</div>
            <div class="expense-item small-width">
                <div class="camera-button" 
                    v-if="expense.receiptref"
                    @click="showPicture(expense.receiptref)" >
                    <i class="fas fa-camera"></i>
                </div>   
            </div>
        </div>
        <div  class="expense-item-row">
            <div class="expense-item med-width"><b>{{'TOTAL' | translate}}</b></div>
            <div class="expense-item med-width"> </div>
            <div class="expense-item large-width"></div>
            <div class="expense-item med-width align-right"><b>{{expenseTotal | toUSD}}</b></div>
            <div class="expense-item med-width"></div>
            <div class="expense-item small-width"></div>
        </div>
        <div class="receipt-image-container" v-if="showReceipt">
            <div class="receipt-image-title">
                {{'Receipt' | translate}}
                <div class="receipt-close-box" @click="showReceipt = false">X</div>
            </div>
            <div class="image-container">
                <img v-bind:src="`${imageURL}${receiptImage}.jpg`" 
                class="receipt-image"
                @click="showReceipt = false"/>
            </div>
        </div>
        
    </div>
</template>

<script>
import DatePicker from '../utility/DatePicker'
import store from '../../store/store'
import Globals from '../../utilities/globals'
import Dropdown from '../utility/Dropdown'

export default {
    name: 'ExpensesAll',
    data() {
        return {
            store,
            selectedStartDate: {},
            selectedEndDate: {},
            selectedLocation:this.$store.getters.currentLocation,
            startdate: '',
            enddate: '',
            expenses: [],
            imageURL: Globals.imageURL,
            receiptImage: '',
            showReceipt: false,
            allLocations: this.$store.getters.allLocations,
            currentLocation:this.$store.getters.currentLocation, 
            expenseTotal: 0
        }
    },
    components: {
        DatePicker,
        Dropdown
    },
    methods: {
        getExpenses() {
            const data = {
                startdate: Globals.formatDBDate(this.selectedStartDate.day,this.selectedStartDate.month,this.selectedStartDate.year),
                enddate: Globals.formatDBDate(this.selectedEndDate.day,this.selectedEndDate.month,this.selectedEndDate.year),
                locationid: this.selectedLocation.id
            }
            window.console.log('GET EXPENSES',data)
            this.$store.dispatch('getExpenses',data).then(response => {
                window.console.log('GET EXPENSES RESULTS', response, response.data)
                this.expenses = response.data
                this.expenses.map(expense => {
                    window.console.log('expense', expense.date,new Date(expense.date) )
                    expense.date = Globals.formatShortDate(new Date(expense.date))
                })
                this.expenses.forEach(expense => {
                    this.expenseTotal += Number(expense.amount)
                })
            })

        },
        showPicture(ref) {
            window.console.log('SHOW PICTURE',ref)
            this.receiptImage = ref
            this.showReceipt = true;
        }
    },
    mounted() {

    },
    watch: {
        selectedStartDate() {
            window.console.log('START DATE', this.selectedStartDate)
            if (this.selectedEndDate.day) {
                this.getExpenses()
            }
        },
        selectedEndDate() {
            window.console.log('END DATE', this.selectedEndDate)
            if (this.selectedStartDate.day) {
                this.getExpenses()
            }
        },
        selectedLocation() {
            if (this.selectedStartDate.day && this.selectedEndDate.day) {
                this.getExpenses()
            }
        }
    }
}
</script>

<style scoped>
.expense-item-row{
    text-align: left;
    border: 1px solid gray;
    border-bottom: none;
    margin: 0 5%;
    height: 39px;
}
.expense-item-row:last-child{
    border-bottom: 1px solid gray;
}
.expense-item{
    display: inline-block;
    padding: 3px 10px;
    border-right: 1px solid gray;
    line-height: 35px;
}
.expense-item:last-child{
    border-right: none;
}
.small-width{
    width: 5%;
} 
.med-width{
    width: 10%;
} 
.large-width{
    width: 30%;
}
.align-right{
    text-align: right;
}
.camera-button{
    width: 30px;
    height: 30px;
    background-color: #63b4c5;
    border-radius: 15px;
    display: inline-block;
    vertical-align: top;
    margin: 2px;
    cursor: pointer;
    position: relative;
}
.fa-camera{
   font-size: 15px;
    padding: 7.5px;
    color: white;
    vertical-align: top; 
} 
.receipt-image-container{
    position: absolute;
    top: 37px;
    z-index: 10;
    width: 80%;
    border: 1px solid gray;
    background-color: white;
    border-top: none;
}
.receipt-image-title{
    background-color:#b9bbbb;
    height: 35px;
    vertical-align: middle;
    line-height: 35px;
    font-weight: 600;
}
.receipt-close-box{
    width: 50px;
    background-color: gray;
    color: white;
    height: 35px;
    float: right;
    cursor: pointer;
} 
.receipt-image{
    width: 100%;
} 
.image-container{
    padding: 10px;
}
@media screen and (max-width: 700px) {
    .expense-item-row{
        margin: 0;
    }
} 
</style>