<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Welcome' | translate | allCaps}}</div>
        <div class="intro-section">
            <div class="welcome-title">{{'Welcome to Simplify Software' | translate}}</div>
            <div>{{'INFO_CONTENT.START_STEPS' | translate}}</div>
            <div>{{'INFO_CONTENT.SETUP_TIME' | translate}}</div>
            <div>{{'INFO_CONTENT.WATCH_TOUR' | translate}}.</div>
            <div class="watch-video-button">{{ 'Tour Video' | translate}}</div>
        </div>
        <div class="step-divider">
            <div class="step-title">{{'4 Easy Steps to Get Started' | translate}}</div>
        </div>
        <div class="welcome-point-container">
            <div class="step-point">1</div>
            <div class="step-description">
                <div class="step-description-title">{{'Enter Business Information' | translate }}</div>
                <div class="step-description-info">{{'INFO_CONTENT.ENTER_BASIC' | translate}}</div>
                <div class="watch-video-button">{{ 'Watch Video' | translate}}</div>
            </div>
        </div>
        <div class="welcome-point-container">
            <div class="step-point">2</div>
            <div class="step-description">
                <div class="step-description-title">{{'Add Services' | translate}}</div>
                <div class="step-description-info">{{'INFO_CONTENT.ADD_SERVICES' | translate}}</div>
                <div class="watch-video-button">{{ 'Watch Video' | translate}}</div>
            </div>
        </div>
        <div class="welcome-point-container">
            <div class="step-point">3</div>
            <div class="step-description">
                <div class="step-description-title">{{'Add Rooms/Stations' | translate}}</div>
                <div class="step-description-info">{{'INFO_CONTENT.ADD_ROOMS' | translate}}</div>
                <div class="watch-video-button">{{ 'Watch Video' | translate}}</div>
            </div>
        </div>
        <div class="welcome-point-container">
            <div class="step-point">4</div>
            <div class="step-description">
                <div class="step-description-title">{{'Add Staff' | translate}}</div>
                <div class="step-description-info">{{'INFO_CONTENT.ADD_STAFF' | translate}}</div>
                <div class="watch-video-button">{{ 'Watch Video' | translate}}</div>
            </div>
        </div>
        <div>
            <div class="button-main"  @click="goNext">{{ 'Next' | translate | allCaps}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WelcomePage',
    data() {
        return{

        }
    },
    components: {

    },
    methods: {
        goNext() {
            this.$router.push('AddBusiness')
        }
    }
}
</script>

<style scoped>
.welcome-point-container{
    /* height: auto; */
    /* min-height: 100px; */
    border-bottom: 1px solid black;
    /* text-align: left; */
}
.intro-section{
    padding: 15px;
}
.step-point{
    background-color: indigo;
    color: white;
    font-size: 18px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
} 
.step-description{
    display: inline-block;
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    width: 80%;
} 
.step-description-title{
    font-weight: 700;
    font-size: 16px;
}
.step-description-info{
    display: block;
}
.step-divider{
    background-color: #4c3399;
    height: 25px;
    color: white;
    line-height: 25px;
    margin-top: 20px;
}
.step-divider-title{
    color: white;
}
.watch-video-button{
    background-color: #9395f1;
    color: white;
    height: 20px;
    padding: 2px 10px;
    border-radius: 10px;
    display:inline-block;
    font-size: 14px;
    line-height: 21px;
    margin: 5px;
    cursor: pointer;
}
.welcome-title{
    font-size: 20px;
    font-weight: 700;
    color: #550080;
}
@media screen and (max-width: 680px) {
    .step-point{
        margin: 5px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        line-height: 40px;
    }
    .step-description{
        width: 72%;
    }
}
</style>