<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Daily Reconciliation' | translate | allCaps}}</div>
        <div class="reports-schedule-row">
            <div class="report-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="selectedDate = $event" 
                    v-bind:mydate="selectedDate"
                    :placeholder = "'Select Date'">
                </DatePicker>
            </div>
            <div class="report-dropdown">
                <Dropdown  
                    v-on:item-selected="selectedLocation = $event" 
                    v-bind:selected="selectedLocation.name" 
                    v-bind:optioncontent="allLocations"
                    v-bind:placeholder="currentLocation.name">
                </Dropdown>
            </div>
        </div>
        <div v-if="showResults">
        <div class="report-activity-title">{{'Bookings' | translate}}</div>
        <div >
            <div class="report-item-title-row" >
                    <div class="report-table-item item-title small-item">{{'Booking' | translate}}#</div> 
                    <div class="report-table-item item-title">{{'Staff' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Estimate' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Invoice' | translate}}#</div>
                    <div class="report-table-item item-title small-item" >{{'Total' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Cash' | translate}}</div>
                    <div class="report-table-item item-title small-item">{{'Card' | translate}}</div>
                    <div class="report-table-item item-title small-item">{{'Check' | translate}}</div> 
                    <div class="report-table-item item-title small-item">{{'Promo' | translate}}</div> 
                </div>
                <div class="report-item-row" v-for="(booking,index) in bookings" :key="'book-'+index">
                    <div class="report-table-item small-item">{{booking.id}}</div> 
                    <div class="report-table-item">{{booking.staff}}</div> 
                    <div class="report-table-item small-item">{{valueBooking(booking.serviceid) | toUSD}}</div> 
                    <!-- <div class="report-table-item small-item">{{getBilled(booking.id) | toUSD}}</div> -->
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length && getInvoice(booking.id)[0].id? getInvoice(booking.id)[0].id : 'Not Billed'}}</div> 
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length? getInvoice(booking.id)[0].invoicetotal : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length && getInvoice(booking.id)[0].paytype === 'Cash'? getInvoice(booking.id)[0].amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length && getInvoice(booking.id)[0].paytype == 'Credit Card'? getInvoice(booking.id)[0].amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length && getInvoice(booking.id)[0].paytype == 'Check'? getInvoice(booking.id)[0].amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{getInvoice(booking.id).length && getInvoice(booking.id)[0].appliedcredititems.length? getInvoice(booking.id)[0].appliedcredititems[0].AMTAPPLIED : 0 | toUSD}}</div>
                    
            </div>
            <div class="report-item-title-row total-row" >
                    <div class="report-table-item item-title small-item">{{'Total' | translate}}</div> 
                    <div class="report-table-item item-title"></div>
                    <div class="report-table-item item-title small-item align-right" >{{getBookingTotal('estimate') | toUSD}}</div>
                    <div class="report-table-item item-title small-item" ></div>
                    <div class="report-table-item item-title small-item align-right" >{{getBookingTotal('total') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right" >{{getBookingTotal('cash') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right">{{getBookingTotal('card') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right">{{getBookingTotal('check') | toUSD}}</div> 
                    <div class="report-table-item item-title small-item align-right">{{getBookingTotal('promo') | toUSD}}</div> 
                </div>
            
            </div>

            <div class="report-activity-title">{{'Invoices' | translate}}</div>
            <div >
                <div class="report-item-title-row" >
                    <div class="report-table-item item-title small-item">{{'Booking' | translate}}#</div> 
                    <div class="report-table-item item-title">{{'Staff' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Bk Date' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Invoice' | translate}}#</div>
                    <div class="report-table-item item-title small-item" >{{'Total' | translate}}</div>
                    <div class="report-table-item item-title small-item" >{{'Cash' | translate}}</div>
                    <div class="report-table-item item-title small-item">{{'Card' | translate}}</div>
                    <div class="report-table-item item-title small-item">{{'Check' | translate}}</div> 
                    <div class="report-table-item item-title small-item">{{'Promo' | translate}}</div> 
                </div>
                <div class="report-item-row" v-for="(invoice,index) in invoices" :key="'inv-'+index">
                    <div class="report-table-item small-item">{{invoice.bookingid}}</div> 
                    <div class="report-table-item">{{invoice.staff}}</div> 
                    <div class="report-table-item small-item">{{invoice.bookingdate | shortDate }}</div> 
                    <div class="report-table-item small-item">{{invoice.id}}</div> 
                    <div class="report-table-item small-item">{{invoice.invoicetotal | toUSD}}</div>
                    <div class="report-table-item small-item">{{invoice.paytype === 'Cash'? invoice.amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{invoice.paytype == 'Credit Card'? invoice.amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{invoice.paytype == 'Check'? invoice.amountpaid : 0 | toUSD}}</div>
                    <div class="report-table-item small-item">{{invoice.appliedcredititems.length? getAppliedCredits(invoice) : 0 | toUSD}}</div>
                    
            </div>
            <div class="report-item-title-row total-row" >
                    <div class="report-table-item item-title small-item">{{'Total' | translate}}</div> 
                    <div class="report-table-item item-title"></div>
                    <div class="report-table-item item-title small-item" ></div>
                    <div class="report-table-item item-title small-item" ></div>
                    <div class="report-table-item item-title small-item align-right" >{{getInvoicesTotal('amt') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right" >{{getInvoicesTotal('Cash') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right">{{getInvoicesTotal('Credit') | toUSD}}</div>
                    <div class="report-table-item item-title small-item align-right">{{getInvoicesTotal('Check') | toUSD}}</div> 
                    <div class="report-table-item item-title small-item align-right">{{getInvoicesTotal('Promo') | toUSD}}</div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from '../utility/DatePicker'
import Dropdown from '../utility/Dropdown'
import store from '../../store/store'
import EventBus from '../../utilities/eventBus'

export default {
    name: 'DailyReconcile',
    data() {
        return {
            selectedDate: {},
            selectedLocation: {},
            allLocations: this.$store.getters.allLocations,
            currentLocation:this.$store.getters.currentLocation, 
            store,
            showDistReport: false,
            showResults: false,
            reportDate: '',
            bookings: [],
            invoices: [],
            allServices: [],

        }
    },
    components: {
        DatePicker,
        Dropdown,
    },
    methods: {
        getBookings() {
            const data = {
                startdate: this.reportDate,
                enddate: this.reportDate,
                location: this.currentLocation
            }
            this.$store.dispatch('getBookingsDates',data).then(response => {
                if (response.result == 'EXPIRED') {
                    const data = {
                        title: "Session Timeout",
                        message: "Your session has timed out. Please login again.",
                        showOK: true,
                        okAction: 'login'
                    }
                    EventBus.$emit('SHOW_MESSAGE', data)
                } else {
                    this.bookings = response
                    this.getInvoices(data)
                }
                
            })
            
        },
        getInvoices(data) {
            window.console.log('GET INVOICES', data)
            this.$store.dispatch('getInvoicesDates',data).then(response => {
                this.invoices = response
            })
            this.showResults = true
        },
        valueBooking(id) {
            let valueBookings = 0            
            this.allServices.forEach(service => {
                if (service.id === id) {
                    if (service.trackinventory) {
                        valueBookings += Number(service.retailprice) * Number(service.estimatedusage)
                    } else {
                        valueBookings += Number(service.retailprice)
                    }
                    
                }
            })
            return valueBookings
        },
        getServices() {
            this.$store.dispatch('getServicesAll').then(response => {
                this.allServices = response
            })
        },
        getBilled(id) {
            let amtBilled = 0
            this.invoices.forEach(invoice => {
                if(invoice.bookingid == id) {
                    amtBilled += Number(invoice.invoicetotal)
                }
            })
            return amtBilled
        },
        getInvoice(bookingid) {
           return  this.invoices.filter(invoice => invoice.bookingid == bookingid)
        },
        getInvoicesTotal(type) {
            let resultTotal = 0
            this.invoices.forEach(invoice => {
                if (type == 'amt') {
                    resultTotal += Number(invoice.invoicetotal)
                }
                if (type == 'Cash') {
                    resultTotal += invoice.paytype == 'Cash'? Number(invoice.amountpaid) : 0
                }
                if (type == 'Credit') {
                    resultTotal += invoice.paytype == 'Credit Card'? Number(invoice.amountpaid) : 0
                }
                if (type == 'Check') {
                    resultTotal += invoice.paytype == 'Check'? Number(invoice.amountpaid) : 0
                }
                if (type == 'Promo') {
                    if (invoice.appliedcredititems.length) {
                        invoice.appliedcredititems.forEach(item => {
                            resultTotal += Number(item.AMTAPPLIED)
                        })                        
                    } 
                }
            })
            return resultTotal
        },
        getAppliedCredits(invoice) {
            let resultTotal = 0
            invoice.appliedcredititems.forEach(item => {
                resultTotal += Number(item.AMTAPPLIED)
            }) 
            return resultTotal
        },
        getBookingTotal(type) {
            let calcTotal = 0
            if (this.bookings.length) {
                 this.bookings.forEach(booking => {
                    if (type == 'estimate') {                   
                        calcTotal += this.valueBooking(booking.serviceid)                   
                    }
                    if (type == 'total') {
                        calcTotal += this.getInvoice(booking.id).length ? Number(this.getInvoice(booking.id)[0].invoicetotal) : 0
                    }
                    if (type == 'cash') {
                        calcTotal += this.getInvoice(booking.id).length && this.getInvoice(booking.id)[0].paytype == 'Cash' ? this.getInvoice(booking.id)[0].amountpaid : 0
                    }
                    if (type == 'card') {
                        calcTotal += this.getInvoice(booking.id).length && this.getInvoice(booking.id)[0].paytype == 'Credit Card' ? this.getInvoice(booking.id)[0].amountpaid : 0
                    }
                    if (type == 'check') {
                        calcTotal += this.getInvoice(booking.id).length && this.getInvoice(booking.id)[0].paytype == 'Check' ? this.getInvoice(booking.id)[0].amountpaid : 0
                    }
                    if (type == 'promo') {
                        if (this.getInvoice(booking.id).length && this.getInvoice(booking.id)[0].appliedcredititems.length) {
                            this.getInvoice(booking.id)[0].appliedcredititems.forEach(item => {
                                calcTotal += Number(item.AMTAPPLIED)
                            })
                        }
                    }
                })
            }
            
            return calcTotal
        }

    },
    mounted() {
        this.getServices()
    },
    watch: {
        selectedDate() {
            window.console.log('START DATE', this.selectedDate)
            if (this.selectedDate.day) {
                this.reportDate = `${this.selectedDate.month + 1}/${this.selectedDate.day}/${this.selectedDate.year}`
                this.showDistReport = true
                this.getBookings()
            }
        },
        selectedLocation() {
            if (this.selectedDate.day) {
               this.currentLocation = this.selectedLocation
               this.showDistReport = true
               this.getBookings()
            }
        }
    }
}
</script>

<style scoped>
.report-activity-title{
    background-color: #3367c5;
    margin-top: 15px;
    color: white;
    font-weight: 700;
    font-size: 14px;
}
.report-title{
    background-color: #33a4c5;
    line-height: 27px;
    height: 25px;
} 
.report-item-title-row{
    height: 20px;
    vertical-align: middle;
}
.report-item-row{
    border: 1px solid #aac1ce;
    min-height: 20px;
    border-bottom: none;
}

.report-item-row:last-child{
    border-bottom: 1px solid #aac1ce;
}
.report-table-item{
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
    text-align: right;
    padding-right: 3px;
    width: 15%;
    border-right: 1px solid #aac1ce;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    
}
.report-table-item:last-child{
    border-right: none;
}
.item-title{
    font-weight: 600;
    word-break:normal;
    white-space: none;
    /* height: 33px; */
    line-height: 16px;
    text-align: center;
    vertical-align: middle
}
.tiny-item{
    width: 5%;
}
.small-item{
    width: 8%;
}
.align-right{
    text-align: right;
}
.report-sub-container{
    margin: 0 2%;
}


.item-total{
    font-weight: 600;
    word-break:normal;
    white-space: none;
    height: 20px;
    line-height: 16px;
    text-align: right;
    padding-right: 3px;
    vertical-align: middle;
}
.item-center{
    text-align: center;
}
.is-clickable{
    cursor: pointer;
    white-space: nowrap;
} 
.total-row{
    border-top: 2px solid black;
}  
</style>