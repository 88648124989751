<template>
    <div class="header-navigation-container">
        
        <div class="navigation-container" v-if="showNav">
            
            <div class="navigation-row" >
                <div class="navigation-items-container" v-if="showNavItems">
                    <div class="nav-item">
                        <router-link :to="{ name: 'Schedule' }">
                            <div class="nav-link-item">{{'Schedule' | translate}}</div>
                        </router-link> 
                    </div>
                <div class="nav-item" @click="showSettings=!showSettings">{{'Settings' | translate}}</div>
                    
                </div>                
            </div>
            <SlideOutPanel v-bind:slideOut="showSettings"
            v-on:show-hide-settings="showSettings = $event"/>
        </div>
    </div>
</template>



<script>
import EventBus from '../../utilities/eventBus'
import SlideOutPanel from '../utility/SlideOutPanel'
import Globals from '../../utilities/globals'

export default {
    name: 'Navigation',
    data: function() {
        return {
            showSubNav: false,
            showClientNav: false,
            showNavItems: true,
            showNav: true,
            showSetup: false,
            showStaffGroup: false,
            showClientGroup: false,
            showPromotionGroup: false,
            showInventoryGroup: false,
            showSetupGroup: false,
            showStaffScheduleGroup: false,
            showSettings: false
        }
    },
    components: {
        SlideOutPanel
    },
    methods: {
        checkRoute() {
            window.console.log('checkRoute',this.$route.name)
           if (Globals.whiteListNav(this.$route.name) || !this.$route.name) {
                this.showNav = false
           } else if (this.$route.name == 'MedOnboard') {
                this.showNavItems = false
           } else {
               window.console.log('SHOW NAV ITEMS')
               this.showNav = true;
                this.showNavItems = true
           }
           
       }
    },
    mounted() {
        EventBus.$on('LOG_OUT',() => {
            window.console.log('ON LOGOUT')
            this.showNavItems = false
        })
        EventBus.$on('USER_LOGIN',() => {
            window.console.log('LOGGED IN')
            this.showNavItems = true
        })
        EventBus.$on('CHANGE_LANGUAGE',() => {
            this.$forceUpdate()            
        })
        this.checkRoute()
    },
    watch: {
        $route(to,from) {
            window.console.log('ROUTE CHANGE', to.name, from.name)
            this.showSettings = false
            this.checkRoute()
        }
    }
}
</script>

<style scoped>
.header-navigation-container{
    background-color: white;
    width: 100%;
}
.nav-item {
    display: inline-block;
    padding: 2px 20px;
    /* border-right: 1px solid gray; */
    /* min-width: 150px; */
    cursor: pointer;
    position: relative;
    font-size: 13px;
    top: -2px;
}
.nav-subpanel{
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    border: 1px solid #7b21f3;
    min-width: 200px;
}
.nav-subitem {
    font-size: 14px;
    border-bottom: 1px solid gray;
    padding: 5px 0;
}
/* .nav-subitem:last-child{
    border-bottom:none;
} */
.navigation-container{
    position: relative;
    z-index: 5
}
.navigation-items-container{
    display: inline-block;
    position: absolute;
    right: 0;
    margin-top: 2px;
}
.navigation-row{
    text-align: right;
    background-color: #6641d8;
    color: white;
    font-weight: 600;
    margin: 0;
    min-height: 23px;
    width: 100%;
}
.nav-link-item{
    color:white;
    display: inline-block;
}
router-link{
    text-decoration: none;   
}
a{
    text-decoration: none;
    font-weight: 600;
    color: gray;
}

.tasks-title-bar{
    background-color: #a360bf;
    text-align: center;
}
.tasks-title{
    color: white;
    font-size: 13px;
    display: inline-block;
    font-weight: 600;
}
.tasks-close-button{
    font-weight: 800;
    font-size: 13px;
    padding: 3px 10px 0 10px;
    cursor: pointer;
    float: right;
    background-color: #1c2669;
    height: 19px;
    color: white;
}
.slide-fade-enter-active {
  transition: all .9s ease;
}
.slide-fade-leave-active {
  transition: all .8s 
  /* cubic-bezier(1.0, 0.5, 0.8, 1.0); */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(210px);
  /* opacity: 0; */
}
.nav-subsection-container{
    max-height: 0;
    transition: all .8s ;
    border-bottom: 2px solid #9b88e8;
    background-color: #f6f6f7;
}
.nav-active{
    max-height: 500px;    
    transition: all .8s ;
}
.title-indicator{
    display: inline-block;
    position: relative;
    top: 1px;
    left: 3px;
}
.nav-subsection-title {
    font-size: 14px;
    text-align: center;
    background-color: #f4f5f6;
    padding: 4px 5px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 2px solid #9b88e8;
    
}
.nav-subsection-item{
    font-size: 14px;
    /* border-bottom: 1px solid gray; */
    /* padding: 5px; */
    transition: all .5s; 
    position: relative;
    left: 200px;
}
.item-active{
    transition: all .5s ;
    left: 0;
}
.nav-task-item{
    display: inline-block;
}
.admin-title{
    background-color: #d5caff;
}

</style>