<template>
    <div class="infotable-container">
        <div class="infotable-item-row ">
            <div class="infotable-title-row">
                <div class="infotable-content-item item-name item-title-row">Product/Service</div>
                <div class="infotable-content-item  item-title-row">{{'Retail' | translate}}</div>
                <div class="infotable-content-item small-item-input  item-title-row">D%</div>
                <div class="infotable-content-item item-title-row">{{'Sale' | translate}}</div>
                <div class="infotable-content-item med-input  item-title-row">{{'Qty' | translate}}</div>
                <div class="infotable-content-item small-item-input  item-title-row">{{'Used' | translate}}</div>
                <div class="infotable-content-item small-item-input  item-title-row">{{'Credit' | translate}}</div>
                <div class="infotable-content-item  item-title-row">{{'Total' | translate}}</div> 
            </div>
        </div>
        <div class="infotable-items-row-container" v-for="(item, index) in infotableItems" 
                v-bind:key="index">
            <InvoiceItem                    
                v-bind:product="item"
                v-bind:ispaid="isPaid"
                v-on:total-updated="updatedTotal = $event"
                v-on:quantity-updated="checkQuantity = $event"
                >
            </InvoiceItem>
            <div class="row-button-group" v-if="index > 0">
                <i class="fas fa-trash-alt" @click="removeItem(index)" v-if="!isPaid"></i>
            </div>
        </div>
    </div>
</template>

<script>
import InvoiceItem from './InvoiceItem'
export default {
    name: 'ItemInfoTable',
    data() {
        return {
            promotionTotal: 0,
            updatedTotal: '',
            checkQuantity: '',
        }
    },
    props: [
        'infotableItems',
        'isPaid',
    ],
    components: {
        InvoiceItem
    },
    methods: {
        removeItem(index) {
            this.infotableItems.splice(index,1)
            this.calcItemsTotal()
        },
        calcItemsTotal: function() {
            let vm = this;
            let itemsTotal = 0
            let retailTotal = 0
            this.infotableItems.forEach(item => {  
                window.console.log('ITEM CALC',item)
                retailTotal += Number(item.retailprice) * item.quantity              
                itemsTotal += item.total
                vm.promotionTotal = itemsTotal;
            })
            window.console.log('TABLE TOTAL UPDATE', retailTotal, itemsTotal)
            this.$emit('table-total-updated', {total: itemsTotal, retail: retailTotal})
        },
    },
    watch: {
        updatedTotal() {
            this.calcItemsTotal()
        },
        checkQuantity() {
            this.$emit('quantity-updated',this.checkQuantity)
        }
    },
    mounted() {
        window.console.log('MOUNT INFO TABLE',this.infotableItems)
    }
    
}
</script>

<style scoped>
.infotable-container{
    margin-bottom: 15px;
    padding-left: 5%;
}
.infotable-item-row{
    display: inline-block;   
    font-size: 13px;
    width: 100%;
}
.infotable-title-row{
    display: inline-block;
    float: left;
    width: 90%;
    height: 24px;
}
.infotable-item-container{
    width: 100%;
    display: inline-block;
}
/* .infotable-items-row-container{
    width: 100%;
    display: inline-block;
    height: 20px;
} */
.infotable-form-container{
    border: 1px solid #b0b2b3;
    margin: 0px 10%;
    max-width: 800px;
    padding-bottom: 15px;
}
.infotable-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px;
    width: 10%;
    height: 20px;
}
.item-name{
    width: 26%;
}
.item-title-row{
    border-right: 1px solid white;
    background-color: #4d91a7;
    color: white;
    font-weight: 600;
}
.item-title-row:last-child{
    border-right: 2px solid #4d91a7;
}

.med-input{
    width: 9%;
    
}
.delete-icon{
    padding: 0 6px;
    margin-top: 1px;
}
.x-mark{
    margin-top: -2px;
}
.small-item-input{
    width: 7%;
}
.fa-trash-alt{
    position: relative;
    left: -15px;
    top: 6px;
    cursor: pointer;
    color: #888383;
}

@media screen and (max-width: 960px) {
    
    .infotable-container{
        padding-left: 0;      
    }
    .infotable-title-row{
        width: 97%;
    }
    
    .invoice-item-row{
        width: 97%;
    }
    .fa-trash-alt{
        left: 0;
    }
}

@media screen and (max-width: 680px) {
    .item-name{
        width: 21%;
    }
}

    
</style>