<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div class="selection-button-row">
            <RadioButton 
                :rbLabel="'Standard Entry'"
                v-bind:rbStatus="standardEntry"
                :rbRef="'standard'"
                v-on:rb-selected="rbWatcher = $event"
            />
            <RadioButton 
                :rbLabel="'Use QuickList'"
                v-bind:rbStatus="!standardEntry"
                :rbRef="'quicklist'"
                v-on:rb-selected="rbWatcher = $event"
            />
        </div>
        <QuickList 
            v-if="!standardEntry"
            :existingServices="allServices"
            v-on:add-selected="QuickListSelected = $event"
        />
        <div v-if="standardEntry">
            <div class="form-input-row">
                <label class="form-input-label" >{{'Name' | translate}} : </label>
                <input class="input-wide" id="name" v-model="service.name"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !service.name">*{{'You need to enter a' | translate}} {{'Service Name' | translate}}</div>
            
            <div class="form-input-row">
                <label for="price" class="form-input-label">{{'Price' | translate}} ($): </label> 
                <InputCurrency
                    v-on:amount-entered="service.retailprice = $event"
                    v-bind:amount="servicePrice"
                ></InputCurrency>
            </div>
            <div class='missing-selection-message' v-if="showError && service.retailprice < 0">*{{'You need to enter a' | translate}} {{'Price' | translate}}</div>

            <div class="form-input-row">
                <label for="price" class="form-input-label">{{'Cost' | translate}} ($): </label> 
                <InputCurrency
                    v-on:amount-entered="service.cost = $event"
                    v-bind:amount="serviceCost"
                ></InputCurrency>
            </div>
            <div class='missing-selection-message' v-if="showError &&  service.cost < 0">*{{'You need to enter a' | translate}} {{'Price' | translate}}</div>
            
            <div class="form-input-row">
                <label class="form-input-label">{{'Type' | translate}}: </label> 
                <Dropdown 
                    v-on:item-selected="selectedType = $event" 
                    v-bind:selected="service.type" 
                    v-bind:optioncontent="serviceTypes"
                    placeholder="Type"> 
                </Dropdown> 
            </div>
            <div class='missing-selection-message' v-if="showError &&  service.type < 0">*{{'You need to enter a' | translate}} {{'Service Type' | translate}}</div>

            <div class="form-input-row">
                <label for="price" class="form-input-label">{{'Track Inventory' | translate}}: </label> 
                <CheckBox 
                    v-bind:checkBoxLabel="''"
                    v-on:item-selected="service.trackinventory = $event, trackInventory = $event" 
                    :cbmodel="service.trackinventory"
                    />
            </div>
            <div class="form-input-row" v-if="trackInventory">
                <label for="price" class="form-input-label">{{'Related Supply' | translate}}: </label>
                <div class="search-ahead-supplies">
                    <SearchAhead 
                        v-bind:searchtype="'Supply'" 
                        v-bind:searchcall="'getSuppliesSearch'" 
                        v-bind:resultfields="['name']"
                        v-on:item-selected="selectedSupply = $event"
                        :searchTerm="selectedSupplyName" >
                    </SearchAhead>
                </div> 
            </div>
            <div class="form-input-row" v-if="trackInventory">
                <label class="form-input-label" >{{'Estimated Usage' | translate}}({{'Number' | translate | toLowerCase}}) : </label>
                <input id="name" v-model="service.estimatedusage"/>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">Length: </div>
                    Hrs
                    <Dropdown 
                    v-on:item-selected="selectedSessionHours = $event" 
                    v-bind:selected="selectedSessionHours.name" 
                    v-bind:optioncontent="sessionHours"
                    placeholder="Hours"> 
                </Dropdown> 
                
                    Min
                    <Dropdown 
                        v-on:item-selected="selectedSessionMinutes = $event" 
                        v-bind:selected="selectedSessionMinutes.name" 
                        v-bind:optioncontent="officeMinutes"
                        placeholder="Minutes">
                    </Dropdown> 
                
            </div>
            <div class='missing-selection-message' v-show="showError && (!selectedSessionHours.name || !selectedSessionMinutes.name)">*You need to select the Session Length</div>
        
        

<!-- LOCATIONS -->
        <div class="info-holding-container">
            <div class="service-existing-container" v-if="showLocations">
                <div class="service-title" >{{'Locations' | translate}}* </div> 
                <div >
                    <div class="checkbox-item-row"  v-for="(location, index) in allLocations" v-bind:key="'location' + index">
                        <CheckBox 
                            v-bind:checkBoxLabel="location.name"
                            v-on:item-selected="locations[location.id] = $event,selectLocation = $event" 
                            :cbmodel="locations[location.id]"
                            />
                        <div v-if="locations[location.id] == true">
                            <div class="restriction-title">{{'Room Restrictions' | translate | allCaps}}</div>
                            <div class="checkbox-item"  v-for="(room, index) in getRestrictedRooms(location.id)" :key="index">
                                <CheckBox 
                                    v-bind:checkBoxLabel="room.name"
                                    v-on:item-selected="restrictions[room.id] = $event" 
                                    :cbmodel="restrictions[room.id]"
                                />
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
            <div class="warning-row" v-if="showCertAlert">
                <div class="missing-warning">{{'No Certifications' | translate | allCaps}}</div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.NO_CERTIFICATIONS'"
                    :positiontop= "'-14px'"
                />
            </div>
            <InfoButton
                    v-if="locations.length>1"
                    :infocontent="'INFO_CONTENT.SELECT_LOCATION'"
                    :positiontop="'-14px'"
                    :positionleft="'72px'"
                    :buttoncolor="'white'"
                    :iconfontsize="'18px'"
                />
            <div class='missing-selection-message' v-if="showError && !calcRestrictLocations().length">*{{'You need to select at least one' | translate}} {{'Location' | translate}}</div>
        </div>
        

        <div class="service-existing-container" v-if="!showCertAlert">
            <div class="service-title" >{{'Certifications Required' | translate}}</div> 
            <div >
                <div class="checkbox-item" v-for="(cert, index) in allCertifications" v-bind:key="'cert' + index">
                    <CheckBox 
                        v-bind:checkBoxLabel="cert.name"
                        v-on:item-selected="certifications[cert.id] = $event" 
                        :cbmodel="certifications[cert.id]"
                    />
                </div>
            </div>
        </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" >
        </ButtonBar>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Service' | translate}} {{successMessage | translate}}</div>
        </div> 
    </div>
        <div class="service-existing-container">
            <div class="service-title">{{'Existing Services' | translate}}</div> 
            <div class="service-type-row" v-for="(type,index) in allServices" :key="index">
                <div class="service-type-item">{{type.name}}</div>
                <div class="service-type-item">{{type.type}}</div>
                <div class="service-type-item">{{type.duration}} {{'Min' | translate}}</div>
                <div class="service-type-item">${{getItemPrice(type)}}</div>
                <div class="control-group-item color-blue" 
                    @click='modifyService(type)'>
                    <i class="fas fa-pen"></i>
                </div>
            </div>
        </div>
    </div>
        
    
</template>

<script>
import store from '../store/store.js'
import CheckBox from './utility/CheckBox'
import InfoButton from './utility/InfoButton'
import InputCurrency from './utility/InputCurrency'
import ButtonBar from './utility/ButtonBar'
import Constants from '../utilities/constants'
import SearchAhead from './utility/SearchAhead'
import Dropdown from './utility/Dropdown'
import QuickList from './utility/QuickList'
import RadioButton from './utility/RadioButton'
import QLData from '../data/quicklist.json'
// import EventBus from '../utilities/eventBus.js'

export default {
    name: 'ServicesForm',
    data: function() {
        return {
            service: {},
            allServices: [],
            allServiceTypes: [],
            store,
            allRooms: [],
            allLocations: [],
            locations: [],
            restrictions: {},
            certifications: {},
            roomRestrictions: [],
            allCertifications: [],
            certificationRestrictions: [],
            showLocations: false,
            showSuccess: false,
            showError: false,
            showCertAlert: false,
            servicePrice: '',
            serviceCost: '',
            buttonList:[],
            buttonClicked: '',
            selectedLocation: {},
            selectLocation: '',
            serviceName: '',
            serviceTypes: [],
            selectedService: {},
            selectedType: {},
            pageTitle: 'Add Service',
            successMessage: 'Successfully Added',
            trackInventory: false,
            selectedSupply:{},
            selectedSupplyName: '',
            selectedSessionHours: {name:0},
            selectedSessionMinutes: {name:30},
            sessionHours: Constants.sessionHours,
            officeMinutes: Constants.officeMinutes,
            duration: 30,
            standardEntry: true,
            rbWatcher: {},
            QuickListSelected:[]
        }
    },
    components: {
        CheckBox,
        InfoButton,
        InputCurrency,
        ButtonBar,
        SearchAhead,
        Dropdown,
        QuickList,
        RadioButton
    },
    methods: {
        setServiceTypes() {
            Object.keys(QLData).forEach(key => {             
                this.serviceTypes.push({name:key})
            })
        },
        getItemPrice(item) {
            item.estimatedusage = !item.estimatedusage ? 1 : item.estimatedusage
            return Number(item.estimatedusage) * item.retailprice
        }, 
        verifyForm () {
            window.console.log('VERIFY FORM', this.restrictions, this.allRooms, this.service)
            this.showError = false
            if (!this.service.name ||  this.service.retailprice < 0 || !this.calcRestrictLocations().length) {
                window.console.log('ERROR FOUND',this.service.retailprice, this.locations, !this.locations.length)
                this.showError = true
            } else {
                this.submitService()
            }
        },
        submitService() {
            // ADD CERTIFICATIONS, ROOM RESTRICTIONS, LOCATIONS
            this.service.roomrestricted = this.calcRoomRestrictions()
            this.service.certrestricted = this.calcCertRestrictions()
            this.service.locations = this.calcRestrictLocations()
            this.service.duration = this.selectedSessionHours && this.selectedSessionMinutes ? (Number(this.selectedSessionHours.name) * 60) + Number(this.selectedSessionMinutes.name) : 30
            this.addServiceDB(this.service)
        },
        addServiceDB(data) {
            this.$store.dispatch('addService',data).then(response => {
                window.console.log ('ADD SERVICE RESPONSE', response)
                if (response.result === 'SUCCESS') {
                    this.showSuccess = true
                    this.getServices()
                    this.clearForm()
                    this.selectedType = {}
                }
            })
        },
        addQuickListServices() {
            this.QuickListSelected.forEach(service => {               
                const data = {
                    quicklistref: service.id,
                    retailprice: Number(service.price),
                    duration: service.len,
                    name: service.name,
                    certrestricted: [],
                    roomrestricted: [],
                    locations: this.setQuickListLocations(),
                    type: service.type
                }
                this.addServiceDB(data)
            })
            // name,retailprice,locations,certrestricted,roomrestricted,businessid,userid,supplyid,trackinventory,cost,supplyname,estimatedusage,duration
        },
        setQuickListLocations() {
            let locations = []
            this.$store.getters.allLocations.forEach(location => {
                locations.push(location.id)
            })
            return locations
        },
        clearForm() {
            this.service = {}
            this.trackInventory = false
            this.servicePrice = ''
            this.serviceCost = ''
            this.locations = []
        },
        updateService() {
            this.service.roomrestricted = this.calcRoomRestrictions()
            this.service.certrestricted = this.calcCertRestrictions()
            this.service.locations = this.calcRestrictLocations()
            this.service.duration = this.selectedSessionHours && this.selectedSessionMinutes ? (Number(this.selectedSessionHours.name) * 60) + Number(this.selectedSessionMinutes.name) : 30
            this.$store.dispatch('updateService',this.service).then(response => {
                window.console.log ('ADD SERVICE RESPONSE', response)
                if (response.result === 'SUCCESS') {
                    this.showSuccess = true
                }
            })
        },
        calcRoomRestrictions(){
            let allRestrictions = []
            Object.keys(this.restrictions).forEach(item => {
                if (this.restrictions[item]) {
                    allRestrictions.push(item)
                }
            })
            return allRestrictions            
        },
        calcCertRestrictions(){
            let allCerts = []
            
            Object.keys(this.certifications).forEach(item => {
                if (this.certifications[item]) {
                    allCerts.push(item)
                }
            })
            return allCerts            
        },
        calcRestrictLocations() {
            if (this.allLocations && this.allLocations.length === 1) {
                return [this.allLocations[0].id]
            }
            let allRestrictLocations = []
            Object.keys(this.locations).forEach(item => {
                if (this.locations[item]) {
                    allRestrictLocations.push(item)
                }
            })
            return allRestrictLocations
        },
        getServices() {
            this.$store.dispatch('getServicesAll').then( () => {
                this.allServices = this.$store.getters.allServices
            })
        },
        getRooms() {
            this.$store.dispatch('getRoomsAll').then( response => {
                window.console.log('GOT ROOMS ALL', response)
                this.allRooms = response.data
                if (!this.allRooms.length) {
                    this.buttonList = ['Submit','Next']
                } else {
                     this.buttonList = ['Submit', 'Cancel']
                }
            })
        },
        getCertifications() {
            this.$store.dispatch('getCertifications',{id:this.$store.getters.user.id}).then( response => {
                window.console.log('GOT CVERTS', response)
                if (!response.data ||!response.data.length) {
                    this.showCertAlert = true;
                }
                this.allCertifications = response.data
            })
        },
        getLocations() {
            this.$store.dispatch('getLocations').then(response => {
                window.console.log('GOT LOCATIONS', response)
                this.allLocations = response.data
                this.locations = {}
                this.allLocations.forEach(loc => {
                    this.locations[loc.id] = false
                })
                if (this.allLocations.length > 1) {
                    window.console.log(this.locations)
                    this.showLocations = true
                } 
            })

        },
        checkRes(){
            window.console.log('CK RES', this.restrictions, Object.keys(this.restrictions))
            window.console.log('CK CERTS', this.certifications, Object.keys(this.certifications))
        },
        getRestrictedRooms(id) {           
            const restrictedRooms =  this.allRooms.filter(room => {
                if (room.locationid == id) {
                    return room
                }
            })
            return restrictedRooms
        },
        setServiceDuration() {
            window.console.log('SERV DUR', this.selectedService.duration)
            const serviceHours = Math.floor(this.selectedService.duration/60)
            const serviceMinutes = serviceHours > 0 ? this.selectedService.duration - (60 * serviceHours) : this.selectedService.duration
            let selectedHour = Constants.sessionHours[0]
            let selectedMinute = Constants.officeMinutes[2]
            Constants.sessionHours.forEach(hour => {
                if (hour.name == serviceHours){
                    selectedHour = hour
                }
            })
            Constants.officeMinutes.forEach(minute => {
                if (Number(minute.name) == serviceMinutes) {
                    selectedMinute = minute
                }
            })
            this.selectedSessionHours = selectedHour
            this.selectedSessionMinutes = selectedMinute

        },
        modifyService(service) {
            window.console.log('MODIFY SERVICE -------', service)
            this.selectedType = null
            this.standardEntry = true
            this.locations = []
            this.restrictions = []
            service.locations.forEach(loc => {
                this.locations[loc] = true
            })
            service.roomrestricted.forEach(room => {
                this.restrictions[room] = true
            })
            service.certrestricted.forEach(cert => {
                this.certifications[cert] = true
            })
            this.statusUpdate = true
            this.serviceName = service.name
            this.pageTitle = 'Update Service'
            this.buttonList = ['Update','Delete','Cancel']
            this.selectedService = service
            this.service = service
            this.selectedType = service.type ? {name: service.type} : null
            window.console.log('MODIFY',this.service, this.service.type)
            this.amountEntered = service.retailprice
            this.successMessage = 'Successfully Updated'
            this.selectedSupplyName = service.supplyname
            this.serviceCost = service.cost
            this.servicePrice = service.retailprice
            this.setServiceDuration()
        },
        deleteService() {
            window.console.log('ADD FUNCTION TO DELETE SERVICE')
        }
    },
    mounted() {
        this.setServiceTypes()
        this.getServices()
        this.getCertifications()
        this.getRooms()
        this.getLocations()
        
    },
    watch: {
        showSuccess() {
            if (this.showSuccess) {
                setTimeout(() => {
                    this.showSuccess = false
                },3000)
            }
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.verifyForm()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
            if (this.buttonClicked.name === 'Update') {
                this.updateService()
            }
            if (this.buttonClicked.name === 'Delete') {
                this.deleteService()
            }
            if (this.buttonClicked.name === 'Next') {
                this.$router.push('AddRoom')
            }
        },
        selectedLocation() {
            window.console.log('SELECteD Location', this.selectedLocation)
            this.getRooms()
        },
        selectLocation() {
            window.console.log('SELECTTY Location', this.selectLocation, this.locations)
            this.selectLocation=''
            this.showLocations=false
            this.showLocations=true
        },
        selectedSupply() {           
            // this.selectedSupplyName = this.selectedSupply.name
            this.service.supplyid = this.selectedSupply.id
            this.service.supplyname = this.selectedSupply.name
             window.console.log('SELECTED PRODUCT', this.selectedSupply, this.selectedSupplyName,'SERVICE', this.service)
        },
        rbWatcher(){
            
            const watchArray = this.rbWatcher.split('~')
    window.console.log('RB SELECTED', this.rbWatcher, watchArray[0],watchArray[1])
            if (watchArray[0] === 'standard'){
                if (Boolean(watchArray[1]) === true) {
                    this.standardEntry = true
                } else {
                    this.standardEntry = false
                }
            } else {
                window.console.log('QList', this.rbWatcher, watchArray[0],watchArray[1])
                if (Boolean(watchArray[1]) === true) {
                    window.console.log('Q True SET FALSE')
                    this.standardEntry = false
                } else {
                    window.console.log('Q FALSE SET TRUE')
                    this.standardEntry = true
                }
            }
            window.console.log('RB STANDARD AFTER', this.standardEntry)
        },
        QuickListSelected() {
            this.addQuickListServices()
        },
        selectedType() {           
            this.service.type = this.selectedType ? this.selectedType.name : null
            window.console.log('UPDATED SELECTDtYPE', this.selectedType,this.service.type)
        }
    }
}

</script>

<style scoped>
.checkbox-item{
    display: inline-block;
    padding: 10px 7px 0 7px;
}
.missing-warning{
    background-color: red;
    display: inline;
    font-size: 12px;
    color: white;
    font-weight: 600;
    padding: 2px 6px;
    
}
.warning-row{
    position: relative;
    margin: 15px;
}
.fa-info-circle{
    top: 19px;
}

.title-info{
    position: relative;
}
.info-holding-container{
    position: relative;
}
.info-button-container{
    top: -18px !important;
}
.form-dropdown-item{
    display: inline-block;
    position: absolute;
}
.form-location-row{
    height: 35px;
    text-align: left;
    padding-top: 10px;
}
.checkbox-item-row {
    padding-top: 5px;
}
.restriction-title{
    font-weight: 600;
}
.search-ahead-supplies{
    display: inline-block;
}
.service-type-item{
    display: inline-block;
    width: 20%;
    text-align: right;
    font-size: 14px;
    vertical-align: top;
}
.selection-button-row{
    margin-bottom: 15px;
}
.input-wide{
    width: 250px;
}
</style>