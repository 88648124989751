import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Global from '../utilities/globals'
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
    storage: window.localStorage
  })

// const auth = {
//     headers: {Authorization:'Bearer xxx'  } 
// }
// axios.defaults.headers.common['Authentication'] = 'Bearer ABCD'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        allServices: [],
        allServiceTypes: [],
        allStaff: [],
        allRooms: [],
        allProducts: [],
        allSupplies: [],
        allPromotions: [],
        allLocations: [],
        allProductTypes: [],
        selectedDateData: {},
        bookingInfo: {},
        allCertifications: [],
        startTime: '',
        clientImages: [],
        user: {},
        token: 'ABC',
        key:'123',
        currentLocation: {},
        selectedDisplayType:'DAY',
        selectedScheduleType: 'STAFF',
        selectedLanguage: 'English',
        selectedStaff: {},
        selectedClient: {},
        confirmCode: {},
        authtoken: null,
        isAuthenticated: false,
        headers: {
            'Authorization': 'Bearer ABCD' ,
            'Feature-Policy': "camera 'self'"
        },
        newBusiness: false,
        setupProcess: {},
        newBusinessAdmin: {},
        newClient: {},
        showLoader: 'hello',
        spinner: true,
    },
    mutations: {
        setServices(state, value) {
            state.allServices = value
            window.console.log('SET SERVICES', state.allServices)
        },
        setProducts(state, value) {
            state.allProducts = value
            window.console.log('SET PRODUCTS', state.allProducts)
        },
        setSupplies(state, value) {
            state.allSupplies = value
            window.console.log('SET SUPPLIES', state.allSupplies)
        },
        setProductTypes(state, value) {
            state.allProductTypes = value
            window.console.log('SET PRODUCT TYPES', state.allProductTypes)
        },
        setServiceTypes(state, value) {
            window.console.log('SET SERVICE TYPES', state.allServiceTypes)
            state.allServiceTypes = value
        },
        setStaff(state, value) {
            state.allStaff = value
        },
        setDate(state, value) {
            window.console.log('SET DATE', value)
            state.selectedDateData = value;
        },
        setBookingInfo(state, value) {
            state.bookingInfo = value
            window.console.log('SET BOOKING', state.bookingInfo)
        },
        setCertifications(state, value) {
            state.allCertifications = value
        },
        setStartTime(state, value) {
            state.startTime = value
        },
        setUser(state,value) {
            window.console.log('SET USER', value)
            state.user = value
        },
        setCurrentLocation(state,value) {
            window.console.log('SET CURRENT LOC STORE', value)
            state.currentLocation = value
        },
        setLocations(state,value) {
            state.allLocations = value
        },
        setDisplayType(state,value) {
            window.console.log('SET DISPLAY TYPE', value)
            state.selectedDisplayType = value
        },
        setScheduleType(state,value) {
            state.selectedScheduleType = value
        },
        setAuthToken(state,value) {
            window.console.log('SET AUTH TOKEN', value)
            state.authtoken = value
            state.isAuthenticated = true
            this.setHeaders(state,value)
        },
        setHeaders(state,value) {
            window.console.log('SET HEADERS', value)
            state.headers = {Authentication: `Bearer ${value}`}
        },
        setLanguage(state,value) {
            window.console.log('SET LANGUAGE', value)
            state.selectedLanguage = value
        },
        setNewClient(state,value) {
            window.console.log('SET NEW CLIENT', value)
            state.newClient = value
        },
        setSelectedStaff(state,value) {
            state.selectedStaff = value
        },
        setShowLoader(state,payload) {
            console.log('SET LOADER', payload)
            state.spinner = payload
        },
        setSelectedClient(state,payload) {
            console.log('SET CLIENT',payload)
            state.selectedClient = payload
        },
        
    },
    actions: {
                
        getServices() {
            const vm = this
            const url = Global.nodeURL + `services?id=${vm.state.currentLocation.id}` 

            return axios({
                    method: 'get',
                    url: url
                })
                .then(function(response) {
                    vm.state.allServices = response.data
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });

        },
        getServicesAll() {
            const vm = this
            const url = Global.nodeURL + `servicesall?id=${vm.state.user.businessid}` 

            return axios({
                    method: 'get',
                    url: url
                })
                .then(function(response) {
                    vm.state.allServices = response.data
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getServiceId(context, data) {
            const url = Global.nodeURL + 'serviceid?id=' + data.id;
            window.console.log('state service store', url, data)
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SERVICE', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET SERVICE ID ERROR', error)
                })
        },
        addService(context, data) {
            const url = Global.nodeURL + 'services'
            const vm = this
            data.businessid = vm.state.user.businessid
            data.userid = vm.state.user.id
            window.console.log('state clients store', url, context, 'CLIENT INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ADDED SERVICE RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateService(context, data) {
            const url = Global.nodeURL + 'servicesupdate'
            window.console.log('UPDATE SERVICE', url,  'SERVICE INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE SERVICE RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addServiceType(context, data) {
            //context
            const url = Global.nodeURL + 'servicetypes'
            window.console.log('add servicetype', url,'data', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getServiceTypes(state) {
            //context
            const url = Global.nodeURL + 'servicetypes'
            window.console.log('get service types request', url)
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('SERVICE TYPES', response.data);
                    state.commit('setServiceTypes', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getServiceSearch(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `servicesearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH SERVICES DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SEARCH SERVICES', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET SERVICE SEARCH ERROR', error)
                })
        },
        addClient(context, data) {
            const url = Global.nodeURL + 'addclient'
            window.console.log('state clients store', url, context, 'CLIENT INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE CLIENTS', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateClient(context, data) {
            const url = Global.nodeURL + 'clientsupdate'
            // context
            // let vm = this;
            window.console.log('store UPDATE CLIENT', url, 'STAFF UP INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('CLIENT UPDATED', response.data, data);
                    if (response.data.result === 'SUCCESS') {
                        store.dispatch('getStaff')
                    }
                    window.console.log('STAFF UPDATED"')
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        findClientsLastName(context, data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `clientsearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH CLIENTS DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CLIENT ERROR', error)
                })
        },
        findClientsFirstName(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `clientsearchfirst?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH CLIENTS FIRST DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CLIENT ERROR', error)
                })
        },
        getClientId(context, data) {
            const url = Global.nodeURL + 'clientid?id=' + data.id;
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENT', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CLIENT ERROR', error)
                })
        },
        sendTextClient(context,data) {
            const url = Global.nodeURL + 'sendtext'
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('SENT TEXT CLIENT', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('SENT TEXT CLIENT ERROR', error)
                })
        },
        sendEmailClient(context,data) {
            const url = Global.nodeURL + 'sendemail'
            data.businessemail = this.state.currentLocation.email
            window.console.log('EMAIL', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('SENT EMAIL CLIENT', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('SENT EMAIL CLIENT ERROR', error)
                })
        },
        sendPictureClient(context,data) {
            const url = Global.nodeURL + 'clientimage'
            data.businessemail = this.state.currentLocation.email
            data.businessname = this.state.currentLocation.businessname
            window.console.log('EMAIL PICTURE', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('SENT PICTURE CLIENT RESULTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('SENT PICTURE CLIENT ERROR', error)
                })
        },
        sendMedPictureClient(context,data) {
            const url = Global.nodeURL + 'clientmedimage'
            data.businessemail = this.state.currentLocation.email
            data.businessname = this.state.currentLocation.businessname
            data.note = Global.encryptData(data.note)
            window.console.log('EMAIL PICTURE', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('SENT PICTURE CLIENT RESULTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('SENT PICTURE CLIENT ERROR', error)
                })
        },
        addStaff(context, data) {
            const url = Global.nodeURL + 'staff'
            data.authority = 'staff'
            data.businessname = this.state.currentLocation.businessname
            // NEED TO ADD DEFAULT PASSWORD
            window.console.log('state staff store', url, context, 'STAFF INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('RESULT ADD STAFF', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addStaffSchedule(context, data) {
            const url = Global.nodeURL + 'staffschedule'
            let vm = this
            window.console.log('state staffschedule store', url, context, 'STAFF INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE STAFF SCHEDULE', response.data);
                    // state.commit('setServices',response.data);
                    vm.state.allStaff = response.data;
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getScheduleStaff(context,data) {
            // context
            const url = Global.nodeURL + `staffschedule?id=${data.id}`
            window.console.log('GET STAFF SCHEDULE', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND SCHEDULE', response.data);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET STAFF SCHEDULE', error)
                })
        },
        getStaffScheduleWeek(context,data) {
            const url = Global.nodeURL + `scheduleweek`
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('FOUND SCHEDULES WEEK', response.data);
                return response.data
            })
            .catch(function(error) {
                window.console.log('GET STAFF SCHEDULES WEEK', error)
            })
        },
        getStaff() {                      
            let vm = this;
            //context  

            if (!vm.state.user.businessid) {
                return {result: 'FAIL NO ID!'}
            }
            const url = Global.nodeURL + `staff?id=${Number(vm.state.currentLocation.id)}` 
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    vm.state.allStaff = response.data.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET STAFF ERROR', error)
                })
        },
        getAllStaff() {                      
            let vm = this;
            //context  

            if (!vm.state.user.businessid) {
                return {result: 'FAIL NO ID!'}
            }
            const url = Global.nodeURL + `staffall?id=${Number(vm.state.user.businessid)}` 
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('GOT STAFF DB', vm.state.currentLocation.id, response.data, 'SCHEDULE', response.data[0].schedule);
                    vm.state.allStaff = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET STAFF ERROR', error)
                })
        },
        getStaffId() {                      
            let vm = this;
            //context              
            const userid = vm.state.user.id
            const url = Global.nodeURL + `staffid?id=${userid}` 
            window.console.log('GET STAFF ID',userid, url)
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('GOT STAFF ID', response);
                    // vm.state.allStaff = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET STAFF ERROR', error)
                })
        },
        getStaffById(context,data) {                      
            //context              
            const userid = data.id
            const url = Global.nodeURL + `staffid?id=${userid}` 
            window.console.log('GET STAFF ID',userid, url)
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('GOT STAFF BY ID', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET STAFF ERROR', error)
                })
        },
        updateStaff(context, data) {
            const url = Global.nodeURL + 'staffid'
            // context
            let vm = this;
            window.console.log('store UPDATE STAFF', url, 'STAFF UP INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STAFF UPDATED', response.data, data);
                    if (response.data.result === 'SUCCESS') {
                        store.dispatch('getStaff')
                    }
                    window.console.log('STAFF UPDATED"',vm.state.allStaff)
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateStaffOrder(context, data) {
            const url = Global.nodeURL + 'stafforder'
            // context
            let vm = this;
            window.console.log('store UPDATE STAFF ORDER', url, 'STAFF ORDER INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STAFF UPDATED', response.data, data);
                    if (response.data.result === 'SUCCESS') {
                        store.dispatch('getStaff')
                    }
                    window.console.log('STAFF UPDATED"',vm.state.allStaff)
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addStaffVacation(context, data) {
            const url = Global.nodeURL + 'staffvacation'
            window.console.log('state staffschedule store', url, context, 'STAFF INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STAFF VACATION RESPONSE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getStaffVacations(context,data) {
            const url = Global.nodeURL + `staffvacation?id=${data.id}`
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('GET STAFF VACATIONS ', response);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                })
        },
        updateTaxes(context, data) {
            const url = Global.nodeURL + 'bustaxes'
            // context
            window.console.log('store UPDATE TAXES', url, 'TAXES INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('TAXES UPDATED', response.data, data);
                    if (response.data.result === 'SUCCESS') {
                        // store.dispatch('getStaff')
                    }
                    // window.console.log('STAFF UPDATED"',vm.state.allStaff)
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addRoom(context, data) {
            const url = Global.nodeURL + 'rooms'
            let vm = this
            // data.locationid = vm.state.currentLocation.id
            data.businessid = vm.state.user.businessid
            data.userid = vm.state.user.id
            window.console.log('state roomsstore', url, context, 'ROOMS INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE ROOMS', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateRoom(context, data) {
            const url = Global.nodeURL + 'roomsupdate'
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ROOM UPDATED', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        deleteRoom(context, data) {
            window.console.log('DELETEROOM', data)
            const url = Global.nodeURL + 'roomdelete'
            return axios({
                method: 'put',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('ROOM DELETED', response.data);               
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        getRooms() {
            // context
            let vm = this;         
            const url = Global.nodeURL + `rooms?id=${vm.state.currentLocation.id}` 
            
            window.console.log('GET ROOMS DATA');
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND ROOMS DB', response.data.data);
                    vm.state.allRooms = response.data.data;

                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET ROOMS ERROR', error)
                })
        },
        getRoomsAll() {
            // context
            let vm = this;         
            const url = Global.nodeURL + `roomsall?id=${vm.state.user.businessid}` 
            
            window.console.log('GET ROOMS DATA', vm.state.user.businessid);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND ROOMS DB', response.data.data);
                    vm.state.allRooms = response.data.data;

                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET ROOMS ERROR', error)
                })
        },
        getRoomsLocation(context,id) {
            // context
            let vm = this;         
            const url = Global.nodeURL + `rooms?id=${id}` 
            
            window.console.log('GET ROOMS DATA',id);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND ROOMS DB', response.data);
                    vm.state.allRooms = response.data;

                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET ROOMS ERROR', error)
                })
        },
        addBusiness(context, data) {
            const vm = this
            const url = Global.nodeURL + 'business'            
            data.utcoffset = new Date().getTimezoneOffset()
            data.contactfirstname = vm.state.user.firstname
            data.contactlastname = vm.state.user.lastname
            data.contactemail = vm.state.user.email
            data.contactphone = vm.state.user.phone
            data.password = vm.state.user.password
            window.console.log('store BUSINESS', url, data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE BUSINESS', response.data);
                    vm.state.user.businessid = response.data.id
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateBusinessHours(context, data) {
            const url = Global.nodeURL + 'hours'
            // context
            window.console.log('store UPDATE HOURS', url, 'HOURS UP INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('HOURS UPDATED', response.data, data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateLocationSchedule(context, data) {
            const url = Global.nodeURL + 'locationhours'
            axios.defaults.headers.common['Authorization'] = this.state.authtoken
            // context
            window.console.log('store UPDATE HOURS', url, 'HOURS UP INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('HOURS UPDATED', response.data, data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addBooking(context, data) {
            const vm = this
            data.locationid = vm.state.currentLocation.id
            data.businessid = vm.state.user.businessid
            data.userid = vm.state.user.id
            const url = Global.nodeURL + 'bookings'
            window.console.log('store BOOKING', url, data)
            window.console.log('ADD NEW BOOKING')
            axios.defaults.headers.common['Authorization'] = vm.state.authtoken
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE BOOKINGS', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateBooking(context, data) {
            const url = Global.nodeURL + 'bookings'
            let vm = this;
            window.console.log('UPDATE BOOKING')
            axios.defaults.headers.common['Authorization'] = vm.state.authtoken
            // vm.getBookingsDay(data.month, data.day, data.year).then(function(data){
            //     window.console.log('GOT DAY BOOKINGS', data)
            // })
            window.console.log('store UPDATE BOOKING', url, context, 'BOOKING INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE BOOKINGS', response.data);
                    if (response.result == 'SUCCESS') {
                        vm.state.bookingInfo = data
                    }
                    return response;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addClientToBooking(context, client) {
            //context
            let vm = this
            window.console.log('ADDING TO BOOKING', client, client.id, client.name)
            vm.state.bookingInfo.clientid = client.id;
            vm.state.bookingInfo.clientname = client.name;
            window.console.log('AFTER', vm.state.bookingInfo, client)
        },
        updateNote(context, bookingInfo) {

            const url = Global.nodeURL + 'notesupdate'
            axios.defaults.headers.common['Authorization'] = this.state.authtoken
            window.console.log('UPDATE NOTE', url, bookingInfo, context)
            const data = {
                id: bookingInfo.id,
                note: bookingInfo.note
            }
            return axios({
                    method: 'put',
                    url: url,
                    data: data,
                })
                .then(function(response) {
                    window.console.log('STORE UPDATE NOTE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getBookingsDay(context, data) {
            let vm = this;
            axios.defaults.headers.common['Authorization'] = vm.state.authtoken
            const url = Global.nodeURL + `bookingsday?month=${data.month}&day=${data.day}&year=${data.year}&id=${vm.state.currentLocation.id}`;
            
            window.console.log(context);
            window.console.log('GET BOOKINGS DAY', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    // window.console.log('FOUND BOOKINGS', response.data);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET ROOMS ERROR', error)
                })
        },
        getBookingsWeek(context, data) {
            let vm = this;
            window.console.log('GET BOOKINGS WEEK STORE', data,vm.state.authtoken.substr(0,6),vm.state.user.token.substr(0,6));
            
            const url = Global.nodeURL + `bookingsweek?startdate=${data.startdate}&enddate=${data.enddate}&id=${vm.state.currentLocation.id}`
            axios.defaults.headers.common['Authorization'] = vm.state.authtoken
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND BOOKINGS WEEK RESPONSE', response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET BOOKINGS WEEK ERROR', error)
                })
        },
        getBookingsByClient(context, data) {
            const url = Global.nodeURL + `bookingsclient?id=${data.id}`;
            axios.defaults.headers.common['Authorization'] = this.state.authtoken
            window.console.log('GET BOOKINGS CLIENT', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND BOOKINGS CLIENT', response.data);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET ROOMS ERROR', error)
                })
        },
        getBookingsDates(context, data) {
            let vm = this;
            const url = Global.nodeURL + `bookingsdates?startdate=${data.startdate}&enddate=${data.enddate}&id=${vm.state.currentLocation.businessid}`
            axios.defaults.headers.common['Authorization'] = vm.state.authtoken
            window.console.log(context);
            window.console.log('GET BOOKINGS DATES');
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND BOOKINGS DATES', response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET BOOKINGS WEEK ERROR', error)
                })
        },
        checkInBooking(context, data) {
            const url = Global.nodeURL + `bookingstatus`;
            window.console.log('GET BOOKINGS STATUS', data);
            axios.defaults.headers.common['Authorization'] = this.state.authtoken
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('Booking Status', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('UPDATE STATUS ERROR', error)
                })
        },
        cancelBooking(context, data) {
            const url = Global.nodeURL + `bookingcancel`;
            window.console.log('CANCEL BOOKING', data);
            axios.defaults.headers.common['Authorization'] = this.state.authtoken
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('CANCEL BOOKING RESPONSE', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('CANCEL BOOKING ERROR', error)
                })
        },
        addCertification(context, data) {
            const url = Global.nodeURL + 'certifications'

            window.console.log('store CERTIFICATION', url, context, 'CERT INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE CERTIF', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateCertification(context, data) {
            const url = Global.nodeURL + 'certificationsupdate'

            window.console.log('store CERTIFICATION', url, context, 'CERT INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE CERTIF', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getCertifications(context,data) {
            let vm = this;
            const url = Global.nodeURL + `certifications?id=${vm.state.user.businessid}`;
            window.console.log('GET CERTIFICATION DATA', vm.state.user.businessid);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND CERTS', response.data);
                    vm.state.allCertifications = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CERTS ERROR', error)
                })
        },
        addProduct(context, data) {
            const url = Global.nodeURL + 'products'

            window.console.log('store Products', url, context, 'CERT INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE PRODUCT', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getProducts() {            
            const vm = this
            window.console.log('GET PRODUCTS', vm.state.allProducts)
           
            
            const url = Global.nodeURL + `products?id=${vm.state.user.businessid}`
            window.console.log('GET PRODUCT DATA', url);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND PRODUCTS', response.data);
                    vm.state.allProducts = response.data
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET PRODUCTS ERROR', error)
                })
        },
        getProductsDistributor(context,data) {            
            window.console.log('GET PRODUCTS DIST', data)
                       
            const url = Global.nodeURL + `productsdistributor?id=${data.id}`
            window.console.log('GET PRODUCT DATA', url);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND DIST PRODUCTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET DIST PRODUCTS ERROR', error)
                })
        },
        updateProduct(context, data) {
            const url = Global.nodeURL + 'productsupdate'
            window.console.log('UPDATE PRODUCT', url,  'PRODUCT INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE PRODUCT RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getProductTypes() {            
            const vm = this
            window.console.log('GET PRODUCT TYPES', vm.state.allProductTypes)
            if (vm.state.allProductTypes.length > 0) {
                return vm.state.allProductTypes
            } else {
                window.console.log('GET PRODUCT TYPE DATA');
                const url = Global.nodeURL + `producttypes?type=${vm.state.user.businesstype}`;
                return axios({
                        method: 'get',
                        url: url,
                    })
                    .then(function(response) {
                        window.console.log('FOUND PRODUCT TYPES', response.data);
                        vm.state.allProductTypes = response.data
                        return response.data
                    })
                    .catch(function(error) {
                        window.console.log('GET PRODUCT TYPES ERROR', error)
                    })
            }
        },
        getProductsSearch(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `productsearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH PRODUCTS DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SEARCH PRODUCTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET PRODUCT SEARCH ERROR', error)
                })
        },
        getNotesClient(context, data) {
            const url = Global.nodeURL + `notesclient?id=${data.id}`;
            // let vm = this;
            window.console.log('GET NOTES CLIENT DATA');
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND NOTES', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET PRODUCTS ERROR', error)
                })
        },
        addInvoice(context, data) {
            const url = Global.nodeURL + 'invoices'
            data.businessid = this.state.user.businessid
            data.businessname = this.state.currentLocation.businessname
            data.phone = this.state.currentLocation.phone
            data.street = this.state.currentLocation.street
            data.address2 = this.state.currentLocation.address2
            data.city = this.state.currentLocation.city
            data.state = this.state.currentLocation.state
            data.zip = this.state.currentLocation.zip
            window.console.log('CURRENT LOCATION', this.state.currentLocation, data)
            window.console.log('store INVOICE', url, context, 'INV INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE INV', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateInvoice(context, data) {
            const url = Global.nodeURL + 'invoicesupdate'
            window.console.log('UPDATE INVOICE', url,  'INVOICE INFO')
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE INVOICE RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addInvoicePromotion(context, data) {
            const url = Global.nodeURL + 'invoicepromotion'
            data.businessid = this.state.user.businessid
            window.console.log('store INVOICE PROMOTION', url, context, 'INV INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE INV PROMOTION', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        checkForInvoice(context, data) {
            // context
            window.console.log('CHECK INVOICE', data)
            const url = Global.nodeURL + `invoicebooking?id=${data.id}`
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('INV RESULTS', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getInvoiceId(context, data) {
            const url = Global.nodeURL + `invoiceid?id=${data.id}`
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND INVOICE FOR ID',data.id, response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET INVOICES DATES ERROR', error)
                })
        },
        getInvoiceItemsDates(context, data) {
            let vm = this;
            const url = Global.nodeURL + `invoiceitemsdates?startdate=${data.startdate}&enddate=${data.enddate}&id=${vm.state.currentLocation.businessid}`

            window.console.log(context);
            window.console.log('GET INVOICES DATES');
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND INVOICES DATES', response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET INVOICES DATES ERROR', error)
                })
        },
        getInvoicesDates(context, data) {
            let vm = this;
            const url = Global.nodeURL + `invoicesdates?startdate=${data.startdate}&enddate=${data.enddate}&id=${vm.state.currentLocation.businessid}`

            window.console.log(context);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND INVOICES DATES', response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET INVOICES DATES ERROR', error)
                })
        },
        getInvoicesDay(context, data) {
            let vm = this;
            const url = Global.nodeURL + `invoicesday?invoiceday=${data.invoiceday}&id=${vm.state.currentLocation.businessid}`

            window.console.log(context);
            window.console.log('GET INVOICES DAY',data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND INVOICES DAY', response);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET INVOICES DAY ERROR', error)
                })
        },
        getInvoicesClient(context, data) {
            const url = Global.nodeURL + `invoicesclient?id=${data.id}`
            window.console.log('GET INVOICES CLIENT');
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND INVOICES CLIENT', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET INVOICES CLIENT ERROR', error)
                })
        },
        getBusinessTypes() {
            const url = Global.nodeURL + 'businesstypes';
            window.console.log('GET bus type DATA');
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND BT', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET BT ERROR', error)
                })
        },
        addPromotion(context, data) {
            const url = Global.nodeURL + 'promotions'
            data.businessid = this.state.user.businessid
            data.locationid = this.state.currentLocation.id
            //context
            window.console.log('store PROMOTION', url, 'PROMO INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE PROMO', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getPromotions() {
            const vm = this
            if (vm.state.allPromotions.length > 0) {
                return vm.state.allPromotions
            } else {
                const url = Global.nodeURL + `promotions?id=${this.state.user.businessid}`;
                window.console.log('GET promotions DATA');
                return axios({
                        method: 'get',
                        url: url,
                    })
                    .then(function(response) {
                        window.console.log('FOUND PROMOS', response.data);
                        return response.data
                    })
                    .catch(function(error) {
                        window.console.log('GET PROMOS ERROR', error)
                    })
            }
        },
        getClientPromotions(context, data) {
            const url = Global.nodeURL + `promotionpurchases?id=${data.id}`;
            window.console.log('GET CLIENT PROMOTION', data, data.id);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENT PROMOTION', response.data);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET PROMOTIONS ERROR', error)
                })
        },
        getClientCredits(context, data) {
            const url = Global.nodeURL + `credits?id=${data.id}`;
            window.console.log('GET CLIENT CREDIT', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENT CREDIT', response.data);
                    // vm.state.rooms = response.data;
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CREDIT ERROR', error)
                })
        },
        getPromotionSearch(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `promotionsearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH SERVICES DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SEARCH SERVICES', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET SERVICE SEARCH ERROR', error)
                })
        },
        getCreditSearch() {
            const data = [
                {
                    category: "certificate",
                    name: "General Credit",
                    retailprice: 1,
                    saleprice: 1,
                    discount: 0,
                    type: "generalcredit",
                    applyto: {products: true, services: true, promotions: true}
                },{
                    category: "certificate",
                    name: "Product Credit",
                    retailprice: 1,
                    saleprice: 1,
                    discount: 0,
                    type: "productcredit",
                    applyto: {products: true, services: false, promotions: false}
                },{
                    category: "certificate",
                    name: "Service Credit",
                    retailprice: 1,
                    saleprice: 1,
                    discount: 0,
                    type: "servicecredit",
                    applyto: {products: false, services: true, promotions: false}
                }

        ]
            return data
        },
        addSale(context, data) {
            const url = Global.nodeURL + 'sales'
            //context
            window.console.log('store SALES', url, 'SALES INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE SALES', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        saveImage(context, data) {
            const url = Global.nodeURL + 'images'
            // const url = Global.nodeURL + 'imageuploads'
            //context
            window.console.log('store IMAGE', url, 'IMAGE INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE IMAGE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        uploadImage(context, image) {
            const url = Global.nodeURL + 'imageuploads'
            //context
            let formData = new FormData()
            formData.append('file',image)
            const data={'image': image,'name':image.name}
            window.console.log('upload IMAGE', url, 'IMAGE UPLOAD DATA', image.name)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPLOADED IMAGE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        deleteImage(context, data) {
            const url = Global.nodeURL + 'imagesdelete'
            window.console.log('store DELETE IMAGE', url, context, 'DATA', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UP DELETE IMAGE', response.data);
                    
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getImages(context, data) {
            const vm = this
            const url = Global.nodeURL + `images?id=${data.id}`;
            window.console.log('GET CLIENT IMAGES', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENT IMAGES', response.data);
                    
                    vm.state.clientImages = response.data
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET IMAGES ERROR', error)
                })
        },
        updateImageNote(context,data) {
            const url = Global.nodeURL + `imagenote`;
            window.console.log('GET CLIENT IMAGES', data);
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE NOTE', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('UPDATE NOTE ERROR', error)
                })
        },
        addUser(context, data) {
            let vm = this
            const url = Global.nodeURL + 'users'
            //context
            data.businessid = vm.state.user.businessid
            window.console.log('store USER', url, 'ADD USER', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE USER', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        checkEmail(context, data) {
            // let vm = this
            const url = Global.nodeURL + 'email'
            //context
            
            window.console.log('store CK EMAIL', url, 'EMAIL', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('CK EMAIL RESULTS', response.data, response.data.length);
                    if (!response.data.length) {
                        return {result: 'NO MATCH'}
                    }
                    return {result: 'MATCH',data: response.data}
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        checkEmailClient(context, data) {
            data.businessid = this.state.user.businessid
            // let vm = this
            const url = Global.nodeURL + 'clientemail'
            //context
            
            window.console.log('store CK EMAIL CLIENT', url, 'EMAIL', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('CK EMAIL CLIENT RESULTS', response.data, response.data.length);
                    if (!response.data.length) {
                        return {result: 'NO MATCH'}
                    }
                    return {result: 'MATCH',data: response.data}
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        }, 
        checkEmailStaff(context, data) {
            // let vm = this
            const url = Global.nodeURL + 'staffemail'
            //context
            
            window.console.log('store CK EMAIL STAFF', url, 'EMAIL', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('CK EMAIL RESULTS', response.data, response.data.length);
                    if (!response.data.length) {
                        return {result: 'NO MATCH'}
                    }
                    return {result: 'MATCH',data: response.data}
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        userLogin(context, data) {
            let vm = this
            const url = Global.nodeURL + 'login'
            //context
            // Global.key =  vm.state.key
            // window.console.log('KEY', vm.state.key, Global.encryptData('test'))
            // window.console.log('TEST DECRYE', Global.decryptData( Global.encryptData('test')))
            // window.console.log('LOGIN',url, data, vm.state.headers)
            return axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.state.headers
                })
                .then(function(response) { 
                    window.console.log('LOGIN RESPONSE: ',response)
                    vm.state.authtoken = response.data.data.token
                    vm.state.isAuthenticated = true
                    vm.state.headers = {Authorization:'Bearer:'+response.data.data.token}
                    window.console.log ('GET HEADERS', vm.state.headers)
                    axios.defaults.headers.common['Authorization'] = response.data.data.token
                    vm.state.key = response.data.data.key
                    Global.key = response.data.data.key
                    // window.console.log('KEY AFTER LOGIN', Global.key,vm.state.key)
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('ERROR LOGGING IN',error);
                    window.console.log('ERROR RESPONSE',error.response);
                    window.console.log('ERROR REQUEST',error.request);
                    vm.state.isAuthenticated = false
                    return error
                });
        },
        updatePW(context, data) {
            const url = Global.nodeURL + 'userupdate'
            window.console.log('store UPDATE PASSWORD', url, context, 'PASS', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UP PASS RESULTS', response.data);
                    
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        forgotPassword(context,data) {
            const url = Global.nodeURL + 'staffconfirm';
            data.refnum = Math.round(Math.random() * 1000000).toString();
            const date = new Date()
            // this.state.confirmCode = {time: date.getTime(),code:data.refnum}
            const vm = this
            window.console.log('REF', data, url)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('RESPONSE FORGOT PASSWORD', response.data, response.data.data.refnum);
                    vm.state.user.phone = response.data.data.phone
                    vm.state.confirmCode = {time: date.getTime(),refnum:response.data.data.refnum}
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        changePassword(context, data) {
            const url = Global.nodeURL + 'chgpass';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE CHANGE PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        changeNewPassword(context, data) {
            const vm = this
            const url = Global.nodeURL + 'chgpassNew';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE CHANGE NEW BUSINESS PASSWORD', response.data);
                vm.state.user.password = data.newpassword
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        sendNewPassword(context,data) {
            const url = Global.nodeURL + 'sendPassword';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE SEND PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        resendPassword(context,data) {
            window.console.log('RESEND EMAIL', data)
            const url = Global.nodeURL + 'resendPassword';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE RESEND PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        newBusinessLogin(context,data) {
            const vm = this
            const url = Global.nodeURL + 'newbusinesslogin';
            data.refnum = Math.round(Math.random() * 1000000).toString();
            const date = new Date()
            vm.state.confirmCode = {time: date.getTime(),code:data.refnum}
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                if (response.data.result == 'SUCCESS') {
                    window.localStorage.clear()
                    vm.state.user = response.data.data
                }                
                return response.data;
            }) 
            .catch(function(error) {
                window.console.log(error);
            });
        },
        tempValidate() {            
            const vm = this
            const url = Global.nodeURL + 'newValidate';
            const data = {
                token: `axyt544z${Date.now()}zrx7`,
                time: Date.now(),
                ref: vm.state.user.businessid
            }
            window.console.log('VALIDATE', data)
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                if (response.data.result == 'SUCCESS') {                    
                    vm.state.user.token = data.token
                    vm.state.authtoken = data.token
                    vm.state.isAuthenticated = true
                } else {
                    vm.state.isAuthenticated = false
                }               
                return response.data;
            }) 
            .catch(function(error) {
                window.console.log(error);
            });
        },
        addLocation(context, data) {
            const url = Global.nodeURL + 'locations';
            data.businessname = this.state.currentLocation.businessname
            data.businessid = this.state.currentLocation.businessid
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ADD NEW LOCATION ', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getLocations(context, data) {
            const vm = this
            window.console.log('GET LOCATIONS', data, vm.state.user)
            
            const url = Global.nodeURL + `locations?id=${Number(vm.state.user.businessid)}`;
            window.console.log('state locations Global', url)
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE LOCATIONS', response.data);
                    vm.state.allLocations = response.data.data
                    window.console.log('STORED ALLLOCATIONS', vm.state.allLocations);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
            
        },
        updateLocation(context,data) {
            const url = Global.nodeURL + 'locationupdate'
            window.console.log('UPDATE LOCATION', url,  'LOCATION INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE location RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateTips(context,data) {
            const url = Global.nodeURL + 'tips'
            window.console.log('UPDATE TIPS', url,  'LOCATION INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE TIPS RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getActiveTips() {
            const url = Global.nodeURL + `tips?id=${this.state.user.businessid}`;
            window.console.log('GET BUINESS TIPS');
            return axios({
                    method: 'get',
                    url: url
                })
                .then(function(response) {
                    window.console.log('FOUND BUSINESS TIPS', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET TIPS ERROR', error)
                })
        },
        addSupply(context, data) {
            // context
            const url = Global.nodeURL + 'supplies'
            data.businessid = this.state.user.businessid
            window.console.log('store SUPPLIES', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE SUPPLIES', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getSupplies() {            
            const vm = this
            window.console.log('GET SUPPLIES', vm.state.allSupplies)
           
            
            const url = Global.nodeURL + `supplies?id=${vm.state.user.businessid}`
            window.console.log('GET SUPPLY DATA', url);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SUPPLIES', response.data);
                    vm.state.allProducts = response.data
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET SUPPLIES ERROR', error)
                })
        },
        getSuppliesSearch(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `suppliessearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH SUPPLIES DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SEARCH SUPPLIES', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET SUPPLY SEARCH ERROR', error)
                })
        },
        updateSupply(context, data) {
            const url = Global.nodeURL + 'suppliesupdate'
            window.console.log('UPDATE SUPPLY', url,  'SUPPLY INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE SUPPLY RESULT', response.data);
                    // state.commit('setServices',response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        addDistributor(context, data) {
            const url = Global.nodeURL + 'distributors'
            const vm = this
            data.businessid = vm.state.user.businessid
            data.userid = vm.state.user.id
            window.console.log('state distributors store', url, context, 'distributors INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ADDED distributors RESULT', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getDistributors() {
            const vm = this
            window.console.log('GET distributors', vm.state.user.businessid)
                const url = Global.nodeURL + `distributors?id=${vm.state.user.businessid}` 
                return axios({
                        method: 'get',
                        url: url
                    })
                    .then(function(response) {
                        window.console.log('STORE distributors', response.data);
                        return response.data;
                    })
                    .catch(function(error) {
                        window.console.log(error);
                    });
        },
        getDistributorId(context,data) {
            const url = Global.nodeURL + `distributorsid?id=${data.id}` 
            return axios({
                    method: 'get',
                    url: url
                })
                .then(function(response) {
                    window.console.log('STORE distributor', response.data);
                    return response.data[0];
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getDistributorsSearch(context,data) {
            const businessid = this.state.user.businessid
            const url = Global.nodeURL + `distributorssearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH DISTRIBUTORS DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND SEARCH DISTRIBUTORS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET DISTRIBUTORS SEARCH ERROR', error)
                })
        },  
        addOrder(context, data) {
            const url = Global.nodeURL + 'orders'
            const vm = this
            data.businessid = vm.state.user.businessid
            data.userid = vm.state.user.id
            data.locationid = vm.state.currentLocation.id
            window.console.log('orders store', url, context, 'orders INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ADDED orders RESULT', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getOrderId (context,data) {
            const url = Global.nodeURL + `orders?id=${data.id}`
            window.console.log('ORDER ID DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND ORDER ID', response.data);
                    return response.data[0]
                })
                .catch(function(error) {
                    window.console.log('GET DISTRIBUTORS SEARCH ERROR', error)
                })
        },
        getOrderItemsOpen() {
            const vm = this
            window.console.log('GET OrderItemsOpen', vm.state.user.businessid)
                const url = Global.nodeURL + `orderitemsopen?id=${vm.state.user.businessid}` 
                return axios({
                        method: 'get',
                        url: url
                    })
                    .then(function(response) {
                        window.console.log('STORE OrderItemsOpen', response.data);
                        return response.data;
                    })
                    .catch(function(error) {
                        window.console.log(error);
                    });
        },
        updateOrder(context, data) {
            const url = Global.nodeURL + 'ordersupdate'
            window.console.log('UPDATE ORDER', url,  'ORDER INFO', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE ORDER RESULT', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        updateItemInventory(context,data) {
            let url = Global.nodeURL 
            if (data.category == 'supply') {
                window.console.log('UPDATE SUPPLIES', data)
                url += 'suppliesinventory'
            }
            if (data.category == 'product') {
                window.console.log('UPDATE PRODUCT', data)
                url += 'productsinventory'
            }
           return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE INVENTORY RESULT', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        getDocument(context,name){
            let url = `../data/${name}.html`
            return axios({
                method: 'get',
                url: url,

            })
            .then(function(response) {
                window.console.log('GOT TEST DATA', response);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        updateRecord(context,data) {
            const url = Global.nodeURL + `updaterec`
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPDATE REC', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('UPDATE REC ERROR', error)
                })
        },
        addExpense(context,data) {
            const url = Global.nodeURL + `expense`
            data.businessid = this.state.user.businessid
            data.staffid = this.state.user.id
            data.locationid= this.state.currentLocation.id
            window.console.log('ADD EXPENSE STORE', data);
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('ADD EXPENSE RESULTS', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('ADD EXPENSE ERROR', error)
                })
        },
        getExpenses(context, data) {
            const url = Global.nodeURL + `expenses`
            data.businessid = this.state.user.businessid
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('GET EXPENSES', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET EXPENSES ERROR', error)
                })
        },
        getMedRec(context,data) {
            const url = Global.nodeURL + `history`
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('GET REC', response);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET REC ERROR', error)
                })
        },
        uploadInvoice(context,pdfData) {
            // Replace 'your-server-url' with the actual URL of your Node.js server endpoint to handle file upload.
            const url = Global.nodeURL + 'saveinvoice'
    
            // const formData = new FormData();
            // console.log('uploadInvoice',pdfBlob,formData)
            // formData.append('pdf', new Blob([pdfBlob], { type: 'application/pdf' }));

            // console.log('formData',formData)
            return axios({
                method: 'post',
                url: url,
                data: pdfData
            })
            .then(function(response) {
                window.console.log( 'UPLOAD INVOICE', response);
                return response.data
            })
            .catch(function(error) {
                window.console.log('UPLOAD INVOICE ERROR', error)
            })
        },
        getImageNote(context,refnum) {
            console.log('GET IMAGE NOTE', refnum)
            const data = {'refnum': refnum}
            const url = `${Global.nodeURL}imagenote?id=${refnum}`;
            return axios({
                method: 'get',
                url:url,
                data: data
            })
            .then(function(response) {
                window.console.log('GOT NOTE', response.data.data[0]);
                return response.data.data[0];
            })
            .catch(function(error) {
                alert(error)
                window.console.log(error);
            });
        },
        
    },
    plugins: [vuexLocal.plugin],
    getters: {
        allServices: state => state.allServices,
        allServiceTypes: state => state.allServiceTypes,
        allStaff: state => state.allStaff,
        allRooms: state => state.allRooms,
        allProducts: state => state.allProducts,
        allSupplies: state => state.allSupplies,
        allProductTypes: state => state.allProductTypes,
        allPromotions: state => state.allPromotions,
        allLocations: state => state.allLocations,
        selectedDateData: state => state.selectedDateData,
        selectedDisplayType: state => state.selectedDisplayType,
        selectedScheduleType: state => state.selectedScheduleType,
        bookingInfo: state => state.bookingInfo,
        allCertifications: state => state.allCertifications,
        startTime: state => state.startTime,
        user: state => state.user,
        currentLocation: state => state.currentLocation,
        selectedLanguage: state => state.selectedLanguage,
        confirmCode: state => state.confirmCode,
        key: state => state.key,
        newClient: state => state.newClient,
        selectedStaff: state => state.selectedStaff,
        isAuthenticated: state => state.isAuthenticated,
        showLoader: state => state.showLoader,
        spinner: state => state.spinner,
        selectedClient: state => state.selectedClient,
    }

})