<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'STAFF VACATIONS' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Staff' | translate}}:<span class="required-entry">*</span></label> 
            <Dropdown  
                v-on:item-selected="selectedStaff = $event" 
                v-bind:selected="selectedStaff.displayname" 
                v-bind:optioncontent="allStaff"
                placeholder="Select Staff">
            </Dropdown>
        </div>
        <div class="staff-info-container" v-if="showEntry">
            <div class="selected-staff-title">{{selectedStaff.name}} - {{'Vacation Dates' | translate }}</div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="vacation-date-picker">
                    <DatePicker  
                        class="date-picker"                
                        v-on:date-selected="startDate = $event" 
                        v-bind:mydate="startDate"
                        :placeholder = "'Select Date'">
                    </DatePicker>
                </div>
            </div>
            <div class='missing-selection-message' v-show="showErrors && (!startDate.day)">*{{'You need to select a' | translate}} {{'Start Date' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'End Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="vacation-date-picker">
                    <DatePicker  
                        class="date-picker"                
                        v-on:date-selected="endDate = $event" 
                        v-bind:mydate="endDate"
                        :placeholder = "'Select Date'">
                    </DatePicker>
                </div>
            </div>
            <div class='missing-selection-message' v-show="showErrors && (!endDate.day)">*{{'You need to select a' | translate}} {{'End Date' | translate}}</div>
            <div class='missing-selection-message' v-show="showDateError">*{{'End Date' | translate}} {{'Needs to Be After' | translate}} {{'Start Date' | translate}}</div>
             <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" />
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Vacation' | translate}} {{'Successfully Added' | translate}}!</div>
            </div> 
        </div>
        
    </div>
</template>

<script>
import Dropdown from "../utility/Dropdown"
import store from"../../store/store"
import DatePicker from "../utility/DatePicker"
import ButtonBar from "../utility/ButtonBar"
import Globals from "../../utilities/globals"
import Constants from "../../utilities/constants"

export default {
    name: 'StaffVacations',
    data() {
        return {
            selectedStaff: {},
            allStaff: [],
            store,
            startDate: {},
            endDate:{},
            showEntry: false,
            showErrors: false,
            showDateError: false,
            showSuccess: false,
            buttonList: ['Add','Cancel'],
            buttonClicked: {}
        }
    },
    components: {
        Dropdown,
        DatePicker,
        ButtonBar
    },
    methods: {
        getStaff() {
            
            this.$store.dispatch('getStaff',this.$store.getters.currentLocation.id).then(response => {
                window.console.log('GET staff',response)
                this.allStaff = response.data
                this.allStaff.map(staff => {
                    staff.name = staff.displayname
                })
            })
        },
        submitForm(start,end) {
            const data = {
                startdate: start,
                enddate: end,
                staffid: this.selectedStaff.id,
                businessid: this.selectedStaff.businessid,
                displayname: this.selectedStaff.name
            }
            this.$store.dispatch('addStaffVacation',data).then(response => {
                if(response.result == 'SUCCESS') {
                    this.showSuccess = true
                    this.getStaffVacations()
                    setTimeout(() => {
                        this.showSuccess = false
                    },Constants.waitTime)
                }
            })
            window.console.log('SUBMIT',data)
        },
        verifyForm() {
            this.showErrors = false
            this.showDateError = false
            if (this.startDate.day && this.endDate.day) {
                const start = Globals.formatDBDateCal(this.startDate)
                const end = Globals.formatDBDateCal(this.endDate)
                if (start > end) {
                    window.console.log('WRONG')
                    this.showDateError = true
                } else {
                    this.submitForm(start,end)
                }
                
            } else {
                this.showErrors = true
                window.console.log('DATE ERROR')
            }
        },
        getStaffVacations() {
            this.$store.dispatch('getStaffVacations',{id:this.selectedStaff.id}).then( response => {
                window.console.log('STAFF VACATIONS RESPONSE', response)
            })
        }
    },
    mounted() {
        this.getStaff()
    },
    watch: {
        selectedStaff() {
            this.showEntry = true;
            this.getStaffVacations()
            window.console.log('SEL', this.selectedStaff)
        },
        buttonClicked() {
            window.console.log('CLICKEd',this.buttonClicked)
            if (this.buttonClicked.name == 'Add') {
                this.verifyForm()
            }
        }
    }
}
</script>

<style scoped>
.vacation-date-picker {
    display: inline-block;
} 
.selected-staff-title {
    font-weight: 600;
    color: blue;
    padding-bottom: 10px;
}  
</style>