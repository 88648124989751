<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Add Location' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Reference Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="locationInfo.name"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.name">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Reference Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Phone' | translate}}:<span class="required-entry">*</span></label> 
            <input v-model="formattedPhone" @input="formatPhoneNumber"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.phone">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Phone' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
            <input type="email" v-model="locationInfo.email" />
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.email">*{{'You need to enter your' | translate}} {{'Business' | translate}} {{'Email' | translate}}</div>
        <div class='missing-selection-message' v-show="showEmailInvalid">*{{'Invalid Email' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Street' | translate}}:<span class="required-entry">*</span></label> 
            <input v-model="locationInfo.street"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.street">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Street' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Suite' | translate}}/{{'Unit' | translate}}:</label> 
            <input v-model="locationInfo.address2"/>
        </div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Zip' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-narrow" v-model="locationInfo.zip" @input="checkZip"/>
            <!-- <div class="button-main small-icon-version" @click="getZipInfo">
                <i class="fas fa-search"></i>
            </div> -->
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.zip">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Zip Code' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'City' | translate}}, {{'State' | translate}}:</label> 
            {{locationInfo.city}}<span v-if="locationInfo.city">, </span>{{locationInfo.state}}
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.city">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Zip Code' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Sales Tax' | translate}}: {{'Services' | translate}}<span class="required-entry">*</span> </label> 
            <input id="name" class="input-narrow text-right" v-model="locationInfo.taxservices" /> %
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.taxservices">{{'You need to enter your' | translate}} {{'Sales Tax' | translate}} {{'For Services' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Sales Tax' | translate}}: {{'Products' | translate}}<span class="required-entry">*</span> </label> 
            <input id="name" class="input-narrow text-right" v-model="locationInfo.taxproducts"/> %
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.taxproducts">{{'You need to enter your' | translate}} {{'Sales Tax' | translate}} {{'For Products' | translate}}</div>
       
        <WeekSchedule
            :scheduletitle="'Business Hours'"
            v-on:schedule-updated="locationInfo.schedule = $event"
        />
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.schedule">{{'You need to enter your' | translate}} {{'Schedule' | translate}} </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Location' | translate}} {{'Successfully Added' | translate}}</div>
        </div> 

    </div>
</template>

<script>
import WeekSchedule from '../utility/WeekSchedule'
import store from '../../store/store.js'
import Globals from '../../utilities/globals'
import ButtonBar from '../utility/ButtonBar'

export default {
    name: 'LocationAddNew',
    data() {
        return {
            location: {city:'',state:'', businessSchedule: true },
            showErrors: false,
            showSuccess: false,
            showEmailInvalid: false,
            formattedPhone: '',
            formattedTaxServices: '',
            locationInfo: {city:'',state:'', businessSchedule: true },
            store,
            buttonList: ['Submit','Cancel'],
            buttonClicked: ''
        }
    },
    components: {
        WeekSchedule,
        ButtonBar
    },
    methods: {
        checkZip() {
            if (this.locationInfo.zip.length > 4) {
                this.getZipInfo()
            }
        },
        getZipInfo() {
            // window.console.log('BEFORE', this.locationInfo)
            const vm = this
            var client = new XMLHttpRequest()
            const url = "http://api.zippopotam.us/us/" + vm.locationInfo.zip.substr(0,5)
            client.open("GET", url, true)
            client.onreadystatechange = function() {
                if(client.readyState == 4) {
                    const data = JSON.parse(client.responseText)                    
                    vm.locationInfo.city = data.places[0]['place name']
                    vm.locationInfo.state = data.places[0]['state abbreviation']                   
                }
            }
            client.send();
        },
        removeItem() {
            window.console.log('REMOVE THIS ITEM', this.locationIndex)
            this.$emit('remove-location', this.locationIndex)

        },
        validateLocation() {
            this.showErrors = false
            if (!this.locationInfo.name || !this.locationInfo.plan ||  !this.locationInfo.email) {
                window.console.log('VALIDATE ERROR',!this.locationInfo.name , !this.locationInfo.plan , !this.locationInfo.email )
                this.showErrors = true;
                return false
            }
            return true
            
        },
        verifyForm() {
            window.console.log('VERIFY')
            this.showSuccess = false
            this.showErrors = false
            if (!this.locationInfo.name || !this.locationInfo.phone || !this.locationInfo.email || !this.locationInfo.street || !this.locationInfo.zip || !this.locationInfo.taxservices || !this.locationInfo.taxproducts || !this.locationInfo.schedule) {
                window.console.log('SHOW ERROR', !this.locationInfo.name ,!this.locationInfo.phone , !this.locationInfo.email , !this.locationInfo.street , !this.locationInfo.zip , !this.locationInfo.taxservices , !this.locationInfo.taxproducts, !this.locationInfo.schedule)
                this.showErrors = true                
            } else {
                if (Globals.validateEmail(this.locationInfo.email)){
                    this.submitForm()
                } else {
                    this.showEmailInvalid = true
                }
                
            }
        },
        submitForm() {
            window.console.log('SUBMIT FORM', this.locationInfo)
            this.$store.dispatch('addLocation',this.locationInfo).then(response => {
                if (response.result==='SUCCESS') {
                    this.showSuccess = true
                    this.$store.dispatch('getLocations',{id:this.$store.getters.user.businessid})
                }
            })
        },
        formatPhoneNumber() {
            let cleaned = ('' + this.formattedPhone).replace(/\D/g, '')
            this.locationInfo.phone = cleaned.substr(0,10)
            this.formattedPhone = Globals.formatPhone(cleaned)
        },
        formatTaxServices() {
            let cleaned = ('' + this.formattedTaxServices).replace(/\D/g, '')
            this.locationInfo.tax = cleaned
            window.console.log('TAX CLEAN',cleaned)
            this.formattedTaxServices = cleaned + '%'
        }

    },
    mounted() {
        this.locationInfo.businessSchedule = true
    },
    watch: {
        buttonClicked() {
            window.console.log('CLICKED',this.buttonClicked.name)
            if (this.buttonClicked.name === 'Submit') {
                this.verifyForm()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        }
    },
}
</script>

<style scoped>
.location-main-container{
    border: 1px solid #6fa3bd;
}
.location-title-bar{
    font-weight: 600;
    font-size: 14px;
    background-color: #f4faff;
    padding: 2px;
    border-bottom: 1px solid #6fa3bd;
    position: relative;
    height: 21px;
    
}
.location-button-container{
    position: absolute;
    top: 2px;
    right: 0;
    display: inline-block;
} 
.button-icon{
    padding: 1px 7px;
    border-radius: 11px;
} 
.x-mark{
    position: relative;
    top: -1px;
} 
.text-right{
    text-align: right;
}
</style>