<template>
    <div>
        <div class="form-input-row">
            <label v-if="showLabel" class="form-input-label">{{phoneLabel | translate}}:<span v-if="showRequired" class="required-entry">*</span></label> 
            <input class="phone-number-input" v-model="formattedPhone" @input="formatPhone" :placeholder="placeholder"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhoneNumber',
    data() {
        return {
            formattedPhone: '',
            showRequired: this.required ? this.required : false,
            phoneLabel: 'Phone',
            showLabel: true
        }  
    },
    props: [
        'existingnumber',
        'required',
        'phonelabel',
        'showlabel',
        'placeholder',
    ],
    methods: {
        formatPhone(){
            let cleaned = ('' + this.formattedPhone).replace(/\D/g, '')
            let phoneNumber = cleaned.substr(0,10)

            if(cleaned.length < 4) {
                this.formattedPhone = cleaned
            }
            if(cleaned.length > 3 && cleaned.length < 7) {
                this.formattedPhone = cleaned.substr(0,3) + '-' + cleaned.substr(3,3)
            }
            if (cleaned.length > 6) {
                this.formattedPhone = cleaned.substr(0,3)+ '-' + cleaned.substr(3,3) + '-'+ cleaned.substr(6,4)
                this.$emit('number-updated', phoneNumber)
            }
        },
        setExisting() {
            this.formattedPhone = this.existingnumber
            this.formatPhone()
        }
    },
    mounted() {
        console.log('SHOW LAB', this.showLabel)
        if(this.existingnumber !== undefined){
            this.setExisting()
        }
        if(this.phonelabel != undefined) {
            this.phoneLabel = this.phonelabel
        }
        if(this.showlabel != undefined) {
            this.showLabel = this.showlabel
        }
    },
    watch: {
        existingnumber() {
            this.setExisting()
        }
    }
}
</script>

<style scoped>
.phone-number-input{
    width: 110px;
}
</style>