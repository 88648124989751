<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Terms of Agreement' | translate | allCaps}}</div>
        <div>
            <div class="business-agree" v-html="businessTerms"></div>
        </div>
        <div>
            <CheckBox 
                v-bind:checkBoxLabel="'Agree'"
                v-on:item-selected="termsAgree = $event" 
                />
        </div>
        <div>
            <div class="button-main" :style="checkAgree()" @click="goNext">{{ 'Next' | translate | allCaps}}</div>
        </div>
    </div>
</template>

<script>
import CheckBox from '../utility/CheckBox'
import Terms from '../../data/terms.json'
import store from '../../store/store'
export default {
    name: 'TermsAgree',
    data() {
        return{
            businessTerms: Terms.conditions,
            termsAgree: false,
            store,
        }
    },
    components: {
        CheckBox
    },
    methods: {
        checkAgree() {
            window.console.log('checkAgree')
            if (!this.termsAgree) {
                return {
                    backgroundColor: '#cad0d6'
                }
            }
        },
        goNext() {
            if (this.termsAgree) {
                this.$router.push('Welcome')
            }
        },
        getHtml() {
            this.$store.dispatch('getDocument','terms').then(response => {
                window.console.log('GOT RESPONSE', response)
                this.businessTerms = response
            })
        }
    },
    mounted() {
        this.getHtml()
    },
    watch: {
        termsAgree() {
            window.console.log('TERMS AGREE CHANGE', this.termsAgree)
            
        }
    },
}
</script>

<style >
.term-subtitle{
    font-size: 14px;
    font-weight: 700;
}
.business-agree{
    width: 80%;
    max-height: 300px;
    border: 1px solid;
    overflow-y: auto;
    padding: 20px;
    margin-left: 7%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: .7em;
    text-align: left;
    height: 70%;

}

</style>