<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="invoice-form-content" v-if="showLogin">
            <div class="form-title-row">{{'New Business Login' | translate | allCaps}}</div>
        
            <div class="form-input-row">
                <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
                <input class="input-wide" type="email" v-model="user.email"/>
            </div>
            <div class='missing-selection-message' v-if="(showError || showEmailRequired) && !this.user.email ">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Password' | translate}}:</label> 
                <input class="input-wide" type="password" v-model="user.password"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !this.user.password ">*{{'You need to enter your' | translate}} {{'Password' | translate}}</div>

            <div class="password-link" @click="forgotPassword">forgot password?</div>
            <div>{{wordList}}</div>
            <div class="button-main" @click="verifyEntry">{{'Submit' | translate}}</div>
            <div class='login-error-message' v-if="showLoginError">{{loginError | translate}}<br>{{'Please Try Again' | translate}}</div>
            <div class='login-success-message' v-if="showResetMessage">{{resetMessage | translate}}</div>
        </div> 
        <ForgetReset 
            :showElement="showElement"
            :launchPage="'business'"
            v-on:resetPage="showLogin = $event,showElement = ''"
            />  
    </div>
</template>

<script>
import store from '../../store/store'
import EventBus from '../../utilities/eventBus'
import ForgetReset from './ForgetResetComponent'
import Globals from '../../utilities/globals'

export default {
    name: 'NewBusinessLogin',
    data() {
        return {
            user: {
                name: '',
                password: ''
            },
            showError: false,
            showLogin: true,
            showLoginError: false,
            showResetMessage: false,
            showEmailRequired: false,
            store,
            wordList: '',
            loginError: 'Error Logging In',
            resetMessage: 'The login password has been resent to your email',
            showElement: ''
            
        }
    },
    components:{
        ForgetReset
    },
    methods: {
        forgotPassword() {
            //NEED TO RESEND LOGIN INSTRUCTIONS
            // NEED TO DISPLAY MESSAGE ABOUT 
            this.hideErrorMessages()
             EventBus.$emit('SHOW_SPINNER')
            if (this.user.email && Globals.validateEmail(this.user.email)){
                window.console.log('EMAIL RESEND', this.user.email)
                this.$store.dispatch('resendPassword',{email:this.user.email}).then(response => {
                    window.console.log('EMAIL RESEND RESPONSE', response)
                    if(response.result === 'SUCCESS'){
                        this.showResetMessage = true
                    } else {
                        this.showLoginError = true
                    }                    
                    EventBus.$emit('HIDE_SPINNER')
                })
            } else {
                this.showEmailRequired = true
                EventBus.$emit('HIDE_SPINNER')
            }           
        },
        hideErrorMessages() {
            this.showEmailRequired = false
            this.showResetMessage = false
            this.showLoginError = false
            this.showError = false
        },
        verifyEntry() {
            this.showError = false
            this.showResetMessage = false
            if (!this.user.email || !this.user.password) {
                this.showError = true
            }
            if (!this.showError) {
                this.submitLogin()
            }
        },
        submitLogin() {
            this.user.email = this.user.email.toLowerCase()
            EventBus.$emit('SHOW_SPINNER')
            this.hideErrorMessages()
            this.$store.dispatch('newBusinessLogin', this.user).then(response => {
                EventBus.$emit('HIDE_SPINNER')
                if (response.result === 'SUCCESS') {
                    window.console.log('USER INFO', response)
                        this.showElement = 'confirm'
                        this.showLogin = false               
                } else {
                    if (response.response) {
                        this.loginError = response.response
                    } else {
                        if (response.request && response.request.status == 0) {
                            this.loginError = 'No Network Connection'
                        } else {
                            if (response.result == 'STARTED') {
                                this.loginError = 'Your Business Account has already been Activated. Use the standard login page.'
                                setTimeout(() => {
                                    this.$router.push('Login')
                                }, 4000)
                            } else {
                                this.loginError = 'Your Email or Password Does not Match'
                            }
                            
                        }
                    }
                    window.console.log('ERROR RESULTS', response)
                    this.showLoginError = true
                }
            })
        },      
    },
    mounted() {
        EventBus.$emit('LOG_OUT')
    },
    
}
</script>

<style scoped>
.login-error-message{
    color: red;
    padding: 5px;
} 
.login-success-message{
    color: green;
    padding: 5px;
}
.password-link{
    cursor: pointer;
    font-size: 13px;
    color:#08556c;
}   
</style>