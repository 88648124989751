<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Update Business Hours' | translate | allCaps}}</div>
        <div class="schedule-container"  v-for="(location,index) in allLocations" :key="index">
            <div class="location-title" @click="showSch(index)">
                {{location.name}} 
                <span v-if="!showLocation[index]"><i class="fas fa-caret-down"></i></span>
                <span v-if="showLocation[index]"><i class="fas fa-caret-right"></i></span>
            </div>
            <div >
                <WeekSchedule                    
                    :scheduletitle="'Hours Open'"
                    :existingschedule="location.schedule"
                    v-on:schedule-updated="locationData[location.id] = $event"
                />
            </div>
        </div>
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Business Hours' | translate}} {{'Successfully Updated' | translate}}</div>
        </div> 
    </div>
</template>

<script>
import WeekSchedule from '../utility/WeekSchedule'
import ButtonBar from '../utility/ButtonBar'
import store from '../../store/store'

export default {
    name: 'BusinessHours',
    data() {
        return {
            locationData: {},
            allLocations: [],
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            showLocation: {},
            showSuccess: false,
            store,
        }
    },
    components: {
        WeekSchedule,
        ButtonBar,
    },
    methods: {
        submitData() {
            // const data = {
            //     id: this.$store.getters.user.businessid,
            //     hours: this.locationData
            // }
            window.console.log('SUBMIT BUSINESS HOURS FOR LOC', this.locationData)
            // this.$store.dispatch('updateBusinessHours',data).then(response => {
            //     window.console.log('RESPONSE', response)
            // })
            Object.keys(this.locationData).forEach(loc => {
                window.console.log('EACH',loc, this.locationData[loc])
                const data = {
                    hours: this.locationData[loc],
                    id: Number(loc)
                }
                this.$store.dispatch('updateLocationSchedule',data).then(response => {
                    window.console.log('RESPONSE', response)
                    if (response.result==='SUCCESS') {
                        this.showSuccess = true
                    }
                })
                
            })

        },
        showSch(index) {
            this.showLocation[index] = !this.showLocation[index]
        },
        setLocation() {
           this.allLocations = this.$store.getters.allLocations
           window.console.log('LOCATIONS LOADED', this.allLocations)
           this.allLocations.forEach((loc,index) => {
               if (loc.id == this.$store.getters.currentLocation.id || (!this.$store.getters.currentLocation.id && index === 0) ) {
                   this.showLocation[index] = true
               } else {
                   this.showLocation[index] = false
               }
               
           })
        }
    },
    mounted() {
        this.setLocation()
        this.showSuccess = false
        window.console.log('ALL LOCS', this.$store.getters.allLocations, this.$store.getters.primaryLocation)
    },
    watch: {
        locationData() {
            window.console.log('LOC SELECTED', this.locationData)
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.submitData()
            }

            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    },
}
</script>

<style scoped>
.location-title{
    font-weight: 600;
    color: #02769a;
    padding: 5px;
    font-size: 17px;
    cursor: pointer;
}
</style>