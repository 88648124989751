<template>
        <div class="info-button-container" :style="{'top': positiontop}" >
            <i class="fas fa-question-circle" 
                @click="showInfo=!showInfo" 
                :style="{'color': buttoncolor,'right': positionright,'left': positionleft,'fontSize': iconfontsize}"></i>
            <div class="info-popup-panel" v-if="showInfo" @click="showInfo=!showInfo">{{infocontent | translate}}</div>
        </div>       
</template>

<script>
export default {
    name: 'InfoButton',
    data() {
        return {
            showInfo: false,
        }
    },
    props:  [
        'infocontent',
        'buttoncolor',
        'positiontop',
        'positionright',
        'positionleft',
        'iconfontsize',
    ],
    methods: {

    }
}
</script>


<style scoped>
.info-button-container{
    display: inline-block;
    padding-left: 10px;
    position: absolute;
    top: 0;
}
.fa-info-circle, .fa-question-circle{
    color: #2280d2;
    font-size: 20px;
    position: absolute;
    top: 17px;
    cursor: pointer;
}
.info-popup-panel{
    position: absolute;
    background-color: white;
    width: 200px;
    border: 1px solid black;
    line-height: 16px;
    font-size: 14px;
    padding: 5px;
    left: -75px;
    bottom: -11px;
    cursor: pointer;
    z-index: 1;
}   
</style>