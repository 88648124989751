<template>
     <div class="main-reports-form-container">
        
        
        
<!-- STAFF -->
    <!-- <div v-if="showStaffActivity"> -->
        <div class="report-activity-title"><div class="report-title">{{'General Report' | translate | allCaps}} </div> {{'Staff Activity' | translate}} - {{'Bookings' | translate}}</div>
        <div >
            <div class="report-item-title-row" >
                <div class="report-table-item item-title">{{'Staff' | translate}}</div>
                <div class="report-table-item item-title tiny-item">#</div>
                <div class="report-table-item item-title small-item">{{'Total Booking' | translate}}</div> 
                <div class="report-table-item item-title small-item" >{{'Total Billed' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Services Products' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Tips' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Taxes' | translate}}</div> 
                <div class="report-table-item item-title small-item">{{'Promo' | translate}}</div> 
            </div>
            <div class="report-item-row" v-for="(staff,index) in allStaff" :key="index">
                <div class="report-table-item is-clickable" @click="showStaffDetail(staff.id)">{{staff.displayname}} <i class="fas fa-caret-down" v-if="bookingsStaff(staff.id).length"></i></div> 
                <div class="report-table-item tiny-item item-center">{{bookingsStaff(staff.id).length}}</div> 
                <div class="report-table-item small-item">{{valueBookingsStaff(staff.id) | toUSD}}</div> 
                <div class="report-table-item small-item">{{valuePaidStaffInvoices(staff.id) | toUSD}}</div> 
                <div class="report-table-item small-item">{{valueSPStaffInvoices(staff.id,'booking') | toUSD}}</div>                 
                <div class="report-table-item small-item">{{valueTips(staff.id,'booking') | toUSD}}</div>
                <div class="report-table-item small-item">{{valueStaffTaxes(staff.id,'booking') | toUSD}}</div>
                <div class="report-table-item small-item">{{valueStaffPromo(staff.id,'booking') | toUSD}}</div>
                <div class="report-sub-container" v-if="showStaff(staff.id)">
                    <div v-for="(booking,index) in bookingsStaff(staff.id)" :key="index" >
                        <ReportsBookingDetail 
                            :booking="booking" 
                            :allInvoiceItems="allInvoiceItems"
                            :invoicesStaff ="invoicesStaffBookings(staff.id)"
                            />
                    </div>
                </div>
           </div>
           <div class="report-item-row" >
                <div class="report-table-item item-total">{{'Total' | translate}}</div>
                <!-- Number Bookings -->
                <div class="report-table-item item-total tiny-item item-center">{{allBookings.length}}</div>
                <!-- Value Bookings -->
                <div class="report-table-item item-total small-item">{{valueAllStaffBookings() | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{bookingTotals.paid | toUSD}}</div>
                
                <div class="report-table-item item-total small-item">{{bookingTotals.services | toUSD}}</div>                  
                <div class="report-table-item item-total small-item">{{bookingTotals.tips | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{bookingTotals.taxes | toUSD}}</div> 
                <div class="report-table-item item-total small-item">{{bookingTotals.promo | toUSD}}</div>  
            </div>           
        </div>

        <!-- INVOICING PAID -->
        <div class="report-activity-title">{{'Staff' | translate}} - {{'Total Payments Received' | translate}}</div>
        <div >
            <div class="report-item-row" >
                <div class="report-table-item item-title">{{'Staff' | translate}}</div>
                <div class="report-table-item item-title small-item"># {{'Inv' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Billed' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Paid' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Services Products' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Tips' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Taxes' | translate}}</div> 
                <div class="report-table-item item-title small-item">{{'Promo Used' | translate}}</div> 
            </div>
            <div class="report-item-row" v-for="(staff,index) in allStaff" :key="index">
                <div class="report-table-item is-clickable" @click="showStaffInvoiceDetail(staff.id)">{{staff.displayname}} <i class="fas fa-caret-down" v-if="paidStaff(staff.id).length"></i></div> 
                <!-- # Inv -->
                <div class="report-table-item small-item item-center">{{invoicesStaffBookingTotal(staff.id).length}}</div>
                <div class="report-table-item small-item">{{valueStaffInvoices(staff.id) | toUSD}}</div> 
                <!-- Total Received -->
                <div class="report-table-item small-item">{{valueStaffInvoices(staff.id) | toUSD}}</div>
                 <!--Products Services  -->
                <div class="report-table-item small-item">{{valueSPStaffInvoices(staff.id) | toUSD}}</div> 
                <!-- Tips -->
                <div class="report-table-item small-item">{{valueTips(staff.id) | toUSD}}</div>
                <!-- Taxes -->
                <div class="report-table-item small-item">{{valueStaffTaxes(staff.id) | toUSD}}</div>
                <!-- Promo -->               
                <div class="report-table-item small-item">{{valueStaffPromo(staff.id) | toUSD}}</div>
                <div class="report-sub-container" v-if="showStaffInvoice(staff.id)">
                    <div v-for="(booking,index) in paidStaff(staff.id)" :key="index" >
                        <ReportsInvoiceDetail 
                            :invoice="booking" 
                            :allInvoiceItems="allInvoiceItems"
                            :invoicesStaffBookings ="invoicesStaffBookings(staff.id)"
                            />
                    </div>
                </div>
           </div>
           <div class="report-item-row" >
                <div class="report-table-item item-total">{{'Total' | translate}}</div>
                <!-- Number Invoices -->
                <div class="report-table-item item-total small-item item-center">{{allInvoices.length}}</div>
                <!-- Total Invoice Billing -->
                <div class="report-table-item item-total small-item">{{invoiceTotals.sales + invoiceTotals.tips | toUSD}}</div> 
                <div class="report-table-item item-total small-item">{{invoiceTotals.paid | toUSD}}</div> 
                <!-- Products Services -->
                <div class="report-table-item item-total small-item">{{invoiceTotals.services | toUSD}}</div>
                <!-- Tips -->
                <div class="report-table-item item-total small-item">{{invoiceTotals.tips | toUSD}}</div> 
                <!-- Taxes -->
                <div class="report-table-item item-total small-item">{{invoiceTotals.taxes | toUSD}}</div> 
                <!-- Promo -->
                <div class="report-table-item item-total small-item">{{invoiceTotals.promo | toUSD}}</div>                 
            </div>           
        </div>
       
<!-- SERVICES -->
        <div class="report-activity-title"> {{'Services' | translate}}</div>
        <div >
            <div class="report-item-row" >
                <div class="report-table-item item-title">{{'Service' | translate}}</div>
                <div class="report-table-item item-title item-center">{{'Bookings' | translate}}</div>
                <div class="report-table-item item-title">{{'Book Value' | translate}}$</div> 
                <div class="report-table-item item-title small-item item-center">{{'Invoiced' | translate}}</div> 
                <div class="report-table-item item-title small-item">{{'Value' | translate}}</div> 
                <div class="report-table-item item-title small-item">{{'Paid' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Promo Used' | translate}}</div> 
            </div>
            <div class="report-item-row" v-for="(service,index) in allServices" :key="index">
                <div class="report-table-item">{{service.name}}</div> 
                <div class="report-table-item item-center">{{bookingsService(service.id).length}}</div> 
                <div class="report-table-item">{{valueBookingsService(service.id) | toUSD}}</div>
                <div class="report-table-item small-item item-center">{{servicesUsed(service.id).quantity}}</div>
                <div class="report-table-item small-item">{{servicesUsed(service.id).value | toUSD}}</div>
                <div class="report-table-item small-item">{{servicesUsed(service.id).paid | toUSD}}</div>
                <div class="report-table-item small-item">{{servicesUsed(service.id).promo | toUSD}}</div> 
            </div>
            <div class="report-item-row" >
                <div class="report-table-item item-total">{{'Total' | translate}}</div>
                <div class="report-table-item item-total item-center">{{allBookings.length}}</div>
                <div class="report-table-item item-total">{{valueAllStaffBookings() | toUSD}}</div> 
                <div class="report-table-item item-total small-item item-center">{{servicesTotal.quantity }}</div> 
                <div class="report-table-item item-total small-item">{{servicesTotal.value | toUSD}}</div> 
                <div class="report-table-item item-total small-item">{{servicesTotal.paid | toUSD}}</div> 
                <div class="report-table-item item-total small-item">{{servicesTotal.promo | toUSD}}</div> 
            </div>
        </div>
<!-- PRODUCTS -->
        <div class="report-activity-title"> {{'Products' | translate}}</div>
        <div >
            <div class="report-item-row" >
                <div class="report-table-item item-title">{{'Product' | translate}}</div>
                <div class="report-table-item item-title small-item item-center">{{'Used' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Paid' | translate}}$</div>
                <div class="report-table-item item-title small-item item-center">{{'Inventory' | translate}}</div>  
            </div>
            <div class="report-item-row" v-for="(product,index) in allProducts" :key="index">
                <div class="report-table-item">{{product.name}}</div> 
                <div class="report-table-item small-item item-center">{{invoiceProductsQuantity(product.id)}}</div> 
                <div class="report-table-item small-item">{{valueInvoiceProducts(product.id) | toUSD}}</div>
                <div class="report-table-item small-item item-center">{{product.inventorytotal}}</div>                
            </div>
        </div>

<!-- SUPPLIES -->
        <div class="report-activity-title"> {{'Supplies' | translate}}</div>
        <div >
            <div class="report-item-row" >
                <div class="report-table-item item-title">{{'Supply' | translate}}</div>
                <div class="report-table-item item-title item-center">{{'Used' | translate}}</div>
                <div class="report-table-item item-title item-center">{{'Inventory' | translate}}</div> 
            </div>
            <div class="report-item-row" v-for="(supply,index) in allSupplies" :key="index">
                <div class="report-table-item">{{supply.name}}</div> 
                <div class="report-table-item item-center">{{getSuppliesUsed(supply.id)}}</div> 
                <div class="report-table-item item-center">{{supply.inventorytotal}}</div>                
            </div>
        </div>
<!-- PROMOTIONS -->
        <div class="report-activity-title"> {{'Promotions' | translate}}</div>
        <div >
            <div class="report-item-row" >
                <div class="report-table-item item-title">{{'Promotion' | translate}}</div>
                <div class="report-table-item item-title small-item item-center">{{'Packages' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Total Value' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Paid' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Used' | translate}}$</div>
                <div class="report-table-item item-title small-item item-center">{{'Used' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Balance' | translate}}$</div>

            </div>
            <div class="report-item-row" v-for="(promotion,index) in allPromotions" :key="index">
                <div class="report-table-item">{{promotion.name}}</div> 
                <div class="report-table-item small-item item-center">{{getBilledPromotionQuantity(promotion.id) }}</div>
                <div class="report-table-item small-item">{{promotionPurchaseValue(promotion.id) | toUSD }}</div>
                <div class="report-table-item small-item">{{paidPromotionPurchases(promotion.name) | toUSD}}</div> 
                <div class="report-table-item small-item item-center">{{usedPromoValue(promotion.name) | toUSD}}</div> 
                <div class="report-table-item small-item">{{usedPromoQuantity(promotion.name)}}</div> 
                <div class="report-table-item small-item">{{promotionPurchaseValue(promotion.id) - usedPromoValue(promotion.name) | toUSD}}</div>               
            </div>
        </div>
    <!-- </div> -->
        
    </div>
</template>

<script>
// import Dropdown from '../utility/Dropdown'
import Constants from '../../utilities/constants'
// import DatePicker from '../utility/DatePicker'
// import Globals from '../../utilities/globals'
import store from '../../store/store'
import ReportsBookingDetail from './ReportsBookingDetail'
import ReportsInvoiceDetail from './ReportsInvoiceDetail'

export default {
    name: 'Reports',
    data() {
        return{
            report:{startdate:'',enddate:''},
            store,
            selectedTopic: 'Bookings',
            monthSelected: {},
            locationSelected: {},
            showDatePicker: false,
            showLocationPicker: false,
            showMonthPicker: false, 
            showStaffActivity: false,           
            selectedStartDate: {},
            selectedEndDate: {},
            allMonths:Constants.allMonths,           
            allLocations:[],            
            allBookings: [],
            allInvoices: [],
            bookingInvoices:[],
            allInvoiceItems: [],
            allInvoicePromotions: [],
            valueStaff: 0,
            valueServices: 0,
            showStaffId: 0,
            showStaffInvoiceId: 0,
            showInvoiceId: 0,
            displayInvoiceDetail: 0,
            invoiceTotals: {},
            bookingTotals: {},
            servicesTotal: {},
            reportSelected: {}
        }
    },
    props: [
        'startdate',
        'enddate',
        'allServices',
        'allStaff',
        'allProducts',
        'allSupplies',
        'allPromotions',
    ],
    components: {
        // Dropdown,
        // DatePicker,
        ReportsBookingDetail,
        ReportsInvoiceDetail
    },
    methods: {
        topicSelected(topic) {
            this.selectedTopic = topic
        },
        // submitSearch() {
        //     this.report.startdate = `${this.selectedStartDate.month + 1}/${this.selectedStartDate.day + 1}/${this.selectedStartDate.year}`
        //     this.report.enddate = `${this.selectedEndDate.month + 1}/${this.selectedEndDate.day}/${this.selectedEndDate.year}`
        //     this.report.locationid = ''
        //     window.console.log('SUBMIT SEARCH', this.report.startdate, this.report.enddate)
        //     this.getBookings()
        //     this.getInvoiceItemsDates()
        //     this.getInvoicesDates()
        //     window.console.log('SEARCH', this.selectedStartDate, this.selectedEndDate,this.report)
        //     if (this.selectedTopic === 'Bookings') {
        //         this.getBookings()
        //     }
        //     this.showStaffActivity = true
        // },
        getBookings() {
            this.$store.dispatch('getBookingsDates',{startdate: this.startdate,enddate: this.enddate}).then(response => {
                this.valueBookings = 0
                this.valueServices = 0
                this.allBookings = response
            })
        },
        getInvoiceItemsDates() {
            this.$store.dispatch('getInvoiceItemsDates',{startdate: this.startdate,enddate: this.enddate}).then(response => {
                this.allInvoiceItems = response
                this.getServicesTotals()
            })
        },
        getInvoicesDates() {
            window.console.log('getInvoicesDates')
            this.$store.dispatch('getInvoicesDates',{startdate: this.startdate,enddate: this.enddate}).then(response => {
                const startdate = new Date(this.startdate)
                const enddate = new Date(this.enddate)
                this.allInvoices = response
                this.bookingInvoices = this.allInvoices.filter(invoice => {
                    let bookdate = new Date(invoice.bookingdate)
                    if (bookdate <= enddate && bookdate >= startdate) {
                        return invoice
                    }
                })
                window.console.log('BOOKING INVOICES',this.bookingInvoices)
                this.getPromtionInvoices()
                this.setValuesAllInvoices()
            })
        },
        getLocations() {
            this.allLocations = this.$store.getters.allLocations
            if (!this.allLocations.length){
                this.$store.dispatch('getLocations').then(response => {
                    window.console.log('RESPONSE LOCATIONS', response)
                    this.allLocations = response
                })
            }
            if (this.allLocations.length < 2) {
                this.showLocationPicker = false
                this.report.locationid = this.allLocations[0].id
            }
            window.console.log('LOCATIONS', this.allLocations)
        },
        bookingsStaff(id) {
            const bookingStaff = this.allBookings.filter(booking => {
                // window.console.log('EACH STAFF', id, booking.staffid,this.allBookings)
                if (booking.staffid === id) {
                    return booking
                }
            })
            return bookingStaff
        },
        paidStaff(id) {
            const staffPaid = this.allInvoices.filter(invoice => {
                if (invoice.staffid === id) {
                    return invoice
                }
            })
            return staffPaid
        },
        invoiceItemsStaffBookings(id) {
            return this.allInvoiceItems.filter(item => {
                if (this.bookingsStaff(id).some(booking => booking.id === item.bookingid)) {
                    return item
                }
            })
        },
        invoicesStaffBookings(id) {
            return this.bookingInvoices.filter(item => {
                if (this.bookingsStaff(id).some(booking => booking.id === item.bookingid)) {
                    return item
                }
            })
        },
        invoicesStaffBookingTotal(id) {
            return this.allInvoices.filter(item => {
                if (id === item.staffid) {
                    return item
                }
            })
        },
        invoicesStaffTotal(id) {
            return this.allInvoices.filter(item => {
                if (item.staffid == id) {
                    return item
                }
            })
        },
        valuePaidStaffInvoices(id) {
            let totalValue = 0
            this.invoicesStaffBookings(id).forEach(invoice => {
                totalValue += Number(invoice.amountpaid)
            })
            return totalValue
        },
        valueStaffTaxes(id,type) {
            let totalValue = 0
            if (type === 'booking') {
                this.invoicesStaffBookings(id).forEach(invoice => {
                    totalValue += Number(invoice.taxproduct) + Number(invoice.taxservice)
                })
            } else {
                this.invoicesStaffTotal(id).forEach(invoice => {
                    totalValue += Number(invoice.taxproduct) + Number(invoice.taxservice)
                })
            }           
            return totalValue
        },
        valueStaffPromo(id, type) {
            let totalValue = 0
            if (type === 'booking') {
                this.invoicesStaffBookings(id).forEach(invoice => {
                    totalValue += Number(invoice.creditapplied)
                })
            } else {
                this.invoicesStaffTotal(id).forEach(invoice => {
                    totalValue = Number(totalValue) +Number(invoice.creditapplied)
                })
            }
            
            return totalValue
        },
        valueStaffInvoices(id,type) {
            let totalValue = 0
            if( type === 'booking') {
                this.invoicesStaffBookings(id).forEach(invoice => {
                    totalValue += Number(invoice.invoicetotal)
                })
            } else {
                this.invoicesStaffTotal(id).forEach(invoice => {
                    totalValue += Number(invoice.invoicetotal)
                })
            }
            
            return totalValue
        },
        valueSPStaffInvoices(id,type) {
            let totalValue = 0
            if( type === 'booking') {
                this.invoicesStaffBookings(id).forEach(invoice => {
                    totalValue += Number(invoice.invoicetotal) - Number(invoice.taxproduct) - Number(invoice.taxservice)
                })
            } else {
                this.invoicesStaffTotal(id).forEach(invoice => {
                    totalValue += Number(invoice.invoicetotal) - Number(invoice.taxproduct) - Number(invoice.taxservice)
                })
            }
            return totalValue
        },
        valueBookingsStaff(id) {
            let valueBookings = 0
            this.bookingsStaff(id).forEach(booking => {
                this.allServices.forEach(service => {
                    if (service.id === booking.serviceid) {
                        if (service.trackinventory) {
                            valueBookings += Number(service.retailprice) * Number(service.estimatedusage)
                        } else {
                            valueBookings += Number(service.retailprice)
                        }
                        
                    }
                })
            })
            return valueBookings
        },
        valueAllStaffBookings() {
            let totalValue = 0
            this.allStaff.forEach(staff => {
                totalValue += this.valueBookingsStaff(staff.id)
            })
            return totalValue
        },
        valueInvoicesAllServices() {
            let totalValue = 0
            this.allServices.forEach(service => {
                totalValue += this.valueInvoicesService(service.id)
            })
            return totalValue
        },
        valueinvoiceItemsStaffBookings(id) {
            let valueInvoices = 0
            this.invoiceItemsStaffBookings(id).forEach(item => {
                valueInvoices += Number(item.amountpaid)
            })
            return valueInvoices
        },
        setValuesAllInvoices() {
            let totals = {sales: 0,tips:0,taxes:0,services:0,paid:0,promo:0}
            this.allInvoices.forEach(invoice => {
                if (invoice.type !== 'promotion') {
                    totals.sales += Number(invoice.invoicetotal)
                    totals.tips += Number(invoice.tip)
                    totals.taxes += Number(invoice.taxservice) + Number(invoice.taxproduct)
                    totals.services += Number(invoice.productservicetotal)
                    totals.paid += Number(invoice.amountpaid)
                    totals.promo += Number(invoice.creditapplied)
                }             
            })
            let totalsbookings = {sales: 0,tips:0,taxes:0,services:0,paid:0,promo:0}
            this.bookingInvoices.forEach(invoice => {
                if (invoice.type !== 'promotion') {
                    totalsbookings.sales += Number(invoice.invoicetotal)
                    totalsbookings.tips += Number(invoice.tip)
                    totalsbookings.taxes += Number(invoice.taxservice) + Number(invoice.taxproduct)
                    totalsbookings.services += Number(invoice.productservicetotal)
                    totalsbookings.paid += Number(invoice.amountpaid)
                    totalsbookings.promo += Number(invoice.creditapplied)
                }               
            })
            window.console.log('TOTAL----------', totals)
            this.bookingTotals = totalsbookings
            this.invoiceTotals = totals
        },
        getServicesTotals() {
            let totals = {quantity: 0,value:0,paid:0,promo:0}
            this.allInvoiceItems.forEach(item => {
                if (item.category == 'service') {
                    totals.quantity += Number(item.quantity)
                    totals.value += Number(item.totalsale)
                    totals.paid += Number(item.totalsale) - Number(item.creditamount)
                    totals.promo += Number(item.creditamount)
                }
            })
            this.servicesTotal = totals
        },
        valueTips(id,type) {
            let totalValue = 0
            if (type === 'booking') {
                this.bookingInvoices.forEach(invoice => {
                    if (invoice.staffid == id) {
                        totalValue +=  Number(invoice.tip)
                    }               
                })
            } else {
                this.allInvoices.forEach(invoice => {
                    if (invoice.staffid == id) {
                        totalValue +=  Number(invoice.tip)
                    }               
                })
            }
            
            return totalValue
        },
        bookingsService(id) {
            const bookingService = this.allBookings.filter(booking => {
                if (booking.serviceid === id) {
                    return booking
                }
            })
            return bookingService
        },
        valueBookingsService(id) {
            let valueBookings = 0
            this.bookingsService(id).forEach(booking => {
                this.allServices.forEach(service => {
                    if (service.id === booking.serviceid) {
                        if (service.trackinventory) {
                            valueBookings += Number(service.retailprice) * Number(service.estimatedusage)
                        } else {
                            valueBookings += Number(service.retailprice)
                        }
                    }
                })
            })
            return valueBookings
        },
        valueInvoicesService(id) {
            let valueBookings = 0
                this.allInvoiceItems.forEach(item => {
                    if (item.itemid == id) {
                        valueBookings += item.amountpaid
                    }
                })
            return valueBookings
        },
        servicesUsed(id) {
            let services = {value:0,quantity:0,paid:0,promo:0}
            this.allInvoiceItems.forEach(item => {
                if (item.itemid == id) {
                    services.quantity += Number(item.quantity)
                    services.value += Number(item.totalsale)
                    services.paid += Number(item.totalsale) - Number(item.creditamount)
                    services.promo += Number(item.creditamount)
                }
            })
            return services

        },
        invoiceProducts(id) {
            const invoiceProduct = this.allInvoiceItems.filter(item => {
                if (item.itemid === id) {
                    return item
                }
            })
            return invoiceProduct            
        },
        invoiceProductsQuantity(id) {
            let quantity = 0
           
            this.invoiceProducts(id).forEach(item => {
                quantity += Number(item.quantity)
            })            
            return quantity
        },
        totalProductInvoices() {
            let totalValue = 0
            this.allProducts.forEach(product => {
                totalValue += this.invoiceProductsQuantity(product.id)
            })
            return totalValue
        },
        valueInvoiceProducts(id) {
            let valueProducts = 0
            this.invoiceProducts(id).forEach(invoice => {
                this.allProducts.forEach(product => {
                    if (product.id === invoice.itemid) {
                        valueProducts += Number(invoice.totalsale)
                    }
                })
            })
            return valueProducts
        },
        valueInvoicesAllProducts() {
            let totalValue = 0
            this.allProducts.forEach(product => {
                totalValue = this.valueInvoiceProducts(product.id)
            })
            return totalValue
        },
        totalValueServices() {
            let totalValue = 0
            this.allBookings.forEach(booking => {
                this.allServices.forEach(service => {
                    if (booking.serviceid === service.id) {
                        if (service.trackinventory) {
                            totalValue += Number(service.retailprice) * Number(service.estimatedusage)
                        } else {
                            totalValue += Number(service.retailprice)
                        }
                    }
                })
            })
            return totalValue
        } ,
        showStaff(id) {
            if (this.showStaffId === id) {
                return true
            }
            return false
        },
        showStaffInvoice(id) {
            if (this.showStaffInvoiceId === id) {
                return true
            }
            return false
        },
        showStaffDetail(id) {
            window.console.log('SET',id)
             if (this.showStaffId === id) {
                this.showStaffId = 0
             } else {
                this.showStaffId = id
             }
            
        },
        showStaffInvoiceDetail(id) {
            if (this.showStaffInvoiceId === id) {
                this.showStaffInvoiceId = 0
            } else {
                this.showStaffInvoiceId = id
            }
        }, 
        showInvoiceItems(id) {
            window.console.log('CHECK SHOW', id, this.showInvoiceId)
            if (this.showInvoiceId === id) {
                return true
            }
            return false
        },
        showInvoiceDetail(id) {
            window.console.log('SET Invoice item',id)
             if (this.showInvoiceId === id) {
                this.showInvoiceId = 0
             } else {
                this.showInvoiceId = id
             }
            
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                window.console.log('ALL PROMOTIONS', response)
                this.allPromotions = response
            })
        },
        getPromtionInvoices() {
            this.allInvoicePromotions = this.allInvoices.filter(invoice => {
                if (invoice.type == 'promotion') {
                    return invoice
                }
            })
        },
        usedPromoValue(name) {
            let totalValue = 0
            this.allInvoices.forEach(invoice => {
                if (invoice.appliedcredititems){
                    invoice.appliedcredititems.forEach(credit => {
                        if (name == credit.name) {
                            totalValue += credit.value
                        } 
                    })
                }
            })
            return totalValue
        },
        usedPromoQuantity(name) {
            let totalQuantity = 0
            this.allInvoices.forEach(invoice => {
                if (invoice.appliedcredititems){
                    invoice.appliedcredititems.forEach(credit => {
                        if (name == credit.name) {
                            totalQuantity += credit.quantity
                        } 
                    })
                }
            })
            return totalQuantity
        },
        paidPromotionPurchases(name) {
            let totalValue = 0
            this.allInvoicePromotions.forEach(invoice => {
                invoice.invoiceitems.forEach(item => {
                    if (name == item.name) {
                        totalValue += Number(item.saleprice) * Number(invoice.quantity)
                    }
                })
            })
            return totalValue
        },
        getBilledPromotionQuantity(id) {
            let totalQuantity = 0
            this.allInvoicePromotions.forEach(invoice => {
                invoice.invoiceitems.forEach(item => {
                    if (item.id == id) {
                        totalQuantity = invoice.quantity
                    }
                })
            })
            return totalQuantity
        },
        getPromotionItemQuantity(id) {
            let totalQuantity = 0
            this.allInvoicePromotions.forEach(invoice => {
                invoice.invoiceitems.forEach(item => {
                    if (item.id == id) {
                        item.items.forEach(subitem => {
                            totalQuantity += subitem.quantity
                        })
                    }
                })
            })
            return totalQuantity
        },
        promotionPurchaseValue(id) {
             let totalValue = 0
            this.allInvoicePromotions.forEach(invoice => {
                invoice.invoiceitems.forEach(item => {
                    if (item.id == id) {
                        totalValue += item.retailprice * invoice.quantity
                    }
                })
            })
            return totalValue
        },
        getUsedPromotions(id) {
            let totalValue = 0
            this.allInvoiceItems.forEach(item => {
                if (item.promotionid == id) {
                    totalValue += Number(item.creditamount)
                }
            })
            return totalValue
        },
        getSuppliesUsed(id) {
            let totalUsed = 0
            this.allInvoiceItems.forEach(item => {
                if (item.refid === id) {
                    totalUsed += Number(item.quantity)
                }
            })
            return totalUsed
        }
             
    },
    watch: {
    
    },
    mounted() {
        this.getInvoiceItemsDates()
        this.getInvoicesDates()
        this.getBookings()
    },
}
</script>

<style scoped>
/* .report-item-container{
    width: auto;
    position: absolute;
    left: 15px;
    padding: 10px;
    background-color: white;
}  */
/* .report-main-item{
    display: inline-block;
    padding: 5px 15px;
    position: relative;
    cursor: pointer;
}  */
/* .report-date-picker{
    display: inline-block;
    padding: 5px;
    vertical-align: top;
}  */

.main-reports-form-container{
    background-color: white;
    max-width: 800px;
    padding-bottom: 30px;
}
.report-search-container {
    display: inline-block;
    padding-top: 4px;
}
</style>