<template>
    <div class="header-container" :style="headerHeight">
        <div class="mobile-menu-button-container" @click="showMobileMenu = !showMobileMenu">
            <i class="fas fa-bars"></i>
        </div>
        <div class="mobile-menu-container" v-show="showMobileMenu">
            <div class="user-option loc-name">{{locationName}} - {{selectedLanguage}}</div>
            <div class="user-option" @click="mobileOption('locations')">{{'Locations' | translate}}</div>
            <div v-if="showOptionLocations">
                <div class="location-option" v-for="(location,index) in allLocations" :key="`L-${index}`" @click="setLocation(location)">{{location.name}}</div>
            </div>
            <div class="user-option" @click="mobileOption('language')">{{'Languages' | translate}}</div>
            <div v-if="showOptionLanguages">
                <div class="location-option" v-for="(language,index) in allLanguages" :key="`La-${index}`" @click="setLanguage(language)">{{language}}</div>
            </div>
            <div class="user-option" v-for="(option,index) in userOptions" :key="index" @click="mobileOption(index)">{{option | translate}}</div>
        </div>
        <div class="main-logo-container" v-if="showLogo" >
            <!-- <img class="main-logo" src="/images/SimplifySoftwareBusiness.jpg" v-if="showMainBanner"> -->
            <img class="main-logo" src="/images/Simplify_Software_Business.jpg">
            
        </div>
        <div class="login-logo-container" v-if="showLoginLogo" >
            <!-- <img class="main-logo" src="/images/SimplifySoftwareBusiness.jpg" v-if="showMainBanner"> -->
            <img class="login-logo" src="/images/Simplify_Software_Business.jpg">
            
        </div>
        <div class="company-info-container" v-if="showLocation" :class="{'center-company':setCenter}">
            <div class="company-name" :class="{'title-only': !showLocation}">{{userInfo.name}}</div>
            <div class="location-name" >{{locationName}}</div>

        </div>
        <div class="user-container" v-if="showUser" >
            <div @click="showHideOptions">
            <i class="fas fa-user"></i>{{userInfo.firstname}} {{userInfo.lastname}}
            <div class="language-label">{{selectedLanguage}}</div>
            </div>
            <div class="user-options-container" v-if="showOptions">
                <div class="user-option" v-for="(option,index) in userOptions" :key="index" @click="selectOption(index)">{{option | translate}}</div>
                <div class="user-option" @click="mobileOption('language')">{{'Languages' | translate}}</div>
                <div v-if="showOptionLanguages">
                    <div class="location-option" v-for="(language,index) in allLanguages" :key="`La-${index}`" @click="setLanguage(language)">{{language}}</div>
                </div>
            </div>
        </div>
        <div v-if="showSpinner">
            <div class="spinner-gray-panel"> </div> 
            <div class="spinner-container" :style="setSpinner">
                <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>           
        </div>
        <div v-if="showMessage">
            <div class="message-gray-panel"></div>
            <div class="message-panel" :style="setMessage">
                <div class="message-topbar">
                    <span class="message-title">{{messageTitle | translate}}</span>
                    <!-- <div class="message-close-button" @click="showMessage = false">X</div> -->
                </div>
                <div class="message-content" >
                    {{ messageContent | translate}}
                </div>
                <div class="message-button-row">
                    <div class="button-main small-button" @click="okAction">{{'OK' | translate}}</div>
                </div>
            </div>
        </div>
        <div class="message-setup-panel" v-if="showSetupAlert">
            <div class="setup-title-bar">{{ 'New Business Setup' | translate}}</div>
            <div class="setup-content">There are 3 initial areas that need to be setup before you can begin booking and scheduling </div>
            <div class="setup-item-row" v-for="(item,index) in setupList" :key="index" @click="goSetup(item)">
                <CheckBox 
                    v-bind:checkBoxLabel="''"
                    :cbmodel="item.show"
                    :cbref="item.id"
                    v-on:item-selected="item.show = $event" >
                </CheckBox>
                <div class="setup-item">{{item.name | translate}}</div>
            </div>     
        </div>
    </div>
</template>
<script>
import store from '../../store/store'
import EventBus from '../../utilities/eventBus'
import Globals from '../../utilities/globals'
import CheckBox from '../utility/CheckBox'
export default {
    name: 'Header',
    data() {
        return {
            store,
            showMainBanner: true,
            showNailBanner: false,
            showHairBanner: false,
            showSpaBanner: false,
            showUser: false,
            showOptions: false,
            showLocation: false,
            showMobileMenu: false,
            showOptionLocations: false,
            showOptionLanguages: false,
            showSpinner: false,
            showMessage: false,
            showSetupAlert: false,
            showLogo: true,
            showLoginLogo: false,
            setCenter: false,
            selectedLanguage: this.$store.getters.selectedLanguage,
            locationName: '',
            userInfo: this.$store.getters.user,
            allLocations: [],
            userOptions: ['Profile','Schedule','Log Out'] ,
            allLanguages: ['English','Spanish','Vietnamese','French','German','Italian'],
            messageTitle: '',
            messageContent: '',
            okActionType: 'close',
            headerHeight: {height:'48px'},
            setupList: [
                {name:'Staff', page: 'AddStaff'},
                {name:'Services', page: 'AddService'},
                {name:'Rooms', page: 'AddRoom'}]         
        }
    },
    components:{
        CheckBox
    },
    props: [
        
    ],
    methods: {
        okAction() {
            this.showMessage = false
            if (this.okActionType == 'login') {
                this.$router.push('Login')
            }
        },
        selectOption(index) {
            window.console.log('OPTION SELECTED', index)
            switch(index) {
                case 0:
                     this.$router.push('UpdateProfile')
                    break
                   
                case 1:
                    this.$router.push('PersonalSchedule')
                    break
                case 2:
                    this.$router.push('Login')
                    break
                case 3:
                    
                    break
            }
        },
        bannersFalse() {        
            this.showMainBanner = false
            this.showHairBanner = false
            this.showSpaBanner = false
            this.showNailBanner = false
        },
        setGeneralInfo() {
            if (this.$route.name !== 'Login' && this.$route.name != undefined ) {
                this.showLocation = true
                this.userInfo = this.$store.getters.user
                this.showUser = true
                this.getAllLocations()
                Globals.setBusinessType(this.userInfo.businesstype)
            }            
        },
        setCurrentLocation() { 
            window.console.log('setCurrentLocation',this.userInfo.primarylocation,this.allLocations)
            if (this.userInfo.primarylocation) {
                this.allLocations.forEach(loc => {
                    if (this.userInfo.primarylocation == loc.id) {
                        this.locationName = loc.name
                    }
                })
            } else {
                this.locationName = this.allLocations[0].name
            }               
        },
        getAllLocations() {
            this.$store.dispatch('getLocations').then(response => {
                if(response.data && response.data.length){
                    this.allLocations = response.data
                    this.setCurrentLocation()
                }            
            })           
        },
        mobileOption(item) {
            window.console.log('clicked on', item)
            if (item === 'locations') {
                this.showOptionLocations = !this.showOptionLocations
            } else if (item === 'language') {
                this.showOptionLanguages = !this.showOptionLanguages
            } else {
                this.selectOption(item)
                this.showMobileMenu = false
            }          
        },
        setLocation(location) {
            window.console.log('SET LOCATION', location)
            this.$store.commit('setCurrentLocation', location) 
            this.locationName = location.name
            this.showOptionLocations = false
            this.showMobileMenu = false
            EventBus.$emit('CHANGE_LOCATION',location)
        },
        setLanguage(language) {
            this.$store.commit('setLanguage',language)
            this.selectedLanguage = language
            this.showOptionLanguages = false
            this.showMobileMenu = false
            this.showOptions = false
            Globals.setLanguage(language)
            EventBus.$emit('CHANGE_LANGUAGE',language)
        },
        setupLanguage() {
            this.selectedLanguage = this.$store.getters.selectedLanguage
            Globals.setLanguage(this.selectedLanguage)
            EventBus.$emit('CHANGE_LANGUAGE',this.selectedLanguage)
        },
        checkRoute() {
            this.setGeneralInfo()
            this.setCenter = false
            this.showLogo = true;
            this.showLoginLogo = false;
            this.headerHeight = {height:'48px'}
            // if (this.$route.name && (this.$route.name.includes('PasswordReset') || this.$route.name.includes('Login') || this.$route.name.includes('PasswordReset') || this.$route.name.includes('BusinessSignup') || this.$route.name.includes('AddBusiness'))) {
            window.console.log('ROUTE HEADER NAME', this.$route.name)
            if (Globals.whiteListNav(this.$route.name) || !this.$route.name) {
                this.showUser = false
                this.showLocation = false
                this.userInfo = {}
                this.bannersFalse()
                this.showMainBanner = true
                this.showLogo = false;
                this.showLoginLogo = true;
                this.headerHeight = {height:'90px'}
            }
            if (this.$route.name == 'MedOnboard') {
                this.showLogo = false
                this.showUser = false
                this.setCenter = true
            }
        },
        bodyClickListener(evt) {  
            if (!evt.target.offsetParent || evt.target.offsetParent.className === 'user-options-container' || evt.target.offsetParent.className === 'user-container' ) {
                if (evt.target.innerHTML === 'Languages') {
                    window.console.log('PICK LANGUAGE')
                } else {
                    if (evt.target.innerHTML === 'Profile' || evt.target.innerHTML === 'Schedule' || evt.target.innerHTML === 'Log Out') {
                        window.console.log('ESE',evt.target.innerHTML)
                        this.showOptions = false
                    }                    
                }        
            } else {
                document.body.removeEventListener('click',this.bodyClickListener)
                this.showOptions = false
            }

        },
        showHideOptions(evt) {
            window.console.log('EVENT',evt,evt.target.offsetParent.className, evt.path.includes('div.user-container'))
            if (evt.target.offsetParent.className === 'user-container') {
                if(this.showOptions) {
                    this.showOptions = false
                } else {
                    this.showOptions = true
                    evt.preventDefault();
                    document.body.addEventListener('click', this.bodyClickListener)                    
                }
            }
        },
        goSetup(item) {
            this.$router.push(item.page)
        },
        checkRestart() {
            
            if (this.$store.getters.user.key) {
                Globals.key = this.$store.getters.user.key
            }
            window.console.log('CHECK START', this.$store.getters.user)
            window.console.log('CHECK KEY', Globals.key)
        },
        setMainHeight() {
            console.log('MAIN CONTAINER',this.$refs['main-container'])
        }

    },
    mounted() {
        this.checkRestart()
        this.setGeneralInfo()
        this.setupLanguage()
        let vm = this
        EventBus.$on('USER_LOGIN',function() {            
            vm.userInfo = vm.$store.getters.user
            vm.showSpinner = false
            window.console.log('USER LOGGED IN', vm.userInfo.businesstype)
            vm.setGeneralInfo()
            vm.showUser = true
            vm.bannersFalse()
            if (vm.userInfo.businesstype === 'Nail Salon') {
                window.console.log('NAIL')
                vm.showNailBanner = true
            }
            if (vm.userInfo.businesstype === 'Hair Salon') {
                vm.showHairBanner = true
            }
            if (vm.userInfo.businesstype === 'Med Spa') {
                vm.showSpaBanner = true
            }
            if(!vm.userInfo.id){
                vm.showMainBanner = true
            }
        })
        EventBus.$on('SET_LOCATION', (location) => {
            window.console.log('SET LOCATION TO', location)
            vm.showLocation = true
            vm.locationName = location
        })
        EventBus.$on('SHOW_SPINNER',() => {
            vm.showSpinner = true
        })
        EventBus.$on('HIDE_SPINNER',() => {
            vm.showSpinner = false
        })
        EventBus.$on('SHOW_MESSAGE',(data) => {
            window.console.log('SHOW MESSAGE', data, data.message)
            vm.messageTitle = data.title
            vm.messageContent = data.message
            vm.showMessage = true
            vm.okActionType = data.okAction
        })
        EventBus.$on('SHOW_SETUP_ALERT',() => {
            vm.showSetupAlert = true
        })
        EventBus.$on('HIDE_SETUP_ALERT',() => {
            vm.showSetupAlert = false
        })

        vm.checkRoute()
    },
    computed: {
        setSpinner() {
            return {
                marginLeft: ((window.innerWidth/2) - 100) + 'px',
                marginTop: ((window.innerHeight/2)-100) + 'px'
            }
        },
        setMessage() {
            return {
                left: ((window.innerWidth/2) - 150) + 'px',
                top:((window.innerHeight/2) - 150) + 'px'
            }
        }
    },
    watch: {
        $route() {
            this.checkRoute()
            this.setMainHeight()
        }
    }
}
</script>

<style scoped>

.header-container{
    background-color: white;
    width: 100%;
    text-align: left;
    /* border-top: 3px solid #42add8; */
}
.main-logo-container{
    display: inline-block;
}
.main-logo{
    display: inline-block;
    height: 40px;
    padding-top: 4px;
}
.login-logo{
    display: inline-block;
    height: 60px;
    padding-top: 4px;
}
.fa-user{
    padding-right: 5px;
    vertical-align: top;
    font-size: 12px;
    margin-top: 4.5px;
    color: #9853b3;
}
.user-options-container{
    position: absolute;
    width: 130px;
    background-color: white;
    border: 1px solid #7796a2;
    z-index: 6;
    right: 12px;
}
.user-option{
    text-align: center;
    height: 20px;
    padding: 5px;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
    color: #4c81a9;
    cursor: pointer;
}
.user-option:last-child{
    border-bottom: none;
}
.user-container{
    padding: 9px 14px;
}
.location-option {
    text-align: center;
    height: 20px;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #a8abaf;
    color: #4c81a9;
    cursor: pointer;
    background-color: #f6f9ff;
}
.company-name{
    font-weight: 700;
    font-size: 18px;
    color: #5509b2;
}
.location-name{
    font-size: 14px;
}
.title-only{
    padding-top: 10px;
}
.mobile-menu-container{
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 50px;
    display: none;
}
.loc-name{
    font-weight: 600;
    color: #5800ff;
    border-bottom: 2px solid #5800ff;
}
.language-label{
    font-size: 11px;
    text-align: center;
    color: #0d84b3;
    font-weight: 600;
    position: relative;
    top: -5px;
    left: -8px;
}

.spinner-gray-panel{
    background-color: #c8d5db;
    opacity: .4;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.message-gray-panel{
    background-color: black;
    opacity: .4;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    top: 0;
}
.message-panel{
    width: 300px;
    min-height: 200px;
    position: absolute;
    z-index: 6;
    background-color: white;
    border: 1px solid black;
}
.message-topbar{
    height: 23px;
    line-height: 23px;
    text-align: center;
    color: white;
    background-color: #0a88b3;;
}
.message-close-button{
    float: right;
    padding-right: 10px;
    cursor: pointer;
}
.message-content{
    padding: 15px;
}
.message-button-row{
    text-align: center;
}
.spinner-container{
    position: absolute;
    z-index: 2;
}
.fa-spinner{
    color: #6e00ff;
    font-size: 50px;
}
.company-info-container{
    position: absolute;
    left: 36%;
}
.message-setup-panel{
    width: 190px;
    position: absolute;
    right: 0;
    top: 136px;
    background-color: white;
    border: 1px solid #00b9ff;
    z-index: 10;
}
.setup-title-bar{
    background-color: #6200ff;
    height: 25px;
    color: white;
    text-align: center;
    line-height: 25px;
    font-size: 16px;
}
.setup-content{
    padding: 10px;
    font-size: 14px;
}
.checkbox-container{
    margin-top: 0;
}
.setup-item-row{
    border-top: 1px solid #1d7cbf;
    height: 30px;
    padding: 10px 10px 0 10px;
    cursor: pointer;
}
.setup-item{
    display:inline-block;
    position: relative;
    top: 3px;
    color: #6200ff;
}
.center-company{
    width: 100%;
    position: initial;
}
.login-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 680px) {
    .mobile-menu-container{
        display: block;
    }
    .company-info-container{
        display: none !important;
    } 
    
}
    
</style>