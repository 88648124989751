<template>
    <div>
        <div class="quicklist-container">
            <div class="quicklist-title-bar" >
                {{listTitle | translate | allCaps}}
            </div>
            <div v-for="(title,index) in titleGroup" :key="index">
                <div class="quick-option-bars" @click="showQL(title)">
                    {{title | translate | allCaps}}
                    <i class="fas fa-caret-right" v-if="showQLDetails(title)"></i>
                    <i class="fas fa-caret-down" v-if="!showQLDetails(title)"></i>                
                </div>
                <div v-if="showQLDetails(title)">
                    <div class="quicklist-item-row" v-for="(item,index) in itemList" :key="index">
                        <CheckBox
                            class="quick-checkbox"
                            v-bind:checkBoxLabel="''"
                            :cbmodel="item.show"
                            :cbref="item.id"
                            v-on:item-selected="item.show = $event" >
                        </CheckBox>
                        <div class="quicklist-item name-item">{{item.name}}</div>
                        <div class="quicklist-item">{{item.len}} {{'Min' | translate}}</div>
                        <div class="quicklist-item">{{item.price | toUSD}}</div>
                    </div>
                    <div class="quicklist-button-row">
                        <ButtonBar 
                            :buttonlist="['Add Selected']"
                            v-on:button-click="buttonClicked = $event" 
                        />
                    </div>               
                </div>
            </div>            
        </div>
        <div class="quicklist-button-row" v-if="showSuccess">
            <div class="success-label">{{'Service' | translate}}(s) {{'Successfully Added' | translate}}</div>
        </div>
    </div>
</template>

<script>
import QuickList from '../../data/quicklist.json'
import CheckBox from './CheckBox'
import ButtonBar from './ButtonBar'
import store from '../../store/store'

export default {
    name: 'QuickList',
    data() {
        return {
            listTitle: 'Quicklist',
            itemList: [],
            showQuickList: false,
            showItems: false,
            showSuccess: false,
            buttonList: ['Add','Cancel'],
            buttonClicked: {},
            store,
            titleGroup: [],
            selectedList: ''
        }
    },
    props: [ 
        'existingServices'
    ],
    components: {
        CheckBox,
        ButtonBar
    },
    methods: {
        setShowSuccess() {
            this.showSuccess = true
            setTimeout(() => {
                this.showSuccess = false
            },3000)
        },
        setBusinessType() {
            window.console.log('SET BUZ TYPE',this.$store.getters.user )
            Object.keys(QuickList).forEach(key => {               
                QuickList[key].forEach(item => {
                    item.type = key
                })
                this.titleGroup.push(key)
            })
            window.console.log('title',this.titleGroup)            
        },
        filterList() {
            const allServices = this.$store.getters.allServices
            
            this.itemList = this.itemList.filter(item => {
                return !allServices.some(service => {
                    return service.quicklistref == item.id
                })
            })
            window.console.log('Item LIST',this.itemList)
        },
        showQL(list) {
            if (this.selectedList === list) {
                this.selectedList = ''
            } else {
                this.selectedList = list
                // this.itemList = QuickList[list]
                this.itemList = this.sortCollection(QuickList[list])
                this.checkItemListExisting()
                window.console.log('EXIStiNG',this.existingServices)           
            } 
            
        },
        checkItemListExisting() {
            this.itemList = this.itemList.filter(item => {
                let exists = false
                this.existingServices.forEach(service => {
                    if (service.quicklistref && service.quicklistref == item.id){
                        exists = true
                    }
                })
                if (!exists) {
                    return item
                }
            })
        },
        sortCollection(collection) {
            let list = Array.prototype.slice.call(collection)
            list.sort(function(a, b) {
                if (a.name < b.name) {
                    return -1;
                } else {
                    return 1;}
            })
            return list
        },
        showQLDetails(list) {
            if (list === this.selectedList) {
                return true
            } 
            return false
        }
    },
    watch: {
        buttonClicked() {
            window.console.log('BUT CLICKED', this.buttonClicked)
            if (this.buttonClicked.name === 'Add' || this.buttonClicked.name === 'Add Selected') {                
                const selectedList = this.itemList.filter(item => item.show == true)
                window.console.log('BUT Add', this.itemList, selectedList)
                this.$emit('add-selected',selectedList)
                this.setShowSuccess()
                this.selectedList = ''
            }
            
            if (this.buttonClicked.name === 'Cancel') {
                this.showQuickList = false
            }
        }
    },
    beforeMount() {
        this.setBusinessType()
        this.filterList()
    },
    
}
</script>

<style scoped>
.quicklist-container{
    margin: 0 5%;
    /* border: 1px solid #1d7cbf; */
    margin-bottom: 15px;
} 
.quicklist-title-bar{
    height: 23px;
    color: white;
    background-color: #643cc5;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    cursor: pointer;
} 
.quicklist-item-row{
    border-bottom: 1px solid #1d7cbf;
    height: 30px;
    padding-bottom: 3px;
    line-height: 24px;
} 
.quicklist-item-row:last-child{
    border-bottom: none;
}
.quicklist-item{
    display: inline-block;
    width: 15%;
    font-size: 14px;
    margin-top: 6px;
    vertical-align: bottom;
}
.quick-option-bars{
    height: 25px;
    background-color: #4f71a4;
    color: white;
    line-height: 25px;
    border-bottom: 1px solid white;
    cursor: pointer;
}
.title-indicator-group{
    display: inline-block;
    padding-left: 20px;
}
.name-item{
    text-align: left;
    font-weight: 600;
    width: 55%;
}
.quick-checkbox{
    width: 50px;
    position: relative;
    top: 5px;
}
</style>