<template>
    <div class="invoice-container">
        <form action="" id="myForm">
            <div class="invoice-title-container">
                <div class="invoice-title-item">{{ user.name }}</div>
                <div class="invoice-title-item-phone">{{ globals.formatPhone(this.$store.getters.user.businessphone) }}</div>
                <div class="invoice-title-item-email">{{ this.$store.getters.user.businessemail }}</div>
            </div>
            <div class="invoice-des-container">
                <div class="invoice-des-item"><span class="des-item-term">Invoice# </span><b>{{ invoiceData.invoicenum }}</b></div>
                <div class="invoice-des-item"><span class="des-item-term">To: </span><b>{{ this.invoiceData.client }} </b></div>
                <div class="invoice-des-item"><span class="des-item-term">Booking Date: </span><b>{{ globals.formatSelectedDate(invoiceData.bookingdate) }}</b></div>
                <div class="invoice-des-item"><span class="des-item-term">Date Paid: </span><b>{{ globals.formatSelectedDate(invoiceData.datepaid) }}</b></div>
                <div class="invoice-des-item"><span class="des-item-term">Provider: </span><b>{{ invoiceData.staff }}</b></div>
            </div>
            <div class="invoice-services-container">
                <div class="serice-title-row">
                    <div class="service-title title-wide">Service/Product</div>
                    <div class="service-title item-small">Qty</div>
                    <div class="service-title item-small">Credit</div>
                    <div class="service-title">Price</div>
                    <div class="service-title">Total</div>
                </div>
                <div class="service-item-row" v-for="(item,index) in invoiceData.invoicesaleitems" :key="index">
                    <div class="service-item item-wide">{{ item.name }}</div>
                    <div class="service-item item-small">{{ item.quantity }}</div>
                    <div class="service-item item-small">{{ item.credit }}</div>
                    <div class="service-item">{{ globals.toUSD(item.saleprice) }}</div>
                    <div class="service-item">{{ globals.toUSD(item.total) }}</div>
                </div>
            </div>
            <div class="invoice-payment-container">
                <div class="payment-row"><div class="payment-label">Item Totals: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.productservicetotal) }}</div></div>
                <div class="payment-row"><div class="payment-label">Sales Tax: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.taxservice + invoiceData.taxproduct) }}</div></div>
                <div class="payment-row"><div class="payment-label">Tip: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.tip)  }}</div></div>
                <div class="payment-row" v-if="invoiceData.creditapplied"><div class="payment-label">Credit Applied: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.creditapplied)  }}</div></div>
                <div class="amt-seperator">__________</div>
                <div class="payment-row"><div class="payment-label">Total: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.productservicetotal + invoiceData.taxservice + invoiceData.taxproduct + invoiceData.tip) }}</div></div>
                <div class="payment-row"><div class="payment-label">Amount Paid: </div><div  class="payment-amount">{{ globals.toUSD(invoiceData.amountpaid) }}</div></div>
                <div class="amt-seperator">__________</div>
                <div class="payment-row"><div class="payment-label">Balance: </div><div  class="payment-amount">{{ globals.toUSD((invoiceData.productservicetotal + invoiceData.taxservice + invoiceData.taxproduct + invoiceData.tip) - (invoiceData.creditapplied + invoiceData.amountpaid)) }}</div></div>
                <div class="payment-row"><div class="payment-label"></div><div  class="payment-amount"></div></div>
                <div class="payment-row"><div class="payment-label">Paid By: </div><div  class="payment-amount">{{ invoiceData.paytype }}</div></div>
                <div class="payment-row" v-if="invoiceData.cardtype"><div class="payment-label">Card Type: </div><div  class="payment-type">{{ invoiceData.cardtype }}</div></div>
                <div class="payment-row" v-if="invoiceData.checknumber"><div class="payment-label">Check #: </div><div  class="payment-type">{{ invoiceData.checknumber }}</div></div>

            </div>
        </form>
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import store from '../../store/store';
import globals from '../../utilities/globals';
export default {
    data() {
        return {
            store,
            data: {},
            globals: globals,
            user: this.$store.getters.user,
            buttonList: ['Print', 'Cancel'],
            buttonClicked: '',
        }
    },
    components: { },
    props: ['invoiceData'],
    methods: {
        
        async generatePDF() {
            // Generate PDF using html2pdf
            const formContent = this.$el.querySelector('form');
            const pdfOptions = {
                margin: 10,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            try {
                const pdf = await html2pdf().from(formContent).set(pdfOptions).outputPdf();
                // To generate and save locally
                html2pdf().from(formContent).set(pdfOptions).save()
            
                this.$store.dispatch('uploadInvoice',{pdfData: pdf}).then(response => {
                    console.log('RESPONSE FROM UPLOAD INVOICE', response)
                    this.$emit('close-form')
                })
            } catch (error) {
                console.error(error);
            }
        },
        createTitle() {
            this.invoiceData.businessname = this.user.name
            this.invoiceData.businessphone =this.globals.formatPhone(this.$store.getters.user.businessphone)
            this.invoiceData.businessemail = this.$store.getters.user.businessemail
            // this.generatePDF()
        }
        
    },
    mounted() {
        this.createTitle()
        console.log('create title mount')
        // console.log('MOUNTED FOORM',this.$store.getters.user,'DATA', this.invoiceData)     
    },
    watch: {
        invoiceData () {
            window.console.log('INVOICE DATA CAHNGED', this.invoiceData)
            
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Print') {
                console.log('Print Invoice')
            }
            if (this.buttonClicked.name === 'Cancel') {
                console.log('Print Invoice')
            }
        }
        
    },


}
</script>

<style scoped>
form {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
.invoice-title-container{
    text-align: center;
}
.invoice-services-container {
    text-align: center;
}
.invoice-des-item{
    text-align: left;
    padding: 5px 20px 0 50px;
    font-size: 16px;
}
.invoice-title-item {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
}
.invoice-title-item-phone {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}
.invoice-title-item-email {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 100px;
}
.invoice-payment-container{
    padding-left: 30%;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    padding-top: 30px;
}
.payment-label{
    width: 100px;
    display: inline-block;
    text-align: right;
}
.payment-amount{
    width: 100px;
    text-align: right;
    display: inline-block;
    font-weight: 700;
}

.payment-type {
    width: 250px;
    text-align: left;
    display: inline-block;
    font-weight: 700;
    padding-left: 10px;
}
.service-item-row{
    margin: 0 10.5%;
    border: 1px solid lightgray;
    border-top: none;
}
.service-item{
    display: inline-block;
    /* width: 94px; */
    padding: 3px 6px;
    vertical-align: top;
    text-align: right;
    width: 16%;
}
.serice-title-row {
    margin: 40px 10% 0 10%;
}
.service-title {
    display: inline-block;
    border: 1px solid lightgray;
    border-top: none;
    border-bottom: none;
    /* width: 100px; */
    padding: 3px;
    width: 16%;
}
.title-wide{
    /* width: 200px; */
    width: 45%;
}
.item-wide{
    /* width: 195px; */
    width: 41%;
}
.item-small{
    width: 8%;
}
.amt-seperator {
    height: 6px;
    line-height: 3px;
    text-align: right;
    width: 200px;
    position: relative;
    bottom: 7px;
}
.invoice-container {
    width: 100%;
    /* position: fixed; */
    /* background: white;
    top: 0;
    left: 0;
    z-index: 20; */
    /* height: 100%; */
}

</style>