import { render, staticRenderFns } from "./ClientAdd.vue?vue&type=template&id=2a61c16c&scoped=true&"
import script from "./ClientAdd.vue?vue&type=script&lang=js&"
export * from "./ClientAdd.vue?vue&type=script&lang=js&"
import style0 from "./ClientAdd.vue?vue&type=style&index=0&id=2a61c16c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a61c16c",
  null
  
)

export default component.exports