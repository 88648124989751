<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Add Expense' | translate | allCaps}}</div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Description' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="expense.description"/>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!expense.description)">*{{'You need to enter a' | translate}} {{'Description' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Expense Date' | translate}}:<span class="required-entry">*</span></label> 
            <div class="expense-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="expenseDate = $event" 
                    v-bind:mydate="expenseDate"
                    :placeholder = "'Select Date'">
                </DatePicker>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && !expenseDate.day">*{{'You need to select a' | translate}} {{'Date' | translate}}</div>
        
        <div class="form-input-row">
            <label class="form-input-label" >{{'Type' | translate}}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="expenseType = $event" 
                v-bind:selected="expenseType.name" 
                v-bind:optioncontent="expenseTypes"
                :placeholder="existingExpenseType"/>
                <div class="form-content-item" v-if="isPaid" >{{this.expenseType}}</div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!expenseType.name)">*{{'You need to select a' | translate}} {{'Expense Type' | translate}}</div>

        <div class="form-input-row">
            <div class="form-input-label">{{'Amount' | translate}}: </div>
            <InputCurrency
                v-on:amount-entered="expense.amount = $event"
                :amount="expenseAmount"
             ></InputCurrency>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!expense.amount)">*{{'You need to enter a' | translate}} {{'Expense Amount' | translate}}</div>
        
         <div class="form-input-row">
            <label class="form-input-label" >{{'Payment Type' | translate}}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="paymentType = $event" 
                v-bind:selected="paymentType.name" 
                v-bind:optioncontent="paymentTypes"
                :placeholder="existingPaymentType"/>
                <div class="form-content-item" v-if="isPaid" >{{this.expense.paytype}}</div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!paymentType.name)">*{{'You need to select a' | translate}} {{'Payment Type' | translate}}</div>

        <div class="form-input-row" v-if="paymentType.name === 'Check'">
            <label class="form-input-label" >{{'Check Number' | translate }}</label>
            <input v-model="checknumber" v-if="!isPaid" />
            <div class="form-content-item" v-if="isPaid" >{{this.expense.checknumber}}</div>
        </div>
        <div class="form-input-row" >
            <label class="form-input-label" >{{'Receipt' | translate }}</label>
            <div class="camera-button" @click="takePicture = true" >
                    <i class="fas fa-camera"></i>
                </div>      
        </div>
        <div class="receipt-image-container" v-if="receiptImage">
            <img v-bind:src="`${imageURL}${receiptImage}-th.jpg`" class="receipt-image" height="100" @click="previewImage()" />
        </div>
        <div class="image-capture" :style="getCaptureStyle" >
                <CaptureComponent                   
                    :takeImage="takePicture"
                    :showImage="showImage"
                    :refImage="receiptImageRef"
                    v-on:close-edit="closeCapture = $event"
                    v-on:image-saved="receiptImageRef = $event"
                />
            </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" >
        </ButtonBar>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Expense' | translate}} {{'Successfully Submitted' | translate}}</div>
        </div>  
    </div>
</template>

<script>
import store from '../../store/store'
import InputCurrency from '../utility/InputCurrency'
import Constants from '../../utilities/constants'
import Dropdown from '../utility/Dropdown'
import ButtonBar from '../utility/ButtonBar'
import CaptureComponent from '../utility/ImageCaptureReceipt'
import Globals from '../../utilities/globals'
import DatePicker from '../utility/DatePicker'

export default {
    name: 'ExpensesAdd',
    data() {
        return {
            expense: {},
            store,
            expenseAmount: 0,
            paymentType: {},
            expenseType: {},
            expenseDate: {},
            paymentTypes: Constants.paymentTypes,
            expenseTypes: Constants.expenseTypes,
            isPaid: false,
            existingPaymentType: 'Select Payment Type',
            existingExpenseType: 'Select Expense Type',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            showSuccess: false,
            showImageEdit: false,
            showCamera: true,
            showCapture: false,
            showImage: false,
            showErrors: false,
            takePicture: false,
            closeCapture: '',
            receiptImageRef: '',
            receiptImage: '',
            imageURL: Globals.imageURL,
            checknumber: ''
        }
    },
    components:{
        InputCurrency,
        Dropdown,
        ButtonBar,
        CaptureComponent,
        DatePicker
    },
    methods:{
        captureImage() {
            if (this.takePicture) {
                this.takePicture = false
            } 
            this.takePicture = true
        },
        previewImage(){
            window.console.log('PREVIEW IMAGE',this.receiptImageRef)
            this.takePicture = false
            this.showImage =true
        },
        setupTakePicture(){
            this.takePicute = true
            // this.showCapture = true
        },
        validate() {
            window.console.log('VALIDATE')
            if (!this.expense.description || !this.expenseType.name || !this.expense.amount || !this.paymentType.name) {
                this.showErrors = true
                window.console.log('SHOW ERRORS',!this.expense.description, !this.expenseType.name, !this.expense.amount, !this.paymentType.name)
            } else {
                this.submitExpense()
                window.console.log('TO SUBMIT')
            }
        },
        submitExpense() {
            this.expense.type = this.expenseType.name
            this.expense.receiptref = this.receiptImageRef
            this.expense.checknumber = this.checknumber
            this.expense.paytype = this.paymentType.name
            this.expense.date = Globals.formatDBDate(this.expenseDate.day,this.expenseDate.month,this.expenseDate.year)
            window.console.log('SUBMIT EXPENSE',this.expense) 
            this.$store.dispatch('addExpense',this.expense).then(response => {
                window.console.log('SUBMIT EXPENSE RESULT', response)
                if (response.result == 'SUCCESS') {
                    this.showSuccess = true
                    this.clearEntry()
                }
            })          
        },
        clearEntry() {
            this.expense = {}
            this.expenseType = {}
            this.receiptImageRef = ''
            this.checknumber = ''
            this.paymentType = {}
            this.receiptImage = ''
            this.expenseAmount = ''
            this.expenseDate = {}
        }
    },
    computed: {
        getCaptureStyle() {
            window.console.log('CHECK CAPTURE STYLE',this.takePicture, this.showImage )
            if (this.takePicture || this.showImage) {
                return {
                    height: '100%',
                    top: 0
                }
            }
            return {
                    height: 0,
                    top: '-18px'
                }
        }
    },
    watch: {
        closeCapture() {
            window.console.log('CLOSE CAPtURE',this.closeCapture)
            if(!this.closeCapture) {
                this.takePicture = false
                this.showImage = false
                this.closeCapture = true
            }
        },
        receiptImageRef() {
            window.console.log('IMAGE SAVED REF',this.receiptImageRef)
            setTimeout(() => {
                this.receiptImage = this.receiptImageRef
                window.console.log('SHOW RECEIPT IMAGE', this.receiptImage)
            }, 2000);           
            this.closeCapture = true
            this.takePicture = false
        },
        buttonClicked() {
            window.console.log('this.buttonClicked.name',this.buttonClicked.name)
            if (this.buttonClicked.name === 'Submit') {
                this.validate()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
            
        }
    }

}
</script>

<style scoped>
.image-capture{
    position: absolute;
    top: -18px;
    left: 0;
    z-index: 5;
    width: 100%;
    padding: 10px;
    background-color: white;
} 
.camera-button{
    width: 30px;
    height: 30px;
    background-color: #63b4c5;
    border-radius: 15px;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 8px;
    cursor: pointer;
    position: relative;
}
.fa-camera{
   font-size: 15px;
    padding: 8px;
    color: white; 
}
.receipt-image-container{
    display: block;
    cursor: pointer;
} 
.expense-date-picker{
    display:inline-block;
}


</style>