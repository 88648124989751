<template>
    <div>
        <div>
            <div class="info-name">{{clientInfo.firstname}} {{clientInfo.lastname}}
                <div class="button-icon small" @click="showEntry = !showEntry">
                    <i class="fas fa-pen"></i>
                </div>
            </div>
            <div v-if="!showEntry" class="client-info-block">
                <div class="info-row">{{clientInfo.phone | formatPhone}} | {{clientInfo.email }}<div class='missing-message' v-if="showNoEmail">{{'No Email Available' | translate}}</div></div>
                <div class="info-row">{{clientAddress}}</div>
                <div class='missing-message' v-if="showNoAddress">{{'No Address Available' | translate}}</div>
                <div class="info-row">{{pharmacyInfo}}</div>
                <div class='missing-message' v-if="showNoPharmacy">{{'No Pharmacy Info Available' | translate}}</div>

            </div>
        </div>
        <div v-if="showEntry" class="info-form">
            <div class="form-input-row" >
                   
                    <Dropdown 
                        v-on:item-selected="clientPrefix = $event" 
                        v-bind:selected="clientPrefix.name" 
                        v-bind:optioncontent="allPrefixes"
                        :placeholder="clientPrefixPlaceholder">
                    </Dropdown>
                
                <div class="form-row-item">
                    <input class="input-medium" v-model="clientInfo.firstname"/>
                </div>

                <div class="form-row-item">
                    <input class="input-medium" v-model="clientInfo.lastname"/>
                </div>
            </div>
           
            <!-- CONTACT INFO -->
            <label class="item-label">{{'Contact Info' | translate}}</label> 
            <PhoneNumber 
                v-on:number-updated="clientInfo.phone = $event" 
                :required="true"
                :showlabel="false"
                :existingnumber="clientInfo.phone"/>
            <div class='missing-selection-message' v-if="showError && !clientInfo.phone">*{{'You need to enter a' | translate}} {{'Phone Number' | translate}}</div>

            <div class="form-input-row">
                
                <input class="input-wide" v-model="clientInfo.email" placeholder="Email"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !clientInfo.email">*{{'You need to enter a' | translate}} {{'Email' | translate}}</div>
            <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>

            <!-- ADDRESS INFO -->
            <label class="item-label">{{'Address' | translate}}</label> 
            <div class="form-input-row">
                <input class="input-wide"  v-model="clientInfo.street" placeholder="Street"/>
            </div>
            <div class="form-input-row">
                
                <input  v-model="clientInfo.address2" placeholder="Address 2"/>
                <div class="info-zip">
                    <ZipLookup 
                        v-on:zip-found="zipInfo = $event"
                        :existingzip ="existingZip"
                    />
                </div>
            </div>
            <label class="item-label">{{'Pharmacy Info' | translate}}</label> 
            <div class="form-input-row">
                <input class="input-wide" v-model="clientInfo.pharmacy" :placeholder="'Pharmacy' | translate"/>    
            </div>
            <div class="form-input-row">
                <PhoneNumber 
                    v-on:number-updated="clientInfo.pharmacyphone = $event" 
                    :existingnumber="clientInfo.pharmacyphone"
                    :showlabel="false"
                    :phonelabel="'Pharmacy Phone'"/>
            </div>
            <label class="item-label">{{'Emergency Info' | translate}}</label> 
            <div class="form-input-row">
                <input class="input-wide"  v-model="clientInfo.emergencyname" :placeholder="'Emergency Contact Name' | translate"/>
                
            </div>
            <div class="form-input-row">
                <input class="input-wide"  v-model="clientInfo.emergencyrelation" :placeholder="'Emergency Contact Relation' | translate"/>
                
            </div>
            
            <div class="form-input-row">
                <PhoneNumber 
                    v-on:number-updated="clientInfo.emergencyphone = $event" 
                    :showlabel="false"
                    :existingnumber="clientInfo.emergencyphone"
                    :placeholder="'Emergency Phone'"/>
            </div>
            <div class="form-input-row dropdown-row">
                <label class="item-label dropdown-label">{{'How Did You Hear About Us' | translate}}?:</label> 
                <Dropdown 
                    v-on:item-selected="clientLearn = $event" 
                    v-bind:selected="clientLearn.name" 
                    v-bind:optioncontent="learnAboutList"
                    :placeholder="clientLearn.name">
                </Dropdown>
            </div>
            <!-- <div class="form-input-row" v-if="clientLearn.ref">
                <input  v-model="clientInfo.learnref" :placeholder="clientLearn.ref"/>
            </div> -->

            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Client Info' | translate}} {{'Successfully Updated' | translate}}</div>
            </div>
            <button-bar 
                :buttonlist="buttonList"
                 v-on:button-click="buttonClicked = $event" 
            />
        </div>
    </div>
</template>

<script>
import Dropdown from '../utility/DropdownInline'
import ZipLookup from '../utility/zipLookupInline'
import PhoneNumber from '../utility/PhoneNumberInline'
import Globals from '../../utilities/globals'
import store from '../../store/store'
import Constants from '../../utilities/constants'
import ButtonBar from '../utility/ButtonBar'

export default {
    name: 'ClientInfoUpdate',
    data() {
        return {
            store,
            clientPrefix:{},
            allPrefixes: Constants.prefixes,
            client: {},
            clientPrefixPlaceholder: 'Select',
            existingZip: this.clientInfo.zip,
            showError: true,
            showEmailError: false,
            showNoEmail: false,
            showNoAddress: false,
            showNoPharmacy: false,
            showEntry: false,
            showSuccess: false,
            clientAddress: '',
            pharmacyInfo: '',
            zipInfo: {},
            buttonList: ['Cancel','Update Info'],
            buttonClicked:{},
            learnAboutList: Constants.learnAbout,
            clientLearn: {}         
        }
    },
    components: {
        Dropdown,
        ZipLookup,
        PhoneNumber,
        ButtonBar
    },
    props: ['clientInfo'],
    methods: {
        updateClient() {
            this.showSuccess = false
            this.$store.dispatch('updateClient',this.clientInfo).then(response => {
                if (response.result == 'SUCCESS') {
                    this.showSuccess = true
                    setTimeout(() => {
                        this.showEntry = false
                        this.setData()
                    },Constants.waitTime)
                }
            })
        },
        setData() {
            this.showNoEmail = false
            this.showNoAddress = false
            this.showNoPharmacy = false
            this.showSuccess = false
            this.clientLearn = {}
            if (this.clientInfo.street) {
                this.clientAddress = this.clientInfo.street
                if (this.clientInfo.address2) {
                    this.clientAddress += ', ' + this.clientInfo.address2
                }
                if (this.clientInfo.city) {
                    this.clientAddress += ', ' + this.clientInfo.city
                }
                if (this.clientInfo.state) {
                    this.clientAddress += ' ' + this.clientInfo.state
                }
                if (this.clientInfo.zip) {
                    this.clientAddress += ' ' + this.clientInfo.zip
                }
            } else {
                this.showNoAddress = true
            }
            if (!this.clientInfo.email) {
                this.showNoEmail = true
            } 
            if (Globals.businessType == 'medspa') {
                if (!this.clientInfo.pharmacy && !this.clientInfo.pharmacyphone ) {
                    this.showNoPharmacy = true
                } else {
                    if (this.clientInfo.pharmacy) {
                        this.pharmacyInfo = Globals.translate('Pharmacy') + ': ' +this.clientInfo.pharmacy
                        if (this.clientInfo.pharmacyphone) {
                            this.pharmacyInfo += " | " + Globals.formatPhone(this.clientInfo.pharmacyphone)
                        }
                    } else if (this.clientInfo.pharmacyphone) {
                        this.pharmacyInfo = Globals.translate('Pharmacy Phone') + ': ' + Globals.formatPhone(this.clientInfo.pharmacyphone)
                    }
                }        
            }
            this.clientPrefixPlaceholder = this.clientInfo.prefix ? this.clientInfo.prefix : ''  
            this.clientLearn.name = this.clientInfo.learn ? this.clientInfo.learn : ''
            this.clientLearn.ref = this.clientInfo.learn ? this.clientInfo.learn : ''         
        }
    },
    watch: {
        clientInfo() {
            this.setData()
        },
        zipInfo() {
            this.clientInfo.zip = this.zipInfo.zip
            this.clientInfo.city = this.zipInfo.city
            this.clientInfo.state = this.zipInfo.state
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Update Info') {
                this.updateClient()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.showEntry = false
            }
        },
        clientLearn() {
            this.clientInfo.learn = this.clientInfo.learn ? this.clientInfo.learn : this.clientLearn.name
        },
        clientPrefix() {          
            this.clientInfo.prefix = this.clientPrefix.name
            this.clientPrefixPlaceholder = this.clientPrefix.name
        }
    },
    mounted() {
        this.setData()
    },
}
</script>

<style scoped>
input {
    padding: 5px;
    height: 20px;
    border: 1px solid black;
}
.form-row-item{
    display: inline-block;
    padding-left: 10px;
    position: relative;
    top: -2px;
} 
.info-name{
    font-size: 16px;
    font-weight: 600;
    padding: 3px;
}
.info-row{
    font-size: 14px;
    padding: 2px;
}
.info-form{
    border-top: 1px solid gray;
    margin-top: 5px;
    padding: 10px;
    border-bottom: 1px solid;
    margin-bottom: 10px;
} 
.info-zip{
    display: inline-block;
    position: relative;
} 
.missing-message{
    display: inline-block;
    color: red;
    font-size: 14px;
}
.client-info-block{
    padding-bottom: 5px;
}
.small{
    font-size: 12px;
}
.form-input-row{
    height: 35px;
    text-align: center;
    margin: 5px;
}
.dropdown-row {
    height: 50px;
}
.dropdown-label {
    margin: 5px;
}
.item-label {
    display: block;
    font-size: 14px;
    font-weight: 600;

}
</style>