<template>
    <div>
        <!-- <div class="load-back" :style="getHeight()"></div> -->
        <div class="form-container front">
            <div class="close-symbol" @click="hideEntry">+</div>
            <div class="form-content" @input="clearErrors">
                <h2>{{'Add Client'}}</h2>
            
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.firstname" placeholder="First Name"/>
                    <div class="required-symbol">*</div>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.firstname ">
                    *{{'You need to enter a'}} {{'First Name'}}
                </div>

                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.lastname" placeholder="Last Name"/>
                    <div class="required-symbol">*</div>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.lastname ">
                    *{{'You need to enter a'}} {{'Last Name'}}
                </div>

                <div class="form-input-row">
                    <input class="form-input med-width" type="email" v-model="user.email" placeholder="Email Address"/>
                    <div class="required-symbol">*</div>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !validEmail ">
                    *{{'You need to enter a valid Email Address'}}
                </div>

                <div class="form-input-row">
                    <input 
                        class="form-input med-width" 
                        type="phone" v-model="user.phone" 
                        placeholder="Phone" 
                        @input="formatPhoneNumber"/>
                        <div class="required-symbol">*</div>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !validPhone ">
                    *{{'You need to enter a valid Phone Number'}}
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.pharmacy" placeholder="Pharmacy" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.pharmacyphone" placeholder="Pharmacy Phone" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.street" placeholder="Street" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.address2" placeholder="Address2" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.city" placeholder="City" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.state" placeholder="State" />
                </div>
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.zip" placeholder="Zip" />
                </div>
                
                
            </div>
                <div class="button-row">
                    <div class="submit-button" @click="verifyEntry">{{'Submit'}}</div>
                </div>
                <div class="button-row single">
                    <div class="submit-button cancel-style" @click="hideEntry">Cancel</div>
                </div>
                <div class="form-input-row" v-show="showSuccess">
                    <div class="success-label">{{'New Client Successfully Added'}}</div>
                </div> 
                <div class="form-input-row" v-show="showExists">
                    <div class="fail-label">{{'Client Email Already Exists'}}</div>
                </div> 
                <div class="form-input-row" v-show="showFail">
                    <div class="fail-label">An error occurred. Please try again. If problem persists contact Administrator</div>
                </div>
            </div>
        </div>
</template>

<script>
import Globals from '../../utilities/globals'
import store from '../../store/store'
export default {
    components: {
    },
    data() {
        return {
            store,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                businessid: ''
            },
            showError: false,
            validEmail: false,
            validPhone: false,
            showSuccess: false,
            showExists: false,
            showFail: false,
            foundName: '',
            // setHeight: Globals.setHeight()
        }
    },
    methods: {
        // getHeight() {
        //     return Globals.setHeight()
        // },
        hideEntry() {
            console.log('hide entry')
            this.$emit('close-add-client')
        },
        verifyEntry() {
            let isError = false;
            this.clearErrors();
            if(!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone) {
                isError = true;
                this.showError = true;
            }
            if (!Globals.validateEmail(this.user.email)) {
                isError = true;
                this.showError = true;
                this.validEmail = false;
            }
            if (this.user.phone.length !== 12) {
                isError = true;
                this.showError = true;
                this.validPhone = false;
            }
            if (!isError) {
                this.submitReg()
            }
        },
        clearErrors() {            
            this.validEmail = true;
            this.validPhone = true;
            this.showError = false;
            this.showSuccess = false;
            this.showExists = false;
            this.showFail = false;
            this.foundName = '';
        },
        submitReg () {           
            let data = {...this.user};
            data.phone = data.phone.replaceAll('-','')
            data.businessid = this.$store.getters.user.businessid
            console.log('SUBMIT REG CLIENT', this.user, data)
            this.$store.commit('setShowLoader',true)
            this.$store.dispatch('addClient',data)
            .then( response => {
                console.log('NEW Client REG' , response)
                this.$store.commit('setShowLoader',false)
                if (response.result === 'SUCCESS') {
                    this.showSuccess = true;
                    data.id = response.data                   
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.$store.commit('setSelectedClient',data)
                        console.log('SET SELECTED', data)
                        this.hideEntry();
                    }, 3000)
                }
                if (response.result === 'EXISTS') {
                    // this.foundName = `${response.data.firstname} ${response.data.lastname}`
                    this.showExists = true;
                }
                if (response.result === 'FAIL') {
                    this.showFail = true;
                }
            })
            .catch(error => {
                this.$store.commit('setShowLoader',false)
                console.log('ERROR OCCURRED', error)
            })
        }, 
        formatPhoneNumber() {
            let cleaned = ('' + this.user.phone).replace(/\D/g, '')
            this.user.phone = Globals.formatPhone(cleaned)
        },
        
    },
    mounted() {
        console.log('USERINFO',this.$store.getters.user)
    },
    watch: {
        
    }

}
</script>

<style scoped>
.single {
    margin-top: 5px;
}
.front {
    z-index: 6;
    position: relative;
    top: 50px;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  padding: 15px;
  padding-top: 0;
  margin: auto;
  border: 1px solid lightblue;
  background-color: #fbfbfb;
  min-height: 300px
}
.submit-button {
    padding: 10px;
    text-align: center;
    width: 70%;
    color: white;
    background-color: #258ba2;
    cursor: pointer;
}
.button-row {
  display: flex;
  justify-content: center;
  margin: 10px 0;
} 
.success-label {
  font-size: 18px;
  font-weight: 600;
  color: green;
}
.fail-label {
  font-size: 18px;
  font-weight: 600;
  color: red;
}
.form-input-row{
    height: 35px;
    text-align: left;
}
.form-input {
  padding: 5px;
  margin: 5px;
  height: 25px;
  font-size: 16px;
  line-height: 30px;
  width: 70%;
}
.med-width {
    width: 70%;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 10px;
} 
.missing-selection-message {
    margin: 0;
    margin-top: 7px;
    margin-bottom: -5px;
}
.required-symbol {
    color: red;
    font-size: 24px;
    position: relative;
    left: 50px;
    top: -50px;
    width: 10px;
}
.close-symbol {
    font-size: 40px;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: -7px;
    cursor: pointer;
}
</style>