<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div class="form-search-row"> 
            <div class="search-ahead-item">
                <SearchAhead 
                    v-bind:searchtype="addSearch.type" 
                    v-bind:searchcall="addSearch.dbcall" 
                    v-bind:resultfields="addSearch.resultfields"
                    v-on:item-selected="selectedSupplyProduct = $event"
                    :clearterm="true" >
                </SearchAhead>
            </div>
            <div class="search-selection-item">
                <SelectionSwitch  
                    :switches="['PRODUCTS','SUPPLIES']"
                    v-on:item-selected="selectedSearchType = $event"
                    :selectedSwitch= "'PRODUCTS'"
                    >
                </SelectionSwitch>
            </div>
        </div>

                    <!-- ITEM ENTRY DETAILS -->
        <div v-if="showEntry">
            <OrderInfoTable 
                v-if="invoiceProducts.length > 0"
                v-bind:infotableItems="invoiceProducts"
                v-bind:isPaid="isPaid"
                v-on:table-total-updated="invoiceTotalsData = $event"
            />
            <div class="form-input-row">
                <div class="form-input-label">{{'Tax' | translate}}: </div>
                <div class="form-content-item">
                    <InputCurrency
                        v-on:amount-entered="itemTax = $event"
                        :amount="item.tax"
                    ></InputCurrency>
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Shipping' | translate}}: </div>
                <div class="form-content-item">
                    <InputCurrency
                        v-on:amount-entered="itemShipping = $event"
                        :amount="item.shipping"
                    ></InputCurrency>
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Order Total' | translate}}: </div>
                <div class="form-content-item">
                    {{orderTotal | toUSD}}
                </div>
            </div>

            <!-- COMPANY DISTRIBUTOR INFO -->

            <div class="form-input-row">
                <div class="form-input-label">{{'Distributor' | translate}}: </div>
                <div class="form-content-item">
                    {{item.distributor}}
                </div>
            </div>
            <div class="distributor-section-title">{{'Distributor Contacts' | translate}}</div>
            <div class="distributor-items-container" v-for="(item,index) in distributor.contacts" v-bind:key="index" >
                <div class="distributor-item">{{item.name}}</div>
                <div class="distributor-item"> {{item.phone | formatPhone}}</div>
                <div class="distributor-item"> {{item.email}}</div>
                <div class="distributor-item small-item"> {{item.ext}}</div>               
            </div>
            <br>
            <div class="distributor-section-title">{{'Additional Products/Supplies Available'}}</div>
            <div class="distributor-items-container" v-for="(item,index) in distributorItems" v-bind:key="index" >
                <div class="distributor-item">{{item.company}}</div>
                <div class="distributor-item"> {{item.name}}</div>
                <div class="distributor-item"> {{item.code}}</div>
                <div class="distributor-item button-container">
                    <div class="small-button" @click="addItem(item)">{{'Add' | translate}} {{item.category | translate}}</div>
                </div>
            </div>
           
        </div>


<!-- NAVIGATION -->
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />
    </div>
</template>

<script>
import SearchAhead from '../utility/SearchAhead'
import ButtonBar from '../utility/ButtonBar'
import SelectionSwitch from '../utility/SelectionSwitch'
import OrderInfoTable from './OrderInfoTable'
import InputCurrency from '../utility/InputCurrency'
import store from '../../store/store'


export default {
    name: 'PurchaseSuppliesProducts',
    data() {
        return {
            pageTitle: 'Order Products/Supplies',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            selectedSupplyProduct: {},
            selectedSearchType: '',
            item: {totalorder:0},
            existingNumber: '',
            itemRetailPrice: '',
            itemCost: '',
            invoiceProducts: [],
            showEntry: false,
            distributor: {contacts:[{name:'bob'}]},
            distributorItems: [],
            isPaid: false,
            totalOrder: '',
            orderTotal: '',
            invoiceTotalsData:{},
            itemShipping:'',
            itemTax: '',
            store,
            productSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            supplySearch: {
                dbcall: 'getSuppliesSearch',
                type: 'Supplies',
                resultfields: ['name']
            },
            addSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            } 
        }
    },
    components: {
        SearchAhead,
        ButtonBar,
        SelectionSwitch,
        OrderInfoTable,
        InputCurrency,
    },
    methods: {
        verifyForm() {
            if (this.orderTotal < 1 && !this.selectedSupplyProduct) {
                this.showErrors = true
                window.console.log('FOUND ERROR',this.orderTotal ,this.selectedSupplyProduct)
            } else {
                this.submitForm()
            }
        },
        submitForm() {
            const data = {
                items:this.invoiceProducts,
                distributor: this.distributor.name,
                distributorid: this.distributor.id,
                tax: this.itemTax ? this.itemTax : 0,
                shipping: this.itemShipping ? this.itemShipping : 0,
                total: this.orderTotal
            }
            this.$store.dispatch('addOrder',data).then(response => {
                window.console.log('ORDER RESULT', response)
            })
            window.console.log('Submit',data)
        },
        addItem(item) {
            window.console.log('ADD ITEM', item)
            this.invoiceProducts.push(item)
        },
        calcTotal() {
            this.orderTotal = Number(this.invoiceTotalsData.total) + Number(this.itemTax) + Number(this.itemShipping)
        }
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.verifyForm()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        selectedSearchType() {
            window.console.log('SELECTED SEARCH CHANGED', this.selectedSearchType)
            if (this.selectedSearchType === 'PRODUCTS') {
                window.console.log('SELECTED products')
                this.addSearch = this.productSearch
            }
            if (this.selectedSearchType === 'SUPPLIES') {
                window.console.log('SELECTED SUPPLIES')
                this.addSearch = this.supplySearch
            }
        },
        selectedSupplyProduct() {
            this.item = this.selectedSupplyProduct
            this.existingNumber = Number(this.item.contactphone)
            this.itemRetailPrice = Number(this.item.retailprice)
            this.itemCost= Number(this.selectedSupplyProduct.cost)
            this.invoiceProducts.push(this.selectedSupplyProduct)
            this.showEntry = true

            this.$store.dispatch('getProductsDistributor',{id:this.selectedSupplyProduct.distributorid}).then(response => {
                window.console.log('GOT RESPONSE DIST PROD', response)
                this.distributorItems = response.data
            })
            this.$store.dispatch('getDistributorId',{id:this.selectedSupplyProduct.distributorid}).then(response => {
                
                this.distributor = response
                window.console.log('GOt DIST', response,this.distributor.contacts)
            })
             window.console.log('SELECTED', this.selectedSearchType, this.selectedSupplyProduct, this.item.cost, this.itemCost, this.existingNumber)
        },
        invoiceTotalsData() {
            window.console.log('PO UPDATED', this.invoiceTotalsData, this.invoiceTotalsData.total)
            this.item.totalorder = this.invoiceTotalsData.total
            // this.orderTotal = this.invoiceTotalsData.total
            this.calcTotal()
        },
        itemShipping() {
            this.calcTotal()
        },
        itemTax() {
            this.calcTotal()
        }
        
    },
}
</script>

<style scoped>
.search-ahead-item{
    display: inline-block;
}
.search-selection-item{
    display: inline-block;
    padding-left: 5px;
    position: relative;
    top: 3px;
}
.input-search{
    height: 17px;
}
.distributor-item{
    display: inline-block;
    width: 20%;
    border: 1px solid white;
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    background-color: #8faab7;
    color: white;
    font-weight: 650;
}
.small-item{
    width: 10%;
}
.button-container{
    background-color: white;
}
.distributor-item:last-child{
    /* border-right: none */
}
.distributor-items-container{
    /* border: 1px solid blue; */
    border-bottom: none;
    margin: 0 10%;
    height: 25px;
}
.distributor-items-container:only-child{
    /* border-bottom: 1px solid blue; */
}
.distributor-items-container:last-child{
    /* border-bottom: 1px solid blue; */
}
.distributor-section-title{
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
}
.small-button{
    background-color: #0d91de;
    border-radius: 12px;
    height: 16px;
    display: inline-block;
    font-size: 12px;
    color: white;
    font-weight: 600;
    padding: 2px 10px;
    cursor: pointer;
    line-height: 16px;
}
</style>