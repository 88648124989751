<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div class="form-input-row" >
            <div class="form-input-label add-format">{{'Location' | translate | allCaps}}</div>
            <div class="form-content-item" v-if="!statusUpdate || singleLocation">               
                <Dropdown  
                    v-on:item-selected="selectedLocation = $event" 
                    v-bind:selected="selectedLocation.name" 
                    v-bind:optioncontent="allLocations"
                    placeholder="Select Location">
                </Dropdown>
            </div>
            <div class="form-content-item" v-if="statusUpdate">
                {{selectedLocation.name}}
            </div>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !selectedLocation.id">*{{'You need to select a' | translate}} {{'Location' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Name' | translate}} </label> 
            <input id="name" v-model="roomName"/>
        </div>
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Room' | translate}} {{successMessage | translate}}</div>
        </div> 
        <div class="existing-rooms-container" v-if="!statusUpdate">
            <div class="existing-title-bar">{{'Existing Rooms' | translate}}</div>
            <div class="existing-row" v-for="(suite,index) in allRooms" v-bind:key="index">
                <div class="client-list-item" >
                    {{suite.name}}
                    
                        <div class="control-group-item color-blue" @click='modifyRoom(suite)'>
                            <i class="fas fa-pen"></i>
                        </div>
                        
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import store from '../store/store'
import ButtonBar from './utility/ButtonBar'
import Dropdown from './utility/Dropdown'

export default {
    name: 'Rooms',
    data: function() {
        return {
            roomName: '',
            allRooms: [],
            store,
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            showSuccess: false,
            showErrors: false,
            allLocations: [],
            selectedLocation: {},
            selectedRoom: {},
            statusUpdate: false,
            pageTitle: this.statusUpdate ? 'Update Room' : 'Add Room',
            successMessage: 'Successfully Added',
            singleLocation: false
        }
    },
    components: {
        ButtonBar,
        Dropdown,
    },
    methods: {
        addRoom () {
            if(this.selectedLocation.id) {
                this.$store.dispatch('addRoom', {name: this.roomName,locationid:this.selectedLocation.id}).then(response => {
                    if (response.result == 'SUCCESS') {
                        this.showSuccess = true
                        this.roomName = ''
                        this.getRooms(this.selectedLocation.id)
                    }                               
                })
            } else {
                this.showErrors = true
            }
        },
        checkLocations() {
            this.allLocations = this.$store.getters.allLocations
            if(this.allLocations.length == 1) {
                this.selectedLocation = this.allLocations[0]
                this.singleLocation = true
                this.getRooms(this.selectedLocation.id)
            }
        },
        getRooms(id) {
            this.allRooms = this.$store.dispatch('getRoomsLocation',id).then(response => {
                this.allRooms = response.data
                if (!response || response == undefined) {
                    this.buttonList = ['Submit', 'Next']
                }
            })
        },        
        modifyRoom(room) {
            this.statusUpdate = true
            this.roomName = room.name
            this.pageTitle = 'Update Room'
            this.buttonList = ['Update','Delete','Cancel']
            this.selectedRoom = room
        },
        UpdateRoom() {
            window.console.log('UPDATE ROOM')
            const data = {
                name: this.roomName,
                id: this.selectedRoom.id
            }
            this.$store.dispatch('updateRoom', data).then(response => {
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Updated'
                    this.showSuccess = true
                }
            })
        },
        DeleteRoom() {
            const data = {
                name: this.roomName,
                id: this.selectedRoom.id
            }
            this.$store.dispatch('deleteRoom',data).then(response => {
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Removed'
                    this.showSuccess = true
                    this.$router.back()
                }
            })
        }
    },
    mounted () {
        this.checkLocations()
    },
    watch: {
       buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.addRoom()               
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
            if (this.buttonClicked.name === 'Update') {
                this.UpdateRoom()
            }
            if (this.buttonClicked.name === 'Delete') {
                this.DeleteRoom()
            }
            if (this.buttonClicked.name === 'Next') {
                this.$router.push('AddStaff')
            }
        },
        selectedLocation() {
            this.allRooms = []
            this.getRooms(this.selectedLocation.id)
            this.showErrors = false
        }
    },
}
</script>

<style scoped>
.existing-rooms-container{
    margin: 0 20%;
    
}
.existing-title-bar{
    background-color: #2b6e94;
    color: white;
}
.existing-row{   
    border: 1px solid #2b6e94;
    border-top: none;
}

</style>

