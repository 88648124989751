<template>
    <div class="main-input-form-container min-width" ref="main-container">
        <InvoiceForm :invoiceData="invoiceData"  v-if="showInvoice" v-on:close-form="showInvoice= false,showSuccesMsg()"/>
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Invoice' | translate | allCaps}}</div>          
            <div class="form-input-row">
                <label class="form-input-label" >{{'Invoice' | translate}}# </label>
                <div class="form-content-item">{{invoiceInfo.id}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Name' | translate}} </label>
                <div class="form-content-item">{{bookingInfo.client | allCaps}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Service' | translate}} </label>
                <div class="form-content-item">{{bookingInfo.service}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Date' | translate}} </label>
                <div class="form-content-item">{{bookingInfo.bookingdate | longDate}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Provider' | translate}} </label>
                <div class="form-content-item">{{bookingInfo.staff}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Status' | translate}} </label>
                <div class="form-content-item">{{bookingInfo.status | allCaps}}</div>
                <!-- <div class="button-main small-button" v-if="bookingInfo.status === 'booked'" @click="checkIn">Check In</div> -->
            </div>

            <ItemInfoTable 
                v-if="invoiceProducts.length > 0"
                v-bind:infotableItems="invoiceProducts"
                v-bind:isPaid="preventUpdate"
                v-on:table-total-updated="invoiceTotalsData = $event"
            />
            <div class="client-credit-container" v-if="clientCredits.length  && !invoiceInfo.id">
                <div class="client-credit-box-title">{{'Available Credit' | translate}}</div>
                <div v-for="(credit,index) in clientCredits" :key="index">                  
                    <div class="client-credit-item-name">{{credit.name}}</div>
                    <div class="client-credit-item-amount">{{credit.balance}}</div>
                    <div class="client-credit-apply-button" v-if="isCreditApply(credit)" @click="applyCredit(credit)">{{'apply credit' | translate | toLowerCase}}</div>
                </div>
            </div>            
        </div>
        <div class="form-input-row" v-if="Number(this.productServiceTotal) > 0">
            <label class="form-input-label" >{{'Total Products' | translate}}/{{'Services' | translate}}</label>
            <div class="form-content-amount">{{this.productServiceTotal | toUSD}}</div>
        </div >
        <div class="form-input-row">
            <label class="form-input-label" >{{'Sales Tax' | translate}} ({{'Services' | translate}})</label>
            <div class="form-content-amount">{{salesTaxServiceAmt | toUSD}}</div>
            <div class="form-content-tax-item">{{salesTaxService}}%</div>  
        </div >
        <div class="form-input-row">
            <label class="form-input-label" >{{'Sales Tax' | translate}} ({{'Products' | translate}})</label>
            
            <div class="form-content-amount">{{salesTaxProductsAmt | toUSD}}</div>
            <div class="form-content-tax-item">{{salesTaxProducts}}%</div>
        </div >
        <div class="form-input-row" v-if="showTips">
            <label class="form-input-label">{{'Tip' | translate }}:</label> 
            <InputCurrency
                v-if="!isPaid"
                v-on:amount-entered="tipAmount = $event"
                :amount="existingTip"
             ></InputCurrency>
             <div class="form-content-amount" v-if="isPaid" >{{this.invoiceInfo.tip | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Total' | translate}} </label>
            <div class="invoice-total">{{invoiceTotal  | toUSD}}
                <!-- <div class="credit-applied"  v-if="creditApplied">{{'Credit Applied' | translate}} ({{totalApplied | toUSD}})</div> -->
            </div>
        </div >
        <div class="form-credit-row" v-if="creditApplied">
            <label class="form-input-label" >{{'Credit Applied' | translate}} </label>
            <div class="credit-applied">- {{totalApplied | toUSD}}</div>
            <credit-item 
                :creditData="creditData"
            />           
        </div >       
        <div class="form-input-row">
            <label class="form-input-label" >{{'Pay Amount' | translate}} </label>
            <div class="invoice-total" v-if="!isPaid">{{payAmount | toUSD}}</div> 
            <div class="form-content-amount" v-if="isPaid" >{{(Number(this.invoiceInfo.invoicetotal) + Number(this.invoiceInfo.tip))-Number(this.totalApplied) | toUSD}}</div>           
        </div >        
        <div class="form-input-row">
            <label class="form-input-label" >{{'Amount Paid' | translate}}</label>
            <!-- <input id="paid" v-model="formattedPaidValue" v-on:blur="setToUSD" v-on:focus="setToNum" v-if="!isPaid"/> -->
            <InputCurrency
                v-if="!isPaid"
                v-on:amount-entered="formattedPaidValue = $event"

                :amount="existingPaid"
             ></InputCurrency>
            <div class="form-content-amount" v-if="isPaid" >{{this.invoiceInfo.amountpaid | toUSD}}</div>
            
            <div class="paid-full" v-if="paidInFull">
                <i class="fas fa-check-square"></i>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && !Number(this.formattedPaidValue)">*{{'You need to enter a' | translate}} {{'Amount Paid' | translate}}</div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Payment Type' | translate}}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="paymentType = $event" 
                v-bind:selected="paymentType.name" 
                v-bind:optioncontent="paymentTypes"
                :placeholder="existingPaymentType"/>
                <div class="form-content-amount" v-if="isPaid" >{{this.invoiceInfo.paytype}}</div>
        </div>
        <div class="form-input-row" v-if="paymentType.name === 'Check'">
            <label class="form-input-label" >{{'Check Number' | translate }}</label>
            <input v-model="checknumber" v-if="!isPaid" />
            <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.checknumber}}</div>
        </div>
        <div class="form-input-row" v-if="paymentType.name === 'Credit Card'">
            <label class="form-input-label" >{{'Card Type' | translate }}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="cardType = $event" 
                v-bind:selected="cardType.name" 
                v-bind:optioncontent="creditCards"
                :placeholder="existingCardType"/>
            <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.cardtype}}</div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!paymentType.id && paymentType.name !== 'Promotion Credit')">*{{'You need to enter a' | translate}} {{'Payment Type' | translate}}</div>

        <!-- SEARCH FOR ADDITIONAL ITEMS -->
        <div v-if="!preventUpdate">
            <div class="form-search-row"> 
                <div class="search-ahead-label">
                    {{'Add' | translate }} 
                </div>
                <div class="search-ahead-item">
                    <SearchAhead 
                        v-show="showProductSearch"
                        v-bind:searchtype="addSearch.type" 
                        v-bind:searchcall="addSearch.dbcall" 
                        v-bind:resultfields="addSearch.resultfields"
                        v-on:item-selected="selectedProduct = $event"
                        :clearterm="true" >
                    </SearchAhead>
                </div>
                <div class="search-selection-item">
                    <SelectionSwitch  
                        :switches="['PRODUCTS','SERVICES','PROMOTIONS','CREDIT']"
                        v-on:item-selected="selectedSearchType = $event"
                        :selectedSwitch= "'PRODUCTS'"
                        >
                    </SelectionSwitch>
                </div>
            </div>
        </div>
        
        <!-- PROCESS BUTTONS -->
        <div class="button-confirm-container">
            <div class="submit-confirm" id="confirm-delete" v-if="showConfirm" >
                {{'Are you sure you want to submit without and payment' | translate}}?
                <div>
                    <div class="button-main small-button" @click="submitInvoice">{{'Yes' | translate}}</div>
                    <div class="button-main small-button" @click="showConfirm = false" >{{'No' | translate}}</div>
                </div>
            </div >
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" >
            </ButtonBar>
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Invoice Successfully Submitted' | translate}}</div>
            </div>  
        </div>
        
        
        
    </div>
</template>

<script>
import SearchAhead from '../utility/SearchAhead'
import Filters from '../../utilities/filters'
import ItemInfoTable from '../utility/ItemInfoTable'
import EventBus from '../../utilities/eventBus.js'
import Globals from '../../utilities/globals.js'
import Dropdown from '../utility/Dropdown'
import Constants from '../../utilities/constants'
import ButtonBar from '../utility/ButtonBar'
import SelectionSwitch from '../utility/SelectionSwitch'
import InputCurrency from '../utility/InputCurrency'
import CreditItem from '../utility/CreditItem'
import InvoiceForm from './InvoiceForm.vue'

export default {
    name: 'Invoice',
    data: function() {
        return {
            selectedProduct: {},
            selectedSearchType: '',
            showProductSearch: true,
            showErrors: false,
            invoiceProducts:[],
            invoiceTotal: 0,
            payAmount: 0,
            invoiceInfo: {id: ''},
            updateTotal: 0,
            bookingInfo: {},
            itemRemove: {},
            amountPaid: 0,
            datePaid: '',
            paymentForm: '',
            paymentType: {},
            cardType: {},
            isPaid: false,
            paidInFull: false,
            updatedItem: {},
            showSuccess: false,
            showConfirm: false,
            showTips: true,
            showInvoice: true,
            preventUpdate: false,
            formattedPaidValue: '$0.00',
            invoiceTotalsData: {total:'',retail:''},
            paymentTypes: Constants.paymentTypes,
            creditCards: Constants.creditCards,
            allPromotions: [],
            allServices: [],
            selectedPromotion: {},
            selectedService: {},
            clientPromotions: [],
            clientCredits: [],
            creditData: [],
            creditApplied: false,
            appliedRef: [],
            maxApplied: false,
            totalApplied: 0,
            tipAmount: 0,
            checknumber: '',
            existingTip: '',
            existingPaid: '',
            existingPaymentType: 'Select Payment Type',
            existingCardType: 'Select Card Type',
            salesTaxProducts: this.$store.getters.currentLocation.taxproducts,
            salesTaxProductsAmt: 0,
            salesTaxService: this.$store.getters.currentLocation.taxservices,
            salesTaxServiceAmt: 0,
            appliedCreditList: [],
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            productSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            serviceSearch: {
                dbcall: 'getServiceSearch',
                type: 'Services',
                resultfields: ['name']
            },
            promotionSearch: {
                dbcall: 'getPromotionSearch',
                type: 'Promotions',
                resultfields: ['name']
            },
            creditSearch: {
                dbcall: 'getCreditSearch',
                type: 'Credit',
                resultfields: ['name']
            },
            addSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            invoiceData: {
                name:'Hello World',
                businessname: this.$store.getters.businessname,
                phone: this.$store.getters.phone,
            } 
        }
    },
    components: {
    SearchAhead,
    ItemInfoTable,
    Dropdown,
    ButtonBar,
    SelectionSwitch,
    InputCurrency,
    CreditItem,
    InvoiceForm,
},
    methods: {
        checkShowTips() {
            this.$store.dispatch('getActiveTips').then(response => {
                this.showTips = response.data.tips
            })
        },
        isCreditApply(credit) {
            if (this.payAmount == 0 || credit.balance == 0 ) {return false}
            window.console.log('CHECK CREDIT APPLIED',credit.balance,credit.AMTAPPLIED,credit.category,credit.type)
            if (credit.AMTAPPLIED && credit.AMTAPPLIED > 0) {return false}
            if (credit.category == 'credit') { return true}
            else if (credit.category == 'certificate') {
                if (credit.type == 'productcredit') {
                    return this.invoiceProducts.some(item => item.category == 'product')
                } else if (credit.type == 'servicecredit') {
                    return this.invoiceProducts.some(item => item.category == 'service')
                } else if (credit.type == 'generalcredit' || credit.type == 'promotion') { return true }
            } else {
                if (credit.category == 'service') {
                   return this.invoiceProducts.some(item => item.category == 'service' && item.id == credit.itemid)  
                }
                if (credit.category == 'product') {
                   return this.invoiceProducts.some(item => item.category == 'product' && item.id == credit.itemid)  
                }
                window.console.log('NOT CERT OR CREDIT',credit)
                return this.invoiceProducts.some(item => item.id == credit.id)
            }
           
        },
        applyCredit(credit) {
            this.invoiceProducts.forEach(item => {
                if (credit.category == 'credit') {
                    this.applyItemCredit(item,credit)
                }
                if (credit.category == 'certificate') {
                    if ((credit.type == 'productcredit' || credit.type == 'promotion') && item.category == 'product') {
                        this.applyCertCredit('product',credit)
                    }
                    if ((credit.type == 'servicecredit' || credit.type == 'promotion') && item.category == 'service') {
                        this.applyCertCredit('service',credit)
                    }
                }
                if (credit.category == 'service') {
                    if (item.category == 'service' && item.id == credit.itemid) {
                        window.console.log('APPLY CREDIT TO THIS', item)
                        this.applyItemCredit(item,credit)
                    }
                }
                if (credit.category == 'product') {
                    if (item.category == 'product' && item.id == credit.itemid) {
                        window.console.log('APPLY CREDIT TO THIS', item)
                        this.applyItemCredit(item,credit)
                    }
                }
                
            })
        },
        applyCertCredit(type,credit) {
            this.invoiceProducts.forEach(item => {
                if (item.category == type) {
                    this.applyItemCredit(item,credit)
                }
            })
        },
        applyItemCredit(item,credit) {
            if (item.AMTAPPLIED  && item.AMTAPPLIED == item.creditamount && item.AMTAPPLIED == item.total) {
                window.console.log('NOTHING TO APPLY',item.name, item.creditamount, item.AMTAPPLIED)
                return 
            }
            let appliedItemCredit = 0
            let applyQuantity = 0
            if ((item.category == 'service' || item.category == 'product') && item.id == credit.itemid) {
                applyQuantity = item.quantity < credit.balance ? item.quantity : credit.balance
                const applyDollarValue = item.saleprice * applyQuantity
                appliedItemCredit = applyDollarValue <= this.payAmount  ? applyDollarValue : this.payAmount
                credit.balance -= applyQuantity
            } else {               
                let itemBalance = item.total
                if (item.AMTAPPLIED) {
                    itemBalance -= item.AMTAPPLIED
                }
                if (credit.balance > itemBalance) {                    
                    appliedItemCredit = itemBalance
                } else {
                    appliedItemCredit = credit.balance
                }
                credit.balance -= appliedItemCredit
                applyQuantity = appliedItemCredit
            }
            
            
            this.payAmount -= appliedItemCredit
            
            credit.AMTAPPLIED = credit.AMTAPPLIED ? credit.AMTAPPLIED + appliedItemCredit : appliedItemCredit
            const itemapplication = {
                id :item.id,
                name: item.name,
                amount: applyQuantity
            }
            if (credit.applieddetail) {
                credit.applieddetail.push(itemapplication)
            } else {
                credit.applieddetail = [itemapplication]
            } 
            if (credit.APPLIEDITEMS && credit.APPLIEDITEMS.length) {
                credit.APPLIEDITEMS.push(item)
            }  else {
                credit.APPLIEDITEMS = [item]
            } 
            item.AMTAPPLIED = item.AMTAPPLIED ? item.AMTAPPLIED + appliedItemCredit : appliedItemCredit
            item.creditamount = item.AMTAPPLIED
            item.credittype = credit.type
            this.totalApplied += appliedItemCredit
            this.creditApplied = true
            this.appliedCredit = true
            this.creditData.push(credit)
            window.console.log('CREDIT APPLIED', this.creditApplied, credit,credit.APPLIEDITEMS, item)
        },
        addProduct() {
            if (this.selectedProduct.category == 'promotion') {
                this.selectedProduct.items.forEach(item => {
                    item.dateEntered = Globals.formatDateYMDdash(new Date())
                    item.businessid = this.$store.getters.user.businessid
                    this.invoiceProducts.push(item)
                })
                
            } else {
                this.selectedProduct.discount = 0;
                this.selectedProduct.quantity = 1;
                this.selectedProduct.dateEntered = Globals.formatDateYMDdash(new Date())
                this.selectedProduct.businessid = this.$store.getters.user.businessid
                this.invoiceProducts.push(this.selectedProduct)
            }            
            this.calcInvoiceTotal()
        },
        calcPrice(product) {
            return product.retailprice - (product.reatailprice * product.discount/100)
        },
        calcTotal(product) {
            return product.retailprice - (product.reatailprice * product.discount/100) * product.quantity
        },
        calcInvoiceTotal: function() {
            let vm = this;
            let invTotal = 0
            this.salesTaxProductsAmt = 0
            this.salesTaxServiceAmt = 0
            this.productServiceTotal = 0
            this.invoiceProducts.forEach(item => {  
                let tax = 0
                if (item.category === 'service') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxservices)/100
                    this.salesTaxServiceAmt += tax
                } 
                if (item.category === 'product') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxproducts)/100
                    this.salesTaxProductsAmt += tax
                }  
                this.productServiceTotal += item.total         
                invTotal += item.total  + tax             
            })
            
            if (this.bookingInfo.status !== 'paid-in-full') {
                vm.invoiceTotal = Globals.roundPrice(invTotal)
                window.console.log('PAYAMOUNT---------VVVV',vm.invoiceTotal,invTotal ,this.totalApplied)
                vm.payAmount = vm.invoiceTotal  - this.totalApplied + this.tipAmount
            }
        },
        getBookingInfo: function() {
            this.bookingInfo = this.$store.getters.bookingInfo
            this.bookingInfo.status = this.bookingInfo.status ? this.bookingInfo.status : 'booked'
            window.console.log('BOOKING INFO', this.bookingInfo)
            this.checkIfInvoiceExists()
            
        },
        checkIfInvoiceExists() {
             window.console.log('CHK INVOICE EXIST', this.bookingInfo)
            this.$store.dispatch('checkForInvoice',{id: this.bookingInfo.id}).then( response => {
                window.console.log('DOES INVOICE EXIST', response.length, response)
                this.setupInvoice(response)
            })
        },
        getServiceId(serviceId) {
            this.$store.dispatch('getServiceId',{id: serviceId}).then(response => {
                    this.selectedProduct = response[0]
                    this.selectedProduct.discount = 0
                    this.selectedProduct.quantity = 1
                    this.selectedProduct.retailprice = Number(response[0].retailprice)
                })
        },
        setupInvoice(data) {
            window.console.log('SETUP INVOICE FROM DATA', data, data.length)

            if (!data.id && !data.length ) {
                if (this.bookingInfo.services) {
                    this.bookingInfo.services.forEach(service => {
                        this.getServiceId(service.id)
                    })
                } else {
                    this.getServiceId(this.bookingInfo.serviceid)
                }
                console.log('INVOICEINFO NO DATA', this.invoiceInfo, this.amountPaid)
            } else {

                if (data[0].appliedcredititems.length) {
                    this.creditApplied = true
                    data[0].appliedcredititems.forEach(credititem => {
                        this.creditData.push(credititem)
                        this.totalApplied += Number(credititem.AMTAPPLIED)
                    })
                }
               
                this.invoiceInfo = data[data.length - 1]
                this.bookingInfo.status = this.invoiceInfo.status
                if (this.invoiceInfo.status === "paid" || this.invoiceInfo.status === "paid-in-full" || this.invoiceInfo.amountPaid > 0) {
                    this.isPaid = true;
                    this.buttonList = ['Adjust','Print','Cancel']
                }
                this.invoiceTotal = Globals.roundPrice(this.invoiceInfo.invoicetotal)
                this.payAmount = this.invoiceTotal
                this.invoiceInfo.invoiceitems.forEach( item => {
                    this.invoiceProducts.push(item)
                })
                this.amountPaid = this.invoiceInfo.amountpaid
                window.console.log('PT', this.invoiceInfo.paytype)

                this.paymentTypes.forEach(type => {
                    if (type.name === this.invoiceInfo.paytype) {
                        this.paymentType = type
                    }
                })
                this.creditCards.forEach(type => {
                    if (type.name === this.invoiceInfo.cardtype) {
                        this.cardType = type
                    }
                })
                window.console.log('check CREDIT APPLIED',this.invoiceInfo) 
                if (this.invoiceInfo.creditapplied) {
                    this.creditApplied = true
                    this.totalApplied = this.invoiceInfo.creditapplied
                    window.console.log('CREDIT APPLIED', this.creditApplied, this.totalApplied)
                }
                console.log('INVOICEINFO', this.invoiceInfo, this.amountPaid)
                this.preventUpdate = true                
            }
            
        },
        removeItem(index) {
            this.invoiceProducts.splice(index,1)
        },
        compileSubmitData() {
            if ( Number(this.amountPaid).toFixed(2) === Number(this.payAmount).toFixed(2)) {
                this.bookingInfo.status = 'paid-in-full'
            } else {
                this.bookingInfo.status = 'paid'
            }
            this.datePaid = new Date()
            this.addCreditDetailItems()
            const data = {
                invoiceitems: JSON.stringify(this.invoiceProducts),
                invoicesaleitems: this.invoiceProducts,
                bookingid: this.bookingInfo.id,
                client: this.bookingInfo.client,
                clientid: this.bookingInfo.clientid,
                staff: this.bookingInfo.staff,
                staffid: this.bookingInfo.staffid,
                bookingdate: this.bookingInfo.bookingdate,
                invoicetotal: this.invoiceTotal ,
                amountpaid: this.formattedPaidValue ? Number(this.formattedPaidValue) : 0,
                paytype: this.paymentType.name,
                datepaid: this.datePaid,
                status: this.bookingInfo.status,
                promotionid: this.bookingInfo.promotionid,
                creditid: this.bookingInfo.creditid,
                creditapplied: this.totalApplied,
                credittype: this.bookingInfo.credittype,
                checknumber: this.checknumber,
                cardtype: this.cardType.name,
                clientpromotions: this.clientPromotions,
                appliedcredititems: this.appliedCreditList,
                tip: this.tipAmount,
                taxservice: Math.round(this.salesTaxServiceAmt * 100)/100,
                taxproduct: Math.round(this.salesTaxProductsAmt * 100)/100,
                productservicetotal: this.productServiceTotal,
            }
            return data
        },
        submitInvoice() {
            const data = this.compileSubmitData()
            window.console.log('INVOICE PRODUCTS', this.invoiceProducts )
            window.console.log('SAVE', data, this.bookingInfo, data.clientPromotions )
            window.console.log('DATA', data, 'PROMOTIONS', data.clientPromotions)
            this.invoiceData = data
            this.showInvoice = true
            // this.$store.dispatch('addInvoice', data).then(response => {
            //     window.console.log('RESUTS ADD INVOICE', response)
            //     if (response.result == 'SUCCESS'){
            //         this.invoiceData.invoicenum = response.id
            //         this.showInvoice = true
                    
            //     }
            // })
        },
        showSuccesMsg() {
            this.successMessage = 'Successfully Created'
            this.showSuccess = true                
            setTimeout(() => {
                // this.$router.push('Schedule')
                this.showSuccess = false 
            }, Constants.waitTime);
        },
        updateInvoice() {
            let data = this.compileSubmitData()
            data.id = this.invoiceInfo.id
            window.console.log('update', data)
            this.$store.dispatch('updateInvoice',data).then(response => {
                window.console.log('RESULTS UPDATE INVOICE', response)
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Created'
                    this.showSuccess = true                
                    setTimeout(() => {
                        this.$router.push('Schedule')
                    }, Constants.waitTime);
                }
            })
        },
        addCreditDetailItems() {
            this.invoiceProducts.forEach(item => {
                this.clientCredits.forEach(credit => {
                    window.console.log('APPLIED',credit, credit.invoiceid,credit.id,credit.itemid)
                    if (credit.AMTAPPLIED && !this.appliedCreditList.some(item => credit.invoiceid == item.invoiceid && credit.id == item.id)) {
                        window.console.log('APPLIED ADDED')
                        this.appliedCreditList.push(credit)
                    }
                })
                if(item.category === 'product') {
                    item.refid = item.id
                }
                if(item.category === 'service' && item.trackinventory) {
                    item.refid = item.supplyid
                }
            })
        },
        checkIn(){
            this.$store.dispatch('checkInBooking',{id: this.bookingInfo.id} ).then( response => {
                if (response.substr(0,7) === 'SUCCESS') {
                    this.bookingInfo.status = 'confirmed'
                }
            })
        },
        gotoPrevious() {
            this.$router.push('Schedule')
        },
        adjustInvoice() {
            window.console.log('ADJUST INVOICE',this.invoiceInfo, this.invoiceInfo.paytype )
            this.title = 'Update Invoice'
            this.buttonList=['Update','Cancel']
            this.isPaid = false;
            this.existingTip = this.invoiceInfo.tip
            this.tipAmount = Number(this.invoiceInfo.tip)
            this.existingPaid = this.invoiceInfo.amountpaid
            this.formattedPaidValue = this.invoiceInfo.amountpaid
            this.checknumber = this.invoiceInfo.checknumber
            this.existingPaymentType = this.invoiceInfo.paytype
            this.existingCardType = this.invoiceInfo.cardtype
            window.console.log('ADJUST INVOICE',this.paymentType)
            
        },
        setToUSD() {
            this.amountPaid = Number(this.formattedPaidValue.replace(/[^0-9.-]+/g,""))
            if (isNaN(this.amountPaid)) {
                this.amountPaid = ''
            }
            const amtNum = this.amountPaid.replace(/\d(?=(\d{3})+\.)/g, '$&,')
            this.formattedPaidValue = "$ " + Number(amtNum).toFixed(2)
        },
        setToNum() {
            this.formattedPaidValue = this.amountPaid.toString()
        },
        getServices() {
            this.$store.dispatch('getServices').then(response => {
                this.allServices = response
            })
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                this.allPromotions = response
            })
        },
        getClientPromotions() {
            this.$store.dispatch('getClientPromotions',{id:this.bookingInfo.clientid}).then(response => {
                window.console.log('CLIENT PROMO EACH RESPONSE', response, response.length, typeof response)
                if (response.result == 'SUCCESS') {
                    this.clientPromotions = response.data
                    this.clientPromotions.forEach(item => {
                        window.console.log('EACH ITEM', item)
                        item.items = !item.items ? [] : item.items
                        item.items.forEach(subitem => {
                            window.console.log('CLIENT PROMO EACH', item, subitem)
                            subitem.valueUsed = 0
                            subitem.quantityUsed = 0
                        })
                    })
                }                
            })
        },
        getClientCredits() {
            this.$store.dispatch('getClientCredits',{id:this.bookingInfo.clientid}).then(response => {
                window.console.log('CLIENT CREDITS', response, response.length)
                this.clientCredits = response.data.filter(credit => credit.balance > 0)
            })
        },
        showClientPromotions(promo) {
            window.console.log('SHOW PROMO',promo.quantity)
            if (promo.quantity > 0 ){ //&& this.invoiceInfo.status !== 'paid-in-full') {
                return true
            }
            return false
        },
        getTotalServices() {
            let totalServices = 0
            this.invoiceProducts.forEach(item => { 
                if (item.category === 'service') {
                    totalServices += item.total
                }               
            })
            return totalServices
        },
        verifyInvoice() {
            this.showErrors = false

            this.payAmount = Number(this.payAmount).toFixed(2)
            if (Number(this.formattedPaidValue) && !this.paymentType){ 
                window.console.log('INFO MISSING type',this.paytype,'paid',this.formattedPaidValue, Number(this.formattedPaidValue),Number(this.payAmount) ,Number(this.tipAmount),(Number(this.payAmount) + Number(this.tipAmount)))
                this.showErrors = true
            } else {
                if (!Number(this.formattedPaidValue)) {
                    this.showConfirm = true;
                    return
                }
                window.console.log('NO ERRORS SUBMIT')
                this.submitInvoice()
            }           
        },
        openAndPrintPDF() {
            // Replace the URL with the link to your online PDF file
            const pdfUrl = Globals.nodeURL + 'getinvoice/invoice_1691092284932.pdf'
            //'https://example.com/path/to/your/pdf.pdf';
            
            // Open the PDF file in a new window
            const pdfWindow = window.open(pdfUrl, '_blank');

            // Wait for the window to load (optional, depending on browser behavior)
            pdfWindow.onload = () => {
                // Trigger the print dialog for the opened PDF file
                pdfWindow.print();
            };
        }
    },
    computed: {
        getDiscountPrice(item){
            return this.calcPrice(item)
        },
        getDateInfo(){
            const bookDate = Globals.getSelectedDate(this.bookingInfo.day,this.bookingInfo.month, this.bookingInfo.year)
            return bookDate + ' at ' + this.bookingInfo.starttime +  Globals.getAMPM(this.bookingInfo.starttime)
        }
    },
    filters: {
        Filters
    },
    mounted() {
        let vm = this;
        this.checkShowTips()
        this.getBookingInfo()
        this.getPromotions()
        this.getServices()
        this.getClientPromotions()
        this.getClientCredits()
        EventBus.$on('UPDATE_INVOICE_TOTAL', function() {
            vm.calcInvoiceTotal()
        })
    },
    watch: {
        selectedProduct() {
            this.addProduct()
        },
        amountPaid() {
           
            if (Number(this.payAmount).toFixed(2) === Number(this.amountPaid).toFixed(2)) {
                this.paidInFull = true
            }
            window.console.log('AMT PAID', this.payAmount, this.amountPaid.substr(0))
            this.datePaid = new Date()
        },
        updatedItem() {
            window.console.log('PRODUT ITEM UPDATED', this.updatedItem)
        },
        invoiceTotalsData() {
            this.calcInvoiceTotal()
        },
        selectedService() {
            this.invoiceProducts.push(this.selectedService)
            this.calcInvoiceTotal()
        },
        selectedPromotion() {
            this.invoiceProducts.push(this.selectedPromotion)
            this.calcInvoiceTotal()
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.verifyInvoice()
            }
            if (this.buttonClicked.name === 'Adjust') {
                
                this.adjustInvoice()
            }
            if (this.buttonClicked.name === 'Email') {
                window.console.log('EMAIL',this.invoiceInfo)
            }
            if (this.buttonClicked.name === 'Print') {
                window.open(`${Globals.nodeURL}xvoice/invoice-${this.invoiceInfo.id}.pdf`, "_blank");             
            }
            if (this.buttonClicked.name === 'Update') {
                // window.console.log('Update,this.invoiceInfo')
                this.updateInvoice()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        selectedSearchType() {
            window.console.log('SELECTED SEARCH CHANGED', this.selectedSearchType)
            if (this.selectedSearchType === 'PRODUCTS') {
                window.console.log('SELECTED products')
                this.addSearch = this.productSearch
            }
            if (this.selectedSearchType === 'SERVICES') {
                window.console.log('SELECTED SERVICES')
                this.addSearch = this.serviceSearch
            }
            if (this.selectedSearchType === 'PROMOTIONS') {
                window.console.log('SELECTED PROMOTIONS')
                this.addSearch = this.promotionSearch
            }
            if (this.selectedSearchType === 'CREDIT') {
                window.console.log('SELECTED PROMOTIONS')
                this.addSearch = this.creditSearch
            }
        },
        tipAmount() {
            this.calcInvoiceTotal()
        },
        formattedPaidValue() {
            if (this.formattedPaidValue == this.payAmount) {
                this.paidInFull = true
            }
        }
    },
}
</script>

<style scoped>
.invoice-form-container{
    border: 1px solid #b0b2b3;
    margin: 0px 10%;
    max-width: 800px;
    padding-bottom: 15px;
}
/* .invoice-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px;
    width: 12%;
    height: 25px;
} */
.title-row{
    border-right: 1px solid white;
    background-color: #4d91a7;
    color: white;
    font-weight: 600;
}
.invoice-item-row{
    display: inline-block;
    float: left;
    margin-left: 5%;
    font-size: 13px;
    width: 85%;
}
.product-name{
    width: 30%;
}
.form-product-row{
    /* border-bottom: 1px solid gray; */
}
.border-right{
    border-right: 1px solid gray !important;
}
.border-left{
    border-left: 1px solid gray !important;
}
.border-top{
    border-top: 1px solid gray;
}
.width-small{
    width: 5%;
}
.invoice-items-row-container{
    width: 100%;
    display: inline-block;
    margin-bottom: -5px;
}
.row-button-group{
    display: inline-block;
    margin-left: -100px;
}
.invoice-total-row{
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
}

.invoice-total-label{
    width: 70.5%;
    text-align: right;
    display: inline-block;
    font-weight: 600;
}
.invoice-total{
    display: inline-block;
    text-align: right;
    font-weight: 700;
    margin-right: 17%;
    position: relative;
    top: 3px;
    width: 75px;
}
.credit-applied{
    display: inline-block;
    font-weight: 600;
    color: #9e1e55;;
    position: relative;
    top: 3px;
    vertical-align: top;
    text-align: right;
    width: 75px;
}
.promo-credit-row{
    border: 1px solid;
    display: inline-block;
    margin-bottom: 10px;
    background: #f8fafb;
}
.promo-credit-item{
    display: inline-block;
    padding: 1px 15px;
    border-right: 1px solid;
}
.promo-credit-item:last-child{
    border-right: none;
}
.client-credit-item-name{
    display: inline-block;
    padding: 1px 15px;
    border: 1px solid #4a8bce;
    width: 150px;
    margin-top: 10px;
}
.client-credit-item-amount{
    display: inline-block;
    padding: 1px 15px;
    border: 1px solid #4a8bce;
    width: 75px;
}
.client-credit-box-title {
    text-align: center;
    display: inline;
    padding: 3px 92px;
    background-color: #4a8bce;
    color: white;
    font-weight: 600;
    font-size: 14px;
}
.client-credit-container{
    margin: 16px;
    padding-left: 19%;
    text-align: left;
}
.client-credit-item-container{
    margin: 0 0 16px 0;
    padding-left: 19%;
}
.client-credit-apply-button{
    display: inline;
    background-color: #1489d6;
    color: white;
    padding: 3px 15px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 5px;
}
.blue-back{
    background-color: #4a8bce;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 3px 12px;
    height: 15px;
    vertical-align: top;
    border: 1px solid  #4a8bce;
}
.form-search-row{   
    height: 30px;
}
.search-ahead-label{
    display: inline-block;
    position: relative;
    top: 3px;
}
.search-ahead-item{
    display: inline-block;
    position: relative;
    top: 0;
}
.search-selection-item{
    display: inline-block;
    position: relative;
    top: 2px;
    padding-left: 5px;
}
.form-content-tax-item{
    display: inline-block;
    position: relative;
    top: 2px;
    padding-left: 10px;
}
.form-content-amount{
    width: 75px;
    text-align: right;
}
.min-width{
    min-width: 650px;
}
.submit-confirm{
 width: 232px;
 background-color: white;
 border: 1px solid black;
 position: absolute;
 top: -80px;
 left: 50%;
 margin-left: -116px;
 padding: 3px;
 z-index: 10;
}
.button-confirm-container{
    position: relative;
}
.form-credit-row{
    text-align: left;
}
@media screen and (max-width: 680px) {
    .product-name{
        width: 21%;
    }
    .invoice-item-row{
        width: 97%;
    }
}

</style>