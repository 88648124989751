<template>
    <div class="radio-button-container" @click="buttonClick">
        <div class="radio-button-main">
            <div class="radio-button-inner" v-if="buttonSelected"></div>
        </div>
        <div class="radio-button-label" :class="labelStyle">{{rbLabel}}</div>        
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    data() {
        return{
            buttonSelected: this.rbStatus
        }
    },
    props: [
        'rbLabel',
        'rbStatus',
        'rbRef',
        'labelStyle',
        'inactive'
    ],
    methods: {
        buttonClick() {
            window.console.log('RB CLICK', `${this.rbRef}~${this.buttonSelected}`,this.inactive)
            if (!this.inactive) {           
                this.buttonSelected = !this.buttonSelected
                this.$emit('rb-selected',`${this.rbRef}~${this.buttonSelected}`)
            }
        }
    },
    watch: {
        rbStatus() {
            window.console.log('RB STATUS CHANGED')
            this.buttonSelected = this.rbStatus
        }
    }
}
</script>

<style scoped>
.radio-button-container{
    display: inline-block;
    cursor: pointer;
    padding-left: 15px;
}
.radio-button-main{
    height: 20px;
    width:20px;
    border: 1px solid #2196F3;
    border-radius: 12px;
    background-color: #f8fcff;
    display: inline-block;
    vertical-align: middle;
} 
.radio-button-inner{
    height: 16px;
    width:16px;
    border-radius: 12px;
    background-color: #2196F3;
    margin: 2px;
} 
.radio-button-label{
    display: inline-block;
    vertical-align: middle;
    padding-left: 4px;
    font-size: 14px;
} 
.label-small{
  font-size: 14px;
  width: 60px;
  line-height: 12px;
  text-align: left;
}
</style>