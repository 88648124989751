<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Schedule' | translate | allCaps}}</div>
            
            <div class="staff-schedule-container" v-for="(schedule,index) in staffSchedule" :key="index">
                
                <div class="schedule-location">{{schedule.name}}</div> 
                <div class="schedule-row" v-for="(day,index) in schedule.data" :key="index">
                    <div v-if="!day.closed">
                        <div class="schedule-day">{{day.name}}</div>
                        <div class="schedule-day" ><span v-if="day.startTime">{{day.startTime.name}}</span></div>
                        <div class="schedule-day" ><span v-if="day.endTime">{{day.endTime.name}}</span></div>
                    </div>
                </div>   
            </div>

            <div class="button-main" @click="closePanel()">{{'Close' | translate}}</div>
        </div>
        
    </div>
</template>

<script>

import store from '../../store/store'

export default {
    name: 'StaffScheduleId',
    data() {
        return {
            selectedStaff: {},
            allStaff: [],
            store,
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            staffSchedule: []
        }
    },
    components: {

    },
    methods: {
        getStaff() {
            this.$store.dispatch('getStaff').then(response => {
                this.allStaff = response.data
                this.allStaff.map(item => {item.name = item.displayname})

                const staffInfo = this.allStaff.filter(staff => {
                    if (staff.id == this.$store.getters.user.id) {
                        return staff
                    }
                })
                this.staffSchedule = staffInfo[0].schedule
            })
        },
        closePanel() {
            this.$router.back()
        }

    },
    mounted() {
        this.getStaff()
    },
    watch: {
        
    },
}
</script>

<style scoped>
.schedule-day{
    display: inline-block;
    width: 100px;
}
.schedule-location{
    font-weight: 600;
    padding: 5px;
}
</style>
    