<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Add Promotion' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="promotion.name"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !promotion.name">*{{'You need to enter a' | translate}} {{'Promotion Name' | translate}}</div>
        
        <!-- <div class="form-input-row">
            <label class="form-input-label">{{'Promotion Type' | translate}}:<span class="required-entry">*</span></label> 
            <Dropdown 
                v-on:item-selected="promotionType = $event" 
                v-bind:selected="promotion.type" 
                v-bind:optioncontent="promotionTypes"
                placeholder="Select Promotion Type">
            </Dropdown>
        </div>
        <div class='missing-selection-message' v-if="showError && !promotionType.id">*{{'You need to select a' | translate}} {{'Promotion Type' | translate}}</div>
        
        <div class="form-checkbox-row" v-if="promotionType.id === 1">
            <label class="form-input-label">{{'Apply To' | translate}}:<span class="required-entry">*</span></label> 
            <div class="checkbox-container">
                <div class="checkbox-item">
                    <CheckBox 
                            v-bind:checkBoxLabel="'Services'"
                            v-on:item-selected="applyTo.services = $event" 
                            />
                </div>
                <div class="checkbox-item">
                    <CheckBox 
                            v-bind:checkBoxLabel="'Products'"
                            v-on:item-selected="applyTo.products = $event" 
                            />
                </div>
                <div class="checkbox-item">
                    <CheckBox 
                            v-bind:checkBoxLabel="'Promotions'"
                            v-on:item-selected="applyTo.promotions = $event" 
                            />
                </div>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showError && (!applyTo.products && !applyTo.services && !applyTo.promotions) ">*{{'You need to select what is eligible for credit' | translate}} </div> -->

        <ItemInfoTable 
                v-if="invoiceProducts.length > 0"
                v-bind:infotableItems="invoiceProducts"
                v-bind:isPaid="preventUpdate"
                v-on:table-total-updated="invoiceTotalsData = $event"
            />

        <div class="form-input-row">
            <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
            <div class="date-selection-container">
                <input class="input-wide" v-model="promotion.selectedStartDate" v-on:focus="showStartCalendar=true"/>
                <div class="inline-calendar-picker" v-if="showStartCalendar">
                    <CalendarMini 
                        v-on:date-selected="startDate = $event"/>
                </div>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showError && !promotion.selectedStartDate">*{{'You need to select a' | translate}} {{'Start Date' | translate}}</div>
        
        <div class="form-input-row">
            <label class="form-input-label">{{'End Date' | translate}}:<span class="required-entry">*</span></label> 
            <div class="date-selection-container">
                <input class="input-wide" v-model="promotion.selectedEndDate" v-on:focus="endFocus"/>
                <div class="inline-calendar-picker" v-if="showEndCalendar">
                    <CalendarMini 
                        v-on:date-selected="endDate = $event"/>
                </div>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showError && !promotion.selectedEndDate">*{{'You need to select a' | translate}} {{'End Date' | translate}}</div>
        <div class='missing-selection-message' v-if="dateError">*{{'End Date' | translate}} {{'needs to be after' | translate}} {{'Start Date' | translate}}</div>

        <div class="form-input-row" >
            <label class="form-input-label">{{'Use By Length' | translate }}:<span class="required-entry">*</span></label> 
            <Dropdown 
                v-on:item-selected="expirationSelected = $event" 
                v-bind:selected="expiration.name" 
                v-bind:optioncontent="expirationOptions"
                :placeholder="getUsePlaceholder()"
                >
            </Dropdown>
        </div>
       
        <div class='missing-selection-message' v-if="showError && !expirationSelected.id">*{{'You need to select a' | translate}} {{'Use By Length' | translate}}</div>
        
        <!-- <div class="form-input-row" v-if="showServices">
            <label for="type" class="form-input-label">{{'Add Service' | translate}}:<span class="required-entry">*</span></label> 
            <Dropdown 
                v-on:item-selected="serviceSelected = $event" 
                v-bind:selected="service.type" 
                v-bind:optioncontent="allServices"
                placeholder="Select Service">
            </Dropdown>
        </div>
        <div class="form-input-row" v-if="showProducts">
            <label for="type" class="form-input-label">{{'Add Product' | translate}}:</label> 
            <Dropdown 
                v-on:item-selected="productSelected = $event" 
                v-bind:selected="product.type" 
                v-bind:optioncontent="allProducts"
                placeholder="Select Product">
            </Dropdown>
        </div> -->
        <ItemInfoTable 
            v-if="promotionItems.length > 0"
            v-bind:infotableItems="promotionItems"
            v-on:table-total-updated="promotionTotalsData = $event"
        />
        
        <div class="form-input-row">
            <label class="form-input-label">{{'Retail' | translate }}:</label> 
            <div class="promotion-savings">{{retailTotal | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Sales Tax' | translate }}:</label> 
            <div class="promotion-savings">{{salesTaxTotal | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Pay Amount' | translate}}</label>
            <div class="invoice-total" v-if="!isPaid">{{payAmount | toUSD}}</div> 
            <div class="form-content-item" v-if="isPaid" >{{Number(this.invoiceInfo.invoicetotal) + Number(this.invoiceInfo.tip) | toUSD}}</div>           
        </div > 
        <div class='missing-selection-message' v-if="showError && promotionTotal === 0">*{{'You need to enter data to calculate a total' | translate}} </div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Savings' | translate }}:</label> 
            <div class="promotion-total" >{{savingsTotal | toUSD}} ({{promotionPrecentSavings}}%)</div>
        </div>

        <div class="search-item-container">
            <div class="form-search-row"> 
                <div class="search-ahead-label">
                    {{'Add' | translate }} 
                </div>
                <div class="search-ahead-item">
                    <SearchAhead 
                        v-show="showProductSearch"
                        v-bind:searchtype="addSearch.type" 
                        v-bind:searchcall="addSearch.dbcall" 
                        v-bind:resultfields="addSearch.resultfields"
                        v-on:item-selected="selectedProduct = $event"
                        :clearterm="true" >
                    </SearchAhead>
                </div>
                <div class="search-selection-item">
                    <SelectionSwitch  
                        :switches="['PRODUCTS','SERVICES','CREDIT']"
                        v-on:item-selected="selectedSearchType = $event"
                        :selectedSwitch= "'PRODUCTS'"
                        >
                    </SelectionSwitch>
                </div>
            </div>
        </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" >
        </ButtonBar>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Promotion' | translate}} {{'successfully added' | translate}}</div>
        </div>
        
    </div>
</template>

<script>
import Dropdown from './utility/Dropdown'
import Constants from '../utilities/constants.js'
import store from '../store/store.js'
import ItemInfoTable from './utility/ItemInfoTable'
import CalendarMini from './utility/CalendarMini'
import Globals from '../utilities/globals'
// import CheckBox from './utility/CheckBox'
import ButtonBar from './utility/ButtonBar'
import SearchAhead from './utility/SearchAhead'
import SelectionSwitch from './utility/SelectionSwitch'

export default {
    name: 'PromotionAdd',
    data() {
        return{
            promotion: {
                type:'', 
                selectedStartDate: '',
            },
            promotionTypes: Constants.promotionTypes,
            expirationOptions: Constants.expirationLength,
            expiration: {},
            expirationSelected: {},
            promotionType: {},
            promotionItems: [],
            promotionTotal: 0,
            promotionPrecentSavings: 0,
            promotionTotalsData: {},
            retailTotal: 0,
            savingsTotal: 0,
            SaleSavings: 0,
            salesTaxTotal: 0,
            showServices: false,
            showProducts: false,
            service: {},
            product: {},
            allServices: [],
            allProducts: [],
            serviceSelected: {},
            productSelected: {},
            store,
            showStartCalendar: false,
            showEndCalendar: false,
            showError: false,
            dateError: false,
            startDate: '',
            endDate: '',
            creditCost: '',
            creditAmount: '',
            showSuccess: false,
            applyTo: {products: false, services: false, promotions: false},
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            showProductSearch: true,
            productSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            serviceSearch: {
                dbcall: 'getServiceSearch',
                type: 'Services',
                resultfields: ['name']
            },
            promotionSearch: {
                dbcall: 'getPromotionSearch',
                type: 'Promotions',
                resultfields: ['name']
            },
            creditSearch: {
                dbcall: 'getCreditSearch',
                type: 'Credit',
                resultfields: ['name']
            },
            addSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            invoiceProducts:[],
            selectedProduct: {},
            preventUpdate: false,
            selectedSearchType:'',
            isPaid: false,
            payAmount: 0,
            salesTaxProductsAmt: 0,
            salesTaxServiceAmt: 0,
            productServiceTotal: 0,
            invoiceTotalsData: {total:'',retail:''},
        }    
    },
    components: {
        Dropdown,
        ItemInfoTable,
        CalendarMini,
        ButtonBar,
        SearchAhead,
        SelectionSwitch
    },
    methods: {
        
        addProduct() {
            if (this.selectedProduct.category == 'promotion') {
                this.selectedProduct.items.forEach(item => {
                    item.dateEntered = Globals.formatDateYMDdash(new Date())
                    item.businessid = this.$store.getters.user.businessid
                    this.invoiceProducts.push(item)
                })
                
            } else {
                this.selectedProduct.discount = 0;
                this.selectedProduct.quantity = 1;
                this.selectedProduct.dateEntered = Globals.formatDateYMDdash(new Date())
                this.selectedProduct.businessid = this.$store.getters.user.businessid
                this.invoiceProducts.push(this.selectedProduct)
            }
            
            
            window.console.log('ADD PROD', this.invoiceProducts, this.selectedProduct)
            this.calcInvoiceTotal()
        },
        calcInvoiceTotal: function() {
            let vm = this;
            let invTotal = 0
            vm.salesTaxProductsAmt = 0
            vm.salesTaxServiceAmt = 0
            vm.productServiceTotal = 0
            vm.savingsTotal = 0
            vm.retailTotal = 0
            vm.invoiceProducts.forEach(item => {  
                let tax = 0
                if (item.category === 'service') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxservices)/100
                    vm.salesTaxServiceAmt += tax
                } 
                if (item.category === 'product') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxproducts)/100
                    vm.salesTaxProductsAmt += tax
                }  
                vm.retailTotal += Number(item.retailprice) * Number(item.quantity) 
                vm.productServiceTotal += item.total  
                vm.salesTaxTotal =   vm.salesTaxServiceAmt +  vm.salesTaxProductsAmt     
                invTotal += item.total  + tax 
                vm.savingsTotal = vm.retailTotal - invTotal + tax
                vm.payAmount = invTotal 
                vm.promotionTotal = vm.retailTotal - vm.savingsTotal
                vm.promotionPrecentSavings = Math.round((1 - (Number(vm.promotionTotal)/Number(this.retailTotal))) * 100)
            })
            
        },
        loadData() {
            this.$store.dispatch('getServices').then(response => {
                window.console.log('LOAD SERVICES', response)
                this.allServices = response
            })
            this.loadProducts()
        },
        startFocus(){
            this.showStartCalendar = true;
        },
        endFocus(){
            this.showEndCalendar = true;
        },
        loadProducts() {
            this.$store.dispatch('getProducts').then(response => {
                window.console.log('PRODUCTS LOADED IN PROMO', response)
                this.allProducts = response
            })
        },
        addPromotion() {
            this.checkData()
            //check all data entered
            //check data conflicts
                //end date before start date
                //start date after end date
                //no total
                //no savings
                //no name
            //submit promotion
        },
        checkData() {
            window.console.log('CHECK PROMOTION ITEMS', this.promotionItems)
            window.console.log('PROMOTION', this.promotion, this.applyTo)
            this.showError = false
            if (!this.promotion.name || !this.promotion.selectedStartDate || !this.promotion.selectedEndDate || this.promotionTotal === 0  ) {
                window.console.log('SHOW ERROR', this.creditAmount, this.creditCost)
                this.showError = true
            } else {
                this.validateValues()
            }
        },
        validateValues() {
            
            this.dateError = false
            if (new Date(this.promotion.startDate) > new Date(this.promotion.endDate)) {
                this.dateError = true
            } else {
                this.submitPromotion()
            }           
        },
        calcCredit() {
            window.console.log('CALC CREDIT')
            this.promotionTotal = this.promotion.creditcost
            this.retailTotal = this.promotion.creditamount
            this.savingsTotal = this.retailTotal - this.promotionTotal
            if (isNaN(this.savingsTotal) || isNaN(this.retailTotal)) {
                this.promotionPrecentSavings =  ''
            } else {
                this.promotionPrecentSavings = ((this.savingsTotal/this.retailTotal) * 100).toFixed(1) + '%'
            }
        },
        formatCredit() {
            window.console.log(this.promotion.creditamount)
            this.creditAmount = this.toUSD(this.promotion.creditamount)
        },
        formatCost() {
            window.console.log(this.promotion.creditcost)
            this.creditCost = this.toUSD(this.promotion.creditcost)
            this.calcCredit()
        },
        toUSD(amt) {
            return '$' + Number.parseFloat(amt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        },
        addApplyTo() {
            if (this.promotionType.id === 1 ) {
                this.promotion.applyto = this.applyTo
                this.promotionItems.forEach(item => {
                    if (item.category === 'credit') {
                        item.applyto = this.applyTo
                    }
                })
            }             
        },
        submitPromotion() {  
            this.addApplyTo()         
            this.promotion.items = this.invoiceProducts
            this.promotion.retailprice = this.retailTotal
            this.promotion.saleprice = this.promotionTotal
            this.promotion.salestax = this.salesTaxTotal
            this.promotion.savings = this.savingsTotal
            this.promotion.type = this.promotionType.name
            this.promotion.discount = Math.round((1 - (Number(this.promotionTotal)/Number(this.retailTotal))) * 100)
            this.promotion.expirationdate = this.calcExpirationDate()
            this.addExpirationDate()
            
            window.console.log('ADD PROMOTION',this.promotion)
            this.$store.dispatch('addPromotion',this.promotion).then(response => {
                if (response.result == 'SUCCESS'){
                    this.showSuccess = true             
                    this.promotion = {}
                    this.promotionItems = []
                    this.calcCredit()                                                     
                }
            })
        },
        calcExpirationDate() {
            let expirationdate = new Date()
            let calcEndDate = new Date(this.promotion.enddate)
            expirationdate.setDate(calcEndDate.getDate() + this.expirationSelected.value)
            return new Date(expirationdate)
        },
        addExpirationDate() {
            this.promotionItems.forEach(item => {
                item.expirationdate= this.calcExpirationDate()
            })
        },
        getUsePlaceholder() {
            return `${Globals.translate('Select')} ${Globals.translate('Use By Length')}` 
        },
        addCreditItem() {
            const creditItem = {
                id: 0,
                name: 'Credit',
                type: 'credit',
                category: 'credit',
                retailprice: 1,
                saleprice: 1,
                discount: 0,
                quantity: 0,
                unit: 'dollar',
                total: 0,
                businessid: 1,
                locations: [],
                applyto: {},
                expirationdate: '',
            }
           
            this.promotionItems.push(creditItem)
             window.console.log('ADD CREDIT ITEM', creditItem, this.promotionItems)
        }
    },
    watch: {
        invoiceTotalsData() {
            window.console.log('UPDATE DATA', this.invoiceTotalsData)
            this.calcInvoiceTotal()
        },
        selectedSearchType() {
            window.console.log('SELECTED SEARCH CHANGED', this.selectedSearchType)
            if (this.selectedSearchType === 'PRODUCTS') {
                window.console.log('SELECTED products')
                this.addSearch = this.productSearch
            }
            if (this.selectedSearchType === 'SERVICES') {
                window.console.log('SELECTED SERVICES')
                this.addSearch = this.serviceSearch
            }
            if (this.selectedSearchType === 'PROMOTIONS') {
                window.console.log('SELECTED PROMOTIONS')
                this.addSearch = this.promotionSearch
            }
            if (this.selectedSearchType === 'CREDIT') {
                window.console.log('SELECTED PROMOTIONS')
                this.addSearch = this.creditSearch
            }
        },
        selectedProduct() {
            this.addProduct()
        },
        promotionType() {
            window.console.log('TYPE PICKED', this.promotionType, this.promotionType.id)
            this.showServices = this.promotionType.id === 2 || this.promotionType.id === 4 ? true : false
            this.showProducts = this.promotionType.id === 3 || this.promotionType.id === 4 ? true : false
            if (this.promotionType.id === 1) { 
                this.addCreditItem()
            } 
            
        },
        serviceSelected() {
            window.console.log('SERVICE SELECTED', this.serviceSelected)
            this.promotionItems.push(this.serviceSelected)
            this.service.type = ''
        },
        productSelected() {
            window.console.log('PRODUCT SELECTED', this.productSelected)
            this.promotionItems.push(this.productSelected)
            this.product.type = ''
        },
        startDate() {
            window.console.log('START DATE', this.startDate)
            this.promotion.selectedStartDate = this.startDate.selectedDate;
            this.promotion.startdate = `${this.startDate.year}/${this.startDate.month + 1}/${this.startDate.day}`            
            this.showStartCalendar = false;
        },
        endDate() {
            this.promotion.selectedEndDate = this.endDate.selectedDate;
            this.promotion.enddate = `${this.endDate.year}/${this.endDate.month + 1}/${this.endDate.day}`
            this.showEndCalendar = false;
        },
        promotionTotalsData() {
            window.console.log('PRO TOT DATA', this.promotionTotalsData)
            this.promotionTotal = this.promotionTotalsData.total
            this.retailTotal = this.promotionTotalsData.retail
            this.savingsTotal = this.retailTotal - this.promotionTotal
            this.promotionPrecentSavings = ((this.savingsTotal/this.retailTotal) * 100).toFixed(1) + '%'
        },
        creditCost() {
            
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.addPromotion()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    },
    mounted() {
        this.loadData()
    }
}
</script>

<style scoped>
.promotion-total{
    display: inline-block;
    font-weight: 600;
    position: relative;
    top: 3px;
} 
.promotion-savings{
    display: inline-block;
} 
.form-checkbox-row{
    text-align: left;
    margin-top: -12px;
}
.form-search-row{    
    height: 30px;
}
.search-ahead-label{
    display: inline-block;
    position: relative;
    top: 3px;
}
.search-ahead-item{
    display: inline-block;
    position: relative;
    top: 0;
}
.search-selection-item{
    display: inline-block;
    position: relative;
    top: 2px;
    padding-left: 5px;
}
.invoice-total{
    display:inline-block;
    position: relative;
    top: 2px;
}
.search-item-container{
    border-top: 1px solid #d3cdcd;
    border-bottom: 1px solid #d3cdcd;
    margin-bottom: 10px;
    padding: 5px 0 9px;
}

</style>