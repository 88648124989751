<template>
    <div>
        Introduction
    </div>
</template>

<script>
export default {
    name: 'Introduction',
    data() {
        return{

        }
    },
    components: {

    },
    methods: {

    }
}
</script>

<style scoped>
    
</style>