<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'UPDATE STAFF' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Staff' | translate}}:<span class="required-entry">*</span></label> 
            <Dropdown  
                v-on:item-selected="selectedStaff = $event" 
                v-bind:selected="selectedStaff.displayname" 
                v-bind:optioncontent="allStaff"
                placeholder="Select Staff">
            </Dropdown>
        </div>
        <!-- FROM PROFILE -->
        <div v-if="showProfile">
            <div v-if="!showGeneralInfo">
                <div>{{user.prefix}}  {{user.firstname}} {{user.lastname}}</div>
                <div>{{user.displayname}}</div>
                <div>{{user.phone | formatPhone}} | {{user.email}}</div>
                <div>{{user.street}} {{user.address2}}</div>
                <div>{{user.city}}, {{user.state}} {{user.zip}}</div>
            </div>
            

            <!-- GENERAL INFO -->
            <div v-if="showGeneralInfo">
                <hr>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Prefix' | translate}} </label> 
                    <Dropdown 
                        v-on:item-selected="userPrefix = $event" 
                        v-bind:selected="user.prefix" 
                        v-bind:optioncontent="allPrefixes"
                        :placeholder="user.prefix">
                    </Dropdown>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'First Name' | translate}}:<span class="required-entry">*</span></label> 
                    <input class="input-wide" v-model="user.firstname"/>
                </div>
                <div class='missing-selection-message' v-if="showError && !user.firstname">*{{'You need to enter a' | translate}} {{'First Name' | translate}}</div>

                <div class="form-input-row">
                    <label class="form-input-label">{{'Last Name' | translate}}:<span class="required-entry">*</span></label> 
                    <input class="input-wide" v-model="user.lastname"/>
                </div>
                <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Last Name' | translate}}</div>

                <!-- DISPLAY NAME -->
                <div class="form-input-row">
                    <label  class="form-input-label">{{'Display Name' | translate}}:<span class="required-entry">*</span> </label>
                    <div class="form-input-container">  
                        <input  v-model="user.displayname"/>
                        <InfoButton
                            :infocontent="'INFO_CONTENT.DISPLAY_NAME'"
                            :positiontop="'-16px'"
                            :positionleft="'10px'"
                        />
                    </div>
                </div>              
                <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Display Name' | translate}}</div>

                <!-- CONTACT INFO -->
                <PhoneNumber 
                    v-on:number-updated="user.phone = $event" 
                    v-bind:existingnumber="user.phone" >
                </PhoneNumber>
                <div class='missing-selection-message' v-if="showError && !user.phone">*{{'You need to enter your' | translate}} {{'Phone Number' | translate}}</div>

                <div class="form-input-row">
                    <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
                    <input class="input-wide" v-model="user.email"/>
                </div>
                <div class='missing-selection-message' v-if="showError && !user.email">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>
                <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>

                <!-- ADDRESS INFO -->
                <div class="form-input-row">
                    <label class="form-input-label">{{'Street' | translate}}:</label> 
                    <input class="input-wide"  v-model="user.street"/>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Suite' | translate}}/{{'Apt' | translate}}/{{'Unit' | translate}}:</label> 
                    <input class="input-wide"  v-model="user.address2"/>
                </div>
                <ZipLookup 
                    v-on:zip-found="zipInfo = $event"
                    :existingzip ="user.zip"
                />
                
            </div>
            
            <div class="profile-update-link" @click="showGeneralInfo = !showGeneralInfo">
                <span v-if="!showGeneralInfo">{{'Edit General Info' | toLowerCase}}</span>
                <span v-if="showGeneralInfo">{{'Close General Info' | toLowerCase}}</span>
                </div>
            <hr>
            <div class="title-row">
                {{'Application Use Restrictions' | translate}}
            </div>
             <div class="form-input-row">
                <label class="form-input-label">{{'Restrict To Approved Devices' | translate}}:</label>
                <div class="checkbox-item">
                    <CheckBox                     
                        v-bind:cbmodel="user.devicerestrict"
                        v-on:item-selected="user.devicerestrict = $event"
                        />
                </div>
             </div>
             <div class="form-input-row">
                <label class="form-input-label">{{'Restrict to On-Site Only' | translate}}:</label>
                <div class="checkbox-item">
                    <CheckBox                     
                        v-bind:cbmodel="user.locrestrict"
                        v-on:item-selected="user.locrestrict = $event"
                        />
                </div>
             </div> 
            <!-- ROOM PREFERENCE -->
            <hr>
            <div class="title-row">
                {{'Default' | translate}} {{'Room' | translate}} {{'Preference' | translate}}
            </div>
             <div class="form-input-row">
                <label class="form-input-label">{{'Default' | translate}} {{'Room' | translate}} {{'Preference' | translate}}:</label>
                <Dropdown
                    v-on:item-selected="selectedRoom = $event" 
                    v-bind:selected="selectedRoom.name" 
                    v-bind:optioncontent="allRooms"
                    :placeholder="roomPlaceholder">
                </Dropdown>
             </div>

            <hr>
            <div v-if="showPayInfo">
               
                <div class="form-input-row">
                    <label for="type" class="form-input-label">{{'Title' | translate}} </label> 
                    <input id="type" v-model="user.title"/>
                </div>
                <div class="form-bookable-checkbox-row">
                    <label class="form-input-label">{{'Bookable' | translate}}:<span class="required-entry">*</span></label> 
                    <div class="form-input-container"> 
                        
                        <div class="checkbox-item">
                            <CheckBox                     
                                v-bind:cbmodel="user.bookable"
                                v-on:item-selected="user.bookable = $event"
                                />
                        </div>
                        <InfoButton
                            :infocontent="'INFO_CONTENT.BOOKABLE'"
                            :positiontop="'-2px'"
                            :positionleft="'-6px'"
                        />
                    </div>
                </div>
                <div class="form-bookable-checkbox-row">
                    <label for="type" class="form-input-label">{{'Active' | translate}} </label> 
                    <div class="checkbox-item">
                            <CheckBox                     
                                v-bind:cbmodel="user.active"
                                v-on:item-selected="user.active = $event"
                                />
                        </div>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Pay Amt' | translate}}:</label> 
                    <InputCurrency
                        v-on:amount-entered="user.payrate = $event"
                        :amount="payRate"
                    ></InputCurrency>
                    <Dropdown 
                        v-on:item-selected="user.paytype = $event.name" 
                        v-bind:selected="user.paytype" 
                        v-bind:optioncontent="salaryTypes"
                        :placeholder="getPayType">
                    </Dropdown>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Commission Services' | translate}} (%):</label> 
                    <input class="input-narrow"  v-model="user.commissionservices"/>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Commission Products' | translate}} (%):</label> 
                    <input class="input-narrow"  v-model="user.commissionproducts"/>
                </div>
            </div>
            <div v-if="!showPayInfo">
                <div class="title-row">{{'Pay Information' | translate}}</div>
                <div>
                    <span class="profile-label">{{'Payrate' | translate}}: </span>
                    <span class="profile-data">${{user.payrate}}/hour</span>
                </div>
                <div>
                    <span class="profile-label">{{'Commission Products' | translate}}: </span>
                    <span class="profile-data">{{user.commissionproducts}}%</span>
                </div>
                <div>
                    <span class="profile-label">{{'Commission Services' | translate}}: </span>
                    <span class="profile-data">{{user.commissionservices}}%</span>
                </div>
                <div>
                    <span class="profile-label">{{'Title' | translate}}: </span>
                    <span class="profile-data">{{user.title}}</span>
                </div>
                <div>
                    <span class="profile-label">{{'Bookable' | translate}}: </span>
                    <span class="profile-data">{{user.bookable}}</span>
                </div>
                <div>
                    <span class="profile-label">{{'Start Date' | translate}}: </span>
                    <span class="profile-data">{{user.startdate | longDate}}</span>
                </div>
            </div>

            <!-- <div class="profile-update-link" @click="showPersonalInfo = !showPersonalInfo">{{'Edit Personal Info' | toLowerCase}}</div> -->
            <div v-if="showPayInfo">
                <!-- PERSONAL INFO -->
                <div class="form-input-row">
                    <label class="form-input-label">{{'Social Security Number' | translate}}:</label> 
                    <input class="input-medium"  v-model="user.ssnumber"/>
                </div>
                <div class="form-input-row">
                    <label class="form-input-label">{{'Date of Birth' | translate}} <span class="dob-format">({{'MM' | translate}}/{{'DD' | translate}}/{{'YYYY' | translate}})</span>:</label> 
                    <input class="input-medium"  v-model="formattedDate" @input="formatDate"/>
                </div>
            </div>
            <div class="profile-update-link" @click="showPayInfo = !showPayInfo">
                <span v-if="!showPayInfo">{{'Edit Pay Info' | toLowerCase}}</span>
                <span v-if="showPayInfo">{{'Close Pay Info' | toLowerCase}}</span>
            </div>
            <hr>
            <!-- CERTIFICATIONS -->
            <div class="title-row">{{'Certifications' | translate}}</div>
            <div class="form-checkbox-row" v-if="allCertifications.length">
                
                <div class="form-input-container centered"> 
                    <div class="checkbox-item" v-for="(cert, index) in allCertifications" v-bind:key="'cert' + index">
                        <CheckBox 
                            v-bind:checkBoxLabel="cert.name"
                            :cbmodel="checkCertifications(cert.id)"
                            v-on:item-selected="certifications[cert.id] = $event" 
                            />
                    </div>
                    <InfoButton
                        :infocontent="'INFO_CONTENT.CERTIFICATIONS'"
                        :positiontop="'-2px'"
                        :positionleft="'-16px'"
                    />
                </div>
            </div>
            <!-- PERMISSIONS -->
            <Permissions 
                v-if="showPermissionInfo"
                :permissionrole="'staff'"
                :userpermissions="user.permissions"
                v-on:update-permissions="user.permissions = $event"
                
            />
            <div class="profile-update-link" @click="showPermissionInfo = !showPermissionInfo">
                <span v-if="!showPermissionInfo">{{'Edit Permissions' | toLowerCase}}</span>
                <span v-if="showPermissionInfo">{{'Close Permissions' | toLowerCase}}</span>
            </div>

            <!-- LOCATIONS -->
            <div class="title-row">{{'Schedule' | translate}} </div> 
            <div v-if="showSchedule">
                <div class="form-checkbox-row" v-if="allLocations.length > 1">            
                    <div class="locations-container" :class="{'show-schedule':showSchedules} ">
                        <div class="locations-checkbox-item" 
                            v-for="(location, index) in allLocations" 
                            v-bind:key="'cert' + index"
                            >
                            <CheckBox 
                                v-bind:checkBoxLabel="location.name"
                                v-on:item-selected="locations[location.id] = $event,ckSch = new Date()" 
                                v-bind:cbmodel="locations[location.id]"
                                :cbref="location.id"
                                />
                            <div class="radiobutton-container">
                                <RadioButton 
                                    :rbLabel="'Primary'"
                                    :rbStatus="applyTo[index] && applyTo[index].status"
                                    :rbRef="location.id"
                                    v-on:rb-selected="rbWatcher = $event"
                                />
                            </div>
                            <WeekSchedule
                                class="staff-week-schedule"
                                v-if="locations[location.id]"
                                :scheduletitle="'Work Hours'"
                                :closename="'Off'"
                                v-on:schedule-updated="scheduleInfo[location.id] = $event"
                                :existingschedule="location.schedule"
                            />
                        </div>
                        
                    </div>
                </div>
                <WeekSchedule
                    v-if="allLocations.length === 1"
                    :scheduletitle="'Work Hours'"
                    :closename="'Off'"
                    v-on:schedule-updated="scheduleInfo[allLocations[0].id] = $event"
                    :existingschedule="allLocations[0].schedule"
                />
            </div>
            <!-- COLOR BLOCKS -->
            <div class="form-bookable-checkbox-row color-row" >
                <label class="form-input-label color-label">{{'Display Color' | translate}}:</label> 
                <div class="form-input-container"> 
                    <div class="color-block-container">
                        <ColorBlocks 
                            v-on:color-selected="user.displaycolor = $event"
                            :selectedcolor="user.displaycolor"/>
                    </div>
                    <InfoButton
                        :infocontent="'INFO_CONTENT.COLOR_BLOCKS'"
                        :positiontop="'-2px'"
                        :positionleft="'0'"
                    />
                </div>
            </div>
            
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" />

            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{successNotice | translate}}</div>
            </div> 
            <div class="form-input-row" v-show="showEmailMatch">
                <div class="success-label">{{'Email already exists for' | translate}}: {{emailMatch.firstname}}  {{emailMatch.lastname}} </div>
            </div> 
        </div>

        
    </div>
</template>

<script>
import store from '../../store/store'
import ZipLookup from '../utility/ZipLookup'
import CheckBox from '../utility/CheckBox'
import PhoneNumber from '../utility/PhoneNumber'
import Dropdown from '../utility/Dropdown'
import RadioButton from '../utility/RadioButton'
import ButtonBar from '../utility/ButtonBar'
import ColorBlocks from '../utility/ColorBlocks'
import WeekSchedule from '../utility/WeekSchedule'
import Constants from '../../utilities/constants'
import InfoButton from '../utility/InfoButton'
import Globals from '../../utilities/globals'
import InputCurrency from '../utility/InputCurrency'
import Permissions from '../utility/Permissions'

export default {
    name: 'StaffUpdate',
    data() {
        return {
            user: {locations:[]},
            userPrefix: '',
            userStatus: '',
            staffInfo: {},
            showError: false,
            showSuccess: false,
            showEmailError: false,
            showEmailMatch: false,
            showSchedules: false,
            showGeneralInfo: false,
            showPersonalInfo: false,
            showSchedule: true,
            showProfile: false,
            showPayInfo: false,
            showPermissionInfo: false,
            store,
            allCertifications: [],
            certifications: {},
            zipInfo: {},
            formattedDate: '',
            emailMatch: {},
            allLocations: this.$store.getters.allLocations,
            locations:{},
            applyTo:[],
            rbWatcher: '',
            applyToPayType:{'hourly': false,'fixed': false,'commission': false},
            rbWatcherPayType: '',
            pageTitle: 'Add Staff',
            buttonList: ['Update', 'Cancel'],
            buttonClicked: '',
            isUpdate: false,
            successNotice: 'Staff Successfully Updated',
            scheduleInfo: {},
            allPrefixes: Constants.prefixes,
            salaryTypes: Constants.salaryTypes,
            ckSch: '',
            showWS: {},
            selectedStartDate: '',
            selectedStaff: {},
            allStaff: this.$store.getters.allStaff,
            scheduleStartDate: {},
            showStartCalendar: false,
            payType: {},
            payRate:'',
            allRooms: [],
            selectedRoom: {},
            placeholderLabel: 'Select Room',
            roomPlaceholder: this.placeholderLabel
        }
    },
    components: {
       ZipLookup,
       CheckBox,
       PhoneNumber,
       Dropdown,
       RadioButton,
       ButtonBar,
       ColorBlocks,
       WeekSchedule,
       InfoButton,
       InputCurrency,
       Permissions
    },
    methods: {
        verifyForm() {
            
            window.console.log('USER LOCATIONS', this.user.loctions,'USER INFO', this.user)
            this.showSuccess = false
            this.showError = false         
            if (!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone ) {
                window.console.log('ERROR FOUND')
                if (this.user.email) {
                    this.showEmailError = Globals.validateEmail(this.user.email) ? false : true
                }
                this.showError = true
            } else {
                if (this.user.email) {
                    this.showEmailError = Globals.validateEmail(this.user.email) ? false : true
                }
                if (!this.showEmailError) {
                    this.submitForm()
                }       
            }
        },
        startFocus(){
            window.console.log('SHOW CAL')
            this.showStartCalendar = true;
        },
        formatDate() {
            let cleaned = ('' + this.formattedDate).replace(/\D/g, '')

            if(cleaned.length===2) {
                this.formattedDate = cleaned + '/'
            }
            if(cleaned.length==4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}`
            }
            if(cleaned.length>4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}/${cleaned.substr(4,4)}`
            }
        },
        setScheduleInfo() {
            this.user.schedule = []
            
            Object.keys(this.scheduleInfo).forEach(locSch => {
                window.console.log('EACH SCHUDLE LOCXXXXX', locSch)
                let schedule = {}
                schedule.id = Number(locSch) 
                schedule.name = this.getLocationName(locSch)
                schedule.data = this.scheduleInfo[locSch]
                this.user.schedule.push(schedule)
            })
        },
        getLocationName(id) {
            let locName = ''
            this.allLocations.forEach(loc => {
                if (loc.id == id) {
                    locName = loc.name
                }
            })
            return locName
        },
        submitForm() {
            window.console.log('CERTS', this.certifications,this.zipInfo)
            this.user.type = 'staff'
            this.user.businessid = this.$store.getters.user.businessid
            this.user.zip = this.zipInfo.zip
            this.user.city = this.zipInfo.city
            this.user.state = this.zipInfo.state
            this.user.dob = this.formattedDate ? this.formattedDate : null
            this.user.status = 'active'
            this.user.certifications = []
            this.setUserLocations()
            this.setScheduleInfo()
            // NEED TO ADD THESE
            this.user.password = this.$store.getters.user.defaultpassword
            Object.keys(this.certifications).forEach(item => {                              
                if (this.certifications[item]) {
                    this.user.certifications.push(Number(item))
                }
            })
            this.showEmailMatch = false
            this.emailMatch = {}
            window.console.log('SUBMIT NEW STAFF INFO', this.user)
            // CHECK EMAIL TO SEE IF ALREADY EXISTS (NO DUPLICATE EMAILS AS USED LOGIN)
            // this.$store.dispatch('checkEmail',{email: this.user.email}).then(response => {
            //     // IF NO SAME EMAIL ASSUME NEW USER AND ADD 
            //     if (response.result==='NO MATCH') {
            //         this.$store.dispatch('addStaff', this.user).then(response => {
            //             if (response.result==='SUCCESS') {
            //                 this.showSuccess = true
            //             }
            //         })
            //     } else {
            //         //  CHECK TO SEE IF SAME USER EMAIL ALREADY EXISTS IN STAFF
            //         this.emailMatch = response.data[0]                   
            //         this.$store.dispatch('checkEmailStaff',{email: this.user.email}).then(response => {
            //             // IF SAME EMAIL AND PHONE AND LISTED AS USER BUT NOT STAFF THEN ADD TO STAFF
            //             if (this.emailMatch.phone === this.user.phone && response.result==='NO MATCH') {
            //                 this.user.userid = this.emailMatch.id
            //                 this.$store.dispatch('updateStaff',this.user).then(response => {
            //                     if (response.result==='SUCCESS') {
            //                         this.showSuccess = true
            //                     }
            //                 })
            //             } else {
            //                 this.showEmailMatch = true
            //             }
            //         })
            //     }
            // })                   
        },        
        getCertifications() {
            this.$store.dispatch('getCertifications',{id:this.$store.getters.user.businessid}).then( () => {
                this.allCertifications = this.$store.getters.allCertifications
            })
        },
        getLocations() {
            window.console.log('GET LOCATIONS', this.$store.getters.user.businessid)
            this.$store.dispatch('getLocations',{id:this.$store.getters.user.businessid}).then(response => {
                window.console.log('STAFF ALL LOCATIONS',this.$store.getters.user.businessid, response, this.user.primarylocation)
                window.console.log('CURRENT')
                this.allLocations = response
                this.allLocations.forEach(location => {
                    window.console.log('LOC EACH')
                    const rbstatus = this.$store.getters.currentLocation.id === location.id? true : false
                    this.applyTo.push({id:location.id,status:rbstatus})
                    this.locations[location.id] = rbstatus
                    if (rbstatus) {
                        this.showSchedules = true
                    }
                })
                if (this.allLocations.length === 1) {
                    
                    this.user.locations = [this.allLocations[0].id]
                }
                // DEFAULT TO FIRST LOCATION BEING PRIMARY
                this.user.primarylocation = this.allLocations[0].id
                window.console.log('AFTER GET LOC', this.locations)
            })
        },
        // 
        getAllStaff() {
            this.$store.dispatch('getAllStaff').then(response => {
                 window.console.log('GOT ALL STAFF', response)
                this.allStaff = response
                this.allStaff.map(staff => {
                    staff.name = staff.displayname
                })
            })
        },
        getStaffNum(allStaff) {  
            const userId = this.$store.getters.user.id 
            window.console.log('CK STAFF', allStaff)         
            for (let i = 0;i < allStaff.length; i++) {
                if (allStaff[i].userid === userId) {
                    this.staffInfo = allStaff[i]
                    return i
                }
            }           
        },
        checkCertifications(id) {
            if (this.user.certifications && this.user.certifications.indexOf(id) > -1) {
                return true
            }
            return false           
        },
        checkLocations(id) {
            window.console.log('CHECK LOC', id, this.locations)
            let vm = this
            Object.keys(vm.locations).forEach(loc => {
                window.console.log('EACH', id, loc, vm.locations[loc])
                if (vm.locations[loc]) {
                    window.console.log('RETURNING TRUE!!!', loc)
                    return true
                }
            })
            return  false
            // this.locations && this.locations.includes(id) ? true : false
        },
        updateStaff() {
             window.console.log('PERMISSIONS', this.user.permissions)
            this.showSuccess = false           
            this.setUserLocations()
            window.console.log('CERTS', this.certifications)
            this.user.certifications = []
            Object.keys(this.certifications).forEach(item => {                              
                if (this.certifications[item]) {
                    this.user.certifications.push(Number(item))
                }
            })
            if (this.zipInfo.zip) {
                this.user.city = this.zipInfo.city
                this.user.state = this.zipInfo.city
                this.user.zip = this.zipInfo.city
            }            
            this.user.dob = this.formattedDate ? this.formattedDate : null
            window.console.log('UPSTAFF USER', this.user,'LOCS', this.locations,'CERTS', this.certifications)
            this.$store.dispatch('updateStaff',this.user).then(response => {
                if (response.result === 'SUCCESS') {
                    this.staffNotice="Profile Successfully Updated"
                    this.showSuccess = true
                }
            })
            
        },
        setUserLocations() {
            // CHECK IF LOCATION CHECKS ADD OR SUBTRACTED FROM USER LOCATIONS
            const vm = this 
            window.console.log('LOCS', vm.locations,vm.user.locations,'USER', vm.user)
            vm.user.locations = []
            window.console.log('LOCS afer',vm.user.locations)
            Object.keys(vm.locations).forEach(loc => {
                if (vm.locations[loc]) {
                    // ADD TO USER LOCATION IF NOT EXIST
                    if ( !vm.user.locations){
                        window.console.log('RESET LOCATIONS')
                         vm.user.locations = [Number(loc)]
                    } else if ( !vm.user.locations.includes(loc)) {
                       vm.user.locations.push(Number(loc))
                    }
                } 
            })
            window.console.log('LOCS DATA', vm.user.locations)
            this.setLocationData()
        },
        setLocationData() {
            this.user.locationsdata = []
            window.console.log('SET LOC DATA', this.user.locations)
            this.user.locations.forEach(id => {  
                window.console.log('ID', id)          
                this.allLocations.forEach(loc => {
                    window.console.log('DATA', loc.id, id)
                    if (loc.id == id) {
                        const locObj = {
                            id: loc.id,
                            name: loc.name,
                            primary: loc.id == this.user.primarylocation ? true : false,
                        }
                        window.console.log('MATCH', locObj, this.user.primarylocation)
                        this.user.locationsdata.push(locObj)
                    }
                })
            })
            window.console.log('USERS LOCATION DATA', this.user.locationsdata)
        },
        ckShowSch(id) {
            window.console.log('CK SHOW SCH', id)
            if (this.locations[id]) {
                return true
            }
            return false            
        },
        setPayType(){
            this.salaryTypes.forEach(type => {
                window.console.log('EACH',type.name,this.user.paytype)
                if (type.name === this.user.paytype) {
                    
                    this.payType = type
                    window.console.log('MATCH',this.payType)
                }
            })
        },
        getRooms() {
            if (this.$store.getters.allRooms.length) {
                window.console.log('ROOMS EXIST', this.allRooms)
                this.allRooms = this.$store.getters.allRooms;
            } else {
                this.$store.dispatch('getRooms').then( response => {
                    this.allRooms = response
                    window.console.log ('ALL ROOMS RESULT', response)
                })
            }
        },
    },
    mounted() {        
        // this.ckExist()
        this.getAllStaff()
        this.getCertifications()
        this.getLocations()
        this.getRooms()
    },
    computed: {
        getPayType() {
            if (this.user.paytype) {
                return this.user.paytype
            }
            return 'Select Pay Type'
        }
    },  
    watch: {
        selectedStaff() {
            window.console.log('SELECTED STAFF', this.selectedStaff.id)
            this.$store.dispatch('getStaffById',{id:this.selectedStaff.id}).then(response => {
                window.console.log('GOT STAFF FULL INFO', response)
                this.user = response
                this.payRate = this.user.payrate
                this.payType = this.setPayType()
                this.showProfile = true
                this.selectedRoom = response.roompref? response.roompref : {};
                this.roomPlaceholder = response.roompref? response.roompref.name : this.placeholderLabel
                window.console.log('AFTER SELECT',this.user, this.payRate, this.payType)
            })
        },
        rbWatcher() {
            const resultArray = this.rbWatcher.split('~')
            this.applyTo.forEach(item => {
                if (item.id == resultArray[0]) {
                    item.status = resultArray[1]
                    if (resultArray[1]) {
                        this.user.primarylocation = resultArray[0]
                    }
                } else {
                    item.status = false
                }
            })
        },
        rbWatcherPayType() {
            const resultArray = this.rbWatcherPayType.split('~')
            Object.keys(this.applyToPayType).forEach(item => {
                this.applyToPayType[item] = false
            })
            this.applyToPayType[resultArray[0]] = Boolean(resultArray[1])
            if (resultArray[1]) {
                this.user.paytype = resultArray[0]
            }
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Update') {               
                this.updateStaff()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        userPrefix() {
            this.user.prefix = this.userPrefix.name
        },
        ckSch() {
            this.showSchedules = false
            Object.keys(this.locations).forEach(loc => {
                if ( this.locations[loc]) {
                    this.showSchedules = true
                }
            })
        },
        scheduleStartDate() {
            window.console.log('DATE PICKED', this.scheduleStartDate)
            this.selectedStartDate = this.scheduleStartDate.selectedDate
            this.user.startdate = Globals.formatDBDate(this.scheduleStartDate.day, this.scheduleStartDate.month,this.scheduleStartDate.year)         
            this.showStartCalendar = false;
            window.console.log('DATE PICKED', this.scheduleStartDate, this.user.startdate)
        },
        zipInfo() {
            window.console.log('ZIP INFO', this.zipInfo)
        },
        selectedRoom() {
            this.user.roompref = this.selectedRoom
        }
         
    }
}
</script>

<style scoped>
.form-checkbox-row{
    text-align: left;
    margin-top: -8px;
} 
.form-bookable-checkbox-row{
    text-align: left;
    margin-top: -12px;
    margin-bottom: 12px;
}
.color-row{
    margin-bottom: 0;
}
.form-input-label{
    vertical-align: middle;
}
.dob-format{
    font-size: 12px;
} 
.locations-container{
    display: inline-block;
    padding: 6px 7px;
} 
.locations-checkbox-item{

}
.vertical{
    vertical-align: top;
    margin-top: 5px;
}
.radiobutton-container{
    display: inline-block;
    vertical-align: top;
}
.color-label{
    vertical-align: top;
    margin-top: 16px;
}
.color-block-container{
    display: inline-block;
    margin-top: 13px;
}
.show-schedule{
    padding-left: 23%;
}
.title-row{
    padding-bottom: 10px;
    font-weight: 600;
}
.profile-update-link{
    color: #1e7ea5;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-top: 1px solid;
    padding: 5px;
    margin: 0 20%;
}
.profile-data{
    font-weight: 600;
}
.centered{
    display: block;
    text-align: center;
}
</style>