<template>
    <div class="login-form-container" :style="loginHeight">
        <div class="login-form-content" @keyup.enter="verifyEntry" >
            <div class="login-title-row">{{'User Log In' | translate | allCaps}}</div>
        
            <div class="login-input-row">
                <span class="required-entry">*</span>
                <input class="login-input" type="email" v-model="user.email" placeholder="Email Address"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !this.user.email ">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>

            <div class="login-input-row">
                <span class="required-entry">*</span>
                <input class="login-input" :type="passwordType"  v-model="user.password" placeholder="Password"/>
                <div class="password-selection-container"  @click="showHidePassword" >
                    <i v-if="passwordType==='password'" class="far fa-eye"></i>
                    <i v-if="passwordType==='text'" class="far fa-eye-slash"></i>
                </div>
            </div>
            <div class='missing-selection-message' v-if="showError && !this.user.password ">*{{'You need to enter your' | translate}} {{'Password' | translate}}</div>

            <div class="password-link" @click="forgotPassword">forgot password?</div>
            <div>{{wordList}}</div>
            <div class="button-main" @click="verifyEntry">{{'Submit' | translate}}</div>
            <div class='login-error-message' v-if="showLoginError">{{loginError | translate}}<br>{{'Please Try Again' | translate}}</div>
            <div class='login-required'>*{{'required' | translate}}</div>
        </div>    
    </div>
</template>

<script>
import store from '../../store/store'
import EventBus from '../../utilities/eventBus'
import Globals from '../../utilities/globals'
export default {
    name: 'Login',
    data() {
        return {
            user: {
                name: '',
                password: ''
            },
            showError: false,
            showLoginError: false,
            store,
            wordList: '',
            loginError: 'Error Logging In',
            deviceApproved: false,
            passwordType: 'password',
            loginHeight: {}
        }
    },
    components:{
    },
    methods: {
        forgotPassword() {
            this.$router.push('PasswordReset')
        },
        verifyEntry() {
            window.console.log('verifyEntry')
            this.showError = false
            if (!this.user.email || !this.user.password) {
                this.showError = true
            }
            if (!this.showError) {
                this.submitLogin()
            }
        },
        showHidePassword() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
        },
        setDateToday() {
            const today = new Date()
            const day = today.getDate()
            const month = today.getMonth()
            const monthName = today.toLocaleString('default', { month: 'long' })
            const dayName = today.toLocaleDateString('en-us', { weekday: 'long' })
            const year = today.getFullYear()
            const dateData = {
                selectedDate: `${dayName} ${monthName} ${day}, ${year}`,
                day: day,
                month: month,
                year: year
            }
            this.$store.commit('setDate', dateData)
            this.$store.commit('setDisplayType', 'DAY')
        },
        submitLogin() {
            this.user.email = this.user.email.toLowerCase()
            EventBus.$emit('SHOW_SPINNER')
            this.showLoginError = false
            this.$store.dispatch('userLogin', this.user).then(response => {
                window.console.log('USERINFO:', this.user)
                EventBus.$emit('HIDE_SPINNER')
                const results = response
                // window.console.log('LOGIN RESULTS',response, response.data)
                if (results && results.result === 'SUCCESS') {
                    this.deviceApproved = window.localStorage.getItem('deviceApprovalStatus') ? window.localStorage.getItem('deviceApprovalStatus') : false
                    window.console.log('CHECK IN',results.data.devicerestrict ,this.deviceApproved)
                    if (results.data.devicerestrict && !this.deviceApproved){
                        window.console.log('SHOW NOT APPROVED')
                        this.loginError = 'Not Approved To Use This Device'
                        this.showLoginError = true
                        return
                    }
                    Globals.setBusinessType(results.data.businesstype)
                    results.data.email = this.user.email    
                    window.console.log('HEY ReSULTS', results.data, this.deviceApproved)
                    Globals.key = results.data.key              
                    this.$store.commit('setUser',results.data)
                    this.$store.commit('setLocations',[])
                    this.$store.dispatch('getLocations',{id:results.data.businessid}).then(response => {
                        if (response.result == 'SUCCESS') {
                            EventBus.$emit('USER_LOGIN')
                            if (this.user.password === 'newStaff1') {                            
                                // window.console.log('USER NEEDS TO CHANGE PASSWORD', this.$store.getters.user)
                                this.$router.push({ path: 'PasswordReset', query: { loggin: 'new' }  })
                            } else {
                                window.console.log('LOGGED IN', this.user, this.deviceApproved)
                                this.setDateToday()
                                this.$router.push('Schedule')
                            }
                        } else {
                            this.loginError = 'Error Logging In'
                            this.showLoginError = true
                        }
                    })
                } else {
                    if (response.response) {
                        this.loginError = response.response
                    } else {
                        if (response.request && response.request.status == 0) {
                            this.loginError = 'No Network Connection'
                        } else {
                            this.loginError = 'Your Email or Password Does not Match'
                        }
                    }
                    window.console.log('ERROR RESULTS', response)
                    this.showLoginError = true
                }
            })
        },
        // writeCookie() { 
        //     window.console.log('write cookie')       
        //     let cookievalue = "sam"
        //     let now = new Date();
        //     now.setMonth( now.getMonth() + 1 );
        //     document.cookie = "name=" + cookievalue;
        //     document.cookie = "expires=" + now.toUTCString() + ";"
        //     // document.write ("Setting Cookies : " + "name=" + cookievalue );
        //     window.console.log('cookie set', document.cookie)
        // }, 
        // readCookie() {
        //     window.console.log('READ COOKIE', document.cookie)
        // }      
    },
    mounted() {
        // this.readCookie()
        window.console.log('CURRENT', this.$router.currentRoute,'COOKIE', document.cookie)
        EventBus.$emit('LOG_OUT')
        this.loginHeight = {height:`${window.innerHeight - 140}px`}
    },
    
}
</script>

<style scoped>
.login-error-message{
    color: red;
    padding: 5px;
}
.login-required{
    font-size: 12px;
    color: red;
    padding: 10px;
} 
.password-link{
    cursor: pointer;
    font-size: 13px;
    color:#11a8e8;
    text-decoration: underline;
} 
.login-form-container{
    border: 1px solid lightgray;
    background-color: white;
    border-top: none;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
} 
.login-input-row {
 padding: 10px;
 position: relative;
} 
.login-input {
    height: 30px;
    padding: 5px;
    width: 250px;
}
.required-entry {
    padding: 5px;
}
.password-selection-container {
    position: absolute;
    right: 30px;
    top: 20px;    
}
.login-title-row {
    width: 100%;
    background-color: #28a4bf;
    height: 47px;
    font-size: 22px;
    line-height: 47px;
    font-weight: 700;
    margin-bottom: 15px;
    vertical-align: middle;
    color: white;
}
.login-form-content {
    width: 400px;
    background-color: #f9fcfe;
    border: 1px solid #d0d0d0;
}

</style>