<template>
    <div class="input-currency-container">
        <input  pattern="[0-9]*" id="currencyInput" class="currency-input" v-model="formattedPaidValue" v-on:blur="setToUSD" v-on:focus="setToNum" />
    </div>
</template>

<script>
export default {
    name: 'InputCurrency',
    data() {
        return {
            formattedPaidValue: '',
            amountEntered: this.amount,
            showResult: true,
        }
    },
    props: [
        'amount'
    ],
    methods: {
        setToUSD() {
            this.amountEntered = Number(this.formattedPaidValue.replace(/[^0-9.-]+/g,""));
            if (isNaN(this.amountEntered)) {
                this.amountEntered = 0
            }
            window.console.log('PID',this.amountEntered)
            this.$emit('amount-entered',this.amountEntered)
            
            this.formattedPaidValue = "$ " + this.amountEntered.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            
        },
        setToNum() {
            window.console.log('this.amountEntered',this.amountEntered)
            this.formattedPaidValue = this.amountEntered == 0 ? '' : this.amountEntered.toString()
        },
        showInput() {
            window.console.log('SHOW INPUT 4')
            this.showResult = false
            setTimeout(() => {
                document.getElementById('currencyInput').focus()
            }, 500);
            
            
        }

    },
    watch: {
        amount() {
            window.console.log('AMOUNT UPDATED', this.amount)
            this.formattedPaidValue = this.amount
        }
    },
    mounted() {
        if (this.amount) {
            this.formattedPaidValue = this.amount
        }
    },
}
</script>

<style scoped>
.input-currency-container{
    display: inline-block;
}
.currency-input{
    height: 18px;
    font-size: 14px;
    width: 80px;
} 
.input-currency-capture{
    height: 23px;
    font-size: 14px;
    width: 80px;
    background-color: white;
    border: 1px solid #a7a1a1;
    line-height: 23px;
    padding-left: 3px;
}  
</style>