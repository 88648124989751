<template>
     <div class="main-input-form-container" ref="main-container">        
        <div class="form-title-row">{{'Business Hours' | translate | allCaps}}</div>
        <div v-for="(location,index) in locations" :key="index">
            <div class="schedule-location">{{location.name}}</div>
            <div class="schedule-row" v-for="(day,index) in location.schedule" :key="'sch-' + index">
                <div v-if="!day.closed">
                    <div class="schedule-day">{{day.name}}</div>
                    <div class="schedule-day" ><span v-if="day.startTime">{{day.startTime.name}}</span></div>
                    <div class="schedule-day" ><span v-if="day.endTime">{{day.endTime.name}}</span></div>
                </div>
            </div>  
        </div>
        
    </div>
</template>

<script>
import store from '../../store/store'

export default {
    
    name: 'BusinessHours',
    data() {
        return{
            store,
            locations: this.$store.getters.allLocations 
        }
    }
}
</script>

<style scoped>
.schedule-day{
    display: inline-block;
    width: 100px;
}
.schedule-location{
    font-weight: 600;
    padding: 5px;
}
</style>