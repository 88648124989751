<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Add Client' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Prefix' | translate}} </label> 
            <Dropdown 
                v-on:item-selected="clientPrefix = $event" 
                v-bind:selected="clientPrefix.name" 
                v-bind:optioncontent="allPrefixes"
                placeholder="Select Prefix">
            </Dropdown>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'First Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="client.firstname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !client.firstname">*{{'You need to enter a' | translate}} {{'First Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Last Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="client.lastname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !client.lastname">*{{'You need to enter a' | translate}} {{'Last Name' | translate}}</div>
        
        <!-- CONTACT INFO -->
        <PhoneNumber v-on:number-updated="client.phone = $event" :required="true"/>
        <div class='missing-selection-message' v-if="showError && !client.phone">*{{'You need to enter a' | translate}} {{'Phone Number' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="client.email"/>
        </div>
        <div class='missing-selection-message' v-if="showError && (!client.email && !emailOverride)">*{{'You need to enter a' | translate}} {{'Email' | translate}}
            <div class="email-override">
                <span class="over-ride"> {{'Temporary Email Override' || translate}}</span>
                <CheckBox 
                    v-bind:checkBoxLabel="''"
                    v-on:item-selected="emailOverride = $event" 
                    :cbmodal="emailOverride"
                    />
            </div>
        </div>
        <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>

        <!-- ADDRESS INFO -->
        <div class="form-input-row">
            <label class="form-input-label">{{'Street' | translate}}:</label> 
            <input class="input-wide"  v-model="client.street"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Suite' | translate}}/{{'Apt' | translate}}/{{'Unit' | translate}}:</label> 
            <input  v-model="client.address2"/>
            
        </div>
        <ZipLookup 
            v-on:zip-found="zipInfo = $event"
        />
        <div class="form-input-row">
            <label class="form-input-label">{{'Pharmacy' | translate}}:</label> 
            <input  v-model="client.pharmacy"/>                
        </div>
        <div class="form-input-row">
            <PhoneNumber 
                v-on:number-updated="client.pharmacyphone = $event" 
                :existingnumber="client.pharmacyphone"
                :phonelabel="'Pharmacy Phone'"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Emergency Contact Name' | translate}}:</label> 
            <input  v-model="client.emergencyname"/>                
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Emergency Contact Relation' | translate}}:</label> 
            <input  v-model="client.emergencyrelation"/>                
        </div>
        <div class="form-input-row">
            <PhoneNumber 
                v-on:number-updated="client.emergencyphone = $event" 
                :existingnumber="client.emergencyphone"
                :phonelabel="'Emergency Phone'"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'How Did You Hear About Us' | translate}}?:</label> 
            <Dropdown 
                v-on:item-selected="clientLearn = $event" 
                v-bind:selected="clientLearn.name" 
                v-bind:optioncontent="learnAboutList"
                placeholder="Hear About">
            </Dropdown>
        </div>
        <div class="form-input-row" v-if="clientLearn.ref">
            <label class="form-input-label">{{clientLearn.ref}}:</label> 
            <input  v-model="client.learnref"/>
        </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Client' | translate}} {{'Successfully Added' | translate}}</div>
        </div> 
        <div class="form-input-row" v-show="showEmailMatch">
            <div class="success-label">{{'Email already exists for' | translate}}: {{client.firstname}}  {{client.lastname}} </div>
        </div>  
        
    </div>
</template>

<script>
import store from '../../store/store'
import ZipLookup from '../utility/ZipLookup'
import PhoneNumber from '../utility/PhoneNumber'
import Dropdown from '../utility/Dropdown'
import Constants from '../../utilities/constants'
import ButtonBar from '../utility/ButtonBar'
import CheckBox from '../utility/CheckBox'
import Globals from '../../utilities/globals'

export default {
    name: 'ClientAdd',
    data() {
        return {
            client: {},
            showError: false,
            showSuccess: false,
            showEmailError: false,
            showEmailMatch: false,
            emailOverride: false,
            store,
            zipInfo: {},
            formattedDate: '',
            clientPrefix: {},
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            allPrefixes: Constants.prefixes,
            clientLearn:{name:'',ref:''},
            learnAboutList: Constants.learnAbout,
        }
    },
    components: {
       ZipLookup,
       PhoneNumber,
       Dropdown,
       ButtonBar,
       CheckBox
    },
    methods: {
        verifyForm() {
            this.showSuccess = false
            this.showError = false         
            if (!this.client.firstname || !this.client.lastname ||  (!this.client.email && !this.emailOverride) || !this.client.phone ) {
                window.console.log('ERROR FOUND')
                if (this.client.email) {
                    this.showEmailError = Globals.validateEmail(this.client.email) ? false : true
                }
                this.showError = true
            } else {
                if (this.client.email) {
                    this.showEmailError = Globals.validateEmail(this.client.email) ? false : true
                }
                if (!this.showEmailError) {
                    this.submitForm()
                }       
            }
        },
        formatDate() {
            let cleaned = ('' + this.formattedDate).replace(/\D/g, '')
            let DOB = cleaned.substr(0,8)

            if(cleaned.length===2) {
                this.formattedDate = cleaned + '/'
            }
            if(cleaned.length==4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}`
            }
            if(cleaned.length>4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}/${cleaned.substr(4,4)}`
            }
            window.console.log('FORMAT DATE', DOB, this.formattedDate)
        },
        submitForm() {
            this.client.learn = this.clientLearn.name
            this.client.type = 'client'
            this.client.prefix = this.clientPrefix.name
            this.client.password = this.$store.getters.user.defaultpassword
            this.client.businessid = this.$store.getters.user.businessid
            this.client.locationid = this.$store.getters.currentLocation.id
            this.client.zip = this.zipInfo.zip
            this.client.city = this.zipInfo.city
            this.client.state = this.zipInfo.state
            this.showEmailMatch = false
            // CHECK EMAIL TO SEE IF ALREADY EXISTS (NO DUPLICATE EMAILS AS USED LOGIN)
            
            this.$store.dispatch('addClient', this.client).then(response => {
                if (response.result==='SUCCESS') {
                    this.showSuccess = true
                    setTimeout(() => {
                        window.console.log('ROUTER BACK')
                        this.client.id = response.data.id
                        this.$store.commit('setNewClient',this.client)
                        this.$router.back()
                    }, Constants.waitTime);
                }
                if (response.result == 'EXISTS') {
                    this.showEmailMatch = true  
                }
            })           
        },                
    },
    mounted() {
        
    },
    watch: {
        zipInfo() {
            window.console.log('ZIP INFO', this.zipInfo)
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.verifyForm()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        clientLearn() {
            window.console.log('Client Learn Change',this.clientLearn)
        }
    }
}
</script>

<style scoped>
.form-checkbox-row{
    text-align: left;
    margin-top: -8px;
} 
.dob-format{
    font-size: 12px;
} 
.email-override{
    color: rgb(76, 0, 255);
    padding: 10px;
} 
.over-ride{
    padding: 5px;
    line-height: 23px;
}
</style>