<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{pageTitle | translate | allCaps}}</div>
        <div class="form-input-row search-ahead-row">
            <div  class="form-input-label">{{'Client' | translate}}:<span class="required-entry">*</span></div>
            <SearchAhead 
                v-bind:searchtype="clientSearch.type" 
                v-bind:searchcall="clientSearch.dbcall" 
                v-bind:resultfields="clientSearch.resultfields"
                v-on:item-selected="selectedClient = $event" 
                :searchTerm="selectedClientName"
                :clearterm="true" >
            </SearchAhead>
            <SelectionSwitch  
                :switches="['FIRST','LAST']"
                v-on:item-selected="selectedClientSearchType = $event"
                :selectedSwitch= "'LAST'"
                >
            </SelectionSwitch>
            <div class="button-main icon-row-version" @click="addClient">+</div>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !selectedClient.firstname">*{{'You need to select a' | translate}} {{'Client' | translate}}</div>

         <ItemInfoTable 
            v-if="promotionItems.length > 0"
            v-bind:infotableItems="promotionItems[0].items"
            v-on:table-total-updated="promotionTotalsData = $event"
            v-on:quantity-updated="quantityUpdated = $event"
        />
        <div class="form-input-row">
            <label class="form-input-label">{{'Value' | translate}}:</label> 
            <div class="form-input-value">{{promotionValue | toUSD}}</div>
        </div>
        
        
        <div class="form-input-row">
            <label class="form-input-label">{{'Savings' | translate}}:</label> 
            <div class="form-input-value" v-if="promotionValue > 0">
                {{(promotionValue - promotionCost) | toUSD}} ({{1- (promotionCost/promotionValue) | toPercent}})
            </div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Cost' | translate }}:</label> 
            <div class="form-input-value">{{promotionCost | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Sales Tax' | translate}} ({{'Services' | translate}})</label>
            <div class="form-content-tax-item">{{salesTaxService}}%</div>
            <div class="form-content-tax-item">{{salesTaxServiceAmt | toUSD}}</div>
        </div >
        <div class="form-input-row">
            <label class="form-input-label" >{{'Sales Tax' | translate}} ({{'Products' | translate}})</label>
            <div class="form-content-tax-item">{{salesTaxProducts}}%</div>
            <div class="form-content-tax-item">{{salesTaxProductsAmt | toUSD}}</div>
        </div >
        <div class="form-input-row">
            <label class="form-input-label">{{'Total Amount Due' | translate }}:</label> 
            <div class="form-input-value"><b>{{promotionTotal | toUSD}}</b></div>
        </div>


        <div class="form-input-row">
            <label class="form-input-label" >{{'Amount Paid' | translate}}:<span class="required-entry">*</span></label>
            <input id="paid" v-model="formattedPaidValue" v-on:blur="setToUSD" v-on:focus="setToNum" v-if="!isPaid"/>
            <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.amountpaid | toUSD}}</div>
            <div class="paid-full" v-if="paidInFull">
                <i class="fas fa-check-square"></i>
            </div>
        </div>
        <div class='missing-selection-message' v-show="showErrors && formattedPaidValue ==='$0.00'">*{{'You need to enter a' | translate}} {{'Payment Amount' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label" >{{'Payment Type' | translate}}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="paymentType = $event" 
                v-bind:selected="paymentType.name" 
                v-bind:optioncontent="paymentTypes"
                placeholder="Select Payment Type"/>
                <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.paytype}}</div>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !paymentType.name">*{{'You need to select a' | translate}} {{'Payment Type' | translate}}</div>
        
        <div class="form-input-row" v-if="paymentType.name === 'Check'">
            <label class="form-input-label" >{{'Check Number' | translate }}</label>
            <input v-model="checknumber" />
        </div>

        <div class="search-item-container">
            <div class="form-search-row"> 
                <div class="search-ahead-label">
                   <b> {{'Add' | translate | allCaps }} </b>
                </div>
                <div class="search-ahead-item">
                    <SearchAhead 
                        v-show="showProductSearch"
                        v-bind:searchtype="addSearch.type" 
                        v-bind:searchcall="addSearch.dbcall" 
                        v-bind:resultfields="addSearch.resultfields"
                        v-on:item-selected="selectedProduct = $event"
                        :clearterm="true" >
                    </SearchAhead>
                </div>
                <div class="search-selection-item">
                    <SelectionSwitch  
                        :switches="['PRODUCTS','SERVICES','CREDIT','PROMOTIONS']"
                        v-on:item-selected="selectedSearchType = $event"
                        :selectedSwitch= "'PRODUCTS'"
                        >
                    </SelectionSwitch>
                </div>
            </div>
        </div>
        <div>
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" >
            </ButtonBar>
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Invoice Successfully Submitted' | translate}}</div>
            </div>  
        </div>
        
        
    </div>
</template>

<script>
import SearchAhead from '../utility/SearchAhead'
import SelectionSwitch from '../utility/SelectionSwitch'
import Dropdown from '../utility/Dropdown'
import store from '../../store/store.js'
import Constants from '../../utilities/constants'
import ItemInfoTable from '../utility/ItemInfoTable'
import ButtonBar from '../utility/ButtonBar'
import Globals from '../../utilities/globals'

export default {
    name: 'InvoicePromotion',
    data() {
        return {
            promotion: {quantity:1},
            selectedSearchType: {},
            selectedClientSearchType: 'LAST',
            selectedClientName: '',
            selectedClient: {},
            selectedPromotion: {},
            allPromotions: [],
            store,
            pageTitle: 'Purchase',
            showErrors: false,
            showSuccess: false,
            paymentType: '',
            paymentTypes: Constants.paymentTypes,
            formattedPaidValue: '$0.00',
            isPaid: false,
            invoiceInfo: {},
            paidInFull: false,
            datePaid: '',
            amountPaid: 0,
            promotionItems: [],
            promotionTotalsData: {},
            quantityUpdated: '',
            promotionTotal: 0,
            promotionValue: '',
            promotionCost: '',
            checknumber: '',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            productSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            serviceSearch: {
                dbcall: 'getServiceSearch',
                type: 'Services',
                resultfields: ['name']
            },
            promotionSearch: {
                dbcall: 'getPromotionSearch',
                type: 'Promotions',
                resultfields: ['name']
            },
            creditSearch: {
                dbcall: 'getCreditSearch',
                type: 'Credit',
                resultfields: ['name']
            },
            addSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            showProductSearch: true,
            selectedProduct: {},
            salesTaxProducts: this.$store.getters.currentLocation.taxproducts,
            salesTaxProductsAmt: 0,
            salesTaxService: this.$store.getters.currentLocation.taxservices,
            salesTaxServiceAmt: 0, 
            appliedCreditList: []
            
        }
    },
    components: {
        SearchAhead,
        SelectionSwitch,
        Dropdown,
        ItemInfoTable,
        ButtonBar,
    },
    methods: {
        addClient() {
            this.$router.push('AddClient')
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                window.console.log('GOT PROMOS', response)
                this.allPromotions = response;
            })
        },
        setToUSD() {
            this.amountPaid = Number(this.formattedPaidValue.replace(/[^0-9.-]+/g,""));
            if (isNaN(this.amountPaid)) {
                this.amountPaid = ''
            }
            window.console.log('PID',this.amountPaid,this.selectedPromotion.price)
            if(Number(this.amountPaid) === Number(this.promotionTotal)) {
                this.paidInFull = true
            } else {
                this.paidInFull = false
            }
            this.formattedPaidValue = "$ " + this.amountPaid.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            
        },
        setToNum() {
            
            this.formattedPaidValue = this.amountPaid.toString()
        },
        checkInvoice() {
            if (!this.paymentType.name || this.formattedPaidValue ==='$0.00'  || !this.selectedClient.firstname) {
                window.console.log('SHOW ERRORS')
                this.showErrors = true;
            } else {
                this.saveInvoice()
            }
            
        },        
        saveInvoice() {
            this.promotionItems.map(promo => {
                promo.items.map(item => {
                    window.console.log('EACH P ITEM', item)
                    item.quantity = Number(item.quantity) * Number(this.promotion.quantity)
                })
            })
            const status = this.paidInFull ? 'paid-in-full' : 'paid'
            const now = new Date()
            const data = {
                client: this.selectedClient.firstname + ' ' + this.selectedClient.lastname,
                clientid: this.selectedClient.id,
                type: 'promotion',
                invoicetotal: Globals.roundPrice(this.invoiceTotal),
                amountpaid: Number(this.amountPaid),
                quantity: Number(this.promotion.quantity),
                datepaid: now,
                paytype: this.paymentType.name,
                status: status,
                invoiceitems: JSON.stringify(this.promotionItems[0].items),
                bookingid: 0,
                staff: 'Test User',
                staffid: 0,
                bookingdate: now,
                checknumber: this.checknumber,
                taxservice: Math.round(this.salesTaxServiceAmt * 100)/100,
                taxproduct: Math.round(this.salesTaxProductsAmt * 100)/100,
                productservicetotal: Globals.roundPrice(this.promotionCost),
                appliedcredititems: []
            }
            window.console.log('PROMO ITEMS', this.promotionItems)
            window.console.log('SUB', data, this.promotionItems)
            this.$store.dispatch('addInvoice', data).then(response => {
                window.console.log('RESUTS ADD INVOICE', response)
                if (response.result == 'SUCCESS'){
                    this.showSuccess = true             
                    this.invoiceInfo = data
                    this.invoiceInfo.id = response.id
                    this.$router.push(`InvoiceId?id=${response.id}`)
                }
            })
            window.console.log('SAVE INVOICE',data)
        },
        gotoPrevious() {
            this.$router.push('Schedule')
        },
        adjustInvoice() {
            window.console.log('ADJUST INVOICE')
        },
        addProduct() {
            window.console.log('ADD PRODUCT START', this.selectedProduct, this.promotionItems)
            if (this.selectedProduct.category == 'promotion') {
                this.selectedProduct.items.forEach(item => {
                    item.dateEntered = Globals.formatDateYMDdash(new Date())
                    item.businessid = this.$store.getters.user.businessid
                })

                if (this.promotionItems.length) {
                    this.selectedProduct.items.forEach(proItem => {
                        this.promotionItems[0].items.push(proItem)
                    })
                    
                } else {
                    this.promotionItems.push(this.selectedProduct)
                }
                
            } else {
                this.selectedProduct.discount = 0;
                this.selectedProduct.quantity = 1;
                this.selectedProduct.dateEntered = Globals.formatDateYMDdash(new Date())
                this.selectedProduct.businessid = this.$store.getters.user.businessid
                const newPromoItem = {items: [this.selectedProduct]}
                if (this.promotionItems.length) {
                    this.promotionItems[0].items.push(this.selectedProduct)
                } else {
                    this.promotionItems.push(newPromoItem)
                }
            }
        },
        calcTax() {
            this.salesTaxServiceAmt = 0
            this.salesTaxProductsAmt = 0
            this.promotionItems[0].items.forEach(item => {
                if (item.category == 'service') {
                    this.salesTaxServiceAmt += Math.round(Number(item.saleprice) * Number(item.quantityused)  * Number(this.salesTaxService))/100
                }
                if (item.category == 'product') {
                    window.console.log('CALC TAX PRODUCT', item.total, item)
                    this.salesTaxProductsAmt += Math.round(Number(item.saleprice) * Number(item.quantityused) * Number(this.salesTaxProducts))/100
                }
            })
            this.promotionTotal = this.promotionCost + this.salesTaxProductsAmt + this.salesTaxServiceAmt
        },
        checkRoute() {
            if (this.$route.name == 'InvoicePurchase') {
                    window.console.log('MATCH PURCHASE')
                this.pageTitle = "Purchase"
            }
            if (this.$route.name == 'InvoicePromotion') {
                    window.console.log('MATCH PROMO')
                this.pageTitle = "Purchase Promotion Package"
            }
        }
        
    },
    mounted() {
        this.getPromotions()
        this.checkRoute()
    },
    watch: {
        selectedClient() {
            this.selectedClientName = `${this.selectedClient.firstname} ${this.selectedClient.lastname}`
        },
        selectedPromotion() {
            this.promotionItems.push(this.selectedPromotion)
            this.promotionValue = Globals.roundPrice(Number(this.selectedPromotion.retailtotal))
            this.promotionCost = Globals.roundPrice(Number(this.selectedPromotion.price))
        },
        promotionTotalsData() {
            this.promotionValue = Globals.roundPrice(this.promotionTotalsData.retail)
            this.promotionCost = Globals.roundPrice(this.promotionTotalsData.total)
            this.calcTax()
        },
        quantityUpdated() {
            this.calcTax()
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.checkInvoice()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        selectedSearchType() {
            window.console.log('SELECTED SEARCH CHANGED', this.selectedSearchType)
            if (this.selectedSearchType === 'PRODUCTS') {
                this.addSearch = this.productSearch
            }
            if (this.selectedSearchType === 'SERVICES') {
                this.addSearch = this.serviceSearch
            }
            if (this.selectedSearchType === 'PROMOTIONS') {
                this.addSearch = this.promotionSearch
            }
            if (this.selectedSearchType === 'CREDIT') {
                this.addSearch = this.creditSearch
            }
        },
        selectedClientSearchType() {
            if (this.selectedClientSearchType === 'FIRST') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedClientSearchType === 'LAST') {
                this.clientSearch = this.lastNameSearch
            }
            
        },
        selectedProduct() {
            this.addProduct()
        }
    }
    
}
</script>

<style scoped>
.form-input-value{
    display:inline-block;
    position: relative;
    top: 2px;
} 
.search-item-container{
    border-top: 1px solid #d3cdcd;
    border-bottom: 1px solid #d3cdcd;
    margin-bottom: 10px;
    padding: 5px 0 9px;
}
.search-ahead-label{
    display: inline-block;
    position: relative;
    top: 3px;
}
.search-ahead-item{
    display: inline-block;
    position: relative;
    top: -2px;
}
.search-selection-item{
    display: inline-block;
    position: relative;
    top: 2px;
    padding-left: 5px;
} 
.form-content-tax-item{
    display: inline-block;
    padding: 0 10px;
} 
</style>