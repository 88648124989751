<template>
<div>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ formTitle | translate | allCaps}}</div>
        
        <div class="form-input-row">
            <div class="form-input-label">{{'Product Name' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="product.name"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Product Code' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="product.code"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Company' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="product.company"/>
            </div>
        </div>
        
        <div class="form-input-row">
            <div class="form-input-label">{{'Cost' | translate}}: </div>
            <InputCurrency
                v-on:amount-entered="product.cost = $event"
                :amount="productCost"
             ></InputCurrency>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Retail Price' | translate}}: </div>
            <InputCurrency
                v-on:amount-entered="product.retailprice = $event"
                :amount="productRetailPrice"
             ></InputCurrency>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'In Stock Quantity' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input small-input"  v-model="product.inventorytotal"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Alert Quantity' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input small-input"  v-model="product.alertnumber"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Distributor' | translate}}: </div>
                <SearchAhead 
                    v-bind:searchcall="'getDistributorsSearch'"
                    v-bind:searchtype="'Distributor'"  
                    v-bind:resultfields="['name']"
                    v-on:item-selected="selectedDistributor = $event" 
                    :searchTerm="selectedDistributorName" />
            
        </div>
        
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />
        <div class="form-input-row" v-show="showSuccess">
            <div class="form-success-msg">{{'Product' | translate}} {{'Successfully Added' | translate}}</div>
        </div>

        <div class="service-existing-container">
            <div class="service-title">{{'Existing Products' | translate}}</div> 
            <div class="service-type-row" v-for="(type,index) in allProducts" :key="index">
                {{type.name}}
                <div class="control-group-item color-blue" 
                    @click='modifyProduct(type)'>
                    <i class="fas fa-pen"></i>
                </div>
            </div>
        </div>
        </div>  
    </div>
</template>

<script>
import store from '../../store/store.js'
import ButtonBar from '../utility/ButtonBar'
import InputCurrency from '../utility/InputCurrency'
import SearchAhead from '../utility/SearchAhead'

export default {
    name: 'ProductAdd',
    data: function() {
        return {
            product: {},
            store,
            showSuccess: false,
            successMessage: 'Product was successfully Added!',
            selectedDistributor: {},
            selectedDistributorName: '',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            allTypes: [],
            allProducts: this.$store.getters.allProducts,
            productCost: '',
            productRetailPrice: '',
            existingNumber: '',
            formTitle: 'Add Product'
        }
    },
    components: {
        ButtonBar,
        InputCurrency,
        SearchAhead,
    },
    methods: {
        getAllDistributors() {
            window.console.log('GET ALL DIST')
            this.$store.dispatch('getDistributors').then(response => {
                this.allDistributors = response
                window.console.log('ALL DIST GET', this.allDistributors, response)
            })
        },
        setProductValues() {
            this.product.date = new Date();
            this.product.businessid = this.$store.getters.user.businessid
            this.product.distributor = this.selectedDistributor.name
            this.product.distributorid = this.selectedDistributor.id
        },
        clearProductValues() {
            this.product = {};
            this.productCost = Number('')
            this.productRetailPrice = Number('')
            this.existingNumber = ''            
        },
        submitProduct() {
            this.setProductValues()
            window.console.log('ADD', this.product)
            this.$store.dispatch('addProduct',this.product).then(response => {
                window.console.log('GOT ADD RETURN', response)
                if (response.result === 'SUCCESS'){
                    window.console.log('UPDATE SUCCESS')
                    this.getAllProducts()
                    this.showSuccess = true;                    
                    this.clearProductValues()
                }
            })
        },
        updateProduct() {
            this.setProductValues()
            this.$store.dispatch('updateProduct',this.product).then(response => {
                window.console.log('GOT ADD RETURN', response)
                if (response.result === 'SUCCESS'){
                    window.console.log('UPDATE SUCCESS')
                    this.getAllProducts()
                    this.showSuccess = true;
                    this.formTitle = 'Add Product'
                    this.buttonList = ['Submit','Cancel']
                    this.clearProductValues()
                }
            })
        },
        getAllProducts() {
            window.console.log('GET ALL PRODUCTS')
            this.$store.dispatch('getProducts').then(response => {
                this.allProducts = response
                window.console.log('ALL PROD GET', this.allProducts, response)
            })
        },
        modifyProduct(product) {
            this.product = product
            this.productCost = Number(product.cost)
            this.productRetailPrice = Number(product.retailprice)
            this.selectedDistributorName = product.distributor
            this.formTitle = 'Update Product'
            this.buttonList = ['Update','Cancel']
            window.console.log('MODIFY PRODUCT', product)
        },

    },
    mounted() {
        this.getAllProducts()
        this.getAllDistributors()
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.submitProduct()
            }
            if (this.buttonClicked.name === 'Update') {
                this.updateProduct()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    },
}
</script>

<style scoped>

</style>
