<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div v-if="showContent">
            <div class="form-input-row">
                <div class="form-input-label">{{'Distributor' | translate}}: </div>
                <div class="form-content-item">
                    {{item.distributor}}
                </div>
            </div>
            <OrderInfoTable 
                    v-if="invoiceProducts.length > 0"
                    v-bind:infotableItems="invoiceProducts"
                    v-bind:isPaid="isPaid"
                    v-on:table-total-updated="invoiceTotalsData = $event"
                />
            <div class="form-input-row">
                <div class="form-input-label">{{'PO Number' | translate}}: </div>
                <div class="form-content-item">
                    {{item.id}}  
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Date' | translate}}: </div>
                <div class="form-content-item">
                    {{item.date | shortDate}}
                </div>
            </div>
            <div class="service-existing-container order-container">
                <div class="service-title order-title">{{'Order Items' | translate}}</div> 
                <div class="service-type-row" v-for="(item,index) in item.items" :key="'oi-' +index">
                    <div class="table-item table-narrow-item">{{item.id }}</div>
                    <div class="table-item">{{item.company}}</div>
                    <div class="table-item table-wide-item">{{item.name}}</div>
                    <div class="table-item">{{item.code}}</div>
                    <div class="table-item table-narrow-item">{{item.cost | toUSD}}</div>
                    <div class="table-item table-narrow-item">{{item.quantity}}</div>
                    <div class="table-item ">{{item.total | toUSD}}</div>
                </div>
            </div> 
            <div class="form-input-row">
                <div class="form-input-label">{{'Tax' | translate}}: </div>
                <div class="form-content-item">
                    {{item.tax | toUSD}}
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Shipping' | translate}}: </div>
                <div class="form-content-item">
                    {{item.shipping | toUSD}}
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Order Total' | translate}}: </div>
                <div class="form-content-item">
                    {{item.total | toUSD}}
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Amount Paid' | translate}}: </div>
                <div class="form-content-item" v-if="!item.paid">
                    <InputCurrency
                        v-on:amount-entered="itemPaidAmount = $event"
                        :amount="item.paidamount"
                    ></InputCurrency>
                </div>
                <div class="form-content-item" v-if="item.paid">
                    {{item.paidamount | toUSD}}
                </div>
            </div>
            <div class="form-input-row" v-if="item.paid">
                <div class="form-input-label">{{'Date Paid' | translate}}: </div>
                <div class="form-content-item" >
                    {{item.paiddate | shortDate}}
                </div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Received' | translate}}: </div>
                <div class="checkbox-container">
                    <div class="checkbox-item">
                        <CheckBox 
                            v-bind:checkBoxLabel="''"
                            :cbmodel="item.received"
                            :cbref="'received'"                        
                            v-on:item-selected="itemReceived = $event" >
                        </CheckBox>
                    </div>               
                </div>
            </div>

            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" />
            <div class="form-input-row" v-show="showSuccess">
                <div class="form-success-msg">{{'Order' | translate}} {{'Successfully Updated' | translate}}</div>
            </div>
        </div>
        
<!-- v-bind:labelStyle="'label-small'" -->
        <div class="service-existing-container">
            <div class="service-title">{{'Existing Orders' | translate}}</div> 
            <div class="service-type-row" v-for="(order,index) in allOrderItems" :key="index">
                <div class="table-item table-narrow-item">{{order.orderid }}</div>
                <div class="table-item">{{order.distributor}}</div>
                <div class="table-item">{{order.company}}</div>
                <div class="table-item table-wide-item">{{order.name}}</div>
                <div class="table-item table-narrow-item">{{order.cost | toUSD}}</div>
                <div class="table-item table-narrow-item">{{order.quantity}}</div>
                <div class="table-item table-narrow-item">{{order.total | toUSD}}</div>
                <div class="control-group-item color-blue" 
                    @click='selectedOrder(order.orderid)'>
                    <i class="fas fa-pen"></i>
                </div>
            </div>
        </div> 
        
    </div>
</template>

<script>
import store from '../../store/store'
import InputCurrency from '../utility/InputCurrency'
import CheckBox from '../utility/CheckBox'
import ButtonBar from '../utility/ButtonBar'

export default {
    name: 'Orders',
    data() {
        return {
            pageTitle: 'Orders',
            allOrderItems: [],
            store,
            orderTotal: '',
            invoiceProducts: [],
            invoiceTotalsData: {},
            itemPaidAmount: '',
            itemReceived: {},
            item: {received:false},
            showSuccess: false,
            showContent: false,
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            updateInventory: false,
        }
    },
    components: {
        InputCurrency,
        CheckBox,
        ButtonBar,
    },
    methods: {
        getOrderItems() {
            this.$store.dispatch('getOrderItemsOpen').then(response => {
                window.console.log('Order item response', response)
                this.allOrderItems = response
            })
        },
        selectedOrder(id) {
            this.$store.dispatch('getOrderId',{id:id}).then(response => {
                window.console.log('GET ORDER',response)
                this.item = response
                this.itemTax = response.tax
                this.itemShipping = response.shipping
                this.invoiceTotalsData = response.items
                this.updateOrderItems(id,response.items)
                this.showContent = true

            })
        },
        updateOrderItems(id,items){
            const orderitems = this.allOrderItems.filter(orderitem => orderitem.orderid == id)
            items.forEach(item => {
                orderitems.forEach(orderitem => {
                    if (orderitem.name === item.name) {
                        item.orderitemid = orderitem.id
                    }
                })
                
            })
        },
        submitOrderUpdate() {
            this.item.paidamount = this.item.paidamount ? this.item.paidamount : this.itemPaidAmount
            this.item.received = this.item.received ? this.item.received : false
            if (this.item.paidamount) { 
                this.item.paiddate = this.item.paiddate ? this.item.paiddate : new Date()
                this.item.paid = true
            }
            if (this.item.received) {
                if (!this.item.receiveddate){
                    this.updateInventory = true
                }
                this.item.receiveddate = this.item.receiveddate ? this.itemreceiveddate : new Date()}
           window.console.log('SUBMIT ORDER', this.item)
           this.$store.dispatch('updateOrder',this.item).then( response => {
               window.console.log('UPDATED ORDER', response)
               if (response.result == 'SUCCESS') {
                   this.showSuccess = true
                   if (this.updateInventory) {
                       this.item.items.forEach(item => {
                            this.updateItemInventory(item)
                       })
                   }
               }
           })
        },
        updateItemInventory(item) {
            this.$store.dispatch('updateItemInventory',item).then(response => {
                window.console.log('INVENTORY UPDATED',item, response)
            })
        }
    },
    mounted(){
        this.getOrderItems()
    },
    watch: {
        itemReceived() {
            window.console.log('CHECKBOX', this.itemReceived)
            this.item.received = this.itemReceived
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.submitOrderUpdate()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    }
}
</script>

<style scoped>
.table-item{
    height: 25px;
    border-right: 1px solid black;
    width: 15%;
    display: inline-block;
    font-size: 12px;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} 
.table-item:last-child{
    border: none;
}
.table-narrow-item{
    width: 10%;
} 
.table-wide-item{
    width: 20%;
} 
.service-type-row{
    height: 25px;
    padding: 0;
}
.order-title{
    background-color: #8aa8b3;
} 
.order-container{
    margin-top: 0;
    margin-bottom: 15px;
}
</style>