<template>
    <div class="infotable-container">
        <div class="infotable-items-row-container">
            <div class="infotable-item-row title-row">
                <div class="infotable-content-item item-name item-title-row">{{'Contact' | translate}} {{'Name' | translate}}</div>
                <div class="infotable-content-item item-name  item-title-row">{{'Email' | translate}}</div>
                <div class="infotable-content-item item-name item-title-row">{{'Phone' | translate}}</div>
                <div class="infotable-content-item small-item item-title-row">{{'Ext' | translate}}</div>
                <div class="add-contact-button-container" @click="addContact">
                    <div class="button-main icon-row-version">+</div>
                 </div>
            </div>
        </div>
        <div class="infotable-items-row-container" v-for="(item, index) in infotableItems" 
            v-bind:key="index">
            <div class="infotable-item-row">
                <div class="infotable-content-item item-name ">
                    <input class="contacts-input" v-model="item.name"> 
                </div>
                <div class="infotable-content-item item-name">
                    <input class="contacts-input" v-model="item.email">
                </div>
                <div class="infotable-content-item item-name">
                    <PhoneNumber 
                        class="contacts-input"
                        v-on:number-updated="item.phone = $event"
                        :showlabel="false"
                        :existingnumber="item.phone"
                        :required="false" />
                </div>
                <div class="infotable-content-item small-item">
                    <input class="contacts-input" v-model="item.phoneext">
                </div>
                <div class="add-contact-button-container">
                    <div class="button-main icon-row-version delete-button"><i class="fas fa-trash-alt" @click="removeItem(index)"></i></div>
                    
                </div>
            </div>
        
            <!-- <OrderItem                    
                v-bind:product="item"
                v-bind:ispaid="isPaid"
                >
            </OrderItem> -->
            
        </div>
    </div>
</template>

<script>
import PhoneNumber from '../utility/PhoneNumber'

export default {
    name: 'ContactsInfoTable',
    data() {
        return {
           
        }
    },
    props: [
        'infotableItems',
    ],
    components: {
        PhoneNumber,
    },
    methods: {
        removeItem(index) {
            this.infotableItems.splice(index,1)
            
        },
        addContact() {
            const newContact = {name:'',phone:'',email:'',phoneext:''}
            this.infotableItems = this.infotableItems ? this.infotableItems : []
            window.console.log('add contact', this.infotableItems)
            this.infotableItems.push(newContact)
        }
    },
    watch: {
        
    },
    mounted() {
        this.addContact()
    },
    
}
</script>

<style scoped>
.infotable-container{
    margin-bottom: 15px;
    padding-left: 5%;
}
.infotable-item-row{
    display: inline-block;   
    font-size: 13px;
    width: 90%;
}
.infotable-title-row{
    display: inline-block;
    /* float: left; */
    width: 90%;
    height: 24px;
}
.infotable-item-container{
    width: 100%;
    display: inline-block;
}
/* .infotable-items-row-container{
    width: 100%;
    display: inline-block;
    height: 20px;
} */
.infotable-form-container{
    border: 1px solid #b0b2b3;
    margin: 0px 10%;
    max-width: 800px;
    padding-bottom: 15px;
}
.infotable-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px;
    width: 14%;
    height: 22px;
    margin-top: 4px;
}
.item-name{
    width: 24%;
}
.contacts-input{
    width: 95%;
}
.item-title-row{
    border-right: 1px solid white;
    background-color: #4d91a7;
    color: white;
    font-weight: 600;
    height: 15px;
}
.item-title-row:last-child{
    border-right: 2px solid #4d91a7;
}

.med-input{
    width: 9%;
}
.delete-icon{
    padding: 0 6px;
    margin-top: 1px;
}
.delete-button{
    margin-top: 6px !important;
}
.x-mark{
    margin-top: -2px;
}
.small-item{
    width: 7%;
}
.fa-trash-alt{
    position: relative;
    left: 0;
    top: 0;
    cursor: pointer;
    color: white;
    font-size: 12px;
}
.add-contact-button-container{
    position: relative;
    left: 0;
    top: 0;
    float: right;
    padding-top: 3px;
}



    
</style>