<template>
    <div class="invoice-item-row" >
        <div class="invoice-content-item  align-left border-left product-name">{{product.name}}</div>
        <div class="invoice-content-item">{{product.company}}</div>
        <div class="invoice-content-item med-input">{{product.code}}</div>
        <div class="invoice-content-item med-input">{{product.inventorytotal}}</div>
        <div class="invoice-content-item align-right med-input">{{product.cost | toUSD}}</div>
        <div class="invoice-content-item med-input">
            <input class="invoice-content-input " v-model="itemQuantity" @input="calcTotal" v-if="!ispaid">
            <span v-if="ispaid">{{itemQuantity}}</span>
        </div>
        <div class="invoice-content-item align-right ">{{itemTotal | toUSD}}</div>
    </div>
</template>

<script>
import Filters from '../../utilities/filters'
import EventBus from '../../utilities/eventBus'
import Globals from '../../utilities/globals'

export default {
    name: 'OrderItem',
    data: function() {
        return {
            productDiscount: 0,
            itemTotal: 0,
            itemQuantity: 1
        }
    },
    props: ['product','ispaid'],
    filters: {
        Filters
    },
    methods: {
        
        calcTotal(){
            window.console.log('CALC TOTAL')
            this.product.quantity = Number(this.itemQuantity)
            this.product.total = Globals.roundPrice(this.product.cost * Number(this.product.quantity))
            //(this.product.retailprice - Math.floor(this.product.retailprice * this.productDiscount/100)) * this.product.quantity
            EventBus.$emit('UPDATE_INVOICE_TOTAL', this.product.total)            
            this.itemTotal = this.product.total
            this.$emit('total-updated', this.product.total)
            return this.product.total
        },
        removeItem(){
            window.console.log('REMOVE ITEM', this.product)
            this.$emit('item-remove',this.product)
        }

    },
    computed: {
        
        
        productTotal(){
            return this.calcTotal()
        }
    },
    watch: {
        itemQuantity() {
            window.console.log('Q CHANGED')
        }
    },
    mounted() {
        this.product.quantity = !this.product.quantity ? 1 : this.product.quantity
        this.itemQuantity = this.product.quantity
        this.productDiscount = this.product.discount
        if (this.product.category === 'promotion') {
            // To prevent changing promotion values
            this.ispaid = true
        }
        this.calcTotal()
    },
}
</script>
<style scoped>
/* .invoice-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 3px 5px;
    width: 14%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
    font-size: 14px;
} */
.invoice-content-item:last-child{
    /* border-right: none; */
}
/* .invoice-content-input{
    width: 30px;
    font-size: 13px;
    text-align: center;
    border: none;
    vertical-align: middle;
} */
.product-name{
    width: 20%;
}
/* .invoice-item-row{
    display: inline-block;
    float: left;
    font-size: 13px;
    width: 90%;
    /* height: 24px; */
    /* margin-left: 9px; 
} */
.align-left{
    text-align: left;
}
.align-right{
    text-align: right;
}
.border-right{
    outline-right: 1px solid gray !important;
}
.border-left{
    border-left: 1px solid gray !important;
}
.small-item-input{
    width: 7%;
}
.med-input{
    width: 9%;
}


</style>
