<template>
    <div id="calendar" class="atc-calendar" >
        <div class="calendar-title-row">
            <div class="prev-button" v-on:click="prevMonth">
                <i class="fas fa-caret-left"></i>prev
            </div>
            <div class="calendar-month">{{calMonth}} {{calYear}}</div>
            <div class="next-button" v-on:click="nextMonth">
                next<i class="fas fa-caret-right"></i>
            </div>
        </div>
        <div class="calendar-day-row">
            <div class="row-item label">S</div>
            <div class="row-item label">M</div>
            <div class="row-item label">T</div>
            <div class="row-item label">W</div>
            <div class="row-item label">T</div>
            <div class="row-item label">F</div>
            <div class="row-item label">S</div>
        </div>
        <div class="calendar-day-row" v-for="row in rows" v-bind:key="row">
            <div class="row-item"                
                v-for="item in rowItems" 
                v-bind:key="item" 
                @click="selectDate(item,row)" 
                :class="{'not-current':!isCurrentMonth(item,row),'date-today':isDateToday(item,row),'selected-date':isSelectedDate(item,row)}">
                {{getDate(item,row)}}
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store/store.js'

export default {
  name: 'CalendarMini',
  data: function () {
    return {
      rowItems: [1,2,3,4,5,6,7],
      rows: [0,1,2,3,4],
      longRows: [0,1,2,3,4,5],
      shortRows: [0,1,2,3,4],
      firstDay: 2,
      numDays: 30,
      calYear: new Date().getFullYear(),
      calMonthNum: new Date().getMonth(),
      calMonths: ['January','February','March','April','May','June','July','August','September','October','November','December'],
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      calMonth: 'none',
      calDay: '',
      store,
      dateData:{}
    }
  },
  props: [
      'dateselected',
  ],
  methods: {  
    createCalendar: function () { 
        this.calMonth =   this.calMonths[this.calMonthNum];     
        this.firstDay = (new Date(this.calYear,this.calMonthNum)).getDay();
        this.numDays = 32 - (new Date(this.calYear, this.calMonthNum, 32)).getDate();
        this.rows = this.numDays + this.firstDay > 35 ? this.longRows : this.shortRows;
    },
    getDate: function (item,row) { 
        const prevMonthNum = this.calMonthNum > 0 ? this.calMonthNum - 1: 11;
        const daysPrevMonth =  32 - (new Date(this.calYear, prevMonthNum, 32)).getDate();     
        const dayNumber = item + (row * 7) - this.firstDay;
        let returnNumber = dayNumber > this.numDays ? dayNumber - this.numDays : dayNumber;
        returnNumber = dayNumber < 1 ? daysPrevMonth + dayNumber : returnNumber;
        return returnNumber;
    },
    isCurrentMonth: function (item,row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        if (dayNumber > this.numDays || dayNumber < 1) {
            return false
        }
        return true;
    },
    isDateToday: function (item,row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        if (this.calYear === new Date().getFullYear() && this.calMonthNum === new Date().getMonth() && dayNumber === new Date().getDate()) {
            return true
        }
        return false;
    },
    isSelectedDate: function (item, row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        if (dayNumber === this.dateData.day  && this.isCurrentMonth(item,row)) {
            return true
        }
        return false;
    },
    nextMonth: function () {
        this.calMonthNum ++
        if (this.calMonthNum > 11 ) {
            this.calMonthNum = 0;
            this.calYear++;
        }
        this.calMonth =   this.calMonths[this.calMonthNum];
        this.createCalendar();
    },
    prevMonth: function () {
        this.calMonthNum --
        if (this.calMonthNum < 0 ) {
            this.calMonthNum = 11;
            this.calYear--;
        }
        this.calMonth =   this.calMonths[this.calMonthNum];
        this.createCalendar();
    },
    selectDate: function(item, row) {
        if ( this.isCurrentMonth(item,row) ) {
            this.calDay = this.getDate(item,row)
            let selectedDate =  this.calMonth + ' ' + this.calDay + ', ' + this.calYear;
            selectedDate = this.daysOfWeek[(new Date(selectedDate)).getDay()] + ' ' + selectedDate
            this.dateData = {
                selectedDate: selectedDate,
                day: this.calDay,
                month: this.calMonthNum,
                year: this.calYear
            }
            this.$store.commit('setDate', this.dateData)
            this.$emit('date-selected', this.dateData)
            return this.dateData
        }
    },
    getId: function(item,row) {
        if ( this.isCurrentMonth(item,row)) {
            this.calDay = this.getDate(item,row);
            return  `${this.calYear}/${this.calMonthNum + 1}/${this.calDay}`;
        }
        return ''
    }
      
  },
  computed: {
      
  },
  mounted() {
      this.createCalendar();   
  }
}
</script>

<style scoped>
.atc-calendar{
    border: 1px solid gray;
    border-radius: 0px;
    background-color: white;
    z-index: 3;
    position: absolute;
    width: 233px;
    font-size: 16px;
    right: 8px;
    top: 114px;
}
.row-item {
    display: inline-block;
    width: 13.8%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    font-weight: 600;
    height: 25px;
    cursor: pointer;
}
.row-item:last-child {
    border-right: none;
}
.row-item.label{
    height: 20px;
}
.prev-button{
    display: inline-block;
    width: 26%;
    cursor: pointer;
    font-size: 14px;
    text-align: 'left';
    float: left;
    padding-top: 1px;
}
.next-button{
    display: inline-block;
    width: 26%;
    cursor: pointer;
    font-size: 14px;
    text-align: right;
    float: right;
    padding-top: 1px;
}
.calendar-month{
    display: inline-block;
    font-weight: 600;
    width: 45%;
    font-size: 13px;
}
.calendar-title-row{
    margin: 0;
    padding: 0;
}
.not-current{
    color: grey;
    background-color: #f7f7f7;
    font-weight: 400;
    cursor: default;
}
.date-today{
    background-color: #95f1ff;
}
.selected-date {
    background-color: #e4f4ff;
}
.fas {
    padding: 0 4px;
    font-weight: 900;
    vertical-align: middle; 
}
</style>

