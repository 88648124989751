<template>
    <div class="datepicker-container">
        <div class="datepicker-selector" @click="showCalendar = !showCalendar">
            <div class="datepicker-placeholder" v-if="!selectedDate" v-on:change="showChange"> {{placeholder}}</div>
            <div class="datepicker-date"  >{{selectedDate}}</div>
            <i class="fas fa-caret-right" v-if="showCalendar"></i>
            <i class="fas fa-caret-down" v-if="!showCalendar"></i>
        </div>
        <Calendar v-on:date-selected="selectedDateEvent = $event" v-if="showCalendar" />
    </div>
</template>

<script>
import Calendar from './CalendarMini'
import Filters from '../../utilities/filters.js'

export default {
    name: 'DatePicker',
    data: function () {
        return {
            selectedDate:'',
            showCalendar: false,
            selectedDateEvent: {}
        }
    },
    props: [
        'placeholder', 'mydate'
    ],
    components: {
        Calendar
    },
    methods: {
        showChange() {
            window.console.log('IT CHANGED!')
        }
    },
    watch: {
        selectedDate: function() {
            // window.console.log('DATE SELECTED', this.selectedDate, this.mydate)
            // this.mydate = this.selectedDate
            this.showCalendar = false;
             this.$emit('date-selected', this.selectedDateEvent)
        },
        selectedDateEvent() {
            // window.console.log('SELECTED DATE EVENT', this.selectedDateEvent)
            this.selectedDate = this.selectedDateEvent.selectedDate
        },
        mydate(){
            this.selectedDateEvent = this.mydate
        }
    },
    filters: {
        Filters
    }
}

</script>

<style scoped>
.datepicker-container{
    width: 235px;
    display: inline-block;
    position:relative;
}
.datepicker-placeholder{
    display: inline-block;
}
.datepicker-date{
    display: inline-block;
}
.fas{
    padding: 3px 3px;
    vertical-align: top;
    float: right;
}
.datepicker-selector{
    cursor: pointer;
    border: 1px solid lightgray;
    font-size: 14px;
    padding: 2px 5px;
}
</style>