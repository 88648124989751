import Constants from './constants'
import English from './translate/English.js'
import Spanish from './translate/Spanish.js'
import French from './translate/French.js'
import German from './translate/German.js'
import Italian from './translate/Italian.js'
import Vietnamese from './translate/Vietnamese.js'
import CryptoJS from 'crypto-js'



export default {
    selectedDate: '',
    nodeURL: 'https://serve.simplifysoftware.org/',
    // nodeURL: 'http://localhost:443/',
    imageURL: 'https://serve.simplifysoftware.org/uploads/',
    businessType: '',
    language: 'English',
    key: 'ABC',
    sortList(list,key) {
        list.sort(function(a, b) {
            if (a[key] < b[key]) {
                return -1
            } else {
                return 1
            }
        })
    },
    setDate: function(date) {
        window.console.log('SET DATE', date, this.selectedDate)
    },
    setLanguage(lang) {
        window.console.log('GLOBAL SET LANG TO',lang)
        this.language = lang
    },
    getSelectedDate(day, month, year) {
        window.console.log(month, day, year)
        let selectedDate = Constants.months[month] + ' ' + day + ' ' + year
        return Constants.daysOfWeek[(new Date(selectedDate)).getDay()] + ', ' + selectedDate
    },
    getAMPM(startTime) {
        let timeArray = startTime.split(':')
        return timeArray[0] < 13 && timeArray[0] > 7 ? ' AM' : ' PM'
    },
    formatDBDate(day, month, year) {
        return `${year}-${this.to2Digits(month+1)}-${this.to2Digits(day)}`
    },
    formatDBDateCal(calData) {
        return `${calData.year}-${this.to2Digits(calData.month + 1)}-${this.to2Digits(calData.day)}`
    },
    formatShortDate(date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${String(date.getFullYear()).substr(-2)}`
    },
    formatDBDateLong(date) {
        return `${String(date.getFullYear())}/${this.to2Digits(date.getMonth() + 1)}/${this.to2Digits(date.getDate())}/`
    },
    formatMonthDay(date) {
        return `${date.getMonth() + 1}/${date.getDate()}`
    },
    formatDateYMD(date) {
        return`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    formatDateYMDdash(date) {
        window.console.log('DASH DATE',date)
        return`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    formatSelectedDate(date) {
        return `${Constants.daysOfWeek[(new Date(date)).getDay()]} ${Constants.months[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`
    },
    to2Digits(number) {
        return String(number).length === 1 ? '0' + String(number) : String(number)
    },
    toWhole(number) {
        return Number.floor(number)
    },
    toPercent(value) {
        const numValue =Number(value)
        const percent = Number.floor(numValue)
        window.console.log('TO PERCENT',numValue,'%',percent)
        return percent + '%'
    },
    toUSD(value) {
        if (value) {
            return '$' + Number.parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return '$0.00'  
    },
    formatPhone(phone) {
        if(phone.length < 4) {
            return phone
        }
        if(phone.length > 3 && phone.length < 7) {
            return `${phone.substr(0,3)}-${phone.substr(3,3)}`
        }
        if (phone.length > 6) {
            return `${phone.substr(0,3)}-${phone.substr(3,3)}-${phone.substr(6,4)}`
        }
    },
    validateEmail(email) {
        // const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
        email = email.replace('-','')
        const pattern = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
        if (email.match(pattern)) {
            return true
        }
        return false
    },
    neverUse() {
        window.console.log(Spanish)
    },
    whiteListNav(routeName) {
        const routes = ['PasswordReset','Login','PasswordReset','BusinessSignup','AddBusiness','TermsAgree','Welcome']
        let containsRoute = false
        if (routeName) {
            routes.forEach(route => {
                if (routeName.includes(route)) {
                    containsRoute = true
                }
            })
        }        
        return containsRoute
    },
    encryptData(item) {
        window.console.log('ENC',this.key)
        return CryptoJS.AES.encrypt(item, this.key).toString()
    },        
    decryptData(item) {
        window.console.log('KEY',this.key)
        const bytes  = CryptoJS.AES.decrypt(item, this.key);
        return bytes.toString(CryptoJS.enc.Utf8);
    },
    formulateNumber(data) {
        window.console.log('FORMULATE', data)
        if (data) {
            let resultNum = 0
            const forumateString = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            data.split('').forEach(letter => {
                resultNum += forumateString.indexOf(letter)
            })
            return resultNum
        }
        return ''
    },
    formulateKey(num,cid) {
        const mainnum = num * num * num * num * num
        const formulateString ='abcdeVWXYZ'
        const numbercode = mainnum.toString()+cid.toString()
        let key = ''
        numbercode.split('').forEach(item => {
            key += formulateString.charAt(Number(item))
        })
        return key
    },
    getKey(name,cid) {
        return this.formulateKey(this.formulateNumber(name),cid)
    },
    setHeight() {
        return {height: `${window.innerHeight}px`} 
    },
    translate(value) {
        
        if (!value) {
            return ''
        }
        let term = value.toUpperCase().replace(/ /g, "_").replace(/\//g,'_').replace(/-/g,'_')

        if (term.substr(0,12) === 'INFO_CONTENT') {
            const termArray = term.split('.')
            const infoContent = English['INFO_CONTENT']
            return infoContent[termArray[1]]
        }

        if (term.substr(0,15) === 'MESSAGE_CONTENT') {
            const termArray = term.split('.')
            const infoContent = English['MESSAGE_CONTENT']
            return infoContent[termArray[1]]
        }

        if (term.indexOf('ROOM') > -1) {
            window.console.log('FOUND ROOM WORD GLOBAL', term,term.indexOf('ROOM'),this.businessType)
            if (this.businessType === 'nail' || this.businessType === 'hair')
            
            term = term.replace('ROOM','STATION')
        }

        // if (!this.language[term]) {
        //     window.console.log('** NEED TO ADD TERM---', term)
        //     return value
        // }  else {
        //     return this.language[term]
        // }  

        if (this.language === 'English') {
            if (!English[term]) {
                window.console.log('** NEED TO ADD TERM---', term)
                return value
            }  else {
                return English[term]
            }                     
        } 
        if (this.language === 'Spanish') {
            if (!Spanish[term]) {
                window.console.log('**SPANISH MISSING ----', term)
                return value
            }
            return Spanish[term]
        } 
        if (this.language === 'French') {
            if (!French[term]) {
                window.console.log('**FRENCH MISSING ----', term)
                return value
            }
            return French[term]
        } 
        if (this.language === 'German') {
            if (!German[term]) {
                window.console.log('**GERMAN MISSING ----', term)
                return value
            }
            return German[term]
        } 
        if (this.language === 'Italian') {
            if (!Italian[term]) {
                window.console.log('**Italian MISSING ----', term)
                return value
            }
            return Italian[term]
        } 
        if (this.language === 'Vietnamese') {
            if (!Vietnamese[term]) {
                window.console.log('**Vietnamese MISSING ----', term)
                return value
            }
            return Vietnamese[term]
        } else {
            return term
        }
    },
    setBusinessType(value) {
        window.console.log('SET BUSINESS TYPE', value)
        this.businessType = value
    },
    roundPrice(value) {
        return value.toFixed(2)//Math.round(Number(value) * 100)/100
    }

}