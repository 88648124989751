<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Find Client' | translate | allCaps}}</div>
        <div class="client-find-row">
            <div>
                <label class="client-find-label item-label">{{'Find Client' | translate}}</label>
                <SearchAhead 
                    v-bind:searchtype="clientSearch.type" 
                    v-bind:searchcall="clientSearch.dbcall" 
                    v-bind:resultfields="clientSearch.resultfields"
                    v-on:item-selected="selectedClient = $event" 
                    :searchTerm="selectedClientName"
                    :clearterm="true" >
                </SearchAhead>
                <SelectionSwitch  
                    :switches="['FIRST','LAST']"
                    v-on:item-selected="selectedSearchType = $event"
                    :selectedSwitch= "'LAST'"
                    >
                </SelectionSwitch>
            </div>
        </div>
        <div class="client-find-info-container">
            <client-info-update
                v-if="clientInfo.id" 
                v-bind:clientInfo="clientInfo"
            />
            <ClientInfoPanel 
                v-bind:clientref="clientRef"
                v-if="showClientInfoPanel"
                v-on:show-camera="showCamera = $event" 
                v-on:update-image="imageInfo = $event"
                v-on:preview-image="showImageEdit = true,takePicture = false"
            />
            <div v-if="showMedSurveyButton">
                <div class="button-main" @click="showMedSurvey">{{'Enter Client Medical History' | translate }}</div>
            </div>
    </div>
        <div class="booking-image-edit-background" id="image-background-container"  v-if="showImageEdit"></div>
        <div class="booking-image-edit-container" id="image-editing-container" v-if="showImageEdit">
            <div class="camera-container-title-row">
                <div class="camera-app-title">{{'Picture Taking and Editing' | translate}}</div>
                <div class="close-box" @click="showImageEdit = false">
                    <i class="fas fa-times"></i>
                </div>
            </div>
            <ImagePicture
                :bookinginfo="bookingInfo"
                :imageinfo="imageInfo"
                :clientid="clientRef.clientid"
                :takepicture="takePicture"
                v-on:close-edit="showImageEdit = $event,showCamera = $event"
            />
        </div>
    </div>
</template>

<script>
import SearchAhead from '../utility/SearchAhead'
import ClientInfoPanel from './ClientInfoPanel'
import SelectionSwitch from '../utility/SelectionSwitch'
import ImagePicture from '../utility/ImagePicture'
import ClientInfoUpdate from './ClientInfoUpdate'
import Globals from '../../utilities/globals'

export default {
    name: 'ClientFind',
    data() {
        return{
            selectedClient: {},
            clientRef: {},
            selectedClientName: '',
            selectedSearchType: 'LAST',
            showClientInfoPanel: false,
            showClientContact: false,
            showCamera: {},
            showMedSurveyButton: false,
            imageInfo:{},
            showImageEdit: false,
            takePicture: false,
            clientInfo: {},
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
        }
    },
    components: {
        SearchAhead,
        ClientInfoPanel,
        SelectionSwitch,
        ImagePicture,
        ClientInfoUpdate,
    },
    methods: {
        showMedSurvey() {
            window.open(`/#/MedOnboard?ref=${this.selectedClient.id}`, '_blank');
        },
        getMedHistory() {
            const data = {
                refnum: Globals.getKey(this.clientRef.refname,this.clientRef.clientid)
            }
            
            this.$store.dispatch('getMedRec',data).then(response => {
                if (response.result == 'SUCCESS') {
                   this.showMedSurveyButton = false
                } else {
                   this.showMedSurveyButton = true
                }                
            })
        }
    },
    watch: {
        selectedClient() {
            this.clientInfo = this.selectedClient
            this.showClientContact = true
            this.clientRef = {
                clientid: this.selectedClient.id,
                refname: this.clientInfo.refname,
                bookingid: 0,
                hideCameraButton: true
            }
            window.console.log("SELECTED______",this.selectedClient,this.clientRef)
            window.console.log('CLIENT INFO', this.clientInfo)
            this.showClientInfoPanel = false;
            if (Globals.businessType == 'medspa') {
                this.getMedHistory()
            }           
            setTimeout(() => {
                this.showClientInfoPanel = true;
            },100)
            
        },
        selectedSearchType() {
            window.console.log('TYPE',this.selectedSearchType)
            if (this.selectedSearchType == 'FIRST') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedSearchType == 'LAST') {
                this.clientSearch = this.lastNameSearch
            }
        },
    },
}
</script>
<style scoped>
.item-label{    
    top: 6px;
}
.client-find-row {
    position: absolute;
    width: 100%;
    text-align: center;
}
.client-find-info-container {
    margin-top: 60px;
}
</style>

