<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Profile' | translate | allCaps}}</div>
        <div v-if="!showGeneralInfo">
            <div>
                <div>{{user.prefix}}  {{user.firstname}} {{user.lastname}}</div>
                <div>{{user.displayname}}</div>
                <div>{{user.phone | formatPhone}} | {{user.email}}</div>
                <div>{{user.street}} {{user.address2}}</div>
                <div>{{user.city}}, {{user.state}} {{user.zip}}</div>
            </div>
            <div>
                <span class="profile-label">{{'Title' | translate}}: </span>
                <span class="profile-data">{{user.title}}</span>
            </div>
            <div>
                <span class="profile-label">{{'Bookable' | translate}}: </span>
                <span class="profile-data">{{user.bookable}}</span>
            </div>
        </div>
        <!-- GENERAL INFO -->
        <div v-if="showGeneralInfo">
            <hr>
            <div class="form-input-row">
                <label class="form-input-label">{{'Prefix' | translate}} </label> 
                <Dropdown 
                    v-on:item-selected="userPrefix = $event" 
                    v-bind:selected="user.prefix" 
                    v-bind:optioncontent="allPrefixes"
                    :placeholder="user.prefix">
                </Dropdown>
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'First Name' | translate}}:<span class="required-entry">*</span></label> 
                <input class="input-wide" v-model="user.firstname"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !user.firstname">*{{'You need to enter a' | translate}} {{'First Name' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Last Name' | translate}}:<span class="required-entry">*</span></label> 
                <input class="input-wide" v-model="user.lastname"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Last Name' | translate}}</div>

            <!-- DISPLAY NAME -->
            <div class="form-input-row">
                <label  class="form-input-label">{{'Display Name' | translate}}:<span class="required-entry">*</span> </label>
                <div class="form-input-container">  
                    <input  v-model="user.displayname"/>
                    <InfoButton
                        :infocontent="'INFO_CONTENT.DISPLAY_NAME'"
                        :positiontop="'-16px'"
                        :positionleft="'10px'"
                    />
                </div>
            </div>              
            <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Display Name' | translate}}</div>
            <div class="form-input-row">
                    <label for="type" class="form-input-label">{{'Title' | translate}} </label> 
                    <input id="type" v-model="user.title"/>
                </div>
                <div class="form-bookable-checkbox-row">
                    <label class="form-input-label">{{'Bookable' | translate}}:<span class="required-entry">*</span></label> 
                    <div class="form-input-container"> 
                        
                        <div class="checkbox-item">
                            <CheckBox                     
                                v-bind:cbmodel="user.bookable"
                                v-on:item-selected="user.bookable = $event"
                                />
                        </div>
                        <InfoButton
                            :infocontent="'INFO_CONTENT.BOOKABLE'"
                            :positiontop="'-6px'"
                            :positionleft="'-6px'"
                        />
                    </div>
                </div>
            <!-- CONTACT INFO -->
            <PhoneNumber 
                v-on:number-updated="user.phone = $event" 
                v-bind:existingnumber="user.phone" >
            </PhoneNumber>
            <div class='missing-selection-message' v-if="showError && !user.phone">*{{'You need to enter your' | translate}} {{'Phone Number' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
                <input class="input-wide" v-model="user.email"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !user.email">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>
            <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>

            <!-- ADDRESS INFO -->
            <div class="form-input-row">
                <label class="form-input-label">{{'Street' | translate}}:</label> 
                <input class="input-wide"  v-model="user.street"/>
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Suite' | translate}}/{{'Apt' | translate}}/{{'Unit' | translate}}:</label> 
                <input class="input-wide"  v-model="user.address2"/>
            </div>
            <ZipLookup 
                v-on:zip-found="zipInfo = $event"
                :existingzip ="user.zip"
            />
            <hr>
        </div>
        
        <div class="profile-update-link" @click="showGeneralInfo = !showGeneralInfo">{{'Edit General Info' | toLowerCase}}</div>

        <div>
            <div>
                <span class="profile-label">{{'Payrate' | translate}}: </span>
                <span class="profile-data">${{user.payrate}}/hour</span>
            </div>
            <div>
                <span class="profile-label">{{'Commission Products' | translate}}: </span>
                <span class="profile-data">{{user.commissionproducts}}%</span>
            </div>
            <div>
                <span class="profile-label">{{'Commission Services' | translate}}: </span>
                <span class="profile-data">{{user.commissionservices}}%</span>
            </div>
            
            <div>
                <span class="profile-label">{{'Start Date' | translate}}: </span>
                <span class="profile-data">{{user.startdate | longDate}}</span>
            </div>
        </div>

        <div class="profile-update-link" @click="showPersonalInfo = !showPersonalInfo">{{'Edit Personal Info' | toLowerCase}}</div>
        <div v-if="showPersonalInfo">
            <!-- PERSONAL INFO -->
            <div class="form-input-row">
                <label class="form-input-label">{{'Social Security Number' | translate}}:</label> 
                <input class="input-medium"  v-model="user.ssnumber"/>
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Date of Birth' | translate}} <span class="dob-format">({{'MM' | translate}}/{{'DD' | translate}}/{{'YYYY' | translate}})</span>:</label> 
                <input class="input-medium"  v-model="formattedDate" @input="formatDate"/>
            </div>
        </div>

        <div class="form-input-row" v-if="allCertifications.length">
            <label class="form-input-label">{{'Certifications' | translate}} </label>  {{user.certifications}}  
            <span class="profile-update-link">{{'Edit' | toLowerCase}}</span>         
        </div>
        <!-- CERTIFICATIONS -->
        <div class="form-checkbox-row" v-if="allCertifications.length">
            <label class="form-input-label">{{'Certifications' | translate}}:<span class="required-entry">*</span></label> 
            <div class="form-input-container"> 
                <div class="checkbox-item" v-for="(cert, index) in allCertifications" v-bind:key="'cert' + index">
                    <CheckBox 
                        v-bind:checkBoxLabel="cert.name"
                        :cbmodel="checkCertifications(cert.id)"
                        v-on:item-selected="certifications[cert.id] = $event" 
                        />
                </div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.CERTIFICATIONS'"
                    :positiontop="'-2px'"
                    :positionleft="'10px'"
                />
            </div>
        </div>
        
        <!-- LOCATIONS -->
        <div class="title-row">{{'Schedule' | translate}}:<span class="profile-update-link" @click="showSchedule=!showSchedule">{{'View/Edit' | toLowerCase}}</span> </div> 
        <div class="profile-schedule-container" v-if="!showSchedule">
            <div class="staff-schedule-container" v-for="(schedule,index) in scheduleInfo" :key="index">                    
                <div class="schedule-location">{{schedule.name}}</div> 
                <!-- <div class="schedule-location">Start:{{selectedStaff.startdate}}</div> 
                <div class="schedule-location">End:{{selectedStaff.expiredate}}</div>  -->
                <div class="schedule-row" v-for="(day,index) in schedule.data" :key="index">
                    <div v-if="!day.closed">
                        <div class="schedule-day">{{day.name}}</div>
                        <div class="schedule-day" ><span v-if="day.startTime">{{day.startTime.name}}</span></div>
                        <div class="schedule-day" ><span v-if="day.endTime">{{day.endTime.name}}</span></div>
                    </div>
                </div>   
            </div>
        </div>
        
        <div class="profile-schedule-container"  v-if="showSchedule">
            <div class="form-checkbox-row" v-if="allLocations.length > 1">            
                <div class="locations-container" :class="{'show-schedule':showSchedules} ">
                    <div class="locations-checkbox-item" 
                        v-for="(location, index) in allLocations" 
                        v-bind:key="'cert' + index"
                        >
                        <CheckBox 
                            v-bind:checkBoxLabel="location.name"
                            v-on:item-selected="locations[location.id] = $event,ckSch = new Date()" 
                            v-bind:cbmodel="locations[location.id]"
                            :cbref="location.id"
                            />
                        <div class="radiobutton-container">
                            <RadioButton 
                                :rbLabel="'Primary'"
                                :rbStatus="applyTo[index].status"
                                :rbRef="location.id"
                                v-on:rb-selected="rbWatcher = $event"
                            />
                        </div>
                        <WeekSchedule
                            class="staff-week-schedule"
                            v-if="locations[location.id]"
                            :scheduletitle="'Work Hours'"
                            :closename="'Off'"
                            v-on:schedule-updated="scheduleInfo[location.id] = $event"
                            :existingschedule="location.schedule"
                        />
                    </div>
                    
                </div>
            </div>
            <WeekSchedule
                v-if="allLocations.length === 1"
                :scheduletitle="'Work Hours'"
                :closename="'Off'"
                v-on:schedule-updated="updatedSchedule = $event"
                v-bind:existingschedule="scheduleInfo[0].data"
            />
            <div class="form-input-row" >
                <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="date-selection-container">
                    <input class="input-wide" v-model="updateDate" v-on:focus="startFocus"/>
                    <div class="inline-calendar-picker" v-if="showStartCalendar">
                        <CalendarMini 
                            v-on:date-selected="scheduleStartDate = $event"/>
                    </div>
                </div>
            </div>

        </div>
        <!-- COLOR BLOCKS -->
        <div class="form-bookable-checkbox-row color-row" >
            <label class="form-input-label color-label">{{'Display Color' | translate}}:</label> 
            <div class="form-input-container"> 
                <div class="color-block-container">
                    <ColorBlocks 
                        v-on:color-selected="user.displaycolor = $event"
                        :selectedcolor="user.displaycolor"/>
                </div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.COLOR_BLOCKS'"
                    :positiontop="'-2px'"
                    :positionleft="'0'"
                />
            </div>
        </div>
        <!-- PAY INFO -->
        <hr>        
        
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{successNotice | translate}}</div>
        </div> 
        <div class="form-input-row" v-show="showEmailMatch">
            <div class="success-label">{{'Email already exists for' | translate}}: {{emailMatch.firstname}}  {{emailMatch.lastname}} </div>
        </div> 

        
    </div>
</template>

<script>
import store from '../../store/store'
import ZipLookup from '../utility/ZipLookup'
import CheckBox from '../utility/CheckBox'
import PhoneNumber from '../utility/PhoneNumber'
import Dropdown from '../utility/Dropdown'
import RadioButton from '../utility/RadioButton'
import ButtonBar from '../utility/ButtonBar'
import ColorBlocks from '../utility/ColorBlocks'
import WeekSchedule from '../utility/WeekSchedule'
import Constants from '../../utilities/constants'
import InfoButton from '../utility/InfoButton'
import Globals from '../../utilities/globals'
import CalendarMini from '../utility/CalendarMini'
import EventBus from '../../utilities/eventBus'

export default {
    name: 'StaffProfile',
    data() {
        return {
            user: this.$store.getters.user,
            userPrefix: '',
            userStatus: '',
            staffInfo: {},
            showError: false,
            showSuccess: false,
            showEmailError: false,
            showEmailMatch: false,
            showSchedules: false,
            showGeneralInfo: false,
            showPersonalInfo: false,
            showSchedule: false,
            showStartDateError: false,
            scheduledStartDate: '',
            store,
            allCertifications: [],
            certifications: {},
            zipInfo: {},
            formattedDate: '',
            emailMatch: {},
            allLocations: [],
            locations:{},
            applyTo:[],
            rbWatcher: '',
            pageTitle: 'Add Staff',
            buttonList: ['Update', 'Cancel'],
            buttonClicked: '',
            isUpdate: false,
            successNotice: 'Profile Successfully Updated',
            scheduleInfo: {},
            allPrefixes: Constants.prefixes,
            ckSch: '',
            showWS: {},
            selectedStartDate: '',
            scheduleStartDate: {},
            showStartCalendar: false,
            initialData: {},
            staffSchedule: [],
            updatedSchedule:'',
            updateDate: Globals.formatDateYMD(new Date())
        }
    },
    components: {
       ZipLookup,
       CheckBox,
       PhoneNumber,
       Dropdown,
       RadioButton,
       ButtonBar,
       ColorBlocks,
       WeekSchedule,
       InfoButton,
       CalendarMini,      
       
    },
    methods: {
        verifyForm() {  
            window.console.log('USER LOCATIONS', this.user.locations)
            this.showSuccess = false
            this.showError = false         
            if (!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone ) {
                window.console.log('ERROR FOUND')
                if (this.user.email) {
                    this.showEmailError = !Globals.validateEmail(this.user.email)
                }
                this.showError = true
            } else {
                window.console.log('ERROR NOT FOUND')
                if (this.user.email) {
                    window.console.log('EMAIL ERROR FOUND')
                    this.showEmailError = !Globals.validateEmail(this.user.email)
                }
                if (this.showSchedule) {
                    if (!this.scheduledStartDate) {
                        this.showStartDateError = true
                    }
                }
                if (!this.showEmailError) {
                    window.console.log('NO ERROR SUBMIT')
                    this.submitForm()
                }       
            }
        },
        validateEmail() {
            const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
            if (this.user.email && this.user.email.match(pattern)) {
                return true
            }
            return false
        },
        startFocus(){
            window.console.log('SHOW CAL')
            this.showStartCalendar = true;
        },
        formatDate() {
            let cleaned = ('' + this.formattedDate).replace(/\D/g, '')

            if(cleaned.length===2) {
                this.formattedDate = cleaned + '/'
            }
            if(cleaned.length==4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}`
            }
            if(cleaned.length>4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}/${cleaned.substr(4,4)}`
            }
        },
        setScheduleInfo() {
            this.user.schedule = []
            window.console.log('SET SCHEDULE INFO', this.scheduleInfo)
            Object.keys(this.scheduleInfo).forEach(locSch => {
                window.console.log('EACH SCHUDLE LOCXXXXX', locSch)
                let schedule = {}
                schedule.id = Number(locSch) 
                schedule.name = this.getLocationName(locSch)
                schedule.data = this.scheduleInfo[locSch]
                this.user.schedule.push(schedule)
            })
        },
        getLocationName(id) {
            let locName = ''
            this.allLocations.forEach(loc => {
                if (loc.id == id) {
                    locName = loc.name
                }
            })
            return locName
        },
        submitForm() {
            this.user.type = 'staff'
            this.user.businessid = this.$store.getters.user.businessid
            this.user.zip = this.zipInfo.zip
            this.user.city = this.zipInfo.city
            this.user.state = this.zipInfo.state
            this.user.dob = this.formattedDate ? this.formattedDate : null
            this.user.status = 'active'
            this.user.certifications = []
            this.setUserLocations()
            this.setScheduleInfo()
            // NEED TO ADD THESE
            Object.keys(this.certifications).forEach(item => {                              
                if (this.certifications[item]) {
                    this.user.certifications.push(Number(item))
                }
            })
            this.showEmailMatch = false
            this.emailMatch = {}
            window.console.log('SUBMIT NEW STAFF INFO', this.user)
            // CHECK EMAIL TO SEE IF ALREADY EXISTS (NO DUPLICATE EMAILS AS USED LOGIN)
            if (this.initialData.email !== this.user.email) {
                this.$store.dispatch('checkEmail',{email: this.user.email}).then(response => {
                    // IF NO SAME EMAIL ASSUME NEW USER AND ADD 
                    if (response.result==='NO MATCH') {
                        this.$store.dispatch('addStaff', this.user).then(response => {
                            if (response.result==='SUCCESS') {
                                this.showSuccess = true
                            }
                        })
                    } else {
                        //  CHECK TO SEE IF SAME USER EMAIL ALREADY EXISTS IN STAFF
                        this.emailMatch = response.data[0]                   
                        this.$store.dispatch('checkEmailStaff',{email: this.user.email}).then(response => {
                            // IF SAME EMAIL AND PHONE AND LISTED AS USER BUT NOT STAFF THEN ADD TO STAFF
                            if (this.emailMatch.phone === this.user.phone && response.result==='NO MATCH') {
                                this.user.userid = this.emailMatch.id
                                this.sendDataDB()
                            } else {
                                this.showEmailMatch = true
                            }
                        })
                    }
                })
            } else { this.sendDataDB()}                  
        }, 
        sendDataDB() {
            window.console.log('SEND TO DB', this.user, this.user.schedule,this.initialData)
            EventBus.$emit('SHOW_SPINNEER')
            this.$store.dispatch('updateStaff',this.user).then(response => {
                window.console.log('SUBMIT RESPONSE', response)
                EventBus.$emit('HIDE_SPINNEER')
                if (response.result==='SUCCESS') {
                    this.$store.dispatch('addStaffSchedule',this.scheduleUpdateData()).then(response => {
                        if (response.result === 'SUCCESS') {
                            this.showSuccess = true
                        }
                    })
                }
            })
        } , 
        scheduleUpdateData() {
            let expiredate, startdate
            if (this.scheduleStartDate.year) {
                expiredate = `${this.scheduleStartDate.year + 1}-${this.scheduleStartDate.month + 1}-${this.scheduleStartDate.day}`
                startdate = `${this.scheduleStartDate.year + 1}-${this.scheduleStartDate.month + 1}-${this.scheduleStartDate.day}`
            } else {
                const today = new Date()
                expiredate = `${today.getFullYear() + 1}-${today.getMonth() + 1}-${today.getDate()}`
                startdate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
            }

            const data= {
                expiredate: expiredate,
                locations: this.user.locations,
                schedule:this.scheduleInfo,
                staff: `${this.user.firstname} ${this.user.lastname}`,
                staffid: this.user.id,
                startdate: startdate
            }
            return data
        },     
        getCertifications() {
            this.$store.dispatch('getCertifications',{id:this.$store.getters.user.businessid}).then( () => {
                this.allCertifications = this.$store.getters.allCertifications
            })
        },
        getLocations() {
            this.$store.dispatch('getLocations',{id:this.$store.getters.user.businessid}).then(response => {
                window.console.log('STAFF ALL LOCATIONS',this.$store.getters.user.businessid, response, this.user.primarylocation)
                window.console.log('CURRENT')
                this.allLocations = response.data
                this.allLocations.forEach(location => {
                    window.console.log('LOC EACH')
                    const rbstatus = this.$store.getters.currentLocation.id === location.id? true : false
                    this.applyTo.push({id:location.id,status:rbstatus})
                    this.locations[location.id] = rbstatus
                    if (rbstatus) {
                        this.showSchedules = true
                    }
                })
                if (this.allLocations.length === 1) {                    
                    this.user.locations = [this.allLocations[0].id]
                }
                // DEFAULT TO FIRST LOCATION BEING PRIMARY
                this.user.primarylocation = this.allLocations[0].id
                window.console.log('AFTER GET LOC', this.locations)
            })
        },
        getUserData() {
            this.$store.dispatch('getStaffId').then(response => {
                this.initialData = response
                this.user = response
                this.scheduleInfo = response.schedule
                this.selectedStartDate = Globals.formatSelectedDate(this.user.startdate)
            })
        },
        ckExist() {
            let vm = this
            window.console.log('MOUNTED', vm.user, vm.$store.getters.user)             
                if (vm.$store.getters.user.id) {
                    const allStaff =vm.$store.getters.allStaff
                    window.console.log('ALL STAFF', allStaff)
                    const staffNum = this.getStaffNum(allStaff)
                    Object.keys(allStaff[staffNum]).forEach(item => {
                        vm.user[item] = allStaff[staffNum][item]
                    })
                }                
            // }
        },
        getStaffNum(allStaff) {  
            const userId = this.$store.getters.user.id 
            window.console.log('CK STAFF', allStaff)         
            for (let i = 0;i < allStaff.length; i++) {
                if (allStaff[i].userid === userId) {
                    this.staffInfo = allStaff[i]
                    return i
                }
            }           
        },
        checkCertifications(id) {
            if (this.staffInfo.certifications && this.staffInfo.certifications.indexOf(id) > -1) {
                return true
            }
            return false           
        },
        checkLocations(id) {
            window.console.log('CHECK LOC', id, this.locations)
            let vm = this
            Object.keys(vm.locations).forEach(loc => {
                window.console.log('EACH', id, loc, vm.locations[loc])
                if (vm.locations[loc]) {
                    window.console.log('RETURNING TRUE!!!', loc)
                    return true
                }
            })
            return  false
        },
        updateStaff() {
            this.showSuccess = false           
            this.setUserLocations()

            this.user.dob = this.formattedDate ? this.formattedDate : null
            window.console.log('UPSTAFF', this.user, this.locations)
            this.$store.dispatch('updateStaff',this.user).then(response => {
                if (response.result === 'SUCCESS') {
                    this.staffNotice="Profile Successfully Updated"
                    this.showSuccess = true
                }
            })
            
        },
        setUserLocations() {
            // CHECK IF LOCATION CHECKS ADD OR SUBTRACTED FROM USER LOCATIONS
            const vm = this 
            window.console.log('LOCS', vm.locations)
            vm.user.locations = []
            Object.keys(vm.locations).forEach(loc => {
                if (vm.locations[loc]) {
                    // ADD TO USER LOCATION IF NOT EXIST
                    if ( !vm.user.locations || !vm.user.locations.includes(loc)) {
                        vm.user.locations.push(Number(loc))
                    }
                } 
            })
            window.console.log('LOCS DATA', vm.user.locations)
            this.setLocationData()
        },
        setLocationData() {
            this.user.locationsdata = []
            window.console.log('SET LOC DATA', this.user.locations)
            this.user.locations.forEach(id => {  
                window.console.log('ID', id)          
                this.allLocations.forEach(loc => {
                    window.console.log('DATA', loc.id, id)
                    if (loc.id == id) {
                        const locObj = {
                            id: loc.id,
                            name: loc.name,
                            primary: loc.id == this.user.primarylocation ? true : false,
                        }
                        window.console.log('MATCH', locObj, this.user.primarylocation)
                        this.user.locationsdata.push(locObj)
                    }
                })
            })
            window.console.log('USERS LOCATION DATA', this.user.locationsdata)
        },
        ckShowSch(id) {
            window.console.log('CK SHOW SCH', id)
            if (this.locations[id]) {
                return true
            }
            return false            
        }
    },
    mounted() { 
        this.getUserData()       
        // this.ckExist()
        this.getCertifications()
        this.getLocations()
    },
    computed: {
        
    },  
    watch: {
        rbWatcher() {
            const resultArray = this.rbWatcher.split('~')
            this.applyTo.forEach(item => {
                if (item.id == resultArray[0]) {
                    item.status = resultArray[1]
                    if (resultArray[1]) {
                        this.user.primarylocation = resultArray[0]
                    }
                } else {
                    item.status = false
                }
            })
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Update') {
                    this.verifyForm()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        userPrefix() {
            this.user.prefix = this.userPrefix.name
        },
        ckSch() {
            this.showSchedules = false
            Object.keys(this.locations).forEach(loc => {
                if ( this.locations[loc]) {
                    this.showSchedules = true
                }
            })
        },
        scheduleStartDate() {
            window.console.log('DATE PICKED', this.scheduleStartDate)
            this.selectedStartDate = this.scheduleStartDate.selectedDate
            this.updateDate = Globals.formatDBDate(this.scheduleStartDate.day, this.scheduleStartDate.month,this.scheduleStartDate.year)         
            this.showStartCalendar = false;
            window.console.log('DATE PICKED', this.scheduleStartDate, this.user.startdate)
        }        
    }
}
</script>

<style scoped>
.form-checkbox-row{
    text-align: left;
    margin-top: -8px;
} 
.form-bookable-checkbox-row{
    text-align: left;
    margin-top: -12px;
    margin-bottom: 12px;
}
.color-row{
    margin-bottom: 0;
}
.form-input-label{
    vertical-align: middle;
}
.dob-format{
    font-size: 12px;
} 
.locations-container{
    display: inline-block;
    padding: 6px 7px;
} 
.locations-checkbox-item{

}
.vertical{
    vertical-align: top;
    margin-top: 5px;
}
.radiobutton-container{
    display: inline-block;
    vertical-align: top;
}
.color-label{
    vertical-align: top;
    margin-top: 16px;
}
.color-block-container{
    display: inline-block;
    margin-top: 13px;
}
.show-schedule{
    padding-left: 23%;
}
.title-row{
    padding-bottom: 10px;
    font-weight: 600;
}
.profile-update-link{
    color: #2b7dad;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin: 5px;
}
.profile-data{
    font-weight: 600;
}
.schedule-day{
    display: inline-block;
    width: 100px;
}
.profile-schedule-container{
    margin-bottom: 15px;
}
</style>