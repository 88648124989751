<template>
    <div class="location-main-container">
        <div class="location-title-bar back-color-light-blue" >
            {{'Location' | translate}} {{this.locationIndex + 1}}
            <div class="location-button-container" v-if="this.locationIndex > 0">
                <div class="button-icon delete-icon back-color-red" @click="removeItem()" ><div class="x-mark">x</div></div>
            </div>
        </div>
        <br> 
        <div class="form-input-row">
            <label class="form-input-label">{{'Reference Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="locationInfo.name"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.name">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Reference Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Phone' | translate}}:<span class="required-entry">*</span></label> 
            <input v-model="formattedPhone" @input="formatPhoneNumber"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.phone">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Phone' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
            <input type="email" v-model="locationInfo.email" />
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.email">*{{'You need to enter your' | translate}} {{'Business' | translate}} {{'Email' | translate}}</div>
        <div class='missing-selection-message' v-show="showEmailInvalid">*{{'Invalid Email' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Street' | translate}}:<span class="required-entry">*</span></label> 
            <input v-model="locationInfo.street"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.street">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Street' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Suite' | translate}}/{{'Unit' | translate}}:</label> 
            <input v-model="locationInfo.address2"/>
        </div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Zip' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-narrow" v-model="locationInfo.zip" @input="checkZip"/>
            <!-- <div class="button-main small-icon-version" @click="getZipInfo">
                <i class="fas fa-search"></i>
            </div> -->
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.zip">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Zip Code' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'City' | translate}}, {{'State' | translate}}:</label> 
            {{locationInfo.city}}, {{locationInfo.state}}
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.city">*{{'You need to enter your' | translate}} {{'Business Location' | translate}} {{'Zip Code' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Sales Tax' | translate}}: {{'Services' | translate}} (%):<span class="required-entry">*</span> </label> 
            <input class="input-narrow" v-model="locationInfo.taxservices" />
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.taxservices">{{'You need to enter your' | translate}} {{'Sales Tax' | translate}} {{'For Services' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Sales Tax' | translate}}: {{'Products' | translate}} (%):<span class="required-entry">*</span> </label> 
            <input class="input-narrow" v-model="locationInfo.taxproducts"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !locationInfo.taxproducts">{{'You need to enter your' | translate}} {{'Sales Tax' | translate}} {{'For Products' | translate}}</div>

        <div class="form-input-row" v-if="locationIndex > 0">
            <label for="name" class="form-input-label">{{'Location Hours' | translate}}:</label> 
                <CheckBox
                    v-bind:checkBoxLabel="'Same as Location 1'"
                    v-on:item-selected="locationInfo.businessSchedule = $event,scheduleUpdated= $event" 
                    :cbmodel = "sameSchedule"
                />
        </div>
        
        <WeekSchedule
            v-if="!locationInfo.businessSchedule || locationIndex === 0"
            :scheduletitle="'Business Hours'"
            :existingschedule="defaultSchedule"
            v-on:schedule-updated="locationInfo.schedule = $event"
        />
        

    </div>
</template>

<script>
import WeekSchedule from '../utility/WeekSchedule'
import CheckBox from '../utility/CheckBox'
import Globals from '../../utilities/globals'
import DefaultSchedule from '../../data/defaultSchedule.json'

export default {
    name: 'LocationAdd',
    data() {
        return {
            location: {city:'',state:'', businessSchedule: true },
            showErrors: false,
            showEmailInvalid: false,
            sameSchedule: this.locationIndex > 0 ? true : false,
            formattedPhone: '',
            formattedTaxServices: '',
            defaultSchedule: DefaultSchedule.default
        }
    },
    props: [
        'locationInfo', 'locationIndex', 'locationCheck'
    ],
    components: {
        WeekSchedule,
        CheckBox,
    },
    methods: {
        checkZip() {
            if (this.locationInfo.zip.length > 4) {
                this.getZipInfo()
            }
        },
        getZipInfo() {
            // window.console.log('BEFORE', this.locationInfo)
            const vm = this
            var client = new XMLHttpRequest()
            const url = "http://api.zippopotam.us/us/" + vm.locationInfo.zip.substr(0,5)
            client.open("GET", url, true)
            client.onreadystatechange = function() {
                if(client.readyState == 4) {
                    // window.console.log('GOT RESPONSE', client.responseText)
                    const data = JSON.parse(client.responseText)                    
                    // window.console.log('RESPONSE dETAIL', data.places[0]['place name'], data.places[0]['state abbreviation'] )
                    vm.locationInfo.city = data.places[0]['place name']
                    vm.locationInfo.state = data.places[0]['state abbreviation']                   
                }
            }

            client.send();
        },
        removeItem() {
            window.console.log('REMOVE THIS ITEM', this.locationIndex)
            this.$emit('remove-location', this.locationIndex)

        },
        validateLocation() {
            this.showErrors = false
            this.showEmailInvalid = false
            if (!this.locationInfo.name || !this.locationInfo.plan || !this.locationInfo.type || !this.locationInfo.email || !this.locationInfo.phone) {
                this.showErrors = true;
            } else {
                if (!Globals.validateEmail(this.locationInfo.email)){
                    this.showEmailInvalid = true
                }
            }
            
        },
        formatPhoneNumber() {
            let cleaned = ('' + this.formattedPhone).replace(/\D/g, '')
            this.locationInfo.phone = cleaned.substr(0,10)
            this.formattedPhone = Globals.formatPhone(cleaned)
        },
        formatTaxServices() {
            let cleaned = ('' + this.formattedTaxServices).replace(/\D/g, '')
            this.locationInfo.tax = cleaned
            window.console.log('TAX CLEAN',cleaned)
            this.formattedTaxServices = cleaned + '%'
        }

    },
    mounted() {
        this.locationInfo.businessSchedule = true
    },
    watch: {
        locationCheck() {
            this.locationCheck = false
            this.validateLocation()
            window.console.log('GOT CHECK LOCATION')
        }
    },
}
</script>

<style scoped>
.location-main-container{
    border: 1px solid #6fa3bd;
}
.location-title-bar{
    font-weight: 600;
    font-size: 14px;
    background-color: #f4faff;
    padding: 2px;
    border-bottom: 1px solid #6fa3bd;
    position: relative;
    height: 21px;
    
}
.location-button-container{
    position: absolute;
    top: 2px;
    right: 0;
    display: inline-block;
} 
.button-icon{
    padding: 1px 7px;
    border-radius: 11px;
} 
.x-mark{
    position: relative;
    top: -1px;
} 
</style>