import Vue from 'vue'
import Constants from './constants.js'
// import English from './translate/English.js'
import Globals from './globals'

Vue.filter('toUSD', value => {
    if (value) {
        return '$' + Number.parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return '$0.00'   
})

Vue.filter('formatPhone', value => {
    var cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
})
Vue.filter('toAMPM', value => {
    if (value) {
        const timeArray = value.split(':');
        if (timeArray[0] == 0) { return '' }
        return timeArray[0] > 7 && timeArray[0] < 13 ? 'AM' : 'PM'
    }
})
Vue.filter('shortDate', value => {
    const date = new Date(value)
    return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear().toString().substr(-2)
})

Vue.filter('textDate', value => {
    if (value) {
        const dateItems = value.split('/')
        let selectedDate = Constants.months[dateItems[1] - 1] + ' ' + dateItems[2] + ', ' + dateItems[0]
        return Constants.daysOfWeek[(new Date(selectedDate)).getDay()] + ', ' + selectedDate
    }
})

Vue.filter('allCaps', value => {
    if (value) {
        return value.toUpperCase()
    } else {
        return ''
    }
})

Vue.filter('toLowerCase', value => {
    if (value) {
        return value.toLowerCase()
    } else {
        return ''
    }
})

Vue.filter('toPercent', value => {
    if (value) {
        window.console.log('PERCENT', value)
        return (Number(value) * 100).toFixed(1) + '%'
    } else {
        return ''
    }

})

Vue.filter('wholePercent', value => {
    window.console.log('W PERCENT', value)
    if (value && value > 0) {        
        return Number(value).toFixed(1) + '%'
    } else if (value == 0) {
        return '0%'
    } else {
        return ''
    }

})

Vue.filter('longDate', value => {
    const date = new Date(value)
    let selectedDate = Constants.months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
    return Constants.daysOfWeek[date.getDay()] + ' ' + selectedDate

})

Vue.filter('translate', value => {
    return Globals.translate(value)
    // const term = value.toUpperCase().replace(/ /g, "_")

    // if (term.indexOf('ROOM') > -1) {
    //     window.console.log('FOUND ROOM WORD', term,term.indexOf('ROOM'))
    // }
    
    // if (Constants.Language === 'English') {
        
    //     if (term.substr(0,12) === 'INFO_CONTENT') {
    //         const termArray = term.split('.')
    //         const infoContent = English['INFO_CONTENT']
    //         return infoContent[termArray[1]]
    //     }
    //     if (!English[term]) {
    //         window.console.log('** NEED TO ADD TERM---', term)
    //     }
        
    //     return value
    // } else {
    //     return term
    // }
})