<template>
    <div class="credit-item-container">
        <div class="credit-item-title-row">
            <div class="credit-item">Name</div>
            <div class="credit-item">Id</div>
            <div class="credit-item">Credit</div>
            <div class="credit-item">Balance</div>
        </div>
        <div class="credit-item-row" v-for="(credit,index) in creditData" :key="index">
            <div class="credit-item">{{credit.name}}</div>
            <div class="credit-item item-invoice" @click="$router.push(`InvoiceId?id=${credit.invoiceid}`)">{{credit.invoiceid}}</div>
            <div class="credit-item">{{credit.AMTAPPLIED | toUSD}}</div>
            <div class="credit-item">{{credit.balance}}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CreditItem',
    data() {
        return {

        }
    },
    props: [ 'creditData'],
}
</script>
<style scoped>
.credit-item-row {
    border: 1px solid black;
    border-top:none;
    height: 20px;
    font-size: 12px;
    width: 300px;
} 
.credit-item-title-row {
    background-color:#4a8bce;
    color: white;
    height: 20px;
    font-size: 12px;
    width: 300px;
    border-right: 2px solid #4a8bce;
} 
.credit-item {
    width: 70px;
    border-right: 1px solid gray;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}
.credit-item:last-child{
    border: none;
}
.credit-item-container{
    display: inline-block;
    padding-left: 16px;
    text-align: center;
} 
.item-invoice {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
} 
</style>