export default {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    abrevDaysWeek: ['Sun','Mon','Tues','Wed','Thurs','Fri','Sat','Sun'],
    years: ['2019', '2020', '2021', '2022'],
    officeHours: [{ id: 1, name: 8 }, { id: 2, name: 9 }, { id: 3, name: 10 }, { id: 4, name: 11 }, { id: 5, name: 12 }, { id: 6, name: 1 }, { id: 7, name: 2 }, { id: 8, name: 3 }, { id: 9, name: 4 }, { id: 10, name: 5 }, { id: 11, name: 6 }],
    officeMinutes: [
        { id: 1, name: '00' }, { id: 2, name: '15' }, { id: 3, name: '30' }, { id: 4, name: '45' }
    ],
    sessionHours: [
        { id: 1, name: '0' }, { id: 2, name: 1 }, { id: 3, name: 2 }, { id: 4, name: 3 }, { id: 5, name: 4 }, { id: 6, name: 5 }, { id: 7, name: 6 }, { id: 8, name: 7 }
    ],
    allMonths: [{ id: 1, name: 'January' }, { id: 2, name: 'February' }, { id: 3, name: 'March' }, { id: 4, name: 'April' }, { id: 5, name: 'May' }, { id: 6, name: 'June' }, { id: 7, name: 'July' }, { id: 8, name: 'August' }, { id: 9, name: 'September' }, { id: 10, name: 'October' }, { id: 11, name: 'November' }, { id: 12, name: 'December' }],
    waitTime: 2000,
    learnAbout: [
        {id: 1,name: 'Friend',ref: "Friend's Name" },
        {id: 2, name: 'Online Search'},
        {id: 3, name: 'Social Media',ref:"Social Media Application"},
        {id: 4, name: 'Online Advertisement'},
        {id: 5, name: 'Print Advertisement',ref:"Publication"},
        {id: 6, name: 'Article',ref:"Article Publication"},
        {id: 7, name: 'Event',ref:"Event Name"},
    ],
    dayTime: [
        { id: -3, name: '7:00 AM' },
        { id: -2, name: ':15' },
        { id: -1, name: ':30' },
        { id: 0, name: ':45' },
        { id: 1, name: '8:00 AM' },
        { id: 2, name: ':15' },
        { id: 3, name: ':30' },
        { id: 4, name: ':45' },
        { id: 5, name: '9:00 AM' },
        { id: 6, name: ':15' },
        { id: 7, name: ':30' },
        { id: 8, name: ':45' },
        { id: 9, name: '10:00 AM' },
        { id: 10, name: ':15' },
        { id: 11, name: ':30' },
        { id: 12, name: ':45' },
        { id: 13, name: '11:00 AM' },
        { id: 14, name: ':15' },
        { id: 15, name: ':30' },
        { id: 16, name: ':45' },
        { id: 17, name: '12:00 AM' },
        { id: 18, name: ':15' },
        { id: 19, name: ':30' },
        { id: 20, name: ':45' },
        { id: 21, name: '1:00 PM' },
        { id: 22, name: ':15' },
        { id: 23, name: ':30' },
        { id: 24, name: ':45' },
        { id: 25, name: '2:00 PM' },
        { id: 26, name: ':15' },
        { id: 27, name: ':30' },
        { id: 28, name: ':45' },
        { id: 29, name: '3:00 PM' },
        { id: 30, name: ':15' },
        { id: 31, name: ':30' },
        { id: 32, name: ':45' },
        { id: 33, name: '4:00 PM' },
        { id: 34, name: ':15' },
        { id: 35, name: ':30' },
        { id: 36, name: ':45' },
        { id: 37, name: '5:00 PM' },
        { id: 38, name: ':15' },
        { id: 39, name: ':30' },
        { id: 40, name: ':45' },
        { id: 41, name: '6:00 PM' },
        { id: 42, name: ':15' },
        { id: 43, name: ':30' },
        { id: 44, name: ':45' },

    ],
    conflicts: {
        'ROOM_SERVICE': 'This room is not appropriate for this procedure',
        'STAFF_CERT': 'This provider is not certified to do this procedure',
        'ROOM_DOUBLE': 'This room is not available at this time',
        'STAFF_DOUBLE': 'This provider is already booked or not available at this time',
        'SERVICE_DOUBLE': 'This service is already being performed at this time'
    },
    medicalAreas: [{
            name: 'Skin Background',
            ref: 'skin'
        },
        {
            name: 'Allergies',
            ref: 'allergies'
        },
        {
            name: 'Medications',
            ref: 'medications'
        },
        {
            name: 'Conditions',
            ref: 'conditions'
        },
        {
            name: 'Procedures',
            ref: 'skin'
        },
        {
            name: 'Patient Notes',
            ref: 'patientnotes'
        }
    ],
    defaultBookingLength: {
        sessionMinutes: { id: 3, name: '30' },
        sessionHours: { id: 1, name: '0' }
    },
    businessPlans: [
        {id: 1, name: 'Basic'},
        {id: 1, name: 'Premium'},
        {id: 1, name: 'Pro'}
    ],
    promotionTypes: [
        {id: 1, name: 'Credit Dollars'},
        {id: 2, name: 'Services'},
        {id: 3, name: 'Products'},
        {id: 4, name: 'Services & Products'},
        {id: 5, name: 'Points'}
    ],
    expirationLength: [
        {id: 1, name: '1 month', value: 30},
        {id: 2, name: '2 months', value: 60},
        {id: 3, name: '3 months', value: 90},
        {id: 4, name: '6 months', value: 183},
        {id: 5, name: '1 Year', value: 365},
        {id: 6, name: '5 Year', value: 1825}
    ],
    paymentTypes: [
        { id: 1, name:'Cash' },
        { id: 2, name:'Check' },
        { id: 3, name:'Credit Card' }, 
        { id: 4, name:'Promotion Credit'}              
    ],
    creditCards: [
        { id: 1, name:'American Express' },
        { id: 1, name:'Visa' },
        { id: 1, name:'MasterCard' },
        { id: 1, name:'Discover' },
    ],
    expenseTypes: [
        { id: 1, name:'Meal'},
        { id: 2, name:'Entertainment'},
        { id: 3, name:'Supply'}, 
        { id: 4, name:'Transportation'},
        { id: 5, name:'Shipping'},
        { id: 6, name:'Misc'}
    ],
    prefixes: [
        {id:1,name:'Mr'},
        {id:2,name:'Mrs'},
        {id:3,name:'Ms'},
        {id:4,name:'Dr'}
    ],
    salaryTypes: [
        {id:1,name:'Hourly'},
        {id:2,name:'Weekly'},
        {id:3,name:'Monthly'},
        {id:4,name:'Commission'}
    ],
    supplyTypes: [
        {id:1,name:'Office'},
        {id:2,name:'Operational'}
    ],
    businessTypes: [
        {id:1,name:'Hair Salon',value:'hair'},
        {id:2,name:'Nail Salon',value:'nail'},
        {id:3,name:'Med Spa',value:'medspa'},
        {id:4,name:'Beauty Spa',value:'spa'},
    ],
    permissionTypes: [
        'Setup','Schedule','Staff','Clients','Promotions','Inventory','Reports'
    ],
    roles: [
        {id: 0, name: 'Staff',value: 'staff'},
        {id: 1, name: 'Manager',value: 'manager'},
        {id: 2, name: 'Administrator',value: 'admin'}
    ],
    Language: 'English',
    officeTimes: [
        { id: 1, name: '7:00 AM', label: '7:00 AM', value: 7 },
        { id: 2, name: '7:15 AM', label: ':15', value: 7.25 },
        { id: 3, name: '7:30 AM', label: ':30', value: 7.50 },
        { id: 4, name: '7:45 AM', label: ':45', value: 7.75 },
        { id: 5, name: '8:00 AM', label: '8:00 AM', value: 8 },
        { id: 6, name: '8:15 AM', label: ':15', value: 8.25 },
        { id: 7, name: '8:30 AM', label: ':30', value: 8.5 },
        { id: 8, name: '8:45 AM', label: ':45', value: 8.75 },
        { id: 9, name: '9:00 AM', label: ':9:00 AM', value: 9 },
        { id: 10, name: '9:15 AM', label: ':15', value: 9.25 },
        { id: 11, name: '9:30 AM', label: ':30', value: 9.5 },
        { id: 12, name: '9:45 AM', label: ':45', value: 9.75 },
        { id: 13, name: '10:00 AM', label: ':10:00 AM', value: 10 },
        { id: 14, name: '10:15 AM', label: ':15', value: 10.25 },
        { id: 15, name: '10:30 AM', label: ':30', value: 10.5 },
        { id: 16, name: '10:45 AM', label: ':45', value: 10.75 },
        { id: 17, name: '11:00 AM', label: '11:00 AM', value: 11 },
        { id: 18, name: '11:15 AM', label: ':15', value: 11.25 },
        { id: 19, name: '11:30 AM', label: ':30', value: 11.5 },
        { id: 20, name: '11:45 AM', label: ':45', value: 11.75 },
        { id: 21, name: '12:00 PM', label: '12:00 PM', value: 12 },
        { id: 22, name: '12:15 PM', label: ':15', value: 12.25 },
        { id: 23, name: '12:30 PM', label: ':30', value: 12.5 },
        { id: 24, name: '12:45 PM', label: ':45', value: 12.75 },
        { id: 25, name: '1:00 PM', label: '1:00 PM', value: 13 },
        { id: 26, name: '1:15 PM', label: ':15', value: 13.25 },
        { id: 27, name: '1:30 PM', label: ':30', value: 13.5 },
        { id: 28, name: '1:45 PM', label: ':45', value: 13.75 },
        { id: 29, name: '2:00 PM', label: '2:00 PM', value: 14 },
        { id: 30, name: '2:15 PM', label: ':15', value: 14.25 },
        { id: 31, name: '2:30 PM', label: ':30', value: 14.5 },
        { id: 32, name: '2:45 PM', label: ':45', value: 14.75 },
        { id: 33, name: '3:00 PM', label: '3:00 PM', value: 15 },
        { id: 34, name: '3:15 PM', label: ':15', value: 15.25 },
        { id: 35, name: '3:30 PM', label: ':30', value: 15.5 },
        { id: 36, name: '3:45 PM', label: ':45', value: 15.75 },
        { id: 37, name: '4:00 PM', label: '4:00 PM', value: 16 },
        { id: 38, name: '4:15 PM', label: ':15', value: 16.25 },
        { id: 39, name: '4:30 PM', label: ':30', value: 16.5 },
        { id: 40, name: '4:45 PM', label: ':45', value: 16.75 },
        { id: 41, name: '5:00 PM', label: '5:00 PM', value: 17 },
        { id: 42, name: '5:15 PM', label: ':15', value: 17.25 },
        { id: 43, name: '5:30 PM', label: ':30', value: 17.5 },
        { id: 44, name: '5:45 PM', label: ':45', value: 17.75 },
        { id: 45, name: '6:00 PM', label: '6:00 PM', value: 18 },
        { id: 46, name: '6:15 PM', label: ':15', value: 18.25 },
        { id: 47, name: '6:30 PM', label: ':30', value: 18.5 },
        { id: 48, name: '6:45 PM', label: ':45', value: 18.75 },
        { id: 49, name: '7:00 PM', label: '7:00 PM', value: 19 },
        { id: 50, name: '7:15 PM', label: ':15', value: 19.25 },
        { id: 51, name: '7:30 PM', label: ':30', value: 19.5 },
        { id: 52, name: '7:45 PM', label: ':45', value: 19.75 },
        { id: 53, name: '8:00 PM', label: '8:00 PM', value: 20 },
        { id: 54, name: '8:15 PM', label: ':15', value: 20.25 },
        { id: 55, name: '8:30 PM', label: ':30', value: 20.5 },
        { id: 56, name: '8:45 PM', label: ':45', value: 20.75 },
        { id: 57, name: '9:00 PM', label: '9:00 PM', value: 21 },
        { id: 58, name: '9:15 PM', label: ':15', value: 21.25 },
        { id: 59, name: '9:30 PM', label: ':30', value: 21.5 },
        { id: 60, name: '9:45 PM', label: ':45', value: 21.75 },
        { id: 61, name: '10:00 PM', label: '10:00 PM', value: 22 },
        { id: 63, name: '10:15 PM', label: ':15', value: 22.25 },
        { id: 64, name: '10:30 PM', label: ':30', value: 22.5 },
        { id: 65, name: '10:45 PM', label: ':45', value: 22.75 },
        { id: 66, name: '11:00 PM', label: '11:00 PM', value: 23 },
    ]


}