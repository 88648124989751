<template>
    <div class="modal-container">
        <div class="modal-shading"></div>
        <div class="modal-content-container">
            <div class="modal-title-bar">
                <div class="modal-title">{{title}}</div>
                <div class="close-button" @click="closeModal">X</div>
            </div>
            <div class="modal-content">
                <ClientAdd v-if="displayInfo.type === 'addClient'"/>
                <Calendar v-if="displayInfo.type === 'calendar'"/>
                <Schedule v-if="displayInfo.type === 'schedule'"/>
                <ClientInfo 
                    v-if="displayInfo.type === 'client'"
                    :clientdata="clientData"/>
            </div>
        </div>       
    </div>
</template>

<script>
import ClientAdd from '../client/ClientAdd'
import EventBus from '../../utilities/eventBus.js'
import Calendar from './Calendar'
import Schedule from '../Schedule'
import ClientInfo from '../client/ClientInfo'
import store from '../../store/store.js'

export default {
    name: 'Modal',
    data: function() {
        return {
            title: this.getTitle(),
            clientData: {},
            store,
        }
    },
    props: ['displayInfo'],
    components: {
        ClientAdd,
        Calendar,
        Schedule,
        ClientInfo,
    },
    methods: {
        closeModal(){
            EventBus.$emit('CLOSE_MODAL')
        },
        getTitle(){
            window.console.log('GET TITLE', this.displayInfo)
            if (this.displayInfo.type === 'calendar'){
                return 'Select Date'
            }
            if (this.displayInfo.type === 'schedule'){
                return 'Schedule'
            }
            if (this.displayInfo.type === 'client'){
                return 'Client Info' 
            }
            return ''
        },
        checkInfo() {
            // window.console.log('CHEK INFO', this.displayInfo.id)
            if (this.displayInfo.type === 'client') {
                this.$store.dispatch('getClientId',{id:this.displayInfo.id}).then(response => {
                    // window.console.log('GOT CLIENT INFO', response);
                    this.clientData = response[0];
                })
            }
        }
    },
    mounted() {
        this.checkInfo()
    }
}
</script>

<style scoped>
.modal-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: 3;  
}
.modal-shading{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f3f8fb;
    opacity: .8;
}
.modal-content-container{
    position: relative;
    top: 5%;
    margin: auto;
    width: 90%;
    height: 90%;
    background-color:white;
    border: 1px solid gray
}
.modal-title-bar{
    height: 30px;
    background-color: #4096e0;
    color: white;
}
.modal-title{
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    line-height: 30px;
}
.close-button{
    font-weight: 800;
    font-size: 18px;
    padding: 3px 10px 0 10px;
    cursor: pointer;
    float: right;
    background-color: gray;
    height: 27px;
}
.modal-content{
    height: 93%;
    overflow-y: scroll;
    width: 100%;
}
</style>