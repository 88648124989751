<template>

<div class="main-input-form-container" ref="main-container">
    <calendar v-show="false"></calendar>
    
    <div class="booking-form">
    <div class="form-title-row">{{'Update PTO' | allCaps }}</div>
        <div>
             <div class="form-input-row search-ahead-row">
                <div class="form-input-label top-align" >{{'Selected Date' | translate}}: </div>
                <div class="selected-date" v-if="!showDatePicker">{{getSelectedDate}}</div> 
                
                <DatePicker  
                class="date-picker"
                v-if="showDatePicker"
                v-on:date-selected="updatedDate = $event" 
                v-bind:mydate="bookedInfo.startDate"
                :placeholder = "'Select Date'"
                />
                <div class="booking-schedule-button-container">
                    <div class="button-main schedule-button" v-if="!showDatePicker" @click="showDatePicker = !showDatePicker"><i class="far fa-calendar-alt"></i></div>
                </div>
                             
            </div>
            <div class='missing-selection-message' v-show="showErrors && !dateSelected">*You need to select a Date</div>
            
            
            <div class="form-input-row">
                <div class="form-input-label">{{'Service' | translate}}: </div>
                
                <div class="form-content-item" >{{'Personal Time Off' | translate}}</div>
            </div>
            <div class="form-input-row">
                <div class="form-input-label">{{'Provider' | translate}}: </div>
                <Dropdown 
                    v-on:item-selected="selectedProvider = $event" 
                    v-bind:selected="selectedProvider.name" 
                    v-bind:optioncontent="allStaff"
                    :placeholder="bookingProvider">
                </Dropdown>
            </div>
            <div class='missing-selection-message' v-show="showErrors && !selectedProvider.name">*{{'You need to select a' | translate}} {{'Provider' | translate}}</div>
            
            <div class="form-input-row" >
                <div class="form-input-label"></div>
                <div class="checkbox-item-container" >
                        <CheckBox 
                    v-bind:checkBoxLabel="'All Day Event'"
                    v-on:item-selected="allDayEvent = $event" 
                    />
                </div>
            </div>
            
            <div class="form-input-row" v-if="!allDayEvent">
                <div class="form-input-label">{{'Start Time' | translate}}: </div>
                <Dropdown 
                    v-on:item-selected="selectedHour = $event" 
                    v-bind:selected="selectedHour.name" 
                    v-bind:optioncontent="officeHours"
                    placeholder="Select Hour">
                </Dropdown> : 
                <Dropdown 
                    v-on:item-selected="selectedMinute = $event" 
                    v-bind:selected="selectedMinute.name" 
                    v-bind:optioncontent="officeMinutes"
                    placeholder="Select Minute">
                </Dropdown>
                <div class="am-pm">{{getAMPM}}</div>
            </div>           
            <div class='missing-selection-message' v-show="showErrors && (!selectedHour.name || !selectedMinute.name)">*{{'You need to select a' | translate}} {{'Start Time' | translate}}</div>
            
            <div class="form-input-row" v-if="!allDayEvent">
                <div class="form-input-label">{{'Length' | translate}}: </div>
                <div class="am-pm"> {{'Hrs' | translate}}</div>
                 <Dropdown 
                    v-on:item-selected="selectedSessionHours = $event" 
                    v-bind:selected="selectedSessionHours.name" 
                    v-bind:optioncontent="sessionHours"
                    placeholder="Hours">
                </Dropdown> 
                
                   <div class="am-pm">{{'Min' | translate}}</div>
                    <Dropdown 
                        v-on:item-selected="selectedSessionMinutes = $event" 
                        v-bind:selected="selectedSessionMinutes.name" 
                        v-bind:optioncontent="officeMinutes"
                        placeholder="Minutes">
                    </Dropdown> 

                    
                
            </div>
            <div class='missing-selection-message' v-show="showErrors && (!selectedSessionHours.name || !selectedSessionMinutes.name)">*{{'You need to select a' | translate}} {{'Session Length' | translate}}</div>
            <div class="booking-row-note">
                <div class="form-input-label">{{'Note' | translate}}: </div>
                <textarea class="note-input" v-model="note" />
            </div>

            <div class="conflict-modal" v-if="showConflict">
                <div class="conflict-modal-shade"></div>
                <div class="conflict-modal-content">
                    <div class="conflict-title">{{'Conflicts Found' | translate}}</div>
                    <div 
                        class="conflict-item" 
                        v-for="conflictitem in conflicts"
                        v-bind:key="conflictitem">
                            {{conflictitem | translate}} 
                    </div>
                    <div class="conflict-button">
                        <div class="button-plain" @click="showConflict=false">OK</div>
                    </div>
                </div>
            </div>
            <div class="button-row">
                <div class="row-button-container"> 
                    <ButtonBar 
                        :buttonlist="buttonList"
                        v-on:button-click="buttonClicked = $event" />
                </div>
            </div>
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{bookingSuccess}}</div>
            </div>          
        </div>
    </div>

</div>
</template>

<script>
import EventBus from '../utilities/eventBus'
import Calendar from './utility/Calendar'
import store from '../store/store'
import Dropdown from './utility/Dropdown'
import Constants from '../utilities/constants'
import Globals from '../utilities/globals'
import ButtonBar from './utility/ButtonBar'
import DatePicker from './utility/DatePicker'
import CheckBox from './utility/CheckBox'

export default {
    name: "BookingForm",
    data() {
        return {
            dateData: {},          
            dateSelected: '',
            allServices: [],
            serviceList: [],
            allStaff: [],
            allRooms: [],
            store,
            searches: {},
            clients: [],
            selectedClient: {},
            selectedService: {},
            selectedProvider: {},
            selectedHour: {},
            selectedMinute: {},
            selectedRoom: {},
            selectedSessionHours: {},
            selectedSessionMinutes: {},
            showClientSearch: false,
            officeHours: Constants.officeHours,
            officeMinutes: Constants.officeMinutes,
            sessionHours: Constants.sessionHours,
            showErrors: false,
            showConflict: false,
            showDatePicker: false,           
            showSuccess: false,
            showPTO: false,
            conflicts: [],
            bookedInfo: {},
            bookingSuccess: 'Booking was successfully added!',
            note: '',
            notes: [],
            selectedClientName: '',
            selectedSearchType: 'LAST',
            buttonList: ['Back','Update','Delete'],
            buttonClicked: '',
            bookingTitle: 'Add Booking',
            bookingProvider: 'Select Provider',
            bookingRoom: Globals.translate('Select Room'),
            updatedDate: {},
            allDayEvent: false,
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
        }
    },
    props: ['bookingtype'],
    components: {
        Calendar,
        Dropdown,
        ButtonBar,
        DatePicker,
        CheckBox
    },
    methods: {
        getStaffInfo() {
            if (this.$store.getters.allStaff.length) {                
                this.allStaff = this.$store.getters.allStaff
                this.allStaff.map(item => {item.name = item.displayname})
                this.setupForUpdate()
            } else {
                this.$store.dispatch('getStaff').then( response => {
                    window.console.log('response',response)
                    this.allStaff = response.data
                    this.allStaff.map(item => {item.name = item.displayname})
                    //NEED TO ADD CHECK ON RESPONSE                 
                })
            }
            this.setStartTime()

        },
        setStartTime() {
            // window.console.log('SET START TIME BOOKING INFO', 
            window.console.log('booking info',this.$store.getters.bookingInfo)
            
            let vm = this
            this.bookedInfo = this.$store.getters.bookingInfo;
            this.note = this.bookedInfo.note
            const timeArray = this.bookedInfo.starttime.split(':')
            const timeHr = timeArray[0] > 12 ? timeArray[0] -12 : timeArray[0];
            this.bookingProvider = this.bookedInfo.staff ? this.bookedInfo.staff : this.bookingProvider
            if (typeof this.bookedInfo.staff == 'string') {
                this.selectedProvider.name = this.bookedInfo.staff ? this.bookedInfo.staff : this.selectedProvider
                this.selectedProvider.id = this.selectedProvider.name ? this.bookedInfo.staffid : ''
            }
            this.bookingRoom = this.bookedInfo.room ? this.bookedInfo.room : this.bookingRoom
            vm.officeHours.forEach(item => {
                if (item.name == timeHr) {
                    window.console.log('SEL HR',item)
                    vm.selectedHour = item;
                }
            });
            this.officeMinutes.forEach(item => {
                if (item.name == timeArray[1]) {
                    this.selectedMinute = item;
                }
            });
            const sessionHrs = Math.floor(this.bookedInfo.duration/60)
            this.selectedSessionHours.name = String(sessionHrs)
            window.console.log('SESSION HOURS',sessionHrs)
            this.selectedSessionMinutes.name = String(this.bookedInfo.duration - (sessionHrs * 60))
            window.console.log('SET START TIME', timeArray,this.selectedMinute)
        },
        setUpdateDate() {
            const newDate = {
                day: this.bookedInfo.day,
                month: this.bookedInfo.month,
                year: this.bookedInfo.year,
                selectedDate: Globals.formatSelectedDate(this.bookedInfo.bookingdate)
            }
            window.console.log('SETUP DATE')
            this.$store.commit('setDate', newDate)
        },
        setupForUpdate() {
            this.bookedInfo = this.$store.getters.bookingInfo;
            this.dateSelected = this.dateData.selectedDate;
            this.selectedClientName = this.bookedInfo.client            
            this.bookingTitle = 'Update Booking'
            // this.buttonList = ['Update','Back']
            this.setUpdateDate()
            window.console.log('SETUP BOOKING INFO',this.bookedInfo, this.bookedInfo.client, this.bookedInfo.serviceid,'DATE SELECTED', this.dateSelected, this.dateData,'GETTER', this.$store.getters.selectedDateData.selectedDate, Globals.formatSelectedDate(this.bookedInfo.bookingdate))

                this.allStaff.forEach(item => {
                    if (item.id === this.bookedInfo.staffid) {
                        this.selectedProvider = item;
                    }
                });
                
                const lenHours = this.bookedInfo.duration > 59? Math.floor(this.bookedInfo.duration/60) : 0;
                this.sessionHours.forEach(item => {
                    if (item.name == lenHours) {
                        this.selectedSessionHours = item;
                    }
                });
                const lenMinutes = this.bookedInfo.duration < 60? this.bookedInfo.duration : this.bookedInfo.duration - 60;
                this.officeMinutes.forEach(item => {
                    if (item.name == lenMinutes) {
                        this.selectedSessionMinutes = item;
                    }
                });           
        },
        calcRefTime() {
            let refTime = this.selectedHour.id > 5 ? 12 + Number(this.selectedHour.name) : Number(this.selectedHour.name)
            window.console.log('CALC REF TIME',refTime, this.selectedHour)
            refTime += Number(this.selectedMinute.name)/60
            return refTime
        },
        verifySubmitPTO() {
            if ( !this.dateSelected || !this.selectedProvider.name || !this.allDayEvent || ( !this.selectedHour.name || !this.selectedMinute.name || !this.selectedSessionHours.name || !this.selectedSessionMinutes.name) ){
                window.console.log('SHOW ERRORS')
                this.showErrors = true;
            } else {
                this.submitPTO()
            }
        },
        submitPTO() {  
            const bookingInfo = {
                date: this.dateData.selectedDate,
                day: this.dateData.day,
                month: this.dateData.month,
                year: this.dateData.year,                
                service: 'pto',
                serviceid: 0,
                status: 'booked',
                staff: this.selectedProvider.name,
                staffid: this.selectedProvider.id,    
                id: this.bookedInfo.id? this.bookedInfo.id : -1,
                note: this.note,
                bookingdate: Globals.formatDBDate(this.dateData.day, this.dateData.month,this.dateData.year)
            }
            if (!this.allDayEvent) {
                bookingInfo.starttime  = this.selectedHour.name + ':' + this.selectedMinute.name
                bookingInfo.reftime  = this.calcRefTime()
                bookingInfo.duration  = (Number(this.selectedSessionHours.name) * 60) + Number(this.selectedSessionMinutes.name)
            } else {
                bookingInfo.starttime  = '7:00'
                bookingInfo.reftime  = 7.00
                bookingInfo.duration  = 800
            }
            window.console.log('SUBMIT PTO', bookingInfo)
            
            this.updateBookingInfo(bookingInfo)
        },
        checkBookingSubmit(bookingInfo){           
            const searchDate = {               
                "month": Number(this.dateData.month),
                "day": Number(this.dateData.day),
                "year": Number(this.dateData.year),
            }
            window.console.log('DISPATCH ADD BOOKING', bookingInfo, searchDate)
            this.$store.dispatch('getBookingsDay', searchDate).then(response => {
                window.console.log('GOT BOOKING DAY RETURN', response)
                if (response.length > 0) {
                    if(this.checkBookingConflict(response, bookingInfo)){
                        this.submitBookingInfo(bookingInfo);
                    }
                } else {
                    this.submitBookingInfo(bookingInfo);
                    window.console.log('NOTHING BOOKED NO CONFLICT')
                }
            })
            
            
        },
        updateBookingInfo(bookingInfo) {            
            bookingInfo.date = this.updatedDate.selectedDate ? this.updatedDate.selectedDate : bookingInfo.date
            window.console.log('UPDATE BOOKING', bookingInfo, this.updatedDate)
            this.$store.dispatch('updateBooking',bookingInfo).then(response => {
                    if (response.data.result === 'SUCCESS'){
                    window.console.log('UPDATE SUCCESS')
                    this.bookingSuccess = 'Booking was successfully Updated!'
                    this.showSuccess = true;
                    setTimeout(() => {
                        this.$router.push('Schedule')
                    }, Constants.waitTime);
                }
            })           
        },
        addBooking(bookingInfo) {
            this.$store.dispatch('addBooking',bookingInfo).then(response => {
                if (response.substr(0,7) === 'SUCCESS'){
                    window.console.log('ADDED SUCCESS')
                    
                    this.showSuccess = true;
                    this.clearNewClient()
                    setTimeout(() => {
                        this.$router.push('Schedule')
                    }, Constants.waitTime);
                }
                window.console.log('GOT ADD BOOKING RETURN', response, response.substr(0,7))
            })
        },
        checkBookingConflict(bookingsData, bookingInfo) {
            this.conflicts = [];
            const newStart = this.convertTimeToMinutes (bookingInfo.starttime)
            let conflictsExist = false;
            window.console.log('CHECK CONFLICT', bookingsData,bookingInfo)
            for( let i =0;i<bookingsData.length;i++){
                const bookStart = this.convertTimeToMinutes (bookingsData[i].starttime)
                // check for time conflict with staff, service or room
                const bookedID = this.bookedInfo.id? this.bookedInfo.id : -1
                window.console.log('CHECKING ID CONFLICT',bookingsData, bookingsData[i].id !== bookedID, bookingsData[i].id, bookedID)
                if ((bookingsData[i].id !== bookedID && newStart >= bookStart && newStart < bookStart + Number(bookingsData[i].duration)) || (newStart < bookStart && newStart + Number (bookingInfo.duration) > bookStart && bookingsData[i].id !== bookedID)) {
                    window.console.log('CHECKING ID MATCH',bookingsData, bookingsData[i].id !== bookedID, bookingsData[i].id, bookedID)
                    if (bookingsData[i].staffid === bookingInfo.staffid) {
                        this.conflicts.push(Constants.conflicts.STAFF_DOUBLE);
                        conflictsExist = true;
                        window.console.log('CONFLICT STAFF_DOUBLE', bookingsData[i].id, this.bookedInfo.id)
                    }
                    if (bookingsData[i].serviceid === bookingInfo.serviceid) {
                        window.console.log('CONFLICT SERVICE_DOUBLE', bookingsData[i].serviceid, bookingsData[i].id, this.bookedInfo.id)
                        this.conflicts.push(Constants.conflicts.SERVICE_DOUBLE);
                        conflictsExist = true;
                    }
                    if (bookingsData[i].roomid === bookingInfo.roomid) {
                        window.console.log('CONFLICT ROOM_DOUBLE', bookingsData.roomid, bookingInfo.roomid)
                        this.conflicts.push(Constants.conflicts.ROOM_DOUBLE);
                        conflictsExist = true;
                    }
                }
            }
            if(conflictsExist){ 
                this.showConflict = true;
                return false
            } else {
                return true
            }
            
        },
        convertTimeToMinutes (startTime) {
            const startArray = startTime.split(':');
            if ( Number(startArray[0]) < 8 ) {
                return (Number(startArray[0]) * 60) + 720 + Number(startArray[1]);
            } else {
                return (Number(startArray[0]) * 60) + Number(startArray[1]);
            }
        },
        showCalendar() {
            EventBus.$emit('SHOW_MODAL',{type:'calendar'})
        },
        showSchedule() {
            this.$router.push('Schedule')
        },
        setDateData() {
            this.dateData = this.$store.getters.selectedDateData;
            window.console.log('DATE DATA', this.dateData)
            this.selectedDate = this.$store.getters.selectedDateData;
        },
        cancelBooking() {
            this.$router.go(-1)
        },
        goPrevious() {
            this.$router.go(-1)
        },
        setServiceDuration() {
            const serviceHours = Math.floor(this.selectedService.duration/60)
            const serviceMinutes = serviceHours > 0 ? this.selectedService.duration - (60 * serviceHours) : this.selectedService.duration
            let selectedHour = Constants.sessionHours[0]
            let selectedMinute = Constants.officeMinutes[2]
            Constants.sessionHours.forEach(hour => {
                if (hour.name == serviceHours){
                    selectedHour = hour
                }
            })
            Constants.officeMinutes.forEach(minute => {
                if (Number(minute.name) == serviceMinutes) {
                    selectedMinute = minute
                }
            })
            this.selectedSessionHours = selectedHour
            this.selectedSessionMinutes = selectedMinute
            window.console.log('SELECT DUR',serviceHours, serviceMinutes, selectedHour, selectedMinute)

        },

    },
    mounted() {
        var vm = this;
        this.getStaffInfo()
        EventBus.$on('CAL_DATE_SELECTED', function(dateData){
            vm.dateData = dateData;
            vm.dateSelected = dateData.selectedDate;
            window.console.log('DATE SELECTED', dateData)
        })
    },
    computed: {
        getAMPM() {
            if (this.selectedHour.name) {
                if (this.selectedHour.name > 6 && this.selectedHour.name < 12) {
                    return 'AM'
                } else {
                    return 'PM'
                }    
            }
            return ''
        },
        getSelectedDate: function() {
            this.setDateData();
            window.console.log('GETTER DATE', this.$store.getters.selectedDateData.selectedDate)
            return this.$store.getters.selectedDateData.selectedDate
        },
    },
    watch: {
        selectedClient: function() {
            this.showClientSearch = false;
        },
        selectedService: function() {
            window.console.log('SS CHANGED', this.selectedService)
            if (this.selectedService.name === 'Blocked') {
                this.selectedRoom = {}
                this.selectedClient = {}
            }
            this.setServiceDuration()

        },
        selectedSearchType() {
            window.console.log('SEL SEARCH TYPE CHANGED', this.selectedSearchType)
            if (this.selectedSearchType == 'FIRST') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedSearchType == 'LAST') {
                this.clientSearch = this.lastNameSearch
            }
        },
        buttonClicked() {
            window.console.log('BUTTON CLICKED', this.buttonClicked)
            if (this.buttonClicked.name === 'Update') {
                this.submitPTO()
            }
            if (this.buttonClicked.name === 'Back') {
                this.cancelBooking()
            }
            if (this.buttonClicked.name === 'Delete') {
                this.$store.commit('cancelBooking',this.bookedInfo)
            }
        },
        updatedDate() {
            window.console.log('DATE UPDATED', this.updatedDate)
            this.$store.commit('setDate', this.updatedDate)
            this.getSelectedDate()
        }
    }
}
</script>

<style scoped>
.selected-date{
    font-weight: 600;
    padding: 0;
    display: inline-block;
    min-width: 175px;
    vertical-align: top;
    margin-top: 1px;
}
.client-search-container{
    padding-left: 130px;
}
.booking-item{
    display: inline-block;
}
.booking-row{
    height: 35px;
}
.client-list-item{
    cursor: pointer;
    padding-left: 20px;
}
.client-selected-name{
    display: inline-block;
    min-width: 150px;
    padding-left: 5px;
}
.select-label{
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
    width: 100px;
    text-align: right;
}

.conflict-modal{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.conflict-modal-shade{
    background-color: #474747;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .3;
    top: 0;
    left: 0;
}
.conflict-modal-content{
    background-color: white;
    width: 50%;
    top: 30%;
    position: relative;
    z-index: 1;
    float: left;
    left: 25%;
    padding: 5px;
    border: 2px solid red;
    text-align: center;
}
.conflict-title{
    color: red;
    font-weight: 700;
}
.conflict-item{
    font-weight: 500;

}
.note-input{
    margin: 0 10px 10px 5px;
    width: 190px;
    height: 50px;
    vertical-align: top;
    padding: 5px;
    font-family: 'Raleway';
}
.booking-row-note{
    text-align: left;
}
.top-align{
    vertical-align: top;
}
.date-picker{
    z-index: 6;
}
.booking-schedule-button-container{
    display: inline-block;
    position: relative;
    top: -2px;
}
.schedule-button{
    padding: 4px 7px;
    background-color: #0bb2cc;
    margin-left: 15px;
    margin-top: 0;
}
.am-pm{
    display: inline-block;
    position: relative;
    top: 2px;
    left: 5px;
    padding-right: 5px;
}
.row-button-container{
    display: inline-block;
}
.pto-button{
    float: right;
    margin: 12px;
    cursor: pointer;
}
.checkbox-item-container{
    margin-left: 25px;
    margin-top: 2px;
    display:inline-block;
}

@media screen and (max-width: 650px) {
    div.checkbox-label{
        font-size: 14px;
    }
    .checkbox-item-container{
        margin-left: 10px;
    }
}

</style>