<template>
<div class="dropdown-component">
    <div 
        class="dropdown-selected-container"
        :style="dropstyle" 
        @click.stop="checkSelection">
        {{getSelected}}
        <i class="fas fa-caret-down" @click.stop="checkSelection" v-show="showCaret"></i>
    </div>
    <div class="dropdown-selection-container"  v-show="showSelection">
        <div v-for="(item,index) in optionTypes" v-bind:key="index" @click.stop="checkSelection">
            <div                             
                class="dropdown-category" 
                @click="showOptionList(item)">
                {{item}}
                <div class="dropdown-category-carets">
                    <i class="fas fa-caret-down" v-if="!showOptions"></i>
                    <i class="fas fa-caret-right" v-if="showOptions"></i>
                </div>
            </div>
            <div v-if="showOptionsSubList(item)">
                <div class="dropdown-options" v-for="(option,index) in subOptions" :key="'so-'+index" @click="setSelected(option)" >
                    {{option.name}}
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Globals from '../../utilities/globals.js'

export default {
    name:'DropdownCategories',
    data: function() {
        return {
            showSelection: false,
            showOptions: false,
            activeItem: '',
            showCaret: true,
            optionTypes: [],
            subOptions: [],
            selectedOptionType: ''
        }
    },
    props: ['selected','placeholder','optioncontent', 'dropstyle','caretshow'],
    methods: {
        setSelected(item) {
            this.activeItem = item.name
            document.removeEventListener('click', this.handleOutsideClick)
            this.$emit('item-selected', item)
            this.showCaret = this.caretshow ? true : false   
            this.selectedOptionType = ''        
        },
        showOptionsSubList(option) {
            return this.selectedOptionType == option ? true : false
        },
        showOptionList(option) {
            window.console.log('SHOW OPTION LIST', option, this.selectedOptionType)
            if (option == this.selectedOptionType) {
                this.selectedOptionType = ''
                this.showOptions = false
            } else {
                this.subOptions = []
                this.optioncontent.forEach(item => {
                    if (item.type == option) {
                        this.subOptions.push(item)
                    }
                })
                this.showOptions = true
                this.selectedOptionType = option
            }
        },
        checkSelection() {
            this.setTypes()
            if (this.showSelection && !this.selectedOptionType) {
                this.showSelection = false
                this.activeItem = ''            
            } else {
                this.showSelection = true
                document.addEventListener('click', this.handleOutsideClick)
            }
        },
        removeOutsideClick() {
            window.console.log('REMOVE OUTSIDE CLICK')
            document.removeEventListener('click', this.handleOutsideClick)
        },
        handleOutsideClick() {
            window.console.log('Handle Outside Click', this.showSelection)
            this.removeOutsideClick()
            if (this.showSelection) {
                this.showSelection = false
                
            }
        },
        setTypes() {
            this.optioncontent.forEach(option => {
                if (!this.optionTypes.includes(option.type)) {
                    if (option.type) {
                        this.optionTypes.push(option.type)
                    }                    
                }
            })
        }
    },
    computed: {
        getSelected(){
            if (this.activeItem) {
                return this.activeItem
            } 
            if(this.placeholder.length > 1) {
                return Globals.translate(this.placeholder)
            }
            return ''
        }
    },
    mounted() {
        this.setTypes()
    },
    watch: {
        selected: function() {
            this.activeItem = this.selected;
        }
    }
}
</script>
<style>
.dropdown-component{
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: -3px;
}
.dropdown-selected-container{
    height: 22px;
    border: 1px solid #a7d6ec;
    padding: 0 5px 2px 5px;
    margin-left: 5px;
    min-width: 25px;
    background-color: white;
    font-weight: 500;
    color: #0b8ecc;
    line-height: 24px;
    cursor: pointer;
    font-size: 13px;
}
.dropdown-selection-container{
    position: absolute;
    background-color: white;
    border: 1px solid black;
    min-width: 100px;
    left: 5px;
    top: 26px;
    padding: 0;
    margin: 0;
    /* padding: 0 5px; */
    /* margin-right: 5px; */
    z-index: 5;
    width: 230px;
    font-size: 13px;
    max-height: 300px;
    overflow-y: auto;
}
.dropdown-item{
    border-bottom: 1px solid lightgray;
    padding: 8px 0 8px 5px;
    width: 100%;
    cursor: pointer;
    background-color: white;
    color: gray;
    font-size: 13px;
}
.dropdown-category{
    height: 38px;
    border-bottom: 1px solid white;
    background-color: #4379b5;;
    color: white;
    line-height: 38px;
    padding-left: 5px;
    cursor: pointer;
}
.dropdown-category-carets {
    display: inline-block;
    padding-left: 10px;
}
.dropdown-options {
    line-height: 14px;
    padding: 10px;
    border-bottom: 1px solid green;
    cursor: pointer;
    overflow: hidden;
}
.selection-text {
    cursor: pointer;
}
</style>