<template>
    <div id="calendar" class="atc-calendar">
        <div class="calendar-title-row">
            <div class="prev-next" v-on:click="prevMonth">prev</div>
            <div class="calendar-month">{{calMonth}} {{calYear}}</div>
            <div class="prev-next" v-on:click="nextMonth">next</div>
        </div>
        <div class="calendar-day-row">
            <div class="row-item label">Sun</div>
            <div class="row-item label">Mon</div>
            <div class="row-item label">Tues</div>
            <div class="row-item label">Weds</div>
            <div class="row-item label">Thur</div>
            <div class="row-item label">Fri</div>
            <div class="row-item label">Sat</div>
        </div>
        <div class="calendar-day-row" v-for="row in rows" v-bind:key="row">
            <div class="row-item" v-for="item in rowItems" v-bind:key="item" v-on:click="selectDate(item,row)" :class="{'not-current':!isCurrentMonth(item,row),'date-today':isDateToday(item,row),'selected-date':isSelectedDate(item,row)}">{{getDate(item,row)}}</div>
        </div>
    </div>

</template>

<script>
import EventBus from '../../utilities/eventBus.js'
import store from '../../store/store.js'
import Constants from '../../utilities/constants.js'

export default {
  name: 'Calendar',
  data: function () {
    return {
      rowItems: [1,2,3,4,5,6,7],
      rows: [0,1,2,3,4],
      longRows: [0,1,2,3,4,5],
      shortRows: [0,1,2,3,4],
      firstDay: 2,
      numDays: 30,
      calYear: new Date().getFullYear(),
      calMonthNum: new Date().getMonth(),
      calMonths: ['January','February','March','April','May','June','July','August','September','October','November','December'],
      calMonth: 'none',
      calDay: '',
      store,
    }
  },
  props: [],
  methods: {  
    createCalendar: function () { 
        this.calMonth =   this.calMonths[this.calMonthNum];     
        this.firstDay = (new Date(this.calYear,this.calMonthNum)).getDay();
        this.numDays = 32 - (new Date(this.calYear, this.calMonthNum, 32)).getDate();
        this.rows = this.numDays + this.firstDay > 35 ? this.longRows : this.shortRows;
    },
    getDate: function (item,row) { 
        const prevMonthNum = this.calMonthNum > 0 ? this.calMonthNum - 1: 11;
        const daysPrevMonth =  32 - (new Date(this.calYear, prevMonthNum, 32)).getDate();     
        const dayNumber = item + (row * 7) - this.firstDay;
        let returnNumber = dayNumber > this.numDays ? dayNumber - this.numDays : dayNumber;
        returnNumber = dayNumber < 1 ? daysPrevMonth + dayNumber : returnNumber;
        return returnNumber;
    },
    isCurrentMonth: function (item,row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        if (dayNumber > this.numDays || dayNumber < 1) {
            return false
        }
        return true;
    },
    isDateToday: function (item,row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        if (this.calYear === new Date().getFullYear() && this.calMonthNum === new Date().getMonth() && dayNumber === new Date().getDate()) {
            return true
        }
        return false;
    },
    isSelectedDate: function (item, row) {
        const dayNumber = item + (row * 7) - this.firstDay;
        // window.console.log('IS SEL', dayNumber, this.calDay, this.isCurrentMonth)
        if (this.calDay === dayNumber && this.isCurrentMonth(item,row)){
            return true;
        }
        return false;
    },
    nextMonth: function () {
        this.calMonthNum ++
        if (this.calMonthNum > 11 ) {
            this.calMonthNum = 0;
            this.calYear++;
        }
        this.calMonth =   this.calMonths[this.calMonthNum];
        this.createCalendar();
    },
    prevMonth: function () {
        this.calMonthNum --
        if (this.calMonthNum < 0 ) {
            this.calMonthNum = 11;
            this.calYear--;
        }
        this.calMonth =   this.calMonths[this.calMonthNum];
        this.createCalendar();
    },
    selectDate: function(item, row) {
        if ( this.isCurrentMonth(item,row) ) {
            this.calDay = this.getDate(item,row)
            let selectedDate =  this.calMonth + ' ' + this.calDay + ', ' + this.calYear;
            selectedDate = Constants.daysOfWeek[(new Date(selectedDate)).getDay()] + ' ' + selectedDate
            const dateData = {
                selectedDate: selectedDate,
                day: this.calDay,
                month: this.calMonthNum,
                year: this.calYear
            }
            this.$store.commit('setDate', dateData)
            EventBus.$emit('CAL_DATE_SELECTED', dateData)
            window.console.log('store', this.$store.getters.selectedDateData, (new Date(selectedDate)).getDay());
            EventBus.$emit('CLOSE_MODAL')
        }
    }
      
  },
  computed: {
      
  },
  mounted() {
      this.createCalendar();   
  }
}
</script>

<style scoped>
.atc-calendar{
    border: 1px solid black;
    border-radius: 0px;
    background-color: white;
    font-size: 16px;
}
.row-item {
    display: inline-block;
    width: 14%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    font-weight: 600;
    height: 50px;
    cursor: pointer;
}
.row-item:last-child {
    border-right: none;
}
.row-item.label{
    height: 20px;
}
.prev-next{
    display: inline-block;
    width: 30%;
    cursor: pointer;
}
.calendar-month{
    display: inline-block;
    font-weight: 600;
    width: 38%;
}
.not-current{
    color: grey;
    background-color: #f7f7f7;
    font-weight: 400;
    cursor: default;
}
.date-today{
    background-color: yellow;
}
.selected-date {
    background-color: #e4fff6
}

</style>

