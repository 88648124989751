<template>
    <div class="invoice-item-row" >
        <div class="invoice-content-item product-name align-left border-left">{{product.name}}</div>
        <div class="invoice-content-item align-right">{{product.retailprice | toUSD}}</div>
        <div class="invoice-content-item small-item-input">
            <span v-if="showDiscount && !ispaid" @click="showDiscount = false">{{product.discount | wholePercent}}</span>
            <input v-if="!showDiscount && !ispaid" class="invoice-content-input" v-model="productDiscount" @blur="calcSalePrice" >
            <span v-if="ispaid">{{productDiscount | wholePercent}}</span>
        </div>
        
        <div class="invoice-content-item align-right">
            <input  class="invoice-content-input" v-model="salePrice" @blur="calcSalePrice" v-if="!ispaid">
            <span v-if="ispaid">{{product.saleprice | toUSD}}</span>
            </div>
        <div class="invoice-content-item med-input">
            <input  type="number" class="invoice-content-input " v-model="itemQuantity" @input="calcTotal" @blur="calcTotal" v-if="!ispaid">
            <span v-if="ispaid">{{itemQuantity}}</span>
        </div>
        <div class="invoice-content-item small-item-input">
            <input type="number" class="invoice-content-input" v-model="quantityUsed"  @input="calcTotal" v-if="!ispaid">
            <!-- <div class="invoice-content-input">{{ quantityUsed }}</div> -->
            <!-- <span v-if="ispaid">{{quantityUsed}}</span> -->
        </div>
        <div class="invoice-content-item small-item-input">
            <!-- <input type="number" class="invoice-content-input" v-model="itemCredit" @input="calcTotal" v-if="!ispaid">
            <span v-if="ispaid">{{getCredit}}</span> -->
            <div class="invoice-content-input">{{ this.product.credit }}</div>
        </div>
        <div class="invoice-content-item align-right ">{{itemTotal | toUSD}}</div>
    </div>
</template>

<script>
import Filters from '../../utilities/filters'
import EventBus from '../../utilities/eventBus'
import Globals from '../../utilities/globals'

export default {
    name: 'InvoiceItem',
    data: function() {
        return {
            productDiscount: this.product.discount ? Number(this.product.discount) : 0,
            itemTotal: this.product.retailprice,
            itemQuantity: 1,
            quantityUsed: 1,
            itemCredit:0,
            showDiscount: true,
            salePrice: (Number(this.product.retailprice) -(Number(this.product.retailprice)* Number(this.product.discount))/100).toFixed(2)
        }
    },
    props: ['product','ispaid'],
    filters: {
        Filters
    },
    methods: {
        calcPrice() {            
            this.product.saleprice = Globals.roundPrice(this.product.retailprice -Number(this.product.retailprice * this.productDiscount/100))
            this.product.quantityused = Number(this.quantityUsed)
            this.product.credit = this.product.quantity - Number(this.quantityUsed)
            this.itemCredit = this.product.credit
            // this.product.saleprice = Math.round(1000 * this.product.saleprice)/1000
            window.console.log('CALC PRICE', this.product.saleprice)
            return Globals.toUSD(this.product.saleprice)            
        },
        calcTotal(){
            this.product.saleprice = Globals.roundPrice(this.product.retailprice -Number(this.product.retailprice * this.productDiscount/100))
            this.product.quantity = Number(this.itemQuantity)
            this.product.credit = Number(this.itemQuantity)- Number(this.quantityUsed)
            this.product.quantityused = this.product.quantity - Number(this.quantityUsed)
            this.quantityUsed = this.product.quantityused
            window.console.log('QUANTIY used', this.product.quantityused, this.product.quantity,this.product.credit, Number(this.product.credit))
            this.product.credit = this.product.quantity - this.product.quantityused
            window.console.log('PRODUCT CREDIT',this.product.credit)
            window.console.log('SALE VS TOTAL SP:Q:D:U',this.product.saleprice,this.product.quantity,this.productDiscount)
            this.product.total = Globals.roundPrice(Number(this.product.saleprice) * Number(this.product.quantity))
            window.console.log('PRODUCT TOTAL', this.product.total, Globals.roundPrice(Number(this.product.saleprice),this.product.saleprice ))
            //(this.product.retailprice - Math.floor(this.product.retailprice * this.productDiscount/100)) * this.product.quantity
            EventBus.$emit('UPDATE_INVOICE_TOTAL', this.product.total)  
            window.console.log('ITEM TOTAL', this.product.total)          
            this.itemTotal = this.product.total >= 0 ? this.product.total : this.product.retailprice
            this.$emit('total-updated', this.product.total)
            this.$emit('quantity-updated',new Date())
            return this.product.total
        },
        removeItem(){
            window.console.log('REMOVE ITEM', this.product)
            this.$emit('item-remove',this.product)
        },
        calcSalePrice() {
            this.product.discount = this.productDiscount
            this.product.saleprice = this.product.retailprice - (this.product.retailprice * this.productDiscount/100)
            this.salePrice = this.product.retailprice - (this.product.retailprice * this.productDiscount/100)
            this.salePrice = Number(this.salePrice).toFixed(2)
            window.console.log('CALC PRICE',this.product.discount,this.productDiscount)
            this.calcTotal()
            this.showDiscount = true
        }

    },
    computed: {
        getSelectedClient(){
            return 'Sally Smith'
        },
        getDiscountPrice(){
            return this.calcPrice()
        },
        productPrice(){           
            return this.calcPrice()
        },
        productTotal(){
            return this.calcTotal()
        },
        initialTotal(){
            return 35//this.salePrice * this.itemQuantity
        },
        getCredit() { 
            window.console.log('GET CREDIT',this.itemQuantity,this.quantityUsed, this.product.quantityused)
            return Number(this.itemQuantity) - Number(this.quantityUsed)
        }
    },
    watch: {
        // productDiscount: function() {
        //     this.product.discount = this.productDiscount
        //     this.product.saleprice = this.product.retailprice - (this.product.retailprice * this.productDiscount/100)
        //     this.salePrice = this.product.retailprice - (this.product.retailprice * this.productDiscount/100)
        //     this.calcTotal()
        // },
        salePrice() {
            this.productDiscount = ((this.product.retailprice - this.salePrice)/this.product.retailprice)*100
            // this.productDiscount = this.productDiscount.toFixed(1)
        }
    },
    mounted() {
        window.console.log('PRODUCT MOUNT',this.product,this.product.category)

        this.product.quantity = !this.product.quantity ? 1 : this.product.quantity
        this.quantityUsed = (this.product.category == 'service' || this.product.category == 'product') && this.product.quantity == 1 ? 1 : this.product.quantity - this.product.credit
        this.product.discount = !this.product.discount ? 0 : this.product.discount
        this.itemQuantity = this.product.quantity
        if (this.product.AMTAPPLIED) {
            window.console.log('APPLIED')
            this.ispaid = true
        }
        if (this.product.category === 'promotion' || this.product.category == 'credit') {
            window.console.log('PROMO', this.product)
            this.productDiscount = this.product.discount
            // To prevent changing promotion values
            this.ispaid = true
        }
        window.console.log('PRODUCT SETUP MOUNT',this.product)
        this.calcTotal()
    }
}
</script>
<style scoped>
.invoice-content-item{
    /* display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 3px 5px; */
    width: 10%;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px; */
}
.invoice-content-item:last-child{
    /* border-right: none; */
}
/* .invoice-content-input{
    width: 30px;
    font-size: 13px;
    text-align: center;
    border: none;
    vertical-align: middle;
} */
.product-name{
    width: 26%;
}
/* .invoice-item-row{
    display: inline-block;
    float: left;
    font-size: 13px;
    width: 90%; */
    /* height: 24px; */
    /* margin-left: 9px; */
/* } */
.align-left{
    text-align: left;
}
.align-right{
    text-align: right;
}
.border-right{
    outline-right: 1px solid gray !important;
}
.border-left{
    border-left: 1px solid gray !important;
}
.small-item-input{
    width: 7%;
}
.med-input{
    width: 9%;
}

@media screen and (max-width: 680px) {
    .product-name{
        width: 21%;
    }
    .invoice-item-row{
        width: 97%;
    }
}


</style>
