<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Add Distributor' | translate | allCaps}}</div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Company' | translate}} {{'Name' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="distributor.name"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Street' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="distributor.street"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Office/Suite' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="distributor.address2"/>
            </div>
        </div>
        <div class="form-input-row">
            <ZipLookup 
                v-on:zip-found="zipInfo = $event"
                :existingzip ="existingZip"
            />
        </div>
        <div class="form-input-row">
            <PhoneNumber 
                v-on:number-updated="distributor.phone = $event"
                :phonelabel="'Phone'"
                :existingnumber="existingNumber"
                :required="false" />
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Email' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="distributor.email"/>
            </div>
        </div>

        <ContactsInfoTable
        :infotableItems="distributor.contacts">
        </ContactsInfoTable>
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />
        <div class="form-input-row" v-show="showSuccess">
            <div class="form-success-msg">{{'Distributor' | translate}} {{'Successfully Added' | translate}}</div>
        </div>

        <div class="service-existing-container">
            <div class="service-title">{{'Existing Distributors' | translate}}</div> 
            <div class="service-type-row" v-for="(distributor,index) in allDistributors" :key="index">
                {{distributor.name}}
                <div class="control-group-item color-blue" 
                    @click='modifyDistributor(distributor)'>
                    <i class="fas fa-pen"></i>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import store from '../../store/store.js'
import ButtonBar from '.././utility/ButtonBar'
import PhoneNumber from '.././utility/PhoneNumber'
import ZipLookup from '.././utility/ZipLookup'
import ContactsInfoTable from './ContactsInfoTable'

export default {
    name: 'Distributors',
    data: function() {
        return {
            distributor: {contacts:[]},
            store,
            showSuccess: false,
            successMessage: 'Distributor was successfully Added!',
            selectedType: {name:''},
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            allTypes: [],
            allDistributors: this.$store.getters.allDistributors,
            distributorCost: '',
            distributorRetailPrice: '',
            existingNumber: '',
            zipInfo: {},
            existingZip: ''
        }
    },
    components: {
        ButtonBar,
        PhoneNumber,
        ZipLookup,
        ContactsInfoTable,
    },
    methods: {
        submitDistributor() {
            this.distributor.date = new Date();
            this.distributor.businessid = this.$store.getters.user.businessid
            this.distributor.zip = this.zipInfo.zip
            this.distributor.city = this.zipInfo.city
            this.distributor.state = this.zipInfo.state
            window.console.log('ADD', this.distributor,this.zipInfo)
            this.$store.dispatch('addDistributor',this.distributor).then(response => {
                window.console.log('GOT ADD RETURN', response)
                if (response.result === 'SUCCESS'){
                    window.console.log('UPDATE SUCCESS')
                    this.getAllDistributors()
                    this.showSuccess = true;
                    this.distributor = {};
                    this.distributorCost = ''
                    this.distributorRetailPrice = ''
                    this.existingNumber = ''
                    this.zipInfo = ''
                    this.existingZip = ''
                }
            })
        },
        getAllDistributors() {
            window.console.log('GET ALL DIST')
            this.$store.dispatch('getDistributors').then(response => {
                this.allDistributors = response
                window.console.log('ALL DIST GET', this.allDistributors, response)
            })
        },
        modifyDistributor(distributor) {
            window.console.log('MODIFY PRODUCT', distributor)
            this.distributor = distributor
        },

    },
    mounted() {
        this.getAllDistributors()
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.submitDistributor()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    },
}
</script>

<style scoped>

</style>
