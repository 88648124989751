<template>
    <div class="report-detail-container">
        <div class="report-item-detail-row title-row" >
            <div class="report-table-detail-item"></div>
            <div class="report-table-detail-item ">{{'Id' | translate}}</div>
            <div class="report-table-detail-item large-width">{{'Name' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Quantity' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Total' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Paid' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Credit' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Promotion' | translate}}</div>

        </div>
        <div class="report-item-detail-row" v-for="(item,index) in invoiceItemsStaff" :key="index">
            <div class="report-table-detail-item">{{'Item' | translate}}</div>
            <div class="report-table-detail-item ">{{item.id}}</div>
            <div class="report-table-detail-item large-width">{{item.name}}</div>
            <div class="report-table-detail-item small-width">{{getItemQuantity(item)}}</div>
            <div class="report-table-detail-item small-width">{{getSaleValue(item.name)| toUSD}}</div>
            <div class="report-table-detail-item small-width">{{getSaleValue(item.name)- getCreditValue(item)| toUSD}}</div>
            <div class="report-table-detail-item small-width">{{getCreditValue(item)  | toUSD}}</div>
            <div class="report-table-detail-item small-width">{{getPromoName(item)}}</div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'ReportsInvoiceItemsDetail',
    data() {
        return {

        }
    },
    props: ['invoiceItemsStaff','appliedCreditItems','invoice'],
    methods: {
        getItemQuantity(item) {
            let totalQuantity = 0
           if (item.creditid) {
               window.console.log('APP ITEMs', this.appliedCreditItems)
                this.appliedCreditItems.forEach(credit => {
                    if (credit.appliedto == item.name) {
                        window.console.log('MATCH',credit,credit.appliedto, credit.category)
                        if (credit.category == 'credit' && credit.quantity > 0) {
                            totalQuantity = this.getCreditQuantity(item.name)
                        } else {
                            totalQuantity += credit.quantity
                        }
                        
                        window.console.log('CREDIT QUANTITOY', credit, this.invoice)
                    }
                })
            } else {
                window.console.log('CALC QUANTITY NO CREDIT', item,this.invoice)
                totalQuantity = this.getCreditQuantity(item.name)
            }
            return totalQuantity
        },
        getCreditValue(item) {
            let totalValue = 0
           if (item.creditid) {
                this.appliedCreditItems.forEach(credit => {
                    if (credit.appliedto == item.name) {
                        totalValue += credit.value
                        
                    }
                })
            }
            return totalValue
        },
        getSaleValue(name) {
            let totalValue = 0
            this.invoice.invoiceitems.forEach(item => {
                if (item.name == name) {
                    totalValue = item.total
                }
            })
            return totalValue
        },
        getCreditQuantity(name) {
            let quantity = 0
            this.invoice.invoiceitems.forEach(item => {
                if (item.name == name) {
                    quantity = item.quantity
                }
            })
            return quantity
        },
        getPromoName(item) {
            let name = ''
            if (item.creditid) {
                this.appliedCreditItems.forEach(credit => {
                    if (credit.appliedto == item.name && credit.quantity > 0) {
                        window.console.log('MATCH', credit, credit.appliedto, item.name)
                        name = credit.name                        
                    }
                })
            }
            return name
        }
    },
    mounted() {
        window.console.log('INVOICE ITEMS DETAIL', this.invoiceItemsStaff,'APPLIED',this.appliedCreditItems)
    },
}
</script>
<style scoped>
.report-detail-container{
    /* border: 1px solid black; */
    background-color: white;
}
.report-item-detail-row{
    border-top: 1px solid #aac1ce;
    height: 20px;
}
.report-table-detail-item{
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    height: 20px;
    width: 12%;
    border-right: 1px solid #aac1ce;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 5px;
}
.report-table-detail-item:last-child{
    border-right: none;
}
.small-width{
    width: 8%;
}
.large-width{
    width: 18%;
}
.title-row{
    height: 18px;
    background-color: #9cb0b9;
    color: white;
    font-weight: 500;
}
</style>