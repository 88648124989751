<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">XXX{{'Add User' | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'First Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.firstname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.firstname">*{{'You need to enter a' | translate}} {{'First Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Last Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.lastname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Last Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Phone' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.phone"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.phone">*{{'You need to enter your' | translate}} {{'Phone Number' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.email"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.email">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Street' | translate}}:</label> 
            <input class="input-wide"  v-model="user.street"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Suite' | translate}}/{{'Apt' | translate}}/{{'Unit' | translate}}:</label> 
            <input class="input-wide"  v-model="user.address2"/>
        </div>
        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Zip' | translate}}:</label> 
            <input class="input-narrow" v-model="locationInfo.zip" @input="checkZip"/>
        </div>
        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'City' | translate}}, {{'State' | translate}}:</label> 
            {{locationInfo.city}}, {{locationInfo.state}}
        </div>

        <div class="button-main" @click="verifyForm">{{'Submit' | translate}}</div>

        <ButtonBar :buttonlist="buttonList" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'User Successfully Added' | translate}}</div>
        </div>  
        
    </div>
</template>

<script>
import store from '../store/store'
import ButtonBar from './utility/ButtonBar'

export default {
    name: 'UserAdd',
    data() {
        return {
            user: {},
            showError: false,
            showSuccess: false,
            store,
            locationInfo: {},
            buttonList: ['Back','Submit','Delete','Add','Remove','More','Explore']
        }
    },
    components: {
       ButtonBar
    },
    methods: {
        verifyForm() {
            window.console.log('VERIFY')
            this.showError = false
            if (!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone || !this.user.password) {
                window.console.log('ERROR FOUND')
                this.showError = true
            } else {
                this.submitForm()
            }
        },
        submitForm() {
            window.console.log('SUBMIT')
            if (this.applyTo.staff) {
                this.user.type = 'staff'
            } else {
                this.user.type = 'client'
            }
            this.user.city = this.locationInfo.city
            this.user.state = this.locationInfo.state
            this.user.type = 'staff'
            this.user.password = 'atcnaples'
            this.$store.dispatch('addUser', this.user).then(response => {
                window.console.log('ADDED USER',response)
                if (response.result==='SUCCESS') {
                    this.showSuccess = true
                }
            })
        },
        checkZip() {
             window.console.log('CK ZIP', this.locationInfo.zip)
            if (this.locationInfo.zip.length > 4) {
                this.getZipInfo()
            }
        },
        getZipInfo() {
            window.console.log('BEFORE', this.locationInfo)
            const vm = this
            var client = new XMLHttpRequest()
            const url = "http://api.zippopotam.us/us/" + vm.locationInfo.zip.substr(0,5)
            client.open("GET", url, true)
            client.onreadystatechange = function() {
                if(client.readyState == 4) {
                    // window.console.log('GOT RESPONSE', client.responseText)
                    const data = JSON.parse(client.responseText)                    
                    window.console.log('RESPONSE dETAIL', data.places[0]['place name'], data.places[0]['state abbreviation'] )
                    vm.locationInfo.city = data.places[0]['place name']
                    vm.locationInfo.state = data.places[0]['state abbreviation']                   
                }
            }

            client.send();
        },
    },
    watch: {
        rbWatcher() {
            let vm = this
            window.console.log('RB', this.rbWatcher)
            const watchArray = this.rbWatcher.split('~')
            vm.applyTo[watchArray[0]] = Boolean(watchArray[1])
            if (Boolean(watchArray[1]) === true) {
                window.console.log('TRUE', this.rbWatcher)
                this.selectedCategory = watchArray[0]
            }
            Object.keys(vm.applyTo).forEach(item => {               
                if(item !== watchArray[0]){
                    vm.applyTo[item] = false
                }
            })
        },
    }
}
</script>

<style scoped>
    
</style>