<template>
    <div>
        <div class="schedule-table">
            <div class="schedule-table-title">{{locationname}}: {{scheduletitle | translate}} - {{totalHours}}</div>
            <div class="schedule-table-title-row">
                <div class="schedule-table-item description-column description-title">{{'Day' | translate}}</div>
                <div class="schedule-table-item title-row">{{'Start' | translate}}</div>
                <div class="schedule-table-item title-row">{{'End' | translate}}</div>
                <div class="schedule-table-item title-row">{{closeName | translate}}</div>
            </div>
            <div class="schedule-table-row" v-for="day in weekSchedule" :key="day.id">
                <div class="schedule-day-closed" v-if="ckClosed(`${day.name}Closed`)"></div>
                <div class="schedule-table-item description-column">
                    <div class="table-item-label">{{day.name | translate}}</div>
                </div>
                <div class="schedule-table-item">
                    <div class="schedule-table-item-dropdown">
                        <div class="schedule-user-info" v-if="!ckClosed(`${day.name}Closed`)">{{checkUserTime(day.id,'startTime')}}</div>
                        <Dropdown 
                            v-if="!ckClosed(`${day.name}Closed`)" 
                            v-on:item-selected="weekSchedule[day.id].startTime = $event,scheduleUpdated = $event" 
                            v-bind:selected="workTime.name" 
                            v-bind:optioncontent="workTime"
                            placeholder=" ">
                        </Dropdown>
                    </div>
                </div>
                <div class="schedule-table-item">
                    <div class="schedule-table-item-dropdown">
                        <div class="schedule-user-info" v-if="!ckClosed(`${day.name}Closed`)">{{checkUserTime(day.id,'endTime')}}</div>
                        <Dropdown
                            v-if="!ckClosed(`${day.name}Closed`)"  
                            v-on:item-selected="weekSchedule[day.id].endTime = $event,scheduleUpdated = $event" 
                            v-bind:selected="workTime.name" 
                            v-bind:optioncontent="workTime"
                            placeholder=" ">
                        </Dropdown>
                    </div>
                </div>
                <div class="schedule-table-item">
                    <div class="schedule-user-info"></div>
                    <div class="schedule-table-item-checkbox">
                        <CheckBox
                            v-bind:checkBoxLabel="''"
                            v-on:item-selected="weekSchedule[day.id].closed = $event,scheduleUpdated= $event" 
                            :cbmodel = "weekSchedule[day.id].closed"
                        />
                    </div>
                </div>
            </div>
                 
        </div>
        <div class='missing-selection-message' v-if="showErrors && totalHours === 0">*{{'You need to enter start and end times' | translate}} </div>
    </div>
</template>

<script>
import Constants from '../../utilities/constants'
import Dropdown from './Dropdown'
import CheckBox from './CheckBox'

export default {
    name: 'WeekSchedule',
    data() {
        return{
            showErrors: false,
            workTime: Constants.officeTimes,
            daysWeek: Constants.daysOfWeek,
            totalHours: 0,
            weekSchedule: [
                {id: 0, name: 'Sunday'},
                {id: 1, name: 'Monday'},
                {id: 2, name: 'Tuesday'},
                {id: 3, name: 'Wednesday'},
                {id: 4, name: 'Thursday'},
                {id: 5, name: 'Friday'},
                {id: 6, name: 'Saturday'},
            ],
            scheduleUpdated: {},
            SundayClosed: false,
            MondayClosed: false,
            TuesdayClosed: false,
            WednesdayClosed: false,
            ThursdayClosed: false,
            FridayClosed: false,
            SaturdayClosed: false,
            closeName: this.closename ? this.closename : 'Closed'
        }
    },
    props: [
        'scheduletitle',
        'existingschedule',
        'closename',
        'locationname'
    ],
    components: {
        Dropdown,
        CheckBox,
    },
    methods: {
        checkUserTime(id,ref){
            if (this.weekSchedule[id][ref]) {
                return this.weekSchedule[id][ref].name
            }
        },
        checkClosed() {
            this.weekSchedule.forEach(item => {
                const ckName = `${item.name}Closed`
                if (item.closed) {   
                    this[ckName] = true
                } else {
                    this[ckName] = false
                }
            })
        },
        ckClosed(name) {
            return this[name]
        },
        calculateHours() {
            this.totalHours = 0
            this.weekSchedule.forEach(day => {
                if(day.startTime && day.endTime && !day.closed){
                    this.totalHours += (day.endTime.id - day.startTime.id)/4
                }
            })
        },       
    },
    computed: {
        
    },
    watch: {
        scheduleUpdated() {
            this.checkClosed()
            this.calculateHours()
            this.$emit('schedule-updated',this.weekSchedule)
            this.$emit('totalhours-updated',this.totalHours)
            this.scheduleUpdated = ''
        }
    },
    mounted() {
        this.weekSchedule = this.existingschedule ? this.existingschedule : this.weekSchedule
        this.checkClosed()
        // window.console.log('EX SCH', this.existingschedule, 'DISPLAY',JSON.stringify(this.weekSchedule))
    }
}
</script>

<style scoped>
.schedule-table{
    border: 1px solid #0b8ecc;
    display: inline-block;
    margin-bottom: 15px;
}
.schedule-table-title{
    background-color: #1b6fab;
    color: white;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}
.schedule-table-title-row{
    height: 25px;
    background-color: #0b8ecc;
    color: white;
    line-height: 26px;
    border-bottom: 1px solid #0b8ecc;
    font-weight: 600;
}

.schedule-table-row{
    height: 50px;
    vertical-align: top;
    display: block;
    border-bottom: 1px solid #0b8ecc;
    position: relative;
}
.schedule-table-row:last-child{
    border-bottom: none;
}
.schedule-table-item{
    display: inline-block;
    width: 80px;
    border-right: 1px solid #0b8ecc;
    font-size: 13px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
}
.table-item-label{
    padding-right: 3px;
    font-weight: 600;
    color: #0b8ecc;
    line-height: 50px;
}
.schedule-table-item:last-child{
    border-right: none;
}
.schedule-table-item-dropdown{
    padding-top: 3px;
}
.schedule-table-item-checkbox{
    padding-left: 7px;
    padding-top: 5px;
}
.schedule-table-input{
    width: 60px;
    height: 25px;
}
.schedule-user-info{
    font-size: 12px;
    font-weight: 600;
    min-height: 10px;
    min-width: 1px
}
.title-row{   
    border-right: 1px solid white;
}
.description-column{
    width: 85px;
    text-align: right;
    padding-right: 3px;
    background-color: #f8f9f9;
} 
.description-title{
    height: 25px;
    vertical-align: top;
    font-weight: 600;
    text-align: left;
    line-height: 26px;
    background-color: #0b8ecc;
    text-align: center;
    border-right: 1px solid white;
} 
.schedule-day-closed{
    background-color: gray;
    height: 51px;
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    opacity: 0.2;
}  
</style>