<template>
    <div class="reports-form-container">
        <div class="reports-header">
            {{'Income Report' | translate | allCaps}}
        </div>
        <div class="reports-content-container">           
            <div class="reports-day-panel">
                <div class="reports-title-header">{{'Day' | translate | allCaps}}</div>            
                <div class="report-date-picker">
                    <DatePicker  
                        class="date-picker"                
                        v-on:date-selected="selectedDate = $event" 
                        v-bind:mydate="selectedDate"
                        :placeholder = "'Select Date'">
                    </DatePicker>
                </div>
                <div v-if="showDayResults">
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Total Sales' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.totalsales | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Total Invoiced' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.invoicetotal | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Amount Paid' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.amountpaid | toUSD}}</div>
                        <div class="arrows-up-down">
                            <i class="fas fa-chevron-right" v-if="showPayTypes" @click="showPayTypes = false"></i>
                            <i class="fas fa-chevron-down" v-if="!showPayTypes" @click="showPayTypes = true"></i>    
                        </div>
                    </div>
                    <div v-if="showPayTypes">
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Cash' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.paytype.cash | toUSD}}</div>
                        </div>
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Check' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.paytype.check | toUSD}}</div>
                        </div>
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Credit Card' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.paytype.creditcard | toUSD}}</div>
                        </div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Credit Applied' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.creditapplied | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tips' | translate}}:</div><div class="report-item-amount">{{this.resultsData.dayData.tip | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tax Products' | translate}}:</div>
                        <div class="report-item-amount">{{this.resultsData.dayData.taxproduct | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tax Services' | translate}}:</div>
                        <div class="report-item-amount">{{this.resultsData.dayData.taxservice | toUSD}}</div>
                    </div>
                </div>
                <div>
                    <div class="report-item-row" v-if="this.resultsData.dayAdjust.length">
                        <div class="report-item-label" >{{'Adjustments' | translate }} ({{this.resultsData.dayAdjust.length}})</div> 
                        <div class="report-item-amount">{{adjustDayTotal | toUSD }}</div>
                        <div class="arrows-up-down">
                            <i class="fas fa-chevron-right" v-if="showAdjustmentsDay" @click="showAdjustmentsDay = false"></i>
                            <i class="fas fa-chevron-down" v-if="!showAdjustmentsDay" @click="showAdjustmentsDay = true"></i>    
                        </div>
                    </div>
                    <div v-if="showAdjustmentsDay">
                        <div v-for="(item,index) in this.resultsData.dayAdjust" :key="index">
                            <div class="report-item-row back-gray">
                                <div class="report-item-label clickable" @click="showInvoice(item.invoice)">{{ 'Invoice' | translate }} - {{item.invoice}}</div>
                                <div class="report-item-amount">{{item.amount | toUSD }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="reports-half-panel">
                <div class="reports-title-header">{{'Period' | translate | allCaps}}</div>
                <div class="report-date-picker">
                    <DatePicker  
                        class="date-picker"                
                        v-on:date-selected="selectedStartDate = $event" 
                        v-bind:mydate="selectedStartDate"
                        :placeholder = "'Select Start Date'">
                    </DatePicker>
                </div>
                <div class="report-date-picker">
                    <DatePicker  
                        class="date-picker"                
                        v-on:date-selected="selectedEndDate = $event" 
                        v-bind:mydate="selectedEndDate"
                        :placeholder = "'Select End Date'">
                    </DatePicker>
                </div>
                <div v-if="showDatesResults">
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Total Sales' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.totalsales | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Total Invoiced' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.invoicetotal | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Amount Paid' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.amountpaid | toUSD}}</div>
                        <div class="arrows-up-down">
                            <i class="fas fa-chevron-right" v-if="showPayTypes" @click="showPayTypes = false"></i>
                            <i class="fas fa-chevron-down" v-if="!showPayTypes" @click="showPayTypes = true"></i>    
                        </div>
                    </div>
                    <div v-if="showPayTypes">
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Cash' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.paytype.cash | toUSD}}</div>
                        </div>
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Check' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.paytype.check | toUSD}}</div>
                        </div>
                        <div class="report-item-row back-gray">
                            <div class="report-item-label">{{'Credit Card' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.paytype.creditcard | toUSD}}</div>
                        </div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Credit Applied' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.creditapplied | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tips' | translate}}:</div><div class="report-item-amount">{{this.resultsData.periodData.tip | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tax Products' | translate}}:</div>
                        <div class="report-item-amount">{{this.resultsData.periodData.taxproduct | toUSD}}</div>
                    </div>
                    <div class="report-item-row">
                        <div class="report-item-label">{{'Tax Services' | translate}}:</div>
                        <div class="report-item-amount">{{this.resultsData.periodData.taxservice | toUSD}}</div>
                    </div>
                </div>
                <div>
                    <div class="report-item-row" v-if="this.resultsData.periodAdjust.length">
                        <div class="report-item-label" >{{'Adjustments' | translate }} ({{this.resultsData.periodAdjust.length}})</div> 
                        <div class="report-item-amount">{{adjustDatesTotal | toUSD }}</div>
                        <div class="arrows-up-down">
                            <i class="fas fa-chevron-right" v-if="showAdjustments" @click="showAdjustments = false"></i>
                            <i class="fas fa-chevron-down" v-if="!showAdjustments" @click="showAdjustments = true"></i>    
                        </div>
                    </div>
                    <div v-if="showAdjustments">
                        <div v-for="(item,index) in this.resultsData.periodAdjust" :key="index">
                            <div class="report-item-row back-gray">
                                <div class="report-item-label clickable" @click="showInvoice(item.invoice)">{{ 'Invoice' | translate }} - {{item.invoice}}</div>
                                <div class="report-item-amount">{{item.amount | toUSD }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from '../utility/DatePicker'
import store from '../../store/store'
import Globals from '../../utilities/globals'
export default {
    name: 'DailyIncomeReport',
    data() {
        return {
            store,
            selectedDate: {},
            selectedStartDate: {},
            selectedEndDate: {},
            showDatesResults: false,
            showDayResults: false,
            showAdjustments: false,
            showAdjustmentsDay: false,
            showPayTypes: false,
            location: this.$store.getters.currentLocation.id,
            data: {},
            dataItems: {
                amountpaid: 0,
                invoicetotal: 0,
                totalsales: 0,
                creditapplied: 0,
                paytype: {
                    cash: 0,
                    creditcard: 0,
                    check: 0
                },
                tip: 0,
                taxproduct: 0,
                taxservice: 0
            },
            resultsData: {
                dayData: Object.assign({},this.dataItems),
                dayAdjust: [],
                periodData: Object.assign({},this.dataItems),
                periodAdjust: []
            }
            
        }
    },
    components: {
        DatePicker
    },
    methods: {
        setSelectedToday() {
            const today = new Date()
            const day = today.getDate()
            const year = today.getFullYear()
            this.selectedDate = {
                day: day,
                month: today.getMonth(),
                year: year,
                selectedDate: Globals.getSelectedDate(day,today.getMonth(),year)
            }
        },
        setSelectedMonth() {
            const today = new Date()
            const day = today.getDate()
            const year = today.getFullYear()
            this.selectedStartDate = {
                day: 1,
                month: today.getMonth(),
                year: year,
                selectedDate: Globals.getSelectedDate(1,today.getMonth(),year)
            }
            this.selectedEndDate = {
                day: day,
                month: today.getMonth(),
                year: year,
                selectedDate: Globals.getSelectedDate(day,today.getMonth(),year)
            }
        },
        getInvoicesSelectedDate(selectData) {
            this.resultsData.dayData = {}
            this.adjustmentsDay = []
            this.$store.dispatch('getInvoicesDay',selectData).then(response => {
                this.showDayResults = true
                const period = this.calcDatesTotal(response,'day')
                this.adjustmentsDay = period.dayAdjust
            })   
        },
        showInvoice(invoiceId) {
            this.$router.push(`InvoiceId?id=${invoiceId}`)
        },
        getInvoicesPeriod() {
            this.$store.dispatch('getInvoicesDates',this.data).then(response => {
                const period = this.calcDatesTotal(response,'period')
                this.resultsData.periodData = period.periodData
                this.periodAdjustments = period.periodAdjust
            })            
        },
        calcDatesTotal(data,type) {
            let datesData = Object.assign({}, this.dataItems)
            let adjustmentsDates = []
            this.adjustDatesTotal = 0
            data.forEach(item => {
                let balanceCk = 0
                const xtras =  (Number(item.taxproduct) +  Number(item.taxservice) + Number(item.tip))
                let costPurchase = 0 
                if (item.productservicetotal) {
                    costPurchase = Number(item.productservicetotal) + xtras
                } else if (item.invoicetotal) {
                    costPurchase = Number(item.invoicetotal)
                 } else {
                    item.invoiceitems.forEach(invoiceitem => {
                        costPurchase +=  Number(invoiceitem.total) 
                    })
                    costPurchase += xtras
                }
                datesData.invoicetotal += costPurchase
                balanceCk += Number(costPurchase)
                
                datesData.totalsales += costPurchase - xtras                          
                datesData.amountpaid += Number(item.amountpaid)
                balanceCk -= Number(item.amountpaid)
                let creditApply = 0
                if (item.appliedcredititems?.length) {                    
                    item.appliedcredititems.forEach(credit => {
                        creditApply += credit.AMTAPPLIED ? Number(credit.AMTAPPLIED) : 0                        
                    })                                        
                }
                if (item.creditapplied) {
                    if (Number(item.creditapplied) > creditApply) {
                        creditApply = Number(item.creditapplied)
                    }
                }
                datesData.creditapplied +=  creditApply
                balanceCk -= Number(creditApply)
                if (Number(balanceCk) > 0.01) {
                    const adjData = {
                        amount: balanceCk,
                        invoice: item.id
                    }
                    adjustmentsDates.push(adjData)
                }
                
                if (item.tip) {
                    datesData.tip += Number(item.tip)
                }
                if (item.taxproduct) {
                    datesData.taxproduct += Number(item.taxproduct)
                }
                if (item.taxservice) {
                    datesData.taxservice += Number(item.taxservice)
                }
                switch (item.paytype) {
                    case 'Cash': 
                        datesData.paytype.cash += Number(item.amountpaid)
                        break;
                    case 'Credit Card':
                        datesData.paytype.creditcard += Number(item.amountpaid)
                        break;
                    case 'Check':
                        datesData.paytype.check += Number(item.amountpaid)
                        break;
                }            
            })
            // const payTotal = datesData.paytype.cash + datesData.paytype.check + datesData.paytype.creditcard 
            adjustmentsDates.forEach(adjustitem => {
                this.adjustDatesTotal += Number(adjustitem.amount)
            })
            this.showDatesResults = true
            if (type == 'day') {
                this.resultsData.dayData = datesData,
                this.resultsData.dayAdjust = adjustmentsDates                
            } else {
                this.resultsData.periodData = datesData,
                this.resultsData.periodAdjust = adjustmentsDates
            }
        },
    },
    watch: {
        selectedDate() {
            const data = {
                invoiceday:  `${this.selectedDate.year}/${this.selectedDate.month + 1}/${this.selectedDate.day}`,
            }
            this.getInvoicesSelectedDate(data)
        },
        selectedStartDate() {
            this.data.startdate = `${this.selectedStartDate.month + 1}/${this.selectedStartDate.day}/${this.selectedStartDate.year}`
            if (this.data.enddate) {
                this.getInvoicesPeriod()
            }
        },
        selectedEndDate() {
            this.data.enddate = `${this.selectedEndDate.month + 1}/${this.selectedEndDate.day}/${this.selectedEndDate.year}`
            if (this.data.startdate) {
                this.getInvoicesPeriod()
            }
        }
    },
    mounted() {
        this.setSelectedToday()
        this.setSelectedMonth()
    }

}
</script>
<style scoped>
.reports-form-container{
    width: 100%;
} 
.reports-header{
    height: 30px;
    width: 100%;
    background-color: indigo;
    color: white;
    line-height: 30px;
}
.reports-content-container{
    min-height: 400px;
    background-color: white;
    padding: 1%;
} 
.reports-day-panel{
    width: 300px;
    border: 1px solid black;
    display: inline-block;
    vertical-align: top;
    margin: .5%;
}
.reports-half-panel{
    min-width: 300px;
    border: 1px solid black;
    display: inline-block;
    vertical-align: top;
    margin: .5%;
}
.report-item-row{
    text-align: left;
    padding: 5px;
} 
.report-item-label{
    width: 50%;
    display: inline-block;
    text-align: right;
} 
.report-item-amount{
    display: inline-block;
    text-align: right;
    font-weight: 600;
    width: 90px;
}
.arrows-up-down{
    float: right;
    padding-right: 20px;

}
.back-gray{
    background-color:#e2edf0;;
}
.clickable{
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}
.reports-title-header{
    background-color:#74589e;
    color: white;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
}
</style>