<template>
    <div >
        <div class="medical-topic-container" v-for="(topic,index) in medicalList" :key="`list-${index}`">
            <div class="medical-topic-title" @click="setShowTopic(topic.name)">
                <span>{{ translate(topic.name)}}</span>&nbsp; 
                <i class="fas fa-chevron-down" v-show="!topic.display"></i>
                <i class="fas fa-chevron-right" v-show="topic.display"></i>
                <span class="check-complete" v-if="topicComplete(topic)"><i class="fas fa-check"></i></span>
            </div>
            <div v-if="topic.display">
                <div v-for="(item,index) in selectedTopic(topic.name)" :key="`topictitle-${index}`">
                    <div class="subtopic-title">
                        <span>{{item.title}}</span>&nbsp; 
                        <span v-if="item.titleadd" class="subtopic-title-add">({{ translate(item.titleadd)}})</span>
                    </div>
                    <!--  SINGLE ITEM ENTRY-->
                    <div class="subtopic-item-container" v-if="checkOptions(item.itemList)">
                        <div v-for="(existingItem,index) in item.itemList" :key="item.name+'-'+ index" class="med-item-row">
                            <div class="med-survey-med-item">{{existingItem}}</div>
                            <i class="fas fa-trash-alt" @click="removeItem(item.itemList,index)" ></i>
                        </div>
                        <div class="med-item-input-row">
                            <input class="med-survey-input" v-model="itemEntry" :placeholder="`Enter ${item.type} Name`"/>
                            <div class="button-main small-button" @click="addItem(item.itemList)">{{'Add' | translate}}</div>
                        </div>
                    </div>

                    <!-- MEDICATION TRIPLE ITEM ENTRY -->
                    <div class="subtopic-item-container" v-if="checkOptions(item.medicationlist)">
                        <div v-for="(med,index) in item.medicationlist" :key="'med-'+ index" class="perscription-item-row">
                            <div class="perscription-saved-answer">{{med.name}}</div>
                            <div class="perscription-saved-answer">{{med.dosage}}</div>
                            <div class="perscription-saved-answer">{{med.frequency}}/day</div>
                            <i class="fas fa-trash-alt" @click="removeMed(index)" v-if="!setInactive"></i>
                        </div>
                        <div class="med-item-input-row" v-if="!setInactive">
                            <input class="med-survey-input" v-model="med.name" placeholder="Enter Medication Name"/>
                            <input class="med-survey-input small-input" v-model="med.dosage" placeholder="Dosage"/>
                            <input class="med-survey-input small-input" v-model="med.frequency" placeholder="Frequency"/> /{{'day' | translate }}
                            <div class="button-main small-button" @click="addMed">{{'Add' | translate}}</div>
                        </div>
                        <div class="perscription-item-row" v-if="setInactive">
                            <div class="perscription-saved-answer" v-if="setInactive">{{med.name}}</div>
                            <div class="perscription-saved-answer" v-if="setInactive">{{med.dosage}}</div>
                            <div class="perscription-saved-answer" v-if="setInactive">{{med.frequency}}</div>
                        </div>
                    </div >
                    <!-- NOTES -->
                    <div class="subtopic-item-container" v-if="checkOptions(item.notes)">
                        <div class="input-container">
                            <div class="note-title" v-if="!setInactive">{{'Add Note' | translate}}</div>
                            <div class="note-title" v-if="setInactive">{{'Existing Note' | translate}}</div>
                            <textarea 
                                class="note-input"
                                v-if="!setInactive" 
                                v-model="item.notes[item.notes.length-1].note"
                                v-on:focus="checkEntryDate(item.notes[item.notes.length-1],item.notes)"
                                v-on:keyup="checkEntry(item.notes[item.notes.length-1])"
                            />
                            <div class="perscription-saved-note" v-if="setInactive">{{item.notes[item.notes.length-1].note}}</div>
                            <div class="note-title">{{'Existing Notes' | translate}}</div>
                            <div v-for="(eachnote,index) in item.notes" :key="'note-'+index">
                                <div v-if="index !== item.notes.length-1" class="note-container">{{eachnote.date}}-{{eachnote.note}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- QUESTIONS -->
                    <div class="subtopic-item-container" v-if="checkOptions(item.questions)">
                        <div class="subtopic-item-questions" v-for="(question,questionindex) in item.questions" :key="'ques-' + questionindex">
                            <div class="subtopic-item-question">{{question.name}}</div>
                            <div class="subtopic-item-answer" v-if="question.type == 'radio'">
                                <div class="radiobutton-container" v-for="(radioitem,radioindex) in question.options" :key="'radio-'+radioindex">
                                    <RadioButton 
                                        :rbLabel="radioitem.name"
                                        :rbStatus="radioitem.value"
                                        :rbRef="`${topic.name}-${item.name}-${questionindex}-${radioitem.id}`"
                                        :inactive="setInactive"
                                        v-on:rb-selected="rbQuestions = $event"
                                    />
                                </div>
                            </div>
                            <div  class="subtopic-item-answer" v-if="question.type == 'explain'">
                                <input 
                                    v-if="!setInactive"
                                    class="question-input" 
                                    v-model="question.answer"
                                />
                                <div class="subtopic-saved-answer" v-if="setInactive">{{question.answer}}</div>
                            </div>
                            <div class="subtopic-underline" v-if="question.type == 'explain'"></div>
                        </div>
                    </div>

                    <!-- RADIO BUTTON SELECTION -->
                    <div class="subtopic-item-container" v-if="checkOptions(item.radioOptions)">
                        <div v-for="(option,index) in item.radioOptions" :key="'ra-' + index">
                            {{option.name}}
                            
                            <div class="radiobutton-container" v-for="(radioitem,index) in option.options" :key="'radio-'+index">
                                <RadioButton 
                                    :rbLabel="radioitem.name"
                                    :rbStatus="radioitem.value"
                                    :rbRef="`${topic.name}-${item.name}-${option.name}-${radioitem.id}`"
                                    :inactive="setInactive"
                                    v-on:rb-selected="rbWatcher = $event"
                                />
                            </div>
                            
                        </div>
                    </div>
                    <!-- CHECK OPTIONS -->
                    <div class="subtopic-item-container" v-if="checkOptions(item.options)">
                        <div 
                            class="checkbox-inline-container"
                            v-for="(option,index) in item.options" 
                            :key="`option-${index}`">
                            
                            <CheckBox 
                                :checkBoxLabel="option.name"
                                :cbref="`${topic.name}-${item.name}-${option.name}`"
                                :cbmodel="option.value"
                                :inactive="setInactive"
                                v-on:cb-selected="cbSelected = $event">
                            </CheckBox>
                        </div>
                    </div>
                    <div class="subtopic-item-container centered" v-if="item.nooption && item.nooption.display">
                        <div class="checkbox-inline-container">
                            <CheckBox 
                                :checkBoxLabel="item.nooption.name"
                                :cbref="`${topic.name}-${item.name}-nooption`"
                                :cbmodel="item.nooption.value"
                                :inactive="setInactive"
                                v-on:cb-selected="cbSelected = $event">
                            </CheckBox>
                        </div>
                    </div>
                </div>
                <div class="subtopic-item-container centered">
                    <ButtonBar 
                        :buttonlist="buttonList"
                        v-on:button-click="buttonClicked = $event" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Globals from '../../utilities/globals'
import HealthInfo from '../../data/healthInfo.json'
import CheckBox from './CheckBox'
import RadioButton from './RadioButton'
import ButtonBar from './ButtonBar'

export default {
    name:'MedSurvey',
    data() {
        return{
            medicalList: [
                {name:'Patient Concerns',display: false},
                {name:'Skin',display: false},
                {name:'Allergies',display: false},
                {name:'Medications',display: false},
                {name:'Surgical Procedures',display: false},
                {name:'Medical History',display: false},
                {name:'Family Medical History',display: false},
                {name:'Social History',display: false},
                {name:'Symptoms',display: false},
                {name:'Examination',display: false},
                {name:'Impression',display: false},
                {name:'Plan',display: false},
            ],
            showHealthRecords: false,
            showHideHR: false,
            cbSelected: '',
            healthTopics: HealthInfo,
            rbWatcher: '',
            rbQuestions: '',
            med: {},
            itemEntry:'',
            keystrokeTrackTime:0,
            setInactive: false,
            buttonList: ['Edit'],
            buttonClicked: ''

        }
    },
    components:{
        CheckBox,
        RadioButton,
        ButtonBar
    },
    props: ['refname','clientid'],
    methods: {
        checkEntryDate(note,notes){
            const today = Globals.formatShortDate(new Date())
            if (!note.date) {
                note.date = today
            } else if (note.date && note.date !== today){
                const newNote = {
                    id: notes.length + 1,
                    note: '',
                    date: today
                }
                notes.push(newNote)
                window.console.log('ADD NEW NOTE',notes)
            }
        },
        checkEntry(note) {
            this.keystrokeTrackTime = new Date().getTime() 

            const starttime = new Date().getTime()
            
            let checktime = setInterval(() => {
                const currentTime = new Date().getTime() 
                if (currentTime - this.keystrokeTrackTime > 1000) {
                    
                    
                    window.console.log('SAVE NOTE',note)
                    this.saveRecord()
                }
                if (currentTime - starttime  >= 1000) {
                    clearInterval(checktime)
                }
                
            }, 1000);
            
        },
        addNote(notes) {
            window.console.log('ADD NOTE',notes)
            
        },
        addMed() { 
            const medItem = {
                name: this.med.name,
                dosage: this.med.dosage,
                frequency: this.med.frequency
            }   
            this.healthTopics.medications.sections[0].medicationlist.push(medItem)
            this.med = {}
        },
        addItem(list) {
            list.push(this.itemEntry)
            this.itemEntry = ''
            window.console.log('ADD ITEM', this.healthTopics)
        },
        removeMed(index) {
            window.console.log('REPMVE MED',index)
            this.healthTopics.medications.sections[0].medicationlist.splice(index,1)
        },
        removeItem(list,index) {
            list.splice(index,1)
            window.console.log('REPMVE Item',this.healthTopics)
        },
        translate(value) {
            return Globals.translate(value)
        },
        selectedTopic(topic) {           
            const topicContent = this.healthTopics[topic.toLowerCase()]
            const results = topicContent && topicContent.sections ? topicContent.sections : []
            return results
        },
        setShowTopic(topic) {
            window.console.log('SHOW TOPIC',topic)
            this.medicalList.forEach(item => {
                if (item.name == topic) {
                    item.display = !item.display
                    window.console.log('ITEM UPDATE', item)
                }
                
            })
        },
        checkOptions(item) {
            if (item) {
                return true
            }
            return false
        },
        checkNoOptions(item){
            window.console.log('NO OPTIONS', item)
            return true
        },
        saveRecord() {  
            window.console.log('SAVE MED RECORD', this.healthTopics)         
            const data = {
                data: Globals.encryptData(JSON.stringify(this.healthTopics)),
                refnum: Globals.getKey(this.refname,this.clientid)
            }
            window.console.log('SAVE MED RECORD', this.healthTopics)
            this.$store.dispatch('updateRecord',data).then(response => {
                window.console.log('UPDATE RECORD',response)
            })            
        },
        getMedHistory() {
            const data = {
                refnum: Globals.getKey(this.refname,this.clientid)
            }
            window.console.log('MED DATA',data,this.refname,this.clientid)
            this.$store.dispatch('getMedRec',data).then(response => {
                if (response.result == 'SUCCESS') {
                    window.console.log('MED RECORD EXISTS')
                    this.setInactive = true
                    const topics = Globals.decryptData(response.data.info)
                    this.healthTopics = JSON.parse(topics)
                     window.console.log('EXISTING HEALTH RECORD', this.healthTopics)
                } else {
                    window.console.log('MED RECORD DOES NOT EXIST')
                    this.buttonList = []
                    this.setInactive = false
                }
                
            })
        },
        topicComplete(topic){
            const topicContent = this.healthTopics[topic.name.toLowerCase()]
            let sectionsComplete = false
            if (topicContent && topicContent.sections) {
                sectionsComplete = true
                topicContent.sections.forEach(section => {
                   if (!this.sectionComplete(section)) {
                       sectionsComplete = false
                   }                    
                })
            }
            return sectionsComplete            
        },
        sectionComplete(section) {
            if (section.nooption && section.nooption.value) {
                return true
            }
            if (section.options && section.options.some(option => option.value == true)) {
                return true
            }
            return false
        }
    },
    watch: {
        cbSelected() {
            window.console.log('SELECTED',  this.cbSelected)
            
            const selectedArray = this.cbSelected.split('-')
            this.healthTopics[selectedArray[0].toLowerCase()].sections.forEach(section => {
                window.console.log(section)
                if (section.name === selectedArray[1]) {
                    if (selectedArray[2] == 'nooption') {
                        section.nooption.display = true
                        section.nooption.value = selectedArray[3] == true || selectedArray[3] == 'true' ? true : false
                        section.options.forEach(option => {
                            option.value = false
                        })
                    } else {
                        section.options.forEach(option => {
                            if (option.name === selectedArray[2]) {                               
                                option.value = selectedArray[3] == true ||  selectedArray[3] == 'true' ? true : false
                            }
                        })
                        
                        if (section.nooption) {
                            const nonevalue = !section.options.some(option => option.value == true)
                            section.nooption.display = nonevalue == true || nonevalue == 'true' ? true : false
                            section.nooption.value = nonevalue == true  || nonevalue == 'true' ? true : false
                        }
                    }
                }
            })
            this.saveRecord()
        },
        rbWatcher() {
            window.console.log('RB changed',this.rbWatcher)
            const rbNameArray = this.rbWatcher.split('-')
            this.healthTopics[rbNameArray[0].toLowerCase()].sections.forEach(section => {
                if(section.name == rbNameArray[1]) {
                    window.console.log('SECTION RADIO OPTIONS',section.radioOptions)
                    section.radioOptions.forEach( rbOption => {
                        if (rbOption.name == rbNameArray[2]){
                            rbOption.options.forEach(rbItem => {
                                window.console.log('EACH',rbItem.id, rbNameArray[3])
                                if (rbItem.id == rbNameArray[3].split('~')[0]) {
                                    rbItem.value = true
                                } else {
                                    rbItem.value = false
                                }
                            })
                        }
                    })
                }
            })
            this.saveRecord()
        },
        rbQuestions() {
            window.console.log('RB QUESTION', this.rbQuestions)
            const rbNameArray = this.rbQuestions.split('-')
            this.healthTopics[rbNameArray[0].toLowerCase()].sections.forEach(section => {
                if (section.name == rbNameArray[1]) {
                    section.questions[rbNameArray[2]].options.forEach(answer => {
                        if (Number(answer.id) == Number(rbNameArray[3].split('~')[0])) { 
                            answer.value = true
                        } else {
                            answer.value = false
                        }
                    })
                }
            })
            this.saveRecord()            
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Edit') {
                this.setInactive = false
                this.buttonList = ['Save','Cancel']
            }
            if (this.buttonClicked.name === 'Save') {
                this.setInactive = true
                this.buttonList = ['Edit']
                this.saveRecord()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.getMedHistory()
                this.buttonList = ['Edit']
            }
        }
    },
    mounted() {
        this.getMedHistory()
    },

    
}
</script>
<style scoped>
.medical-topic-title{
    background-color:#0692a0;
    color: white;
    line-height: 35px;
    height: 35px;
    border-bottom: 1px solid white;
    cursor: pointer;
}
.checkbox-inline-container{
    display: inline-block;
    min-width: 180px;
 
}
.subtopic-title{
    font-weight: 600;
    padding: 5px;
    background-color: #def2f8;
}
.subtopic-title-add{
    font-weight:300;
    font-size: 14px;
}
.subtopic-item-container{
    padding: 5px 20px;
    text-align: left;
    border-bottom: 1px solid#a3b5be;
    background-color: white;
}  
.check-complete{
    float: right;
    padding-right: 20px;
    color:#00d9ff;
}
.med-item-row{
    border-bottom: 1px solid rgb(137, 137, 151);
    margin-bottom: 5px;
}
.med-item-input-row{
    text-align: center;
    padding: 10px;
}
.med-survey-med-item{
    display: inline-block;
    padding: 5px 15px;
    min-width: 52%;
    text-align: right;
}  
.med-survey-input{
    margin-left: 10px;
} 
.small-input{
    width: 75px;
}
.radiobutton-container{
    display: inline-block;
    vertical-align: top;
    padding-right: 5px;
}
.radio-button-container{
    padding: 5px;
}
.note-input{
    width: 80%;
    height: 100px;
}
.input-container{
    text-align: center;
}
.note-title{
    font-weight: 500;
    font-size: 14px;
    color: #036a97;
}
.fa-trash-alt{
    cursor: pointer;
}
.note-container{
    width: 90%;
    border: 1px solid #036a97;
    padding: 5px;
    text-align: left;
    margin: 5px;
}
.subtopic-item-answer{
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    max-width: 100%;
}
.subtopic-item-questions{
    font-size: 14px;
    height: 35px;
    line-height: 30px;
}
.question-input{
    width: 400px;
    position: relative;
    top: 5px;
    max-width: 90%;
}
.subtopic-underline{
    position: relative;
    bottom: -8px;
    border-top: 1px solid #8897a2;
}
.subtopic-saved-answer{
    display: inline-block;
    font-weight: 600;
    position: relative;
    top: 5px;
}
.subtopic-item-question{
    display: inline-block;
    position: relative;
    top: 8px;
}

@media(max-width: 1080px) {
    .subtopic-item-questions{
        height: auto;
    }
}
.centered {
    text-align: center;
}
.perscription-saved-answer{
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 600;
}
.perscription-saved-note{
    padding: 5px 20px;
    font-size: 16px;
}
.perscription-item-row{
    margin-bottom: 5px;
    text-align: center;
}
</style>