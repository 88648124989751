import BookingForm from './components/BookingForm'
import ServiceForm from './components/ServicesForm'
import StaffAdd from './components/staff/StaffAdd'
import ClientAdd from './components/client/ClientAdd'
import ClientUpdate from './components/client/ClientUpdate'
import ClientFind from './components/client/ClientFind'
import Rooms from './components/Rooms'
import Schedule from './components/Schedule'
import Invoice from './components/invoices/Invoice'
import InvoiceId from './components/invoices/InvoiceId'
import InvoicePromotion from './components/invoices/InvoicePromotion'
import ProductAdd from './components/suppliesproducts/ProductAdd'
import SupplyAdd from './components/suppliesproducts/SupplyAdd'
import BlockedTime from './components/BlockedTime'
import BookingInfo from './components/BookingInfo'
import ServiceTypeAdd from './components/ServiceTypeAdd'
import BusinessAdd from './components/business/BusinessAdd'
import BusinessFeatures from './components/business/BusinessFeatures'
import PromotionAdd from './components/PromotionAdd'
import SaleAdd from './components/SaleAdd'
import StaffSchedule from './components/staff/StaffSchedule'
import StaffScheduleUpdate from './components/staff/StaffScheduleUpdate'
import PersonalSchedule from './components/staff/StaffScheduleId'
import StaffProfile from './components/staff/StaffProfile'
import StaffUpdate from './components/staff/StaffUpdate'
import Login from './components/signin/Login'
import UserAdd from './components/UserAdd'
import BusinessHours from './components/business/BusinessHours'
import BusinessHoursUpdate from './components/business/BusinessHoursUpdate'
import Certifications from './components/Certifications'
import Reports from './components/reports/Reports'
import ProductSupplyPurchase from './components/suppliesproducts/PurchaseSuppliesProducts'
import Distributors from './components/suppliesproducts/Distributors'
import Orders from './components/suppliesproducts/Orders'
import PasswordReset from './components/signin/PasswordReset'
import AddNewLocation from './components/business/LocationAddNew'
import Welcome from './components/intro/WelcomePage'
import TermsAgree from './components/intro/TermsAgree'
import Introduction from './components/intro/Introduction'
import BusinessLogin from './components/signin/NewBusinessLogin'
import ExpensesAdd from './components/expenses/ExpensesAdd'
import ExpensesAll from './components/expenses/ExpensesAll'
import DailyReconcile from './components/reports/DailyReconcile'
import PTOForm from './components/PTOForm'
import MedOnboard from './components/MedOnboardForm'
import DailyIncome from './components/reports/DailyIncomeReport'
import StaffVacations from './components/staff/StaffVacations'


const routes = [
    { path: '/', component: Login },
    { path: '*', component: Login },
    {
        name: 'AddBooking',
        path: '/addbooking',
        component: BookingForm,
        props: { bookingtype: 'ADD' }
    },
    {
        name: 'UpdateBooking',
        path: '/updatebooking',
        component: BookingForm,
        props: { bookingtype: 'UPDATE' }
    },
    {
        name: 'BookingInfo',
        path: '/bookinginfo',
        component: BookingInfo,
    },
    {
        name: 'UpdatePTO',
        path: '/updatepto',
        component: PTOForm,
    },
    {
        name: 'AddService',
        path: '/addService',
        component: ServiceForm
    },
    {
        name: 'AddStaff',
        path: '/addStaff',
        component: StaffAdd
    },
    {
        name: 'StaffSchedule',
        path: '/staffSchedule',
        component: StaffSchedule
    },
    {
        name: 'StaffScheduleUpdate',
        path: '/staffScheduleUpdate',
        component: StaffScheduleUpdate
    },
    {
        name: 'StaffUpdate',
        path: '/staffUpdate',
        component: StaffUpdate
    },
    {
        name: 'StaffVacations',
        path: '/staffVacations',
        component: StaffVacations
    },
    {
        name: 'PersonalSchedule',
        path: '/personalSchedule',
        component: PersonalSchedule
    },
    {
        name: 'AddClient',
        path: '/addClient',
        component: ClientAdd
    },
    {
        name: 'UpdateClient',
        path: '/updateClient',
        component: ClientUpdate
    },
    {
        name: 'FindClient',
        path: '/findClient',
        component: ClientFind
    },
    {
        name: 'AddRoom',
        path: '/addRoom',
        component: Rooms
    },
    {
        name: 'Schedule',
        path: '/schedule',
        component: Schedule
    },
    {
        name: 'Invoice',
        path: '/invoice',
        component: Invoice
    },
    {
        name: 'InvoicePromotion',
        path: '/invoicePromotion',
        component: InvoicePromotion
    },
    {
        name: 'InvoiceId',
        path: '/invoiceId',
        component: InvoiceId
    },
    {
        name: 'InvoicePurchase',
        path: '/invoicePurchase',
        component: InvoicePromotion
    },
    {
        name: 'AddProduct',
        path: '/addProduct',
        component: ProductAdd
    },
    {
        name: 'AddBlockedTime',
        path: '/addBlockedTime',
        component: BlockedTime
    },
    {
        name: 'AddServiceType',
        path: '/addServiceType',
        component: ServiceTypeAdd
    },
    {
        name: 'AddBusiness',
        path: '/addBusiness',
        component: BusinessAdd
    },
    {
        name: 'BusinessFeatures',
        path: '/businessFeatures',
        component: BusinessFeatures
    },
    {
        name: 'AddPromotion',
        path: '/addPromotion',
        component: PromotionAdd
    },
    {
        name: 'AddSale',
        path: '/addSale',
        component: SaleAdd
    },
    {
        name: 'AddUser',
        path: '/addUser',
        component: UserAdd
    },
    {
        name: 'Login',
        path: '/Login',
        component: Login
    },
    {
        name: 'PasswordReset',
        path: '/PasswordReset',
        component: PasswordReset
    },
    {
        name: 'UpdateProfile',
        path: '/updateProfile',
        component: StaffProfile
    },
    {
        name: 'BusinessHours',
        path: '/businessHours',
        component: BusinessHours
    },
    {
        name: 'UpdateBusinessHours',
        path: '/updateBusinessHours',
        component: BusinessHoursUpdate
    },
    {
        name: 'Certifications',
        path: '/certifications',
        component: Certifications
    },
    {
        name: 'Reports',
        path: '/reports',
        component: Reports
    },
    {
        name: 'DailyIncome',
        path: '/dailyIncome',
        component: DailyIncome
    },
    {
        name: 'AddSupply',
        path: '/addSupply',
        component: SupplyAdd
    },
    {
        name: 'AddExpense',
        path: '/addExpense',
        component: ExpensesAdd
    },
    {
        name: 'AllExpenses',
        path: '/allExpenses',
        component: ExpensesAll
    },
    {
        name: 'DailyReconcile',
        path: '/dailyReconcile',
        component: DailyReconcile
    },
    {
        name: 'PurchaseProductSupply',
        path: '/purchaseProductSupply',
        component: ProductSupplyPurchase
    },
    {
        name: 'Distributors',
        path: '/distributors',
        component: Distributors
    },
    {
        name: 'Orders',
        path: '/orders',
        component: Orders
    },
    {
        name: 'PasswordReset',
        path: '/PasswordReset',
        component: PasswordReset
    },
    {
        name: 'AddLocation',
        path: '/addLocation',
        component: AddNewLocation
    },
    {
        name: 'Welcome',
        path: '/welcome',
        component: Welcome
    },
    {
        name: 'TermsAgree',
        path: '/termsAgree',
        component: TermsAgree
    },
    {
        name: 'Introduction',
        path: '/introduction',
        component: Introduction
    },
    {
        name: 'BusinessSignup',
        path: '/businessSignup',
        component: BusinessLogin
    },
    {
        name: 'MedOnboard',
        path: '/medOnboard',
        component: MedOnboard
    },
    {
        name: 'MedSurvey',
        path: '/medSurvey',
        component: MedOnboard
    },
    

    


];

export default routes;