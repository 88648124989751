<template>
    <div>
        <div class="camera-display-container" v-if="showCapture">           
            <canvas  ref="canvas" id="canvas" 
                width="640" height="480" 
                :style="getStatus()" 
                v-if="showCanvas"                
                 ></canvas>
            

        </div>
        <div class="receipt-image-container" v-show="showReceiptImage">
            <img v-bind:src="`${imageURL}${refImage}.jpg`" class="receipt-image" />
        </div>
        <div class="capture-buttons-panel" v-if="showCapture">
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" />
        </div>
        
        <input id="image-capture" name="image-capture" type="file" accept="image/*" capture="environment" style="display:none" @change="imageCaptured($event)"> 
    </div>
</template>

<script>
import ButtonBar from './ButtonBar'
import Globals from '../../utilities/globals'

export default {
    name: 'CaptureOnly',
    data() {
        return {
            showCapture: false,
            showCanvas: false,
            showReceiptImage: false,
            captures: [],
            captureIndex: 0,
            constraints:{video: {
                facingMode: {ideal:'environment'},
                audio: false
            }},
            canvas: document.querySelector('canvas'),
            canvasData: {},
            ctx: '',
            currentImage: '',
            canvasActive: false,
            context: null,
            heightRatio: 1,
            buttonList: ['Save','Delete'],
            buttonClicked:{},
            imageURL: Globals.imageURL,
            
        }
    },
    props:[
        'takeImage',
        'showImage',
        'refImage'
    ],
    components:{
        ButtonBar
    },
    methods: {
        capture() { 
            window.console.log('CAPTURE')          
            this.showCanvas = true
            setTimeout(() => {
                this.canvas = document.getElementById('canvas')
                let ctx = this.canvas.getContext("2d")
                this.canvasData.width = this.canvas.width
                this.canvasData.height = this.canvas.height
                window.console.log('CAP', this.canvas.width, this.canvas.height, ctx.width, ctx.height)
                // const image = this.canvas.toDataURL('image/jpeg', 0.6)
                // this.saveImage(image,'NORM')
                this.captureIndex = this.captures.length
                this.showEdit = true
                this.canvasActive = true                
            },100)
            setTimeout(() => {
                this.heightRatio = Number(document.getElementsByClassName('group-images')[0].width)/100
                window.console.log('IMAGE SIZE', document.getElementsByClassName('group-images')[0].width)
                this.showVideo = false
            },100)            
        },
        stream() {
            window.console.log('STREAM')
            document.getElementById('image-capture').click()
            // this.showVideo = true
            // this.showCanvas = false
            // this.showEdit = false
            // navigator.mediaDevices.getUserMedia(this.constraints).then(this.handleSuccess).catch(this.handleError)    
        },
        imageCaptured(e) {
            this.showCapture = true
            this.showCanvas = true
            this.drawOnCanvas(e.target.files[0])            
        },
        drawOnCanvas(file) {
            const reader = new FileReader();
            let vm = this
            reader.onload = function (e) {
                let dataURL = e.target.result
                vm.canvas = document.getElementById('canvas');
                let ctx = vm.canvas.getContext('2d')
                let img = new Image();

                window.console.log('CAP', vm.canvas.width, vm.canvas.height, ctx.width, ctx.height)

                img.onload = function() {
                    vm.canvas.width = img.width;
                    vm.canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                };

                img.src = dataURL;
                setTimeout(() => {
                    // let image = vm.canvas.toDataURL('image/jpeg', 0.6)
                    // vm.$store.dispatch('uploadImage',image)
                    // vm.saveImage(image,'NORM')
                    // vm.captures.push(image)
                    vm.captureIndex = this.captures ? this.captures.length : 0
                    vm.showEdit = true
                    vm.canvasActive = true
                }, 1000);
                
            };
            
            reader.readAsDataURL(file);
            
        },
        getStatus() {
            if (!this.showVideo && !this.showSplit) {
                return {zIndex: 3}
            }
            return {zIndex: 0}            
        },
        closeWindow() {          
            this.showCapture = false
            this.showCanvas = false
        
            this.showReceiptImage = false
            // this.takeImage = false
            this.$emit('close-edit', false)
        },
        deleteImage() {
            // const deleteImageId = this.captures[this.captureIndex].id
            window.console.log('DELETE IMAGE', this.captureIndex, this.captures, this.selectedImageId)
            this.clearCanvas()
            this.captures.splice(this.captureIndex,1)
            this.$emit('close-edit', false)
        },
        clearCanvas() {
            let canvas = document.getElementById('canvas') 
            if (canvas) {
                let ctx = canvas.getContext("2d")
                ctx.fillStyle= 'black'
                ctx.fillRect(0,0,canvas.width,canvas.height)
            }           
            
        },
        saveImage() {            
            const image = this.canvas.toDataURL('image/jpeg', 0.4)
            const data = {
                type: 'norm',
                image: image
            }
            
            window.console.log('SAVE IMAGE', data, image)
            this.$store.dispatch('saveImage',data).then(response => {
                window.console.log('DATA SAVED', response)
                if (response.result === 'SUCCESS') {
                    this.selectedImageId = response.id
                    window.console.log('DATA SAVED SUCCESSFULLY',response.ref)
                    this.currentImage = response.ref
                    this.$emit('image-saved',response.ref)
                    this.closeWindow()
                    // EventBus.$emit('IMAGE_ADDED',response.ref)
                    // this.getImagesDB()
                }
            })
        },

    },
    watch: {
        takeImage() {
            window.console.log('IMAGE CAPTURE', this.takeImage)
            if(this.takeImage) {
                this.stream()
                this.buttonList = ['Save','Delete']
            }
        },
        showImage() {
            this.showReceiptImage = this.showImage
            if(!this.takeImage && this.showImage){
                window.console.log('SHOW CURRENT IMAGE',this.currentImage,this.refImage )
                this.showCapture = true
                this.buttonList = ['Delete','Close']
            }
        },
        buttonClicked() {
            window.console.log('this.buttonClicked.name',this.buttonClicked.name)
            if (this.buttonClicked.name === 'Save') {
                window.console.log('clicked save')
                this.saveImage()
            }
            if (this.buttonClicked.name === 'Delete') {
                this.clearCanvas()
                this.closeWindow()
            }
            if (this.buttonClicked.name === 'Close') {
                this.showReceiptImage = false
                this.closeWindow()
            }
        }
    }
}
</script>

<style scoped>
.camera-display-container{
    position: relative;
    margin-bottom: 10px;
    background-color:white;
} 
#canvas {
    width: 100%;
    max-width: 640px;
    background-color: #000000;
    position: relative;
    border: 2px solid black;
    z-index: 1;
}
.edit-panel-buttons{
    vertical-align: top;
    display: inline-block;
    padding-top: 2px;
} 
.receipt-image{
    width: 90%;
    margin: 0 5%;
    border: 1px solid#2c3e50;
}



    
</style>