<template>
    <div>
        <div class=slide-out-container :class="{'slide-out': showSlideOut}">
            <!-- <div class="open-close-button" @click="inOut">
                <div class="in-out-button">
                    <i class="fas fa-chevron-right" v-if="showSlideOut"></i>
                    <i class="fas fa-chevron-left" v-if="!showSlideOut"></i>
                </div>
            </div> -->
            <div class="nav-subpanel" v-if="!showSubNav" > 
                <div class="navigation-row" >{{'Settings' | translate}}
                    <div class="close-slideout-button" @click="slideOutValue = false">+</div>   
                </div>                  
                <div class="nav-subsection-title" @click="showSetupGroup=!showSetupGroup" v-if="checkPermission('Setup')">
                    {{'Setup' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showSetupGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showSetupGroup"></i>
                    </div>
                </div>
                <div v-show="showSetupGroup">
                    <div class="nav-subitem-container" @click="showSubNav = false" v-for="(nav,index) in navData.Setup" :key="'set-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Setup[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>                               
                <!-- SCHEDULE GROUP -->
                <div class="nav-subsection-title" @click="showScheduleGroup=!showScheduleGroup" v-if="checkPermission('Schedule')">
                    {{'Schedule' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showScheduleGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showScheduleGroup"></i>
                    </div>
                </div>
                <div v-show="showScheduleGroup">
                    <div v-for="(nav,index) in navData.Schedule" :key="'sch-'+index">
                        <div class="nav-subitem" @click="showSubNav = false"  v-if="permissions[role].Schedule[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- STAFF GROUP -->
                <div class="nav-subsection-title" @click="showStaffGroup=!showStaffGroup" v-if="checkPermission('Staff')">
                    {{'Staff' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showStaffGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showStaffGroup"></i>
                    </div>
                </div>
                <div v-show="showStaffGroup">
                    <div v-for="(nav,index) in navData.Staff" :key="'sta-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Staff[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- CLIENT GROUP -->
                <div class="nav-subsection-title" @click="showClientGroup=!showClientGroup" v-if="checkPermission('Clients')">
                    {{'Clients' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showClientGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showClientGroup"></i>
                    </div>
                </div>
                <div v-show="showClientGroup">
                    <div v-for="(nav,index) in navData.Clients" :key="'cli-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Clients[nav.routeName]" >
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- PROMO GROUP -->
                <div class="nav-subsection-title"  @click="showPromotionGroup=!showPromotionGroup" v-if="checkPermission('Promotions')">
                    {{'Promotions/Purchases' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showPromotionGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showPromotionGroup"></i>
                    </div>
                </div>
                <div v-show="showPromotionGroup">
                    <div v-for="(nav,index) in navData.Promotions" :key="'pro-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Promotions[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- INVENTORY GROUP -->
                <div class="nav-subsection-title" @click="showInventoryGroup=!showInventoryGroup" v-if="checkPermission('Inventory')">
                    {{'Inventory' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showInventoryGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showInventoryGroup"></i>
                    </div>
                </div>
                <div v-show="showInventoryGroup">
                    <div v-for="(nav,index) in navData.Inventory" :key="'inv-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Inventory[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- EXPENSES -->
                <div class="nav-subsection-title" @click="showExpensesGroup=!showExpensesGroup" v-if="checkPermission('Expenses')">
                    {{'Expenses' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showExpensesGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showExpensesGroup"></i>
                    </div>
                </div>
                <div v-show="showExpensesGroup">
                    <div v-for="(nav,index) in navData.Expenses" :key="'rep-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Expenses[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- REPORTS -->

                <div class="nav-subsection-title" @click="showReportsGroup=!showReportsGroup" v-if="checkPermission('Reports')">
                    {{'Reports' | translate}}
                    <div class="title-indicator">
                        <i class="fas fa-chevron-down" v-if="!showReportsGroup"></i>
                        <i class="fas fa-chevron-right" v-if="showReportsGroup"></i>
                    </div>
                </div>
                <div v-show="showReportsGroup">
                    <div v-for="(nav,index) in navData.Reports" :key="'rep-'+index">
                        <div class="nav-subitem" @click="showSubNav = false" v-if="permissions[role].Reports[nav.routeName]">
                            <router-link :to="{ name: nav.routeName }">
                                {{nav.label | translate}}
                            </router-link>
                        </div>
                    </div>
                </div>
            
            
            </div>
        </div>   
    </div>
</template>

<script>
import NavData from '../../data/navigation.json'
import Permissions from '../../data/permissions.json'
import store from '../../store/store'

export default {
    name: 'SlideOutPanel',
    data() {
        return {
            
            showSubNav: false,
            showClientNav: false,
            showNavItems: true,
            showSetup: false,
            showStaffGroup: false,
            showScheduleGroup: false,
            showClientGroup: false,
            showPromotionGroup: false,
            showInventoryGroup: false,
            showExpensesGroup: false,
            showReportsGroup: false,
            showSetupGroup: false,
            showStaffScheduleGroup: false,
            navData: NavData,
            slideOutValue: this.slideOut,
            permissions: Permissions,
            store,
            role: this.$store.getters.user.role           
        }
    },
    props: [
        'slideOut'
    ],
    methods:{
        checkPermission(section) {
            this.$store.getters.user.role
            if (!this.role) {
                this.role = 'staff'
            }
            return Object.keys(this.permissions[this.role][section]).some(item => this.permissions[this.role][section][item] == true)
        },
        setRole() {
            this.role = this.$store.getters.user.role ? this.$store.getters.user.role : 'staff'
        }
    },
    computed: {
        showSlideOut() {
            this.$emit('show-hide-settings', this.slideOutValue)
            return this.slideOutValue
        }
    },
    watch: {
        slideOut() {
            this.slideOutValue = this.slideOut
        }
    },
    mounted() {
        this.setRole()
    },
}
</script>

<style scoped>
.nav-subpanel{
    position: absolute;
    top: 0;
    Right: 0;
    background-color: white;
    border: 1px solid #6386ab;
    min-width: 200px;
}
.nav-subitem {
    font-size: 14px;
    border-bottom: 1px solid gray;
    padding: 5px 0;
}
/* .nav-subitem:last-child{
    border-bottom:none;
} */
.navigation-container{
    position: relative;
    z-index: 5
}
.navigation-row{
    text-align: center;
    background-color: #b82222;
    color: white;
    font-weight: 600;
    margin: 0;
    min-height: 23px;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
}
.nav-link-item{
    color:white;
    display: inline-block;
}
router-link{
    text-decoration: none;   
}
a{
    text-decoration: none;
    font-weight: 600;
    color: gray;
}

.tasks-title-bar{
    background-color: #a360bf;
    text-align: center;
}
.tasks-title{
    color: white;
    font-size: 13px;
    display: inline-block;
    font-weight: 600;
}
.tasks-close-button{
    font-weight: 800;
    font-size: 13px;
    padding: 3px 10px 0 10px;
    cursor: pointer;
    float: right;
    background-color: #1c2669;
    height: 19px;
    color: white;
}
.slide-fade-enter-active {
  transition: all .9s ease;
}
.slide-fade-leave-active {
  transition: all .8s 
  /* cubic-bezier(1.0, 0.5, 0.8, 1.0); */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(210px);
  /* opacity: 0; */
}
.nav-subsection-container{
    max-height: 0;
    transition: all .8s ;
    border-bottom: 2px solid #9b88e8;
    background-color: #f6f6f7;
}
.nav-active{
    max-height: 500px;    
    transition: all .8s ;
}
.title-indicator{
    display: inline-block;
    position: relative;
    top: 1px;
    left: 3px;
}
.nav-subsection-title {
    font-size: 14px;
    text-align: center;
    background-color: #e1ebf7;
    padding: 4px 5px;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 2px solid #9facd2;
    
}
.nav-subsection-item{
    font-size: 14px;
    /* border-bottom: 1px solid gray; */
    /* padding: 5px; */
    transition: all .5s; 
    position: relative;
    left: 200px;
}
.item-active{
    transition: all .5s ;
    left: 0;
}
.nav-task-item{
    display: inline-block;
}
.admin-title{
    background-color: #d5caff;
}
.open-close-button{
    width: 40px;
    height: 40px;
    background-color: #4185d8;
    position: absolute;
    top: 0;
    left: -223px;
    border-radius: 20px;
    border: 1px solid black;
    opacity: .6;
}

.slide-out-container{
    position: absolute;
    display: none;
    top: 23px;
    right: -502px;
    z-index: 5;
    transition: all 1.1s ease;
}
.slide-out {
    right: 0;
    transition: all 1.1s ease;
    display: block;
}
.in-out-button{
    position: absolute;
    top: 10px;
    left: 7px;
}
.close-slideout-button{
    float: right;
    width: 30px;
    line-height: 23px;
    cursor: pointer;
    font-size: 25px;
    rotate: 45deg;
}

</style>