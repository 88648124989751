<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Prefix' | translate}} </label> 
            <Dropdown 
                v-on:item-selected="userPrefix = $event" 
                v-bind:selected="user.prefix" 
                v-bind:optioncontent="allPrefixes"
                placeholder="Select Prefix">
            </Dropdown>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'First Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.firstname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.firstname">*{{'You need to enter a' | translate}} {{'First Name' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Last Name' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.lastname"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Last Name' | translate}}</div>

         <!-- ALTERNATE NAMES & TITLE -->
        <div class="form-input-row">
            <label  class="form-input-label">{{'Display Name' | translate}}:<span class="required-entry">*</span> </label>
            <div class="form-input-container">  
                <input  v-model="user.displayname"/>
                <InfoButton
                    :infocontent="'INFO_CONTENT.DISPLAY_NAME'"
                    :positiontop="'-16px'"
                    :positionleft="'10px'"
                />
            </div>
        </div>       
        <div class='missing-selection-message' v-if="showError && !user.lastname">*{{'You need to enter a' | translate}} {{'Display Name' | translate}}</div>

        <!-- CONTACT INFO -->
        <PhoneNumber 
            v-on:number-updated="user.phone = $event" 
            v-bind:existingnumber="staffInfo.phone"
            :required="true" />
        <div class='missing-selection-message' v-if="showError && !user.phone">*{{'You need to enter your' | translate}} {{'Phone Number' | translate}}</div>

        <div class="form-input-row">
            <label class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span></label> 
            <input class="input-wide" v-model="user.email"/>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.email">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>
        <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Street' | translate}}:</label> 
            <input class="input-wide"  v-model="user.street"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Suite' | translate}}/{{'Apt' | translate}}/{{'Unit' | translate}}:</label> 
            <input class="input-narrow"  v-model="user.address2"/>
        </div>
        <ZipLookup 
            v-on:zip-found="zipInfo = $event"
        />
        <!-- START DATE -->
        <div class="form-input-row">
            <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
            <div class="date-selection-container">
                <input class="input-wide" v-model="selectedStartDate" v-on:focus="startFocus"/>
                <div class="inline-calendar-picker" v-if="showStartCalendar">
                    <CalendarMini 
                        v-on:date-selected="scheduleStartDate = $event"/>
                </div>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showError && !selectedStartDate">*{{'You need to enter a' | translate}} {{'Start Date' | translate}}</div>

        <!-- BOOKABLE -->
        <div class="form-bookable-checkbox-row">
            <label class="form-input-label">{{'Bookable' | translate}}:</label> 
            <div class="form-input-container"> 
                
                <div class="checkbox-item">
                    <CheckBox                     
                        v-bind:cbmodel="user.bookable"
                        v-on:item-selected="user.bookable = $event"
                        />
                </div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.BOOKABLE'"
                    :positiontop="'-16px'"
                    :positionleft="'35px'"
                />
            </div>
        </div>

        <div class="form-input-row">
            <label for="type" class="form-input-label">{{'Title' | translate}} </label> 
            <input id="type" v-model="user.title"/>
        </div>
        <!-- PERMISSIONS --><div class="form-input-row">
        <label class="form-input-label">{{'Role' | translate}}:</label>             
             <Dropdown 
                v-on:item-selected="selectedRole = $event.value, user.role= $event.value" 
                v-bind:selected="selectedRole" 
                v-bind:optioncontent="roles"
                placeholder="Staff">
            </Dropdown>
        </div>
        <Permissions 
                v-if="showPermissionInfo"
                :permissionrole="selectedRole"
                v-on:update-permissions="user.permissions = $event"
                
            />
            <div class="profile-update-link" @click="showPermissionInfo = !showPermissionInfo">
                <span v-if="!showPermissionInfo">{{'Edit Permissions' | toLowerCase}}</span>
                <span v-if="showPermissionInfo">{{'Close Permissions' | toLowerCase}}</span>
            </div>

            <!-- PAY INFO -->
        <hr>
        <div class="title-row">{{'Pay Info' | translate}}</div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Pay Amt' | translate}}:</label> 
            <InputCurrency
                v-on:amount-entered="user.payrate = $event"
                :amount="'0'"
             ></InputCurrency>
             <Dropdown 
                v-on:item-selected="user.paytype = $event.name" 
                v-bind:selected="user.paytype" 
                v-bind:optioncontent="salaryTypes"
                placeholder="Select Type">
            </Dropdown>
        </div>
        
        <div class="form-input-row">
            <label class="form-input-label">{{'Commission Services' | translate}} (%):</label> 
            <input class="input-narrow"  v-model="user.commissionservices"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Commission Products' | translate}} (%):</label> 
            <input class="input-narrow"  v-model="user.commissionproducts"/>
        </div>
        <!-- PERSONAL INFO -->
        <div class="form-input-row">
            <label class="form-input-label">{{'Social Security Number' | translate}}:</label> 
            <input class="input-medium"  v-model="user.ssnumber"/>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Date of Birth' | translate}} <span class="dob-format">({{'MM' | translate}}/{{'DD' | translate}}/{{'YYYY' | translate}})</span>:</label> 
            <input class="input-medium"  v-model="formattedDate" @input="formatDate"/>
        </div>
        <!-- ADDRESS INFO -->
        
        <!-- RESTRICTIONS -->

        <div class="topic-title">{{'Application Use Restrictions' | translate}}</div> 

        <div class="form-input-row">
            <label for="type" class="form-input-label"></label>
            <div class="form-input-container"> 
                <div class="checkbox-item">
                    <CheckBox                     
                        v-bind:checkBoxLabel="'Restrict To Approved Devices'"
                        v-bind:cbmodel="user.devicerestrict"
                        v-bind:labelStyle="'label-long'"
                        v-on:item-selected="user.devicerestrict = $event"
                        />
                </div>
            </div>
        </div>
        <div class="form-input-row">
            <label for="type" class="form-input-label"></label> 
            <div class="form-input-container">
                <div class="checkbox-item">
                    <CheckBox                     
                        v-bind:checkBoxLabel="'Restrict To On-Site Only'"
                        v-bind:labelStyle="'label-long'"
                        v-bind:cbmodel="user.locrestrict"
                        v-on:item-selected="user.locrestrict = $event"
                        />
                </div>
            </div>
        </div>
        <div class="line-row" v-if="allCertifications.length"></div>
        <!-- CERTIFICATIONS -->
        <div class="title-row" v-if="allCertifications.length">{{'Certifications' | translate}}</div>
        <div class="form-checkbox-row" v-if="allCertifications.length">
            <div class="form-input-container centered"> 
                <div class="checkbox-item" v-for="(cert, index) in allCertifications" v-bind:key="'cert' + index">
                    <CheckBox 
                        v-bind:checkBoxLabel="cert.name"
                        :cbmodel="checkCertifications(cert.id)"
                        v-on:item-selected="certifications[cert.id] = $event" 
                        />
                </div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.CERTIFICATIONS'"
                    :positiontop="'-2px'"
                    :positionleft="'10px'"
                />
            </div>
        </div>
        <hr>
        

        
        
        <!-- LOCATIONS -->
        <div class="title-row">{{'Locations' | translate}}:<span class="required-entry">*</span></div> 
        <div class="form-checkbox-row" v-if="allLocations.length > 1">
            
            <div class="locations-container" :class="{'show-schedule':showSchedules} ">
                <div class="locations-checkbox-item" 
                    v-for="(location, index) in allLocations" 
                    v-bind:key="'cert' + index"
                    >
                    <CheckBox 
                        v-bind:checkBoxLabel="location.name"
                        v-on:item-selected="locations[location.id] = $event,ckSch = new Date()" 
                        v-bind:cbmodel="locations[location.id]"
                        :cbref="location.id"
                        />
                    <div class="radiobutton-container">
                        <RadioButton 
                            :rbLabel="'Primary'"
                            :rbStatus="applyTo[index].status"
                            :rbRef="location.id"
                            v-on:rb-selected="rbWatcher = $event"
                        />
                    </div>
                    <WeekSchedule
                        class="staff-week-schedule"
                        v-if="locations[location.id]"
                        :scheduletitle="'Work Hours'"
                        :closename="'Off'"
                        v-on:schedule-updated="scheduleInfo[location.id] = $event"
                        :existingschedule="location.schedule"
                    />
                </div>
                
            </div>
        </div>
        <WeekSchedule
            v-if="allLocations.length === 1"
            :scheduletitle="'Work Hours'"
            :locationname="allLocations[0].name"
            :closename="'Off'"
            v-on:schedule-updated="scheduleInfo[allLocations[0].id] = $event"
            :existingschedule="allLocations[0].schedule"
        />
        <!-- COLOR BLOCKS -->
        <div class="form-bookable-checkbox-row color-row" >
            <label class="form-input-label color-label">{{'Display Color' | translate}}:<span class="required-entry">*</span></label> 
            <div class="form-input-container"> 
                <div class="color-block-container">
                    <ColorBlocks v-on:color-selected="user.displaycolor = $event"/>
                </div>
                <InfoButton
                    :infocontent="'INFO_CONTENT.COLOR_BLOCKS'"
                    :positiontop="'-2px'"
                    :positionleft="'0'"
                />
            </div>
        </div>
        <div class='missing-selection-message' v-if="showError && !user.displaycolor"><br>*{{'You need to select a' | translate}} {{'Display Color' | translate}}</div>

       
        
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{successNotice | translate}}</div>
        </div> 
        <div class="form-input-row" v-show="showEmailMatch">
            <div class="missing-selection-message">{{'ERROR' | translate}}!! {{'Email already exists for' | translate}}: {{user.firstname}}  {{user.lastname}} <br> {{'Staff must have unique email' | translate}}</div><br>        
        </div> 
        
    </div>
</template>

<script>
import store from '../../store/store'
import ZipLookup from '../utility/ZipLookup'
import CheckBox from '../utility/CheckBox'
import PhoneNumber from '../utility/PhoneNumber'
import Dropdown from '../utility/Dropdown'
import RadioButton from '../utility/RadioButton'
import ButtonBar from '../utility/ButtonBar'
import ColorBlocks from '../utility/ColorBlocks'
import WeekSchedule from '../utility/WeekSchedule'
import Constants from '../../utilities/constants'
import InfoButton from '../utility/InfoButton'
import CalendarMini from '../utility/CalendarMini'
import Globals from '../../utilities/globals'
import InputCurrency from '../utility/InputCurrency'
import Permissions from '../utility/Permissions'
import PermissionData from '../../data/permissions'
import EventBus from '../../utilities/eventBus'

export default {
    name: 'StaffAdd',
    data() {
        return {
            user: {locations:[],role:'staff',locrestrict: false,devicerestrict:false},
            userPrefix: '',
            userStatus: '',
            staffInfo: {},
            showError: false,
            showSuccess: false,
            showEmailError: false,
            showEmailMatch: false,
            showSchedules: false,
            showPermissionInfo: false,
            store,
            allCertifications: [],
            certifications: {},
            zipInfo: {},
            formattedDate: '',
            emailMatch: {},
            allLocations: [],
            locations:{},
            applyTo:[],
            rbWatcher: '',
            applyToPayType:{'hourly': false,'fixed': false,'commission': false},
            rbWatcherPayType: '',
            pageTitle: 'Add Staff',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            isUpdate: false,
            successNotice: 'Staff Successfully Added',
            scheduleInfo: {},
            allPrefixes: Constants.prefixes,
            salaryTypes: Constants.salaryTypes,
            roles: Constants.roles,
            ckSch: '',
            showWS: {},
            selectedStartDate: '',
            selectedRole:'',
            scheduleStartDate: {},
            showStartCalendar: false,
        }
    },
    components: {
       ZipLookup,
       CheckBox,
       PhoneNumber,
       Dropdown,
       RadioButton,
       ButtonBar,
       ColorBlocks,
       WeekSchedule,
       InfoButton,
       CalendarMini,
       InputCurrency,
       Permissions
    },
    methods: {
        verifyForm() {
            if (!this.user.permissions) {
                this.user.permissions = PermissionData[this.user.role]
            }
            window.console.log('SUBMIT NEW STAFF INFO', this.user)

            window.console.log('USER LOCATIONS', this.user.loctions,'USER INFO', this.user)
            this.showSuccess = false
            this.showError = false         
            if (!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone || !this.user.displayname || !this.user.displaycolor ) {
                window.console.log('ERROR FOUND')
                if (this.user.email) {
                    this.showEmailError = Globals.validateEmail(this.user.email) ? false : true
                }
                this.showError = true
            } else {
                if (this.user.email) {
                    this.showEmailError = Globals.validateEmail(this.user.email) ? false : true
                }
                if (!this.showEmailError) {
                    this.submitForm()
                }       
            }
        },
        startFocus(){
            window.console.log('SHOW CAL')
            this.showStartCalendar = true;
        },
        formatDate() {
            let cleaned = ('' + this.formattedDate).replace(/\D/g, '')

            if(cleaned.length===2) {
                this.formattedDate = cleaned + '/'
            }
            if(cleaned.length==4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}`
            }
            if(cleaned.length>4) {
                this.formattedDate = `${cleaned.substr(0,2)}/${cleaned.substr(2,2)}/${cleaned.substr(4,4)}`
            }
        },
        setScheduleInfo() {
            this.user.schedule = []
            if (this.scheduleInfo == {}) {
                window.console.log('NO SCHEDULE INFO')
                this.scheduleInfo.data = this.allLocations[0].data
                this.scheduleInfo.id = this.allLocations[0].id
                this.scheduleInfo.name = this.allLocations[0].name
                this.user.schedule.push(this.scheduleInfo)
            } else {
                Object.keys(this.scheduleInfo).forEach(locSch => {
                    window.console.log('EACH SCHUDLE LOCXXXXX', locSch)
                    let schedule = {}
                    schedule.id = Number(locSch) 
                    schedule.name = this.getLocationName(locSch)
                    schedule.data = this.scheduleInfo[locSch]
                    this.user.schedule.push(schedule)
                })
            } 
            
        },
        getLocationName(id) {
            let locName = ''
            this.allLocations.forEach(loc => {
                if (loc.id == id) {
                    locName = loc.name
                }
            })
            return locName
        },
        submitForm() {
            this.user.type = 'staff'
            this.user.businessid = this.$store.getters.user.businessid
            this.user.zip = this.zipInfo.zip
            this.user.city = this.zipInfo.city
            this.user.state = this.zipInfo.state
            this.user.dob = this.formattedDate ? this.formattedDate : null
            this.user.status = 'active'
            this.user.certifications = []
            // CHECK EMAIL TO SEE IF ALREADY EXISTS (NO DUPLICATE
            this.setUserLocations()
            this.setScheduleInfo()
            // NEED TO ADD THESE
            this.user.password = this.$store.getters.user.defaultpassword
            Object.keys(this.certifications).forEach(item => {                              
                if (this.certifications[item]) {
                    this.user.certifications.push(Number(item))
                }
            })
            this.showEmailMatch = false
            this.emailMatch = {}
            window.console.log('SUBMIT NEW STAFF INFO', this.user)
            //CHECK EMAIL TO SEE IF ALREADY EXISTS (NO DUPLICATE EMAILS AS USED LOGIN)
            EventBus.$emit('SHOW_SPINNER')
            this.$store.dispatch('addStaff', this.user).then(response => {   
                 EventBus.$emit('HIDE_SPINNER')                     
                if (response.result==='SUCCESS') {
                    this.showSuccess = true
                } else {                  
                    this.showEmailMatch = true
                }
            })                 
        },        
        getCertifications() {
            this.$store.dispatch('getCertifications',{id:this.$store.getters.user.businessid}).then( () => {
                this.allCertifications = this.$store.getters.allCertifications
            })
        },
        getLocations() {
            this.$store.dispatch('getLocations',{id:this.$store.getters.user.businessid}).then(response => {
                this.allLocations = response.data
                this.allLocations.forEach(location => {
                    const rbstatus = this.$store.getters.currentLocation.id === location.id? true : false
                    this.applyTo.push({id:location.id,status:rbstatus})
                    this.locations[location.id] = rbstatus
                    if (rbstatus) {
                        this.showSchedules = true
                    }
                })
                if (this.allLocations.length === 1) {
                    
                    this.user.locations = [this.allLocations[0].id]
                }
                // DEFAULT TO FIRST LOCATION BEING PRIMARY
                this.user.primarylocation = this.allLocations[0].id
                window.console.log('AFTER GET LOC', this.locations)
            })
        },
        ckExist() {
            let vm = this
            if(vm.$route.name === 'UpdateProfile') {
                vm.pageTitle = 'Update Profile'
                vm.isUpdate = true               
                if (vm.$store.getters.user.id) {
                    const allStaff =vm.$store.getters.allStaff
                    const staffNum = this.getStaffNum(allStaff)
                    Object.keys(allStaff[staffNum]).forEach(item => {
                        vm.user[item] = allStaff[staffNum][item]
                    })
                }                
            }
        },
        getStaffNum(allStaff) {  
            const userId = this.$store.getters.user.id 
            window.console.log('CK STAFF', allStaff)         
            for (let i = 0;i < allStaff.length; i++) {
                if (allStaff[i].userid === userId) {
                    this.staffInfo = allStaff[i]
                    return i
                }
            }           
        },
        checkCertifications(id) {
            if (this.staffInfo.certifications && this.staffInfo.certifications.indexOf(id) > -1) {
                return true
            }
            return false           
        },
        checkLocations(id) {
            window.console.log('CHECK LOC', id, this.locations)
            let vm = this
            Object.keys(vm.locations).forEach(loc => {
                window.console.log('EACH', id, loc, vm.locations[loc])
                if (vm.locations[loc]) {
                    window.console.log('RETURNING TRUE!!!', loc)
                    return true
                }
            })
            return  false
            // this.locations && this.locations.includes(id) ? true : false
        },
        updateStaff() {
            this.showSuccess = false           
            this.setUserLocations()

            this.user.dob = this.formattedDate ? this.formattedDate : null
            window.console.log('UPSTAFF', this.user, this.locations)
            this.$store.dispatch('updateStaff',this.user).then(response => {
                if (response.result === 'SUCCESS') {
                    this.staffNotice="Profile Successfully Updated"
                    this.showSuccess = true
                }
            })
            
        },
        setUserLocations() {
            // CHECK IF LOCATION CHECKS ADD OR SUBTRACTED FROM USER LOCATIONS
            const vm = this 
            window.console.log('LOCS', vm.locations)
            vm.user.locations = []
            Object.keys(vm.locations).forEach(loc => {
                if (vm.locations[loc]) {
                    // ADD TO USER LOCATION IF NOT EXIST
                    if ( !vm.user.locations || !vm.user.locations.includes(loc)) {
                        vm.user.locations.push(Number(loc))
                    }
                } 
            })
            window.console.log('LOCS DATA', vm.user.locations)
            this.setLocationData()
        },
        setLocationData() {
            this.user.locationsdata = []
            window.console.log('SET LOC DATA', this.user.locations)
            this.user.locations.forEach(id => {  
                window.console.log('ID', id)          
                this.allLocations.forEach(loc => {
                    window.console.log('DATA', loc.id, id)
                    if (loc.id == id) {
                        const locObj = {
                            id: loc.id,
                            name: loc.name,
                            primary: loc.id == this.user.primarylocation ? true : false,
                        }
                        window.console.log('MATCH', locObj, this.user.primarylocation)
                        this.user.locationsdata.push(locObj)
                    }
                })
            })
            window.console.log('USERS LOCATION DATA', this.user.locationsdata)
        },
        ckShowSch(id) {
            window.console.log('CK SHOW SCH', id)
            if (this.locations[id]) {
                return true
            }
            return false            
        }
    },
    mounted() {        
        this.ckExist()
        this.getCertifications()
        this.getLocations()
    },
    computed: {
        
    },  
    watch: {
        rbWatcher() {
            const resultArray = this.rbWatcher.split('~')
            this.applyTo.forEach(item => {
                if (item.id == resultArray[0]) {
                    item.status = resultArray[1]
                    if (resultArray[1]) {
                        this.user.primarylocation = resultArray[0]
                    }
                } else {
                    item.status = false
                }
            })
        },
        rbWatcherPayType() {
            const resultArray = this.rbWatcherPayType.split('~')
            Object.keys(this.applyToPayType).forEach(item => {
                this.applyToPayType[item] = false
            })
            this.applyToPayType[resultArray[0]] = Boolean(resultArray[1])
            if (resultArray[1]) {
                this.user.paytype = resultArray[0]
            }
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                if(this.isUpdate) {
                    this.updateStaff()
                } else {
                    this.verifyForm()
                }
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        userPrefix() {
            this.user.prefix = this.userPrefix.name
        },
        ckSch() {
            this.showSchedules = false
            Object.keys(this.locations).forEach(loc => {
                if ( this.locations[loc]) {
                    this.showSchedules = true
                }
            })
        },
        scheduleStartDate() {
            window.console.log('DATE PICKED', this.scheduleStartDate)
            this.selectedStartDate = this.scheduleStartDate.selectedDate
            this.user.startdate = Globals.formatDBDate(this.scheduleStartDate.day, this.scheduleStartDate.month,this.scheduleStartDate.year)         
            this.showStartCalendar = false;
            window.console.log('DATE PICKED', this.scheduleStartDate, this.user.startdate)
        },
        selectedRole(){
            window.console.log('SELECTED ROLE WATCH', this.selectedRole)
            this.showPermissionInfo = false;
        }
        
        
    }
}
</script>

<style scoped>
.form-checkbox-row{
    text-align: left;
    margin-top: -8px;
} 
.form-bookable-checkbox-row{
    text-align: left;
    margin-top: 0;
    margin-bottom: 17px;
}
.color-row{
    margin-bottom: 0;
}
.form-input-label{
    vertical-align: middle;
}
.dob-format{
    font-size: 12px;
} 
.locations-container{
    display: inline-block;
    padding: 6px 7px;
} 
.locations-checkbox-item{

}
.vertical{
    vertical-align: top;
    margin-top: 5px;
}
.radiobutton-container{
    display: inline-block;
    vertical-align: top;
}
.color-label{
    vertical-align: top;
    margin-top: 16px;
}
.color-block-container{
    display: inline-block;
    margin-top: 13px;
}
.show-schedule{
    padding-left: 23%;
}
.title-row{
    padding-bottom: 10px;
    font-weight: 600;
}
.profile-update-link{
    color: #1e7ea5;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-top: 1px solid;
    padding: 5px;
    margin: 0 20%;
}
.topic-title{
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}
.line-row{
    border-bottom: 1px solid rgb(121, 121, 151);
    margin: 1.5% 20% 2.5% 20%;
}
.centered{
    display: block;
    text-align: center;
}
</style>