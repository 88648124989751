<template>
<div>
    <AddClient 
            v-if="showAddClient"
            v-on:close-add-client="showAddClient=false,showImageEdit=false"
            class="add-client-component"
        />
    <div class="main-input-form-container" ref="main-container">
        <calendar v-show="false"></calendar>
        
        <div class="booking-form">
        <div class="form-title-row">{{bookingTitle | allCaps }}</div>
            <div>
                <div class="form-input-row search-ahead-row">
                    <div class="form-input-label top-align" >{{'Selected Date' | translate}}: </div>
                    <div class="selected-date" v-if="!showDatePicker">{{getSelectedDate}}</div> 
                    
                    <DatePicker  
                    class="date-picker"
                    v-if="showDatePicker"
                    v-on:date-selected="updatedDate = $event" 
                    v-bind:mydate="bookedInfo.startDate"
                    :placeholder = "'Select Date'"
                    />
                    <div class="booking-schedule-button-container">
                        <div class="button-main schedule-button" v-if="!showDatePicker" @click="showDatePicker = !showDatePicker"><i class="far fa-calendar-alt"></i></div>
                    </div>
                                
                </div>
                <div class='missing-selection-message' v-show="showErrors && !dateSelected">*You need to select a Date</div>
                <div class="form-input-row search-ahead-row" v-if="!showPTO">
                    <div  class="form-input-label">{{'Client' |translate}}: </div>
                    <SearchAhead 
                        v-bind:searchtype="clientSearch.type" 
                        v-bind:searchcall="clientSearch.dbcall" 
                        v-bind:resultfields="clientSearch.resultfields"
                        v-on:item-selected="selectedClient = $event" 
                        :searchTerm="selectedClientName"
                        :clearterm="false" >
                    </SearchAhead>
                    <SelectionSwitch  
                        :switches="['FIRST','LAST']"
                        v-on:item-selected="selectedSearchType = $event"
                        :selectedSwitch= "'LAST'"
                        >
                    </SelectionSwitch>
                    <div class="button-main icon-row-version" @click="showAddClient=true">+</div>
                </div>
                <div class='missing-selection-message' v-show="showErrors && !selectedClient.firstname && selectedService.name !== 'Blocked'">*{{'You need to select a' | translate}} {{'Client' | translate}}</div>
                
                <div class="form-services-row" >
                    <div class="form-input-label"></div>
                    <div class="checkbox-item-container" >
                            <CheckBox 
                        v-bind:checkBoxLabel="'New Client'"
                        v-on:item-selected="bookedInfo.first = $event" 
                        v-bind:cbmodel="newClient"
                        />
                    </div>
                </div>

                <div class="form-services-row">
                    <div class="form-input-label">{{'Service' | translate}}: </div>
                    <div class="form-selected">
                        <dropdown-categories 
                            v-on:item-selected="selectedService = $event" 
                            v-bind:selected="selectedService.name" 
                            v-bind:optioncontent="allServices"
                            :placeholder="selectPlaceholder"
                            v-if="!showPTO && showAddService">
                        </dropdown-categories>
                        <div class="button-main icon-row-version" v-if="!showAddService" @click="showAddService = true">+</div>
                        <div class="services-list" v-for="(service,index) in selectedServiceList" :key="index">
                            {{service.name}}
                            <div class="remove-button" @click="removeService(index)">
                                <i class="fas fa-trash" ></i>
                            </div>
                        </div>
                        <div class="form-content-item" v-if="showPTO" >{{'Personal Time Off' | translate}}</div>
                    </div>
                </div>
                <div class='missing-selection-message' v-show="showErrors && !selectedServiceList.length">*{{'You need to select a' | translate}} {{'Service' | translate}}</div>
                <div class="form-input-row">
                    <div class="form-input-label">{{'Provider' | translate}}: </div>
                    <Dropdown 
                        v-on:item-selected="selectedProvider = $event" 
                        v-bind:selected="selectedProvider.name" 
                        v-bind:optioncontent="allStaff"
                        :placeholder="bookingProvider">
                    </Dropdown>
                </div>
                <div class='missing-selection-message' v-show="showErrors && !selectedProvider.name">*{{'You need to select a' | translate}} {{'Provider' | translate}}</div>
                <div class="form-input-row" v-if="!showPTO">
                    <div class="form-input-label">{{'Room' | translate}}: </div>
                    <Dropdown
                        v-if="showRooms" 
                        v-on:item-selected="selectedRoom = $event" 
                        v-bind:selected="selectedRoom.name" 
                        v-bind:optioncontent="allRooms"
                        placeholder="Select room">
                    </Dropdown>
                    <div class="default-room-selected" v-if="!showRooms">{{selectedRoom.name}}</div>
                </div>
                <div class='missing-selection-message' v-show="showErrors && !selectedRoom.name && selectedService.name !== 'Blocked'">*{{'You need to select a' | translate}} {{'Room' | translate}}</div>            
                
                <div class="form-input-row" v-if="showPTO">
                    <div class="form-input-label"></div>
                    <div class="checkbox-item-container" >
                            <CheckBox 
                        v-bind:checkBoxLabel="'All Day Event'"
                        v-on:item-selected="allDayEvent = $event" 
                        />
                    </div>
                </div>
                
                <div class="form-input-row" v-if="!allDayEvent">
                    <div class="form-input-label">{{'Start Time' | translate}}: </div>
                    <Dropdown 
                        v-on:item-selected="selectedHour = $event" 
                        v-bind:selected="selectedHour.name" 
                        v-bind:optioncontent="officeHours"
                        placeholder="Select Hour">
                    </Dropdown> : 
                    <Dropdown 
                        v-on:item-selected="selectedMinute = $event" 
                        v-bind:selected="selectedMinute.name" 
                        v-bind:optioncontent="officeMinutes"
                        placeholder="Select Minute">
                    </Dropdown>
                    <div class="am-pm">{{getAMPM}}</div>
                </div>           
                <div class='missing-selection-message' v-show="showErrors && (!selectedHour.name || !selectedMinute.name)">*{{'You need to select a' | translate}} {{'Start Time' | translate}}</div>
                
                <div class="form-input-row" v-if="!allDayEvent">
                    <div class="form-input-label">{{'Length' | translate}}: </div>
                    <div class="am-pm"> {{'Hrs' | translate}}</div>
                    <Dropdown 
                        v-on:item-selected="selectedSessionHours = $event" 
                        v-bind:selected="selectedSessionHours.name" 
                        v-bind:optioncontent="sessionHours"
                        placeholder="Hours">
                    </Dropdown> 
                    
                    <div class="am-pm">{{'Min' | translate}}</div>
                        <Dropdown 
                            v-on:item-selected="selectedSessionMinutes = $event" 
                            v-bind:selected="selectedSessionMinutes.name" 
                            v-bind:optioncontent="officeMinutes"
                            placeholder="Minutes">
                        </Dropdown> 
                </div>
                <div class='missing-selection-message' v-show="showErrors && (!selectedSessionHours.name || !selectedSessionMinutes.name)">*{{'You need to select a' | translate}} {{'Session Length' | translate}}</div>
                <div class="booking-row-note">
                    <div class="form-input-label">{{'Note' | translate}}: </div>
                    <textarea class="note-input" v-model="note" />
                </div>

                <div class="conflict-modal" v-if="showConflict">
                    <div class="conflict-modal-shade"></div>
                    <div class="conflict-modal-content">
                        <div class="conflict-title">{{'Conflicts Found' | translate}}</div>
                        <div 
                            class="conflict-item" 
                            v-for="conflictitem in conflicts"
                            v-bind:key="conflictitem">
                                {{conflictitem | translate}} 
                        </div>
                        <div class="conflict-button">
                            <div class="button-plain" @click="showConflict=false">OK</div>
                        </div>
                    </div>
                </div>
                <div class="button-row">
                    <div class="row-button-container"> 
                        <ButtonBar 
                            :buttonlist="buttonList"
                            v-on:button-click="buttonClicked = $event" />
                    </div>
                    <div class="row-button-container pto-button">
                        <div class="button-main small-button" @click="setupPTO">{{ 'Time Off' | translate | allCaps }}</div>
                    </div>
                </div>
                <div class="form-input-row" v-show="showSuccess">
                    <div class="success-label">{{bookingSuccess}}</div>
                </div>          
            </div>
        </div>

    </div>
</div>
</template>

<script>
import EventBus from '../utilities/eventBus'
import Calendar from './utility/Calendar'
import store from '../store/store'
import Dropdown from './utility/DropdownInline'
import DropdownCategories from './utility/DropdownCategories'
import Constants from '../utilities/constants'
import SearchAhead from './utility/SearchAhead'
import SelectionSwitch from './utility/SelectionSwitch'
import Globals from '../utilities/globals'
import ButtonBar from './utility/ButtonBar'
import DatePicker from './utility/DatePicker'
import CheckBox from './utility/CheckBox'
import AddClient from './client/AddClient.vue'

export default {
    name: "BookingForm",
    data() {
        return {
            dateData: {},          
            dateSelected: '',
            allServices: [],
            serviceList: [],
            allStaff: [],
            allRooms: [],
            store,
            searches: {},
            clients: [],
            selectedClient: {first:false},
            selectedService: {},
            selectedProvider: {},
            selectedHour: {},
            selectedMinute: {},
            selectedRoom: {},
            selectedSessionHours: {},
            selectedSessionMinutes: {},
            selectPlaceholder: 'Select Service',
            showClientSearch: false,
            officeHours: Constants.officeHours,
            officeMinutes: Constants.officeMinutes,
            sessionHours: Constants.sessionHours,
            showErrors: false,
            showConflict: false,
            showDatePicker: false,           
            showSuccess: false,
            showPTO: false,
            showRooms: true,
            showAddService: true,
            showAddClient: false,
            conflicts: [],
            bookedInfo: {},
            bookingSuccess: 'Booking was successfully added!',
            note: '',
            notes: [],
            selectedClientName: '',
            selectedSearchType: 'LAST',
            selectedServiceList: [],
            buttonList: ['Submit','Back'],
            buttonClicked: '',
            bookingTitle: 'Add Booking',
            bookingProvider: 'Select Provider',
            bookingRoom: Globals.translate('Select Room'),
            updatedDate: {},
            allDayEvent: false,
            bookingSubmitted: false,
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            totalSessionHours: 0,
            totalSessionMinutes:0,
            newClient: false
        }
    },
    props: ['bookingtype'],
    components: {
        Calendar,
        Dropdown,
        SearchAhead,
        ButtonBar,
        DatePicker,
        CheckBox,
        SelectionSwitch,
        DropdownCategories,
        AddClient,
    },
    methods: {
        removeService(index) {
            this.selectedServiceList.splice(index,1)
            this.setServiceDuration()
        },
        setupPTO() {
            this.showPTO = true
        },
        getServiceInfo() {
            this.$store.dispatch('getServices').then( response => {
                this.allServices = response
                this.serviceList = this.allServices.map(function(item){return item.name});
                this.getRoomsInfo();            
            })            
            this.setStartTime()  
            this.checkClientInfo()        
        },
        checkClientInfo() {
            if (this.$store.getters.newClient.firstname) {
                this.client = this.$store.getters.newClient
                this.selectedClient = this.$store.getters.newClient
                this.selectedClientName = `${this.$store.getters.newClient.firstname} ${this.$store.getters.newClient.lastname}`
                window.console.log('NEW CLIENT ADDED', this.selectedClient, this.selectedClientName,this.client)
            }
        },
        clearNewClient() {
            this.$store.commit('setNewClient',{})
        },
        getRoomsInfo() {
            if (this.$store.getters.allRooms.length) {
                window.console.log('ROOMS EXIST', this.allRooms)
                this.allRooms = this.$store.getters.allRooms;
                if(this.allRooms.length == 1) {
                    this.showRooms = false
                }
                this.getStaffInfo()
            } else {
                this.allRooms = this.$store.getters.allRooms;
                    if(this.allRooms.length == 1) {
                    this.showRooms = false
                }
                this.getStaffInfo()
            }
            if(this.allRooms.length == 1){
                this.selectedRoom = this.allRooms[0]
                this.showRooms = false;
            }
        },
        getStaffInfo() {
            if (this.$store.getters.allStaff.length) {                
                this.allStaff = this.$store.getters.allStaff
                this.allStaff.map(item => {item.name = item.displayname})
                window.console.log('STAFF EXIST', this.allStaff)
                if (this.bookingtype === "UPDATE") {
                    this.setupForUpdate()
                }
            } else {
                this.$store.dispatch('getStaff').then( response => {
                    this.allStaff = response.data
                    this.allStaff.map(item => {item.name = item.displayname})
                    //NEED TO ADD CHECK ON RESPONSE
                    window.console.log('GOT STAFF',response, this.allStaff)                   
                })
            }
            this.setStartTime()

        },
        setDefaultRoom() {
            if (this.bookedInfo.staffid) {
                this.allStaff.forEach(staff => {
                    if (staff.id == this.bookedInfo.staffid && staff.roompref) {
                        this.selectedRoom = staff.roompref
                    }
                })
            }
        },
        setStartTime() {
            window.console.log('SET START TIME BOOKING INFO') 
            let vm = this
            this.bookedInfo = this.$store.getters.bookingInfo;
            const timeArray = this.bookedInfo.starttime.split(':')
            const timeHr = timeArray[0] > 12 ? timeArray[0] -12 : timeArray[0];
            this.bookingProvider = this.bookedInfo.staff ? this.bookedInfo.staff : this.bookingProvider
            this.setDefaultRoom()
            if (typeof this.bookedInfo.staff == 'string') {
                this.selectedProvider.name = this.bookedInfo.staff ? this.bookedInfo.staff : this.selectedProvider
                this.selectedProvider.id = this.selectedProvider.name ? this.bookedInfo.staffid : ''
            }
            this.bookingRoom = this.bookedInfo.room ? this.bookedInfo.room : this.bookingRoom
            vm.officeHours.forEach(item => {
                if (item.name == timeHr) {
                    window.console.log('SEL HR',item)
                    vm.selectedHour = item;
                }
            });
            this.officeMinutes.forEach(item => {
                if (item.name == timeArray[1]) {
                    this.selectedMinute = item;
                }
            });
            this.allRooms.forEach(item => {
                if (item.id === this.bookedInfo.roomid) {
                    window.console.log('MATCH ROOM', item.id, item)
                    this.selectedRoom = item;
                }
            });
            this.selectedSessionHours = this.selectedSessionHours ? this.selectedSessionHours : Constants.defaultBookingLength.sessionHours
            this.selectedSessionMinutes = this.selectedSessionMinutes ? this.selectedSessionMinutes : Constants.defaultBookingLength.sessionMinutes
            window.console.log('SET START TIME', timeArray,this.selectedMinute)
        },
        setUpdateDate() {
            const newDate = {
                day: this.bookedInfo.day,
                month: this.bookedInfo.month,
                year: this.bookedInfo.year,
                selectedDate: Globals.formatSelectedDate(this.bookedInfo.bookingdate)
            }
            window.console.log('SETUP DATE')
            this.$store.commit('setDate', newDate)
        },
        setupForUpdate() {
            this.bookedInfo = this.$store.getters.bookingInfo
            window.console.log('SETUP',this.bookedInfo)
            this.dateSelected = this.dateData.selectedDate
            this.selectedClientName = this.bookedInfo.client            
            this.bookingTitle = 'Update Booking'
            this.buttonList = ['Update','Back']
            this.setUpdateDate()
            window.console.log('SETUP BOOKING INFO',this.bookedInfo, this.bookedInfo.client, this.bookedInfo.serviceid,'DATE SELECTED', this.dateSelected, this.dateData,'GETTER', this.$store.getters.selectedDateData.selectedDate, Globals.formatSelectedDate(this.bookedInfo.bookingdate))
            // if (this.bookedInfo.service){
                // this.allServices.forEach(item => {
                //     if (item.id === this.bookedInfo.serviceid) {
                //         this.selectedService = item;
                //     }
                // });
                this.selectedServiceList = this.bookedInfo.services
                this.allRooms.forEach(item => {
                    window.console.log('CHECK ROOMS', item.id, this.bookedInfo.roomid)
                    if (item.id === this.bookedInfo.roomid) {
                        window.console.log('MATCH ROOM', item.id)
                        this.selectedRoom = item;
                    }
                });
                this.allStaff.forEach(item => {
                    if (item.id === this.bookedInfo.staffid) {
                        this.selectedProvider = item;
                    }
                });
                
                const lenHours = this.bookedInfo.duration > 59? Math.floor(this.bookedInfo.duration/60) : 0;
                this.sessionHours.forEach(item => {
                    if (item.name == lenHours) {
                        this.selectedSessionHours = item;
                    }
                });
                const lenMinutes = this.bookedInfo.duration < 60? this.bookedInfo.duration : this.bookedInfo.duration - 60;
                this.officeMinutes.forEach(item => {
                    if (item.name == lenMinutes) {
                        this.selectedSessionMinutes = item;
                    }
                });
                if(this.bookedInfo.clientid) {
                    this.$store.dispatch('getClientId',{id: this.bookedInfo.clientid} ).then( response => {
                        this.selectedClient = response[0];
                    })
                }
            // }             
        },
        searchByName() {
            window.console.log('SEARCH BY NAME', this.searchterm)
        },
        searchByPhone() {
            window.console.log('SEARCH BY PHONE', this.searchterm)
        },
        // addClient() {
        //     this.$router.push('AddClient')
        // },
        itemSelected(item){
            window.console.log('GOT EMIT', item)
            this.selectedRoom = item;
        },
        calcRefTime() {
            let refTime = this.selectedHour.id > 5 ? 12 + Number(this.selectedHour.name) : Number(this.selectedHour.name)
            refTime += Number(this.selectedMinute.name)/60
            return refTime
        },
        verifySubmitPTO() {
            if ( !this.dateSelected || !this.selectedProvider.name || !this.allDayEvent || ( !this.selectedHour.name || !this.selectedMinute.name || !this.selectedSessionHours.name || !this.selectedSessionMinutes.name) ){
                window.console.log('SHOW ERRORS')
                this.showErrors = true;
            } else {
                this.submitPTO()
            }
        },
        submitPTO() {  
            const bookingInfo = {
                date: this.dateData.selectedDate,
                day: this.dateData.day,
                month: this.dateData.month,
                year: this.dateData.year,                
                service: 'pto',
                serviceid: 0,
                staff: this.selectedProvider.name,
                staffid: this.selectedProvider.id, 
                status: this.bookedInfo.status,   
                id: this.bookedInfo.id? this.bookedInfo.id : -1,
                note: this.note,
                bookingdate: Globals.formatDBDate(this.dateData.day, this.dateData.month,this.dateData.year)
            }
            if (!this.allDayEvent) {
                bookingInfo.starttime  = this.selectedHour.name + ':' + this.selectedMinute.name
                bookingInfo.reftime  = this.calcRefTime()
                bookingInfo.duration  = (Number(this.selectedSessionHours.name) * 60) + Number(this.selectedSessionMinutes.name)
            } else {
                bookingInfo.starttime  = '7:00'
                bookingInfo.reftime  = 7.00
                bookingInfo.duration  = 800
            }
            this.addBooking(bookingInfo)
        },
        submitBooking(){
            const bookedID = this.bookedInfo.id? this.bookedInfo.id : -1;
            this.dateSelected = this.dateData.selectedDate
            if (this.showPTO) {
                this.submitPTO()
            } else {
                window.console.log('DATE-----',this.dateData, this.dateData.selectedDate)
                const bookingInfo = {
                    date: this.dateData.selectedDate,
                    day: this.dateData.day,
                    month: this.dateData.month,
                    year: this.dateData.year,                
                    client: this.selectedClient.firstname + ' ' + this.selectedClient.lastname,
                    clientid: this.selectedClient.id,
                    clientemail: this.selectedClient.email,
                    clientphone: this.selectedClient.phone,
                    room: this.selectedRoom.name,
                    roomid: this.selectedRoom.id,
                    service: this.selectedService.name,
                    serviceid: this.selectedService.id,
                    services: JSON.stringify(this.selectedServiceList),
                    status: this.bookedInfo.status,
                    staff: this.selectedProvider.name,
                    staffid: this.selectedProvider.id,
                    starttime: this.selectedHour.name + ':' + this.selectedMinute.name,
                    reftime: this.calcRefTime(),
                    first: this.bookedInfo.first,
                    duration: (Number(this.selectedSessionHours.name) * 60) + Number(this.selectedSessionMinutes.name),
                    id: bookedID,
                    note: this.note,
                    bookingdate: Globals.formatDBDate(this.dateData.day, this.dateData.month,this.dateData.year)
                };
                window.console.log('BOOKING INFO',bookingInfo)
                // CHECK IF BLOCKED
                if (this.selectedService.name === 'Blocked') {
                    if (!this.dateSelected || !this.selectedProvider.name || !this.selectedHour.name || !this.selectedMinute.name || !this.selectedSessionHours.name || !this.selectedSessionMinutes.name) {
                        window.console.log('SHOW BLOCKED ERRORS')
                        this.showErrors = true;
                    } else {
                        this.checkBookingSubmit(bookingInfo)
                    }
                } else if ( !this.dateSelected || !this.selectedClient.firstname || !this.selectedProvider.name || !this.selectedRoom.name || !this.selectedServiceList.length || !this.selectedHour.name || !this.selectedMinute.name || !this.selectedSessionHours.name || !this.selectedSessionMinutes.name ){
                    window.console.log('SHOW ERRORS')
                    this.showErrors = true;
                } else {
                    this.checkBookingSubmit(bookingInfo)
                }
            }
                       
        },
        checkBookingSubmit(bookingInfo){           
            const searchDate = {               
                "month": Number(this.dateData.month),
                "day": Number(this.dateData.day),
                "year": Number(this.dateData.year),
            }
            window.console.log('DISPATCH ADD BOOKING', bookingInfo, searchDate)
            this.$store.dispatch('getBookingsDay', searchDate).then(response => {
                window.console.log('GOT BOOKING DAY RETURN', response)
                if (response.length > 0) {
                    if(this.checkBookingConflict(response, bookingInfo)){
                        this.submitBookingInfo(bookingInfo);
                    }
                } else {
                    this.submitBookingInfo(bookingInfo);
                    window.console.log('NOTHING BOOKED NO CONFLICT')
                }
            })
            
            
        },
        submitBookingInfo(bookingInfo) {
            window.console.log('SUBMIT BOOKING DATA', bookingInfo)
            if (this.selectedService.name === 'Blocked') {
                bookingInfo.client = '';
                bookingInfo.clientid = -1;
                bookingInfo.room = '';
                bookingInfo.roomid = -1;
                delete bookingInfo.date;
                delete bookingInfo.id


            }
            window.console.log('SUBMIT', bookingInfo)

            if (this.bookingtype === 'UPDATE') {
                bookingInfo.date = this.updatedDate.selectedDate
                window.console.log('UPDATE BOOKING', bookingInfo, this.updatedDate)
                this.$store.dispatch('updateBooking',bookingInfo).then(response => {
                    window.console.log('UPDATE BOOKING RESULT',response)
                     if (response.data.result == 'SUCCESS'){
                        this.bookingSuccess = 'Booking was successfully Updated!'
                        this.showSuccess = true;
                        setTimeout(() => {
                            this.$router.push('Schedule')
                        }, Constants.waitTime);
                    }
                })
            } else {
                window.console.log('ADD BOOKING', bookingInfo)
                this.addBooking(bookingInfo)
            }            
        },
        addBooking(bookingInfo) {
            if (!this.bookingSubmitted) {
                this.bookingSubmitted = true
                this.$store.dispatch('addBooking',bookingInfo).then(response => {
                    if (response.substr(0,7) === 'SUCCESS'){
                        window.console.log('ADDED SUCCESS')
                        
                        this.showSuccess = true;
                        this.clearNewClient()
                        setTimeout(() => {
                            this.$router.push('Schedule')
                        }, Constants.waitTime);
                    }
                    window.console.log('GOT ADD BOOKING RETURN', response, response.substr(0,7))
                })
            }
        },
        checkBookingConflict(bookingsData, bookingInfo) {
            this.conflicts = [];
            const newStart = this.convertTimeToMinutes (bookingInfo.starttime)
            let conflictsExist = false;
            window.console.log('CHECK CONFLICT', bookingsData,bookingInfo)
            for( let i =0;i<bookingsData.length;i++){
                const bookStart = this.convertTimeToMinutes (bookingsData[i].starttime)
                // check for time conflict with staff, service or room
                const bookedID = this.bookedInfo.id? this.bookedInfo.id : -1
                window.console.log('CHECKING ID CONFLICT',bookingsData, bookingsData[i].id !== bookedID, bookingsData[i].id, bookedID)
                if ((bookingsData[i].id !== bookedID && newStart >= bookStart && newStart < bookStart + Number(bookingsData[i].duration)) || (newStart < bookStart && newStart + Number (bookingInfo.duration) > bookStart && bookingsData[i].id !== bookedID)) {
                    window.console.log('CHECKING ID MATCH',bookingsData, bookingsData[i].id !== bookedID, bookingsData[i].id, bookedID)
                    if (bookingsData[i].staffid === bookingInfo.staffid) {
                        this.conflicts.push(Constants.conflicts.STAFF_DOUBLE);
                        conflictsExist = true;
                        window.console.log('CONFLICT STAFF_DOUBLE', bookingsData[i].id, this.bookedInfo.id)
                    }
                    if (bookingsData[i].serviceid === bookingInfo.serviceid) {
                        window.console.log('CONFLICT SERVICE_DOUBLE', bookingsData[i].serviceid, bookingsData[i].id, this.bookedInfo.id)
                        this.conflicts.push(Constants.conflicts.SERVICE_DOUBLE);
                        conflictsExist = true;
                    }
                    if (bookingsData[i].roomid === bookingInfo.roomid) {
                        window.console.log('CONFLICT ROOM_DOUBLE', bookingsData.roomid, bookingInfo.roomid)
                        this.conflicts.push(Constants.conflicts.ROOM_DOUBLE);
                        conflictsExist = true;
                    }
                }
                // check if service requires certification
                if (this.selectedService.certrestricted && this.selectedService.certrestricted.length) {
                    window.console.log('CHECK FOR SERVICE CERT')
                    let certApproved = false;
                    this.selectedService.certrestricted.forEach(item => {
                        window.console.log('CERT', item, this.selectedProvider)
                        if (this.selectedProvider.certifications) {
                            this.selectedProvider.certifications.forEach(cert => {
                                if (cert === item) { certApproved = true}
                                    window.console.log('CERT PROVIDER', item, cert)
                                }
                            )
                        }
                        
                    })
                    if (!certApproved) {
                        window.console.log('PROVIDER NOT CERTIFIED')
                        this.conflicts.push(Constants.conflicts.STAFF_CERT);
                        conflictsExist = true;
                    }
                }
                // check if service requires specific room
                if (this.selectedService.roomrestricted && this.selectedService.roomrestricted.length) {
                    let restrictApproved = false;
                    this.selectedService.roomrestricted.forEach(item => {
                        if (item === this.selectedRoom.id) {
                            restrictApproved = true;
                        }                       
                    })
                    if (!restrictApproved) {
                        window.console.log('ROOM NOT APPROPRIATE FOR SERVICE')
                        this.conflicts.push(Constants.conflicts.ROOM_SERVICE);
                        conflictsExist = true;
                    }
                }
            }
            if(conflictsExist){ 
                this.showConflict = true;
                return false
            } else {
                return true
            }
            
        },
        convertTimeToMinutes (startTime) {
            const startArray = startTime.split(':');
            if ( Number(startArray[0]) < 8 ) {
                return (Number(startArray[0]) * 60) + 720 + Number(startArray[1]);
            } else {
                return (Number(startArray[0]) * 60) + Number(startArray[1]);
            }
        },
        showCalendar() {
            EventBus.$emit('SHOW_MODAL',{type:'calendar'})
        },
        showSchedule() {
            this.$router.push('Schedule')
        },
        setDateData() {
            this.dateData = this.$store.getters.selectedDateData;
            window.console.log('DATE DATA', this.dateData)
            this.selectedDate = this.$store.getters.selectedDateData;
        },
        cancelBooking() {
            this.clearNewClient()
            this.$router.go(-1)
        },
        goPrevious() {
            this.$router.go(-1)
        },
        setServiceDuration() {
            this.totalSessionMinutes = 0;
            this.totalSessionHours = 0
            this.selectedServiceList.forEach(service => {
                const serviceHours = Math.floor(service.duration/60)
                const serviceMinutes = serviceHours > 0 ? service.duration - (60 * serviceHours) : service.duration
                this.totalSessionHours += serviceHours
                this.totalSessionMinutes += serviceMinutes
                if (this.totalSessionMinutes > 59) {
                    this.totalSessionHours ++
                    this.totalSessionMinutes -= 60
                }
            })
            
            let selectedHour = Constants.sessionHours[0]
            let selectedMinute = Constants.officeMinutes[2]
            
            Constants.sessionHours.forEach(hour => {
                if (hour.name == this.totalSessionHours){
                    selectedHour = hour
                }
            })
            Constants.officeMinutes.forEach(minute => {
                if (Number(minute.name) == this.totalSessionMinutes) {
                    selectedMinute = minute
                }
            })
            this.selectedSessionHours = selectedHour
            this.selectedSessionMinutes = selectedMinute
            window.console.log('SESSION INFO',this.totalSessionHours, this.totalSessionMinutes,this.selectedSessionHours,this.selectedSessionMinutes)
        },
        setClient() {
            window.console.log('set client')
        },
        checkNew() {
            
            if(this.selectedClient.learnref == 'Renew') {
                this.$store.dispatch('getBookingsByClient',{id: this.selectedClient.id}).then( response => {
                    if (response.data.length == 0) {
                        this.newClient = true
                    }
                })
            }
        }
    },
    mounted() {
        var vm = this;
        vm.getServiceInfo();
        EventBus.$on('CAL_DATE_SELECTED', function(dateData){
            vm.dateData = dateData;
            vm.dateSelected = dateData.selectedDate;
            window.console.log('DATE SELECTED', dateData)
        })
    },
    computed: {
        getAMPM() {
            if (this.selectedHour.name) {
                if (this.selectedHour.name > 6 && this.selectedHour.name < 12) {
                    return 'AM'
                } else {
                    return 'PM'
                }    
            }
            return ''
        },
        getSelectedDate: function() {
            this.setDateData();
            window.console.log('GETTER DATE', this.$store.getters.selectedDateData.selectedDate)
            return this.$store.getters.selectedDateData.selectedDate
        },
    },
    watch: {
        selectedClient: function() {
            this.showClientSearch = false;
            this.checkNew()
        },
        selectedService: function() {
            window.console.log('SELECTED SERVICE', this.selectedService.name)
            if (this.selectedService.name) {
                if (this.selectedService.name === 'Blocked') {
                    this.selectedRoom = {}
                    this.selectedClient = {}
                } else { 
                    this.selectedServiceList.push(this.selectedService)
                    // this.showAddService = false
                }
                this.setServiceDuration()
            }

        },
        selectedSearchType() {
            window.console.log('SEL SEARCH TYPE CHANGED', this.selectedSearchType)
            if (this.selectedSearchType == 'FIRST') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedSearchType == 'LAST') {
                this.clientSearch = this.lastNameSearch
            }
        },
        buttonClicked() {
            window.console.log('BUTTON CLICKED', this.buttonClicked)
            if (this.buttonClicked.name === 'Submit') {
                this.submitBooking()
            }
            if (this.buttonClicked.name === 'Back') {
                this.cancelBooking()
            }
            if (this.buttonClicked.name === 'Update') {
                this.submitBooking()
            }
        },
        updatedDate() {
            window.console.log('DATE UPDATED', this.updatedDate)
            this.$store.commit('setDate', this.updatedDate)
            this.getSelectedDate()
        }
    }
}
</script>

<style scoped>
.selected-date{
    font-weight: 600;
    padding: 0;
    display: inline-block;
    min-width: 175px;
    vertical-align: top;
    margin-top: 1px;
}
.client-search-container{
    padding-left: 130px;
}
.booking-item{
    display: inline-block;
}
.booking-row{
    height: 35px;
}
.client-list-item{
    cursor: pointer;
    padding-left: 20px;
}
.client-selected-name{
    display: inline-block;
    min-width: 150px;
    padding-left: 5px;
}
.select-label{
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
    width: 100px;
    text-align: right;
}

.conflict-modal{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.conflict-modal-shade{
    background-color: #474747;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .3;
    top: 0;
    left: 0;
}
.conflict-modal-content{
    background-color: white;
    width: 50%;
    top: 30%;
    position: relative;
    z-index: 1;
    float: left;
    left: 25%;
    padding: 5px;
    border: 2px solid red;
    text-align: center;
}
.conflict-title{
    color: red;
    font-weight: 700;
}
.conflict-item{
    font-weight: 500;

}
.note-input{
    margin: 0 10px 10px 5px;
    width: 190px;
    height: 50px;
    vertical-align: top;
    padding: 5px;
}
.booking-row-note{
    text-align: left;
}
.top-align{
    vertical-align: top;
}
.date-picker{
    z-index: 6;
}
.booking-schedule-button-container{
    display: inline-block;
    position: relative;
    top: -2px;
}
.schedule-button{
    padding: 4px 7px;
    background-color: #0bb2cc;
    margin-left: 15px;
    margin-top: 0;
}
.am-pm{
    display: inline-block;
    position: relative;
    top: 2px;
    left: 5px;
    padding-right: 5px;
}
.row-button-container{
    display: inline-block;
}
.pto-button{
    float: right;
    margin: 12px;
    cursor: pointer;
}
.checkbox-item-container{
    margin-left: 25px;
    margin-top: 2px;
    display:inline-block;
}
.default-room-selected{
    display: inline-block;
    font-weight: 500;
    line-height: 22px;
    padding-left: 5px;
    color: #0b8ecc;
}
.form-selected{
    display: inline-block;
}
.form-services-row{
    text-align: left;
    margin: 5px 0;
}
.services-list{
    padding: 5px;
    color: #1390bd;
}
.remove-button{
    display:inline-block;
    color: grey;
    padding-left: 10px;
    cursor: pointer;
}
.add-client-component {
    position: absolute;
    width: 98.5%;
    top: 0;
}

@media screen and (max-width: 650px) {
    div.checkbox-label{
        font-size: 14px;
    }
    .checkbox-item-container{
        margin-left: 10px;
    }
    .main-app-container{
        overflow-x: auto;
    }
}

</style>