<template>
    <div class="main-input-form-container full-width" ref="main-container">
        <div class="form-title-row">{{ 'Medical Onboarding History' | allCaps }}</div>
        <div v-if="!showSurvey">
            <div class="form-input-row">
                <label class="form-input-label" >{{'First Name' | translate}} <span class="required-entry">*</span> : </label>
                <input class="input-wide" v-if="showInput" id="firstname" v-model="client.firstname"/>
                <div class="form-content" v-if="!showInput">{{client.firstname}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.firstname) ">*{{'You need to enter your' | translate}} {{'First Name' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label" >{{'Last Name' | translate}} <span class="required-entry">*</span>: </label>
                <input class="input-wide" v-if="showInput" id="lastname" v-model="client.lastname"/>
                <div class="form-content" v-if="!showInput">{{client.lastname}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.lastname) ">*{{'You need to enter your' | translate}} {{'Last Name' | translate}}</div>
            
            <div class="form-input-row">
                <label class="form-input-label" >{{'Email' | translate}} <span class="required-entry">*</span>: </label>
                <input class="input-wide" v-if="showInput" id="email" v-model="client.email"/>
                <div class="form-content" v-if="!showInput">{{client.email}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.email) ">*{{'You need to enter your' | translate}} {{'Email' | translate}}</div>

            <div class="form-input-row">
                <PhoneNumber 
                    v-on:number-updated="client.phone = $event"
                    v-if="showInput" 
                    :phonelabel="'Phone'"
                    :existingnumber="client.phone"
                    :required="true" />
                <label class="form-input-label" v-if="!showInput" >{{'Phone' | translate}} <span class="required-entry">*</span>: </label>
                <div class="form-content" v-if="!showInput">{{client.phone | formatPhone}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.phone) ">*{{'You need to enter your' | translate}} {{'Phone' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label" >{{'Pharmacy' | translate}} <span class="required-entry">*</span>: </label>
                <input class="input-wide" v-if="showInput" id="pharmacy" v-model="client.pharmacy"/>
                <div class="form-content" v-if="!showInput">{{client.pharmacy}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.pharmacy) ">*{{'You need to enter your' | translate}} {{'Pharmacy' | translate}}</div>

            <div class="form-input-row">
                <PhoneNumber 
                    v-on:number-updated="client.pharmacyphone = $event"
                    v-if="showInput" 
                    :phonelabel="'Pharmacy Phone'"
                    :existingnumber="client.pharmacyphone"
                    :required="true" />
                <label class="form-input-label" v-if="!showInput" >{{'Phone' | translate}} <span class="required-entry">*</span>: </label>
                <div class="form-content" v-if="!showInput">{{client.pharmacyphone | formatPhone}}</div>
            </div>
            <div class='missing-selection-message' v-if="showError && (!client.pharmacyphone) ">*{{'You need to enter your' | translate}} {{'Pharmacy Phone' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Emergency Contact Name' | translate}}:</label> 
                <input v-if="showInput"  v-model="client.emergencyname"/>
                <div class="form-content" v-if="!showInput">{{client.emergencyname}}</div>
                
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Emergency Contact Relation' | translate}}:</label> 
                <input v-if="showInput"  v-model="client.emergencyrelation"/>
                <div class="form-content" v-if="!showInput">{{client.emergencyrelation}}</div>
            </div>
            <div class="form-input-row">
                <PhoneNumber 
                    v-on:number-updated="client.emergencyphone = $event" 
                    v-if="showInput"
                    :existingnumber="client.emergencyphone"
                    :phonelabel="'Emergency Phone'"/>
                <label v-if="!showInput" class="form-input-label">{{'Emergency Contact Phone' | translate}}:</label>
                <div class="form-content" v-if="!showInput">{{client.emergencyphone}}</div>
            </div>
            

        </div>
        <div class="alert-message" v-if="showAlertMessage">{{alertMessage}}</div>
        <ButtonBar 
            v-if="!showSurvey"
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />
        <div class="patient-name" v-if="showSurvey">{{this.client.firstname}} {{this.client.lastname}}</div>
        <MedSurveyPages 
            v-if="showSurvey"
            v-on:show-entry="showSurvey = false"
            :refname="client.refname"
            :clientid="client.id"></MedSurveyPages>
    </div>
</template>
<script>
import MedSurveyPages from './utility/MedSurveyPages'
// import Globals from '../utilities/globals'
import ButtonBar from './utility/ButtonBar'
import PhoneNumber from './utility/PhoneNumber'

export default {
    
    name: 'medOnboard',
    data() {
        return {
            client: {

            },
            buttonList: ['Update','Next'],
            existingNumber: '',
            showError: false,
            showSurvey: false,
            showClose: false,
            showAlertMessage: false,
            showInput: false,
            buttonClicked: '',
            origClient: {},
            alertMessage: 'Phone does not match number on record!'
        }
    },
    components: {
        MedSurveyPages,
        ButtonBar,
        PhoneNumber
    },
    methods: {
        checkDisplay() {
            window.console.log('RoUTE',this.$route.name)
            if (this.$route.name == 'MedSurvey') {
                this.showClose = true
                this.buttonList = ['Update','Next','Close']
            }
        },
        checkForm() {
            this.showError = false
            if (this.client.firstname && this.client.lastname && this.client.email && this.client.phone && this.client.pharmacy && this.client.pharmacyphone) { 
                if (!this.client.id) {
                    this.$store.dispatch('checkEmailClient', this.client).then(response => {
                        this.showAlertMessage = false
                        if (response.result == 'NO MATCH') {
                            window.console.log('ADD CLIENT')
                            this.$store.dispatch('addClient',this.client).then(response => {
                                this.client.id = response.data.id
                                this.showSurvey = true
                            })
                        }
                        if (response.result == 'MATCH') {
                            if (response.data[0].phone == this.client.phone) {
                                this.client.id = response.data[0].id
                                this.checkIfChanges(response.data[0])
                            } else {
                                this.showAlertMessage = true
                            }
                        }
                    })
                } else {
                    this.checkIfChanges(this.origClient)
                    this.showSurvey = true
                }                
            } else {
                this.showError = true
            }
        },
        checkIfChanges(data) {
            let mismatches = []
            for (const item in data) {
                if (data[item] != this.client[item]) {
                    mismatches.push(item)
                }
            }
            if (mismatches.length > 0) {
                this.$store.dispatch('updateClient',this.client).then(response => {
                    if (response.result == 'SUCCESS') {
                        this.showSurvey = true
                    } else {
                        this.alertMessage = 'Error updating data. Please try again'
                        this.showAlertMessage = true
                    }
                })
            } else {
                this.showSurvey = true
            }
        },
        checkIfUser() {
            if (this.$store.getters.isAuthenticated) {
                if (this.$route.query.ref){
                    this.$store.dispatch('getClientId',{id:this.$route.query.ref}).then(response => {
                        if (response && response.length) {
                            this.client = response[0]
                            this.origClient = {...this.client}
                            window.console.log('CLIENT', this.origClient)
                            this.checkIfComplete()
                        } else {
                            this.showInput = true
                        }                    
                    })
                }
            } else {
                this.$router.push('Login')
            }
        },
        checkIfComplete() {
             if (this.client.firstname && this.client.lastname && this.client.email && this.client.phone && this.client.pharmacy && this.client.pharmacyphone) {
                 this.showInput = false
                if (this.showClose) {
                     this.buttonList = ['Update','Next','Close']
                } else {
                    this.buttonList = ['Update','Next']
                }
                 
             } else {
                 this.showInput = true
                 if (this.showClose) {
                     this.buttonList = ['Next','Close']
                } else {
                    this.buttonList = ['Next']
                }
             }
        } 
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Next') {
                this.checkForm()
            }
            if (this.buttonClicked.name === 'Update') {
                this.showInput = true
                if (this.showClose) {
                     this.buttonList = ['Next','Close']
                } else {
                    this.buttonList = ['Next']
                }
            }
            if (this.buttonClicked.name === 'Close') {
                this.$router.go(-1)
            }
        },
    },
    mounted() {
        this.checkDisplay()
        this.checkIfUser()
    },
}
</script>
<style scoped>
.alert-message{
    color: red;
    font-weight: 500;
} 
.patient-name{
    font-weight: 600;
    margin-top: -9px;
    margin-bottom: 5px;
    color: #156d94;
}
.form-content{
    display: inline-block;
    font-weight: 600;
    line-height: 23px;
}
.full-width{
    max-width: 100%;
}

</style>