<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ supplyTitle | translate | allCaps}}</div>
        
        <div class="form-input-row">
            <div class="form-input-label">{{'Supply Name' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="supply.name"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Supply Code' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="supply.code"/>
            </div>
        </div>
         
        
        <div class="form-input-row">
            <div class="form-input-label">{{'Cost' | translate}}: </div>
            <InputCurrency
                v-on:amount-entered="supply.cost = $event"
                :amount="supplyCost"
             ></InputCurrency>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Retail Price' | translate}}: </div>
            <InputCurrency
                v-on:amount-entered="supply.price = $event"
                :amount="supplyRetailPrice"
             ></InputCurrency>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'In Stock Quantity' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input small-input"  v-model="supply.inventorytotal"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Alert Quantity' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input small-input"  v-model="supply.alertnumber"/>
            </div>
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Company' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="supply.company"/>
            </div>
        </div>
        <div class="form-input-row">
            <PhoneNumber 
                v-on:number-updated="supply.phone = $event"
                :phonelabel="'Phone'"
                :existingnumber="existingNumber"
                :required="false" />
        </div>
        <div class="form-input-row">
            <div class="form-input-label">{{'Email' | translate}}: </div>
            <div class="form-content-item">
                <input class="form-content-input"  v-model="supply.email"/>
            </div>
        </div>

        <!-- COMPANY DISTRIBUTOR INFO -->
        <div class="form-input-row">
            <div class="form-input-label">{{'Distributor' | translate}}: </div>
                <SearchAhead 
                    v-bind:searchcall="'getDistributorsSearch'"
                    v-bind:searchtype="'Distributor'"  
                    v-bind:resultfields="['name']"
                    v-on:item-selected="selectedDistributor = $event" 
                    :searchTerm="selectedDistributorName" />
            
        </div>
        
        

        
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />
        <div class="form-input-row" v-show="showSuccess">
            <div class="form-success-msg">{{'Supply' | translate}} {{'Successfully Added' | translate}}</div>
        </div>

        <div class="service-existing-container">
            <div class="service-title">{{'Existing Supplies' | translate}}</div> 
            <div class="service-type-row" v-for="(type,index) in allSupplies" :key="index">
                {{type.name}}
                <div class="control-group-item color-blue" 
                    @click='modifySupply(type)'>
                    <i class="fas fa-pen"></i>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import store from '../../store/store.js'
import ButtonBar from '../utility/ButtonBar'
import InputCurrency from '../utility/InputCurrency'
import PhoneNumber from '../utility/PhoneNumber'
import SearchAhead from '../utility/SearchAhead'
import Constants from '../../utilities/constants'

export default {
    name: 'SupplyAdd',
    data: function() {
        return {
            supply: {},
            store,
            showSuccess: false,
            successMessage: 'Supply was successfully Added!',
            selectedType: {name:''},
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            allTypes: [],
            allSupplies: this.$store.getters.allSupplies,
            supplyCost: '',
            supplyRetailPrice: '',
            existingNumber: '',
            supplyTypes: Constants.supplyTypes,
            supplyTitle: 'Add Supply',
            selectedDistributor: {},
            selectedDistributorName: '',
        }
    },
    components: {
        ButtonBar,
        InputCurrency,
        PhoneNumber,
        SearchAhead,
    },
    methods: {
        submitSupply() {
            this.setData()
            window.console.log('ADD', this.supply)
            this.$store.dispatch('addSupply',this.supply).then(response => {
                window.console.log('GOT ADD RETURN', response)
                if (response.result === 'SUCCESS'){
                    this.responseSuccess()
                }
            })
        },
        responseSuccess() {
            window.console.log('UPDATE SUCCESS')                    
            this.showSuccess = true;
            this.supply = {};
            this.supplyCost = ''
            this.supplyRetailPrice = ''
            this.existingNumber = ''
            this.buttonlist= ['Submit','Cancel']
            this.supplyTitle = 'Add Supply'
            this.selectedDistributor = {}
            this.selectedDistributorName = ''
            this.getAllSupplies()
        },
        updateSupply() {
            this.setData()
            this.$store.dispatch('updateSupply',this.supply).then(response => {
                window.console.log('GOT UPDATE RETURN', response)
                if (response.result === 'SUCCESS'){
                    this.responseSuccess()
                }
            })
        },
        setData() {
            this.supply.date = new Date();
            this.supply.businessid = this.$store.getters.user.businessid
            this.supply.distributor = this.selectedDistributor.name
            this.supply.distributorid = this.selectedDistributor.id            
        },
        getAllSupplies() {
            this.$store.dispatch('getSupplies').then(response => {
                this.allSupplies = response
                window.console.log('ALL PROD GET', this.allSupplies, response)
            })
        },        
        modifySupply(supply) {
            window.console.log('MODIFY PRODUCT', supply)
            this.supply = supply
            this.supplyCost = Number(this.supply.cost)
            this.supplyRetailPrice = Number(this.supply.retailprice)
            this.existingNumber = Number(this.supply.phone)
            this.supplyTitle = 'Modify Supply'
            this.buttonList= ['Update', 'Cancel']
            this.selectedDistributorName = this.supply.distributor
        },

    },
    mounted() {
        this.getAllSupplies()
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.submitSupply()
            }
            if (this.buttonClicked.name === 'Update') {
                this.updateSupply()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
    },
}
</script>

<style scoped>

</style>
