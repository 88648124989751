<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'ADD BUSINESS' | translate}}</div>
        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Name' | translate}}:<span class="required-entry">*</span></label> 
            <input  class="input-wide" v-model="business.name"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !business.name">*{{'You need to enter a' | translate}} {{'Business' | translate}} {{'Name' | translate}}</div>

        <div class="form-input-row">
            <label for="type" class="form-input-label">{{'Business Type' | translate}}:<span class="required-entry">*</span> </label> 
            <Dropdown 
                v-on:item-selected="business.typeObj = $event" 
                v-bind:selected="business.typeObj.name" 
                v-bind:optioncontent="businessTypes"
                placeholder="Select Business Type">
            </Dropdown>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !business.typeObj.id">*{{'You need to select a' | translate}} {{'Business' | translate}} {{'Type' | translate}}</div>

        <div class="form-input-row">
            <PhoneNumber v-on:number-updated="business.phone = $event" :required="true"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !business.phone">*{{'You need to enter your' | translate}} {{'Business' | translate}} {{'Phone' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Email' | translate}}:<span class="required-entry">*</span> </label> 
            <input v-model="business.email"/>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !business.email">*{{'You need to enter your' | translate}} {{'Business' | translate}} {{'Email' | translate}}</div>

        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Website' | translate}}: </label> 
            <input  v-model="business.website"/>
        </div>

        <div class="locations-container">
            <div class="location-title-bar">
                <div class="location-title">{{'Locations' | translate}}</div>
                <div class="locations-add-button">
                    <div class="button-icon " @click="addLocation()" ><div class="x-mark">+</div></div>
                </div>
            </div>
            <LocationAdd 
                v-for="(item,index) in locations" 
                :key="index" 
                :locationInfo="item"
                :locationIndex="index"
                :locationCheck="checkLocation"
                v-on:remove-location="removeLocation = $event"
                />
            <div class='missing-selection-message' v-show="showErrors && showLocationErrors">{{'You need to enter your' | translate}} {{'Locations' | translate}}</div>
        </div>
        <div class="button-main" @click="validateEntry">{{'Submit' | translate}}</div>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Business Successfully Added' | translate}}</div>
        </div>  
        
    </div>
</template>

<script>
import store from '../../store/store.js'
import Dropdown from '../utility/Dropdown'
import LocationAdd from './LocationAdd'
import Constants from '../../utilities/constants'
import Globals from '../../utilities/globals'
import PhoneNumber from '../utility/PhoneNumber'
import EventBus from '../../utilities/eventBus.js'

export default {
    name: 'BusinessAdd',
    data() {
        return {
            business: {planObj:{},typeObj:{}},
            businessTypes: Constants.businessTypes,
            store,
            locations: [],
            businessPlans: Constants.businessPlans,
            removeLocation: '',
            showErrors: false,
            showSuccess: false,
            checkLocation: false,
            showLocationErrors: false,
            weekSchedule: [],
            formattedPhone: '',
            location: {name:'',street:'',address2:'',city:'',state:'',zip:'',phone:'',email:'',contact:'',businessSchedule: true}
        }
    },
    components: {
        Dropdown,
        LocationAdd,
        PhoneNumber
    },
    methods: {
        getBusinessTypes() {
            // CHANGE TO CHECK IF BUISNESS ALREADY ENTERED
            if (!this.locations.length){
                this.addLocation()
            }
            window.console.log('gET USER INFO', this.$store.getters.user, this.$store.getters.user.businessid,this.$store.getters.user.token)
            if (this.$store.getters.user.businessid || this.$store.getters.user.token) {
                this.$router.push('Login')
            }
        },
        addLocation() {
            const newLoc = Object.assign({}, this.location)
            this.locations.push(newLoc)
        },
        validateEntry() {
            window.console.log('VALIDATE ENTRY', this.locations)
            this.checkLocation = true;
            this.showErrors = false
            // this.checkLocation = false;
            if (!this.business.name || !this.business.typeObj.id || !this.business.planObj.id || !this.business.email || !this.business.phone) {
                this.showErrors = true
            }
            this.checkLocations()
        },
        
        checkLocations() {
            this.showLocationErrors = false
            this.locations.forEach(loc => {
                window.console.log('CK LOC',loc, Object.keys(loc).length)
                if (Object.keys(loc).length < 12) {
                    window.console.log('ERROR WITH LOCATION', Object.keys(loc).length, Object.keys(loc))
                    this.showLocationErrors = true
                }
            })
            if (!this.showLocationErrors) {
                this.submitBusiness()
            }
            
        },
        submitBusiness() {
            // IF LOCATION SCHEDULE SAME AS LOC 1THEN ADD LOC 1 SCHEDULE TO LOCATION
            let locIndex = 0
            let mainSchedule = []
            this.locations.forEach(loc => {
                if (locIndex === 0) {
                    mainSchedule = loc.schedule
                } else {
                    if (loc.businessSchedule) {
                        loc.schedule = mainSchedule
                    }
                }
                locIndex++                
            })
            this.business.locations = this.locations
            this.business.schedule = this.weekSchedule
            this.business.planid = this.business.planObj.id
            this.business.plan = this.business.planObj.name
            this.business.typeid = this.business.typeObj.id
            this.business.type = this.business.typeObj.value
            this.business.startdate = Globals.formatDateYMD(new Date())
            EventBus.$emit('SHOW_SPINNER')
            this.$store.dispatch('addBusiness',this.business).then(response => {
                window.console.log('DATA SUBMITTED RESULT', response)
                if (response.result === 'SUCCESS'){
                    const userdata = this.$store.getters.user
                    userdata.businesstype = this.business.type
                    userdata.locations = this.locations
                    userdata.name = this.business.name
                    userdata.role = 'admin'
                    
                    this.$store.commit('setUser',userdata)
                    this.$store.dispatch('tempValidate').then(tempVal => {
                        EventBus.$emit('HIDE_SPINNER')
                        if (tempVal.result === 'SUCCESS') {
                            window.console.log('NEW LOGIN BUSINESS RESULTS', tempVal)                             
                              this.showSuccess = true
                            setTimeout(() => {
                                this.$router.push('AddService')
                            }, 2000)                           
                        } else {
                            // NEED TO INDICATE ERROR
                            window.console.log('NEW ADD BUSINESS FAILURE')
                        }
                    })
                } else {
                    EventBus.$emit('HIDE_SPINNER')
                    // NEED TO INDICATE ERROR
                    window.console.log('NEW ADD BUSINESS FAILURE else')
                }
            })
            window.console.log('SUBMIT DATA', this.business)
        },
        formatPhoneNumber() {
            let cleaned = ('' + this.formattedPhone).replace(/\D/g, '')
            this.business.phone = cleaned.substr(0,10)
            this.formattedPhone = Globals.formatPhone(cleaned)
        }
    },
    mounted() {
        this.getBusinessTypes()
    },
    watch: {
        removeLocation() {
            window.console.log('REM LOC', this.removeLocation,this.locations)
            // NEW TO ADD CONFIRM MODAL
            this.locations.splice(Number(this.removeLocation),1)
            this.removeLocation = 10000
        }
    },
}
</script>

<style scoped>
.locations-container{
    margin: 0 5%;
    border: 1px solid #2786ca;
}
.location-title-bar{
    background-color: #2786ca;
    color: white;
    height: 30px;
    position: relative;
} 
.location-title{
    font-weight: 600;
    line-height: 30px;
}  
.locations-add-button{
    display: inline-block;
    position: absolute;
    right: 0;
    top: 4px;
}
.button-icon{
    padding: 0 6px;
}


</style>