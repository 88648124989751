<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Update Client' | translate | allCaps}}</div>
        <div class="search-row">
            <SearchAhead 
                v-bind:searchtype="clientSearch.type" 
                v-bind:searchcall="clientSearch.dbcall" 
                v-bind:resultfields="clientSearch.resultfields"
                v-on:item-selected="selectedClient = $event" 
                :searchTerm="selectedClientName"
                :clearterm="true" >
            </SearchAhead>
            <SelectionSwitch  
                :switches="['FIRST','LAST']"
                v-on:item-selected="selectedSearchType = $event"
                :selectedSwitch= "'LAST'"
                >
            </SelectionSwitch>
        </div>
        <client-info-update
            v-if="selectedClient.firstname" 
            v-bind:clientInfo="selectedClient"
            />
        
    </div>
</template>

<script>
import store from '../../store/store'
import SearchAhead from '../utility/SearchAhead'
import SelectionSwitch from '../utility/SelectionSwitch'
import ClientInfoUpdate from './ClientInfoUpdate'

export default {
    name: 'ClientUpdate',
    data() {
        return {
            client: {},
            
            store,
            
            formattedDate: '',
            
            selectedClient: {},
            selectedClientName: '',
            selectedSearchType: 'LAST',
            clientPrefixPlaceholder: 'Select Prefix',
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
        }
    },
    components: {
       SearchAhead,
       SelectionSwitch,
       ClientInfoUpdate
    },
    methods: {              
    },
    mounted() {

    },
    watch: {
        selectedSearchType() {
            if (this.selectedSearchType == 'FIRST') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedSearchType == 'LAST') {
                this.clientSearch = this.lastNameSearch
            }
        },
    }
}
</script>

<style scoped>
.search-row{
    padding: 10px;
}
.form-checkbox-row{
    text-align: left;
    margin-top: -8px;
} 
.dob-format{
    font-size: 12px;
}  
</style>