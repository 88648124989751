export default {
    //PORTAL
    PATIENT_INFORMATION: 'Patient Information',
    MESSAGES: 'Messages',
    HEALTH_RECORDS:'Health Records',
    SKIN: 'Skin',
    ALLERGIES: 'Allergies',
    MEDICATIONS: 'Medications',
    CONDITIONS: 'Conditions',
    PROCEDURES: 'Procedures',
    ADDRESS: 'Address',
    CLOSE_EDIT: 'close edit',
    YOUR_PROFILE_HAS_BEEN_SUCCESSFULLY_UPDATED: 'Your Profile Has Been Successfully Updated',
    PLEASE_CHECK_ALL_THAT_APPLY: 'please check all that apply',
    //NAME
    NAME: 'Name',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PREFIX: 'Prefix',
    SUFFIX: 'Suffix',
    //DATE
    DATE: 'Date',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    //ADDRESS
    STREET: 'Street',
    ADDRESS2: 'Address2',
    CITY: 'City',
    STATE: 'State',
    ZIP: 'Zip',
    //MONEY
    PRICE: 'Price',
    COST: 'Cost',
    QUANTITY: 'Quantity',
    AMOUNT: 'Amount',
    AMOUNT_PAID: 'Amount Paid',
    PAYMENT_TYPE: 'Payment Type',
    PAYMENT_AMOUNT: 'Payment Amount',
    PAY_AMOUNT: 'Pay Amount',
    PAY_TYPE: 'Pay Type',
    PAID: 'Paid',
    DISCOUNT: 'Discount',
    RETAIL: 'Retail',
    RETAIL_PRICE: 'Retail Price',
    TOTAL: 'Total',
    SALES_TAX: 'Sales Tax',
    TAX: 'Tax',
    SAVINGS: 'Savings',
    SALE: 'Sale',
    SALE_PRICE: 'Sale Price',
    SALE_NAME: 'Sale Name',
    SALE_TYPE: 'Sale Type',
    SALE_DISCOUNT: 'Sale Discount',
    QTY: 'Qty',
    CREDIT_AMOUNT: 'Credit Amount',
    CREDIT_APPLIED: 'Credit Applied',
    CREDIT_COST: 'Credit Cost',
    SELECT: 'Select',
    VALUE: 'Value',
    INVOICE: 'Invoice',
    SELECT_PAYMENT_TYPE: 'Select Payment Type',
    BUY: 'Buy',
    BILLED: 'Billed',
    TOTAL_VALUE: 'Total Value',
    BALANCE: 'Balance',
    TAXES: 'Taxes',
    SALE_TOTAL: 'Sale Total',
    TIP: 'Tip',
    MULTIPLE: 'Multiple',
    TIPS: 'Tips', 
    //GENERAL TERMS
    CLIENT: 'Client',
    PACKAGE: 'Package',
    TYPE: 'Type',
    SAVE: 'Save',
    SERVICE: 'Service',
    BACK: 'Back',
    STAFF_SCHEDULE: 'Staff Schedule',
    STAFF: 'Staff',
    START: 'Start',
    END: 'End',
    SCHEDULE: 'Schedule',
    BOOKING: 'Booking',
    BOOKING_DATE: 'Booking Date',
    DATE_PAID: 'Date Paid',
       
    ADMINISTRATION: 'Administration',
    PROVIDER: 'Provider',
    STATUS: 'Status',
    UPDATE: 'Update',
    SUBMIT: 'Submit',
    DESCRIPTION: 'Description',
    CATEGORY: 'Category',
    GET: 'Get',
    INITIAL: 'Initial',
    
    NOTIFY: 'Notify',
    NOTES: 'Notes',
    MEDICAL: 'Medical',
    IMAGES: 'Images',
    TOTAL_HOURS: 'Total Hours',
    TASKS: 'Tasks',
    ROOM: 'Room',
    ROOMS: 'Rooms',
    ROOM_RESTRICTIONS: 'Room Restrictions',
    STATION_RESTRICTIONS: 'Station Restrictions',
    STATION: 'Station',
    STATIONS: 'Stations',
    DAY: 'Day',
    WEEK: 'Week',
    SELECT_ROOM: 'Select Room',
    SELECT_STATION: 'Select Station',
    TITLE: 'Title',
    EMAIL: 'Email',
    EMAIL_ALREADY_EXISTS_FOR: 'Email Already Exists For',
    PHONE: 'Phone',
    PHONE_NUMBER: 'Phone Number',
    PLAN: 'Plan',
    CONTACT: 'Contact',
    SUITE: 'Suite',
    UNIT: 'Unit',
    LOCATION: 'Location',
    LOCATIONS: 'Locations',
    BUSINESS: 'Business',
    WEBSITE: 'Website',
    SERVICES: 'Services',
    FOR_SERVICES: 'For Services',
    PRODUCTS: 'Products',
    FOR_PRODUCTS: 'Products',
    UPDATE_PRODUCT: 'Update Product',
    PRODUCT_CODE: 'Product Code',
    COMPLETE: 'Complete',
    BUSINESS_HOURS: 'Business Hours',
    BUSINESS_LOCATION: 'Business Location',
    COMMISSION_SERVICES: 'Commission Services',
    COMMISSION_PRODUCTS: 'Commission Products',
    ZIPCODE: 'ZipCode',
    ZIP_CODE: 'Zip Code',
    CLOSED: 'Closed',
    STREAM: 'Stream',
    EDIT: 'Edit',
    CAPTURE: 'Capture',
    SIDE_BY_SIDE: 'Side By Side',
    DELETE: 'Delete',
    CLOSE: 'Close',
    YES: 'Yes',
    NO: 'No',
    USER: 'User',
    ADD_USER: 'Add User',
    USER_NAME: 'User Name',
    USER_TYPE: 'User Type',
    USER_LOG_IN: 'User Log In',
    ADD: 'Add',
    PASSWORD: 'Password',
    APT: 'Apt',
    CERTIFICATIONS: 'Certifications',
    DATE_OF_BIRTH: 'Date of Birth',
    MM: 'MM',
    DD: 'DD',
    YYYY: 'YYYY',
    SOCIAL_SECURITY_NUMBER: 'Social Security Number',
    LOGIN: 'Login',
    SELECT_PREFIX: 'Select Prefix',
    SELECT_PROVIDER: 'Select Provider',
    SELECT_HOUR: 'Select Hour',
    SELECT_MINUTE: 'Select Minute',
    HOURS: 'Hours',
    MINUTES: 'Minutes',
    LOG_OUT: 'Log Out',
    LANGUAGE: 'Language',
    LANGUAGES: 'Languages',
    PROFILE: 'Profile',
    UPDATE_PROFILE: 'Update Profile',
    UPDATE_SERVICE: 'Update Service',
    UPDATE_CLIENT: 'Update Client',
    BOOKABLE: 'Bookable',
    CANCEL: 'Cancel',
    //PROMOTION
    PROMOTION: 'Promotion',
    PROMOTIONS: 'Promotions',   
    BUY_PROMOTION: 'Buy Promotion',
    PROMOTION_APPLIED: 'Promotion Applied',
    PROMOTION_NAME: 'Promotion Name',
    PROMOTION_TYPE: 'Promotion Type',
    ADD_PROMOTION: 'Add Promotion',
    ADD_MODIFY_ROOM: 'Add/Modify Room',   
    ADD_MODIFY_CERTIFICATIONS: 'Add/Modify Certifications',
    ADD_SUPPLY: 'Add Supply',
    ADD_DISTRIBUTOR: 'Add Distributor',
    EXISTING_DISTRIBUTORS: 'Existing Distributors',
    ADD_MODIFY_DISTRIBUTORS: 'Add/Modify Distributors',
    SUPPLIES: 'Supplies',
    SUPPLY_NAME: 'Supply Name',
    SUPPLY: 'Supply',
    OFFICE_SUITE:'Office/Suite',
    EXT: 'Ext',
    ADD_MODIFY_SUPPLY: 'Add/Modify Supply',
    EXISTING_SUPPLIES: 'Existing Supplies',
    SELECT_PROMOTION: 'Select Promotion',
    SELECT_PROMOTION_TYPE: 'Select Promotion Type',
    SERV_PROD: 'Serv/Prod',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    //TERMS
    YOU_NEED_TO_ENTER_A: 'You need to enter a',
    YOU_NEED_TO_ENTER_YOUR: 'You need to enter your',
    YOU_NEED_TO_SELECT_A: 'You need to select a',
    YOU_NEED_TO_SELECT: 'You need to select',
    YOU_NEED_TO_ADD: 'You need to add',
    YOU_NEED_TO_ADD_A: 'You need to add a',
    YOU_NEED_TO_ENTER_DATA_TO_CALCULATE_A_TOTAL: 'You need to enter data to calculate a total',
    TO_THE_SALE: 'to the sale',
    USE_BY_LENGTH: 'Use By Length',
    NEEDS_TO_BE_AFTER: 'needs to be after',   
    ADD_PRODUCT: 'Add Product',
    ADD_SERVICE: 'Add Service',    
    ADD_SERVICE_TYPE: 'Add Service Type',
    SERVICE_NAME: 'Service Name',
    ADD_CLIENT: 'Add Client',
    ADD_ROOM: 'Add Room',
    ADD_STATION: 'Add Station',
    ADD_STAFF: 'Add Staff',
    UPDATE_STAFF_SCHEDULE: 'Update Staff Schedule',
    STAFF_ACTIVITY: 'Staff Activity',
    ADD_SALE: 'Add Sale',    
    SELECT_SERVICE: 'Select Service',   
    SELECT_USE_BY_LENGTH: 'Select Use By Length',
    SELECT_BUSINESS_TYPE: 'Select Business Type',
    SELECT_PLAN: 'Select Plan',
    SELECT_STAFF: 'Select Staff',
    SUCCESSFULLY_ADDED: 'Successfully Added',
    SUCCESSFULLY_UPDATED: 'Successfully Updated',
    INVOICE_SUCCESSFULLY_ADDED: 'Invoice Successfully Added',
    INVOICE_SUCCESSFULLY_SUBMITTED: 'Invoice Successfully Submitted',
    INVOICED: 'Invoiced',
    ITEM: 'Item',
    APPLY_TO: 'Apply To',
    ADJUST_INVOICE: 'Adjust Invoice',
    ABBREVIATED_NAME: 'Abreviated Name',
    DISPLAY_NAME: 'Display Name',
    EXISTING_STAFF: 'Existing Staff',
    ADD_STAFF_MEMBER: 'Add Staff Member',
    UPDATE_STAFF: 'Update Staff',
    ADD_BUSINESS: 'Add Business',
    ADD_MODIFY_CERTIFICATION: 'Add/Modify Certification',
    ADD_MODIFY_SERVICE: 'Add/Modify Service',
    ADD_MODIFY_PRODUCT: 'Add/Modify Product',
    ADD_CERTIFICATION: 'Add Certification',
    EXISTING_CERTIFICATIONS: 'Existing Certifications',
    ADD_LOCATION: 'Add Location',
    SELECT_LOCATION: 'Select Location',
    CERTIFICATION: 'Certification',
    EXISTING_ROOMS: 'Existing Rooms',
    EXISTING_PRODUCTS: 'Existing Products',
    EXISTING_ORDERS: 'Existing Orders',
    ORDER: 'Order',
    ORDERS: 'Orders',
    ORDER_TOTAL: 'Order Total',
    ORDER_ITEMS: 'Order Items',
    PO_NUMBER: 'PO Number',
    SHIPPING: 'Shipping',
    RECEIVED: 'Received',
    BUSINESS_TYPE: 'Business Type',
    UPDATE_BUSINESS_HOURS: 'Update Business Hours',
    ADJUST: 'Adjust',
    REFERENCE_NAME: 'Reference Name',
    CONTACT_NAME: 'Contact Name',
    CONTACT_PHONE: 'Contact Phone',
    CONTACT_EMAIL: 'Contact Email',
    NEW_USER: 'New User',
    NEW_PASSWORD: 'New Password',
    PRODUCT_NAME: 'Product Name',
    IN_STOCK_QUANTITY: 'In Stock Quantity',
    UPDATE_PASSWORD: 'Update Password',
    REPEAT_PASSWORD: 'Repeat Password',
    PASSWORDS_MATCH: 'Passwords Match',
    DEFAULT_PASSWORD: 'Default Password',
    PLEASE_UPDATE_YOUR_PASSWORD: 'Please Update Your Password',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS: 'Are you sure you want to Delete this',
    BUSINESS_SUCCESSFULLY_ADDED: 'Business Successfully Added',
    YOU_NEED_TO_SELECT_AT_LEAST_ONE: 'You Need to Select at Least One',
    STAFF_SUCCESSFULLY_ADDED: 'Staff Successfully Added',
    STAFF_SUCCESSFULLY_UPDATED: 'Staff Successfully Updated',
    PROFILE_SUCCESSFULLY_UPDATED: 'Profile Successfully Updated',
    RESTRICTIONS: 'Restrictions',
    CERTIFICATIONS_REQUIRED: 'Certifications Required',
    EXISTING_SERVICES: 'Existing Services',
    EXISTING_STATIONS: 'Existing Stations',
    NO_CERTIFICATIONS: 'No Certifications',
    WORK_HOURS: 'Work Hours',
    HOURS_SCHEDULED: 'Hours Scheduled',
    PICTURE_TAKING_AND_EDITING: 'Picture Taking and Editing',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE: 'Are you sure you want to delete this image',
    PURCHASE_PRODUCT_SUPPLIES: 'Purchase Products/Supplies',
    SUPPLY_CODE: 'Supply Code',
    CLOSE_SIDE_BY_SIDE: 'Close Side-By-Side',
    CHECK_IN: 'Check-In',
    CANCEL_BOOKING: 'Cancel Booking',
    CLICK_TO_ACTIVE: 'Click To Activate',
    THEN_SELECT_IMAGE: 'Then Select Image',
    BILL: 'Bill',
    PAY_AMT: 'Pay Amt',
    PAYRATE: 'Payrate',
    SELECT_TYPE: 'Select Type',
    OFF: 'Off',
    SETUP: 'Setup',
    SETTINGS: 'Settings',
    CLIENTS: 'Clients',
    FIND_CLIENT: 'Find Client',
    DISPLAY_COLOR: 'Display Color',
    PRODUCT: 'Product',
    PACKAGES: 'Packages',
    USED: 'Used',
    PROMO: 'Promo',
    LOGOUT: 'Logout',
    DISTRIBUTOR: 'Distributor',
    ALERT_QUANTITY: 'Alert Quantity',
    ACTIVE: 'Active',
    ID: 'Id',
    BOOKED: 'Booked',
    CREDIT: 'Credit',
    PROD_SERV: 'Prod Serv',
    TRACK_INVENTORY: 'Track Inventory',
    RELATED_PRODUCT: 'Related Product',
    COMPANY: 'Company',
    REPORTS: 'Reports',
    BOOKINGS: 'Bookings',
    INVOICES: 'Invoices',
    INVENTORY: 'Inventory',
    SELECT_MONTH: 'Select Month', 
    BOOKING_INFO: 'Booking Info',
    CREATE_SIDE_BY_SIDE: 'Create Side-By-Side',
    PURCHASE_PRODUCTS_SUPPLIES: 'Purchase Products/Supplies',
    PREV: 'Prev',
    NEXT: 'Next',
    TIME: 'Time',
    ALL: 'All',
    SELECTED_DATE: 'Selected Date',
    START_TIME: 'Start Time',
    HRS: 'Hrs',
    MIN: 'Min',
    LENGTH: 'Length',
    SESSION_LENGTH: 'Session Length',
    NOTE: 'Note',
    SUN: 'Sun',
    MON: 'Mon',
    TUES: 'Tues',
    WED: 'Wed',
    THURS: 'Thurs',
    FRI: 'Fri',
    SAT: 'Sat',
       //NEW
    ESTIMATE: 'Estimate',
    CARD: 'Card',
    CHECK: 'Check',
    BK_DATE: 'Bk Date',
    PROMOTIONS_PURCHASES: 'Promotions/Purchases',
    BUY_PRODUCTS: 'Buy Products',
    DAILY_RECONCILE: 'Daily Reconcile',
    DAILY_RECONCILIATION: 'Daily Reconciliation',
    BUSINESS_FEATURES: 'Business Features',
    ALLOW_TIPS: 'Allow Tips',
    TIP_STATUS: 'Tip Status',
    LOCATION_COORDINATES: 'Location Coordinates',
    LOCATION_COORDINATES_UPDATE: 'Location Coordinates Update',
    COORDINATES_TO_CURRENT_LOCATION: 'Coordinates to Current Location',
    SET: 'Set',
    SUCCESSFULLY: 'Successfully',
    ADD_EXPENSE: 'Add Expense',
    EXPENSES: 'Expenses',
    EXPENSE: 'Expense',
    SUCCESSFULLY_SUBMITTED: 'Successfully Submitted',
    UPDATE_BUSINESS_FEATURES: 'Update Business Features',
    EXPENSE_DATE: 'Expense Date',
    CHECK_NUMBER: 'Check Number',
    RECEIPT: 'Receipt',
    EXPENSE_TYPE: 'Expense Type',
    EXPENSE_AMOUNT: 'Expense Amount',
    SELECT_EXPENSE_TYPE: 'Select Expense Type',
    ROLE: 'Role',
    CONTACT_CLIENT: 'Contact Client',
    TEXT: 'Text',
    SEND: 'Send',
    INVALID_EMAIL: 'Invalid Email',
    HOURS_OPEN: 'Hours Open',
    PERMISSIONS: 'Permissions',
    WEEK_OF: 'Week of',
    TOTAL_BOOKING: 'Total Booking',
    SERVICES_PRODUCTS: 'Services Products',
    TOTAL_PAYMENT_RECEIVED: 'Total Payment Received',
    INV: 'Inv',
    GENERAL_REPORT: 'General Report',
    TOTAL_PAYMENTS_RECEIVED: 'Total Payments Received',
    BOOK_VALUE: 'Book Value',
    CREDIT_CARD: 'Credit Card',
    CASH: 'Cash',
    INCOME_DISTRIBUTION_REPORT: 'Income Distribution Report',
    INVOICES_BY_DATE: 'Invoices By Date',
    PAID_TOTAL: 'Paid Total',
    CHECKS: 'Checks',
    CREDIT_CARDS: 'Credit Cards',
    PROMO_CREDIT: 'Promo Credit',
    PRODUCTS_SERVICES: 'Products Services',
    INVOICE_DETAILS_BY_DATE: 'Invoice Detail By Date',
    TOTAL_BILLED: 'Total Billed',
    PROMO_USED: 'Promo Used',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    PASSWORD_RESET: 'Password Reset',
    FORGOT_PASSWORD: 'Forgot Password',
    PASSWORD_NEEDS_TO_BE_AT_LEAST_8_CHARACTERS: 'Password needs to be at least 8 characters',
    PASSWORD_NEEDS_TO_CONTAIN_AT_LEAST_ONE_NUMBER: 'Password needs to contain at least one number',
    PASSWORD_SUCCESSFULLY_UPDATED: 'Password Successfully Updated',
    ENTER_EMAIL_BELOW: 'Enter Email Below',
    WE_WILL_SEND_YOU_A_LINK_TO: 'We will send you a link to',
    RESET_YOUR_PASSWORD: 'Reset your password',
    COMFIRMATION_CODE: 'Confirmation Code',
    CODE_CANNOT_BE_CONFIRMED: 'Code cannot be confirmed',
    YOUR_EMAIL_OR_PASSWORD_DOES_NOT_MATCH: 'Your Email or Password Does Not Match',
    A_CONFIRMATION_CODE_HAS_BEEN_SENT: 'A Confirmation Text has been sent',
    VIA_TEXT_TO_YOUR_PHONE: 'Via Text to Your Phone',
    PLEASE_ENTER_BELOW: 'Please Enter Below',
    PLEASE_TRY_AGAIN: 'Please Try Again',
    ADD_MODIFY_STATION: 'Add/Modify Station',
    RESET_PASSWORD: 'Reset Password',
    PASSWORD_NEEDS_TO_CONTAIN_ONE_NUMBER: 'Password Needs to Contain One Number',
    ERROR: 'ERROR',
    STAFF_MUST_HAVE_UNIQUE_EMAIL: 'Staff Must Have Unique Email',
    PAY_INFO: 'Pay Info',
    APPLICATION_USE_RESTRICTIONS: 'Application Use Restrictions',
    PATIENT_CONCERNS: 'Patient Concerns',
    MEDICAL_HISTORY: 'Medical History',
    FAMILY_MEDICAL_HISTORY: 'Family Medical History',
    SOCIAL_HISTORY: 'Social History',
    EXAMINATION: 'Examination',
    IMPRESSION: 'Impression',
    ADD_NOTE: 'Add Note',
    EXISTING_NOTE: 'Existing Note',
    EXISTING_NOTES: 'Existing Notes',
    PLEASE_DESCRIBE_YOUR_CONCERNS: 'Please Describe Your Concerns',
    PHARMACY: 'Pharmacy',
    PHARMACY_PHONE: 'Pharmacy Phone',
    NO_PHARMACY_INFO_AVAILABLE: 'No Pharmacy Info Available',
    MRS: 'Mrs',
    MS: 'Ms',
    MR: 'Mr',
    DR: 'Dr',
    MISS: 'Miss',
    FRIEND: 'Friend',
    PREVIOUS: 'Previous',
    PTO: 'PTO',
    FIRST: 'First',
    LAST: 'Last',
    PERSONAL_TIME_OFF: 'Personal Time Off',
    YOUR_SESSION_HAS_TIMED_OUT: 'Your Session has timed out',
    PLEASE_LOGIN_AGAIN: 'Please login again',
    INCOME_REPORT: 'Income Report',
    TOTAL_SALES: 'Total Sales',
    TOTAL_INVOICED: 'Total Invoiced',
    TAX_PRODUCTS: 'Tax Products',
    TAX_SERVICES: 'Tax Services',
    ADJUSTMENTS: 'Adjustments',
    TOTAL_AMOUNT_DUE: 'Total Amount Due',
    PURCHASE: 'Purchase',
    DEVICE_STATUS: 'Device Status',
    APPROVED: 'Approved',
    APPROVE: 'Approve',
    UNAPPROVED: 'Unapproved',
    UNAPPROVE: 'Unapprove',
    CHANGE_STAFF_DISPLAY_ORDER: 'Change Staff Display Order',
    BUY_PRODUCTS_SERVICES_PROMO: 'Buy Products/Services/Promo',
    HOW_DID_YOU_HEAR_ABOUT_US: 'How Did You Hear About Us',
    CLIENT_INFO: 'Client Info',
    NO_ADDRESS_AVAILABLE: 'No Address Available',
    UPDATE_INFO: 'Update Info',
    SURGICAL_PROCEDURES: 'Surgical Procedures',
    SYMPTOMS: 'Symptoms',
    EMERGENCY_CONTACT_NAME: 'Emergency Contact Name',
    EMERGENCY_CONTACT_RELATION: 'Emergency Contact Relation',
    EMERGENCY_PHONE: 'Emergency Phone',
    CLIENT_MEDICAL_HISTORY: 'Client Medical History',
    SURVEY: 'Survey',
    STAFF_VACATIONS: 'Staff Vacations',
    VACATION_DATES: 'Vacation Dates',
        //END NEW
    INFO_CONTENT:{
        DEFAULT_PASSWORD: 'This will be used as the initial login password for all new staff and clients. They will be asked to change it after initial login',
        BUSINESS_ADMINISTRATOR: 'Person in charge of setting up online account. Administrator user account will be set up automatically for this person',
        ADMIN_EMAIL: 'Email is used to login to account and for verification',
        NO_CERTIFICATIONS: 'Certifications are used to help prevent bookings of staff who are not qualified to perform service. Not necessary for daily operation',
        ROOM_RESTRICTIONS: 'Helps prevent service from being booked where cannot be performed. If nothing selected can be performed anywhere. Not necessary for daily operation',
        SELECT_LOCATION: 'Select Locations where service will be offered. You need to select at least on location',
        DISPLAY_NAME: 'This is the name the will appear in the application. Allows use of nicknames as per staff preference',
        ABBREV_NAME: 'This is the name that will appear when there is minimal space.',
        BOOKABLE: 'Select when staff can be booked for service and want to appear on calendar ',
        CERTIFICATIONS: 'Use if want to restrict who can perform certain services',
        COLOR_BLOCKS: 'Assign color for staff as will appear in schedule',
        START_STEPS: 'There are 4 simple steps that you must do to setup and start using your website',
        SETUP_TIME: 'Depending on the size of your business setup time should take less than 15 minutes',
        WATCH_TOUR: 'There are a lot more features and advantages to our software. Watch the "Take a Tour" video to get a good overall of what you can do',
        ENTER_BASIC: 'Enter the basic information about your business, locations, and hours.',
        ADD_SERVICES: 'Add information about the services you offer. We make it easy with our QuickList option which lets you select prepopulated service info which you can change or update at any time',
        ADD_ROOMS: 'Add the Rooms or Stations where you perform services so they can be booked and tracked.',
        ADD_STAFF: 'You have already been automatically added as bookable staff. This can be easily changed by updating your profile. Add any additional staff and you are ready to begin using our software to help run and grow your business.'
    },
    MESSAGE_CONTENT:{
        ERROR_TITLE: 'Error Message',
        ADD_STAFF: 'No staff members were assigned to this location. Update staff for this location'
    }
    


}