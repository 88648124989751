<template>
    <div>
        <div class="zip-input-row">
            <!-- <label for="name" class="form-input-label">{{'Zip' | translate}}:</label>  -->
            <input class="input-narrow" v-model="zip" @input="checkZip" placeholder="Zip"/>           
            &nbsp;&nbsp;{{city}}{{comma}}{{state}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZipLookup',
    data() {
        return {
            location: {},
            zip: '',
            city: '',
            state: '',
            comma: ''
        }
    },
    props: [
        'existingzip'
    ],
    methods: {
        checkZip() {
            if (this.zip.length > 4) {
                this.getZipInfo()
            }
        },
        getZipInfo() {
            const vm = this
            var client = new XMLHttpRequest()
            const url = "http://api.zippopotam.us/us/" + vm.zip.substr(0,5)
            client.open("GET", url, true)
            client.onreadystatechange = function() {
                if(client.readyState == 4) {
                    const data = JSON.parse(client.responseText)                    
                    window.console.log('RESPONSE dETAIL', data.places[0]['place name'], data.places[0]['state abbreviation'] )
                    vm.city = data.places[0]['place name']
                    vm.state = data.places[0]['state abbreviation'] 
                    vm.comma = ', '
                    const zipInfo = {
                        zip: vm.zip,
                        city: vm.city,
                        state: vm.state
                    } 
                    vm.$emit('zip-found',zipInfo)                 
                }
            }

            client.send();
        }
    },
    mounted() {
        if (this.existingzip) {
            this.zip = this.existingzip
        }
    },
    watch: {
        existingzip() {
            this.zip = this.existingzip
        }
    },
}
</script>
<style scoped>
input {
    width: 80px;
    height: 20px;
    padding: 5px;
    margin-left: 10px;
}
.zip-input-row {
    display: inline-block;
    text-align: left; 
}
.info-zip {
    margin-left: 10px;
    left: 0;
}
</style>