<template>
    <div class="dropdown-component">
        <div 
            class="dropdown-selected-container"
            :style="dropstyle" 
            @click.stop="checkSelection">
            {{getSelected}}
            <i class="fas fa-caret-down" @click.stop="checkSelection" v-show="showCaret"></i>
        </div>
        <div class="dropdown-selection-container"  v-show="showSelection">
            <div 
                v-for="(item,index) in optioncontent" 
                v-bind:key="index" 
                class="dropdown-item" 
                @click="setSelected(item)">
                {{item.name}}
            </div>
        </div>
    </div>
    </template>
    <script>
    import Globals from '../../utilities/globals.js'
    
    export default {
        name:'Dropdown',
        data: function() {
            return {
                showSelection: false,
                activeItem: '',
                showCaret: true,
            }
        },
        props: ['selected','placeholder','optioncontent', 'dropstyle','caretshow'],
        methods: {
            setSelected(item) {
                this.activeItem = item.name
                this.showSelection = false
                document.removeEventListener('click', this.handleOutsideClick)
                this.$emit('item-selected', item)
                window.console.log('CARET SHOW', this.caretshow, item)
                this.showCaret = this.caretshow ? true : false            
            },
            checkSelection() {
                window.console.log('CLICKED ON DROP SELECTION',this.optioncontent)
                if (this.showSelection) {
                    this.showSelection = false
                    
                } else {
                    this.showSelection = true
                    document.addEventListener('click', this.handleOutsideClick)
                }
            },
            removeOutsideClick() {
                window.console.log('REMOVE OUTSIDE CLICK')
                document.removeEventListener('click', this.handleOutsideClick)
            },
            handleOutsideClick() {
                window.console.log('Handle Outside Click', this.showSelection)
                this.removeOutsideClick()
                if (this.showSelection) {
                    this.showSelection = false
                    
                }
            }
        },
        computed: {
            getSelected(){
                if (this.activeItem) {
                    return this.activeItem
                } 
                if(this.placeholder.length > 1) {
                    return Globals.translate(this.placeholder)
                }
                return ''
            }
        },
        watch: {
            selected: function() {
                this.showSelection = false;
                this.activeItem = this.selected;
            }
        }
    }
    </script>
    <style>
    .dropdown-component{
        display: inline-block;
        position: relative;
        vertical-align: top;
        top: -3px;
    }
    .dropdown-selected-container{
        height: 30px;
        border: 1px solid black;
        padding: 0 5px 2px 5px;
        margin-left: 5px;
        min-width: 80px;
        background-color: white;
        font-weight: 500;
        color: black;
        line-height: 32px;
        cursor: pointer;
        font-size: 13px;
        position: relative;
        top: 0;
    }
    .dropdown-selection-container{
        position: absolute;
        background-color: white;
        border: 1px solid black;
        min-width: 80px;
        left: 5px;
        top: 26px;
        padding: 0 5px;
        margin-right: 5px;
        z-index: 5;
        width: 150px;
        font-size: 13px;
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .dropdown-item{
        border-bottom: 1px solid lightgray;
        padding: 5px;
        width: 100%;
        cursor: pointer;
        background-color: white;
        color: gray;
        font-size: 13px;
    }
    .selection-text{
        cursor: pointer;
    }
    </style>