<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <!-- <div class="form-input-row" >
            <div class="form-input-label add-format">{{'Location' | translate | allCaps}}</div>
            <div class="form-content-item" v-if="!statusUpdate">               
                <Dropdown  
                    v-on:item-selected="selectedLocation = $event" 
                    v-bind:selected="selectedLocation.name" 
                    v-bind:optioncontent="allLocations"
                    placeholder="Select Location">
                </Dropdown>
            </div>
            <div class="form-content-item" v-if="statusUpdate">
                {{selectedLocation.name}}
            </div>
        </div> -->
        <div class="form-input-row">
            <label for="name" class="form-input-label">{{'Name' | translate}} </label> 
            <input id="name" v-model="certificationName"/>
        </div>
        <div class="form-input-row extra-bottom">
            <label class="form-input-label align-top">{{'Description' | translate}} </label> 
            <textarea class="note-input" v-model="certification.description" />
        </div>
        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Certification' | translate}} {{successMessage | translate}}</div>
        </div> 
        <div class="existing-rooms-container" >
            <div class="existing-title-bar">{{'Existing Certifications' | translate}}</div>
            <div class="existing-row" v-for="(cert,index) in allCertifications" v-bind:key="index">
                <div class="client-list-item" >
                    {{cert.name}}
                    
                        <div class="control-group-item color-blue" @click='modifyCertification(cert)'>
                            <i class="fas fa-pen"></i>
                        </div>
                        
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import store from '../store/store'
import ButtonBar from './utility/ButtonBar'
// import Dropdown from './utility/Dropdown'

export default {
    name: 'Certifications',
    data() {
        return {
            certificationName: '',
            allCertifications: [],
            store,
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            showSuccess: false,
            allLocations: this.$store.getters.allLocations,
            selectedLocation: {},
            selectedCertification: {},
            statusUpdate: false,
            pageTitle: this.statusUpdate ? 'Update Certification' : 'Add Certification',
            successMessage: 'Successfully Added',
            certification: {}
        }
    },
    components: {
        ButtonBar,
    },
    methods: {
        addCertification () {
            window.console.log('ADD CERT')
            // this.$store.dispatch('addRoom', {name: this.roomName,locationid:this.selectedLocation.id}).then(response => {
            //     window.console.log('SUCCESS', response)
            //     if (response.result == 'SUCCESS') {
            //         this.showSuccess = true
            //     }                
            //     this.getRooms()
            // })
        },
        getCertifications() {
            this.$store.dispatch('getCertifications').then(response => {
                window.console.log('GOT CERTIFICATIONS', response)
                this.allCertifications = response.data
            })
            
            window.console.log('GET CERTS',this.allCertifications)
        },
        modifyCertification(cert) {
            window.console.log('MOD CERT', cert)
            this.statusUpdate = true
            this.certificationName = cert.name
            this.certification = cert
            this.pageTitle = 'Update Certification'
            this.buttonList = ['Update','Delete','Cancel']
            this.selectedCertification = cert
        },
        updateCertification() {
            window.console.log('UPDATE CERT')
            const data = {
                name: this.certificationName,
                id: this.selectedCertification.id,
                description: this.certification.description
            }
            this.$store.dispatch('updateCertification', data).then(response => {
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Updated'
                    this.showSuccess = true
                }
            })
        },
        DeleteCertification() {
            // USE ACTIVE AND INACTIVE INSTEAD OF DELETE
            window.console.log('DELETE CERT')
        }
    },
    mounted () {
        this.getCertifications()
    },
    watch: {
       buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.addCertification()               
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
            if (this.buttonClicked.name === 'Update') {
                this.updateCertification()
            }
            if (this.buttonClicked.name === 'Delete') {
                this.DeleteCertification()
            }
        },
        // selectedLocation() {
        //     window.console.log('LOCATION SELECTED', this.selectedLocation,this.selectedLocation.id)
        //     this.allCertifications = []
        //     this.getCertifications(this.selectedLocation.id)
        // }
    },
}
</script>

<style scoped>
.existing-rooms-container{
    margin: 0 20%;
    
}
.existing-title-bar{
    background-color: #2b6e94;
    color: white;
}
.existing-row{   
    border: 1px solid #2b6e94;
    border-top: none;
}
.description-textarea-row{
    text-align: left;
    height: 45px;
}
.align-top{
    vertical-align: top;
}
.note-input{
    width: 200px;
    height: 30px;
    padding-bottom: 10px;
}
.extra-bottom {
    margin-bottom: 15px;
}

</style>