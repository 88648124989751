<template>
    <div>
        <div class="main-footer-container" id="footer-container">
            <div class="disclaimer">&copy;{{new Date().getFullYear()}} Simplify Software v.{{versionNumber}}</div>
            
        </div>
        <div class="footer-cookie-alert-container" v-if="showCookieDisclaimer">
            <div class="footer-cookie-disclaimer">
                We uses cookies to deliver a relevant and personalized service. By continuing to use this site you are agreeing to our use of cookies.
            </div>
            <div class="footer-cookie-button-container">
                <div class="footer-cookie-button" @click="writeCookie" >Allow cookies</div>
            </div>
        </div>
    </div>
</template>

<script>
import Version from '../../data/version.json'
import Cookies from 'js-cookie'

export default {
    name: 'Footer',
    data() {
        return{
            referenceNumber: '',
            versionNumber: Version.version,
            showCookieDisclaimer: true
        }
    },
    methods: {
        setFooter() {
            if (this.$route.name === 'Schedule') {
                document.getElementById('footer-container').style.maxWidth = '100%'
                document.getElementById('footer-container').style.margin = 0
            } else if (this.$route.name === 'Reports') {
                document.getElementById('footer-container').style.maxWidth = '800px'
                document.getElementById('footer-container').style.margin = 0
            } else {
                // document.getElementById('footer-container').style.maxWidth = '800px'
                // document.getElementById('footer-container').style.margin = '0 10%'
            }
        },
        setReference() {
            this.referenceNumber = new Date().getTime()
        },
        writeCookie() {
            Cookies.set('approve',true, {expires: 180})
            this.showCookieDisclaimer = false
        },
        readCookie() {
            if (Cookies.get('approve')) {
                this.showCookieDisclaimer = false
            } 
        }
    },
    mounted() {
        this.setFooter()
        this.setReference()
        this.readCookie()
    },
    watch: {
        $route() {
           this.setFooter()
        }
    }
}
</script>

<style scoped>
.main-footer-container{
    background-color: #eef5f7;
    padding: 5px 0;
    font-size: 12px;
    width: 100%;
    position: fixed;
    bottom: 0;
} 
.disclaimer{
    margin: 0 5%;
    text-align: center;
}
.footer-cookie-alert-container{
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgb(37, 88, 129);
    color: white;
    padding: 20px;
    z-index: 6;
    text-align: left;
} 
.footer-cookie-disclaimer{
    display: inline-block;
    width: 75%;
    padding: 10px;
}
.footer-cookie-button-container{
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    margin: 15px;
}
.footer-cookie-button{
    background-color: #d7e2e6;
    padding: 7px;
    color: #0e4f7f;
    display: inline-block;
    cursor: pointer;
}
@media screen and (max-width: 730px) {
    .footer-cookie-disclaimer{
        width: 95%;
    }
    .footer-cookie-button-container{
        margin: 5px;
    }
    
}

</style>