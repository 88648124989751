<template>
     <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ pageTitle | translate | allCaps}}</div>
        <div v-if="showRequest">
            <div class="form-instructions">
                {{'Enter Email Below' | translate}}. 
                <br>
                {{'We will send you a link to' | translate}} {{'reset your Password' | translate}}
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Email' | translate}}:</label> 
                <input class="input-wide" type="email" v-model="useremail"/>
            </div>
            <div class='missing-selection-message' v-if="showEmailError">*{{'Email entered is invalid' | translate}}</div>
            <div class='missing-selection-message' v-if="showSearchError">*{{'Email not found' | translate}}</div>
        </div>
        <div v-if="showEnterNew">
            <div class="form-instructions">
                {{passwordLength | translate}}. 
                <br>
                {{passwordNumber | translate}}
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'New Password' | translate}}:</label> 
                <input class="input-wide" :type="passwordNewType" v-model="password" @blur="checkPassword"/>
                <div class="password-view-container"  @click="showHideNewPassword" >
                    <i class="far fa-eye"></i>
                </div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Repeat Password' | translate}}:</label> 
                <input class="input-wide" :type="passwordRepeatType" v-model="passwordrepeat" @blur="checkPassword" @input="checkPassword"/>
                <div class="password-view-container" @click="showHideRepeatPassword">
                    <i class="far fa-eye"></i>
                </div>
                <div class="password-match-item" v-if="showMatch">
                    <i class="fas fa-check-square"></i>
                </div>
            </div>
            <div class='missing-selection-message' v-if="showPasswordError">*{{ passwordError | translate}} <span v-if="showPasswordNumberError">{{ passwordNumber | translate}}</span></div>           
        </div>
        <div v-if="showRequestCode">
            <div class="form-instructions">
                {{'A Confirmation Code has been sent' | translate}}. 
                <br>
                {{'via Text to Your Phone' | translate}}. {{'Please Enter Below' | translate}}
            </div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Comfirmation Code' | translate}}:</label> 
                <input class="input-wide" type="text" v-model="confirmationnumber" />
            </div>
            <div class='missing-selection-message' v-if="showCodeError">*{{ codeError | translate}}</div>
        </div>

        <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" />

        <div class='missing-selection-message' v-if="showUpdateError">*{{ 'Error Updating Password' | translate}}</div>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Password Successfully Updated' | translate}}</div>
        </div> 
    </div>
</template>
<script>
import ButtonBar from '../utility/ButtonBar'
import Globals from '../../utilities/globals'
import store from '../../store/store'
import EventBus from '../../utilities/eventBus'

export default {
    name: 'PasswordForgot',
    data() {
        return {
            buttonList: ['Submit','Cancel'],
            buttonClicked: '',
            store,
            useremail: '',
            showEmailError: false,
            showSearchError: false,
            showRequestCode: false,
            showEnterNew: false,
            showRequest: true,
            showSuccess: false,
            showUpdateError: false,
            showPasswordNumberError: false,
            password: '',
            passwordrepeat: '',
            passwordNewType: 'password',
            passwordRepeatType: 'password',
            showPasswordError: false,
            showCodeError: false,
            codeError: 'Code cannot be confirmed',
            passwordLength: 'Password needs to be at least 8 characters',
            passwordNumber: 'Password needs to contain one number',
            passwordMatch:'Passwords do not match',
            passwordError: this.passwordMatch,
            showMatch: false,
            confirmed: false,
            confirmCode: {},
            confirmationnumber: '',
            newUserLogin: false,
            pageTitle: 'Forgot Password'
        }
    },
    components: {
        ButtonBar
    },
    methods: {
        sendPasswordReset() {
            this.showEmailError = false
            if (Globals.validateEmail(this.useremail)) {
                this.$store.dispatch('forgotPassword',{email: this.useremail}).then(response => {
                    window.console.log('RESET DISPATCHED', response.results)
                    if(response.result == 'SUCCESS') {
                        this.showRequestCode = true
                        this.showRequest = false
                        this.showEnterNew = false
                    } else {
                        window.console.log('SHOW SEARCH ERROR')
                        this.showSearchError = true
                    }
                })
            } else {
                window.console.log('SHOW EMAIL ERROR')
                this.showEmailError = true
            }
            
        },
        checkPassword() {
            this.showMatch = false
            this.showPasswordError = false
            this.passwordError = ''
            this.showMatchError = false
            this.showPasswordNumberError = false
            if (this.password === this.passwordrepeat) {
                window.console.log('PASSWORDS MATCH', this.password, this.passwordrepeat)
                this.showMatch = true
            } else {
                this.showPasswordError = true
                this.passwordError = this.passwordMatch
                this.showMatch = false
            } 
            this.checkLength()          
        },
        checkLength(){
            this.showPasswordError = this.passwordrepeat.length >= 8 ? false : true
            if (this.showPasswordError) {
                this.passwordError = this.passwordLength
            }            
            this.checkContainsNumber()
        },
        checkContainsNumber() {
            const hasNumber = /\d/;
            if (!this.showPasswordError) {
                this.showPasswordError = hasNumber.test(this.password) ? false : true
            } 

            this.showPasswordNumberError = true             
        },
        PasswordReset() {
            this.showSuccess = false
            this.showUpdateError = false
            if(this.showMatch && !this.showPasswordError) {
                const data={
                    email: this.useremail,
                    newpassword: this.password,
                    existingpassword: this.$store.getters.user.defaultpassword,
                    phone: this.$store.getters.user.phone
                }
                window.console.log('RESET',data,this.$store.getters.user)               
                EventBus.$emit('SHOW_SPINNER')
                this.$store.dispatch('changePassword',data).then(response => {
                    EventBus.$emit('HIDE_SPINNER')
                    if (response.result === 'SUCCESS') {
                        this.showSuccess = true
                        setTimeout(() => {
                            if (this.newUserLogin) {
                                this.$router.push('Schedule')
                            } else {
                                this.$router.push('Login')
                            }                            
                        }, 2000);
                    } else {
                        this.showUpdateError = true
                    }
                })
            } else {
                this.checkPassword()
            }
        },
        checkConfirm() {
            this.showCodeError = false
            const confirmData = this.$store.getters.confirmCode
            console.log('CONFIRM DATA', confirmData, confirmData.refnum)
            const date = new Date
            const now = date.getTime()
            if(confirmData && confirmData.time) {
                if (now -confirmData.time < 600000) {
                    if (this.confirmationnumber === confirmData.refnum) {
                        this.showEnterNew = true
                        this.showRequestCode = false
                    } else {
                        this.showCodeError = true
                    }
                } else {
                    this.codeError = "To much time has elapsed. Request new confirmation code"
                    this.showCodeError = true
                }
            } else {
                this.showCodeError = true
            }           
        },
        showHideNewPassword() {
            this.passwordNewType = this.passwordNewType === 'password' ? 'text' : 'password'
        },
        showHideRepeatPassword() {
            this.passwordRepeatType = this.passwordRepeatType === 'password' ? 'text' : 'password'
        },
        checkPageType() {
            // let urlParams = new URLSearchParams(window.location.search);
            if (this.$route.query.loggin && this.$route.query.loggin === 'new') {
                window.console.log('NEW USER RESET',this.$store.getters.user.email)
                this.showRequestCode = true
                this.showRequest = false
                this.useremail = this.$store.getters.user.email
                this.newUserLogin = true
                this.pageTitle = 'Reset Password'
                this.sendPasswordReset()
            }
            
        }

    },
    mounted() {
        this.checkPageType()
        window.console.log('FORGET MOUNTED',document.cookie)
    },
    watch: {
        
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                if (this.showRequest) {
                    this.sendPasswordReset()
                } else if (this.showRequestCode) {
                    this.checkConfirm()
                } else if (this.showEnterNew) {
                    this.PasswordReset()
                }
                
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.push('Login')
            }
        },
    }
    
}
</script>
<style scoped>
.form-instructions{
    padding: 15px;
    font-size: 16px;
    color: #236c88;
}
.password-match-item{
    display: inline-block;
    color: #2a922a;
    font-size: 26px;
    vertical-align: top;
    margin: 0 5px;
}
.password-view-container{
    display: inline-block;
    color: #8e9b9e; 
    font-size: 16px;
    vertical-align: middle;
    margin: 0 5px;
    cursor: pointer;
}      
</style>
