<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Add Sale' | translate | allCaps}}</div>
        
            <div class="form-input-row">
                <label class="form-input-label">{{'Name' | translate}}:<span class="required-entry">*</span></label> 
                <input class="input-wide" v-model="sale.name"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !sale.name">*{{'You need to enter a' | translate}} {{'Sale Name' | translate}}</div>

            <div class="form-text-input-row">
                <label class="form-input-label">{{'Description' | translate}}:</label> 
                <textarea class="form-text-input" v-model="sale.description" />
            </div>

             <div class="form-input-row">
                <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="date-selection-container">
                    <input class="input-wide" v-model="sale.selectedStartDate" v-on:focus="startFocus"/>
                    <div class="inline-calendar-picker" v-if="showStartCalendar">
                        <CalendarMini 
                            v-on:date-selected="startDate = $event"/>
                    </div>
                </div>
            </div>
            <div class='missing-selection-message' v-if="showError && !sale.selectedStartDate">*{{'You need to select a' | translate}} {{'Start Date' | translate}}</div>
            
            <div class="form-input-row">
                <label class="form-input-label">{{'End Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="date-selection-container">
                    <input class="input-wide" v-model="sale.selectedEndDate" v-on:focus="endFocus"/>
                    <div class="inline-calendar-picker" v-if="showEndCalendar">
                        <CalendarMini 
                            v-on:date-selected="endDate = $event"/>
                    </div>
                </div>
            </div>
            <div class='missing-selection-message' v-if="showError && !sale.selectedEndDate">*{{'You need to select a' | translate}} {{'End Date' | translate}}</div>
            <div class='missing-selection-message' v-if="dateError">*{{'End Date' | translate}} {{'needs to be after' | translate}} {{'Start Date' | translate}}</div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Type' | translate}}:<span class="required-entry">*</span></label> 
                <div class="form-content-item">               
                    <Dropdown  
                        v-on:item-selected="selectedType = $event" 
                        v-bind:selected="selectedType.name" 
                        v-bind:optioncontent="saleTypes"
                        placeholder="Select Type">
                    </Dropdown>
                </div>
            </div>
            <div class='missing-selection-message' v-if="showError && !selectedType.id">*{{'You need to select a' | translate}} {{'Sale Type' | translate}}</div>

            <div class="sale-item-list-container" v-if="selectedType.type === 'multi'" >
                <div>
                    <div class="sale-item-price item-type title-row">{{'Buy' | translate}}</div>
                    <div class="sale-item-price item-type title-row"> {{'Discount' | translate}}</div>
                    <div class="sale-item-price item-type title-row"> {{'Get' | translate}}</div>
                    <div class="sale-item-price item-type title-row"> {{'Discount' | translate}}</div>
                </div>
                <div class="sale-item-row">
                    <div class="sale-item-price item-type"><input class="sale-type-input" v-model="multi.buy" /></div>
                    <div class="sale-item-price item-type"><input class="sale-type-input" v-model="formattedBuyDiscount" v-on:blur="setBuyPercent" v-on:focus="setBuyNumber"/></div>
                    <div class="sale-item-price item-type"><input class="sale-type-input" v-model="multi.get"/></div>
                    <div class="sale-item-price item-type"><input class="sale-type-input" v-model="formattedGetDiscount" v-on:blur="setGetPercent" v-on:focus="setGetNumber"/></div>
                    
                </div>
            </div>
            
            <div class="form-input-row">
                <label class="form-input-label">{{'Category' | translate}}:<span class="required-entry">*</span></label> 
                <div class="checkbox-container">
                    <RadioButton 
                        :rbLabel="'All Services'"
                        :rbStatus="applyTo.allservices"
                        :rbRef="'allservices'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    <RadioButton 
                        :rbLabel="'All Products'"
                        :rbStatus="applyTo.allproducts"
                        :rbRef="'allproducts'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    <RadioButton 
                        :rbLabel="'All Promotions'"
                        :rbStatus="applyTo.allpromotions"
                        :rbRef="'allpromotions'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    <!-- <div class="checkbox-item">
                        <CheckBox 
                            v-bind:checkBoxLabel="'All Promotions'"
                            :cbmodel="applyTo.allpromotions"
                            :cbref="'allpromotions'"
                            v-bind:labelStyle="'label-small'"
                            v-on:item-selected="applyTo.allpromotions = $event" 
                            v-on:cb-selected="checkWatcher = $event">
                        </CheckBox>
                    </div> -->
                </div>

            </div>
            <div class="form-input-row">
                <label class="form-input-label"> </label> 
                <div class="checkbox-container">
                    <RadioButton 
                        :rbLabel="'Select Services'"
                        :rbStatus="applyTo.services"
                        :rbRef="'services'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    <RadioButton 
                        :rbLabel="'Select Products'"
                        :rbStatus="applyTo.products"
                        :rbRef="'products'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    <RadioButton 
                        :rbLabel="'Select Promotions'"
                        :rbStatus="applyTo.promotions"
                        :rbRef="'promotions'"
                        :labelStyle="'label-small'"
                        v-on:rb-selected="rbWatcher = $event"
                    />
                    
                </div>

            </div>
            <div class='missing-selection-message' v-if="showError && !selectedCategory.length">*{{'You need to select a' | translate}} {{'Sale Type' | translate}}</div>
            
            <div class="sale-item-list-container" v-if="saleItems.length > 0 && selectedType.type==='general'">
                <div>
                    <div class="sale-item-name title-row">{{'Service' | translate}}</div>
                    <div class="sale-item-price title-row"> {{'Retail Price' | translate}}</div>
                    <div class="sale-item-price title-row"> {{'Sale Price' | translate}}</div>
                </div>
                <div v-for="(item,index) in saleItems" :key="item.id" class="sale-item-row">
                    <div class="sale-remove-button">
                        <div class="button-icon delete-icon back-color-red" @click="removeItem(index)" ><div class="x-mark">x</div></div>
                    </div>
                    <div class="sale-item-name">{{item.name}}</div>
                    <div class="sale-item-price"> {{item.retailprice | toUSD}}</div>
                    <div class="sale-item-price"> {{ item.retailprice * ((100 - sale.discount)/100) | toUSD}} </div>
                    
                </div>
            </div>

            <div class="sale-item-list-container" v-if="saleItems.length > 0 && selectedType.type === 'multi'" >
                <div>
                    <div class="sale-item-name title-row">{{'Service' | translate}}</div>
                    <div class="sale-item-price title-row item-type"> {{'Retail' | translate}}</div>
                    <div class="sale-item-price title-row item-type"> {{'Initial' | translate}}</div>
                    <div class="sale-item-price title-row item-type"> {{'Multiple' | translate}}</div>
                </div>
                <div v-for="(item,index) in saleItems" :key="item.id" class="sale-item-row">
                    <div class="sale-remove-button-wide">
                        <div class="button-icon delete-icon back-color-red" @click="removeItem(index)" ><div class="x-mark">x</div></div>
                    </div>
                    <div class="sale-item-name">{{item.name}}</div>
                    <div class="sale-item-price item-type"> {{item.retailprice | toUSD}}</div>
                    <div class="sale-item-price item-type"> {{ item.retailprice * ((100 - sale.multi.buydiscount)/100) | toUSD}} </div>
                    <div class="sale-item-price item-type"> {{item.retailprice * ((100 - sale.multi.getdiscount)/100) | toUSD}}</div>
                </div>
            </div>

            <div class="form-input-row" v-if="selectedType.type === 'general'">
                <label class="form-input-label">{{'Discount' | translate}} %:<span class="required-entry">*</span></label> 
                <input  v-model="formattedDiscount" v-on:blur="setToPercent" v-on:focus="setToNumber"/>
            </div>
            <div class='missing-selection-message' v-if="showError && !sale.discount && selectedType.type === 'general'">*{{'You need to enter a' | translate}} {{'Sale Discount' | translate}}</div>

            <div class="form-add-item-row" v-if="applyTo.products === true">
                <div class="form-input-label add-format">{{'Add Product' | translate | allCaps}}</div>
                <div class="form-content-item">               
                    <SearchAhead 
                        v-show="showProductSearch"
                        v-bind:searchtype="'Products'" 
                        v-bind:searchcall="'getProducts'" 
                        v-bind:resultfields="['company','name']"
                        v-on:item-selected="selectedProduct = $event" >
                    </SearchAhead>
                </div>
            </div>
            <div class="form-add-item-row" v-if="applyTo.services === true">
                <div class="form-input-label add-format">{{'Add Service' | translate | allCaps}}</div>
                <div class="form-content-item">               
                    <Dropdown  
                        v-on:item-selected="selectedService = $event" 
                        v-bind:selected="selectedService.name" 
                        v-bind:optioncontent="allServices"
                        placeholder="Select Service">
                    </Dropdown>
                </div>
            </div>
            <div class="form-add-item-row" v-if="applyTo.promotions === true">
                <div class="form-input-label add-format">{{'Add Promotion' | translate | allCaps}}</div>
                <div class="form-content-item">               
                    <Dropdown  
                        v-on:item-selected="selectedPromotion = $event" 
                        v-bind:selected="selectedPromotion.name" 
                        v-bind:optioncontent="allPromotions"
                        placeholder="Select Promotion">
                    </Dropdown>
                </div>
            </div>
            <br>
            <div class='missing-selection-message' v-if="showError && showListError">*{{'You need to add ' | translate}}{{this.selectedCategory}}{{' to the sale' | translate}}</div>

            <div class="form-input-row" >
                <label class="form-input-label">{{'Notify' | translate}}:<span class="required-entry">*</span></label>
                <div class="checkbox-container">
                    <div class="checkbox-item">
                        <CheckBox 
                            v-bind:checkBoxLabel="'Send Email'"
                            :cbmodel="sale.notify.sendemail"
                            :cbref="'sendemail'"
                            v-bind:labelStyle="'label-small'"
                            v-on:item-selected="sale.notify.sendemail = $event" >
                        </CheckBox>
                        <CheckBox 
                            v-bind:checkBoxLabel="'Send Text'"
                            :cbmodel="sale.notify.sendtext"
                            :cbref="'sendtext'"
                            v-bind:labelStyle="'label-small'"
                            v-on:item-selected="sale.notify.sendtext = $event" >
                        </CheckBox>
                        <CheckBox 
                            v-bind:checkBoxLabel="'Send Now'"
                            :cbmodel="sale.notify.sendnow"
                            :cbref="'sendnow'"
                            v-bind:labelStyle="'label-small'"
                            v-on:item-selected="sale.notify.sendnow = $event" >
                        </CheckBox>
                        <CheckBox 
                            v-bind:checkBoxLabel="'Send Before '"
                            :cbmodel="sale.notify.sendbefore"
                            :cbref="'sendbefore'"
                            v-bind:labelStyle="'label-small'"
                            v-on:item-selected="sale.notify.sendbefore = $event" >
                        </CheckBox>
                    </div>
                </div>
            </div>

            <div>
                <div class="button-main" @click="validateValues">{{'Add Sale' | translate}} </div>
            </div>
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Sale' | translate}} {{'successfully added' | translate}}</div>
            </div>


        </div>
    </div>
</template>

<script>
import CalendarMini from './utility/CalendarMini'
import SearchAhead from './utility/SearchAhead'
import Dropdown from './utility/Dropdown'
import RadioButton from './utility/RadioButton'
import store from '../store/store'
import CheckBox from './utility/CheckBox'

export default {
    name: 'AddSale',
    data() {
        return{
            sale: {
                selectedStartDate: '',
                selectedEndDate: '',
                multi: {buy:1, get:1, buydiscount: 0, getdiscount:0},
                notify:{sendemail: false, sendtext: false, sendnow: false, sendbefore: false}
            },
            applyTo: {allproducts: false, allservices: false, allpromotions: false, products: false, services: false, promotions: false},
            showSuccess: false,
            showError: false,
            showStartCalendar: false,
            showEndCalendar: false,
            showProductSearch: true,
            dateError: false,
            showListError: false,
            selectedCategory: '',
            startDate: '',
            endDate: '',
            selectedProduct: {},
            selectedService: {},
            selectedPromotion: {},
            selectedType: {},
            allServices: [],
            allPromotions: [],
            rbWatcher: '',
            saleItems: [],
            discount: 10,
            formattedDiscount: '',
            formattedBuyDiscount: '0%',
            formattedGetDiscount: '0%',
            store,
            saleTypes: [{id:1,name:'Applies to All',type:'general'},{id:2,name:'Buy X get discount',type:'multi'}],
            multi: {buy:1, get:1, buydiscount:0, getdiscount:0},
        }
    },
    components:{
        CalendarMini,
        SearchAhead,
        Dropdown,
        RadioButton,
        CheckBox
    },
    mounted() {
        this.getServices()
        this.getPromotions()
    },
    methods: {
        startFocus(){
            this.showStartCalendar = true;
        },
        endFocus(){
            this.showEndCalendar = true;
        },
        validateValues() {
            this.dateError = false
            this.showError = false
            this.showListError = false
            // CHECK THAT END DATE AFTER START DATE
            if (new Date(this.sale.startdate) > new Date(this.sale.enddate)) {
                this.dateError = true
                this.showError = true
            } 
                  
            // CHECK THAT REQUIRED INPUT VALUES ENTERED
            if(!this.sale.name || !this.sale.startdate || !this.sale.enddate || (this.selectedType === 'general' && !this.sale.discount) || !this.selectedCategory.length) {
                this.showError = true
                window.console.log('SE date',this.sale.name ,!this.sale.startdate , !this.sale.enddate , (this.selectedType === 'multi' &&!this.sale.discount) , !this.selectedCategory.length,this.selectedCategory )
            }
            //CHECK IF LIST ITEM ADDED IF INDIVIDUAL ITEM SELECTED
            if( (this.selectedCategory === 'services' || this.selectedCategory === 'products' || this.selectedCategory === 'promotions') && this.saleItems.length === 0 ) {
                window.console.log('SE category')
                this.showListError = true
                this.showError = true
            }
            if (!this.showError) {
                this.submitSale()
            }           
        },
        submitSale() {
            this.sale.type = this.selectedType
            this.sale.saleitems = this.saleItems
            this.sale.category = this.selectedCategory
            window.console.log('SUBMIT SALE',this.sale)
            this.$store.dispatch('addSale', this.sale).then(response => {
                if (response.substr(0,7) === 'SUCCESS'){
                    this.showSuccess = true;
                }
            })
        },
        getServices() {
            this.$store.dispatch('getServices').then(response => {
                this.allServices = response
            })
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                this.allPromotions = response
            })
        },
        setToNumber() {
            window.console.log('SET NUMBER', this.formattedDiscount)
            this.formattedDiscount = this.formattedDiscount.length > 0 ? Number(this.formattedDiscount.substr(0,this.formattedDiscount.length-1)) : ''
            this.sale.discount = this.formattedDiscount
        },
        setToPercent() {
                   
            this.sale.discount = this.formattedDiscount
            this.formattedDiscount = this.formattedDiscount + '%'
        },
        removeItem(index) {
            window.console.log('REMOVE ITEM', this.formattedDiscount) 
            this.saleItems.splice(index,1)    
        },
        setBuyPercent() {                   
            this.sale.multi.buydiscount = this.formattedBuyDiscount
            this.formattedBuyDiscount = this.formattedBuyDiscount + '%'
        },
        setBuyNumber() {
            this.formattedBuyDiscount = this.formattedBuyDiscount.length > 0 ? Number(this.formattedBuyDiscount.substr(0,this.formattedBuyDiscount.length-1)) : ''
            this.sale.multi.buydiscount = Number(this.formattedBuyDiscount)
        },
        setGetPercent() {                   
            this.sale.multi.getdiscount = this.formattedGetDiscount
            this.formattedGetDiscount = this.formattedGetDiscount + '%'
            window.console.log('SET GET PER', this.formattedGetDiscount)
        },
        setGetNumber() {
            this.formattedGetDiscount = this.formattedGetDiscount.length > 0 ? Number(this.formattedGetDiscount.substr(0,this.formattedGetDiscount.length-1)) : ''
            this.sale.multi.getdiscount = Number(this.formattedGetDiscount)
            window.console.log('SET GET NUM', this.formattedGetDiscount)
        },

    },
    watch: {
        startDate() {
            this.sale.selectedStartDate = this.startDate.selectedDate;
            this.sale.startdate = `${this.startDate.year}/${this.startDate.month + 1}/${this.startDate.day}`            
            this.showStartCalendar = false;
        },
        endDate() {
            this.sale.selectedEndDate = this.endDate.selectedDate;
            this.sale.enddate = `${this.endDate.year}/${this.endDate.month + 1}/${this.endDate.day}`
            this.showEndCalendar = false;
        },
        rbWatcher() {
            let vm = this
            window.console.log('RB', this.rbWatcher)
            const watchArray = this.rbWatcher.split('~')
            vm.applyTo[watchArray[0]] = Boolean(watchArray[1])
            if (Boolean(watchArray[1]) === true) {
                window.console.log('TRUE', this.rbWatcher)
                this.selectedCategory = watchArray[0]
            }
            Object.keys(vm.applyTo).forEach(item => {               
                if(item !== watchArray[0]){
                    vm.applyTo[item] = false
                }
            })
        },
        selectedProduct() {
            this.saleItems.push(this.selectedProduct)
            window.console.log('SALE ITEMS', this.saleItems, this.selectedType.type)
        },
        selectedService() {
            this.saleItems.push(this.selectedService)
        },
        selectedPromotion() {
            this.saleItems.push(this.selectedPromotion)
        }       
            
       
    }
    
}
</script>

<style scoped>
.form-text-input-row{
    text-align: left;
    height: 60px;
}
.form-text-input{
    width: 195px;
    height: 35px;
    font-size: 14px;
    padding: 5px;
    vertical-align: top;
}
.checkbox-item{
    display: inline-block;
    padding: 0 7px;
}
.add-format{
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    margin-top: 2px;
}
.sale-item-list-container{
    border: 1px solid #4096e0;
    display: inline-block;
    margin-bottom: 15px;
    border-bottom: none;
    font-size: 13px;
}
.sale-item-row{
    border-bottom: 1px solid #4096e0;
    position: relative;
}
.sale-item-name{
    width: 150px;
    border-right: 1px solid #4096e0;
    display: inline-block;
    padding: 3px;
}
.sale-item-price{
    width: 80px;
    border-right: 1px solid #4096e0;
    display: inline-block;
    padding: 3px;
}
.sale-item-price:last-child{
    border-right: none;
}
.title-row{
    background-color: #4096e0;
    color: white;
    font-size: 12px;
    font-weight: 600;
    border-right: 1px solid white;
}
.sale-remove-button{
    left: 330px;
    top: 0;
    position: absolute;
}
.sale-remove-button-wide{
    left: 355px;
    top: 0;
    position: absolute;
}
.delete-icon{
    padding: 3px 8px;
    border-radius: 12px;
}
.x-mark{
    margin-top: -2px;
}
.item-type{
    width: 60px;
}
.sale-type-input{
    width: 50px;
    text-align: center;
}
    
</style>