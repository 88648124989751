<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Staff Schedule' | translate | allCaps}}</div>
            <div class="form-input-row">
                <label class="form-input-label">{{'Staff' | translate}}:<span class="required-entry">*</span></label> 
                <Dropdown  
                    v-on:item-selected="selectedStaff = $event" 
                    v-bind:selected="selectedStaff.name" 
                    v-bind:optioncontent="allStaff"
                    placeholder="Select Staff">
                </Dropdown>
            </div>
            <div class='missing-selection-message' v-if="showErrors && !selectedStaff.id">*{{'You need to select a ' | translate}}{{'Staff Member' | translate}} </div>

            <!-- LOCATIONS -->
        <div class="form-checkbox-row" v-if="showLocations">
            <div class="locations-container" :class="{'show-schedule':showSchedules} ">
                <div class="locations-checkbox-item" 
                    v-for="(location, index) in staffLocations" 
                    v-bind:key="'cert' + index"
                    >
                    <CheckBox 
                        v-bind:checkBoxLabel="location.name"
                        v-on:item-selected="$set(locations, location.id, $event),ckSch = new Date()" 
                        v-bind:cbmodel="locations[location.id]"
                        :cbref="location.id"
                        />
                    <div class="radiobutton-container">
                        <!-- <RadioButton/> -->
                        <RadioButton 
                            :rbLabel="'Primary'"
                            :rbStatus="applyTo[index].status"
                            :rbRef="location.id"
                            v-on:rb-selected="rbWatcher = $event"
                        />
                    </div>
                    <WeekSchedule
                        class="staff-week-schedule"
                        v-if="locations[location.id]"
                        :scheduletitle="'Work Hours'"
                        :closename="'Off'"
                        v-on:schedule-updated="scheduleInfo[location.id] = $event"
                        v-on:totalhours-updated="hoursUpdated = $event,locationHrsRef = location.id"
                        :existingschedule="location.data"
                    />
                </div>
                
            </div>
        </div>
        

            <div class="form-input-row">
                <label class="form-input-label">{{'Start Date' | translate}}:<span class="required-entry">*</span></label> 
                <div class="date-selection-container">
                    <input class="input-wide" v-model="selectedStartDate" v-on:focus="startFocus"/>
                    <div class="inline-calendar-picker" v-if="showStartCalendar">
                        <CalendarMini 
                            v-on:date-selected="scheduleStartDate = $event"/>
                    </div>
                </div>
            </div>
             <div class='missing-selection-message' v-if="showErrors && !scheduleStartDate.year">*{{'You need to select a ' | translate}}{{'Start Date' | translate}} </div>

            <div class="form-input-row">
                <label class="form-input-label">{{'Total Hours' | translate}}:</label> 
                {{totalHours}}
            </div>
            <div class='missing-selection-message' v-if="showErrors && totalHours == 0">*{{'You need to have' | translate}} {{'Hours Scheduled' | translate}} </div>
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" />

            <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Staff Schedule' | translate}} {{'Successfully Updated' | translate}}</div>
        </div> 
            
        </div>
    </div>
</template>

<script>
import store from '../../store/store'
import Dropdown from '../utility/Dropdown'
import Constants from '../../utilities/constants'
import WeekSchedule from '../utility/WeekSchedule'
import CalendarMini from '../utility/CalendarMini'
import ButtonBar from '../utility/ButtonBar'
import RadioButton from '../utility/RadioButton'
import CheckBox from '../utility/CheckBox'

export default {
    name: 'StaffSchedule',
    data() {
        return{
            store,
            allStaff: [],
            selectedStaff: {locations:[]},
            staffSchedule: [],
            
            workTime: Constants.officeTimes,
            daysWeek: Constants.daysOfWeek,
            scheduleUpdated: {},
            existingSchedule: this.$store.getters.currentLocation.schedule,
            hoursLocation:{},
            hoursUpdated:'',
            totalHours: 0,
            showErrors: false,
            showSuccess: false,
            showStartCalendar: false,
            scheduleStartDate: {},
            selectedStartDate: '',
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            locations:{},
            applyTo:[],
            updateSchedule:'',
            updateLocation:'',
            rbWatcher: '',
            ckSch: '',
            allLocations: this.$store.getters.allLocations,
            showSchedules: false,
            showLocations: false,
            scheduleInfo:{},
            locationHrsRef: '',
            staffLocations: [],
            newExpireDate: '',
        }
    },
    components: {
        Dropdown,
        WeekSchedule,
        CalendarMini,
        ButtonBar,
        RadioButton,
        CheckBox,
    },
    methods: {
        getStaff() {
            this.$store.dispatch('getStaff').then(response => {
                this.allStaff = response.data
                this.allStaff.map(item => {item.name = item.displayname})
            })
        },
        setupStaffLocations() {
            // CREATE ENTRY FOR EACH LOCATION
            this.staffLocations = []
            this.allLocations.forEach(location => {
                // IF STAFF HAS SCHEDULE FOR THAT LOCATION ADD
                let scheduleExists = false
                this.selectedStaff.schedule.forEach(staffSchedule => {
                    if (staffSchedule.id == location.id) {
                        this.staffLocations.push(staffSchedule)
                        scheduleExists = true
                    }
                })
                //ELSE ADD BUSINESS SCHEDULE FOR THAT LOCATION
                if (!scheduleExists) {
                    this.staffLocations.push(this.getLocationBusSchedule(location.id))
                }
            })
            window.console.log('AFTER SETUP STAFF LOCATIONS', this.staffLocations)
        },
        getLocationName(locNumber) {
            let locName = ''
            this.$store.getters.allLocations.forEach(loc => {
                if (loc.id == locNumber) {                    
                    locName = loc.name
                }
            })
            return locName
        },
        startFocus(){
            this.showStartCalendar = true;
        },
        validateSchedule() {
            if (this.totalHours === 0 || !this.selectedStaff.id || !this.scheduleStartDate.year) {
                this.showErrors = true
            } else {
                this.submitSchedule()
            }
        },
        submitSchedule() {
            this.showSuccess = false
            let newSchedule = []
            let updatedLocations = []
            Object.keys(this.locations).forEach(loc => {
                if (this.locations[loc]) {
                    const SiteSchedule = {
                        id: loc,
                        name:this.getLocationName(loc),
                        data: this.scheduleInfo[loc]
                    }
                    newSchedule.push(SiteSchedule)
                    updatedLocations.push(Number(loc))
                }
            })
            const updatedSchedule = {
                staff: `${this.selectedStaff.firstname} ${this.selectedStaff.lastname}`,
                staffid: this.selectedStaff.id,
                startdate: `${this.scheduleStartDate.year}/${this.scheduleStartDate.month + 1}/${this.scheduleStartDate.day}`,
                expiration: this.newExpireDate,
                expiredate: `${this.scheduleStartDate.year + 1}/${this.scheduleStartDate.month + 1}/${this.scheduleStartDate.day}`,
                schedule: newSchedule,
                locations: updatedLocations
            }
            window.console.log('SAVE', updatedSchedule, this.scheduleInfo,this.locations)
            this.$store.dispatch('addStaffSchedule',updatedSchedule).then(response => {
                if (response.result === 'SUCCESS') {
                    this.showSuccess = true
                }
            })
        },
        setExistingSchedule(data) {
            window.console.log('SET EXIStING', data, data.schedule)
            this.staffSchedule = data.schedule
        },
        setApplyTo() {
            // RADIOBUTTON STATUS
            this.applyTo = []
            this.$store.getters.allLocations.forEach(loc => {
                const rbstatus = this.selectedStaff.primarylocation == loc.id ? true : false
                this.applyTo.push({id:loc.id,status:rbstatus})
            })
            window.console.log('APPLY TO SET ', this.applyTo)
        },
        setupBusinessHrSchedules() {
             // IF NO PRIMARY LOCATION OR NO SCHEDULE FOR PRIMARY SHOW SCHEDULE ELSE GIVE SELECTION OPTION FOR ALL LOCATION 
            window.console.log('SCHEDULE NOT UP TO DATE', this.$store.getters.allLocations)
            // this.setLocationSchedules()
            if (this.selectedStaff.locations) {
                let scheduleExists = false
                this.selectedStaff.locations.forEach(locid => {
                    // CHECK IF LOCATION PART OF SCHEDULE
                    this.staffSchedule.forEach(sch => {
                        if (sch.id === locid) {
                            scheduleExists = true
                            window.console.log('sssSCHEDULE EXISTS FOR', locid)
                        }
                    })
                    if (!scheduleExists) {
                        window.console.log('sssSCHEDULE NOT EXISTS FOR', locid)
                        this.addLocationBusSchedule(locid)
                        
                    }
                })
                window.console.log('SET BUS SCHEDULE',this.staffSchedule, 'APPY', this.applyTo,'SELECTED STAFF', this.selectedStaff)
            }
        },
        getLocationBusSchedule(locid) {
            window.console.log('ADD LOC BUS', locid)
            let locSchedule = ''
            this.$store.getters.allLocations.forEach(loc => {
                if (loc.id == locid) {  
                    locSchedule = {
                        id: loc.id,
                        name: loc.name,
                        data: loc.schedule
                    }
                    
                }
            })
            return locSchedule
        },
        setupLocations() {
            window.console.log('SETUP LOCATIONS')
            this.locations = {}
            this.selectedStaff.locations.forEach(loc => {
                this.locations[loc] = true
            })
            window.console.log('SETUP LOCATIONS STAFF', this.locations, this.selectedStaff.locations)
            this.$store.getters.allLocations.forEach(location => {
                if (!this.locations[location.id]) {
                    this.locations[location.id] = false
                }
            })
            setInterval(() => {
                this.showLocations = true
            }, 500);
            
        },
        setDefaultLoc() {
            this.$store.getters.allLocations.forEach(loc => {
                this.locations[loc.id] = false;
            })
        }

    },
    
    mounted() {
        this.setDefaultLoc()
        this.getStaff()
    },
    watch: {
        scheduleUpdated() {
            window.console.log('SS Changed', this.scheduleUpdated)
            // this.calculateHours()
        },
        selectedStaff() {
            window.console.log('Staff Selected', this.selectedStaff, this.allStaff)
            this.showLocations = false
            this.locations={}
            this.staffLocations = []
            // GET STAFF SCHEDULE
            // IF NO SCHDEULE DO NOTHING
            // IF SCHEDULE THEN POPULATE PAGE

            //SET RADIOBUTTON STATUS
            this.setApplyTo()
            // NEED TO BE ABLE TO ADD NON SCHEDULED LOCATIONS 
            
            this.staffSchedule = this.selectedStaff.schedule
            // this.setupBusinessHrSchedules()
            this.setupStaffLocations()
            this.setupLocations()
            
        },   
        scheduleStartDate() {
            // d.setDate(d.getDate()-5);
            let exDate = new Date(this.scheduleStartDate.selectedDate)
            
            this.newExpireDate = new Date(exDate.setDate(exDate.getDate() - 1))
            window.console.log('X DATE', this.newExpireDate)
            
            
            this.selectedStartDate = this.scheduleStartDate.selectedDate
            //             
            this.showStartCalendar = false;
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.validateSchedule()
            }

            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        hoursUpdated() {
            this.hoursLocation[this.locationHrsRef] = this.hoursUpdated
            this.totalHours = 0
            Object.keys(this.hoursLocation).forEach(loc => {
                this.totalHours += Number(this.hoursLocation[loc])
            })
        },
        rbWatcher() {
            const resultArray = this.rbWatcher.split('~')
            this.applyTo.forEach(item => {
                if (item.id == resultArray[0]) {
                    item.status = resultArray[1]
                    if (resultArray[1]) {
                        this.selectedStaff.primarylocation = resultArray[0]
                    }
                } else {
                    item.status = false
                }
            })
        },
        updateSchedule() {
            window.console.log('SCHEDULE UPDATED DOES NOTHING', this.updateSchedule, this.updateLocation,'STAFF SCH', this.staffSchedule)
        },
        ckSch() {
            window.console.log('CHECK SCHEDULE',this.locations)
            this.showStartCalendar = true
            this.showStartCalendar = false
        }
    },
    
}
</script>

<style scoped>
.schedule-table{
    border: 1px solid #0b8ecc;
    display: inline-block;
    margin-bottom: 15px;
}
.schedule-table-row{
    height: 50px;
    vertical-align: top;
    display: block;
    border-bottom: 1px solid #0b8ecc;
}
.schedule-table-row:last-child{
    border-bottom: none;
}
.schedule-table-item{
    display: inline-block;
    width: 80px;
    border-right: 1px solid #0b8ecc;
    font-size: 13px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
}
.table-item-label{
    padding-top: 7px;
    font-weight: 600;
    color: #0b8ecc;
}
.schedule-table-item:last-child{
    border-right: none;
}
.schedule-table-item-dropdown{
    padding-top: 3px;
}
.schedule-table-input{
    width: 60px;
    height: 25px;
}
.schedule-user-info{
    font-size: 12px;
    font-weight: 600;
    min-height: 10px;
    min-width: 1px
}
.title-row{
    background-color: #0b8ecc;
    color: white;
    line-height: 30px;
    border-right: 1px solid white;
}
.description-column{
    width: 80px;
    text-align: right;
    padding-right: 3px;
    background-color: #f7fcff;
}
.week-schedule-container{
    display: inline-block;
}
.location-item{
    display: inline-block;
    font-weight: 600;
    padding: 5px;
}
.radiobutton-container{
    display: inline-block;
}
    
</style>