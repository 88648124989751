<template>
    <div class="blocked-time-container">
        <div class="form-title-row">Add Personal Time Off (PTO)</div>
        <div class="form-input-row">
            <div class="form-input-label">Staff Member: </div>
            <Dropdown 
                v-on:item-selected="bookingInfo.selectedStaff = $event" 
                v-bind:selected="bookingInfo.selectedStaff.name" 
                v-bind:optioncontent="allStaff"
                placeholder="Select Staff Member">
            </Dropdown>
        </div>
        <div class='missing-selection-message' v-show="showErrors && !bookingInfo.selectedStaff.name">*You need to select a Staff member</div>

        <div class="form-input-row">
                <div class="form-input-label">Attributes:</div>
                <CheckBox 
                    v-bind:checkBoxLabel="'All Day Event'"
                    v-on:item-selected="allDayEvent = $event" 
                    />
            </div>
        <div class="form-input-row">
            <div class="form-input-label"></div>
            <CheckBox 
                v-bind:checkBoxLabel="'Multiple Days'"
                v-on:item-selected="multiDayEvent = $event" 
                />
        </div>

        <div class="form-input-row">
            <div class="form-input-label">{{getDateLabel}}</div>
            <DatePicker 
                v-on:date-selected="bookingInfo.startDate = $event" 
                v-bind:mydate="bookingInfo.startDate"
                :placeholder = "'Select Date'"
                />
        </div>
        <div class='missing-selection-message' v-show="showErrors && !bookingInfo.startDate">*You need to select a Start Date</div>  
        <div class="form-input-row" v-if="multiDayEvent">
            <div class="form-input-label">End Date</div>
            <DatePicker 
                v-on:date-selected="bookingInfo.endDate = $event" 
                v-bind:mydate="bookingInfo.endDate"
                :placeholder = "'Select End Date'"
                />
        </div>          
        <div class='missing-selection-message' v-show="multiDayEvent && showErrors && !bookingInfo.endDate">*You need to select an End Date</div>
        <div class='missing-selection-message' v-show="multiDayEvent && showErrors && this.bookingInfo.startDate && this.bookingInfo.endDate < this.bookingInfo.startDate">*Your End Date needs to be after your Start Date</div>
        
        <div class="form-input-row" v-if="!allDayEvent">
                <div class="form-input-label">Start Time: </div>
                <Dropdown 
                    v-on:item-selected="selectedHour = $event" 
                    v-bind:selected="selectedHour.name" 
                    v-bind:optioncontent="officeHours"
                    placeholder="Select Hour">
                </Dropdown> : 
                <Dropdown 
                    v-on:item-selected="selectedMinute = $event" 
                    v-bind:selected="selectedMinute.name" 
                    v-bind:optioncontent="officeMinutes"
                    placeholder="Select Minute">
                </Dropdown>
                {{bookingInfo.starttime | toAMPM}}
            </div>
            <div class='missing-selection-message' v-show="!allDayEvent && showErrors && (!selectedHour.name || !selectedMinute.name)">*You need to select a Start Time</div>
            
            <div class="form-input-row" v-if="!allDayEvent">
                <div class="form-input-label">Length: </div>
                 Hrs
                 <Dropdown 
                    v-on:item-selected="selectedSessionHours = $event" 
                    v-bind:selected="selectedSessionHours.name" 
                    v-bind:optioncontent="sessionHours"
                    placeholder="Hours">
                </Dropdown> 
                
                    Min
                    <Dropdown 
                        v-on:item-selected="selectedSessionMinutes = $event" 
                        v-bind:selected="selectedSessionMinutes.name" 
                        v-bind:optioncontent="officeMinutes"
                        placeholder="Minutes">
                    </Dropdown> 
                
            </div>
            <div class='missing-selection-message' v-show="!allDayEvent && showErrors && (!selectedSessionHours.name || !selectedSessionMinutes.name)">*You need to select the Session Length</div>
            <div class="form-input-row" >
                <div class="form-input-label"></div>
                <div class="button-main" @click="submitPTO">Submit PTO</div>
            </div>
    </div>
</template>

<script>
import Dropdown from './utility/Dropdown'
import store from '../store/store.js'
import DatePicker from './utility/DatePicker'
import Constants from '../utilities/constants.js'
import Filters from '../utilities/filters.js'
import CheckBox from './utility/CheckBox'
// import DrawCanvas from './utility/DrawCanvas'

export default {
    name: 'BlockedTime',
    data: function() {
        return {
            allStaff: [],
            store,            
            showErrors: false,
            bookingInfo: {
                selectedStaff: {},
                startDate: '',
                endDate: '',
                starttime: this.getStartTime,
            },
            selectedSessionHours: {},
            selectedSessionMinutes: {},
            selectedHour: {name: 0},
            selectedMinute: {name: 0},
            officeHours: Constants.officeHours,
            officeMinutes: Constants.officeMinutes,
            sessionHours: Constants.sessionHours,           
            allDayEvent: false,
            multiDayEvent: false,
            imgTest: ''
        }
    },
    components: {
        Dropdown,
        DatePicker,
        CheckBox,
        // DrawCanvas,
    },
    methods: {
        getStaffInfo() {
            if (this.$store.getters.allStaff.length) {                
                this.allStaff = this.$store.getters.allStaff
                this.allStaff.map(item => {item.name = item.firstname + ' ' + item.lastname})
                window.console.log('STAFF EXIST', this.allStaff)
            } else {
                this.$store.dispatch('getStaff').then( response => {
                    this.allStaff = response.data
                    this.allStaff.map(item => {item.name = item.firstname + ' ' + item.lastname})
                    window.console.log('GOT STAFF',response, this.allStaff)                  
                })
            }
        },
        submitPTO() {
            window.console.log('SUBMIT')
            this.verifyForm();
            
        },
        verifyForm() {
            window.console.log('VERIFY')
            this.showErrors = false;
            window.console.log('SE', this.showErrors)
            // verify staff selected
            if (!this.bookingInfo.selectedStaff.name) { this.showErrors = true }
            window.console.log('SE', this.showErrors)
            // verify startdate selected
            if (!this.bookingInfo.startDate) { this.showErrors = true }
            window.console.log('SE', this.showErrors)
            // if multiday verify end date selected and verify end date is after start date
            if (this.multiDayEvent && !this.bookingInfo.endDate) { 
                this.showErrors = true
                window.console.log('SE multi & not end date', this.showErrors)
            } else if (this.multiDayEvent && this.bookingInfo.startDate && this.bookingInfo.endDate < this.bookingInfo.startDate) {
                 this.showErrors = true 
                 window.console.log('SE multi & not end date', this.showErrors)
            }
            window.console.log('SE', this.showErrors)
            // if not all day verify start time selected
            if (!this.allDayEvent && (!this.selectedHour || !this.selectedMinute )) { this.showErrors = true }
            window.console.log('SE all Day',this.allDayEvent, this.showErrors)
            // if not all day verify duration selected
            if (!this.allDayEvent && (!this.selectedSessionHours.id || !this.selectedSessionMinutes.id )) { this.showErrors = true }
            // if everything verifies then check for conflicts
            window.console.log('SE dur',!this.selectedSessionHours.id, !this.selectedSessionMinutes.id, this.showErrors)
            if (!this.showErrors) { this.checkConflicts() }
            
        },
        checkConflicts() {
            window.console.log('CK CONF')
            // git bookings for staff for day(s) selected

            // check bookings for time conflict

            // if no conflicts submit to db
        },
        sendToDB() {
            window.console.log('SEND TO DB')
            // if single set data for single submit

            // if multi set and send multiple commits
        }
    },
    mounted() {
        this.getStaffInfo()
    },
    watch: {
        startDate: function() {
            window.console.log('BLOCKED DATE CHANGED', this.startDate)
        },
        selectedHour() {
            this.bookingInfo.starttime = this.selectedHour.name + ':' + this.selectedMinute.name
        }
    },
    filters: {
        Filters
    },
    computed: {
        getAMPM() {
            return this.starttime
        },
        getStartTime(){
            return this.selectedHour.name + ':' + this.selectedMinute.name
        },
        getDateLabel() {
            return this.multiDayEvent ? 'Start Date: ' : 'Date: '
        }
    }
    
}
</script>

<style scoped>

</style>