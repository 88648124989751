<template>
    <div>
        <message-modal v-if="showModal"
            :title="alertTitle"
            :type="'alert'">
            {{ alertMessage }}
            <div>
                <div class="button-main" @click="closeModal" style="backgroundColor:#b04545">OK</div>
            </div>           
        </message-modal>
    <div class="schedule-primary-container">
        <div class="schedule-main-title-row">
            <div class="schedule-buttons-group">
                <SelectionSwitch  
                    v-bind:switches="dayWeekSwitch()"
                    v-on:item-selected="selectedScheduleType = $event"
                    v-bind:selectedSwitch= "sraSwitch"
                    >
                </SelectionSwitch>
                <div class="switch-container">
                    <SelectionSwitch  
                        v-bind:switches="['DAY','WEEK']"
                        v-on:item-selected="selectedDisplayType = $event"
                        v-bind:selectedSwitch= "dwSwitch"
                        >
                    </SelectionSwitch>
                </div>
                <div class="switch-dropdown-container">
                    <Dropdown 
                        v-if="selectedDisplayType === 'WEEK' && (selectedScheduleType === 'ROOMS' || selectedScheduleType ==='STATIONS')" 
                        v-on:item-selected="selectedWeekRoomDisplay = $event" 
                        v-bind:selected="selectedWeekRoomDisplay.name" 
                        v-bind:optioncontent="allRooms"
                        :dropstyle="dropStyle"
                        :caretshow="true"
                        placeholder="Select Room">
                    </Dropdown>
                    <Dropdown 
                        v-if="selectedDisplayType === 'WEEK' && selectedScheduleType === 'STAFF'" 
                        v-on:item-selected="selectedWeekStaffDisplay = $event" 
                        v-bind:selected="selectedWeekStaffDisplay.name" 
                        v-bind:optioncontent="allStaff"
                        :dropstyle="dropStyle"
                        :caretshow="true"
                        placeholder="Select Staff">
                    </Dropdown>
                </div>
                
            </div>
            
            <div class="schedule-buttons-group-right">
                <div class="schedule-location-selection-box" id="container"  @click="showHideSelection" v-if="showLocations">
                    {{'Location' | translate | allCaps}}
                    <i class="fas fa-caret-down"></i>
                    <div class="schedule-selection-container"  v-show="showSelection">
                        <div 
                            v-for="(item,index) in allLocations" 
                            v-bind:key="index" 
                            class="dropdown-item location-schedule"
                            id="item" 
                            @click="setSelectedLocation(item)">
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <div class="button-icon small-icon dark-turq" @click="showHideCalendar" ><i class="far fa-calendar-alt"></i></div>
            </div>
            <!-- <div class="schedule-title" v-if="selectedDisplayType !== 'WEEK'">{{'Schedule' | allCaps }}</div> -->
        </div>
        <Calendar 
            v-show="showCalendar"
            v-on:date-selected="dateSelected = $event"
        />
        <div class="schedule-info-row">
            <div class="prev-button" @click="prevDay">
                <i class="fas fa-caret-left"></i>
                {{'Prev' | translate}}
            </div>
            <div class="schedule-date">{{getSelectedDate}}</div>
            <div class="next-button" @click="nextDay">
                {{'Next' | translate}}
                <i class="fas fa-caret-right"></i>
            </div>
        </div>
        <!-- MAIN SCHEDULE -->
        <div class="schedule-container" id="main-schedule" :style="setContainerHeight"  >            
            <div class="schedule-content-container">
                <div class="schedule-title-row">
                    <div class="schedule-column-title time-title" v-if="selectedScheduleType !== 'ALL'">{{'Time' | translate}}</div>
                    <div class="schedule-column-title" v-for="type in selectedType.data" v-bind:key="'title'+type.id" :style="setColumnStyle()">{{type.name}}</div>
                </div>
            <!-- TIME COLUMN -->
                <div class="schedule-time-column" v-if="selectedScheduleType !== 'ALL'">                
                    <div class="schedule-time-item" v-for="item in officeTimes" v-bind:key="'day'+item.id" :style="setTimeStyle(item)">{{setTimeValue(item)}} </div>
                </div>
            <!-- DATA COLUMNS -->
                <div class="schedule-column" v-for="(type,index) in selectedType.data" v-bind:key="index" :style="setColumnStyle()">
                    <!-- TIME TICKS -->
                    <div class="schedule-time-item" 
                        v-for="(item,index2) in officeTimes" 
                        v-bind:key="index2"
                        :style="checkStaffWorking(item, type,index,index2)"
                        @click="bookNewTime(item, type)"
                        >
                        <div class="schedule-pto-note" @click="showNoteInfo(item,type)">{{checkshowPTONote(item,type,index,index2)}}</div>
                    </div>
                    <!-- BOOKING OBJECTS -->
                    <div v-if="selectedScheduleType !== 'ALL'">    
                        <div class="schedule-item" 
                            v-for="(booking,index3) in getSchedule(type)" 
                            v-bind:key="index3" 
                            :style="getStyle(booking,'ind')"
                            @click="showBookingInfo(booking, $event)">
                            <div class="scheduled-item-client">{{booking.client | allCaps}}
                                <div class="schedule-item-new"  v-if="booking.status == 'confirmed'"><i class="fas fa-check"></i></div>
                            </div>
                            <div class="scheduled-item-service" v-if="booking.services">
                                <div class="scheduled-multi-items" v-for="(service,index) in booking.services" :key="index"><span v-if="index > 0">| </span>{{service.name}}</div>
                            </div>
                            
                            <div class="scheduled-item-service" v-if="!booking.services">{{booking.service}}</div>
                            
                            
                            <div class="schedule-item-new"  v-if="booking.first"><i class="fas fa-star"></i></div>
                            
                            
                            <!-- <div class="scheduled-item-service">{{booking.room}}</div>
                            <div class="scheduled-item-client">{{booking.staff}}</div>  -->
                        </div>
                    </div>
                    <div v-if="selectedScheduleType === 'ALL'">
                        <div class="schedule-item" 
                            v-for="(booking,index4) in getSchedule(type)" 
                            v-bind:key="index4" 
                            :style="getStyle(booking,'all',index4)"
                            @click="showBookingInfo(booking, $event)">
                            <div class="scheduled-item-client">
                                {{booking.client | allCaps}}
                                <span class="schedule-item-new" v-if="booking.first"><i class="fas fa-star"></i></span>
                                <span class="schedule-item-new" v-if="booking.status == 'confirmed'"><i class="fas fa-check"></i></span>
                            </div>
                                                        
                            <div class="scheduled-item-service" v-if="booking.services">
                                {{booking.starttime}} : 
                                <span class="scheduled-multi-items" v-for="(service,index) in booking.services" :key="index"><span v-if="index > 0">| </span>{{service.name}}</span>
                            </div>
                            <div class="scheduled-item-service" v-if="!booking.services">{{booking.starttime}} : {{booking.service}}</div>
                            <div class="scheduled-item-service">{{booking.room}}</div>
                            <div class="scheduled-item-client">{{booking.staff}}</div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="booking-info-container" v-if="showBookingDetail">
                <div class="booking-info-title-bar">
                    <div class="booking-info-title">{{'Booking Info' | translate}}</div>
                    <div class="close-button" @click="showBookingDetail = false">X</div>
                </div>
                <div class="booking-info-content-container">
                    <div class="booking-info-item client-style">
                        {{displayInfo.client}}
                        <div class="schedule-item-new" v-if="displayInfo.first"><i class="fas fa-star"></i></div>
                        <div class="schedule-item-new" v-if="displayInfo.status == 'confirmed'"><i class="fas fa-check"></i></div>
                    </div>
                    
                    <div class="booking-info-item">{{displayInfo.service}}</div>
                    
                    <div class="booking-info-item">{{displayInfo.starttime}} for {{displayInfo.duration}} Minutes</div>
                    <div class="booking-info-item">{{displayInfo.staff}}</div>
                    <div class="booking-info-item">{{displayInfo.room}}</div>
                    <div class="booking-info-item note-item">{{displayInfo.note}}</div>
                    <div class="booking-info-button-row" v-if="displayInfo.service !== 'pto'">
                        <div class="booking-info-status-button" @click="confirmBooking" v-if="displayInfo.status !== 'confirmed'">
                            <i class="fas fa-check-circle"></i>
                        </div>
                        <div class="booking-info-status-button" @click="showBooking">
                            <i class="fas fa-pen"></i>
                        </div>                    
                        <div class="booking-info-status-button" @click="invoiceBooking">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </div>
                    <div class="booking-info-button-row" v-if="displayInfo.service == 'pto'">
                        <div class="booking-info-status-button" @click="updatePTO">
                            <i class="fas fa-pen"></i>
                        </div>
                        <div class="booking-info-status-button">
                            <i class="fas fa-trash-alt"  @click="showConfirm = !showConfirm"></i>
                            <div class="delete-booking-confirm" id="confirm-delete" v-if="showConfirm" >
                                {{'Are you sure you want to Delete this' | translate}} {{'Booking' | translate}}?                   
                                <input class="delete-reason" v-model="deleteReason" placeholder="Reason" />
                                <div class="button-main small-button" @click="deletePTO">{{'Yes' | translate}}</div>
                                <div class="button-main small-button" @click="hideConfirm" >{{'No' | translate}}</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    </div>
</template>

<script>
import Constants from '../utilities/constants'
import Globals from '../utilities/globals'
import store from '../store/store'
import Calendar from './utility/CalendarMini'
import EventBus from '../utilities/eventBus'
import SelectionSwitch from './utility/SelectionSwitch'
import Dropdown from './utility/Dropdown'
import MessageModal from './utility/MessageModal'

export default {
    name: 'Schedule',
    components: {
        Calendar,
        SelectionSwitch,
        Dropdown,
        MessageModal
    },
    data: function(){
        return{
            officeTimes: Constants.officeTimes,
            store,
            selectedDate: this.$store.getters.selectedDateData.selectedDate,
            dateData: this.$store.getters.selectedDateData,
            scheduledItems: [],
            allRooms: [],
            allStaff: [],
            allLocations: this.$store.getters.allLocations,
            selectedOptions: [
            
            ],
            selectedType: {},
            showCalendar: false,
            showSelection: false,
            showLocations: false,
            showConfirm: false,
            showModal: false,
            calButtonTitle: 'Show Calendar',
            showBookingDetail: false,
            showRooms: true,
            displayInfo: {},
            selectedScheduleType: this.$store.getters.selectedScheduleType,
            selectedDisplayType: this.$store.getters.selectedDisplayType,
            selectedWeekRoomDisplay: {},
            selectedWeekStaffDisplay: this.$store.getters.selectedStaff,
            weekSchedule: [
                {id: 1, ref: 1, name: `Monday`, data: []},
                {id: 2, ref: 2, name: 'Tuesday', data: []},
                {id: 3, ref: 3, name: 'Wednesday', data: []},
                {id: 4, ref: 4, name: 'Thursday', data: []},
                {id: 5, ref: 5, name: 'Friday', data: []},
                {id: 6, ref: 6, name: 'Saturday', data: []},
                {id: 7, ref: 7, name: 'Sunday', data: []},
            ],
            dropStyle: {
                border: '1px solid lightgray',
                color: '#4f5a5f',
                fontSize: '13px',
                height: '22px',
                backgroundColor: '#e8edef'
            },
            bookingResults: [],
            primaryLocationId: this.$store.getters.user.primarylocation,
            currentLocation: {},
            dwSwitch: this.$store.getters.selectedDisplayType === 'WEEK' ? 'WEEK' : 'DAY',
            sraSwitch: this.$store.getters.selectedScheduleType,
            dateSelected: {},
            businessStartTime: 0,
            businessEndTime: 0,
            deleteReason: '',
            serviceList: '',
            alertMessage: '',
            alertTitle: '',
            weekStartDate: '',
            weekEndDate: '',
        }
    },
    methods: {
        confirmBooking() {
            window.console.log('booking id', this.displayInfo.id)
            this.$store.dispatch('checkInBooking',{id: this.displayInfo.id}).then(response => {
                if (response.substr(0,7) === 'SUCCESS'){
                    this.displayInfo.status = 'confirmed'
                }
            })
        },
        closeModal() {
            this.showModal = false;
            this.$router.push('Login')
        },
        hideConfirm() {
            this.showConfirm = false
            window.console.log('HIDE CONFIRM',this.showConfirm)
        },
        checkResponse(response,type) {
            let result = true
            if (response.result == 'FAIL') {
                window.console.log('FAILED')
                    result = false
            } else if (response.result == 'EXPIRED') {
                window.console.log('EXPIRED')
                this.showModal = true
                this.alertMessage = `${Globals.translate('Your Session has timed out')}. ${Globals.translate('Please Login again')}!`
                this.alertTitle = Globals.translate('Session Timed Out')
                result = false
            } else if (response.result == 'NOT VERIFIED') {
                window.console.log('NOT VERIFIED')
                result = false
                this.showModal = true
                this.alertMessage = `${Globals.translate('There was a problem verifying your account')}. ${Globals.translate('Please Login again')}!`
                this.alertTitle  = Globals.translate('Error Verifying Account')
            }
            window.console.log('checkresponse', result, response.result, response,type)
            return result
        },
        getStyle(booking,type,index) {   
            const itemHeight = ((booking.duration/15)*30) - 16;
            let officeRef = 0
            this.officeTimes.forEach(time => {
                if (time.name.includes(booking.starttime)) {
                    officeRef = time.id
                }
            })
            const itemTop = (officeRef - this.businessStartTime) * 30
            let leftPos = 0
            const borderColor = this.getStaffColor(booking.staffid)
            const calcNewWidth = type !== 'all' ? (window.innerWidth - 60)/this.selectedType.data.length : window.innerWidth/this.selectedType.data.length
            let newWidth = calcNewWidth < 80 ? 80 : calcNewWidth
            
            if (this.selectedDisplayType == 'WEEK') {
                window.console.log('IS WEEK',this.selectedScheduleType,newWidth )
                newWidth = this.selectedScheduleType !== 'ALL' ? (newWidth - 16) : newWidth
            } else {
                newWidth -= 24
            }
            if (booking.conflict) {
                newWidth = (newWidth)/2
            }
            if (booking.secondConflict) {
                leftPos = newWidth
            }
            const backColor = booking.billed ? '#eafdd3' : '#eaf7ff'
            if (type === 'ind') {
                return {
                    height: itemHeight + 'px',
                    top: itemTop + 'px',
                    left: leftPos + 'px',
                    borderTop: '5px solid ' + borderColor,
                    width: `${newWidth}px`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: backColor
                }
            }
            // 
            if (type === 'all') {
                return {
                    height: '65px',
                    top: 78 * index + 'px',
                    // backgroundColor: '#e0ebf3',
                    borderTop: '5px solid ' + borderColor,
                    width: `${newWidth - 15}px`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: backColor
                }
            }
        },
        getStaffColor(staffid) {
            // window.console.log('CHECK STAFF ID', staffid, this.allStaff)
            let staffColor = this.allStaff.filter(staff => {
                if (staff.id == staffid) {
                    return staff
                }
            })

            if (staffColor[0] && staffColor[0].displaycolor) {
                return staffColor[0].displaycolor
            } else {
                return '#6641d8'
            }           
        },
        setColumnStyle() {
            const calcNewWidth = this.selectedScheduleType === 'ALL' ? (window.innerWidth - 30)/this.selectedType.data.length : (window.innerWidth - 103)/this.selectedType.data.length
            const newWidth = calcNewWidth < 80 ? 80 : calcNewWidth
            return {
                width: `${newWidth}px`
            }
        },
        setTimeStyle(item) {
            if(item.value % 1 == 0){
                return {
                    fontWeight: 600,
                    fontSize: '14px'
                }                
            }
        },
        setTimeValue(item) {
            if(item.value % 1 == 0){
                if (item.value < 12){
                    return `${item.value} AM`
                } else if (item.value == 12) {
                    return 'NOON'
                } else {
                    return  `${item.value - 12} PM` 
                }             
            } else {
                return item.name
            }
        },
        getSchedule(type){ 
            if (!this.scheduledItems || !this.scheduledItems.length) {
                return []
            }
            
           return this.checkDoubleBooking(this.scheduledItems.filter(item => item[this.selectedType.ref] === type.id && item.serviceid !== 0))           
        },
        getWeekStartDate() {
            const selDate = new Date(this.dateData.selectedDate)
            let dayNumber = (new Date(selDate)).getDay()
            let startDate = new Date(this.dateData.selectedDate)
            startDate.setDate(selDate.getDate() - (dayNumber -1))
            return startDate
        },
        getWeekEndDate() {
            const startDate = this.getWeekStartDate()
            let endDate = new Date(startDate)
            endDate.setDate(startDate.getDate() + 6)
            return endDate
        },
        setupWeek() {
            // const selDate = new Date(this.dateData.selectedDate)
            // let dayNumber = (new Date(selDate)).getDay()
            // let startDate = new Date(this.dateData.selectedDate)
            // startDate.setDate(selDate.getDate() - (dayNumber -1))
            // let endDate = new Date(startDate)
            // endDate.setDate(startDate.getDate() + 6)
            // window.console.log('SET UP WEEK ------',startDate,endDate)
            this.$store.dispatch('getBookingsWeek',{startdate: Globals.formatDBDateLong(this.getWeekStartDate()), enddate: 
            Globals.formatDBDateLong(this.getWeekEndDate())}).then(response => {
                if (this.checkResponse(response,'getBookingsWeek')) {
                    this.bookingResults = response.data
                    window.console.log('BOOKING RESULTS WEEK',this.bookingResults)
                    this.setupWeekSchedule(this.getWeekStartDate())
                }
                
                
            }) 
         },   
        setupWeekSchedule(startDate) {
            let refIndex = 1
            this.weekSchedule.forEach(item => {
                const setDate = this.setNextDate(startDate,(item.ref - 1))
                // SET SEARCH ID for SCHEDULE TO FORMATTED DATE
                item.id = Globals.formatShortDate(setDate)
                if (this.bookingResults.length) {
                    this.bookingResults.forEach(booking => {
                        // ADD FORMATTED DATE TO EACH BOOKING ITEM AS refDate                   
                        booking.refDate = Globals.formatShortDate(new Date(booking.bookingdate)) 
                    })
                }
                // IF NOT ALL THEN FILTER BY TYPE AND SPECIFIC
                this.filterBookingsForWeek()
                
                item.name = this.getScheduleFormat(setDate,refIndex)
                refIndex++
            })
            this.selectedType = {id: 3, name: 'WEEK', data: this.weekSchedule, ref: 'refDate'}
        },
        filterBookingsForWeek() {
            let listings = this.bookingResults
            if (this.selectedScheduleType === 'STAFF' && listings.length) {
                if (this.selectedWeekStaffDisplay && !this.selectedWeekStaffDisplay.id){
                    // SHOULD SET TO USER DATA
                    this.selectedWeekStaffDisplay = this.allStaff[0]
                }
                listings = listings.filter(item => {
                    if (this.selectedWeekStaffDisplay && item.staffid === this.selectedWeekStaffDisplay.id) {
                        return item
                    }
                })
            }
            if (this.selectedScheduleType === 'ROOMS') {
                listings = listings.filter(item => {
                    if (item.roomid === this.selectedWeekRoomDisplay.id) {
                        return item
                    }
                })
            }
            // ADD BOOKINGS TO SCHEDULED ITEMS AFTER SORTING BY TIME
            this.scheduledItems = listings.length ? listings.sort(this.sortCompare) : []
        },
        sortCompare(a, b) {
            // Use toUpperCase() to ignore character casing
            const itemA = Number(a.reftime)
            const itemB = Number(b.reftime)
            
            let comparison = 0;
            if (itemA > itemB) {
            comparison = 1;
            } else if (itemA < itemB) {
            comparison = -1;
            }
            return comparison;
        },       
        setNextDate(day,num) {
            let newDate = new Date(day)
            newDate.setDate(day.getDate() + num)
            return newDate
        },
        getScheduleFormat(date,num) {
            return `${Globals.translate(Constants.abrevDaysWeek[num])} ${Globals.formatMonthDay(date)}`
        },
        getBookingData(){
            this.dateData = this.$store.getters.selectedDateData;
            if(!this.dateData.day){                
                this.setDateToday()
                window.console.log('NO DATE DATA SET TO TODAY', this.dateData.day)
            }
            const searchDate = {               
                "month": Number(this.dateData.month),
                "day": Number(this.dateData.day),
                "year": Number(this.dateData.year),
            }
            if(this.dateData.year) {
                if (this.selectedDisplayType == 'DAY') {
                    this.$store.dispatch('getBookingsDay', searchDate).then(response => {
                        if (this.checkResponse(response, 'getBookingsDay')) {
                            this.scheduledItems = response.data
                        } else {
                            this.scheduledItems = []
                            window.console.log('NO DATE SELECTED')
                        }
                    })
                }
            }            
        },
        showHideCalendar() {
            if (this.showCalendar){
                this.showCalendar = false;
                this.calButtonTitle = 'Show Calendar'

            } else {
                this.showCalendar = true;
                this.calButtonTitle = 'Hide Calendar'
            }
        },
        updateDateData(date) {
            this.dateData.selectedDate = Globals.formatSelectedDate(date)
            this.dateData.day = date.getDate()
            this.dateData.month = date.getMonth()
            this.dateData.year = date.getFullYear()
        },
        nextDay() {
            if(this.selectedType.name === 'WEEK') {                
                const day = new Date (this.weekSchedule[0].id)
                let newDate = new Date(day)
                newDate.setDate(day.getDate() + 7)
                this.updateDateData(newDate)
                
                this.setupWeek()
            } else {
                this.dateData.day ++;
                const daysMonth = 32 - (new Date(this.dateData.year, this.dateData.month, 32)).getDate();
                window.console.log('NEXT',daysMonth,this.dateData.day,new Date(this.dateData.year, this.dateData.month, 32),new Date(this.dateData.year, this.dateData.month, 32).getDate())
                if (this.dateData.day > daysMonth) {
                    this.dateData.month ++;
                    this.dateData.day = 1;
                    if (this.dateData.month > 11){
                        this.dateData.month = 0;
                        this.dateData.year++;
                        this.dateData.day = 1;
                    }
                }
                this.setSelectedDate();
            }
        },
        prevDay() {
            if(this.selectedType.name === 'WEEK') {                
                const day = new Date (this.weekSchedule[0].id)
                let newDate = new Date(day)
                newDate.setDate(day.getDate() - 7)
                this.updateDateData(newDate)
                this.setupWeek()
            } else {
                this.dateData.day --;
                if(this.dateData.day < 1){
                    // get days previous month
                    this.dateData.month --;
                    if (this.dateData.month < 0) {
                        this.dateData.month = 11;
                        this.dateData.year--;
                    }
                    this.dateData.day =  32 - (new Date(this.dateData.year, this.dateData.month, 32)).getDate();   
                }
                this.setSelectedDate();
            }
        },
        setSelectedDate() {
            this.dateData.selectedDate =  Constants.months[this.dateData.month] + ' ' + this.dateData.day + ', ' + this.dateData.year;
            this.dateData.selectedDate = Constants.daysOfWeek[(new Date(this.dateData.selectedDate)).getDay()] + ' ' + this.dateData.selectedDate;
            this.$store.commit('setDate', this.dateData)
        },
        setSelectedLocation(item) {  
            this.currentLocation = item 
            this.$store.commit('setCurrentLocation', item)       
            EventBus.$emit('SET_LOCATION',item.name)
            this.showSelection = false
            this.getRooms()
            this.getStaff()
            this.getBookingData()
        },
        showHideSelection($event) {
            if ($event.target.id === 'container') {
                this.showSelection = !this.showSelection
            } else {
                this.showSelection = false
            }
        },
        showBookingInfo(booking) {
            window.console.log('BOOKING', booking)
            this.displayInfo = booking;
            
            if (booking.services) {
                this.serviceList = ''
                booking.services.forEach((service,index) => {
                    this.serviceList += index == 0 ? service.name : ', ' + service.name
                })
                this.displayInfo.service = this.serviceList
            }
            this.showBookingDetail = true;
        },
        invoiceBooking() {
             this.$store.commit('setBookingInfo', this.displayInfo)
             this.$router.push('Invoice')
        },
        updateBooking() {
            this.$store.commit('setBookingInfo', this.displayInfo)
            this.$router.push('UpdateBooking')
        },
        showBooking() {
            this.$store.commit('setBookingInfo', this.displayInfo)
            this.$router.push('BookingInfo')
        },
        updatePTO() {
            this.$store.commit('setBookingInfo', this.displayInfo)
            this.$router.push('UpdatePTO')
        },
        deletePTO() {
            
            this.$store.dispatch('cancelBooking',this.displayInfo).then(response => {
                if (response.result == 'SUCCESS') {
                    this.showBookingDetail = false
                    this.setupWeek()
                }
            })
        },
        bookNewTime(item, type){
            // IF WEEK VIEW SET DATE COLUMN SELECTED
            if (this.selectedType.name === 'WEEK') {
                const dateArray = type.id.split('/')
                const newDate = {
                    year: `20${dateArray[2]}`,
                    month: dateArray[0] - 1,
                    day: dateArray[1],
                    selectedDate: Globals.formatSelectedDate(type.id)
                }
                this.$store.commit('setDate',newDate)
            }
            // CANNOT BOOK IF NOT WORKING
            if (typeof this.checkStaffWorking(item,type) === 'undefined') {            
                const startTotal = 7 + (((item.id-1) * 15)/60)
                const startHour = Math.floor(startTotal)
                const startMinute = ('0' + (60*(startTotal-startHour))).slice(-2)
                const startTime = startHour + ':' + startMinute
                const newBookingData = {starttime: startTime}
                if (this.selectedType.name === 'ROOMS') {
                    newBookingData.room = type.name,
                    newBookingData.roomid = type.id
                }
                if (this.selectedType.name === 'STAFF') {
                    newBookingData.staff = type.name,
                    newBookingData.staffid = type.id
                }

                if(this.selectedType.name && this.selectedType.name === 'WEEK'){
                    
                    if (this.selectedScheduleType === 'STAFF') {
                        newBookingData.staff = this.selectedWeekStaffDisplay.name
                        newBookingData.staffid = this.selectedWeekStaffDisplay.id
                    }
                    if (this.selectedScheduleType === 'ROOMS' || this.selectedScheduleType === 'STATIONS') {
                        newBookingData.room =this.selectedWeekRoomDisplay.name
                        newBookingData.roomid =this.selectedWeekRoomDisplay.id
                    }
                    
                }



                this.$store.commit('setBookingInfo',newBookingData)
                this.$router.push('AddBooking')
            }
        },
        setDateToday(){
            const thisMonth = new Date().getMonth()
            const thisDay = new Date().getDate()
            const thisYear = new Date().getFullYear()
            let calMonth = Constants.months[thisMonth]
            let selectedDate =  calMonth + ' ' + thisDay + ', ' + thisYear;
            selectedDate = Constants.daysOfWeek[(new Date(selectedDate)).getDay()] + ' ' + selectedDate
            const todayData = {
                year: thisYear,
                month: thisMonth,
                day: thisDay,
                selectedDate: selectedDate
            }
            this.$store.commit('setDate',todayData)
        },
        showClientInfo() {
            EventBus.$emit('SHOW_MODAL',{type:'client',id:this.displayInfo.clientid})
        },
        setSelectedType(type) {
            if (this.selectedDisplayType !== 'WEEK') {
                if(type === 'ROOMS' || type === 'STATIONS') {                    
                    this.allRooms = this.$store.getters.allRooms
                    if (this.allRooms.length == 1) {
                        this.showRooms = false
                    }
                    this.selectedType = {id: 1, name: 'ROOMS', data: this.allRooms, ref: 'roomid'}
                }
                if(type === 'STAFF') {
                    this.selectedType = {id: 2, name: 'STAFF', data: this.allStaff, ref: 'staffid'}
                }
            } 
        },
        selectedDisplay(type) {
            return type !== this.selectedType.name
        },
        getTimeRef(refnum) {
            return ((refnum -8) * 4) + 1
        },
        showNoteInfo(item,type) {
            let ptoTimes = []
            if (this.scheduledItems && this.scheduledItems.length) {
                ptoTimes = this.scheduledItems.filter(item => item[this.selectedType.ref] === type.id && item.serviceid === 0)
            }
            this.displayInfo = ptoTimes[0]
            this.showBookingDetail = true
        },
        checkshowPTONote(item,type,index,index2){
            let ptoTimes = []
            
            //check if PTO
            if (this.scheduledItems && this.scheduledItems.length) {
                ptoTimes = this.scheduledItems.filter(item => item[this.selectedType.ref] === type.id && item.serviceid === 0)
            }

            for (let i = 0;i<ptoTimes.length;i++) {
                if (this.selectedScheduleType !== 'ALL' || this.selectedDisplayType !== 'WEEK') {
                    if (item.value >= ptoTimes[i].reftime && item.value < Number(ptoTimes[i].reftime) + Number(ptoTimes[i].duration/60) ) {
                        if (this.officeTimes[index2].value == ptoTimes[i].reftime || (ptoTimes[i].reftime < this.officeTimes[0].value && index2 == 0)) {
                            let ptoNote = ptoTimes[i].note ? ptoTimes[i].note : 'TIME OFF'
                            return ptoNote
                        }
                    }
                }
            }
           
        },
        checkStaffWorking(item,type) {
            // item = time, type=staff info
            const currentDay = new Date(this.dateData.selectedDate).getDay()
            let offTime = false
            let ptoTime = false
            let ptoTimes = []
            //check if PTO
            if (this.scheduledItems && this.scheduledItems.length) {
                ptoTimes = this.scheduledItems.filter(item => item[this.selectedType.ref] === type.id && item.serviceid === 0)
            }
            
            ptoTimes.forEach((time) => {
                if (this.selectedScheduleType !== 'ALL' || this.selectedDisplayType !== 'WEEK') {
                    if (item.value >= time.reftime && item.value < Number(time.reftime) + Number(time.duration/60) ) {
                        ptoTime = true
                        
                    }
                }
            }) 
            if (this.selectedScheduleType == 'STAFF' && this.selectedDisplayType == 'WEEK') {
                const dayNum = type.ref > 0 && type.ref < 7 ? type.ref : 0
                if (this.selectedWeekStaffDisplay.schedule) {
                    const daySchedule = this.selectedWeekStaffDisplay.schedule[0].data[dayNum]
                    if (daySchedule.startTime && daySchedule.startTime.id) {
                        if (item.id < daySchedule.startTime.id) {
                            offTime = true
                        }
                        if (item.id >= daySchedule.endTime.id) {
                            offTime = true
                        }
                    }
                    if (daySchedule.closed) {
                        offTime = true
                    }
                }
            }
            if (type.schedule && type.schedule[0]){
                if (type.schedule[0].data[currentDay] && type.schedule[0].data[currentDay].startTime) {
                    if(item.id < type.schedule[0].data[currentDay].startTime.id ) {
                        offTime = true
                    }
                    if(item.id >= type.schedule[0].data[currentDay].endTime.id) {
                        offTime = true
                    }
                } else {
                    offTime = true                   
                }
                
            }
            if (offTime) {
                return {backgroundColor: '#bfd8df',opacity: '0.4',borderBottom: '1px solid #b5b3b3'}
                // #a2b4b6 #d7e0e2 #799aa4 #a1c3cd
            } 
            if (ptoTime) {
                return {backgroundColor:'#86eed4' ,opacity: '0.4',borderBottom: '1px solid #b5b3b3'}
            }
        },
        checkStatus() {
            if (!this.$store.getters.user.businessid) {
                window.console.log('NO BUSINESS ID - GO LOGIN', this.$store.getters.user)
                // this.$router.push('Login')
            } else {
                this.getAllData()
            }
        },
        getAllData() {
            // GET ALL LOCATIONS FOR BUSINESS           
            // IF NO PRIMARY LOCATION ID SET DEFAULT
            if (!this.primaryLocationId) {
                this.primaryLocationId = this.allLocations[0].id                 
            }

            // ADD LOCATION TO HEADER IF MULTIPLE LOCATIONS
            if (this.allLocations.length > 1) { 
                this.showLocations = true 
                this.allLocations.forEach(loc => {
                    if (loc.id == this.primaryLocationId) {                                                
                        this.currentLocation = loc
                        this.$store.commit('setCurrentLocation',loc)  
                        EventBus.$emit('SET_LOCATION',loc.name)
                    }
                })                    
            } else {
                this.currentLocation = this.allLocations[0]
                this.$store.commit('setCurrentLocation',this.currentLocation) 
            }
            // LOAD DATA
            this.setOfficeTimes()
            this.getStaff()
            this.getRooms()  
        },
        getRooms() {

            this.$store.dispatch('getRooms',this.currentLocation.id).then(response => {
                if (this.checkResponse(response, 'getRooms')) {
                    this.allRooms = response.data
                    if (this.allRooms.length == 1) {
                        this.showRooms = false;
                    }
                    // SET DEFAULT FOR WEEKLY ROOM DISPLAY
                    this.selectedWeekRoomDisplay = this.allRooms[0]
                }
            })
        },
        getStaff() {
            this.$store.dispatch('getStaff',this.currentLocation.id).then(response => {
                if (this.checkResponse(response, 'getStaff')) {
                    if (response.length === 0) {
                        EventBus.$emit('SHOW_MESSAGE',{title: 'MESSAGE_CONTENT.ERROR_TITLE',message:'MESSAGE_CONTENT.ADD_STAFF'})
                    }
                    this.allStaff = response.data
                    this.allStaff.map(item => {item.name = item.displayname})
                    // FILTER FOR BOOKABLE
                    this.allStaff = this.allStaff.filter(item => { 
                        if (item.bookable === true) {
                            return item
                        }
                    })
                    // MAP FOR LOCATION SCHEDULE
                    this.allStaff.map(item => {item.schedule = item.schedule.filter(sch => { 
                            if (sch.id == this.currentLocation.id) {
                                return item
                            }
                        })
                    })
                    Globals.sortList(this.allStaff,'displayorder')
                    // SET DEFAULT FOR WEEKLY STAFF DISPLAY
                    this.setDisplayType()
                    this.setScheduleType()
                // SHOULD SETUP INITIAL VIEW TO REFLECT STAFF LOADING APPLICATION selectedWeekStaffDisplay
                }
            }) 
            this.getStaffSchedulesWeek()   
        },
        getStaffSchedulesWeek() {
            const data = {
                locationid: this.currentLocation.id,
                startdate: Globals.formatDBDateLong(this.getWeekStartDate()), 
                enddate: Globals.formatDBDateLong(this.getWeekEndDate())
            }
            window.console.log('SEARCH SCHEDULE',data, new Date(this.getSelectedDate))
            this.$store.dispatch('getStaffScheduleWeek',data).then(response => {
                window.console.log('STAFF SCH WEEK', response)
            })
        },
        dayWeekSwitch() {
            if (this.selectedDisplayType === 'WEEK') {
                if (this.showRooms) {
                    return ['STAFF','ROOMS','ALL']
                } else {
                    return ['STAFF','ALL']
                }
                
            }
            if (this.showRooms) {
                return ['STAFF', 'ROOMS']
            } else {
                return ['STAFF']
            }
        },
        windowResize() {
            document.getElementById('main-schedule').style.height = `${window.innerHeight - 140}px`
        },
        setDisplayType() {
            
            if (this.selectedDisplayType === 'DAY') {
                this.selectedScheduleType = this.selectedScheduleType ==='ALL' ? 'STAFF' : this.selectedScheduleType
                this.setSelectedType(this.selectedScheduleType)
            }
            if (this.selectedDisplayType === 'WEEK') {
                this.setupWeek()
            }
        },
        setScheduleType() {
            this.setSelectedType(this.selectedScheduleType)
        },
        resetSchedule() {
            this.dwSwitch = this.selectedDisplayType
            this.sraSwitch = this.selectedScheduleType
            window.console.log('RESET',this.selectedDisplayType,this.selectedScheduleType)      
        },
        setOfficeTimes() {
            this.currentLocation.schedule.forEach(day => {
                if (!day.startTime && !day.endTime) {
                    day.closed = true
                }
                if (!day.closed) {
                    if (this.businessStartTime > 0) {
                       if (day.startTime && day.startTime.id < this.businessStartTime) {
                           this.businessStartTime = day.startTime.id
                       }
                    } else {
                        this.businessStartTime = day.startTime.id
                    }
                    this.businessEndTime = day.endTime.id > this.businessEndTime ? day.endTime.id : this.businessEndTime
                }
            })
            this.officeTimes = this.officeTimes.filter(time => {
                if(time.id >= this.businessStartTime && time.id <= this.businessEndTime) {
                    return time
                }
            })
        },
        checkDoubleBooking(items) {
            items.sort(function(a, b) {
                if (a.reftime < b.reftime) {
                    return -1;
                } else {
                    return 1;}
            })
            let prevItem = {}
            items.forEach(item => {
                if(prevItem && prevItem.reftime == item.reftime) {
                    item.conflict = true
                    item.secondConflict = true
                    prevItem.conflict = true
                }
                prevItem = item
            })
            return items
        }
    },
    computed: {
        getSelectedDate: function() {
            if(this.selectedType.name && this.selectedType.name == 'WEEK'){
                this.filterBookingsForWeek()
                if (this.selectedScheduleType == 'STAFF') {
                    return `${this.selectedWeekStaffDisplay.name} ${Globals.translate('week')} ${this.weekSchedule[0].id}`
                }
                if (this.selectedScheduleType == 'ROOMS' || this.selectedScheduleType == 'STATIONS') {
                    return `${this.selectedWeekRoomDisplay.name} ${Globals.translate('week')} ${this.weekSchedule[0].id}`
                }
                return `${Globals.translate('WEEK of')} ${this.weekSchedule[0].id}`
            }
            this.getBookingData();
            return this.$store.getters.selectedDateData.selectedDate;
        },
        setContainerHeight() {
            return {
                height: (window.innerHeight - 170) + 'px'
            }
        }              
    },
    watch: {
 
        dateData() {
            this.showCalendar = false
            this.setSelectedDate()
        },
        selectedScheduleType() {
            this.setScheduleType()
            this.$store.commit('setScheduleType',this.selectedScheduleType)
        },
        selectedDisplayType() {
            this.setDisplayType()
            this.$store.commit('setDisplayType', this.selectedDisplayType)
        },
        dateSelected(){
            this.showCalendar = false
            this.dateData = this.dateSelected
            this.selectedScheduleType = 'STAFF'
            this.selectedDisplayType = 'DAY'
            this.dwSwitch = ''
            this.sraSwitch = ''
            setTimeout(() => {
                this.dwSwitch = 'DAY'
                this.sraSwitch = 'STAFF'
            }, 100)            
        },
        selectedWeekStaffDisplay() {
            this.$store.commit('setSelectedStaff',this.selectedWeekStaffDisplay)
        }
    },
    mounted() {
        window.addEventListener('resize', this.windowResize);
        EventBus.$on('CHANGE_LOCATION',item => {
            this.currentLocation = item 
            this.showSelection = false
            this.getRooms()
            this.getStaff()
        })
        EventBus.$on('CHANGE_LANGUAGE',() => {
            this.$forceUpdate() 
            this.resetSchedule()
        })
        this.checkStatus()
        this.resetSchedule()       
    }
}
</script>

<style scoped>
body{
    overflow-y: hidden !important;
}
.schedule-primary-container{
    height: 100%;
    width: 100%;
    
    background-color: lightgray;
}
.schedule-container{
    height: 500px;
    overflow: auto;
    background-color: #f7f7f7;
    text-align: left;
    border: solid 1px black;
    width: 100%;
}
.schedule-time-column{
    width: 70px;
    background-color: #edf7ff;
    display: inline-block;
    text-align: right;
    border-right: 1px solid black;
    float: left;
    font-size: 10px;
}
.schedule-time-item{
    height: 29px;
    border-bottom: 1px solid lightgray;
    line-height: 33px;
    padding-right: 10px;
}
.schedule-column{
    width: 140px;
    background-color: white;
    display: inline-block;
    position: relative;
    vertical-align: top;
    border: 1px solid black;
    border-left: none;
    border-top: none;
    float: left;
    max-width: 300px;
}
.schedule-item{
    background-color: #eaf7ff;
    position: absolute;
    width: 130px;
    border-top: 1px solid #0797b3;
    border-bottom: 1px solid #0797b3;
    padding: 5px;
    font-size: 11px;
    text-align: center;
    line-height: 11px;
    cursor: pointer;
    max-width: 290px;
    white-space: 'nowrap';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
}
.schedule-item-new{
    color: #4315a2;
    font-weight: 550;
    font-size: 8px;
    display: inline-block;
    vertical-align: top;
}
.schedule-item-service{
    text-overflow: initial;
    white-space: normal;
    line-height: 12px;
    font-size: 13px;
}
.schedule-column-title{
    background-color: white;
    height: 23px;
    font-weight: 500;   
    border-right: 1px solid black;
    display: inline-block;
    width: 121px;
    text-align: center;
    /* padding-left: 19px; */
    line-height: 24px;
    font-size: 14px;
    max-width: 300px;
    float: left;


}
.time-title{
    width: 51px;
    padding-left: 19px;
}
.schedule-title-row{
    text-align: left;
    background-color: white;
    border-bottom: solid 1px black;
    height: 23px;
}
.schedule-info-row{
    height: 22px;
    background-color: white;
    border: solid 1px lightgray;
    padding: 5px 10px 0 10px;
}
.schedule-content-container{
    width: max-content;
}
.dropdown-container{
    float: left;
}
.prev-button{
    cursor: pointer;
    display: inline-block;
    float: left;
}
.next-button{
    cursor: pointer;
    display: inline-block;
    float: right;
}
.schedule-button{
    float: left;
    padding: 0 10px;
    margin: 3px 0 3px 10px;
}
.schedule-date{
    display: inline-block;
    font-weight: 700;
}
.booking-info-container{
    width: 250px;
    background-color: white;
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -125px;
    border: 1px solid gray;
}
.close-button{
    width: 30px;
    height: 25px;
    color: white;
    background-color: gray;
    float: right;
    line-height: 26px;
    cursor: pointer;
}
.booking-info-title-bar{
    background-color: #63b4c5;
    height: 25px;
    text-align: center;
}
.booking-info-title{
    color: white;
    display: inline-block;
    font-weight: 600;
    padding-top: 3px;
}
.booking-info-item{
    text-align: center;
    font-weight: 600;
    font-size: 14px;
}
.note-item{
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px;
    word-wrap: normal;

}
.booking-info-status-button{
    width: 30px;
    height: 30px;
    background-color: #63b4c5;
    border-radius: 15px;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 8px;
    cursor: pointer;
}
.booking-info-status-button:hover{
    background-color: #4fa5b7;
}
.fa-ban, .fa-check-circle{
    font-size: 24px;
    padding: 3px;
    color: white;
}
.fa-pen, .fa-trash-alt{
   font-size: 15px;
    padding: 7px;
    color: white; 
}
.fa-dollar-sign{
   font-size: 17px;
    padding: 10px;
    padding-top: 6px;
    color: white;
}
.fa-info-circle{
    font-size: 19px;
    /* color: #4d7979; */
    margin-top: 5px;
}
.booking-info-button-row{
    height: 40px;
    margin-top: 20px;
    text-align: center;
}
.client-info-button{
    display: inline-block;
}
.client-style{
    color: #4d7979;
    font-size: 18px;
    padding-top: 5px;
    cursor: pointer;
}
.schedule-main-title-row{
    text-align: left;
    width: 100%;
    background-color: #28a4bf;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    padding-bottom: 3px;
    vertical-align: middle;
    color: white;
}
.schedule-title{
    line-height: 39px;
}

.disabled{
    background-color: #80808069;
}
.schedule-buttons-group{
    display: inline-block;
    padding: 3px 15px;
    margin-right: 25px;
}
.schedule-buttons-group-right{
    position: absolute; 
    right: 0; 
    display: inline-block;
    margin-top: 2px;
}
.scheduled-item-client{
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 550;
    padding: 3px;
}
.scheduled-item-service{
    font-size: 14px;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.calendar-button{
    padding: 0 10px;
    margin: 3px 10px 3px 10px;
}
.switch-container{
    display: inline-block;
    margin-left: 15px;
}
.switch-dropdown-container{
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    margin-left: 10px;
    margin-top: 5px;
}
.schedule-location-selection-box{
    background-color: #758588;
    color: white;
    font-size: 10px;
    padding: 0 5px;
    display: inline-block;
    border-right: 1px solid lightgray;
    cursor: pointer;
    height: 23px;
    line-height: 26px;
}
.schedule-selection-container{
    position: absolute;
    background-color: white;
    border: 1px solid black;
    min-width: 100px;
    right: 15px;
    top: 29px;
    padding: 0 5px 0 5px;
    margin-right: 5px;
    z-index: 5;
    width: 125px;
    font-size: 13px;
    max-height: 300px;
    overflow-y: scroll;
}
.schedule-pto-note{
    display: inline-block;
    font-size: 13px;
    text-align: center;
    padding: 0 5px;
    width: 100%;
    cursor: pointer;
}
.location-schedule{
    font-size: 12px;
}
.delete-booking-confirm{
    left: 50px;
    bottom: 45px;
}
.scheduled-multi-items{
    padding-bottom: 5px;
    text-overflow: initial;
    white-space: normal;
    line-height: 12px;
    font-size: 13px;
}
.booking-info-content-container{
    padding: 5px;
}
@media screen and (max-width: 680px) {
    .schedule-location-selection-box{
        display: none;
    }
}
</style>