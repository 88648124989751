import { render, staticRenderFns } from "./Invoice.vue?vue&type=template&id=fc12857e&scoped=true&"
import script from "./Invoice.vue?vue&type=script&lang=js&"
export * from "./Invoice.vue?vue&type=script&lang=js&"
import style0 from "./Invoice.vue?vue&type=style&index=0&id=fc12857e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc12857e",
  null
  
)

export default component.exports