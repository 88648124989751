import { render, staticRenderFns } from "./SupplyAdd.vue?vue&type=template&id=7a8fae93&scoped=true&"
import script from "./SupplyAdd.vue?vue&type=script&lang=js&"
export * from "./SupplyAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a8fae93",
  null
  
)

export default component.exports