<template>
    <div v-if="showModal">
        <div class="modal-shading"></div>
        <div class="modal-content-container">
            <div class="modal-title-bar" :style="setTitleBarStyle()">
                <div class="modal-title">{{title}}</div>
                <div class="close-button" @click="closeModal">+</div>
            </div>
            <div class="modal-content">
                <slot></slot>
            </div>       
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageModal',
    data() {
        return {
            showModal: true
        }
    },
    props: [
        'title', 'type'
    ],
    methods: {
        closeModal() {
            window.console.log('clicked on close modal')
        },
        setTitleBarStyle() {
            if (this.type == 'alert') {
                return {backgroundColor: '#b04545'}
            }
        }
    }
}
</script>

<style scoped>
.modal-shading{
    background-color:#5b6265;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: .7;
}
.modal-content-container{
    background-color: white;
    width: 70%;
    margin: auto;
    position: absolute;
    z-index: 13;
    margin: 15%;
    
}
.modal-title-bar{
    height: 30px;
    color: white;
    text-align: center;
    background-color:#278db7;;
    line-height: 30px;
}
.modal-content{
    padding: 20px;
    min-height: 200px;
}
.close-button{
    -webkit-transform: rotateX(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    font-size: 30px;
    float: right;
    padding: 0 10px;
}
</style>