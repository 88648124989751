<template>
    <div class="main-reports-form-container">
        <div>
            <div class="report-activity-title">
                <div class="report-title">{{'Income Distribution Report' | translate | allCaps}} </div> 
                {{'Invoices By Date' | translate}}
            </div>
            <div class="report-item-title-row" >
                <div class="report-table-item item-title">{{'Date' | translate}}</div>
                <div class="report-table-item item-title tiny-item">#</div>
                <div class="report-table-item item-title small-item">{{'Billed' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Paid Total' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Cash' | translate}}</div> 
                <div class="report-table-item item-title small-item" >{{'Checks' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Credit Cards' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Promo Credit' | translate}}</div>
            </div>
            <div class="report-item-row" v-for="(item,index) in invoiceDates" :key="index">
                <div class="report-table-item">{{item.date}}</div>
                <div class="report-table-item tiny-item center-item">{{item.invoices.length}}</div>
                <div class="report-table-item small-item">{{item.billed | toUSD}}</div>
                <div class="report-table-item small-item">{{item.total | toUSD}}</div>
                <div class="report-table-item small-item">{{item.cash | toUSD}}</div>
                <div class="report-table-item small-item">{{item.check | toUSD}}</div>
                <div class="report-table-item small-item">{{item.creditcard | toUSD}}</div>
                <div class="report-table-item small-item">{{item.credit | toUSD}}</div>
            </div>
            <div class="report-item-row" >
                <div class="report-table-item item-total"></div>
                <div class="report-table-item item-total tiny-item center-item"></div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.billed | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.totals | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.cash | toUSD}} </div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.check | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.creditcard | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.credit | toUSD}}</div>
            </div>
        </div>


        <div class="report-activity-title">
            {{'Invoice Details By Date' | translate}}
        </div>
        <div>
            <div class="report-item-title-row" >
                <div class="report-table-item item-title">{{'Date' | translate}}</div>
                <div class="report-table-item item-title tiny-item">#</div>
                <div class="report-table-item item-title small-item">{{'Paid Total' | translate}}</div>
                <div class="report-table-item item-title small-item">{{'Products Services' | translate}}</div> 
                <div class="report-table-item item-title small-item" >{{'Tips' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Tax' | translate}}</div>
                <div class="report-table-item item-title small-item" >{{'Promo Credit' | translate}}</div>
            </div>
            <div class="report-item-row" v-for="(item,index) in invoiceDates" :key="index">
                <div class="report-table-item">{{item.date}}</div>
                <div class="report-table-item tiny-item center-item">{{item.invoices.length}}</div>
                <div class="report-table-item small-item">{{item.total | toUSD}}</div>
                <div class="report-table-item small-item">{{item.servprod | toUSD}}</div>
                <div class="report-table-item small-item">{{item.tips | toUSD}}</div>
                <div class="report-table-item small-item">{{item.taxes | toUSD}}</div>
                <div class="report-table-item small-item">{{item.credit | toUSD}}</div>
            </div>
            <div class="report-item-row" >
                <div class="report-table-item item-total">{{'Total' | translate | allCaps}}</div>
                <div class="report-table-item item-total tiny-item center-item"></div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.totals | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.servprod | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.tips | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.taxes | toUSD}}</div>
                <div class="report-table-item item-total small-item">{{invoiceItemTotals.credit | toUSD}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store/store'
import Globals from '../../utilities/globals'
export default {
    name: 'IncomeDistReport',
    data() {
        return {
            store,
            allInvoices: [],
            allInvoicePromotions: [],
            invoiceTotals: {},
            invoiceDates: [],
            totalsObj: {billed:0,totals:0,cash:0,creditcard:0,check:0,credit:0,servprod:0,tips:0,taxes:0},
            invoiceItemTotals: this.totalsObj
        }
    },
    components: {

    },
    props: [
        'startdate',
        'enddate'
    ],
    methods: {
        getInvoiceItemsDates() {
            this.$store.dispatch('getInvoiceItemsDates',{startdate: this.startdate,enddate: this.enddate}).then(response => {
                this.allInvoiceItems = response
                this.getServicesTotals()
            })
        },
        getInvoicesDates() {  
            window.console.log('START END', this.startdate, this.enddate)  
            this.invoiceTotals = {}
            this.invoiceDates = [] 
            this.invoiceItemTotals = this.totalsObj   
            this.$store.dispatch('getInvoicesDates',{startdate: this.startdate,enddate: this.enddate}).then(response => {
                this.allInvoices = response
                window.console.log('getInvoicesDates ALL', this.allInvoices)
                this.getPromtionInvoices()
                this.setValuesAllInvoices()
                this.sortInvoicesByDate()
                this.setItemTotal()
            })
        },
        getPromtionInvoices() {
            this.allInvoicePromotions = this.allInvoices.filter(invoice => {
                if (invoice.type == 'promotion') {
                    return invoice
                }
            })
        },
        setValuesAllInvoices() {
            let totals = this.totalsObj

            this.allInvoices.forEach(invoice => {
                if (invoice.type !== 'promotion') {
                    totals.sales += Number(invoice.invoicetotal)
                    totals.tips += Number(invoice.tip)
                    totals.taxes += Number(invoice.taxservice) + Number(invoice.taxproduct)
                    totals.services += Number(invoice.invoicetotal) - Number(invoice.tip) - Number(invoice.taxservice) - Number(invoice.taxproduct)
                    totals.paid += Number(invoice.amountpaid)
                    totals.promo += Number(invoice.creditapplied)
                }             
            })            
            window.console.log('TOTAL----------', totals)
            this.invoiceTotals = totals
        },
        sortInvoicesByDate(){
            this.allInvoices.forEach(invoice => {
                 this.findMatchingDate(Globals.formatShortDate(new Date(invoice.datepaid)),invoice)
            })
            window.console.log('RESULT',this.invoiceDates.length, this.invoiceDates)
        },
        findMatchingDate(date,invoice){
            let dateNotFound = true
            this.invoiceDates.forEach(o => {
                if ( o.date == date){
                    dateNotFound = false
                    o = this.updateInvoiceObj(o,invoice)
                }
            })
            if (dateNotFound) {
                this.invoiceDates.push(this.updateInvoiceObj({date: date, invoices:[],billed:0,total:0,cash:0,creditcard:0,check:0,credit:0,servprod:0,tips:0,taxes:0},invoice))
            }  
        },
        updateInvoiceObj(obj,invoice) {
            const amount = Number(invoice.amountpaid)
            obj.invoices.push(invoice)
            obj.total += amount
            obj.servprod += Number(invoice.productservicetotal)
            obj.tips += Number(invoice.tip)
            obj.taxes += Number(invoice.taxproduct) + Number(invoice.taxservice)
            obj.billed += Number(invoice.invoicetotal) + Number(invoice.tip)
            if(invoice.paytype == 'Cash') {
                obj.cash += amount
            } 
            if (invoice.paytype == 'Credit Card') {
                obj.creditcard += amount
            }
            if (invoice.paytype == 'Check') {
                obj.check += amount
            }
            if (invoice.creditapplied) {
                obj.credit += Number(invoice.creditapplied)
            }
            return obj
        },
        setItemTotal() {
            this.allInvoices.forEach(invoice => {
                this.invoiceItemTotals.totals += Number(invoice.amountpaid)
                this.invoiceItemTotals.billed += Number(invoice.invoicetotal) + Number(invoice.tip)
                if (invoice.paytype == 'Cash') {
                    this.invoiceItemTotals.cash += Number(invoice.amountpaid)
                }
                if (invoice.paytype == 'Credit Card') {
                    this.invoiceItemTotals.creditcard += Number(invoice.amountpaid)
                }
                if (invoice.paytype == 'Check') {
                    this.invoiceItemTotals.check += Number(invoice.amountpaid)
                }
                this.invoiceItemTotals.credit += Number(invoice.creditapplied)
                this.invoiceItemTotals.servprod += Number(invoice.productservicetotal)
                this.invoiceItemTotals.tips += Number(invoice.tip)
                this.invoiceItemTotals.taxes += Number(invoice.taxproduct) + Number(invoice.taxservice)
            })
        }
    },
    beforeMount() {
        this.getInvoicesDates()
    },
    watch: {
        startdate() {
            this.getInvoicesDates()
        },
        enddate() {
            this.getInvoicesDates()
        }
    },
}
</script>

<style scoped>
    .small-item{
        width: 11%;
    }
</style>