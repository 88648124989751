<template>
  <div id="app" class="main-app-container">
    <Header/>
    <Navigation/>
    <router-view/>
    <Footer/>
    <Modal 
      v-if="showModal" 
      v-bind:displayInfo="modalInfo"
    />
  </div>
</template>

<script>
import Navigation from './components/main/Navigation'
import Modal from './components/utility/Modal'
import EventBus from './utilities/eventBus'
import store from './store/store'
import Footer from './components/main/Footer'
import Header from './components/main/Header'

export default {
  name: 'app',
  components: {
    Navigation,
    Modal,
    Footer,
    Header,
  },
  data: function() {
    return {
      showModal: false,
      store,
      modalInfo:'',
    }
  },
  mounted() {
    let vm = this;
    EventBus.$on('CLOSE_MODAL', function() {
      vm.showModal = false;
    })
    EventBus.$on('SHOW_MODAL', function(item){
      window.console.log('show modal',item)
      vm.modalInfo = item;
      vm.showModal = true;
    })
  },
  created() {
  
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-app-container{
  /* background-color: #ececec; */
  background-color: #cad1d2;
}
input{
  height: 18px;
  font-size: 14px;
}
.form-input-row {
    min-height: 25px;
    text-align: left;
}
.form-title-row{
  width: 100%;
  background-color: #28a4bf;
  height: 27px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  vertical-align: middle;
  color: white;
}
.list-item{
    display: inline-block;
    padding: 5px;
    text-align: left;
    min-width: 150px;
}
.button-main{
  padding: 7px 15px 7px 15px;
  background-color: #0b8ecc;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  border-radius: 20px;
  margin: 10px 8px 0 8px;
}
.button-back{
  background-color: #758588;
  padding: 7px 12px 7px 7px;
  position: relative;
  position: absolute;
  left: 100px;
}
.cancel-version{
  background-color: #178a9c;
  margin-left: 15px;
}
.icon-version{
  padding: 7px 10px;
  background-color: #0bb2cc;
  margin-left: 15px;
}
.icon-row-version{
  padding: 4px 9px;
  background-color: #0bb2cc;
  margin-left: 15px;
  margin-top: 0;
}
.small-icon-version{
  font-size: 12px;
  padding: 4px 6px;
  background-color: #0bb2cc;
  margin-left: 15px;
  margin-top: 0;
}
.button-main:hover, .button-icon:hover{
  background-color: #3189d4;
  color: white;
}
.button-icon{
    display: inline-block;
    background-color: #0bb2cc;
    color: white;
    padding: 5px 7px;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 10px;
}
.small-icon{
    padding: 0 8px;
    margin-top: 3px;
    position: relative;
    left: -7px;
    top: 1px;
}
.small-button{
    padding: 3px 10px;
    margin: 3px 0 3px 10px;
    background-color: #0ba0cc;
    font-size: 12px;
}
.button-disabled{
    background-color: #80808069;
}
.button-plain{
  padding: 5px 15px 5px 15px;
  background-color: white;
  border: 1px solid gray;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.button-plain:hover{
  background-color: #e6e9ee;
  color: black;
}
.input-main{
  height: 16px;
  background-color: white;
  border: 1px solid gray;
  width: 150px;
  margin: 5px;
  padding: 3px 10px 5px 5px;
  font-size: 14px;
}
.form-input-row{
  height: 35px;
  text-align: left;
}
.form-input-label {
  display: inline-block;
  width: 40%;
  text-align: right;
  padding-right: 10px;
  min-width: 100px;
  vertical-align: top;
  position: relative;
  top: 2px;
}
.form-label-center {
  text-align: center;
  width: 100%;
}
.form-content-item{
  display: inline-block;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.form-content-amount{
  display: inline-block;
  font-weight: 600;
  position: relative;
  top: 2px;
  text-align: right;
  width: 75px;
}
.form-content-input{
  height: 17px;
  font-size: 16px;
  padding-left: 5px;
}
.form-success-msg{
    color: green;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    padding: 10px;
}
.form-add-item-row{
    height: 24px;
    text-align: left;
    /* margin-top: 4px; */
    padding: 3px;
    background-color: #e9edf1;
}
.small-input{
    width: 15%;
    min-width: 40px;
}
.missing-selection-message{
    color: red;
    display: block;
    margin-top: -8px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 5px;
}
.search-ahead-row{
    height: 35px;
}

.main-input-form-container{
  margin: 0 10%;
  /* border: 1px solid lightgray; */
  padding-bottom: 15px;
  max-width: 800px;
  background-color: white;
  border-top: none;
  font-size: 16px;
}
.input-wide{
  width: 200px;
}
.input-narrow{
  width: 50px;
}
.input-medium{
  width: 100px;
}
.success-label{
    color: green;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    padding: 10px;
}
.service-title{
    font-weight: 600;
    font-size: 14px;
    background-color: #2080a5;
    color: white;
    padding: 2px;
    border-bottom: 1px solid #6fa3bd;
    
}
.service-existing-container{
    margin: 0 10%;
    border: 1px solid #6fa3bd;
    max-height: 315px;
    overflow-y: scroll;  
    margin-top: 15px;
    position: relative; 
}
.service-type-row{
    border-bottom: 1px solid #6fa3bd;
    padding: 2px;
}
.service-type-row:last-child{
    border-bottom: none;
}
.mobile-menu-button-container{
    display: none;
}

.required-entry{
    color: red;
}
.paid-full{
    display: inline-block;
    color: #2a922a;
    font-size: 25px;
    vertical-align: middle;
    margin: 0 5px;
    height: 20px;
    vertical-align: top;
    margin-top: -4px;
}
.date-selection-container{
    display: inline-block;
    z-index: 3;
}
.checkbox-container{
    display: inline-block;
}
.invoice-item-row{
    display: inline-block;
    float: left;
    font-size: 13px;
    width: 90%;
    height: 34px;
    line-height: 25px;
    /* margin-left: 9px; */
}
.invoice-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 6px 5px 0px 5px;
    width: 14%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
    font-size: 14px;
}
.invoice-content-input{
    width: 50px;
    font-size: 13px;
    text-align: center;
    border: none;
    padding: 0;
}
.infotable-items-row-container{
    width: 100%;
    display: inline-block;
    height: 32px;
    vertical-align: top;
}

/* COLORS */

.color-dark-blue{
  color: #2c6bbb;
}
.color-white{
  color: white;
}
.small-icon.dark-turq{
  background-color: #480a86;
  width: 13px;
  /* #457584; */
}
.back-color-red{
  background-color: #f57777;
}
.back-color-red:hover{
  background-color: #b10000;
}
.back-color-dark-turq{
   background-color: #4d91a7;
}
.back-color-light-turq{
  background-color: #c6eef7;
}
.back-color-dark-gray{
  background-color: #758588;
}
.back-color-light-blue{
  background-color: #bde1ff;
}
.form-input-container{
    display: inline-block;
    position: relative;
}
.delete-image-confirm{
    position: absolute;
    background-color: white;
    color: black;
    width: 150px;
    left: -50px;
    top: -80px;
    z-index: 3;
    padding: 5px;
    border: 2px solid red;
    font-size: 14px;
}
.user-container{
    display: inline-block;
    vertical-align: top;
    float: right;
    padding: 14px;
    padding-bottom: 0;
    cursor: pointer;
    position: relative;
}
.company-info-container{
    font-family: 'Raleway', sans-serif;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    font-weight: 600;
    color: #2c88d2;
    padding-left: 15px;
    width: 34%;
    text-align: center;
    height: 45px;
    margin-top: 5px;
}
.delete-booking-confirm{
    position: absolute;
    background-color: white;
    color: black;
    width: 150px;
    left: -50px;
    bottom: 35px;
    z-index: 3;
    padding: 5px;
    border: 2px solid red;
    font-size: 14px;
}
.delete-reason{
    width: 135px;
    height: 15px;
    margin: 5px 0;
}

/* @MEDIA CHANGES */
@media screen and (max-width: 960px) {
  .main-input-form-container{
    margin: 0;
    max-width: 100%;
    font-size: 13px;
  }
}
@media screen and (max-width: 680px) {
  .main-input-form-container{
    margin: 0;
    font-size: 13px;
  }
  .main-app-container{
    overflow-x: auto;
  } 
  /* .navigation-container{
    display: none;
  } */
  .main-footer-container{
    margin: 0 !important;
    width: 100%;
  }
  /* .mobile-menu-button-container{
    position: absolute;
    right: 20px;
    top: 5px;
    display: block;
    color: white;
    cursor: pointer;
  } */
  /* .form-title-row{
    background-color: #2eaff9;
    color: white;
    cursor: default;
    background-color: #9cd5f1;
  } */
  /* .schedule-buttons-group-right{
    right: 30px !important;
    color: white;
  } */
  /* .disabled{
    background-color: #807d7d !important;
  } */
  .form-input-label{
    width: 25%;
  }
  .button-back{
    position: relative;
    left: none;
  }
  .input-search{
    width: 109px !important;
  }
  div.checkbox-label{
        font-size: 13px;
  }
  .checkbox-item-container{
      margin-left: 10px;
  }
  
}
@media screen and (max-width: 680px) {
  .mobile-menu-button-container {
    display: inline-block;
    float: right;
    margin: 15px;
    cursor: pointer;
  }
  .user-container{
    display: none !important;
  }
  /* .company-info-container{
    display: none !important;
  } */
}

.control-group-item{
    display: inline-block;
    padding: 0 5px;
    float: right;
}
.client-list-item{
    padding: 0 10px 0 55px;
    cursor: pointer;
}
.color-red{
  color: #bb7070;
}
.color-blue{
  color: #0d8ec1;
}
.booking-image-edit-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130%;
    background-color: black;
    opacity: .6
}
.booking-info-note{
    text-align: left;
    padding: 5px;
    width: 68%;
    display: inline-block;
    margin-top: 10px;
    border: 1px solid lightgray;
    min-height: 30px;
}
.booking-info-icon{
    display: inline-block;
    padding: 5px 15px 0 0;
    vertical-align: top;
    margin-top: 6px;
    margin-left: 15px;
}
.booking-note-input{
    width: 70%;
    font-size: 14px;
    padding: 10px;
    margin: 10px 0px;
}
.button-row-center{
    width: 100%;
    text-align: center;
    display: block;
}
.booking-info-container{
    max-width: 800px;
    border: 1px solid #b0b2b3;;
    margin: 0 10%;
    padding-bottom: 15px;
}
.booking-camera-container{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    border: 1px solid;
    background-color: white;
    text-align: center;
}
.booking-image-edit-container{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    border: 1px solid;
    background-color: white;
    text-align: center;
}
.camera-container-title-row{
    width: 100%;
    text-align: right;
    background-color: #3d6eb3;
    color: white;
}
.close-box{
    height: 30px;
    width: 30px;
    color: white;
    font-size: 35px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    transform: rotate(45deg);
    padding: 5px;
}
.camera-app-title{
    display: inline-block;
    text-align: center;
    font-weight: 600;
    width: 90%;
    font-size: 20px;
    vertical-align: top;
    padding-top: 6px;
}


</style>
