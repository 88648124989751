<template>
    <div class="imaging-container">
        <canvas class="split-canvas"  id="canvasSplit" width="1330" height="560" v-if="showSplitCanvas"></canvas>
        <div class="camera-display-container" v-if="showCapture"> 
            <div class="top-capture-button-container">
                <div class="edit-button camera-button no-float " @click="stream" >
                    <i class="fas fa-camera"></i>
                </div>
            </div>          
            <canvas  ref="canvas" id="canvas" 
                width="640" height="480" 
                :style="getStatus()" 
                v-if="showCanvas"
                
                @click="clickOnImage" ></canvas>
            <canvas  ref="canvasSBS" id="canvasSBS" 
                width="650"  
                :style="getStatus()" 
                v-if="showSBSCanvas"
                 ></canvas>
        </div>
        <input id="image-capture" name="image-capture" type="file" accept="image/*" capture="environment" style="display:none" @change="imageCaptured($event)"> 
        <div class="camera-display-split-container" v-if="showCapture">
            
        </div>
        <div class="camera-split-imge-container" id="canvasSBS-container" :style="setSplit2Container()" v-if="showSplit2"> 
            <div class="image-box-left" :style="splitSize()" @click="splitLeftSelected=true,splitRightSelected=false" :class="{'selected': splitLeftSelected}">
                <div class="split-notice" v-if="showSplitNoticeLeft">{{'Click to Activate' }}<br>{{'then Select Image' }}</div>
                <img id='imageLeft' class="image-left" :style="splitSize()" crossorigin="Anonymous"/>
            </div>
            <div class="image-box-right" :style="splitSize()" @click="splitRightSelected=true,splitLeftSelected=false" :class="{'selected': splitRightSelected}">
                <div class="split-notice" v-if="showSplitNoticeRight">{{'Click to Activate' }}<br>{{'then Select Image' }}</div>
                <img id='imageRight' class="image-right" :style="splitSize()" crossorigin="Anonymous"/>
            </div>
            <textarea class="split-note" v-model="splitNote" />
            <div class="split-button-row">
                <div class="image-button" @click="saveSplitImage()">
                    {{'Save'}}
                </div>
                <div class="image-button" @click="closeSplit">{{'Close Side-by-Side'}}</div>
                <div class="image-saved split-saved" v-if="showSaved">Image Successfully Saved</div>
            </div>
        </div>
        <textarea class="edit-note" v-model="imageNote" v-if="showCanvas" @input="showSaveNote=true"></textarea>
        <div class="modal-container" v-if="showSendImage">
            <div class="image-background-container" ></div>
            <div class="image-send-container" >
                <!-- <div class="image-send-title">{{'Send Image to'}} {{bookinginfo.client}}</div> -->
                <div>{{'Note'}}</div>
                <textarea class="note-input" v-model="sendNote"  />
                <!-- <div>
                    <div class="image-button" @click="sendImage">Send</div><div class="image-button" @click="showSendImage = false">Cancel</div>
                </div> -->
                <div class="form-input-row" v-show="showSuccess">
                    <div class="success-label">{{'Image Successfully Sent'}}</div>
                </div> 
            </div>
        </div>
        <div class="capture-edit-panel" v-if="!showSplit2">

         <!-- v-if="(showEdit && !showSBSCanvas) || showCapture"> -->
    
            <div class="edit-panel-icons" v-if="showMarkup">
                <div class="edit-button"  @click="setSelectedIcon('pen')" :class="{'selected-icon': drawPen}">
                    <i class="fas fa-pen"></i>
                </div>
                <div class="edit-button" @click="setSelectedIcon('circle')" :class="{'selected-icon': drawCircle}">
                    <i class="fas fa-circle"></i>
                </div>
                <div class="edit-button" @click="setSelectedIcon('plus')" :class="{'selected-icon': drawPlus}">
                    <i class="fas fa-plus"></i>
                </div>
                <div class="edit-button" @click="undoEdit" >
                    <i class="fas fa-undo"></i>
                </div>
                <div class="edit-button" @click="redoEdit" >
                    <i class="fas fa-redo"></i>
                </div>
            </div>
            <div class="edit-panel-icons" v-if="!showSBSCanvas && !showMarkup">
                
                <div class="edit-button wide-edit" @click="updateImageNote()" v-if="showSaveNote">
                    {{'Update'}}
                    <div class="image-saved" v-if="showSaved">Image Successfully Saved</div>
                    <div class="image-saved" v-if="showNoteUpdated">Image Note Successfully Updated</div>
                </div>

                <div class="edit-button wide-edit" @click="displayMarkup" v-if="!checkIfPhone() && showCanvas">
                    {{'Markup'}}
                </div>
                
                <div class="edit-button"  @click="ckConfirm" v-if="showDelete" >
                    <i class="fas fa-trash"></i>                        
                    <div class="delete-image-confirm" id="confirm-delete" v-if="showConfirm" >
                        {{'Are you sure you want to Delete this image'}}?
                        <div class="image-button" @click="deleteImage">{{'Yes'}}</div>
                        <div class="image-button plain" >{{'No'}}</div>
                    </div>                    
                </div>

            </div>
            
            <div class="edit-panel-buttons">
                <div class="button-bar-inline">
                    <ButtonBar 
                        :buttonlist="buttonList"
                        v-on:button-click="buttonClicked = $event" /> 
                    <div class="delete-image-confirm" id="confirm-delete" v-if="showConfirmList" >
                        {{'Are you sure you want to Delete this image'}}?
                        <div class="image-button" @click="deleteImage">{{'Yes'}}</div>
                        <div class="image-button plain" >{{'No'}}</div>
                    </div>   
                </div>
            </div>
            <div class="edit-button camera-button" @click="stream" >
                <i class="fas fa-camera"></i>
            </div>
        </div>
        <div v-if="showCapture && !showEdit">
            <div @click="capture()" v-if="showVideo">
                <div class="capture-outer-ring" >
                    <div class="capture-button" ></div>
                </div>
            </div>
            <div class="image-button" @click="closeWindow()" v-if="showVideo">{{'Close'}}</div>
        </div>

        <div class="session-images-container" v-if="showSessionImages">
            <div class="client-images-title-bar">{{ selectedClient.firstname }} {{ selectedClient.lastname }} Images</div>
            <div class="session-image" id="image-group" v-for="(imageData,index) in captures" :key="index">
                <img v-if="!imageData.imagedata"
                    v-bind:src="`${imageURL}${imageData.refnum}-th.jpg`" 
                    class="group-images" 
                    height="100" 
                    @click="copyToCanvas(imageData.refnum,index)" 
                    crossorigin="Anonymous" />
                    <!-- <img v-if="imageData.imagedata"
                    v-bind:src="imageData.imagedata" 
                    class="group-images" 
                    height="100" 
                    @click="copyToCanvas(imageData.refnum,index)" 
                    crossorigin="Anonymous" /> -->
                <div class="image-date">{{imageData.displaydate}} <i class="fas fa-pen pen-black" v-if="imageData.note" ></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../utilities/eventBus';
import ButtonBar from './ButtonBar'
import Globals from '../../utilities/globals'
import store from '../../store/store'

export default {
    name: 'ImagePicture',
    data() {
        return{
            store,
            captures: [],
            captureIndex: 0,
            video: document.querySelector('video'),
            constraints:{video: {
                facingMode: {ideal:'environment'},
                audio: false
            }},
            canvas: document.querySelector('canvas'),
            ctx: '',
            currentImage: '',
            canvasActive: false,
            context: null,
            showVideo: false,
            showSplit: false,
            showSplit2: false,
            showCapture: false,
            showCanvas: false,
            showSBSCanvas: false,
            showSplitCanvas: false,
            showEdit: false,
            showConfirm: false,
            showConfirmList: false,
            showSessionImages: true,
            showMarkup: false,
            showSendImage: false,
            showSuccess: false,
            showDelete: false,
            showSaveNote: false,
            showSaved: false,
            showNoteUpdated: false,
            selectedSplit:'canvas',
            canvasData: {},
            splitWidth: 0,
            splitHeight: 0,
            splitRightSelected: false,
            splitLeftSelected: false,
            heightRatio: 1,
            drawActive: false,
            currentX: 0,
            currentY: 0,
            prevX: 0,
            prevY: 0,
            drawStart: false,
            drawOffsetX: 9,
            drawOffsetY: 26,
            drawCircle: false,
            drawPlus: false,
            drawPen: false,
            splitNote:'',
            edits: [],
            editsundone: [],
            editIndex: -1,
            imageData: '',
            showSplitNoticeLeft: true,
            showSplitNoticeRight: true,
            buttonItems: ['Create Side-By-Side'],
            buttonList: ['Create Side-By-Side'],
            buttonClicked: {},
            selectedImageId: '',
            Yfactor: 1,
            Xfactor: 1,
            keys: {37: 1, 38: 1, 39: 1, 40: 1},
            supportsPassive: false,
            wheelOpt: this.supportsPassive ? { passive: false } : false,
            wheelEvent: 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel',
            imageURL: Globals.imageURL,
            sendNote: '',
            imageRef: this.imageinfo.refnum,
            imageNote: '',
            isPhone: false,
            isPhoneVertical: false,
            formatShortDate: Globals.formatShortDate,
            clientid: '',
            selectedClient: {}, 
        }
    },
    components: {
        ButtonBar
    },
    props: [
        'takepicture','imageinfo'
    ],
    methods: {
        sendImage() {
            const data = {
                clientid: this.clientid,
                note: this.sendNote,
                imageref: this.imageRef,
                clientemail: 'yalebiz@gmail.com',             
            }

            if (this.$store.getters.user.businesstype == 'medspa') {
                this.$store.dispatch('sendMedPictureClient',data).then(response => {
                    window.console.log('RESULTS FROM SEND PICTURE',response)
                    if (response.result == 'SUCCESS') {
                        this.showSuccess = true
                        setTimeout(() => {
                            this.showSendImage = false
                        }, 3000);
                    }
                })

            } else {
                this.$store.dispatch('sendPictureClient',data).then(response => {
                    window.console.log('RESULTS FROM SEND PICTURE',response)
                    if (response.result == 'SUCCESS') {
                        this.showSuccess = true
                        setTimeout(() => {
                            this.showSendImage = false
                        }, 3000);
                    }
                })
            }
        },
        setDrawEvents() {
            window.console.log('SET DRAW EVENTS')
            this.canvas = document.getElementById('canvas')
            this.ctx = this.canvas.getContext('2d')            
            this.canvas.addEventListener('mousemove',this.drawOnImage,true)            
            this.canvas.addEventListener('mousedown',this.drawCanvasStart,true)
            this.canvas.addEventListener('mouseup',this.drawCanvasStop,true)
            this.canvas.addEventListener('mouseleave',this.drawCanvasStop,true)
            this.canvas.addEventListener('touchstart',this.drawCanvasStart,false)
            this.canvas.addEventListener('touchend',this.drawCanvasStop,false)
           this.canvas.addEventListener('touchmove',this.drawOnImage,false)
        },
        drawCanvasStart(e) {
            e.preventDefault()
            this.canvas.style.cursor = 'crosshair'
            
            this.drawStart = true
            this.canvas = document.getElementById('canvas')
            this.ctx = this.canvas.getContext('2d')
            window.console.log('DRAW ON CANVAS', this.canvas.width, this.canvas.height)
            if (this.drawPen) {
                this.imageData = this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height)
                if (this.editIndex < this.edits.length -1) {
                    this.edits.splice(this.editIndex)
                    this.edits.push(this.imageData)
                } else {
                    this.edits.push(this.imageData)
                    this.editIndex++
                }
                this.drawActive = true
                window.console.log('DRAW ACTIVE TRUE',this.drawStart)
            }
        },
        drawCanvasStop() {
            this.drawActive = false
        },
        removeDrawEvents() {
            window.console.log('REMOVE EVENTS')
            this.canvas.removeEventListener('mousemove', this.drawOnImage,true)
            this.canvas.removeEventListener('mousedown', this.drawCanvasStart,true)
            this.canvas.removeEventListener('mouseup', this.drawCanvasStop,true)
            this.canvas.removeEventListener('mouseleave', this.drawCanvasStop,true)
            this.canvas.removeEventListener('touchstart',this.drawCanvasStart,false)
            this.canvas.removeEventListener('touchend',this.drawCanvasStop,false)
            this.canvas.removeEventListener('touchmove',this.drawOnImage,false)
        },
        displayMarkup() {
            this.showMarkup = true;
            this.resizeCanvasMarkup()
            this.buttonList = ['Save','Close Markup']
            // window.scrollTo(0,0)
            this.edits = []
            this.editIndex = -1
        },
        disableScroll() {
           if (window.scrollY > 0 || window.scrollX > 0) {
                window.scrollTo(0,0);
            }
        },
        resizeCanvasMarkup() {
            const canvas = document.getElementById('canvas');
            const heightWidthRatio = canvas.width/canvas.height
            const maximumHeight = window.innerHeight - 95
            if (canvas.clientHeight > canvas.clientWidth) {
                canvas.style.maxHeight = maximumHeight + 'px'
                canvas.style.maxWidth = (maximumHeight * heightWidthRatio) + 'px'
            } else {
                if (window.innerWidth * heightWidthRatio > maximumHeight) {
                    canvas.style.maxHeight = maximumHeight +'px'
                    canvas.style.maxWidth = (maximumHeight * heightWidthRatio) + 'px'
                } else {
                    canvas.style.maxWidth = window.innerWidth + 'px'
                    canvas.style.maxHeight = (window.innerWidth * heightWidthRatio) + 'px'
                }
                
            }
        },
        ckConfirm(e) {
            this.showConfirm = !this.showConfirm
            if (this.showConfirm && (window.innerWidth - e.screenX) < 75) {
                setTimeout(() => {
                    document.getElementById('confirm-delete').style.left = '0'
                },0)                
            }
        },
        capture() {           
            this.showCanvas = true
            setTimeout(() => {
                this.canvas = document.getElementById('canvas')
                let ctx = this.canvas.getContext("2d")
                this.canvasData.width = this.canvas.width
                this.canvasData.height = this.canvas.height
                ctx.font = '300px Arial'
                ctx.fillStyle='white'
                const today = new Date()
                const displayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
                // ctx.fillText('THIS IS A TEST', this.canvas.height/2, this.canvas.width/2)
                window.console.log('CAPX',displayDate, this.canvas.width, this.canvas.height, ctx.width, ctx.height)
                // const image = this.canvas.toDataURL('image/jpeg', 0.6)
                // this.saveImage(image,'NORM')
                this.captureIndex = this.captures.length
                this.showEdit = true
                this.canvasActive = true                
            },100)
            setTimeout(() => {
                this.heightRatio = Number(document.getElementsByClassName('group-images')[0].width)/100
                window.console.log('IMAGE SIZE', document.getElementsByClassName('group-images')[0].width)
                this.showVideo = false
            },100)
            
            
        },
        checkMobileVertical() {
            if (window.innerWidth < window.innerHeight) {
                window.console.log('IN VERTICAL', navigator.userAgent,window.innerWidth)
                if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
                    window.console.log('ON MOBILE DEVICE', window.innerWidth , window.innerHeight)
                    return true
                }
                return true
            }
            return false            
        },
        checkIfPhone() {
            window.console.log('CHECK PHONE', navigator.userAgent, window.innerWidth)
            if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
                this.isPhone = true;
                this.isPhoneVertical = window.innerWidth < 680 ? true : false;
                return true
            }
            return false
        },
        setSplitSize() {
            // SET SPLIT SIZE BASED ON 4:3 RATIO
            this.splitWidth = (window.innerWidth - 100)/2
            this.splitHeight = this.splitWidth * (100/133)
        },
        openSplit() {
            
            this.showSplitCanvas = false
            this.showSplit2 = true 
            this.showCapture = false
            this.showVideo = false
            this.showSplitNoticeLeft = true
            this.showSplitNoticeRight = true
            this.showEdit = false
            this.splitNote = '';
            this.imageNote = '';
        },
        closeSplit() {
            this.showSplit2 = false 
            this.showCapture = true
            this.showVideo = false
            this.showSplitNoticeLeft = false
            this.showSplitNoticeRight = false
            this.showEdit = true
        },
        saveImage(image,type) {
            let data = {}
            data.type = type            
            data.clientid = this.clientid
            data.businessid = this.$store.getters.user.businessid
            data.userid= this.$store.getters.user.id
            data.locationid = 0
            data.imagetype='camera'
            data.imagetime=new Date()
            data.image = image
            data.imagenote = this.imageNote
            this.$store.dispatch('saveImage',data).then(response => {
                window.console.log('DATA SAVED', response)
                this.$store.commit('setShowLoader',false)
                if (response.result === 'SUCCESS') {
                    this.showSaved = true;
                    this.selectedImageId = response.id
                    window.console.log('DATA SAVED SUCCESSFULLY', this.selectedImageId)
                    // EventBus.$emit('IMAGE_ADDED')
                    setTimeout(() => {
                        this.getImagesDB();
                        this.showSaved = false;
                    },3000)                    
                }
            })
        },
        closeWindow() {
            this.$emit('close-edit', false)
        },
        getStatus() {
            if (!this.showVideo && !this.showSplit) {
                return {zIndex: 3}
            }
            return {zIndex: 0}            
        },
        stream() {
            document.getElementById('image-capture').click()
            // this.showVideo = true
            this.showCanvas = false
            this.showEdit = false
            // navigator.mediaDevices.getUserMedia(this.constraints).then(this.handleSuccess).catch(this.handleError)    
        },
        imageCaptured(e) {
            console.log('IMAGE CAPTURED')
            this.showCapture = true
            this.showCanvas = true
            this.buttonList = ['Create Side-By-Side','Delete']
            this.drawOnCanvas(e.target.files[0])            
        },
        drawOnCanvas(file) {
            const reader = new FileReader();
            let vm = this
            reader.onload = function (e) {
                let dataURL = e.target.result
                vm.canvas = document.getElementById('canvas');
                let ctx = vm.canvas.getContext('2d')
                let img = new Image();

                window.console.log('CAP', vm.canvas.width, vm.canvas.height, ctx.width, ctx.height)

                img.onload = function() {
                    vm.canvas.width = img.width;
                    vm.canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const fontSize = img.height * 0.05
                    ctx.font = `${fontSize}px Arial`
                    
                    const today = new Date()
                    const displayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
                    const top = Number(vm.canvas.height) - (fontSize/2) 
                    const left = 60
                    // PUT BACKGROUND TO TEXT
                    ctx.globalAlpha = 0.3;
                    ctx.fillStyle='black'
                    ctx.fillRect(40,top - (fontSize * 1.2),fontSize * 5.8,fontSize * 1.5);
                    ctx.globalAlpha = 1.0;
                    ctx.fillStyle='white'
                    ctx.fillText(displayDate, left, top)
                    window.console.log('DISPLAY DATE', displayDate,top,vm.canvas.height,left,vm.canvas.width)
                };

                img.src = dataURL;
                setTimeout(() => {
                    let image = vm.canvas.toDataURL('image/jpeg', 0.2)
                    // vm.store.dispatch('uploadImage',image)
                    vm.saveImage(image,'NORM')
                    // vm.captures.push(image)
                    vm.captureIndex = this.captures ? this.captures.length : 0
                    vm.showEdit = true
                    vm.canvasActive = true
                }, 1000);
                
            };
            
            reader.readAsDataURL(file);
            
        },
        handleError() {
            alert('ACCESS CAMERA ERROR')
        },
        stopStream() {
            const video = document.querySelector('video')
            video.srcObject = null;
        },
        errorCallback(error) {
            window.console.log('navigator.getUserMedia error: ', error);
        },
        handleSuccess(stream) {
            window.stream = stream; // only to make stream available to console
            const video = document.querySelector('video')
            video.srcObject = stream;
        },
        addImage() {
            const canvas = document.getElementById('canvas');
            // this.captures.push(canvas.toDataURL('image/jpeg', 1.0))
            const image = canvas.toDataURL('image/jpeg', 0.6)
            this.saveImage(image,'NORM')
            // NEED TO CLEAR ALL EDIT WHEN CLOSE OR LOAD NEW IMAGE
        },
        closeEdit() {
            this.showCanvas = true
            this.showEdit = false
            this.showCapture = true
            this.edits = []
            this.editIndex = 0
        },
        deleteImage() {
            window.console.log('DELETE IMAGE', this.captureIndex, this.captures, this.selectedImageId)
            
            this.captures.splice(this.captureIndex,1)
            this.$store.dispatch('deleteImage',{id: this.selectedImageId}).then(response => {
                window.console.log('IMAGE DELETED',response)
                if (response.result == 'SUCCESS') {
                    this.getImagesDB()
                    this.clearCanvas()
                    this.imageNote = '';
                    this.splitNote = '';
                    EventBus.$emit('IMAGE_ADDED')
                }
            })
        },
        clearCanvas() {
            let canvas = document.getElementById('canvas')            
            let ctx = canvas.getContext("2d")
            ctx.fillStyle= 'black'
            ctx.fillRect(0,0,canvas.width,canvas.height)
            this.showDelete = false;
            this.showSaveNote = false;
            this.showConfirm = false; 
            this.showConfirmList = false; 
            if (this.buttonList.includes('Delete')) {
                this.buttonList = ['Create Side-By-Side'];
            }        
        },
        getImageNote(refnum) {           
            this.$store.dispatch('getImageNote',refnum).then(response => {
                console.log('GOT IMAGE NOTE', response)              
                this.imageNote = response.note ? response.note : '';
                if (this.splitNote.length) {
                    this.splitNote += response.note ? ` | ${response.note}`: '';
                } else {
                    this.splitNote = response.note ? response.note : '';
                }
                
            })
        },
        copyToCanvas(imgSrc,index) {
            // IF SETTING UP LARGE VIEW/DRAW VIEW
            console.log('COPY CANVAS SPLIT LEFT', this.splitLeftSelected, this.splitRightSelected)
            
            this.showCanvas = true;
            if (this.splitLeftSelected || this.splitRightSelected) {
                console.log('NOTE SPLIT', this.imageNote)
                this.showSBSCanvas = true;
                this.showCanvas = false;
            } 
            this.getImageNote(imgSrc);           
            this.showCapture = true;
            console.log('COPYTOCANVAS', imgSrc, index)
            if ((this.showSplit2 && (this.splitRightSelected || this.splitLeftSelected)) || !this.showSplit2) {
                this.$store.commit('setShowLoader',true);
            }
            
            this.imageRef = imgSrc
            const refImage = `${this.imageURL}${imgSrc}.jpg`
            // window.console.log('COPY TO CANVAS IMAGE PIC', index, this.captures[index], this.imageinfo, 'user', this.$store.getters.user.id, this.captures[index].userid)
            this.showDelete = this.captures[index].userid === this.$store.getters.user.id ? true : false;
            this.selectedImageId = this.captures[index].id
// this.imageinfo = this.captures[index] ? this.captures[index] : this.imageinfo
            this.imageNote = this.imageinfo.note
            this.buttonList = this.buttonItems
            // this.selectedImageId = index === 0 ? this.imageinfo.id : this.captures[index].id
            window.console.log('COPY CANVAS', this.selectedImageId)
            this.showVideo = false
            // this.showCanvas = true
            let isSBS = false
            if (this.captures[index] && this.captures[index].type) {  
                window.console.log('isSBS BEFORE', isSBS, this.captures[index].type)             
                isSBS = this.captures[index].type === 'SBS' ? true : false
                window.console.log('isSBS', isSBS, this.captures[index].type)
            } else {
                isSBS = this.imageinfo.type === 'SBS' ? true : false
                window.console.log('UNDEFINED', isSBS, this.imageinfo.type)
            }
                var img = new Image();
                img.src = refImage //imgSrc
                img.crossOrigin = 'Anonymous'               
                let vm = this
                img.onload = function() {
                    vm.showSBSCanvas = false
                    vm.canvas = document.getElementById('canvas')                    
                    vm.ctx = vm.canvas.getContext("2d")
                    vm.canvas.width = img.width;
                    vm.canvas.height = img.height;
                    vm.ctx.drawImage(img, 0, 0);
                    vm.captureIndex = index
                    vm.showEdit = true
                    vm.canvasActive = true
                    vm.store.commit('setShowLoader',false)
                };
                
            // IF SETTING UP SPLIT IMAGES
            let imageLeft = document.getElementById('imageLeft')
            let imageRight = document.getElementById('imageRight')
            if (this.splitLeftSelected) {
                imageLeft.src = refImage
                this.splitLeftSelected = false
                this.showSplitNoticeLeft = false
                this.$store.commit('setShowLoader',false)
            }
            if (this.splitRightSelected) {
                imageRight.src = refImage
                this.splitRightSelected = false
                this.showSplitNoticeRight = false
                this.$store.commit('setShowLoader',false)
            }
                       
        },
        saveSplitImage() {
            this.$store.commit('setShowLoader',true);
            this.imageNote = '';
            this.showSplitCanvas = true;
            setTimeout(() => {
                let imageLeft = document.getElementById('imageLeft')
                let imageRight = document.getElementById('imageRight')
                let canvas = document.getElementById('canvasSplit')  
                window.console.log('CANVAS', canvas)
                    window.console.log('BEFORE GET CONTEXT CANVAS', canvas.width, canvas.height)
                    window.console.log('BEFORE GET CONTEXT LEFT ', imageLeft.width, imageLeft.height,imageLeft, imageLeft.clientHeight, 'RIGHT', imageRight.width, imageRight.height)
                    const imgWidth = (canvas.width * .97)/2
                    let imgHeightLeft = (imgWidth/imageLeft.width) * imageLeft.height
                    let imgHeightRight = (imgWidth/imageRight.width) * imageRight.height
                    const spacing = canvas.width * .01
                    let newCanvasHeight = imageLeft.height > imageRight.height ? imageLeft.height : imageRight.height
                    console.log('WINDOW WIDTH', window.innerWidth, newCanvasHeight)
                    window.console.log('NEW CANV HEIGHT',newCanvasHeight,'height',imageRight.height,imageLeft.height,'width', imgWidth, imageLeft.width, 'height left', imgHeightLeft)
                    let orientTall = false
                    if (imageRight.height > imageRight.width || imageLeft.height > imageLeft.width) {                        
                        newCanvasHeight += 400
                        orientTall = true
                        console.log('ADDED FOR MIXED 500')
                        console.log('IS TALL is PHONE VERT', this.isPhoneVertical, 'CANVAS HW',  canvas.width,canvas.height, 'NEW CANV H',newCanvasHeight,'IMGW', imgWidth, 'RIGT HHW',imageRight.height,imgHeightRight,imageRight.width, 'LEFT HHW', imageLeft.height, imgHeightLeft,imageLeft.width)     
                    } 
                    if (this.isPhoneVertical && this.checkIfPhone()) {
                        console.log('ADDED FOR PHONE VERTICAL 500')
                        newCanvasHeight += 500;
                        console.log('AFTER is PHONE VERTICAL',  imgHeightLeft,imgHeightRight)
                    } else {
                        // console.log('ADDED FOR REGULAR 200')
                        // newCanvasHeight += 200;
                    }
                    
                    console.log('NC-HEIGHT', newCanvasHeight)
                    canvas.height = newCanvasHeight
                    
                    console.log('NC-HEIGHT AFT', newCanvasHeight)
                    let ctx = canvas.getContext("2d")
                    // Set Canvas Height to at least 150px greater than largest image height
                    const maxImgHgt = Math.max(imgHeightRight, imgHeightLeft);
                    const minFooterHgt = 150
                    newCanvasHeight = maxImgHgt + minFooterHgt;
                    canvas.height = newCanvasHeight
                    ctx.fillStyle='white'
                    ctx.fillRect(0,0,canvas.width,newCanvasHeight)
                    
                    ctx.drawImage(imageLeft, spacing, spacing, imgWidth, imgHeightLeft)
                    ctx.drawImage(imageRight, imgWidth + spacing + spacing, spacing, imgWidth, imgHeightRight)

                    const sizeFont = 30 * (this.Xfactor + this.Yfactor)/2
                    ctx.font = `${sizeFont}px Arial`;
                    ctx.fillStyle = 'black'
                    // Set line breaks for text
                    let startVertical = 0
                    if (orientTall) {
                        startVertical = newCanvasHeight - 50
                    } else {
                        startVertical = newCanvasHeight - 50
                    }
                    const noteArray = this.setLineBreaks(ctx,canvas.width - 100)
                    console.log('NOTE ARRAY', noteArray)
                    console.log('VERT POS', startVertical)
                    console.log('FONT SIZE', sizeFont)

                    noteArray.forEach(line => {
                        ctx.fillText(line, 50, startVertical);
                        startVertical += 35                       
                    })
                                        
                    window.console.log('SAVE SPLIT IMAGE',imageLeft.width,imageRight.height, this.splitNote)
                    const image = canvas.toDataURL('image/jpeg', 0.2)
                    this.saveImage(image,'SBS')
                    this.showSplitCanvas = false 
                    setTimeout(() => {
                        this.closeSplit()
                    }, 3000);       
            },100)
        },
        setLineBreaks(ctx,width) {
            const textArray = this.splitNote.split(' ')
            console.log('TEXT ARRAY',this.splitNote, textArray, width)
            let lineArray = []
            let lineText = ''
            textArray.forEach(item => {
                lineText += item + ' ';
                if (ctx.measureText(lineText).width > width - 100) {
                    lineArray.push(lineText)
                    lineText = ''
                } 
            })
            lineArray.push(lineText)
            return lineArray
        },
        setSplit(name) {
            this.selectedSplit = name
        },
        setSplit2Container() {
            const splitZ = this.showSplit2 === true ? 5 : 0
            return {
                zIndex: splitZ
            }
        },
        splitSize() {            
            return {
                width: `${this.splitWidth}px`,
                minHeight: `${this.splitHeight}px`
            }
        },
        clickOnImage(e) {           
            let c = document.getElementById("canvas");
            let ctx = c.getContext("2d");
            ctx.beginPath();
            // let posX = e.clientX - c.offsetLeft + this.drawOffsetX - 10;
            // let posY = e.clientY - c.offsetTop -32 
            this.Xfactor = c.width/c.clientWidth
            this.Yfactor = c.height/c.clientHeight
            console.log('canvas width',c.width,'canvas client width',)
            // posX = posX * this.Xfactor
            // posY = posY * this.Yfactor

            // if (window.innerWidth < 640) {
            //     window.console.log('ADJUST FOR LESS 640')
            //     let adjustment = 640/window.innerWidth
            //     posX = posX * adjustment
            //     posY = posY  * adjustment +  (window.pageYOffset * adjustment)
            // }
            ctx.fillStyle= 'white'
            if (this.drawCircle) {
                this.drawImageCircle(e,c,ctx);              
            }
            if (this.drawPlus) {
                this.drawImagePlus(e,c,ctx); 
                // this.imageData = ctx.getImageData(0,0,c.width,c.height)
                // if (this.editIndex < this.edits.length -1) {
                //     this.edits.splice(this.editIndex)
                //     this.edits.push(this.imageData)
                // } else {
                //     this.edits.push(this.imageData)
                //     this.editIndex++
                // }
                // const sizeFont = 30 * (this.Xfactor + this.Yfactor)/2
                // ctx.font = `${sizeFont}px Arial`;
                // ctx.fillText("+", posX - (10 * this.Xfactor) , posY + ((window.pageYOffset + 10) * this.Yfactor) ) 
            }
            
        },
        drawImagePlus (e,c,ctx) {
            this.imageData = ctx.getImageData(0,0,c.width,c.height)
            if (this.editIndex < this.edits.length -1) {
                this.edits.splice(this.editIndex)
                this.edits.push(this.imageData)
            } else {
                this.edits.push(this.imageData)
                this.editIndex++
            }
            let Xpos = (e.clientX -((window.innerWidth - c.clientWidth)/2)) * (c.width/c.clientWidth)
            this.Xfactor = c.width/c.clientWidth
            this.Yfactor = c.height/c.clientHeight
            const yCalc = e.y + window.pageYOffset -248;
            const sizeFont = 30 * (this.Xfactor + this.Yfactor)/2
            ctx.font = `${sizeFont}px Arial`;
            ctx.fillText("+", Xpos - 40 , (yCalc * this.Yfactor)+70 );
        },
        drawImageCircle (e,c,ctx) {
            this.imageData = ctx.getImageData(0,0,c.width,c.height)
            if (this.editIndex < this.edits.length -1) {
                this.edits.splice(this.editIndex)
                this.edits.push(this.imageData)
            } else {
                this.edits.push(this.imageData)
                this.editIndex++
            }
            ctx.beginPath();
            let Xpos = (e.clientX -((window.innerWidth - c.clientWidth)/2)) * (c.width/c.clientWidth)
            this.Xfactor = c.width/c.clientWidth
            this.Yfactor = c.height/c.clientHeight
            const yCalc = e.y + window.pageYOffset -248;
            ctx.arc(Xpos, (yCalc * this.Yfactor) , 5 * this.Xfactor, 0, 2 * Math.PI);               
            ctx.fill() 
        },
        drawOnImage(e) {
            e.preventDefault()
            this.Xfactor = this.canvas.width/this.canvas.clientWidth
            this.Yfactor = this.canvas.height/this.canvas.clientHeight
            const yCalc = e.y + window.pageYOffset -248;
            if (e.touches) {
                e = e.touches[0]
            }
            if (this.drawActive) {
                if (this.drawStart){
                    this.currentX = (e.clientX -((window.innerWidth - this.canvas.clientWidth)/2)) * (this.canvas.width/this.canvas.clientWidth)
                    this.currentY = yCalc * this.Yfactor
                    this.prevX = this.currentX
                    this.prevY = this.currentY
                    console.log('DRAW START',this.prevY,this.currentY,this.drawOffsetY,'WinPage', window.pageYOffset, 'WinTop',window.offsetTop)
                    this.drawStart = false
                }
                this.prevX = this.currentX
                this.prevY = this.currentY
                this.currentX = (e.clientX -((window.innerWidth - this.canvas.clientWidth)/2)) * (this.canvas.width/this.canvas.clientWidth)
                this.currentY = yCalc * this.Yfactor;

                this.ctx.beginPath();
                this.ctx.moveTo(this.prevX, this.prevY);
                this.ctx.lineTo(this.currentX, this.currentY);
                this.ctx.strokeStyle = 'white';
                this.ctx.lineWidth = 3 * ((this.Xfactor + this.Yfactor)/2);
                this.ctx.stroke();
                this.ctx.closePath();
            }           
        },
        setSelectedIcon(type) {
            if (type === 'pen') {
                this.drawPen = true
                this.drawPlus = false
                this.drawCircle = false
                this.canvas.style.cursor = 'crosshair'
                this.setDrawEvents()
            }
            if (type === 'plus') {
                this.drawPen = false
                this.drawPlus = true
                this.drawCircle = false
                this.canvas.style.cursor = 'crosshair'
                this.removeDrawEvents()
            }
            if (type === 'circle') {
                this.drawPen = false
                this.drawPlus = false
                this.drawCircle = true
                this.canvas.style.cursor = 'crosshair'
                this.removeDrawEvents()
            } 
            if (type === 'cancel') {
                this.drawPen = false
                this.drawPlus = false
                this.drawCircle = false
                this.removeDrawEvents()
            }
            
        },
        undoEdit() {
            if (this.editIndex > 0) {
                if (this.editIndex >= this.edits.length -1) {
                    window.console.log('ADD to EDIT')
                    this.edits.push(this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height))
                    this.editIndex++
                }
                this.editIndex--
                const imageData = this.edits[this.editIndex]
                const canvas = document.getElementById('canvas')            
                const ctx = canvas.getContext("2d")
                ctx.putImageData(imageData, 0, 0);
            }            
        },
        undoAllEdits() {
            let editCt = this.editIndex
            for (let i = editCt;i > -1;i--){
                window.console.log('CT',editCt,i, this.editIndex)
                this.undoEdit();
            }
        },
        redoEdit() {
            if (this.editIndex < this.edits.length -1) {
                this.editIndex++
                const imageData = this.edits[this.editIndex]
                const canvas = document.getElementById('canvas')            
                const ctx = canvas.getContext("2d")
                ctx.putImageData(imageData, 0, 0);
            }
        },
        getImages() {
            if (this.takepicture) {
                this.stream()
            }
            if (this.imageinfo && this.imageinfo.id && !this.takepicture) {
                this.copyToCanvas(this.imageinfo.refnum, 0)
            } else {
                this.getImagesDB()
            }
        },
        getImagesDB() {
            console.log('GET CLIENT IMAGES')
            this.$store.dispatch('getImages',{id: this.clientid}).then(response => {
                this.captures = response.data
                console.log('IMAGES', this.captures)
                this.captures.map(image => {                
                    image.displaydate = Globals.formatShortDate(new Date(image.dateentered))                   
                })               
            })
        },
        checkStatus() {
           
        },
        updateImageNote() {
            const data = {
                    imageid: this.selectedImageId,
                    note: this.imageNote
            }
            window.console.log('UPDATE NOTE', data)
            this.$store.dispatch('updateImageNote',data).then(response => {
                window.console.log('NOTE UPDATED', response, this.captureIndex)
                if (response == 'SUCCESS') {
                    this.showNoteUpdated = true;
                    console.log('Note Update')
                    setTimeout(() => {
                        console.log('NOTE UPDATED FINISHED')
                        this.showNoteUpdated = false;
                    },3000)
                }
            })
        },
//         startNoteTimer() {
//             this.noteTimer = setInterval(() => {
//                 const data = {
//                     imageid: this.selectedImageId,
//                     note: this.imageNote
//                 }
//                 window.console.log('UPDATE NOTE', data)
//                 clearInterval(this.noteTimer)
//                 this.$store.dispatch('updateImageNote',data).then(response => {
//                     window.console.log('NOTE UPDATED', response, this.captureIndex)
//                     if (response.result == 'SUCCESS') {
//                         // this.getImages();
// //this.imageinfo.note = this.imageNote
//                         this.captures[this.captureIndex].note = this.imageNote
//                         EventBus.emit('IMAGE_ADDED')
//                     }
//                 })
//             },1000)
//         },
        windowResize() {
            this.setSplitSize()
        },
        getClientInfo() {
            console.log('getClientInfoF',this.$store.getters.selectedClient)
            this.clientid = this.$store.getters.selectedClient.id
            this.selectedClient = this.$store.getters.selectedClient
            console.log('OPENIMAGE SEL CLIENT', this.selectedClient,this.selectedClient.id, this.clientid)
        },
    },
    computed: {
        
    },
    mounted() {
        console.log('MOUNTED')
        window.addEventListener('resize', this.windowResize);
        console.log('RESIZE')
        this.getClientInfo()
        console.log('getClientInfo')
        this.setSplitSize()
        console.log('setSplitSize')
        // this.getImages()
        this.getImagesDB()
        console.log('getImagesDB')
        // this.checkStatus()
    },
    watch: {
        buttonClicked() {
            if (this.buttonClicked.name === 'Create Side-By-Side') {
                this.checkIfPhone()
                this.openSplit()
                this.showSessionImages = true
            }
            if (this.buttonClicked.name === 'Close') {
                window.console.log('DISABLE SCROLL')
                window.removeEventListener('scroll',this.disableScroll,true)
                this.closeWindow()
            }
             if (this.buttonClicked.name === 'Save') {
                this.addImage()
            }
             if (this.buttonClicked.name === 'Cancel') {
                this.clearCanvas()
                this.setSelectedIcon('cancel')
                this.buttonList = this.buttonItems
            }
            if (this.buttonClicked.name === 'Delete') {
                this.showConfirmList = true
                // this.deleteImage()
                // this.clearCanvas()
            }
            if (this.buttonClicked.name === 'Close Markup') {
                window.removeEventListener('scroll',this.disableScroll,true)  
                
                this.setSelectedIcon('cancel')
                this.showMarkup = false;
                this.undoAllEdits()
                setTimeout(() => {
                    this.buttonList = this.buttonItems
                }, 100);            
                
            }
            if (this.buttonClicked.name === 'Share') {
                window.console.log('SEND CLIENT')
                this.showSuccess = false
                this.sendNote = ''
                this.showSendImage = true
            }
        },
        imageNote() {
            // window.console.log('IMAGE NOTE', this.imageNote, this.noteTimer)
            // if (this.noteTimer) {
            //     // window.console.log('CLEAR NOTE TIMER')
            //     clearInterval(this.noteTimer)
                
            // }
            // if (this.imageinfo.note !== this.imageNote) {
            //     this.startNoteTimer()
            // }
            
        }
    },
    
}
</script>

<style scoped>
.camera-display-container{
    position: relative;
    margin-bottom: 10px;
    background-color:white;
} 
#video {
    background-color: #000000;
    width: 100%;
    max-width: 640px;
    position: relative;
    border: 2px solid black;
    z-index: 1;
} 
#canvas {
    width: 100%;
    max-width: 640px;
    background-color: #000000;
    position: relative;
    border: 2px solid black;
    z-index: 1;
} 
.session-images-container{
    display: block;
    margin: 15px;
    border: 1px solid #cbcdcf;
}
.session-image{
    display: inline-block;
    padding: 5px;
    position: relative;
}
.camera-split-imge-container{
    position: relate;
    margin-bottom: 10px;
    top: 0;
    left: 0;
    background-color: lightgray;
    width: 100%;
    z-index: 0;
    padding-bottom: 15px;
}
.split-canvas{
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;    
}
.image-box-left{
    display: inline-block;
    border: 1px solid black;
    margin-top: 20px;
    margin-right: 1%;
}
.image-box-right{
    display: inline-block;
    border: 1px solid black;    
    margin-top: 20px;
    margin-right: 1%;
}
.image-icon-buttons{
    position: absolute;
    right: 0;
    top: 75px;
}
.selected {
    border: 2px solid red;
}
.split-note {
    margin-top: 10px;
    width: 90%;
    height: 30px;
    font-size: 15px;
    padding: 5px;
    text-align: center;
}
.capture-edit-panel{
    width: 100%;
    background-color: #dadada;
    padding: 8px 8px 8px 0;
}
.delete-image-confirm{
    position: absolute;
    background-color: white;
    color: black;
    width: 150px;
    left: -72px;
    top: -83px;
    z-index: 3;
    padding: 5px;
    border: 2px solid red;
    font-size: 14px;
}
.image-saved {
    position: absolute;
    background-color: #1c5eb5;
    color: #fff;
    width: 150px;
    left: -46px;
    top: -53px;
    z-index: 3;
    padding: 7px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 14px;
    font-weight: 600;
}
.split-saved {
    position: relative;
    left: -178px;
    top: -74px;
}
.edit-panel-buttons{
    vertical-align: top;
    display: inline-block;
    padding-top: 2px;
}
.edit-panel-icons{
    display: inline-block;
    margin-bottom: 5px;
    
}
.edit-button{
    width: 30px;
    height: 30px;
    background-color: #63b4c5;
    border-radius: 15px;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 8px;
    cursor: pointer;
    position: relative;
}
.edit-button:hover {
    background-color: #6d63c5;
}
.camera-button{
    /* float: right; */
    margin-right: 20px;
    margin-top: -2px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    background-color: #5a9dd9;
    position: relative;
    right: -25px;
}
.wide-edit {
    width: auto;
    padding: 0 15px;
    color: white;
    font-weight: 600;
    line-height: 30px;
}
/* .edit-button:hover{
    background-color: #4fa5b7;
} */
.fa-pen, .fa-circle, .fa-minus, .fa-plus, .fa-undo, .fa-redo, .fa-file, .fa-camera, .fa-trash{
   font-size: 15px;
    padding: 8px;
    color: white; 
}
.fa-circle {
    padding: 7.5px 8px 8px 7.5px;
}
.pen-black {
    color: black;
    font-size: 10px;
    padding: 0 8px;
}
.selected-icon{
    background-color: orange;
}
.color-red{
    background-color: red;
    position: relative;
}
.edit-note-title{
    display: inline-block;
    color: white;
    vertical-align: top;
    line-height: 28px;
    padding-right: 5px;
}
.edit-note{
    width: 80%;
    padding: 5px;
    margin-bottom: 5px;
}
.capture-button{
    width: 30px;
    height: 30px;
    border: 1px solid #366e84;
    border-radius: 15px;
    background-color: #7cc8ec;
    display: inline-block;
    z-index: 1;
    top: 9px;
    position: relative;
    cursor: pointer;
}
.capture-outer-ring{
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 25px;
    background-color: #ace5fb;
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.button-bar-inline{
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.button-bar{
    padding: 0;
}
.button-bar-right-group{
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: -2px;
}
.split-notice{
    position: absolute;
    text-align: center;
    width: 40%;
    padding-top: 20px;
}
.image-send-container{
    min-height: 200px;
    left: 20%;
    border: 1px solid black;
    z-index: 10;
    position: absolute;
    top: 20%;
    width: 60%;
    background-color: white;
}
.note-input{
    width: 200px;
    height: 70px;
    padding: 10px;
}
.image-send-title{
    background-color: #3a86d1;
    color: white;
    padding: 5px;
    margin: 5px;
}
.modal-container{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 12;
    top: 0;
}
.image-background-container{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
}
.image-date{
    text-align: center;
    font-size: 12px;
}
.imaging-container {
    background-color: white;
    max-width: 96%;
    margin: auto;
    min-height: 800px;
}
.image-button {
    padding: 10px 15px;
    color: white;
    background-color: #1a7281;
    font-size: 16px;
    cursor: pointer;
    margin: 5px 15px;

}
.plain {
    background-color: #f6f6f6;
    color: #1a7281;
    border: 1px solid #1a7281;
}
.split-button-row {
    border: 1px solid #1a4f81;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
}
.top-capture-button-container {
    display: block;
    position: relative;
    top: 0; 
    padding-bottom: 10px;
}
.no-float {
    float: none;
    margin-top: 10px;
}
.client-images-title-bar {
    background-color: #f2f3f3;
    padding: 5px;
}
@media screen and (max-width: 680px) {
    .capture-edit-panel{
        height: auto;
    }
    .edit-panel-buttons{
        height: 30px;
    }
}
</style>