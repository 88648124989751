<template>
    <div class="client-info-container">
        <div class="client-info-row">
            {{clientdata.firstname}} {{clientdata.lastname}}
        </div>
        <div class="client-info-row">
            Phone: {{clientdata.phone | formatPhone}}
        </div>
        <div class="client-info-row">
            Email: {{clientdata.email}} 
        </div>
        <div class="client-info-row">
            {{clientdata.address}} 
        </div>
        <div class="client-info-row">
            {{clientdata.address2}} 
        </div>
        <div class="client-info-row">
            {{clientdata.city}}, {{clientdata.state}} {{clientdata.zip}}
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showNotes=!showNotes">
                <i class="fas fa-caret-right" v-if="!showNotes"></i>
                <i class="fas fa-caret-down" v-if="showNotes"></i>
                Notes 
                <div class="item-count-container" >
                    {{notes.length}}
                </div>
            </div>
            <div v-if="showNotes">
                <div class="client-info-sub-item" 
                    v-for="(note, index) in notes"
                    :key = "index">
                    {{note.note}} - {{note.dateentered | shortDate}}
                </div>
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showBookings=!showBookings">
                <i class="fas fa-caret-right" v-if="!showBookings"></i>
                <i class="fas fa-caret-down" v-if="showBookings"></i>
                Bookings 
                <div class="item-count-container" >
                    {{bookings.length}}
                </div>
            </div>
            <div v-if="showBookings">
                <div class="client-info-sub-item" 
                    v-for="(book, index) in bookings"
                    :key = "index">
                    {{getSelectedDate(book)}} - {{book.starttime}} {{book.starttime | toAMPM}}- {{book.service}} - {{book.room}} - {{book.staff}}
                </div>
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showMedical=!showMedical">
                <i class="fas fa-caret-right" v-if="!showMedical"></i>
                <i class="fas fa-caret-down" v-if="showMedical"></i>
                Medical
                <div class="item-count-container" >
                    {{medicalInfo.length}}
                </div>
            </div>
            <div v-if="showMedical">
                <div class="client-info-sub-item" 
                    v-for="(info, index) in medicalInfo"
                    :key = "index">
                    {{info.id}}
                </div>
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showImages=!showImages">
                <i class="fas fa-caret-right" v-if="!showImages"></i>
                <i class="fas fa-caret-down" v-if="showImages"></i>
                Images 
                <div class="item-count-container" >
                    {{images.length}}
                </div>
            </div>
            <div v-if="showImages">
                <div class="client-info-sub-item" 
                    v-for="(image, index) in images"
                    :key = "index">
                    {{image.id}}
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Filters from '../../utilities/filters.js'
import Globals from '../../utilities/globals.js'
export default {
    name: 'ClientInfo',
    data: function() {
        return {
            bookings: [],
            notes: [],
            medicalInfo: [],
            images: [],
            showNotes: false,
            showBookings: false,
            showMedical: false,
            showImages: false,
        }
    },
    props: [
        'clientdata'
    ],
    methods: {
        getBookings() {
            window.console.log('BOOKINGS FOR CLIENT GET',this.clientdata.id, this.clientdata)
            this.$store.dispatch('getBookingsByClient',{id: this.clientdata.id}).then( response => {
                this.bookings = response
            })
        },
        getNotes() {
            this.$store.dispatch('getNotesClient',{id: this.clientdata.id}).then( response => {
                if(response.result == 'SUCCESS') {
                    this.notes = response.data
                } else {
                    this.notes = []
                }                
            })
        },
        getSelectedDate(book) {
            window.console.log('this day',this.bookings)
            return Globals.getSelectedDate(book.day, book.month, book.year)
        }
        
    },
    computed: {
        
    },
    filters: {
        Filters
    },
    mounted() {

    },
    watch: {
        clientdata: function() {
            this.getBookings()
            this.getNotes()
        }
    }
}

</script>

<style scoped>
.client-info-container{
    padding: 10px 0;
    margin: 0 5%;
}
.client-info-row{
    text-align: center;
}
.client-info-sub-item{
    text-align: left;
    border-top: 1px solid lightgray;
    padding: 5px 20px;
    font-size: 13px;
}
.client-info-sub-container{
    border: 1px solid lightgray;
}
.client-info-sub-title{
    background-color: #fafdfc;
    text-align: left;
    height: 20px;
    padding: 5px 10px;
    cursor: pointer;
    
}
.item-count-container{
    background-color: #2d9fe2;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    width: 30px;
    text-align: center;
    color: white;
    border-radius: 9px;
    line-height: 19px;
    vertical-align: top;
    margin: 2px 0 0 3px;
}
</style>
