<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{'Booking' | translate | allCaps }}</div>
        <div class="booking-info-row">{{bookingInfo.bookingdate | longDate}} {{bookingInfo.starttime}} {{bookingInfo.starttime | toAMPM}} </div>

        <client-info-update 
            v-bind:clientInfo="clientInfo"
        />

        
        <div class="form-item-result" v-if="bookingInfo.first">* {{'New Client' | translate }}</div>
        

        <div class="booking-info-row">{{bookingInfo.service}} : {{bookingInfo.room}} : {{bookingInfo.staff}}</div> 
        <div class="booking-info-row">Status: <span class="booking-status">{{bookingInfo.status | allCaps}}</span></div>
        <div class="booking-info-row note" v-if="!editNote">
            <div class="booking-info-note">
                {{bookingInfo.note}}
            </div>
            <div class="booking-info-icon">
                <div class="button-icon" @click="editNote=true"><i class="fas fa-pen"></i></div>
            </div>
        </div>
        <div class="booking-info-row note" v-if="editNote">
            <textarea class="booking-note-input" v-model="bookingInfo.note" />
            <div class="booking-info-icon">
                <div class="button-main" @click="saveNote">Save</div>
            </div>
        </div> 
        <ClientInfoPanel 
            v-bind:clientref = "clientRef"
            v-if="showClientInfoPanel"
            v-on:show-camera="showCamera = $event" 
            v-on:update-image="imageInfo = $event"
            v-on:preview-image="showImageEdit = true,takePicture = false"
        />
         <ButtonBar 
            :buttonlist="buttonList"
            v-on:button-click="buttonClicked = $event" >
        </ButtonBar>
        <div class="button-bar-right-group">
            <div class="edit-button" @click="checkIn" v-if="bookingInfo.status=='booked'" >
                <i class="fas fa-check"></i>
            </div>
            <div class="edit-button"  >
                <i class="fas fa-trash" @click="showConfirm = !showConfirm"></i>
                <div class="delete-booking-confirm" id="confirm-delete" v-if="showConfirm" >
                    {{'Are you sure you want to Delete this' | translate}} {{'Booking' | translate}}?                   
                    <input class="delete-reason" v-model="deleteReason" placeholder="Reason" />
                    <div class="button-main small-button" @click="deleteBooking">{{'Yes' | translate}}</div>
                    <div class="button-main small-button" @click="showConfirm = false" >{{'No' | translate}}</div>
                    
                </div>
            </div>
        </div>
        <div class="booking-image-edit-background" id="image-background-container"  v-if="showImageEdit"></div>
        <div class="booking-image-edit-container" id="image-editing-container" v-if="showImageEdit">
            <div class="camera-container-title-row">
                <div class="camera-app-title">{{'Picture Taking and Editing' | translate}}</div>
                <div class="title-client">{{ clientInfo.firstname }} {{ clientInfo.lastname }}</div>
                <div class="close-box" @click="showImageEdit = false">+</div>
            </div>
            <ImagePicture
                :bookinginfo="bookingInfo"
                :imageinfo="imageInfo"
                :clientid="bookingInfo.clientid"
                :takepicture="takePicture"
                v-on:close-edit="showImageEdit = $event,showCamera = $event"
            />
        </div>  
        
    </div>
</template>
<script>
import store from '../store/store.js'
import ClientInfoPanel from './client/ClientInfoPanel'
import ImagePicture from './utility/ImagePicture'
import ButtonBar from './utility/ButtonBar'
import EventBus from '../utilities/eventBus'
import ClientInfoUpdate from './client/ClientInfoUpdate'


export default {
    name: 'BookingInfo',
    data() {
        return {
            bookingInfo: {},
            store,
            editNote: false,
            clientRef: {},
            showClientInfoPanel: false,
            showClientContact: false,
            showCamera: false,
            showConfirm: false,
            showImageEdit: false,
            previewImage:{},
            imageInfo:{},
            closeImageEdit: '',
            buttonList: ['Back','Bill','Update'],
            buttonClicked: '',
            contactMethod: '',
            contactNote: '',
            deleteReason: '',
            clientContactInfo: {},
            clientInfo: {}
            
        }
    },
    components: {
        ClientInfoPanel,
        ImagePicture,
        ButtonBar,
        ClientInfoUpdate,
    },
    methods: {
        showClientInfo() {
        },
        saveNote() {
            this.$store.dispatch('updateNote', this.bookingInfo).then(response => {
                if (response.substr(0,7) === 'SUCCESS'){
                    this.editNote = false
                }
            })
        },
        getClientInfo() {
            if (this.bookingInfo.id) {
                this.clientRef = {
                    clientid: this.bookingInfo.clientid,
                    bookingid: this.bookingInfo.id,
                    clientname: this.bookingInfo.client.split(' ')[1]
                }
                this.$store.dispatch('getClientId',{id: this.bookingInfo.clientid}).then(response => {
                    this.clientInfo = response[0]
                    this.$store.commit('setSelectedClient',this.clientInfo)
                    this.clientRef.refname = this.clientInfo.refname
                    this.$store.commit('setClientInfo',this.clientInfo)
                })
                window.console.log('CLIENT INFO', this.clientRef)
                this.clientContactInfo.email = this.bookingInfo.clientemail
                this.clientContactInfo.phone = this.bookingInfo.clientphone
                this.showClientInfoPanel = true
            } else {
                this.$router.push('Schedule')
            }
        },
        checkIn() {
            if (this.bookingInfo.id) {
                this.$store.dispatch('checkInBooking',{id: this.bookingInfo.id}).then(response => {
                    if (response.substr(0,7) === 'SUCCESS'){
                        this.bookingInfo.status = 'confirmed'
                    }
                })
            } else {
                window.console.log('GO SCHEDULE')
                this.$router.push('Schedule')
            }
        },
        updateBooking() {
            this.$router.push('UpdateBooking')
        },
        gotoInvoice() {
            this.$router.push('Invoice')
        },
        deleteBooking() {
            window.console.log('DELETE THIS BOOKING', this.bookingInfo)
            const data = {
                id: this.bookingInfo.id,
                cancelInfo:{
                    date: new Date(),
                    by: this.bookingInfo.staffid,
                    reason: this.deleteReason
                }
            }
            this.$store.dispatch('cancelBooking',data).then(response => {
                window.console.log('CANCEL BOOKING RESPONSE', response)
                if(response.result === 'SUCCESS') {
                    this.$router.push('Schedule')
                }
            })
        },
        

    },
    mounted() {
        EventBus.$on('CHANGE_LANGUAGE',() => {
            this.$forceUpdate()            
        })
        this.bookingInfo = this.$store.getters.bookingInfo
        this.getClientInfo()
    },
    computed: {
        
    },
    watch: {
        showCamera() {
            window.console.log('SHOW CAMERA CHANGE', this.showCamera)
            // this.$emit('show-camera-app', true)
            if (this.showCamera) {
                this.takePicture = true
                this.showImageEdit = true
            }
            
        },
        showImageEdit() {
            if (this.showImageEdit === false) {
                this.showCamera = false
            } else {
                setTimeout(() => {
                    
                
                    document.getElementById('image-background-container').style.height = (document.getElementById('image-editing-container').clientHeight + 155) + 'px'
                    window.console.log('HIEHG',document.getElementById('image-background-container').clientHeight)
                }, 2000);
            }
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Update') {
                this.updateBooking()
            }
            if (this.buttonClicked.name === 'Back') {
                this.$router.back()
            }
            if (this.buttonClicked.name === 'Bill') {
                this.gotoInvoice()
            }
        },
    },
}
</script>
<style scoped>
.form-item-result {
    font-weight: 600;
    color: #178d9e;
    font-size: 16px;
    padding-bottom: 5px;
}
.bold {
    font-weight: 600;
}

.contact-icon{
    margin-top: 3px;
    font-size: 12px;
}
.contact-container{
    position: absolute;
    top: 100px;
    left: 25%;
    width: 50%;
    height: 150px;
    background-color: white;
    border: 1px solid black;
}
.contact-note{
    width: 200px;
    height: 60px;
}
.contact-header{
    background-color: #5e0794;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
}

.edit-button{
    width: 30px;
    height: 30px;
    background-color: #63b4c5;
    border-radius: 15px;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 8px;
    cursor: pointer;
    position: relative;
}
.fa-check, .fa-trash{
   font-size: 15px;
    padding: 8px;
    color: white; 
}
.booking-status{
    font-size: 13px;
    font-weight: 700;
    color: #9322d8;
}
.close-box {
    position: absolute;
    right: 5px;
    top: 8px;
}
.title-client {
    text-align: center;
    font-size: 20px;
    padding-left: 10%;
}

</style>