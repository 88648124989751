<template>
    <div class="report-detail-container">
        <div class="report-item-detail-row title-row" >
            <div class="report-table-detail-item small-width"></div>
            <div class="report-table-detail-item small-width">{{'id' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Booked' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Paid' | translate}}</div>
            <div class="report-table-detail-item large-width">{{'Client' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Prod/Serv' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Paid' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Credit' | translate}}</div>
            <div class="report-table-detail-item small-width">{{'Pay Type' | translate}}</div>
        </div>
        <div class="report-item-detail-row" >
            <div class="report-table-detail-item small-width is-clickable" @click="showDetail(invoice.id)">
                {{'Invoice' | translate}}
                <i class="fas fa-caret-down"></i>
            </div>
            <div class="report-table-detail-item small-width">{{invoice.id}}</div>
            <div class="report-table-detail-item small-width">{{invoice.bookingdate | shortDate}}</div>
            <div class="report-table-detail-item small-width">{{invoice.datepaid | shortDate}}</div>
            <div class="report-table-detail-item large-width">{{invoice.client}}</div>
            <div class="report-table-detail-item small-width">{{invoice.productservicetotal | toUSD}}</div>
            <div class="report-table-detail-item small-width">{{Number(invoice.amountpaid) | toUSD}}</div>
            <div class="report-table-detail-item small-width">{{Number(invoice.creditapplied) | toUSD}}</div>
            <div class="report-table-detail-item small-width">{{invoice.paytype | translate}}</div>
            
            <!-- <div class="report-table-detail-item">{{invoice.paytype}}</div>  -->
        </div>
        <ReportsInvoiceItemsDetail 
            :invoiceItemsStaff="selectedInvoiceItems" 
            :appliedCreditItems="invoice.appliedcredititems"
            :invoice="invoice"
            v-if="showInvoiceItems" />
        
    </div>
</template>
<script>
import ReportsInvoiceItemsDetail from './ReportsInvoiceItemsDetail'
export default {
    name: 'ReportsInvoiceDetail',
    data() {
        return {
            showInvoiceItems: false,
            selectedInvoiceItems: [],
        }
    },
    props: ['invoice', 'allInvoiceItems'],
    components: {
        ReportsInvoiceItemsDetail,
    },
    methods: {
        showDetail(id) {
            if (this.showInvoiceItems) {
                this.showInvoiceItems = false
            } else {
                this.selectedInvoiceItems = this.allInvoiceItems.filter(item => item.invoiceid == id)
                this.showInvoiceItems = true
            }

            window.console.log('EMIT SHOW DETAIL',this.invoicesStaff, this.selectedInvoiceItems,'INVOICE', this.invoice)
            
            
        }
    },
    mounted() {
        window.console.log('INVOICE DETAIL', this.invoice, this.allInvoiceItems)
    },
    watch: {
        invoice() {
            window.console.log('INVOICE UPDATED', this.invoice)
        }
    },
}
</script>
<style scoped>
.report-detail-container{
    /* border: 1px solid black; */
    background-color: white;
}
.report-item-detail-row{
    border-top: 1px solid #aac1ce;
    min-height: 20px;
    background-color: #d0e6f3
}
.report-table-detail-item{
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    height: 20px;
    width: 12%;
    border-right: 1px solid #aac1ce;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 5px;
}
.report-table-detail-item:last-child{
    border-right: none;
}
.small-width{
    width: 8%;
}
.large-width{
    width: 18%;
}
.is-clickable{
    cursor: pointer;
}
.title-row{
    height: 12px;
    color: white;
    background-color: #93b9d0;
    font-weight: 600;
    line-height: 12px;
    font-size: 10px;
}
</style>