<template>
    <div class="main-input-form-container min-width" ref="main-container">
        <div class="invoice-form-content">
            <div class="form-title-row">{{'Invoice' | translate | allCaps}}</div>          
            <div class="form-input-row">
                <label class="form-input-label" >{{'Invoice' | translate}}# </label>
                <div class="form-content-item">{{invoiceInfo.id}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Name' | translate}} </label>
                <div class="form-content-item">{{invoiceInfo.client | allCaps}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Booking Date' | translate}} </label>
                <div class="form-content-item">{{invoiceInfo.bookingdate | longDate}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Date Paid' | translate}} </label>
                <div class="form-content-item">{{invoiceInfo.datepaid | longDate}}</div>
            </div>
            <div class="form-input-row">
                <label class="form-input-label" >{{'Provider' | translate}} </label>
                <div class="form-content-item">{{invoiceInfo.staff}}</div>
            </div>
            

            <ItemInfoTable 
                v-if="invoiceProducts.length > 0"
                v-bind:infotableItems="invoiceProducts"
                v-bind:isPaid="preventUpdate"
                v-on:table-total-updated="invoiceTotalsData = $event"
            />
            <div v-for="(promotion) in clientPromotions" v-bind:key="promotion.id" >                
                <div v-for="(promoItemsItem) in promotion.items" v-bind:key="promoItemsItem.id">
                    <div class="promo-credit-row" v-if="showClientPromotions(promoItemsItem)">
                        <div class="promo-credit-item blue-back">{{promotion.name}}</div>
                        <div class="promo-credit-item">{{promoItemsItem.name}}</div>
                        <div class="promo-credit-item">{{getCreditValue(promoItemsItem,promotion)}}</div>
                    </div>
                    <div class="button-main small-button" @click="applyCredit(promotion,promoItemsItem)" v-if="showApplyCreditButton(promoItemsItem,promotion)">Apply credit</div>
                </div>
            </div>           
        </div>
        <div class="tax-info" >
            {{'Sales Tax' | translate}}({{'Services' | translate}}): {{salesTaxService}}% - {{salesTaxServiceAmt | toUSD}} |

            {{'Sales Tax' | translate}}({{'Products' | translate}}): {{salesTaxProducts}}% - {{salesTaxProductsAmt | toUSD}}
        </div >
        <div class="form-input-row">
            <label class="form-input-label">{{'Total' | translate }} {{'Products' | translate }}/{{'Services' | translate }}:</label> 
             <div class="form-content-amount" v-if="isPaid" >{{this.invoiceInfo.productservicetotal | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label">{{'Taxes' | translate }}:</label> 
             <div class="form-content-amount" v-if="isPaid" >{{(Number(this.invoiceInfo.taxproduct) + Number(this.invoiceInfo.taxservice)) | toUSD}}</div>
        </div>
        <div class="form-input-row ">
            <label class="form-input-label">{{'Tip' | translate }}:</label> 
            <InputCurrency
                v-if="!isPaid"
                v-on:amount-entered="tipAmount = $event"
                :amount="existingTip"
             ></InputCurrency>
             <div class="form-content-amount bottom-border" v-if="isPaid" >{{this.invoiceInfo.tip | toUSD}}</div>
        </div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Total' | translate}} </label>
            <div class="form-content-amount">{{(Number(this.invoiceInfo.productservicetotal) + Number(this.invoiceInfo.tip) + Number(this.invoiceInfo.taxproduct) + Number(this.invoiceInfo.taxservice)) | toUSD}}
                <!-- <div class="credit-applied"  v-if="creditApplied">{{'Credit Applied' | translate}} ({{totalApplied | toUSD}})</div> -->
            </div>
        </div >
        <div class="form-credit-row" v-if="creditApplied">
            <label class="form-input-label" >{{'Credit Applied' | translate}} </label>
            <div class="credit-applied">- {{totalApplied | toUSD}}</div> 
            <credit-item 
                :creditData="creditData"
            />              
        </div >       
        <div class="form-input-row">
            <label class="form-input-label" >{{'Amount Paid' | translate}}</label>
            <!-- <input id="paid" v-model="formattedPaidValue" v-on:blur="setToUSD" v-on:focus="setToNum" v-if="!isPaid"/> -->
            <InputCurrency
                v-if="!isPaid"
                v-on:amount-entered="formattedPaidValue = $event"

                :amount="existingPaid"
             ></InputCurrency>
            <div class="form-content-amount" v-if="isPaid" >{{this.invoiceInfo.amountpaid | toUSD}}</div>
            
            <div class="paid-full" v-if="paidInFull">
                <i class="fas fa-check-square"></i>
            </div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && !Number(this.formattedPaidValue)">*{{'You need to enter a' | translate}} {{'Amount Paid' | translate}}</div>
        <div class="form-input-row">
            <label class="form-input-label" >{{'Payment Type' | translate}}</label>
            <Dropdown  
                v-if="!isPaid"
                v-on:item-selected="paymentType = $event" 
                v-bind:selected="paymentType.name" 
                v-bind:optioncontent="paymentTypes"
                :placeholder="existingPaymentType"/>
                <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.paytype}}</div>
        </div>
        <div class="form-input-row" v-if="paymentType.name === 'Check'">
            <label class="form-input-label" >{{'Check Number' | translate }}</label>
            <input v-model="checknumber" v-if="!isPaid" />
            <div class="form-content-item" v-if="isPaid" >{{this.invoiceInfo.checknumber}}</div>
        </div>
        <div class='missing-selection-message' v-if="showErrors && (!paymentType.id && paymentType.name !== 'Promotion Credit')">*{{'You need to enter a' | translate}} {{'Payment Type' | translate}}</div>

        <!-- SEARCH FOR ADDITIONAL ITEMS -->
        <div v-if="!preventUpdate">
            <div class="form-search-row"> 
                <div class="search-ahead-label">
                    {{'Add' | translate }} 
                </div>
                <div class="search-ahead-item">
                    <SearchAhead 
                        v-show="showProductSearch"
                        v-bind:searchtype="addSearch.type" 
                        v-bind:searchcall="addSearch.dbcall" 
                        v-bind:resultfields="addSearch.resultfields"
                        v-on:item-selected="selectedProduct = $event"
                        :clearterm="true" >
                    </SearchAhead>
                </div>
                <div class="search-selection-item">
                    <SelectionSwitch  
                        :switches="['PRODUCTS','SERVICES','PROMOTIONS']"
                        v-on:item-selected="selectedSearchType = $event"
                        :selectedSwitch= "'PRODUCTS'"
                        >
                    </SelectionSwitch>
                </div>
            </div>
        </div>

        <!-- PROCESS BUTTONS -->
        <div class="button-confirm-container">
            <div class="submit-confirm" id="confirm-delete" v-if="showConfirm" >
                {{'Are you sure you want to submit without and payment' | translate}}?
                <div>
                    <div class="button-main small-button" @click="submitInvoice">{{'Yes' | translate}}</div>
                    <div class="button-main small-button" @click="showConfirm = false" >{{'No' | translate}}</div>
                </div>
            </div >
            <ButtonBar 
                :buttonlist="buttonList"
                v-on:button-click="buttonClicked = $event" >
            </ButtonBar>
            <div class="form-input-row" v-show="showSuccess">
                <div class="success-label">{{'Invoice Successfully Submitted' | translate}}</div>
            </div>  
        </div>
        
        
        
    </div>
</template>

<script>
import SearchAhead from '../utility/SearchAhead'
import Filters from '../../utilities/filters'
import ItemInfoTable from '../utility/ItemInfoTable'
// import EventBus from '../utilities/eventBus.js'
import Globals from '../../utilities/globals.js'
import Dropdown from '../utility/Dropdown'
import Constants from '../../utilities/constants'
import ButtonBar from '../utility/ButtonBar'
import SelectionSwitch from '../utility/SelectionSwitch'
import InputCurrency from '../utility/InputCurrency'
import CreditItem from '../utility/CreditItem'

export default {
    name: 'InvoiceId',
    data: function() {
        return {
            selectedProduct: {},
            selectedSearchType: '',
            showProductSearch: true,
            showErrors: false,
            invoiceProducts:[],
            invoiceTotal: 0,
            payAmount: 0,
            invoiceInfo: {id: ''},
            updateTotal: 0,
            bookingInfo: {},
            itemRemove: {},
            amountPaid: 0,
            datePaid: '',
            paymentForm: '',
            paymentType: {},
            isPaid: false,
            paidInFull: false,
            updatedItem: {},
            showSuccess: false,
            showConfirm: false,
            preventUpdate: false,
            formattedPaidValue: '$0.00',
            invoiceTotalsData: {total:'',retail:''},
            paymentTypes: Constants.paymentTypes,
            allPromotions: [],
            allServices: [],
            selectedPromotion: {},
            selectedService: {},
            clientPromotions: [],
            creditApplied: false,
            creditData: [],
            appliedRef: [],
            maxApplied: false,
            totalApplied: 0,
            tipAmount: 0,
            checknumber: '',
            existingTip: '',
            existingPaid: '',
            existingPaymentType: 'Select Payment Type',
            salesTaxProducts: this.$store.getters.currentLocation.taxproducts,
            salesTaxProductsAmt: 0,
            salesTaxService: this.$store.getters.currentLocation.taxservices,
            salesTaxServiceAmt: 0,
            appliedCreditList: [],
            buttonList: ['Submit', 'Cancel'],
            buttonClicked: '',
            productSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            },
            serviceSearch: {
                dbcall: 'getServiceSearch',
                type: 'Services',
                resultfields: ['name']
            },
            promotionSearch: {
                dbcall: 'getPromotionSearch',
                type: 'Promotions',
                resultfields: ['name']
            },
            addSearch: {
                dbcall: 'getProductsSearch',
                type: 'Products',
                resultfields: ['company','name']
            } 
        }
    },
    components: {
        SearchAhead,
        ItemInfoTable,
        Dropdown,
        ButtonBar,
        SelectionSwitch,
        InputCurrency,
        CreditItem
    },
    methods: {
        addProduct() {
            this.selectedProduct.discount = 0;
            this.selectedProduct.quantity = 1;
            this.invoiceProducts.push(this.selectedProduct)
            window.console.log('ADD PROD', this.invoiceProducts, this.selectedProduct)
            this.calcInvoiceTotal()
        },
        calcPrice(product) {
            return product.retailprice - (product.reatailprice * product.discount/100)
        },
        calcTotal(product) {
            return product.retailprice - (product.reatailprice * product.discount/100) * product.quantity
        },
        calcInvoiceTotal: function() {
            let vm = this;
            let invTotal = 0
            this.salesTaxProductsAmt = 0
            this.salesTaxServiceAmt = 0
            this.productServiceTotal = 0
            this.invoiceProducts.forEach(item => {  
                let tax = 0
                if (item.category === 'service') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxservices)/100
                    this.salesTaxServiceAmt += tax
                } 
                if (item.category === 'product') {
                    tax = item.total * Number(vm.$store.getters.currentLocation.taxproducts)/100
                    this.salesTaxProductsAmt += tax
                }  
                this.productServiceTotal += item.total         
                invTotal += item.total  + tax             
            })
            
            if (this.bookingInfo.status !== 'paid-in-full') {
                vm.invoiceTotal = invTotal.toFixed(2)
                vm.payAmount = vm.invoiceTotal  - this.totalApplied + this.tipAmount
            }
        },
        getBookingInfo: function() {
            this.bookingInfo = this.$store.getters.bookingInfo
            this.bookingInfo.status = this.bookingInfo.status ? this.bookingInfo.status : 'booked'
            window.console.log('BOOKING INFO', this.bookingInfo)
            this.checkIfInvoiceExists()
            
        },
        checkIfInvoiceExists(id) {
             window.console.log('CHK INVOICE EXIST', this.bookingInfo)
            this.$store.dispatch('checkForInvoice',{id: id}).then( response => {
                window.console.log('DOES INVOICE EXIST', response.length, response)
                this.setupInvoice(response)
            })
        },
        setupInvoice(data) {
            window.console.log('SETUP INVOICE FROM DATA', data, data.length)

            if (!data.id && !data.length ) {
                window.console.log('NO DATA LENGTH')
                this.$store.dispatch('getServiceId',{id: this.bookingInfo.serviceid}).then(response => {
                    this.selectedProduct = response[0]
                    this.selectedProduct.discount = 0
                    this.selectedProduct.quantity = 1
                    this.selectedProduct.retailprice = Number(response[0].retailprice)
                })
            } else {
               
                this.invoiceInfo = data[data.length - 1]
                window.console.log('MULTI DATA LENGTH', data.length, data[0], this.invoiceInfo)
                this.bookingInfo.status = this.invoiceInfo.status
                window.console.log('STATUS',this.bookingInfo.status,this.invoiceInfo.status)
                if (this.invoiceInfo.status === "paid" || this.invoiceInfo.status === "paid-in-full" || this.invoiceInfo.amountPaid > 0) {
                    this.isPaid = true;
                    this.buttonList = ['Adjust','Print','Cancel']
                }
                this.invoiceTotal = Globals.roundPrice(this.invoiceInfo.invoicetotal)
                this.payAmount = this.invoiceTotal
                this.invoiceInfo.invoiceitems.forEach( item => {
                    this.invoiceProducts.push(item)
                })
                this.amountPaid = this.invoiceInfo.amountpaid
                window.console.log('PT', this.invoiceInfo.paytype)

                this.paymentTypes.forEach(type => {
                    if (type.name === this.invoiceInfo.paytype) {
                        this.paymentType = type
                    }
                })
                if (this.invoiceInfo.appliedcredititems.length) {
                    this.creditApplied = true
                    this.invoiceInfo.appliedcredititems.forEach(credititem => {
                        this.creditData.push(credititem)
                        this.totalApplied += Number(credititem.AMTAPPLIED)
                    })
                    window.console.log('CREDIT APPLIED', this.creditApplied, this.totalApplied)
                }
                this.preventUpdate = true
            }
            
        },
        removeItem(index) {
            this.invoiceProducts.splice(index,1)
        },
        compileSubmitData() {
            if ( Number(this.amountPaid).toFixed(2) === Number(this.payAmount).toFixed(2)) {
                this.bookingInfo.status = 'paid-in-full'
            } else {
                this.bookingInfo.status = 'paid'
            }
            this.datePaid = new Date()
            this.addCreditDetailItems()
            const data = {
                invoiceitems: JSON.stringify(this.invoiceProducts),
                bookingid: this.bookingInfo.id,
                client: this.bookingInfo.client,
                clientid: this.bookingInfo.clientid,
                staff: this.bookingInfo.staff,
                staffid: this.bookingInfo.staffid,
                bookingdate: this.bookingInfo.bookingdate,
                invoicetotal: this.invoiceTotal ,
                amountpaid: Number(this.formattedPaidValue),
                paytype: this.paymentType.name,
                datepaid: this.datePaid,
                status: this.bookingInfo.status,
                promotionid: this.bookingInfo.promotionid,
                creditid: this.bookingInfo.creditid,
                creditapplied: this.bookingInfo.creditapplied,
                credittype: this.bookingInfo.credittype,
                checknumber: this.checknumber,
                clientpromotions: this.clientPromotions,
                appliedcredititems: this.appliedCreditList,
                tip: this.tipAmount,
                taxservice: Math.round(this.salesTaxServiceAmt * 100)/100,
                taxproduct: Math.round(this.salesTaxProductsAmt * 100)/100,
                productservicetotal: this.productServiceTotal,
            }
            return data
        },
        submitInvoice() {
            const data = this.compileSubmitData()
            window.console.log('INVOICE PRODUCTS', this.invoiceProducts )
            window.console.log('SAVE', data, this.bookingInfo, data.clientPromotions )
            window.console.log('DATA', data, 'PROMOTIONS', data.clientPromotions)
            this.$store.dispatch('addInvoice', data).then(response => {
                window.console.log('RESUTS ADD INVOICE', response)
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Created'
                    this.showSuccess = true                
                    setTimeout(() => {
                        this.$router.push('Schedule')
                    }, Constants.waitTime);
                }
            })
        },
        updateInvoice() {
            let data = this.compileSubmitData()
            data.id = this.invoiceInfo.id
            window.console.log('update', data)
            this.$store.dispatch('updateInvoice',data).then(response => {
                window.console.log('RESULTS UPDATE INVOICE', response)
                if (response.result == 'SUCCESS'){
                    this.successMessage = 'Successfully Created'
                    this.showSuccess = true                
                    setTimeout(() => {
                        this.$router.push('Schedule')
                    }, Constants.waitTime);
                }
            })
        },
        addCreditDetailItems() {
            this.invoiceProducts.forEach(item => {
                this.appliedCreditList.forEach(credit => {
                    if (item.name == credit.appliedto) {
                        window.console.log('APPLIED LIST',item.name,credit.appliedto, credit,credit.value)
                        item.creditamount = item.creditamount ? item.creditamount : 0
                        item.creditamount += Globals.roundPrice(Number(credit.value))
                        item.credittype = credit.category
                        item.creditid = credit.id
                        item.promotionname = credit.name
                    }
                })
                if(item.category === 'product') {
                    item.refid = item.id
                }
                if(item.category === 'service' && item.trackinventory) {
                    item.refid = item.supplyid
                }
            })
        },
        checkIn(){
            this.$store.dispatch('checkInBooking',{id: this.bookingInfo.id} ).then( response => {
                if (response.substr(0,7) === 'SUCCESS') {
                    this.bookingInfo.status = 'confirmed'
                }
            })
        },
        gotoPrevious() {
            this.$router.push('Schedule')
        },
        adjustInvoice() {
            window.console.log('ADJUST INVOICE',this.invoiceInfo, this.invoiceInfo.paytype )
            this.title = 'Update Invoice'
            this.buttonList=['Update','Cancel']
            this.isPaid = false;
            this.existingTip = this.invoiceInfo.tip
            this.tipAmount = Number(this.invoiceInfo.tip)
            this.existingPaid = this.invoiceInfo.amountpaid
            this.formattedPaidValue = this.invoiceInfo.amountpaid
            this.checknumber = this.invoiceInfo.checknumber
            this.existingPaymentType = this.invoiceInfo.paytype
            window.console.log('ADJUST INVOICE',this.paymentType)
            
        },
        setToUSD() {
            this.amountPaid = Number(this.formattedPaidValue.replace(/[^0-9.-]+/g,""))
            if (isNaN(this.amountPaid)) {
                this.amountPaid = ''
            }
            this.formattedPaidValue = "$ " + this.amountPaid.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        },
        setToNum() {
            this.formattedPaidValue = this.amountPaid.toString()
        },
        getServices() {
            this.$store.dispatch('getServices').then(response => {
                this.allServices = response
            })
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                this.allPromotions = response
            })
        },
        getClientPromotions() {
            this.$store.dispatch('getClientPromotions',{id:this.bookingInfo.clientid}).then(response => {
                window.console.log('CLIENT PROMO EACH RESPONSE', response, response.length, typeof response)
                if (response.result == 'SUCCESS') {
                    this.clientPromotions = response.data
                    this.clientPromotions.forEach(item => {
                        window.console.log('EACH ITEM', item)
                        item.items = !item.items ? [] : item.items
                        item.items.forEach(subitem => {
                            window.console.log('CLIENT PROMO EACH', item, subitem)
                            subitem.valueUsed = 0
                            subitem.quantityUsed = 0
                        })
                    })
                }
            })
        },
        showClientPromotions(promo) {
            window.console.log('SHOW PROMO',promo.quantity)
            if (promo.quantity > 0 ){ //&& this.invoiceInfo.status !== 'paid-in-full') {
                return true
            }
            return false
        },
        getTotalServices() {
            let totalServices = 0
            this.invoiceProducts.forEach(item => { 
                if (item.category === 'service') {
                    totalServices += item.total
                }               
            })
            return totalServices
        },
        applyCredit(promotion,promoItemsItem) {
            // item, creditItem
            window.console.log('APPLY', promoItemsItem.category, promoItemsItem.applyto,'ITEM',promotion.applyto)

            
            for (let i = 0;i<this.invoiceProducts.length;i++) {
                const product = this.invoiceProducts[i]
                product.creditQuantity = product.creditQuantity ? product.creditQuantity : product.quantity
                //FOR EACH INVOICE ITEM CHECK IF CREDIT APPLIES
                //IF ITEM IS SERVICE AND CREDIT IS SAME SERVICE 
                if ((product.category === 'service'  && (promoItemsItem.category === 'service')) ||  (product.category === 'product'  && (promoItemsItem.category === 'product'))) {
                    this.applySpecificCredit(product,promoItemsItem,promotion)
                }
                if (promoItemsItem.category === 'credit') {
                    this.applyCreditForItem(product,promoItemsItem,promotion)
                }
                
                this.bookingInfo.promotionid = promotion.itemid
                this.bookingInfo.creditid = promotion.id
                this.bookingInfo.credittype = promoItemsItem.category
            }
        },
        applySpecificCredit(product,promoItemsItem,promotion) {
            window.console.log('APPLY CREDIT SERVICE', product, 'ITEMS',promoItemsItem, promoItemsItem.quantity,'PROMO',promotion,promotion.quantity)
            if (product.id === promoItemsItem.id && product.name === promoItemsItem.name) {
                 window.console.log('MEET IF CRITERIA')
                let appliedCreditItem = {
                    id: promotion.id,
                    name: promotion.name,
                    appliedto: product.name,
                    category: promoItemsItem.category
                }
                let quantityPreviouslyUsed = 0
                promoItemsItem.appliedToInvoices.forEach(prevInvoice => {
                    quantityPreviouslyUsed += Globals.roundPrice(Number(prevInvoice.usedquantity))
                    window.console.log('EACHxxxx', prevInvoice, prevInvoice.usedquantity,quantityPreviouslyUsed)
                })
                // promoItemsItem.quantity = (Number(promotion.quantity) * Number(promoItemsItem.quantity)) - quantityPreviouslyUsed
            //If have more credits than new service used
                if (promoItemsItem.quantity >= product.creditQuantity) {
                     window.console.log('HAVE MORE THAN', promoItemsItem.quantity,product.creditQuantity)
                    promoItemsItem.quantity -= product.creditQuantity 
                    appliedCreditItem.quantity = product.creditQuantity,
                    appliedCreditItem.itemquantity = product.creditQuantity,
                    product.total = Globals.roundPrice(product.total)
                    appliedCreditItem.value = product.total,                       
                    this.payAmount -= (product.total )
                    this.totalApplied += (product.total)
                    promoItemsItem.quantityUsed += product.creditQuantity
                    promoItemsItem.valueUsed += product.total   
                    this.creditApplied = true                        
                    // product.creditQuantity = 0                       
                    this.bookingInfo.creditapplied = Globals.roundPrice(this.totalApplied)
                    window.console.log('AFTER APPLY',promoItemsItem.quantityUsed, promoItemsItem.quantity)
                } else {
                    // If have less credits than current sale
                    window.console.log('HAVE LESS THAN',promoItemsItem.quantity,'PROD QTY',product.creditQuantity,'SALE PRICE',product.saleprice)
                    const totalToApply = promoItemsItem.quantity * product.saleprice                     
                    this.payAmount -= totalToApply
                    this.totalApplied += totalToApply
                    appliedCreditItem.quantity = Globals.roundPrice(promoItemsItem.quantity)
                    appliedCreditItem.itemquantity = Globals.roundPrice(promoItemsItem.quantity)
                    appliedCreditItem.value = Globals.roundPrice(totalToApply) 
                    promoItemsItem.quantityUsed += promoItemsItem.quantity
                    promoItemsItem.valueUsed += totalToApply    
                    this.creditApplied = true                        
                    product.creditQuantity -= promoItemsItem.quantity
                    promoItemsItem.quantity = 0                        
                    this.bookingInfo.creditapplied = this.totalApplied   
                }
                this.paymentType.name = Globals.translate('Promotion Credit')
                this.appliedCreditList.push(appliedCreditItem)               
            }
        },
        applyCreditForItem(product,promoItemsItem,promotion) {
            // item,creditItem,promo
            window.console.log('APPLY PROMOTION CREDIT', product, promoItemsItem,promoItemsItem.quantity, product.category, promoItemsItem.applyto, promoItemsItem.applyto[`${product.category}s`])
            if (promoItemsItem.applyto[`${product.category}s`]) {
                window.console.log('APPLY $CREDITS FOR ', product.category, product.name, product.creditQuantity,this.appliedCreditList)
                let appliedQuantity = 0
                this.appliedCreditList.forEach(credit => {
                    window.console.log('CHECK APPLIED CREDIT', credit, credit.name, product.name,product.creditQuantity)
                    if (credit.appliedto == product.name) {
                        appliedQuantity += credit.quantity
                    }
                })
                const totalItemBalance = Globals.roundPrice(product.creditQuantity   * product.saleprice)
                window.console.log('total item balance', totalItemBalance,product.creditQuantity,appliedQuantity)
                let appliedCreditItem = {
                    id: promotion.id,
                    name: promotion.name,
                    appliedto: product.name,
                    category: promoItemsItem.category
                }
                // window.console.log('TOT SERVICE', product.total, balanceToApply)
                
                    // If have more credits than services used apply all credits needed and subtract total for product
                if (promoItemsItem.quantity > totalItemBalance) {
                    window.console.log('HAVE MORE CREDITS THAN BALANCE',promoItemsItem.quantity,totalItemBalance)
                    this.payAmount -= totalItemBalance
                    this.totalApplied += totalItemBalance
                    appliedCreditItem.quantity = totalItemBalance
                    appliedCreditItem.value = totalItemBalance 
                    appliedCreditItem.itemquantity = product.creditQuantity
                    promoItemsItem.quantity -= totalItemBalance
                    promoItemsItem.quantityUsed += totalItemBalance
                    promoItemsItem.valueUsed += totalItemBalance
                    this.bookingInfo.creditapplied = Globals.roundPrice(this.totalApplied)
                    this.creditApplied = true  
                    window.console.log('USED TOT CREDITS',promoItemsItem, totalItemBalance, Math.floor(promoItemsItem.quantity/product.saleprice))
                } else {
                    // if do not have enough credits for all used then subtract only as have and calc price reduction
                    window.console.log('HAVE LESS CREDITS THAN BALANCE',promoItemsItem.quantity,totalItemBalance)
                    promoItemsItem.quantity = Globals.roundPrice(promoItemsItem.quantity)
                    this.payAmount -= promoItemsItem.quantity
                    this.totalApplied += promoItemsItem.quantity
                    promoItemsItem.quantityUsed += promoItemsItem.quantity
                    promoItemsItem.valueUsed += promoItemsItem.quantity
                    appliedCreditItem.quantity = promoItemsItem.quantity
                    appliedCreditItem.value = promoItemsItem.quantity
                    appliedCreditItem.itemquantity = promoItemsItem.quantity/product.saleprice                   
                    this.bookingInfo.creditapplied = Globals.roundPrice(this.totalApplied)
                    promoItemsItem.quantity = 0                        
                    this.creditApplied = true  
                    window.console.log('USED REMAINING CREDITS', promoItemsItem.quantity)
                }
                this.appliedCreditList.push(appliedCreditItem)
                
                
            }
        },
        showApplyCreditButton(promoItemsItem,promotion) {
            if (promoItemsItem.quantityUsed > 0 || this.getCreditValue(promoItemsItem,promotion) === 0 || this.invoiceAmount === 0 || this.invoiceInfo.status === 'paid-in-full') {
                return false
            }
            return true
        },
        getCreditValue(promoItemsItem,promotion){
            // let valueUsed = 0
            promoItemsItem.appliedToInvoices = !promoItemsItem.appliedToInvoices ? [] : promoItemsItem.appliedToInvoices
            // promoItemsItem.appliedToInvoices.forEach(prevInvoice => {
            //     valueUsed += Globals.roundPrice(Number(prevInvoice.usedquantity))
            // })
            // item,ref
            let val = 0
            promoItemsItem.quantityUsed = !promoItemsItem.quantityUsed ? 0 : promoItemsItem.quantityUsed
            if (promotion && promotion.name){                
                 val = Number(promoItemsItem.quantity) // - Number(valueUsed)- Number(promoItemsItem.quantityUsed)
            }           
            if (promoItemsItem.category === 'credit') {
                val = Globals.toUSD(val)
            }
            return val
        },
        verifyInvoice() {
            this.showErrors = false

            this.payAmount = Globals.roundPrice(this.payAmount)
            if (Number(this.formattedPaidValue) && !this.paymentType){ 
                window.console.log('INFO MISSING type',this.paytype,'paid',this.formattedPaidValue, Number(this.formattedPaidValue),Number(this.payAmount) ,Number(this.tipAmount),(Number(this.payAmount) + Number(this.tipAmount)))
                this.showErrors = true
            } else {
                if (!Number(this.formattedPaidValue)) {
                    this.showConfirm = true;
                    return
                }
                window.console.log('NO ERRORS SUBMIT')
                this.submitInvoice()
            }           
        },
        getInvoiceData(id) {
            this.$store.dispatch('getInvoiceId',{id:id}).then(response => {
                this.setupInvoice(response)
            })
        }
    },
    computed: {
        getDiscountPrice(item){
            return this.calcPrice(item)
        },
        getDateInfo(){
            const bookDate = Globals.getSelectedDate(this.bookingInfo.day,this.bookingInfo.month, this.bookingInfo.year)
            return bookDate + ' at ' + this.bookingInfo.starttime +  Globals.getAMPM(this.bookingInfo.starttime)
        }
    },
    filters: {
        Filters
    },
    mounted() {
        window.console.log('INVOICE ID LOADED', this.$route.query.id)
        this.getInvoiceData(this.$route.query.id)
        // let vm = this;
        // this.getBookingInfo()
        // this.getPromotions()
        // this.getServices()
        // this.getClientPromotions()
        // EventBus.$on('UPDATE_INVOICE_TOTAL', function() {
        //     vm.calcInvoiceTotal()
        // })
    },
    watch: {
        $route(to,from) {
            window.console.log('ROUTE CHANGE for INVOICE ID', to.query.id, from.name)
        },
        selectedProduct() {
            this.addProduct()
        },
        amountPaid() {
           
            if (Number(this.payAmount).toFixed(2) === Number(this.amountPaid).toFixed(2)) {
                this.paidInFull = true
            }
            window.console.log('AMT PAID', this.payAmount, this.amountPaid.substr(0))
            this.datePaid = new Date()
        },
        updatedItem() {
            window.console.log('PRODUT ITEM UPDATED', this.updatedItem)
        },
        invoiceTotalsData() {
            this.calcInvoiceTotal()
        },
        selectedService() {
            this.invoiceProducts.push(this.selectedService)
            this.calcInvoiceTotal()
        },
        selectedPromotion() {
            this.invoiceProducts.push(this.selectedPromotion)
            this.calcInvoiceTotal()
        },
        buttonClicked() {
            if (this.buttonClicked.name === 'Submit') {
                this.verifyInvoice()
            }
            if (this.buttonClicked.name === 'Adjust') {
                
                this.adjustInvoice()
            }
            if (this.buttonClicked.name === 'Email') {
                window.console.log('EMAIL',this.invoiceInfo)
                
            }
            if (this.buttonClicked.name === 'Print') {
                window.open(`${Globals.nodeURL}xvoice/invoice-${this.invoiceInfo.id}.pdf`, "_blank"); 
                
            }
            if (this.buttonClicked.name === 'Update') {
                // window.console.log('Update,this.invoiceInfo')
                this.updateInvoice()
            }
            if (this.buttonClicked.name === 'Cancel') {
                this.$router.back()
            }
        },
        selectedSearchType() {
            window.console.log('SELECTED SEARCH CHANGED', this.selectedSearchType)
            if (this.selectedSearchType === 'PRODUCTS') {
                window.console.log('SELECTED products')
                this.addSearch = this.productSearch
            }
            if (this.selectedSearchType === 'SERVICES') {
                window.console.log('SELECTED SERVICES')
                this.addSearch = this.serviceSearch
            }
            if (this.selectedSearchType === 'PROMOTIONS') {
                window.console.log('SELECTED PROMOTIONS')
                this.addSearch = this.promotionSearch
            }
        },
        tipAmount() {
            this.calcInvoiceTotal()
        },
        formattedPaidValue() {
            if (this.formattedPaidValue == this.payAmount) {
                this.paidInFull = true
            }
        }
    },
}
</script>

<style scoped>
.invoice-form-container{
    border: 1px solid #b0b2b3;
    margin: 0px 10%;
    max-width: 800px;
    padding-bottom: 15px;
}
/* .invoice-content-item{
    display: inline-block;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px;
    width: 12%;
    height: 25px;
} */
.title-row{
    border-right: 1px solid white;
    background-color: #4d91a7;
    color: white;
    font-weight: 600;
}
.invoice-item-row{
    display: inline-block;
    float: left;
    margin-left: 5%;
    font-size: 13px;
    width: 85%;
}
.product-name{
    width: 30%;
}
.form-product-row{
    /* border-bottom: 1px solid gray; */
}
.border-right{
    border-right: 1px solid gray !important;
}
.border-left{
    border-left: 1px solid gray !important;
}
.border-top{
    border-top: 1px solid gray;
}
.width-small{
    width: 5%;
}
.invoice-items-row-container{
    width: 100%;
    display: inline-block;
    margin-bottom: -5px;
}
.row-button-group{
    display: inline-block;
    margin-left: -100px;
}
.invoice-total-row{
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
}

.invoice-total-label{
    width: 70.5%;
    text-align: right;
    display: inline-block;
    font-weight: 600;
}
.invoice-total{
    display: inline-block;
    text-align: left;
    font-weight: 700;
    margin-right: 17%;
}
.credit-applied{
    display: inline-block;
    font-weight: 600;
    color: #9e1e55;
    position: relative;
    top: 3px;
    vertical-align: top;
    text-align: right;
    width: 75px;
}
.promo-credit-row{
    border: 1px solid;
    display: inline-block;
    margin-bottom: 10px;
    background: #f8fafb;
}
.promo-credit-item{
    display: inline-block;
    padding: 1px 15px;
    border-right: 1px solid;
}
.promo-credit-item:last-child{
    border-right: none;
}
.blue-back{
    background-color: #4a8bce;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 3px 12px;
    height: 18px;
    vertical-align: top;
}
.form-search-row{
    
    height: 30px;
}
.search-ahead-label{
    display: inline-block;
    position: relative;
    top: 3px;
}
.search-ahead-item{
    display: inline-block;
    position: relative;
    top: 0;
}
.search-selection-item{
    display: inline-block;
    position: relative;
    top: 2px;
    padding-left: 5px;
}
.form-content-tax-item{
    display: inline-block;
    padding: 0 10px;
}
.min-width{
    min-width: 650px;
}
.submit-confirm{
 width: 232px;
 background-color: white;
 border: 1px solid black;
 position: absolute;
 top: -80px;
 left: 50%;
 margin-left: -116px;
 padding: 3px;
 z-index: 10;
}
.button-confirm-container{
    position: relative;
}
.bottom-border{
    border-bottom: 2px solid gray;
}
.tax-info{
    font-size: 13px;
    text-align: center;
    margin-top: -10px;
    padding-bottom: 5px;
}
.form-credit-row{
    text-align: left;
}

</style>