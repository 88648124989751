<template>
    <div class="report-detail-container">
        <div class="report-item-detail-row">
            <div class="report-table-detail-item">{{'Booking' | translate}}</div>
            <div class="report-table-detail-item small-width">{{booking.id}}</div>
            <div class="report-table-detail-item">{{booking.bookingdate | shortDate}}</div>
            <div class="report-table-detail-item large-width">{{booking.service}}</div>
            <div class="report-table-detail-item">{{booking.room}}</div>            
            <div class="report-table-detail-item large-width">{{'Billed' | translate}}: {{booking.billed}}</div>
        </div>
        <ReportsInvoiceDetail 
            class="detail-sub-container"
            :invoice="invoice[0]" 
            :allInvoiceItems="allInvoiceItems"
            v-on:show-detail="displayInvoiceDetail = $event" />
    </div>
</template>
<script>
import ReportsInvoiceDetail from './ReportsInvoiceDetail'
export default {
    name: 'ReportsBookingDetail',
    data() {
        return {
            invoice: []
        }
    },
    props: ['booking','allInvoiceItems','invoicesStaff'],
    components: {
        ReportsInvoiceDetail,
    },
    methods: {
        getInvoiceBooking() {          
            this.invoice = this.invoicesStaff.filter(item => {
                if (item.bookingid == this.booking.id) {
                    window.console.log('MATCH', item)
                    return item
                }
            })
            window.console.log('BOOKING MOUNTED ', this.invoice, 'INV STAFF', this.invoicesStaff, this.booking, this.booking.id)
        }
    },
    mounted() {
        this.getInvoiceBooking()
    },
}
</script>
<style scoped>
.report-detail-container{
    /* border: 1px solid black; */
    background-color: white;
}
.report-item-detail-row{
    border-top: 1px solid #aac1ce;
    height: 20px;
    background-color: #e9f6fd;
}
.report-table-detail-item{
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    height: 20px;
    width: 12%;
    border-right: 1px solid #aac1ce;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 5px;
}
.report-table-detail-item:last-child{
    border-right: none;
}
.small-width{
    width: 8%;
}
.large-width{
    width: 18%;
}
.detail-sub-container{
    margin: 0 2%;
}
</style>