<template>
    <div class="permissions-container">
        <div class="permission-title-row">{{'Permissions' | translate}}</div>
        <div v-for="(category, index) in permissionTypes" :key="index">
            <div class="permission-category-title" @click="setShowCategory(category)">
                {{category}}
                <i class="fas fa-caret-right" v-if="categoryShow === category"></i>
                <i class="fas fa-caret-down" v-if="categoryShow !== category"></i></div>
            <div v-if="categoryShow === category" class="permission-category-container">
                <div class="permission-category-row" v-for="(categoryItem,index) in getPermissionInfo(category)" :key="'key-'+index">
                    <CheckBox 
                        v-bind:checkBoxLabel="categoryItem.name"
                        v-bind:cbmodel="categoryItem.value"
                        v-on:item-selected="updateCategoryItem(category,categoryItem.name,$event)" />
                </div>
            </div>
        </div>       
    </div>
</template>

<script>
import Permissions from '../../data/permissions.json'
import Constants from '../../utilities/constants'
import CheckBox from './CheckBox'

export default {
    name: 'Permissions',
    data() {
        return {
            // permissions: Permissions[this.permissionrole],
            permissionTypes: Constants.permissionTypes,
            categoryShow: '',
            permissions: {}
        }
    },
    props: [
        'permissionrole',
        'userpermissions'
    ],
    components: {
        CheckBox
    },
    methods: {        
        getPermissionInfo(category) {
            let permissionArray = []
            Object.keys(this.permissions[category]).forEach(item => {
                permissionArray.push({name:item, value: this.permissions[category][item]})
            })
            return permissionArray
        },
        setShowCategory(category) {
            this.categoryShow = this.categoryShow === category? '' : category
        },
        updateCategoryItem(category,item,value){
            this.permissions[category][item] = value
        },
        ckPermissions() {
            // Permissions
            this.permissionrole = !this.permissionrole ? 'staff' : this.permissionrole
            if (!this.userpermissions){
                this.permissions = Permissions[this.permissionrole]
                this.$emit('update-permissions',this.permissions)
            } else {
                this.permissions = this.userpermissions
            }
        }
    },
    mounted() {
        this.ckPermissions()
    },
    watch: {
        
    },
}
</script>

<style scoped>
.permissions-container{
    border: 1px solid black;
    margin: 0 20%;
}
.permission-title-row{
    font-weight: 600;
    color: white;
    background-color: #4c8cce;
}
.permission-category-title{
    font-weight: 600;
    background-color: #eaeaea;
    cursor: pointer;
    border-bottom: 1px solid #8c9dab;
    height: 30px;
    line-height: 32px;
}

.permission-category-row{
    text-align: left;
    padding-left: 100px;
}
.permission-category-container{
    padding-top: 15px;
}
</style>