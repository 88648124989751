<template>
    <div class="main-reports-form-container">        
        <div>{{'Reports' | translate | allCaps}} </div>
        <div>
            <Dropdown 
                v-on:item-selected="reportSelected = $event" 
                v-bind:selected="reportSelected.name" 
                v-bind:optioncontent="allReports"
                placeholder="Select Report">
            </Dropdown>
        </div>
        <div class="reports-schedule-row">
            <div class="report-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="selectedStartDate = $event" 
                    v-bind:mydate="startdate"
                    :placeholder = "'Select Start Date'">
                </DatePicker>
            </div>
            <div class="report-date-picker">
                <DatePicker  
                    class="date-picker"                
                    v-on:date-selected="selectedEndDate = $event" 
                    v-bind:mydate="enddate"
                    :placeholder = "'Select End Date'">
                </DatePicker>
            </div>
            <div class="report-search-container">
                <div class="button-main small-button" @click="submitSearch()">{{'Submit' | translate}}</div>
            </div>
        </div>
        <div v-if="showMonthPicker">
            <Dropdown 
                v-on:item-selected="monthSelected = $event" 
                v-bind:selected="monthSelected.name" 
                v-bind:optioncontent="allMonths"
                placeholder="Select Month">
            </Dropdown>
            
        </div>
        <div v-if="showLocationPicker">
            <Dropdown 
                v-on:item-selected="locationSelected = $event" 
                v-bind:selected="locationSelected.name" 
                v-bind:optioncontent="allLocations"
                placeholder="Select Location">
            </Dropdown>
            
        </div>
        <div class="error-container">
         <div class='missing-selection-message' v-if="showNoSelect">*{{'You need to select a report' | translate}}</div>
        </div>
        <GeneralReport 
            v-if="reportSelected.name =='General Report' && searchSubmitted"
            :allProducts="allProducts"
            :allServices="allServices"
            :allStaff="allStaff"
            :allSupplies="allSupplies"
            :allPromotions="allPromotions"
            :startdate="report.startdate"
            :enddate="report.enddate"
        />
        <IncomeDistReport
            v-if="reportSelected.name=='Income Distribution' && searchSubmitted"
            :startdate="report.startdate"
            :enddate="report.enddate"
        />
        
    </div>
</template>

<script>
import Dropdown from '../utility/Dropdown'
// import Constants from '../../utilities/constants'
import DatePicker from '../utility/DatePicker'
import Globals from '../../utilities/globals'
import store from '../../store/store'
import GeneralReport from './GeneralReport'
import IncomeDistReport from './IncomeDistReport'

export default {
    name: 'Reports',
    data() {
        return {
            store,
            reportSelected:{},
            allReports: [
                {id:0, name: 'General Report'},
                {id: 1, name: 'Income Distribution'}
            ],
            allLocations:[],
            allStaff:[],
            allServices:[],
            allProducts:[],
            allSupplies:[],
            allPromotions:[],
            showMonthPicker: false,
            showLocationPicker: false,
            showGeneralReport: false,
            showIncomeDistReport: false,
            showNoSelect: false,
            selectedStartDate: {},
            selectedEndDate: {},
            startdate: '',
            enddate: '',
            report:{startdate:'',enddate:''},
            searchSubmitted: false
        }
    },
    components: {
        Dropdown,
        DatePicker,
        GeneralReport,
        IncomeDistReport
    },
    methods: {
        submitSearch() {
            window.console.log('SUBMIT SEARCH', this.reportSelected, this.selectedStartDate, this.selectedEndDate)
            if (!this.reportSelected.name) {
                this.showNoSelect = true
                return
            }
            this.report.startdate = `${this.selectedStartDate.month + 1}/${this.selectedStartDate.day}/${this.selectedStartDate.year}`
            this.report.enddate = `${this.selectedEndDate.month + 1}/${this.selectedEndDate.day}/${this.selectedEndDate.year}`
            this.report.locationid = ''
            this.startdate = {day: this.selectedStartDate.day, month: this.selectedStartDate.month, year: this.selectedStartDate.year,selectedDate: this.selectedStartDate.selectedDate}
            this.enddate = {day: this.selectedEndDate.day, month: this.selectedEndDate.month, year: this.selectedEndDate.year,selectedDate: this.selectedEndDate.selectedDate}
            this.searchSubmitted = true
            window.console.log('SUBMIT SEARCH AFTER', this.report.startdate, this.report.enddate, )
            // if (this.reportSelected.name == 'General Report') {
            //     this.showGeneralReport = true
            // } else if (this.reportSelected.name == 'Income Distribution') {
            //     this.showIncomeDistReport = true
            // }
            
        },
        setCurrentMonth() {
            const month = new Date().getMonth()
            const year = new Date().getFullYear()
            const lastDay = new Date(year,month + 1, 0).getDate()
            
            
            this.report.startdate = `${month + 1}/1/${year}`
            this.report.enddate = `${month + 1}/${lastDay}/${year}`
            this.startdate = {day: 1, month: month, year: year,selectedDate: Globals.formatSelectedDate(this.report.startdate)}
            this.enddate = {day: lastDay, month: month, year: year,selectedDate: Globals.formatSelectedDate(this.report.enddate)}
            // this.getBookings()
            // this.getInvoiceItemsDates()
            // this.getInvoicesDates()
        },
        getLocations() {
            this.allLocations = this.$store.getters.allLocations
            if (!this.allLocations.length){
                this.$store.dispatch('getLocations').then(response => {
                    window.console.log('RESPONSE LOCATIONS', response)
                    this.allLocations = response
                })
            }
            if (this.allLocations.length < 2) {
                this.showLocationPicker = false
                this.report.locationid = this.allLocations[0].id
            }
            window.console.log('LOCATIONS', this.allLocations)
        },
        getStaff() {
            window.console.log('GET STAFF')
            this.$store.dispatch('getAllStaff').then(response => {
                window.console.log('RESPONSE STAFF', response)
                this.allStaff = response
            })
            window.console.log('STAFF', this.allStaff)
        },
        getServices() {
            window.console.log('GET SERVICES')
            this.$store.dispatch('getServicesAll').then(response => {
                window.console.log('RESPONSE SERVICES', response)
                this.allServices = response
            })
            window.console.log('SERVICES', this.allServices, this.allServices.length)
        },
        getProducts() {
            this.$store.dispatch('getProducts').then(response => {
                window.console.log('RESPONSE PRODUCTS', response)
                this.allProducts = response
            })
        },
        getSupplies() {
            this.allSupplies = this.$store.getters.allSupplies
            if(!this.allSupplies.length) {
                this.$store.dispatch('getSupplies').then(response => {
                    window.console.log('RESPONSE SUPPLIES', response)
                    this.allSupplies = response
                })
            }
            window.console.log('PRODUCTS', this.allProducts, this.allProducts.length)
        },
        getPromotions() {
            this.$store.dispatch('getPromotions').then(response => {
                window.console.log('ALL PROMOTIONS', response)
                this.allPromotions = response
            })
        }
    },
    mounted() {
        this.setCurrentMonth()
        this.getLocations()
        this.getStaff()
        this.getServices()
        this.getProducts()
        this.getPromotions()
        this.getSupplies()
    },
    watch: {
        reportSelected() {
            this.showNoSelect = false
        }
    },
}
</script>

<style >
.main-reports-form-container{
    background-color: white;
    max-width: 800px;
    padding-bottom: 30px;
}
.report-search-container {
    display: inline-block;
    padding-top: 4px;
} 
.report-date-picker{
    display: inline-block;
    padding: 5px;
    vertical-align: top;
    background-color: white;
}
.error-container{
    margin-top: 10px;
} 
.report-activity-title{
    background-color: #3367c5;
    margin-top: 15px;
    color: white;
    font-weight: 700;
    font-size: 14px;
}
.report-title{
    background-color: #33a4c5;
    line-height: 27px;
    height: 25px;
} 
.report-item-title-row{
    height: 33px;
    vertical-align: middle;
}
.report-item-row{
    border: 1px solid #aac1ce;
    min-height: 20px;
    border-bottom: none;
}

.report-item-row:last-child{
    border-bottom: 1px solid #aac1ce;
}
.report-table-item{
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
    text-align: right;
    padding-right: 3px;
    width: 15%;
    border-right: 1px solid #aac1ce;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    
}
.report-table-item:last-child{
    border-right: none;
}
.tiny-item{
    width: 5%;
}
.small-item{
    width: 10%;
}
.report-sub-container{
    margin: 0 2%;
}

.item-title{
    font-weight: 600;
    word-break:normal;
    white-space: none;
    /* height: 33px; */
    line-height: 16px;
    text-align: center;
    vertical-align: middle
}
.item-total{
    font-weight: 600;
    word-break:normal;
    white-space: none;
    height: 20px;
    line-height: 16px;
    text-align: right;
    padding-right: 3px;
    vertical-align: middle;
}
.item-center{
    text-align: center;
}
.is-clickable{
    cursor: pointer;
    white-space: nowrap;

} 
</style>