<template>
    <div class="colors-container">
        <div class="color-block" 
            v-for="(color, index) in colorList" 
            :key="index" 
            :style="{'backgroundColor':color.hex}"
            :class="{'selected-color':selectedIndex === index}"
            @click="selectedColor(index)"
            ></div>
        
    </div>
</template>

<script>
export default {
    name: 'ColorBlocks',
    data() {
        return {
            colorList: [
                { id: 0, hex: '#6641d8'}, 
                { id: 1, hex: '#cc00ff'},
                { id: 2, hex: '#ff00ec'}, 
                { id: 3, hex: '#e67501'},
                { id: 4, hex: '#ffa400'}, 
                { id: 5, hex: '#e6cc16'},
                { id: 6, hex: '#9fc30a'}, 
                { id: 7, hex: '#59e040'},
                { id: 8, hex: '#00d0ff'}, 
                { id: 9, hex: '#008aff'},
            ],
            selectedIndex: ''
        }
    },
    props: [
        'selectedcolor'
    ],
    methods: {
        selectedColor(index) {
            window.console.log('SELECTED COLOR', index)
            this.$emit('color-selected',this.colorList[index].hex)
            this.selectedIndex = index
        },
        setSelectedColor(color) {
            let colorIndex = 0
            this.colorList.forEach(item => {
                if (item.hex == color) {
                    colorIndex = item.id
                }
            })
            this.selectedColor(colorIndex)
        }

    },
    mounted() {
        
        if (this.selectedcolor !== undefined) {
            this.setSelectedColor(this.selectedcolor)
        }
        
    },
    watch: {
        selectedcolor() {
            this.setSelectedColor(this.selectedcolor)
        }
    },
}
</script>
<style scoped>
.color-block{
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 2px 5px ;
    cursor: pointer;
}
.colors-container{
    display: inline-block;
    max-width: 160px;
}
.selected-color{
    border: 3px solid black;
    margin: 0 5px;
}
    
</style>