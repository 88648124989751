<template>
    <div class="client-info-container">
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showNotes=!showNotes">
                <i class="fas fa-caret-right" v-if="!showNotes"></i>
                <i class="fas fa-caret-down" v-if="showNotes"></i>
                {{'Notes' | translate}} 
                <div class="item-count-container" >
                    {{notes.length}}
                </div>
                <div class="client-add-button" @click="addNote">
                    <i class="fas fa-plus-circle"></i>
                </div>
            </div>
            <div class="client-new-note-container" v-if="showNewNote">
                <div class="new-note-title">{{'New Note' | translate}}</div>
                <textarea class="new-note" v-model="note" />
                <div>
                    <CheckBox
                        v-bind:checkBoxLabel="'Send to Client'"
                        v-on:item-selected="sendToClient = $event"  >
                    </CheckBox>
                </div>
                <div class="new-note-buttons">
                    <div class="button-main snall-button" @click="submitNewNote">{{'Submit' | translate}}</div>
                    <div class="button-main snall-button" @click="showNewNote = false">{{'Cancel' | translate}}</div>
                </div>
            </div>
            <div v-if="showNotes">
                <div class="client-info-sub-item" 
                    v-for="(note, index) in notes"
                    :key = "index">
                    {{note.note}} - {{note.dateentered | shortDate}}
                </div>               
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showBookings=!showBookings">
                <i class="fas fa-caret-right" v-if="!showBookings"></i>
                <i class="fas fa-caret-down" v-if="showBookings"></i>
                {{'Bookings' | translate}} 
                <div class="item-count-container" >
                    {{bookings.length}}
                </div>
            </div>
            <div v-if="showBookings">
                <div class="client-info-sub-item" 
                    v-for="(book, index) in bookings"
                    :key = "index">
                    {{book.bookingdate | longDate}} - {{book.starttime}} {{book.starttime | toAMPM}}- {{book.service}} - {{book.room}} - {{book.staff}}
                </div>
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showInvoices=!showInvoices">
                <i class="fas fa-caret-right" v-if="!showInvoices"></i>
                <i class="fas fa-caret-down" v-if="showInvoices"></i>
                {{'Invoices' | translate}}
                <div class="item-count-container" >
                    {{invoices.length}}
                </div>
            </div>
            <div v-if="showInvoices">
                <div class="client-info-sub-item" 
                    v-for="(invoice, index) in invoices"
                    :key = "index">
                    {{invoice.id}} - {{'Booking' | translate}}: {{invoice.bookingdate | shortDate}} - {{'Paid' | translate}}: {{invoice.datepaid | shortDate}} - {{invoice.amountpaid | toUSD}}
                    <div class="button-icon contact-icon" @click="openInvoice(invoice.id)"><i class="fas fa-file-invoice"></i></div>
                </div>
            </div>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showPromotions=!showPromotions">
                <i class="fas fa-caret-right" v-if="!showPromotions"></i>
                <i class="fas fa-caret-down" v-if="showPromotions"></i>
                {{'Promotions' | translate}}
                <div class="item-count-container" >
                    {{promotions.length}}
                </div>
            </div>
            <div v-if="showPromotions">
                <div class="client-info-sub-item" 
                    v-for="(promotion, index) in promotions"
                    :key = "index">
                    {{promotion.datepaid | shortDate}} - {{promotion.expirationdate | shortDate}} {{promotion.name}} -  {{promotion.refName}}: {{promotion.refCategory}} balance: {{promotion.refBalance}}
                </div>
            </div>
        </div>
        <div class="client-info-sub-container " v-if="showMedInfo">
            <div  class="client-info-sub-title border-bottom" @click="showMedicalSub">
                <i class="fas fa-caret-right" v-if="!showMedical"></i>
                <i class="fas fa-caret-down" v-if="showMedical"></i>
                {{'Medical' | translate}}
                <div class="button-main small-button survey-button" @click="showSurvey">{{'Page Layout' | translate | allCaps}}</div>   
            </div>
            
            <MedSurvey 
                v-if="showMedical"
                :refname="clientref.refname"
                :clientid="clientref.clientid"></MedSurvey>
        </div>
        <div class="client-info-sub-container">
            <div  class="client-info-sub-title" @click="showImages=!showImages">
                <i class="fas fa-caret-right" v-if="!showImages"></i>
                <i class="fas fa-caret-down" v-if="showImages"></i>
                {{'Images' | translate}} 
                <div class="item-count-container" >
                    {{images.length}}
                </div>
                <div class="show-camera-button-container" v-if="showCameraButton">
                    <div class="button-icon small-icon camera-icon" @click="showCamera">
                        <i class="fas fa-camera"></i>
                    </div>
                </div>
            </div>
            <div v-if="showImages">
                <div class="client-info-sub-item" >
                    <div class="info-panel-image-container"
                        v-for="(imageData, index) in images"
                        :key = "index">
                        <!-- <img v-bind:src="imageData.image" class="group-images" height="100" @click="previewImage(imageData,index)" /> -->
                        <img v-bind:src="`${imageURL}${imageData.refnum}-th.jpg`" class="group-images" height="100" @click="previewImage(imageData,index)" />
                        <div class="image-date">{{imageData.dateentered | shortDate}} <i class="fas fa-pen" v-if="imageData.note"/></div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import Constants from '../../utilities/constants.js'
import Globals from '../../utilities/globals'
import EventBus from '../../utilities/eventBus'
import CheckBox from '../utility/CheckBox'
import MedSurvey from '../utility/MedSurvey'

export default {
    name: 'ClientInfoPanel',
    data() {
        return {
            bookings: [],
            notes: [],
            medicalInfo: [],
            promotions: [],
            medicalAreas: Constants.medicalAreas,
            images: [],
            invoices: [],
            showNotes: false,
            showNewNote: false,
            showBookings: false,
            showMedical: false,
            showMedInfo: false,
            showImages: false,
            showPromotions: false,
            showCameraButton: true,
            showInvoices: false,
            imageURL: Globals.imageURL,
            note: '',
            sendToClient: false
        }
    },
    props: [
        'clientref',
    ],
    components: {
        CheckBox,
        MedSurvey
    },
    methods: {
        showSurvey() {
            window.console.log('ROUTE',this.clientref,this.$store.getters.isAuthenticated )
            this.$router.push(`MedSurvey?ref=${this.clientref.clientid}`)
        },
        addNote(){
            this.showNewNote = true
            window.console.log('ADD NOTE',Globals.businessType)
            setTimeout(() => {
                this.showNotes = false
            }, 0);            
        },
        submitNewNote() {
            window.console.log('SUBMIT NOTE', this.sendToClient)
            const noteData = {
                note: Globals.encryptData(this.note),
                bookingid: 0,
                clientid: 28,
                type: 'message',
                status: 'note'
            }
            if(this.sendToClient) {
                if (Globals.businessType == 'medspa') {
                    noteData.status = 'sendPatient'
                } else {
                    noteData.status = 'normal'
                }
                window.console.log('SEND TO CLIENT', this.note)
            }            
        },
        getBookings() {           
            this.$store.dispatch('getBookingsByClient',{id: this.clientref.clientid}).then( response => {
                if (response.result == 'SUCCESS') {
                    this.bookings = response.data
                } else if (response.result == 'EXPIRED') {
                    const data = {
                        title: "Session Timeout",
                        message: "Your session has timed out. Please login again.",
                        showOK: true,
                        okAction: 'login'
                    }
                    EventBus.$emit('SHOW_MESSAGE', data)
                }
                
                window.console.log('BOOKINGS', response)
            })
        },
        checkType() {
            window.console.log('BUSINESS TYPE', Globals.businessType)
            if (Globals.businessType == 'medspa') {
                this.showMedInfo = true
            }
        },
        getPromotions() {
            this.$store.dispatch('getClientPromotions',{id:this.clientref.clientid}).then( response => {
                window.console.log('PROMOTIONS', response,this.clientref)
                if (response.result == 'SUCCESS') {
                    this.promotions = response.data
                    this.promotions.forEach(item => {
                        item.items.forEach(subitem => {
                            item.refName = subitem.name                       
                            item.refBalance = subitem.quantity
                            item.refCategory = subitem.category
                            if (subitem.category === 'credit') {
                                item.refBalance = Globals.toUSD(subitem.quantity)
                            }
                        })
                    })
                }
            })
        },
        getNotes() {
            window.console.log('GET NOTES CLIENT', this.clientref)
            this.$store.dispatch('getNotesClient',{id: this.clientref.clientid}).then( response => {
               if(response.result == 'SUCCESS'){
                   window.console.log('GOT NOTES', response)
                   this.notes = response.data.filter(item => {
                        if (item.note && item.note.length > 0) {
                            return item
                        }
                    })
               } else {
                   return []
               }
               
            })
        },
        setupImages() {

        },
        getImages() {
            this.$store.dispatch('getImages',{id: this.clientref.clientid}).then(response => {
                window.console.log('IMAGES',response.data)
                this.images = response.data
            })
        },
        getInvoices() {
            this.$store.dispatch('getInvoicesClient',{id: this.clientref.clientid}).then(response => {
                window.console.log('GOT INVOICES',response)
                if(response.result == 'SUCCESS') {
                    this.invoices = response.data
                } else {
                    this.invoices = []
                }
                
            })
        },
        previewImage(imageData,index) {
            window.console.log('PREVIEW IMAGE',index, imageData)
            this.$emit('preview-image',true)
            this.$emit('update-image',imageData)
        }, 
        showMedicalSub() {
            let vm = this
            window.console.log('SHIDE',vm.showMedical)
            this.showMedical = !this.showMedical
            window.console.log('SHIDE AFTER',vm.showMedical)
        },
        showCamera(){
            window.console.log('SHOW CAMERA')
            this.$emit('show-camera',true)
        },
        cameraButton() {
            if (this.clientref.hideCameraButton) {
                // this.showCameraButton = false
            }
        },
        openInvoice(id) {
            window.console.log('OPEN INVOICE ',id)
            this.$router.push(`InvoiceId?id=${id}`)
        }
    },
    mounted() {
        
        this.imageURL = Globals.imageURL
        window.console.log('MOUNT CIP', this.clientref, this.imageURL)
        let vm = this
        EventBus.$on('IMAGE_ADDED', function(){
            window.console.log('GOT IMAGES ADDED')
            vm.getImages()
        })
        EventBus.$on('CHANGE_LANGUAGE', () => {
            this.$forceUpdate()
        })
        vm.checkType()
        vm.getBookings()
        vm.getInvoices() 
        vm.getNotes()
        vm.getPromotions()
        vm.getImages()
        vm.cameraButton()      
    },
    watch: {
        
    },
    
}
</script>

<style scoped>
.client-info-container{
    padding: 10px 0;
    margin: 0 5%;
}
.client-info-row{
    text-align: center;
}
.client-info-sub-item{
    text-align: left;
    border-top: 1px solid lightgray;
    padding: 8px 20px;
    font-size: 13px;
    background-color: white;
}
.client-info-sub-container{
    border: 1px solid lightgray;
    background-color: #578bb7;
    min-height: 35px;
}
.client-info-sub-title{
    text-align: center;
    height: 20px;
    padding: 8px 10px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    
}
.item-count-container{
    background-color: #bdd4e2;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    width: 30px;
    text-align: center;
    color: black;
    border-radius: 9px;
    line-height: 19px;
    vertical-align: top;
    margin: 2px 0 0 3px;
}
.client-info-medical-sub-title{
    /* border-bottom: 1px solid lightgray; */
    cursor: pointer;
    background-color: white;
}
.client-add-button{
    display: inline-block;
    color: #bdd4e2;
    font-size: 20px;
    float: right;
    cursor: pointer;
}
.client-new-note-container{
    background-color: #e6ecee;
    padding: 10px;
}
.new-note-buttons{
    margin-top: -9px;
}
.new-note-title{
    background-color: #bcc7ce;
    margin-bottom: 10px;
}
.new-note{
    width: 90%;
    height: 60px;
    margin-bottom: 5px;
    font-size: 16px;
    padding: 10px;
}
.client-add-container{

}
.border-bottom{
    /* border-bottom: 1px solid lightgray; */
}
.show-hide-icon{
    float: right;
    padding-right: 15px;
}
.show-camera-button-container{
    display: inline-block;
    float: right;
    margin-top: -6px;
}
.camera-icon{
    padding: 2px 6px;
} 
.group-images{
    cursor: pointer;
}  
.info-panel-image-container{
    display: inline-block;
    padding: 3px;
}
.survey-button{
    float: right;
    margin-top: 0;
    background-color: orange;
}
.image-date{
    text-align: center;
    font-size: 12px;
}
</style>