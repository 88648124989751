<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">{{ 'Business Features' | translate | allCaps}}</div>
        <div class="form-input-row feature-row">
            <label class="form-input-label" >{{'Allow Tips' | translate}} : </label>
            <CheckBox 
                    v-bind:checkBoxLabel="''"
                    v-on:item-selected="allowTips = $event"
                    :cbmodel="allowTips" 
                    />
            <div class="update-tip-button">
                <div class="button-main" @click="updateTips">{{'Update' | translate | allCaps}}</div> 
            </div> 


        </div>

    <!-- TAXES -->
    <div class="form-input-row feature-row" v-if="this.allLocations.length > 1">
        <label class="form-input-label" >{{'Location' | translate}}: </label>
        <Dropdown 
                v-on:item-selected="locationSelected = $event" 
                v-bind:selected="locationSelected.name" 
                v-bind:optioncontent="allLocations"
                placeholder="Select Location">
            </Dropdown>
    </div>
        <div class="form-input-row feature-row">
            <label class="form-input-label" >{{'Sale Tax' | translate}}- {{'Services' | translate}}: </label>
            <div class="device-status" v-if="!updateTaxServices">{{this.locationSelected.taxservices}} %</div>
            <input class="feature-input-narrow" v-model="locationSelected.taxservices" v-if="updateTaxServices"/><span class="tax-percent" v-if="updateTaxServices">%</span>
            <div class="status-approval-button">
                <div class="button-main" @click="updateTaxes" v-if="updateTaxServices">{{'Update' | translate | allCaps}}</div>
                <div class="button-main" @click="updateTaxServices = true" v-if="!updateTaxServices">{{'Change' | translate | allCaps}}</div>  
            </div> 
        </div>
        <div class="form-input-row feature-row">
            <label class="form-input-label" >{{'Sale Tax' | translate}}- {{'Products' | translate}}: </label>
            <div class="device-status" v-if="!updateTaxProducts">{{this.locationSelected.taxproducts}} %</div>
            <input class="feature-input-narrow" v-model="locationSelected.taxproducts" v-if="updateTaxProducts"/><span class="tax-percent" v-if="updateTaxProducts">%</span>
            <div class="status-approval-button">
                <div class="button-main" @click="updateTaxes" v-if="updateTaxProducts">{{'Update' | translate | allCaps}}</div>
                <div class="button-main" @click="updateTaxProducts = true" v-if="!updateTaxProducts">{{'Change' | translate | allCaps}}</div>  
            </div> 
        </div>

    <!-- DEVICES -->
        <div class="form-input-row feature-row">
            <label class="form-input-label" >{{'Device Status' | translate}} : </label>
            <div class="device-status">{{deviceStatus | translate | allCaps}}</div>
            <div class="status-approval-button">
                <div class="button-main" @click="updateApproval">{{deviceChangeStatus | translate | allCaps}}</div> 
            </div> 


        </div>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{'Tip Status' | translate}} {{'Successfully Updated' | translate}}</div>
        </div>
        <div class="location-coordinate-container" >
            <div @click="showLocationDetail = !showLocationDetail">
                <div class="section-title" >{{'Location Coordinates' | translate}}</div>
                <div class="show-hide-arrows">
                    <i class="fas fa-chevron-down" v-if="!showLocationDetail"></i>
                    <i class="fas fa-chevron-right" v-if="showLocationDetail"></i>
                </div>
            </div>
              
            <div v-if="showLocationDetail">      
                <div v-for="(location,index) in allLocations" :key="index" class="location-coordinate-box">
                    <div class="form-input-row">
                        <label class="location-input-label">
                            {{'Location Coordinates' | translate}} : {{location.name}} : {{'status' | translate }} : {{location.coordinatestatus}}
                        </label>               
                    </div>
                    <div class="coordinate-input-row">
                        <label class="location-input-label">{{setStatus(location.id) | translate}} {{'Coordinates to Current Location' | translate}} : </label>
                        <div class="button-main" @click="checkCoordinateStatus(location.coordinatestatus,location.id,location.name)">{{setStatus(location.id) | translate | allCaps}} </div>            
                    </div>
                </div> 
                <div class="form-input-row" v-show="showLocationSuccess">
                    <div class="success-label">{{'Location Coordinates Update' | translate}} {{'Successfully' | translate}}</div>
                </div>
                <div class="confirm-update-container" v-if="showConfirmUpdate">
                    <div class="confirm-update-content">{{'Are You at ' | translate}} {{selectedLocationName}}? <br><br> {{'Are You Sure You Want to Update the Current Coordinates for this Location?' | translate}}</div>
                    <div>
                        <div class="button-main" @click="confirmUpdate(true)" >{{'Yes' | translate | allCaps}}</div>
                        <div class="button-main" @click="confirmUpdate(false)" >{{'No' | translate | allCaps}}</div>
                    </div>
                </div>
            </div> 
        </div>
        <div>
            <div @click="showDisplayOrder = !showDisplayOrder">
                <div class="section-title">{{'Change Staff Display Order' | translate }}</div>
                <div class="show-hide-arrows" >
                    <i class="fas fa-chevron-down" v-if="!showDisplayOrder" ></i>
                    <i class="fas fa-chevron-right" v-if="showDisplayOrder"></i>
                </div>
            </div>
            <div v-if="showDisplayOrder">
                <div class="staff-order-row" v-for="(staff, index) in orderStaff" :key="index">
                <div class="staff-name"> {{staff.displayname}}</div>
                <div class="up-down">
                        <div class="up-down-arrows" @click="moveUp(index)" v-if="index !== 0"><i class="fas fa-chevron-up" ></i></div>
                        <div class="blank-arrow-holder"  v-if="index == 0"> </div>
                        <div class="up-down-arrows" @click="moveDown(index)" v-if="index !== orderStaff.length -1 "><i class="fas fa-chevron-down" ></i></div>
                        <div class="blank-arrow-holder"  v-if="index == orderStaff.length -1"> </div>
                </div> 
                </div>
                <div class="button-main" @click="updateStaffOrder">{{ 'Save' | translate }}</div>
                
            </div>
        </div>
        <div class="form-input-row" v-show="showOrderSuccess">
            <div class="success-label">{{'Feature' | translate}} {{'Successfully Updated' | translate }}</div>
        </div>  
                 
    </div>
</template>

<script>
import CheckBox from '../utility/CheckBox'
import Dropdown from '../utility/Dropdown'
import store from '../../store/store'
import Globals from '../../utilities/globals'

export default {
    name: 'BusinessFeatures',
    data() {
        return {
            allowTips: true,
            store,
            allLocations:[],
            setLocation: '',
            locationSelected: {},
            showSuccess: false,
            showOrderSuccess: false,
            showLocationSuccess: false,
            showConfirmUpdate: false,
            selectedLocationId: 0,
            selectedLocationName: '',
            showLocationDetail: false,
            showDisplayOrder: false,
            approvalStatus: false,
            deviceChangeStatus: 'Approve',
            deviceStatus: 'Unapproved',
            orderStaff: [],
            updateTaxServices: false,
            updateTaxProducts: false,
        }
    },
    components: {
        CheckBox,
        Dropdown
    },
    methods: {
        updateTaxes() {
            this.showOrderSuccess = false
            window.console.log('UPDATE TAXES', this.locationSelected.taxproducts, this.locationSelected.taxservices)
            this.updateTaxServices = false;
            this.updateTaxProducts = false;
            this.$store.dispatch('updateTaxes',{id:this.locationSelected.id,taxproducts: this.locationSelected.taxproducts,taxservices:this.locationSelected.taxservices}).then(response => {
                if (response.result == 'SUCCESS') {
                    this.showOrderSuccess = true
                }
            })
        },
        updateStaffOrder() {
            this.showOrderSuccess = false
            this.orderStaff.forEach(staff => {
                if (staff.order !== staff.displayorder) {
                    this.$store.dispatch('updateStaffOrder',{id: staff.id,order:staff.order}).then(response => {
                        if (response.result == 'SUCCESS') {
                             this.showOrderSuccess = true
                        }
                    })
                }
            })
            this.getStaff()
        },
        moveUp(position) {
            this.orderStaff[position].order = position -1
            this.orderStaff[position -1].order = position
            Globals.sortList(this.orderStaff,'order')
            this.showOrderSuccess = false
        },
        moveDown(position) {
            this.orderStaff[position].order = position + 1
            this.orderStaff[position + 1].order = position
            Globals.sortList(this.orderStaff,'order')
            this.showOrderSuccess = false
        },
        getStaff() {
            this.$store.dispatch('getStaff',this.$store.getters.currentLocation.id).then(response => {
                this.orderStaff = response.data
                this.orderStaff.map((staff) => {staff.order = staff.displayorder})
                Globals.sortList(this.orderStaff,'order')
                window.console.log('STAFF LOAD',this.orderStaff)
            })
            
        },
        updateApproval() {
            window.localStorage.setItem('deviceApprovalStatus', !this.approvalStatus)
        },
        getApproval() {
            window.console.log('APPROVAL', window.localStorage.getItem('deviceApprovalStatus'))
            this.approvalStatus = window.localStorage.getItem('deviceApprovalStatus') ? window.localStorage.getItem('deviceApprovalStatus') : false
        },
        getTipsActive() {
            this.showSuccess = false
            this.$store.dispatch('getActiveTips').then(response => {
                window.console.log('TIPS ACTIVE RESPONSE',response)
                if (response.result == 'SUCCESS') {
                    this.allowTips = response.data.tips                   
                }                
            })
        },
        updateTips() {            
            const data = {
                businessid: this.$store.getters.user.businessid,
                tips: this.allowTips
            }
            window.console.log('UPDATE TIPS', data)
            this.$store.dispatch('updateTips',data).then(response => {
                window.console.log('TIPS UPDATED RESPONSE',response)
                if (response.result == 'SUCCESS') {
                    this.showSuccess = true
                }
            })
        },
        getLocations() {
            this.$store.dispatch('getLocations').then(response => {
                if(response.data && response.data.length){
                    this.allLocations = response.data
                    window.console.log('GOT LOCATIONS', this.allLocations)
                    if (this.allLocations.length == 1) {
                        this.locationSelected = this.allLocations[0]
                        window.console.log('THIS LOCATION', this.locationSelected)
                    }
                }            
            })   
        },
        setStatus(id) {
            let currentStatus = 'SET'
            this.allLocations.forEach(location => {
                if (location.id == Number(id)) {
                    if (location.coordinatestatus == 'SET') {
                        currentStatus = 'UPDATE'
                    }
                }
            })
            return currentStatus
        },
        checkCoordinateStatus(status, id, name) {
            this.selectedLocationId = id
            this.selectedLocationName = name
            if(status == 'SET') {
                this.showConfirmUpdate = true
            } else {
                this.setCoordinates()
            }
        },
        confirmUpdate(value) {
            this.showConfirmUpdate = false
            if (value) {
                this.setCoordinates()
            }
        },
        setCoordinates() {
            window.console.log('set location coordinates for',this.setLocation,navigator.geolocation)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.setPosition,this.positionFail)
            } else {
                alert ("Geolocation is not supported by this browser.")
            }
        },
        setPosition(position) {
            this.showLocationSuccess = false
            window.console.log('POSITION',position,this.selectedLocationId,position.coords )
            this.allLocations.forEach(location => {
                window.console.log(location.id,this.selectedLocationId)
                if (location.id == Number(this.selectedLocationId)) {
                    location.coordinatestatus = 'SET'
                    location.coordinates = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    }
                    alert('LOC'+ position.coords.latitude + ' : ' + position.coords.longitude + ' : ' + position.coords.accuracy)
                    this.$store.dispatch('updateLocation',location).then(response => {
                        window.console.log('LOCATION UPDATED',response)
                        if (response.result == 'SUCCESS') {
                            this.showLocationSuccess = true
                        }
                    })
                    window.console.log('SET COR STATUS',this.allLocations)
                    window.console.log('DISTANCE', this.calculateDistance(26.020025989592526,-81.68835817555549,26.01985291255625,-81.68844528132234))
                }
            })
        },
        clearCoordinates() {
            window.console.log('CLEAR POSITION',this.setLocation.split('-')[0])
        },
        positionFail() {
            window.console.log('COULD NOT GET POSITION')
        },
        calculateDistance(lon1, lat1, lon2, lat2) {
            /** Converts numeric degrees to radians */
            if (typeof(Number.prototype.toRad) === "undefined") {
                Number.prototype.toRad = function() {
                    return this * Math.PI / 180;
                }
            }

            var R = 6371; // Radius of the earth in km
            var dLat = (lat2-lat1).toRad();  // Javascript functions in radians
            var dLon = (lon2-lon1).toRad(); 
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * 
                    Math.sin(dLon/2) * Math.sin(dLon/2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c; // Distance in km
            return d;
        }
    },
    mounted() {
        this.getLocations()
        this.getTipsActive()
        this.getApproval()
        this.getStaff()
        window.console.log('bus info',this.$store.getters.currentLocation)
    },
    watch: {
        setLocation() {
            window.console.log('SET LOCATION', this.setLocation,this.setLocation.split('-')[1])
            if (this.setLocation.split('-')[1] == 'true') {
                this.setCoordinates()
            } else {
                this.clearCoordinates()
            }
            
        },
        approvalStatus() {
            this.deviceStatus = this.approvalStatus ? 'Approved' : 'unApproved'
            this.deviceChangeStatus = this.approvalStatus ? 'Unapprove' : 'Approve'
        },
        showOrderSuccess() {
            if (this.showOrderSuccess) {
                setTimeout(() => {
                    this.showOrderSuccess = false
                },3000)
            }
        }
    }
}
</script>

<style scoped>
.form-input-label{
    font-size: 16px;
}
.location-input-label{
    padding-left: 10%;
}
.location-coordinate-box{
    border-top: 1px solid black;
    padding-top: 10px;
    margin: 0 5%;
}
.location-coordinate-container{   
    position: relative;
}
.coordinate-input-row{
    text-align: left;
    margin-top: -20px;
    margin-bottom: 10px;
}
.confirm-update-container{
    position: absolute;
    width: 50%;
    margin: 0px 18%;
    height: 150px;
    background-color: white;
    border: 1px solid blue;
    padding: 15px;
    top: 0;
    left: 30px;
}
.confirm-update-content{
    text-align:center;
    font-weight: 600;
} 
.update-tip-button{
    display: inline-block;
    position: relative;
    top: -15px;
    left: -133px;
}
/* .location-detail-title{
    font-weight: 600;
    color: #0b7896;
    cursor: pointer;
}  */
.status-approval-button{
    display: inline-block;
    position: relative;
    top: -15px;
} 
.feature-row{
    height: 45px;
} 
.device-status{
    display: inline-block;
    font-weight: 600;
    vertical-align: top;
    line-height: 23px;
    padding-right: 15px;
    font-size: 16px;
}
.staff-name{
    display: inline-block;
    border: 1px solid gray;
    padding: 5px;
    width: 150px;
}
.up-down{
    display: inline-block;
}
.up-down-arrows{
    background-color: #4e8fbf;
    color: white;
    padding: 4px;
    border-radius: 15px;
    display: inline-block;
    margin-left: 15px;
    width: 15px;
    cursor: pointer;
}
.blank-arrow-holder {
    width: 15px;
    margin-left: 15px;
    padding: 4px;
    border-radius: 15px;
    display: inline-block;
}
.section-title {
    font-weight: 600;
    padding: 10px;
    font-size: 15px;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
}
.show-hide-arrows {
    display: inline-block;
    padding-left: 15px;
}
.feature-input-narrow {
    width: 30px;
    height: 18px;
    font-size: 14px;
    position: relative;
    top: -15px;
}
.tax-percent {
    position: relative;
    top: -15px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 5px;
}
</style>