<template>
    <div class="main-input-form-container" ref="main-container">
        <div class="form-title-row">Add Service Type</div>
        <div class="form-input-row">
            <label class="form-input-label" >Name </label>
            <input id="type" class="input-wide" v-model="newType"/>
        </div>
        <div class="service-existing-container">
            <div class="service-title">Existing Service Types</div> 
            <div class="service-type-row" v-for="(type,index) in servicetypes" :key="index">
                {{type.name}}
            </div>
        </div>
        <div class="form-input-row" v-show="showSuccess">
            <div class="success-label">{{serviceTypeSuccess}}</div>
        </div>  
        <div>
            <div class="button-main" @click="addTerm">Add Service Type</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServiceTypeAdd',
    data() {
        return{
            newType: '',
            servicetypes: [],
            showSuccess: false,
            serviceTypeSuccess: 'New Service Type Successfully Added'
        }
    },
    components: {
        
    },
    methods: {
        addTerm() {
            window.console.log('ADD TERM', {name:this.newType})
            this.$store.dispatch('addServiceType',{name:this.newType}).then(response => {
                if (response.substr(0,7) === 'SUCCESS') {
                    this.getTypes()
                    this.showSuccess = true
                    setTimeout(() => {
                        this.showSuccess = false
                        this.newType = ''
                    }, 2000);
                }
            })
        },
        getTypes() {
            this.$store.dispatch('getServiceTypes').then(response => {
                this.servicetypes = response
            })
        }
    },
    mounted() {
        this.getTypes()
    },
}
</script>

<style scoped>

</style>